import { IconArrowDownRight, IconArrowRight, IconArrowUpRight } from '@tabler/icons-react';
import { forwardRef, memo, type ComponentPropsWithoutRef, type ForwardedRef } from 'react';
import { FormattedNumber } from 'react-intl';

import { type MergeAll } from '@amalia/ext/typescript';

import { type TablerIconComponent } from '../../general/icons/types';
import { Typography } from '../../general/typography/Typography';

import * as styles from './Trend.styles';
import { TrendDirection } from './Trend.types';

const RATIO_CLASSNAME_MAPPING: Record<TrendDirection, string> = {
  [TrendDirection.DOWN]: styles.DOWN_CLASSNAME,
  [TrendDirection.FLAT]: styles.FLAT_CLASSNAME,
  [TrendDirection.UP]: styles.UP_CLASSNAME,
};

const RATIO_ICON_MAPPING: Record<TrendDirection, TablerIconComponent> = {
  [TrendDirection.DOWN]: IconArrowDownRight,
  [TrendDirection.FLAT]: IconArrowRight,
  [TrendDirection.UP]: IconArrowUpRight,
};

export type TrendProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Current value. */
      currentValue: number;
      /** Value to compare current value to. */
      previousValue: number;
    },
  ]
>;

const TrendForwardRef = forwardRef(function Trend(
  { previousValue, currentValue, ...props }: TrendProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const trendRatio = previousValue
    ? Math.round(((currentValue - previousValue) / previousValue) * 100) / 100 // Need to round here otherwise it can show a +0% or -0% up/down trend instead of 0% flat.
    : currentValue === 0
      ? 0
      : currentValue > 0
        ? 1
        : -1;

  const trendDirection: TrendDirection =
    trendRatio > 0 ? TrendDirection.UP : trendRatio < 0 ? TrendDirection.DOWN : TrendDirection.FLAT;

  const Icon = RATIO_ICON_MAPPING[trendDirection];

  return (
    <div
      {...props}
      ref={ref}
      className={RATIO_CLASSNAME_MAPPING[trendDirection]}
      css={styles.trend}
    >
      <Icon size={14} />

      <Typography variant={Typography.Variant.BODY_XSMALL_MEDIUM}>
        <FormattedNumber
          maximumFractionDigits={0}
          minimumFractionDigits={0}
          signDisplay="exceptZero"
          style="percent"
          value={trendRatio}
        />
      </Typography>
    </div>
  );
});

export const Trend = memo(TrendForwardRef);
