import { keyBy, omit } from 'lodash';
import { combineReducers } from 'redux';

import { moveElementInArray, calculateNewIndicesAfterMovingElementsInArray } from '@amalia/core/types';
import { assert } from '@amalia/ext/typescript';
import { HidableElementVisibility, type PlanRule, type Plan } from '@amalia/payout-definition/plans/types';

import { type ReduxAction } from '../types';

import { PLANS_ACTIONS } from './constants';
import { type PlansReducer } from './types';

export const switchDisplayStatus = (displayStatus: HidableElementVisibility) =>
  displayStatus === HidableElementVisibility.AVAILABLE
    ? HidableElementVisibility.ON_DISPLAY
    : HidableElementVisibility.AVAILABLE;

export default combineReducers<PlansReducer, ReduxAction>({
  isLoading: (state = 0, action) => {
    switch (action.type) {
      case PLANS_ACTIONS.START:
        return state + 1;
      case PLANS_ACTIONS.ERROR:
      case PLANS_ACTIONS.SET_PLANS:
      case PLANS_ACTIONS.SET_CURRENT_PLAN:
      case PLANS_ACTIONS.SET_HIGHLIGHTED_KPI:
      case PLANS_ACTIONS.DELETE_HIGHLIGHTED_KPI:
      case PLANS_ACTIONS.SET_CATEGORIES:
      case PLANS_ACTIONS.DELETE_PLAN:
      case PLANS_ACTIONS.ARCHIVE_PLAN:
        return state - 1;
      default:
        return state;
    }
  },
  map: (state = {}, action) => {
    switch (action.type) {
      case PLANS_ACTIONS.SET_PLANS:
        return keyBy(action.payload.plans, 'id');
      case PLANS_ACTIONS.DELETE_PLAN: {
        return {
          ...omit(state, action.payload.planId),
        };
      }
      case PLANS_ACTIONS.SET_CURRENT_PLAN: {
        const payload = action.payload as { plan: Plan | null };
        return payload.plan
          ? {
              ...state,
              [payload.plan.id]: payload.plan,
            }
          : state;
      }
      case PLANS_ACTIONS.RENAME_PLAN: {
        return {
          ...state,
          [action.payload.planId]: {
            ...state[action.payload.planId],
            name: action.payload.name,
          },
        };
      }
      case PLANS_ACTIONS.ARCHIVE_PLAN: {
        return {
          ...state,
          [action.payload.updatedPlan.id]: {
            ...state[action.payload.updatedPlan.id],
            planAssignements: action.payload.updatedPlan.planAssignements,
            archived: action.payload.archived,
          },
        };
      }
      default:
        return state;
    }
  },
  isMapFullyLoaded: (state = false, action) => {
    switch (action.type) {
      case PLANS_ACTIONS.SET_PLANS:
        return true;
      default:
        return state;
    }
  },
  // @ts-expect-error -- Shit's broken.
  currentPlan: (state = null, action) => {
    switch (action.type) {
      case PLANS_ACTIONS.SET_CURRENT_PLAN:
        return action.payload.plan;
      case PLANS_ACTIONS.DELETE_PLAN:
        return state?.id === action.payload.planId ? null : state;
      case PLANS_ACTIONS.RENAME_PLAN:
        return {
          ...state,
          name: action.payload.name,
        };
      case PLANS_ACTIONS.ARCHIVE_PLAN: {
        return {
          ...state,
          planAssignements: action.payload.updatedPlan.planAssignements,
          archived: action.payload.archived,
        };
      }
      case PLANS_ACTIONS.SET_SETTINGS: {
        assert(state, 'currentPlan should be defined at this point.');
        return {
          ...state,
          workflowId: action.payload.settings.workflowId,
          linkToPlanDocumentation: action.payload.settings.linkToPlanDocumentation,
          useVariableInsteadOfTotal: action.payload.settings.useVariableInsteadOfTotal,
          totalVariableId: action.payload.settings.totalVariableId,
          name: action.payload.settings.name || state.name,
          badgeConfigurations: action.payload.settings.badgeConfigurations,
          manualWeight:
            action.payload.settings.manualWeight === undefined
              ? state.manualWeight
              : action.payload.settings.manualWeight,
          isForecasted: action.payload.settings.isForecasted,
          calculateBothStatements: action.payload.settings.calculateBothStatements,
          isSimulationEnabled: action.payload.settings.isSimulationEnabled,
          isSimulationAddRecordEnabled: action.payload.settings.isSimulationAddRecordEnabled,
        };
      }
      case PLANS_ACTIONS.SET_HIGHLIGHTED_KPI: {
        if (!state) {
          return null;
        }
        // Remove existing kpi with the same identifier.
        const kpiList = (state.highlightedKpis || []).filter((kpi) => kpi.identifier !== action.payload.kpi.identifier);
        // Add the set kpi.
        return {
          ...state,
          // @ts-expect-error -- Shit's broken.
          highlightedKpis: kpiList.concat(action.payload.kpi),
        };
      }
      case PLANS_ACTIONS.DELETE_HIGHLIGHTED_KPI: {
        if (!state) {
          return null;
        }
        return {
          ...state,
          highlightedKpis: (state.highlightedKpis || []).filter((kpi) => kpi.identifier !== action.payload.identifier),
        };
      }
      case PLANS_ACTIONS.ADD_PLAN_RULES: {
        if (!state?.rules) {
          return state;
        }
        const rulesToAdd: Omit<PlanRule, 'id'>[] = [];
        const numberOfRuleWithoutCategory = state.rules.filter((r) => r.category === null).length;
        for (let i = 0; i < action.payload.rules.length; i++) {
          // @ts-expect-error - Type is wrong
          rulesToAdd.push({ ...action.payload.rules[i], category: null, index: numberOfRuleWithoutCategory + i });
        }
        return {
          ...state,
          rules: [...state.rules, ...rulesToAdd],
        };
      }
      case PLANS_ACTIONS.ON_PATCH_RULE_KPIS_TO_DISPLAY:
        return {
          ...state,
          // For each rule, if we find a rule with the same id that in the patch, replace it.
          rules: (state?.rules || []).map((rule) =>
            rule.id === action.payload.ruleId ? { ...rule, kpisToDisplay: action.payload.kpisToDisplay } : rule,
          ),
        };

      case PLANS_ACTIONS.ON_PATCH_RULE_KPI_TO_DISPLAY_STATUS:
        return {
          ...state,
          // For each rule, if we find a rule with the same id that in the patch, replace it.
          rules: (state?.rules || []).map((rule) =>
            rule.id === action.payload.ruleId
              ? {
                  ...rule,
                  kpisToDisplay: (rule.kpisToDisplay || []).map((section) => ({
                    ...section,
                    kpis: (section.kpis || []).map((kpi) =>
                      kpi.id === action.payload.kpiToDisplayId
                        ? { ...kpi, displayStatus: switchDisplayStatus(kpi.displayStatus) }
                        : kpi,
                    ),
                  })),
                }
              : rule,
          ),
        };

      case PLANS_ACTIONS.ON_PATCH_RULE_REMOVE_KPI_TO_DISPLAY:
        return {
          ...state,
          // For each rule, if we find a rule with the same id that in the patch, replace it.
          rules: (state?.rules || []).map((rule) =>
            rule.id === action.payload.ruleId
              ? {
                  ...rule,
                  kpisToDisplay: (rule.kpisToDisplay || []).map((section) => ({
                    ...section,
                    kpis: (section.kpis || []).filter((kpi) => kpi.id !== action.payload.kpiToDisplayId),
                  })),
                }
              : rule,
          ),
        };

      case PLANS_ACTIONS.ON_PATCH_RULE_FILTERS_TO_DISPLAY:
        return {
          ...state,
          // For each rule, if we find a rule with the same id that in the patch, replace it.
          rules: (state?.rules || []).map((rule) =>
            rule.id === action.payload.ruleId ? { ...rule, filtersToDisplay: action.payload.filtersToDisplay } : rule,
          ),
        };

      case PLANS_ACTIONS.ON_PATCH_RULE_FILTER_TO_DISPLAY_STATUS:
        return {
          ...state,
          // For each rule, if we find a rule with the same id that in the patch, replace it.
          rules: (state?.rules || []).map((rule) =>
            rule.id === action.payload.ruleId
              ? {
                  ...rule,
                  filtersToDisplay: (rule.filtersToDisplay || []).map((filterToDisplay) =>
                    filterToDisplay.id === action.payload.filterToDisplayId
                      ? { ...filterToDisplay, displayStatus: switchDisplayStatus(filterToDisplay.displayStatus) }
                      : filterToDisplay,
                  ),
                }
              : rule,
          ),
        };

      case PLANS_ACTIONS.ON_PATCH_RULE_REMOVE_FILTER_TO_DISPLAY:
        return {
          ...state,
          // For each rule, if we find a rule with the same id that in the patch, replace it.
          rules: (state?.rules || []).map((rule) =>
            rule.id === action.payload.ruleId
              ? {
                  ...rule,
                  filtersToDisplay: (rule.filtersToDisplay || []).filter(
                    (filterToDisplay) => filterToDisplay.id !== action.payload.filterToDisplayId,
                  ),
                }
              : rule,
          ),
        };

      case PLANS_ACTIONS.ON_PATCH_RULE_FIELDS_TO_DISPLAY:
        return {
          ...state,
          // For each rule, if we find a rule with the same id that in the patch, replace it.
          rules: (state?.rules || []).map((rule) =>
            rule.id === action.payload.ruleId
              ? {
                  ...rule,
                  filtersToDisplay: (rule.filtersToDisplay || []).map((filterToDisplay) =>
                    filterToDisplay.id === action.payload.filterId
                      ? { ...filterToDisplay, fieldsToDisplay: action.payload.fieldsToDisplay }
                      : filterToDisplay,
                  ),
                }
              : rule,
          ),
        };

      case PLANS_ACTIONS.ON_PATCH_RULE_FIELD_TO_DISPLAY_STATUS:
        return {
          ...state,
          // For each rule, if we find a rule with the same id that in the patch, replace it.
          rules: (state?.rules || []).map((rule) =>
            rule.id === action.payload.ruleId
              ? {
                  ...rule,
                  filtersToDisplay: (rule.filtersToDisplay || []).map((filterToDisplay) =>
                    filterToDisplay.id === action.payload.filterId
                      ? {
                          ...filterToDisplay,
                          fieldsToDisplay: filterToDisplay.fieldsToDisplay.map((fieldToDisplay) =>
                            fieldToDisplay.name === action.payload.fieldToDisplayMachinename
                              ? { ...fieldToDisplay, displayStatus: switchDisplayStatus(fieldToDisplay.displayStatus) }
                              : fieldToDisplay,
                          ),
                        }
                      : filterToDisplay,
                  ),
                }
              : rule,
          ),
        };

      case PLANS_ACTIONS.ON_PATCH_RULE_REMOVE_FIELD_TO_DISPLAY:
        return {
          ...state,
          // For each rule, if we find a rule with the same id that in the patch, replace it.
          rules: (state?.rules || []).map((rule) =>
            rule.id === action.payload.ruleId
              ? {
                  ...rule,
                  filtersToDisplay: (rule.filtersToDisplay || []).map((filterToDisplay) =>
                    filterToDisplay.id === action.payload.filterId
                      ? {
                          ...filterToDisplay,
                          fieldsToDisplay: filterToDisplay.fieldsToDisplay.filter(
                            (fieldToDisplay) => fieldToDisplay.name !== action.payload.fieldToDisplayMachinename,
                          ),
                        }
                      : filterToDisplay,
                  ),
                }
              : rule,
          ),
        };

      case PLANS_ACTIONS.ON_PATCH_RULES:
        return {
          ...state,
          // For each rule, if we find a rule with the same id that in the patch, replace it.
          rules: (state?.rules || []).map((rule) => action.payload.rules.find((r) => r.id === rule.id) || rule),
        };
      case PLANS_ACTIONS.ON_REMOVE_RULE:
        // It is necessary to lower by 1 all the indexes of the rules having an index higher than the deleted rule.
        return {
          ...state,
          rules: (state?.rules || [])
            .filter((rule) => rule.id !== action.payload.rule.id)
            .map((r) => {
              if (r.category === action.payload.rule.category && r.index > action.payload.rule.index) {
                return {
                  ...r,
                  index: r.index - 1,
                };
              }
              return r;
            }),
        };
      case PLANS_ACTIONS.ON_REMOVE_RULE_BY_ID: {
        // It is necessary to lower by 1 all the indexes of the rules having an index higher than the deleted rule.
        const ruleToDelete = state?.rules?.find((r) => r.id === action.payload.ruleId);
        return {
          ...state,
          rules: ruleToDelete
            ? (state?.rules || [])
                .filter((rule) => rule.id !== action.payload.ruleId)
                .map((r) => {
                  if (r.category === ruleToDelete.category && r.index > ruleToDelete.index) {
                    return {
                      ...r,
                      index: r.index - 1,
                    };
                  }
                  return r;
                })
            : (state?.rules || []).filter((rule) => rule.id !== action.payload.ruleId),
        };
      }
      case PLANS_ACTIONS.CREATE_CATEGORY:
        return {
          ...state,
          categoriesV2: (state?.categoriesV2 || []).concat(action.payload.category),
        };
      case PLANS_ACTIONS.DELETE_CATEGORY: {
        // When deleting category we need to move all rules inside to the null category
        const nbOfRulesCategoryNull = (state?.rules || []).filter((r) => r.category === null).length;
        let i = -1;
        return {
          ...state,
          categoriesV2: (state?.categoriesV2 || []).filter((c) => c.name !== action.payload.categoryName),
          rules: (state?.rules || []).map((r) => {
            if (r.category === action.payload.categoryName) {
              i++;
              return {
                ...r,
                category: null,
                index: nbOfRulesCategoryNull + i,
              };
            }
            return r;
          }),
        };
      }
      case PLANS_ACTIONS.EDIT_CATEGORY:
        // When editing a category name we need to update all rules in this category.
        return {
          ...state,
          categoriesV2: (state?.categoriesV2 || []).map((c) => {
            if (c.name === action.payload.oldCategoryName) {
              return action.payload.category;
            }
            return c;
          }),
          rules: (state?.rules || []).map((r) => {
            if (r.category === action.payload.oldCategoryName) {
              return {
                ...r,
                category: action.payload.category.name,
              };
            }
            return r;
          }),
        };
      case PLANS_ACTIONS.MOVE_CATEGORY:
        return {
          ...state,
          categoriesV2: moveElementInArray(state?.categoriesV2 || [], action.payload.fromIndex, action.payload.toIndex),
        };
      case PLANS_ACTIONS.MOVE_RULE:
        // Moving rule is decompose in 2 cases : update the order in the same category or update the order and the category name.
        // In the second case we need also the reindex the rules in the previous category and also in the one selected.
        if (action.payload.lastCategoryName === action.payload.newCategoryName) {
          // Sometimes the indices are destroyed for no reason. Let's start by rebuilding the indices.
          // Build and hashmap of the current cursor per category.
          const indicesPerCategory: Record<string, number> = {};
          // Order the rules by their index (so if the indices were set properly, it doesn't move).
          const rebuildedRules = [...(state?.rules || [])]
            .sort((r1, r2) => r1.index - r2.index)
            .map((r) => {
              // Get the current rule index in the category.
              const currentIndex = indicesPerCategory[r.category || 'null'] || 0;
              indicesPerCategory[r.category || 'null'] = currentIndex + 1;
              return {
                ...r,
                // And rewrite it.
                index: currentIndex,
              };
            });

          return {
            ...state,
            // And now do the move.
            rules: rebuildedRules.map((r) => {
              if (r.category === action.payload.newCategoryName) {
                return {
                  ...r,
                  index: calculateNewIndicesAfterMovingElementsInArray(
                    action.payload.fromIndex,
                    action.payload.toIndex,
                    r.index,
                  ),
                };
              }
              return r;
            }),
          };
        }
        return {
          ...state,
          rules: (state?.rules || []).map((r) => {
            if (r.id === action.payload.ruleId) {
              return {
                ...r,
                category: action.payload.newCategoryName,
                index: action.payload.toIndex,
              };
            }
            if (r.category === action.payload.newCategoryName && r.index >= action.payload.toIndex) {
              return {
                ...r,
                index: r.index + 1,
              };
            }
            if (r.category === action.payload.lastCategoryName && r.index >= action.payload.fromIndex) {
              return {
                ...r,
                index: r.index - 1,
              };
            }
            return r;
          }),
        };
      case PLANS_ACTIONS.EDIT_VISIBILITY: {
        return {
          ...state,
          isHidden: action.payload.isHidden,
        };
      }
      default:
        return state;
    }
  },
  ruleCategories: (state = {}, action) => {
    switch (action.type) {
      case PLANS_ACTIONS.SET_CATEGORIES:
        return {
          ...state,
          ...keyBy(action.payload.ruleCategories, 'id'),
        };
      default:
        return state;
    }
  },
});
