import styled from '@emotion/styled';
import { IconRobot } from '@tabler/icons-react';
import { type KeyboardEvent, memo, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import { Button, Group, Modal, TextArea } from '@amalia/design-system/components';
import { useCurrentUser } from '@amalia/kernel/auth/state';
import { useOmolioPrompt } from '@amalia/omolio/state';
import { type OmolioMessage, OmolioMessageType } from '@amalia/omolio/types';

import { useOmolioPromptModalContext } from './contexts/omolio-prompt-modal.context';
import { OmolioPromptMessage } from './messages/OmolioPromptMessage';

const OmolioChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  height: 480px;
  width: 100%;
`;

const OmolioChatMessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 8px;
  overflow-y: auto;
`;

const OmolioChatInputContainer = styled.div`
  display: flex;
  gap: 32px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const OmolioChatInput = styled.div`
  flex: 1;
`;

export const OmoliotPromptModal = memo(function OmoliotPromptModal() {
  const { formatMessage } = useIntl();
  const { data: user } = useCurrentUser();

  // Greeting message displayed when the modal is opened
  const omolioGreetingMessage = formatMessage(
    {
      defaultMessage:
        "Hello {firstName} {lastName}, I'm Omolio, an AI assistant designed to help you with Amalia.<br />How can I help you today?",
    },
    { firstName: user.firstName, lastName: user.lastName },
  );

  // To keep track of the conversation
  const conversationIdRef = useRef(uuid());
  const [question, setQuestion] = useState('');
  const [messages, setMessages] = useState<OmolioMessage[]>([
    {
      conversationId: conversationIdRef.current,
      content: omolioGreetingMessage,
      id: uuid(),
      type: OmolioMessageType.answer,
    },
  ]);
  const lastMessageRef = useRef<HTMLDivElement | null>(null);

  const { setOmolioPromptModalOpenFalse } = useOmolioPromptModalContext();
  const { data: omolioAnswer, isPending, mutate: askAmolio } = useOmolioPrompt();

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    if (omolioAnswer) {
      const newOmolioAnswer: OmolioMessage = {
        conversationId: conversationIdRef.current,
        content: omolioAnswer,
        id: uuid(),
        type: OmolioMessageType.answer,
      };

      // Remove the last "answer" that was a fake one to show the thinking indicator
      setMessages((prevMessages) => [...prevMessages.slice(0, prevMessages.length - 1), newOmolioAnswer]);
    }
  }, [omolioAnswer]);

  const handleChangeTextarea = useCallback((newQuestion: string) => {
    setQuestion(newQuestion);
  }, []);

  const sendQuestion = useCallback(() => {
    if (!question || isPending) {
      return;
    }

    const omolioQuestionMessage: OmolioMessage = {
      id: uuid(),
      conversationId: conversationIdRef.current,
      content: question,
      type: OmolioMessageType.question,
    };

    const thinkingMessage = formatMessage({ defaultMessage: 'Omolio is thinking...' });

    const omolioThinkingMessage: OmolioMessage = {
      conversationId: conversationIdRef.current,
      content: `<b><i>${thinkingMessage}</i></b>`,
      id: uuid(),
      type: OmolioMessageType.answer,
    };

    setMessages((prevQuestions) => [...prevQuestions, omolioQuestionMessage, omolioThinkingMessage]);
    setQuestion('');
    askAmolio(omolioQuestionMessage);
  }, [question, askAmolio, isPending, formatMessage]);

  const handleKeydownTextarea = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        // To avoid new line in textarea
        event.preventDefault();
        sendQuestion();
      }
    },
    [sendQuestion],
  );

  const handleClickAskButton = useCallback(() => {
    sendQuestion();
  }, [sendQuestion]);

  return (
    <Modal
      isDismissable
      isOpen
      size={Modal.Size.XLARGE}
      variant={Modal.Variant.DEFAULT}
      onClose={setOmolioPromptModalOpenFalse}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <Group align="center">
              <IconRobot /> <FormattedMessage defaultMessage="Ask Omolio" />
            </Group>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <OmolioChatContainer>
            <OmolioChatMessagesContainer>
              {messages.map((message) => (
                <OmolioPromptMessage
                  key={message.id}
                  message={message}
                />
              ))}
              <div ref={lastMessageRef} />
            </OmolioChatMessagesContainer>

            <OmolioChatInputContainer>
              <OmolioChatInput>
                <TextArea
                  autoFocus
                  value={question}
                  onChange={handleChangeTextarea}
                  onKeyDown={handleKeydownTextarea}
                />
              </OmolioChatInput>

              <Button
                disabled={isPending || !question.trim().length}
                variant={Button.Variant.PRIMARY}
                onClick={handleClickAskButton}
              >
                <FormattedMessage defaultMessage="Ask" />
              </Button>
            </OmolioChatInputContainer>
          </OmolioChatContainer>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
});
