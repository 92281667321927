import { Fragment, memo } from 'react';

import { type DatasetRow, type Statement } from '@amalia/core/types';
import { type Dataset } from '@amalia/payout-calculation/types';
import { type StatementThread } from '@amalia/payout-collaboration/comments/types';
import { type PlanRule } from '@amalia/payout-definition/plans/types';

import { type ComputedPlanRuleFieldsToDisplayWithSubTitle } from '../../RowsTable/RowsTable';

import { RowAdditionalRowIndicator } from './row-additional-row-indicator/RowAdditionalRowIndicator';
import { RowOverwritesIndicator } from './row-overwrite-indicator/RowOverwritesIndicator';
import { RowThreadsIndicator } from './row-threads-indicator/RowThreadsIndicator';

export type DatasetRecordsDataGridRowIndicatorsProps = {
  readonly datasetRow: DatasetRow;
  readonly dataset: Dataset;
  readonly fields: ComputedPlanRuleFieldsToDisplayWithSubTitle[];
  readonly statementThreads: Record<string, StatementThread>;
  readonly rule?: PlanRule;
  readonly statement?: Statement;
};

export const DatasetRecordsDataGridRowIndicators = memo(function DatasetRecordsDataGridRowIndicators({
  datasetRow,
  dataset,
  fields,
  statementThreads,
  rule,
  statement,
}: DatasetRecordsDataGridRowIndicatorsProps) {
  return (
    <Fragment>
      <RowAdditionalRowIndicator datasetRow={datasetRow} />

      <RowOverwritesIndicator
        datasetRow={datasetRow}
        fields={fields}
      />

      <RowThreadsIndicator
        dataset={dataset}
        datasetRow={datasetRow}
        fields={fields}
        rule={rule}
        statement={statement}
        statementThreads={statementThreads}
      />
    </Fragment>
  );
});
