import { useTheme } from '@emotion/react';
import { IconAlertTriangle, IconExclamationCircle } from '@tabler/icons-react';
import { type ReactNode, memo, useLayoutEffect } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { type TablerIconProps } from '../../../../general/icons/types';
import { Tooltip } from '../../../../overlays/tooltip/Tooltip';

export type CellErrorIndicatorProps = MergeAll<
  [
    Omit<TablerIconProps, 'color' | 'size'>,
    {
      readonly error?: ReactNode;
      readonly warning?: ReactNode;
      readonly isTooltipOpen?: boolean;
      readonly onChangeIsTooltipOpen?: (isOpen: boolean) => void;
    },
  ]
>;

export const CellErrorIndicator = memo(function CellErrorIndicator({
  error,
  warning,
  isTooltipOpen,
  onChangeIsTooltipOpen,
  ...props
}: CellErrorIndicatorProps) {
  const theme = useTheme();

  useLayoutEffect(() => {
    if (!error && !warning && isTooltipOpen) {
      onChangeIsTooltipOpen?.(false);
    }
  }, [error, warning, isTooltipOpen, onChangeIsTooltipOpen]);

  return error || warning ? (
    <Tooltip
      content={error || warning}
      isOpen={isTooltipOpen}
      onChangeIsOpen={onChangeIsTooltipOpen}
    >
      {error ? (
        <IconExclamationCircle
          {...props}
          color={theme.ds.colors.danger[500]}
          size={18}
        />
      ) : (
        <IconAlertTriangle
          {...props}
          color={theme.ds.colors.warning[500]}
          size={18}
        />
      )}
    </Tooltip>
  ) : null;
});
