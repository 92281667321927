import { type FormulaBuilderFunctionCategory } from '../amaliaFormula';

import { type FormulaBuilderFunctionBaseBlock } from './formulaBuilder';
import { type AttributeValue, type ManualValue } from './valueOrAttribute';

export enum DateTransform {
  DAYS_AGO = 'DAYS_AGO',
  MONTHS_AGO = 'MONTHS_AGO',
}

export type DateOptions = {
  options: {
    transform: DateTransform | null;
    value: number | null;
  };
};
export type ManualValueDate = ManualValue<number>;
export type AttributeValueDate = AttributeValue & DateOptions;
export type ValueOrAttributeDate = AttributeValueDate | ManualValueDate;

export enum FormulaBuilderDateOperatorNoArgs {
  YEAR_TO_DATE = 'YEAR_TO_DATE',
  QUARTER_TO_DATE = 'QUARTER_TO_DATE',
  IN_MONTH = 'IN_MONTH',
  IN_PERIOD = 'IN_PERIOD',
  IS_BLANK = 'IS_BLANK',
  IS_NOT_BLANK = 'IS_NOT_BLANK',
}

export enum FormulaBuilderDateOperatorOneArg {
  DATE_BEFORE = 'DATE_BEFORE',
  DATE_AFTER = 'DATE_AFTER',
  DATE_STRICTLY_BEFORE = 'DATE_STRICTLY_BEFORE',
  DATE_STRICTLY_AFTER = 'DATE_STRICTLY_AFTER',
  DATE_EQUALS = 'DATE_EQUALS',
}

export enum FormulaBuilderDateOperatorTwoArgs {
  DATE_BETWEEN = 'DATE_BETWEEN',
}

export type FormulaBuilderFunctionBlockDate =
  | FormulaBuilderFunctionBlockDateNoArgs
  | FormulaBuilderFunctionBlockDateOneArg
  | FormulaBuilderFunctionBlockDateTwoArgs;

export type FormulaBuilderFunctionBlockDateBase = FormulaBuilderFunctionBaseBlock & {
  category: FormulaBuilderFunctionCategory.DATE;
};

export type FormulaBuilderFunctionBlockDateNoArgs = FormulaBuilderFunctionBlockDateBase & {
  operator: FormulaBuilderDateOperatorNoArgs;
  args: [AttributeValueDate];
};

export type FormulaBuilderFunctionBlockDateOneArg = FormulaBuilderFunctionBlockDateBase & {
  operator: FormulaBuilderDateOperatorOneArg;
  args: [AttributeValueDate, ValueOrAttributeDate];
};

export type FormulaBuilderFunctionBlockDateTwoArgs = FormulaBuilderFunctionBlockDateBase & {
  operator: FormulaBuilderDateOperatorTwoArgs;
  args: [AttributeValueDate, ValueOrAttributeDate, ValueOrAttributeDate];
};
