import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type VariableDefinition } from '@amalia/amalia-lang/tokens/types';
import { formatTotal } from '@amalia/core/types';
import {
  ColumnHelper,
  DataGrid,
  getPageCount,
  useControlledDataGridData,
  useDataGridState,
} from '@amalia/design-system/components';
import { fuzzyFilter } from '@amalia/ext/filters';
import { type Period } from '@amalia/payout-definition/periods/types';
import { type Challenge, type ChallengeLeaderboard } from '@amalia/payout-definition/plans/types';
import { type Company } from '@amalia/tenants/companies/types';
import { type UsersMap } from '@amalia/tenants/users/types';

import { ChallengeLeaderboardCellPosition } from './cells/position/ChallengeLeaderboardCellPosition';
import { ChallengeLeaderboardCellUser } from './cells/user/ChallengeLeaderboardCellUser';

const columnHelper = new ColumnHelper<ChallengeLeaderboard>();

export type ChallengeLeaderboardTableProps = {
  readonly challenge: Challenge;
  readonly company: Company;
  readonly currentPeriod: Period;
  readonly comparisonVariable: Pick<VariableDefinition, 'format' | 'name'>;
  readonly usersMap: UsersMap;
};

export const ChallengeLeaderboardTable = memo(function ChallengeLeaderboardTable({
  challenge,
  company,
  currentPeriod,
  comparisonVariable,
  usersMap,
}: ChallengeLeaderboardTableProps) {
  const { formatMessage } = useIntl();

  // I don't think it's necessary but the type is nullable. The previous implementation did not check for null and did not crash.
  const leaderboard = useMemo(() => challenge.leaderboard || [], [challenge.leaderboard]);

  const { searchText, page, pageSize, setPage, setPageSize, setSearchText } = useDataGridState();

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor('position', {
          id: 'position',
          size: 0,
          header: formatMessage({ defaultMessage: 'Rank' }),

          cell: ({ value: position }) => <ChallengeLeaderboardCellPosition position={position} />,
        }),

        columnHelper.accessor('userId', {
          id: 'userId',
          header: formatMessage({ defaultMessage: 'User name' }),
          filterFn: (userId: string, searchText) =>
            fuzzyFilter(`${usersMap[userId].firstName}${usersMap[userId].lastName}`, searchText),

          cell: ({ value: userId, row: leaderboardRow }) =>
            userId && userId in usersMap ? (
              <ChallengeLeaderboardCellUser
                period={currentPeriod}
                statementId={leaderboardRow.statementIds[0]}
                user={usersMap[userId]}
              />
            ) : null,
        }),

        !challenge.rule?.configuration?.challengeHideResults &&
          columnHelper.accessor('comparisonValue', {
            id: 'comparisonValue',
            header: comparisonVariable.name,
            cell: ({ value: comparisonValue }) =>
              formatTotal(comparisonValue, comparisonVariable.format, company.currency),
          }),
      ].filter(Boolean),
    [
      formatMessage,
      challenge.rule?.configuration?.challengeHideResults,
      comparisonVariable.name,
      comparisonVariable.format,
      usersMap,
      currentPeriod,
      company.currency,
    ],
  );

  const { data, total } = useControlledDataGridData(columns, leaderboard, {
    page,
    pageSize,
    searchText,
  });

  const pageCount = getPageCount(total, pageSize);

  return (
    <DataGrid
      columns={columns}
      data={data}
      rowKey={(row) => row.userId!} // The type is optional because the model handles team challenges but the app doesn't.
      pageSize={
        <DataGrid.PageSize
          value={pageSize}
          onChange={setPageSize}
        />
      }
      pagination={
        <DataGrid.Pagination
          page={page}
          pageCount={pageCount}
          onChangePage={setPage}
        />
      }
      search={
        <DataGrid.Search
          value={searchText}
          onChange={setSearchText}
        />
      }
    />
  );
});
