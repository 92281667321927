import { type Theme, css } from '@emotion/react';

import { ColorCategory } from '@amalia/design-system/meta';

export const container = css`
  min-height: 340px;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 24px 40px;
`;

export const title = css`
  text-transform: capitalize;
`;

export const body = css`
  width: 100%;
  flex: 1;
`;

export const rulesAndTotalSeparator = css`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const statementTotalRow = css`
  display: flex;
  justify-content: space-between;
`;

export const statementTotalHighlight = (theme: Theme) => css`
  background-color: ${theme.ds.colors[ColorCategory.PRIMARY]['50']};
`;

export const footer = css`
  display: flex;
  justify-content: flex-end;
`;

export const statementLink = css`
  text-decoration: none;
`;
