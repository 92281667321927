import { css } from '@emotion/react';
import { IconPencilPlus } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type DatasetRow } from '@amalia/core/types';
import { Tooltip } from '@amalia/design-system/components';

import { IndicatorIcon } from '../indicator-icon/IndicatorIcon';

export type RowAdditionalRowIndicatorProps = {
  readonly datasetRow: DatasetRow;
};

export const RowAdditionalRowIndicator = memo(function RowAdditionalRowIndicator({
  datasetRow,
}: RowAdditionalRowIndicatorProps) {
  return (
    !!datasetRow.isAdditionalRow && (
      <Tooltip content={<FormattedMessage defaultMessage="This record was added via a simulation." />}>
        <IndicatorIcon
          icon={<IconPencilPlus />}
          css={(theme) => css`
            background-color: ${theme.ds.hues.purple[50]};
            color: ${theme.ds.hues.purple[500]};
          `}
        />
      </Tooltip>
    )
  );
});
