import { combineReducers } from 'redux';

import { type PlanAssignmentsByPlan } from '../../types/planAssignments';
import { type ReduxAction } from '../types';

import { PLAN_ASSIGNMENTS_ACTIONS } from './constants';
import { type PlanAssignmentsReducer } from './types';

export default combineReducers<PlanAssignmentsReducer, ReduxAction>({
  isLoading: (state = 0, action) => {
    switch (action.type) {
      case PLAN_ASSIGNMENTS_ACTIONS.START:
        return state + 1;
      case PLAN_ASSIGNMENTS_ACTIONS.SET_PLAN_ASSIGNMENTS:
      case PLAN_ASSIGNMENTS_ACTIONS.ERROR:
        return state - 1;
      default:
        return state;
    }
  },
  map: (state = {}, action) => {
    switch (action.type) {
      case PLAN_ASSIGNMENTS_ACTIONS.SET_PLAN_ASSIGNMENTS:
        return {
          ...state,
          ...action.payload.planAssignments.reduce(
            (acc, p) => ({
              ...acc,
              [p.planId]: [...(p.planId in acc ? acc[p.planId] : []), p],
            }),
            {} as PlanAssignmentsByPlan,
          ),
        };
      default:
        return state;
    }
  },
});
