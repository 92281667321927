import { useLocalStorageState } from 'ahooks';
import { isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type ComputedVariableWithVariableIdAndLabel } from '@amalia/core/types';
import { type SelectDropdownOption, type SelectOptionGroup } from '@amalia/design-system/components';
import { type StatementDetailContextInterface } from '@amalia/lib-ui';
import {
  HidableElementVisibility,
  type PlanRule,
  type PlanRuleKpiSection,
  type PlanRuleKpiToDisplay,
} from '@amalia/payout-definition/plans/types';

export type SectionWithComputedKPIS = Omit<PlanRuleKpiSection, 'kpis'> & {
  kpis: ComputedVariableWithVariableIdAndLabel[];
};

export type KpiOption = SelectDropdownOption<string>;

/**
 * Hook to handle the list of selected kpis to display in the statement
 * for a given rule, also taking into account the view (forecasted or not) and
 * the user preferences (stored in local storage).
 *
 * IMPORTANT TO NOTE THAT THE SOURCE OF TRUTH FOR THE KPIs TO DISPLAY COMES FROM THE DESIGNER
 * WE ONLY APPLY THE USER PREFERENCES ON TOP OF IT
 */
export const useKPIDisplay = (
  statementContext: StatementDetailContextInterface,
  ruleDefinition: PlanRule,
  statementRuleComputedVariables: ComputedVariableWithVariableIdAndLabel[],
) => {
  const { formatMessage } = useIntl();

  // We extract here the kpis "source" and the local storage key to avoid conditional later in every useMemo
  // Both depend on the view (forecasted or not)
  const kpiSource = useMemo(
    () =>
      (statementContext.isForecastedView
        ? statementContext.objectsToDisplay?.[ruleDefinition.id]?.kpisToDisplay?.flatMap(
            (section) => section.kpis || [],
          )
        : ruleDefinition.kpisToDisplay?.flatMap((section) => section.kpis || [])) || [],
    [
      ruleDefinition.id,
      ruleDefinition.kpisToDisplay,
      statementContext.isForecastedView,
      statementContext.objectsToDisplay,
    ],
  );
  const selectedKPIsStorageKey = statementContext.isForecastedView
    ? `displayed-forecasted-kpis-${statementContext.statement.plan.id}-${ruleDefinition.id}`
    : `displayed-kpis-${statementContext.statement.plan.id}-${ruleDefinition.id}`;

  // Save the displayed/hidden kpis in local storage to keep the user preferences
  // We can't only save the ids because the order of the kpis is important
  // We'll need to update the list if the displayed kpis change in the designer
  const [kpisVisibilityUserPreferences, setKpisVisibilityUserPreferences] = useLocalStorageState<
    Pick<PlanRuleKpiToDisplay, 'displayStatus' | 'id'>[]
  >(selectedKPIsStorageKey, {
    defaultValue: () =>
      kpiSource.map(({ id, displayStatus }) => ({
        id,
        displayStatus,
      })),
  });

  // The KPI ids to display from the kpiSource as the source of truth
  // Override when needed with the user preferences
  const displayedKPIIds = useMemo(
    () =>
      kpiSource
        .map((kpiFromStatement) => {
          // If the kpi is not in the user preferences, we return the kpi from the source (designer state)
          const kpiInUserPreferences = kpisVisibilityUserPreferences.find(({ id }) => id === kpiFromStatement.id);
          return kpiInUserPreferences ?? kpiFromStatement;
        })
        .filter(({ displayStatus }) => displayStatus === HidableElementVisibility.ON_DISPLAY)
        .map(({ id }) => id),
    [kpiSource, kpisVisibilityUserPreferences],
  );

  const availableKPIOptions = useMemo(
    () =>
      (
        (statementContext.isForecastedView
          ? statementContext.objectsToDisplay?.[ruleDefinition.id]?.kpisToDisplay
          : ruleDefinition.kpisToDisplay) || []
      )
        .map(
          (section): SelectOptionGroup<KpiOption> =>
            // Show ungrouped kpis at the root of the dropdown, and grouped kpis inside a group.
            ({
              label: section.name ?? formatMessage({ defaultMessage: 'Ungrouped' }),
              options: (section.kpis || [])
                .map((kpi) => ({
                  label: statementRuleComputedVariables.find(({ kpiVariableId }) => kpiVariableId === kpi.id)?.label,
                  value: kpi.id,
                }))
                .filter((kpi) => kpi.label),
            }),
        )
        .filter(({ options }) => options.length), // Ignore empty groups.
    [
      formatMessage,
      statementRuleComputedVariables,
      ruleDefinition.id,
      ruleDefinition.kpisToDisplay,
      statementContext.isForecastedView,
      statementContext.objectsToDisplay,
    ],
  );

  // The callback proxy to update the local storage and everything that depends on it
  // So basically, all the process from the top of this hook
  const setDisplayedKPIIds = useCallback(
    (newDisplayedKPIIds: string[]) => {
      setKpisVisibilityUserPreferences(
        kpiSource.map(({ id }) => ({
          id,
          displayStatus: newDisplayedKPIIds.includes(id)
            ? HidableElementVisibility.ON_DISPLAY
            : HidableElementVisibility.AVAILABLE,
        })),
      );
    },
    [kpiSource, setKpisVisibilityUserPreferences],
  );

  // The Sections with the KPIs to display from the list of planRuleKPIs (including the value)
  const KPISections: SectionWithComputedKPIS[] = useMemo(() => {
    const sections =
      (statementContext.isForecastedView
        ? statementContext.objectsToDisplay?.[ruleDefinition.id]?.kpisToDisplay
        : ruleDefinition.kpisToDisplay) || [];
    return sections
      .map((section) => ({
        ...section,
        kpis: (section.kpis || [])
          .filter((kpi) => displayedKPIIds.includes(kpi.id))
          .map((kpi) => statementRuleComputedVariables.find(({ kpiVariableId }) => kpiVariableId === kpi.id))
          .filter(Boolean),
      }))
      .filter((section) => !isEmpty(section.kpis));
  }, [
    displayedKPIIds,
    ruleDefinition.id,
    ruleDefinition.kpisToDisplay,
    statementContext.isForecastedView,
    statementContext.objectsToDisplay,
    statementRuleComputedVariables,
  ]);

  return {
    availableKPIOptions,
    displayedKPIIds,
    setDisplayedKPIIds,
    KPISections,
  };
};
