import { css } from '@emotion/react';
import { memo } from 'react';

import { type CalculationDescriptorBatch } from '@amalia/core/types';
import { Group } from '@amalia/design-system/components';
import { type UserComputed } from '@amalia/tenants/users/types';

import { iconForCalculationStatus } from './calculationProgression.utils';
import { CalculationProgressModalStepBatchUsers } from './CalculationProgressModalStepBatchUsers';

interface CalculationProgressModalStepBatchProps {
  readonly batch: CalculationDescriptorBatch;
}

export const CalculationProgressModalStepBatch = memo(function CalculationProgressModalStepBatch({
  batch,
}: CalculationProgressModalStepBatchProps) {
  const icon = iconForCalculationStatus[batch.status];

  return (
    <Group
      align="center"
      css={css`
        min-height: 48px;
        margin-top: 16px;
        position: relative;
      `}
    >
      <Group
        align="center"
        justify="center"
        css={(theme) => css`
          color: ${theme.ds.colors.gray[0]};
          background-color: ${theme.ds.colors.primary[400]};
          border-radius: ${theme.ds.borderRadiuses.round};
          height: 32px;
          width: 32px;
          flex: none;
        `}
      >
        {icon}
      </Group>

      <div
        css={(theme) => css`
          position: absolute;
          top: 50%;
          width: 16px;
          left: 32px;
          height: 1px;
          background-color: ${theme.ds.colors.primary[400]};
        `}
      />

      <div
        css={css`
          margin-left: 16px;
        `}
      >
        <CalculationProgressModalStepBatchUsers
          subTitle={batch.planName}
          users={batch.users as UserComputed[]}
        />
      </div>
    </Group>
  );
});
