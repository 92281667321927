export enum BadgeSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum BadgeHue {
  BLUE = 'BLUE',
  BROWN = 'BROWN',
  CYAN = 'CYAN',
  GREEN = 'GREEN',
  GREY = 'GREY',
  MAGENTA = 'MAGENTA',
  ORANGE = 'ORANGE',
  RED = 'RED',
  YELLOW = 'YELLOW',
}

export enum BadgeColor {
  PRIMARY = 'PRIMARY',
}

export enum BadgeStatus {
  DANGER = 'DANGER',
  READY = 'READY',
  MISSING = 'MISSING',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}
