import {
  useIsFetching,
  useIsMutating,
  useMutation,
  useMutationState,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { last } from 'lodash';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { useFeedback } from '@amalia/ext/react/hooks';
import { assert, toError } from '@amalia/ext/typescript';
import { UserProfileApiClient } from '@amalia/tenants/users/profile/api-client';
import { USER_PROFILE_QUERY_KEYS } from '@amalia/tenants/users/profile/state';
import { type UpdateUserProfileRequest, type UserProfile } from '@amalia/tenants/users/profile/types';

export const useAuthorizedProfiles = (options?: { enabled?: boolean }) =>
  useQuery({
    queryKey: [USER_PROFILE_QUERY_KEYS.PROFILE],
    queryFn: () => UserProfileApiClient.getAuthorizedProfiles(),
    initialData: [],
    ...options,
  });

export const useUserProfile = (userId?: UserProfile['id']) =>
  useQuery({
    queryKey: [USER_PROFILE_QUERY_KEYS.PROFILE, userId],
    queryFn: () => {
      // enabled should have made sure userId is defined.
      assert(userId, 'userId is required');
      return UserProfileApiClient.getUserProfile(userId);
    },
    enabled: !!userId,
  });

export const useUpdateUserInfo = (
  userId: UserProfile['id'],
  { shouldInviteToReload = () => false }: { shouldInviteToReload?: (updatedUser: UserProfile) => boolean } = {},
) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: [USER_PROFILE_QUERY_KEYS.PROFILE, userId, 'info'],
    mutationFn: (user: UpdateUserProfileRequest) => UserProfileApiClient.updateUserInfo(user),
    onSuccess: async (updatedUser) => {
      snackSuccess(
        shouldInviteToReload(updatedUser)
          ? formatMessage({
              defaultMessage:
                'Your language has been successfully updated. Please refresh the page to apply the changes.',
            })
          : formatMessage(
              { defaultMessage: "{firstName} {lastName}'s information has been successfully updated." },
              { firstName: updatedUser.firstName, lastName: updatedUser.lastName },
            ),
      );

      await queryClient.invalidateQueries({
        // Invalidate all profiles to refresh useAuthorizedProfiles() too
        queryKey: [USER_PROFILE_QUERY_KEYS.PROFILE],
      });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useToggleUserDeactivation = (userId: UserProfile['id']) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: [USER_PROFILE_QUERY_KEYS.PROFILE, userId, 'toggle-deactivation'],
    mutationFn: () => UserProfileApiClient.toggleUserDeactivation(userId),
    onSuccess: async (updatedUser: UserProfile) => {
      if (updatedUser.clearedAt) {
        snackSuccess(
          formatMessage(
            { defaultMessage: '{firstName} {lastName} has been successfully deactivated.' },
            {
              firstName: updatedUser.firstName,
              lastName: updatedUser.lastName,
            },
          ),
        );
      } else {
        snackSuccess(
          formatMessage(
            { defaultMessage: '{firstName} {lastName} has been successfully reactivated.' },
            {
              firstName: updatedUser.firstName,
              lastName: updatedUser.lastName,
            },
          ),
        );
      }

      await queryClient.invalidateQueries({
        queryKey: [USER_PROFILE_QUERY_KEYS.PROFILE],
      });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useInviteUser = (user: Pick<UserProfile, 'firstName' | 'id' | 'lastName'>) => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();
  const [isSuccess, setSuccess] = useFeedback();

  const mutation = useMutation({
    mutationKey: [USER_PROFILE_QUERY_KEYS.PROFILE, user.id, 'invitationSentAt'],
    mutationFn: () => UserProfileApiClient.inviteUser(user.id),
    onSuccess: async () => {
      setSuccess(true);
      snackSuccess(
        formatMessage(
          { defaultMessage: 'Invitation has been successfully sent to {firstName} {lastName}' },
          { firstName: user.firstName, lastName: user.lastName },
        ),
      );
      await queryClient.invalidateQueries({
        queryKey: [USER_PROFILE_QUERY_KEYS.PROFILE],
      });
    },
    onError: (e) => {
      snackError(
        formatMessage(
          {
            defaultMessage:
              'Invitation could not be sent to {firstName} {lastName} due to: {message}. Please try again',
          },
          { firstName: user.firstName, lastName: user.lastName, message: toError(e).message },
        ),
      );
    },
  });

  return { ...mutation, isSuccess };
};

export const useInviteUsers = (userIds: string[]) => {
  const queryClient = useQueryClient();
  const { snackSuccess } = useSnackbars();
  const { formatMessage } = useIntl();
  const [isSuccess, setSuccess] = useFeedback();

  const mutation = useMutation({
    mutationKey: [USER_PROFILE_QUERY_KEYS.PROFILE, 'invitationSentAt', { userIds }],
    mutationFn: () => UserProfileApiClient.inviteUsers(userIds),
    onSuccess: async () => {
      setSuccess(true);
      snackSuccess(
        formatMessage(
          {
            defaultMessage: 'Invitation has been successfully sent to {count, plural, one {# user} other {# users}}',
          },
          { count: userIds.length },
        ),
      );
      await queryClient.invalidateQueries({
        queryKey: [USER_PROFILE_QUERY_KEYS.PROFILE],
      });
    },
    onError: async (error: unknown) => {
      await queryClient.invalidateQueries({
        queryKey: [USER_PROFILE_QUERY_KEYS.PROFILE],
      });

      if (!UserProfileApiClient.isBulkInvitationError(error)) {
        return;
      }

      const { errors } = error.response.data;

      snackSuccess(
        formatMessage(
          {
            defaultMessage: 'Invitation has been successfully sent to {count, plural, one {# user} other {# users}}',
          },
          { count: userIds.length - errors.length },
        ),
      );
    },
  });

  return { ...mutation, isSuccess };
};

export const useInviteUsersState = () =>
  last(
    useMutationState({
      filters: {
        mutationKey: [USER_PROFILE_QUERY_KEYS.PROFILE, 'invitationSentAt'],
      },
      select: (mutation) => mutation.state,
    }).toSorted((a, b) => /* ASC */ a.submittedAt - b.submittedAt),
  );

export const useIsUpdatingUserInfo = () =>
  useIsMutating({
    mutationKey: [USER_PROFILE_QUERY_KEYS.PROFILE],
  }) > 0;

export const useIsFetchingUserProfile = (userId?: UserProfile['id']) =>
  useIsFetching({
    queryKey: [USER_PROFILE_QUERY_KEYS.PROFILE, userId],
  }) > 0;
