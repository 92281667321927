import { css } from '@emotion/react';
import { capitalize } from 'lodash';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type Calculation, formatDate } from '@amalia/core/types';
import { AlertBanner, AlertBannerVariant } from '@amalia/design-system/components';

interface CalculationErrorProps {
  readonly calculation: Calculation;
  readonly showDebug?: boolean;
}

export const CalculationError = memo(function CalculationError({ calculation, showDebug }: CalculationErrorProps) {
  return (
    <AlertBanner
      inline
      withBorder
      variant={AlertBannerVariant.ERROR}
    >
      <p>
        <FormattedMessage
          defaultMessage="An error happened during the last calculation / {calculationDate} / {calculationError}"
          values={{
            calculationDate: formatDate(calculation.createdAt, 'lll'),
            calculationError: (
              <em>
                {calculation.error || (
                  <FormattedMessage defaultMessage="No error to display, please contact your administrator." />
                )}
              </em>
            ),
          }}
        />
      </p>

      {!!showDebug && (
        <p
          css={css`
            list-style: none;
            margin-top: 8px;
          `}
        >
          {Object.entries(calculation.errorMetadata || {})
            .filter(([k]) => k !== 'message')
            .map(([k, v], index, elts) => (
              <span key={k}>
                {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                <u>{capitalize(k)}</u>: {v}
                {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                {index + 1 !== elts.length && ' / '}
              </span>
            ))}
        </p>
      )}
    </AlertBanner>
  );
});
