import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { keyBy } from 'lodash';
import { useIntl } from 'react-intl';

import { type VariablesMap, type VariableObjectsEnum } from '@amalia/amalia-lang/tokens/types';
import { useSnackbars } from '@amalia/design-system/components';
import { VariablesApiClient } from '@amalia/payout-definition/api-client';

import { VARIABLE_QUERY_KEYS } from './queries.keys';

export const useFieldsListByObjectId = (customDefinitionObjectId: string) =>
  useQuery({
    queryKey: [VARIABLE_QUERY_KEYS.VARIABLE, 'listByCustomObjectId', customDefinitionObjectId],
    queryFn: () => VariablesApiClient.getByObjectId(customDefinitionObjectId),
    placeholderData: [],
    enabled: !!customDefinitionObjectId,
  });

export const useVariables = (filters?: { types?: VariableObjectsEnum[]; userIds?: string[]; planIds?: string[] }) =>
  useQuery({
    queryKey: [VARIABLE_QUERY_KEYS.VARIABLE, 'list', filters],
    queryFn: () => VariablesApiClient.list(filters?.types, filters?.userIds, filters?.planIds),
  });

export const useVariablesMap = (...args: Parameters<typeof useVariables>) => {
  const { data, ...rest } = useVariables(...args);
  return { data: data ? (keyBy(data, 'id') satisfies VariablesMap as VariablesMap) : undefined, ...rest };
};

export const useDuplicateVariablesInNewContext = () => {
  const queryClient = useQueryClient();
  const { snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationFn: (args: Parameters<typeof VariablesApiClient.duplicateInNewContext>) =>
      VariablesApiClient.duplicateInNewContext(...args),

    onSuccess: async ({ isErrorWhileDuplicating }) => {
      if (isErrorWhileDuplicating) {
        snackError(
          formatMessage({
            defaultMessage: 'Error while importing variables. Variables with the same name already exist.',
          }),
        );
      }

      await queryClient.invalidateQueries({ queryKey: [VARIABLE_QUERY_KEYS.VARIABLE, 'list'] });
    },
  });
};
