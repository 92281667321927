import { memo } from 'react';
import { generatePath, Navigate } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';

export const HomeRedirectView = memo(function HomeRedirectView() {
  const ability = useAbilityContext();

  if (ability.can(ActionsEnum.view, SubjectsEnum.RepHome)) {
    return (
      <Navigate
        replace
        to={generatePath(routes.HOME)}
      />
    );
  }

  return (
    <Navigate
      replace
      to={generatePath(routes.STATEMENTS)}
    />
  );
});
