import { extractStatementWorkflowSteps, type Statement } from '@amalia/core/types';
import { useWorkflowsMap } from '@amalia/payout-collaboration/workflows/state';
import { type WorkflowStatementState } from '@amalia/payout-collaboration/workflows/types';

export const useWorkflowSteps = (
  statement?: Statement,
): { steps: Partial<WorkflowStatementState>[]; currentStepNumber: number } => {
  const { data: workflowMap, isLoading } = useWorkflowsMap();

  if (isLoading || !statement) {
    return { steps: [], currentStepNumber: 0 };
  }

  return extractStatementWorkflowSteps(statement, workflowMap);
};
