import { Menu, MenuItem, MenuList } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconCirclePlus } from '@tabler/icons-react';
import { sort } from 'd3';
import { entries, isEmpty } from 'lodash';
import { memo, useState, useCallback, type MouseEvent, useMemo, Fragment } from 'react';
import { useIntl } from 'react-intl';

import { IconButton } from '@amalia/design-system/components';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';

import { type FilterItem } from './FilterBarItem';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  menuButton: {
    transform: 'translate(0, 0.5rem)',
    '& .MuiMenu-list, .MuiMenu-paper': {
      boxShadow: '0px 0px 12px 0px  rgba(0, 0, 0, 0.1)',
    },
    '.MuiMenu-paper': {
      width: 250,
      height: 300,
      maxHeight: 'calc(100% - 10px)',
    },
  },

  addbuttonFilterStyle: {
    backgroundColor: theme.palette.grey[100],
    color: `${theme.palette.grey[700]}`,
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
      color: `${theme.palette.grey[700]}`,
    },
    '&.MuiIconButton-root': {
      padding: theme.spacing(0.2),
    },
  },
}));

export interface AddFilterMenuProps {
  readonly activeFilters: Record<string, FilterItem>;
  readonly additionalFilters: Record<string, FilterItem>;
  readonly onAddNewFilter?: (filterKey: string, additionalFilter: FilterItem) => void;
}

export const AddFilterMenu = memo(function AddFilterMenu({
  activeFilters,
  additionalFilters,
  onAddNewFilter,
}: AddFilterMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const handleClose = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback((event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  }, []);

  const inactiveFilters = useMemo(
    () => sort(entries(additionalFilters).filter(([k]) => !activeFilters[k])),
    [activeFilters, additionalFilters],
  );

  return (
    !isEmpty(additionalFilters) && (
      <Fragment>
        <IconButton
          icon={<IconCirclePlus />}
          id="filterbutton"
          label={formatMessage({ defaultMessage: 'Add a filter' })}
          onClick={handleClick}
        />
        <Menu
          anchorEl={anchorEl}
          className={classes.menuButton}
          elevation={0}
          open={open}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handleClose}
        >
          <MenuList id="filters">
            {inactiveFilters.map(([key, item]) => (
              <MenuItem
                key={key}
                value={item.label}
                onClick={() => onAddNewFilter(key, item)}
              >
                {item.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Fragment>
    )
  );
});
