import { type FormikValues } from 'formik';
import { Fragment, type ReactNode, memo } from 'react';

import { type UseFormikAutosaveOptions, useFormikAutosave } from '../../hooks/use-formik-autosave/useFormikAutosave';

export type FormikAutosaveProps<TValues extends FormikValues = FormikValues> = UseFormikAutosaveOptions<TValues> & {
  /** Children if wrapping content. */
  readonly children?: ReactNode;
};

const FormikAutosaveBase = function FormikAutosave<TValues extends FormikValues = FormikValues>({
  onSave,
  timeoutMs = undefined,
  children = undefined,
}: FormikAutosaveProps<TValues>) {
  useFormikAutosave({ onSave, timeoutMs });

  return <Fragment>{children}</Fragment>;
};

export const FormikAutosave = memo(FormikAutosaveBase) as typeof FormikAutosaveBase;
