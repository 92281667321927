import { type SubmissionError } from '../errors/submissionError';

export enum FormikStatusType {
  SUBMISSION_ERROR = 'SUBMISSION_ERROR',
}

export type FormikStatusSubmissionError = {
  type: FormikStatusType.SUBMISSION_ERROR;
  error: SubmissionError;
};

// Union of all possible formik statuses.
// For now there is only one.
export type FormikStatus = FormikStatusSubmissionError;
