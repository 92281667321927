import { useQuery } from '@tanstack/react-query';

import { type ColumnSortingState } from '@amalia/design-system/components';

import { StatementDatasetsApiClient } from '../api-client/statement-datasets.api-client';

type UseDatasetProps = {
  statementId: string;
  datasetId: string;
  currentPage: number;
  searchValue?: string;
  pageSize: number;
  forecasted: boolean;
  columnSorting: ColumnSortingState;
};

export enum STATEMENT_DATASET_QUERY_KEYS {
  STATEMENT_DATASET = 'statement-dataset',
}

export const useStatementDataset = ({
  statementId,
  datasetId,
  currentPage,
  searchValue,
  pageSize,
  forecasted,
  columnSorting,
}: UseDatasetProps) =>
  useQuery({
    queryKey: [
      STATEMENT_DATASET_QUERY_KEYS.STATEMENT_DATASET,
      statementId,
      datasetId,
      currentPage,
      searchValue,
      pageSize,
      forecasted,
      columnSorting,
    ],
    queryFn: () =>
      StatementDatasetsApiClient.fetchPaginatedDatasetRows(
        statementId,
        datasetId,
        {
          // Datagrid counts pages starting at 0, but API starts at 1.
          page: currentPage + 1,
          search: searchValue !== '' ? searchValue : undefined,
          limit: pageSize,
          sort: columnSorting[0]?.id,
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          desc: columnSorting[0]?.direction ? columnSorting[0].direction === 'desc' : undefined,
        },
        forecasted,
      ),
    enabled: !!statementId && !!datasetId,
  });
