import { useMemo } from 'react';

import { fuzzyFilter } from '@amalia/ext/filters';

import { type DropdownListItemGroup, type DropdownListItem, isDropdownListItemGroup } from '../DropdownList.types';

const defaultFilterOption = (item: DropdownListItem, filter: string): boolean => {
  const filterLabel = item.filterLabel ?? item.label;
  return typeof filterLabel === 'string' && fuzzyFilter(filterLabel, filter);
};

/**
 * Filter items with the current filter text and return the filtered items by keeping the same items/groups structure.
 * Empty groups are deleted.
 */
export const useFilterItems = (
  itemsAndGroups: (DropdownListItem | DropdownListItemGroup)[],
  filter: string,
  filterItem: (item: DropdownListItem, filter: string) => boolean = defaultFilterOption,
): (DropdownListItem | DropdownListItemGroup)[] =>
  useMemo(
    () =>
      (filter
        ? itemsAndGroups.reduce(
            (acc, itemOrGroup) => {
              if (isDropdownListItemGroup(itemOrGroup)) {
                acc.push({
                  ...itemOrGroup,
                  items: itemOrGroup.items.filter((option) => filterItem(option, filter)),
                });
              } else if (filterItem(itemOrGroup, filter)) {
                acc.push(itemOrGroup);
              }

              return acc;
            },
            [] as typeof itemsAndGroups,
          )
        : itemsAndGroups
      ).filter((optionOrGroup) => !isDropdownListItemGroup(optionOrGroup) || !!optionOrGroup.items.length),
    [filter, filterItem, itemsAndGroups],
  );
