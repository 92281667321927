import { ClassNames } from '@emotion/react';
import clsx from 'clsx';
import { type ReactNode, memo, cloneElement, type ComponentPropsWithoutRef } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { type TablerIconElement } from '../../../general/icons/types';
import { TextOverflow } from '../../../general/text-overflow/TextOverflow';
import { Typography } from '../../../general/typography/Typography';

import * as styles from './BreadcrumbsTextItem.styles';

export type BreadcrumbsTextItemProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Optional left icon. */
      icon?: TablerIconElement;
      /** Label. */
      children: ReactNode;
    },
  ]
>;

export const BreadcrumbsTextItem = memo(function BreadcrumbsTextItem({
  icon = undefined,
  children = null,
  ...props
}: BreadcrumbsTextItemProps) {
  return (
    <div
      {...props}
      css={styles.breadcrumbsTextItem}
    >
      {!!icon && (
        <ClassNames>
          {({ css }) =>
            cloneElement(icon, {
              color: 'currentColor',
              size: 16,
              className: clsx(
                icon.props.className,
                css`
                  flex: none;
                `,
              ),
            })
          }
        </ClassNames>
      )}

      <Typography
        as={TextOverflow}
        variant={Typography.Variant.BODY_BASE_MEDIUM}
      >
        {children}
      </Typography>
    </div>
  );
});
