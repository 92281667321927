import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import { PlanSyncActions, editCurrentPlan, useThunkDispatch } from '@amalia/frontend/web-data-layers';
import { PlansApiClient } from '@amalia/payout-definition/api-client';
import { type PatchPlanVisibility, type Plan } from '@amalia/payout-definition/plans/types';

import { PLAN_QUERY_KEYS } from './queries.keys';

export const usePlansList = (
  filters?: Parameters<(typeof PlansApiClient)['list']>[0],
  options?: { enabled?: boolean },
) =>
  useQuery({
    queryKey: [PLAN_QUERY_KEYS.PLAN, 'list', filters],
    queryFn: () => PlansApiClient.list(filters),
    placeholderData: [],
    ...options,
  });

export const usePlanTemplate = (planId?: Plan['id']) =>
  useQuery({
    queryKey: [PLAN_QUERY_KEYS.PLAN, 'template', planId],
    queryFn: () => PlansApiClient.getPlanTemplate(planId!),
    enabled: !!planId,
  });

export const usePatchPlanVisibility = (planId: string) => {
  const queryClient = useQueryClient();
  const dispatch = useThunkDispatch();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: [PLAN_QUERY_KEYS.PLAN, planId],
    mutationFn: (patchPlanVisibility: PatchPlanVisibility) =>
      PlansApiClient.patchPlanVisibility(planId, patchPlanVisibility),
    onSuccess: async (plan: Plan) => {
      // TODO: to remove when the designer is out of redux
      await dispatch(editCurrentPlan(PlanSyncActions.editVisibility(planId, plan.isHidden)));

      snackSuccess(
        plan.isHidden
          ? formatMessage({ defaultMessage: 'Plan has been successfully hidden.' })
          : formatMessage({ defaultMessage: 'Plan has been successfully published.' }),
      );

      await queryClient.invalidateQueries({ queryKey: [PLAN_QUERY_KEYS.PLAN, planId] });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};
