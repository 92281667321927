import { css } from '@emotion/react';
import { type ForwardedRef, forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, type ButtonProps } from '../../../general/button/Button';

export type DropdownListShowMoreButtonProps = Omit<ButtonProps, 'children' | 'variant'> & {
  readonly count: number;
  readonly total: number;
};

export const DropdownListShowMoreButton = forwardRef(function ShowMoreButton(
  { count, total, ...props }: DropdownListShowMoreButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <Button
      {...props}
      ref={ref}
      variant={Button.Variant.PRIMARY_TEXT}
      css={css`
        margin-top: 1px;
        width: 100%;
        justify-content: center;
      `}
    >
      <FormattedMessage
        defaultMessage="Show more ({current, number}/{total, number})"
        values={{
          current: count,
          total,
        }}
      />
    </Button>
  );
});
