import { css, useTheme } from '@emotion/react';
import { Skeleton } from '@mui/material';
import { IconAward } from '@tabler/icons-react';
import { mapValues } from 'lodash';
import { memo, useMemo } from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { formatCurrencyAmount, formatUserFullName } from '@amalia/core/types';
import { Group, Stack, Typography } from '@amalia/design-system/components';
import { amaliaTheme, colors as colorPalette } from '@amalia/ext/mui/theme';
import { Avatar } from '@amalia/lib-ui';

import { type DataLeaderboard, getUserCurrency, sumTotalForUser } from '../../../utils/dashboard.utils';

type LeaderBoardRowProps = {
  readonly idx: number;
  readonly max?: number;
  readonly row?: DataLeaderboard;
  readonly colors?: string[];
  readonly loading?: boolean;
  readonly rowsCount?: number;
};

export const LeaderBoardRow = memo(function LeaderBoardRow({
  idx,
  max,
  row,
  colors,
  loading,
  rowsCount = 0,
}: LeaderBoardRowProps) {
  const theme = useTheme();

  const amount = useMemo(() => sumTotalForUser(row), [row]);
  const currencySymbol = useMemo(() => getUserCurrency(row), [row]);
  return (
    <Stack
      key={`rank=${idx + 1}`}
      gap={4}
      id={`leaderboard-rank-${idx + 1}`}
      css={css`
        height: 50px;
        width: 100%;
      `}
    >
      <Group
        css={css`
          padding-left: 4px;
          width: 100%;
        `}
      >
        <Group
          align="center"
          css={css`
            width: 100%;
          `}
        >
          <Group
            align="center"
            gap={8}
            css={css`
              position: relative;
              width: 100%;
            `}
          >
            {!loading ? (
              <Avatar
                style={{ height: amaliaTheme.spacing(3.1), width: amaliaTheme.spacing(3.1) }}
                user={row.user}
              />
            ) : (
              <Skeleton
                style={{ height: amaliaTheme.spacing(3.1), width: amaliaTheme.spacing(3.1) }}
                variant="circular"
              />
            )}

            <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
              {loading ? (
                <Skeleton
                  style={{ width: amaliaTheme.spacing(10) }}
                  variant="text"
                />
              ) : (
                formatUserFullName(row.user)
              )}
            </Typography>

            {idx === 0 && amount > 0 && rowsCount > 1 && (
              <IconAward
                color={theme.ds.colors.primary[400]}
                size={16}
              />
            )}
          </Group>

          <Typography
            style={{ justifyContent: 'flex-end' }}
            variant={Typography.Variant.BODY_BASE_MEDIUM}
          >
            {loading ? (
              <Skeleton
                style={{ width: amaliaTheme.spacing(10) }}
                variant="text"
              />
            ) : (
              formatCurrencyAmount({
                amount,
                currencySymbol,
              })
            )}
          </Typography>
        </Group>
      </Group>

      {loading ? (
        <Skeleton
          style={{ width: `calc(${100 - idx * 6}%)` }}
          variant="text"
        />
      ) : (
        <ResponsiveContainer
          height="100%"
          width="100%"
        >
          <BarChart
            height={300}
            layout="vertical"
            maxBarSize={max}
            width={500}
            data={[
              {
                ...mapValues(row.stats, (s) => s.value),
                name: row.user.lastName,
              },
            ]}
          >
            <XAxis
              hide
              domain={[0, max]}
              type="number"
            />
            <YAxis
              hide
              dataKey="name"
              scale="band"
              type="category"
            />

            {Object.keys(row.stats).map((bar, index) => (
              <Bar
                key={index}
                {...(index === 0 ? { background: { fill: colorPalette['grey-100'] } } : {})}
                barSize={9}
                dataKey={bar}
                fill={colors[index % colors.length]}
                radius={Object.keys(row.stats).length === index + 1 ? 10 : 0}
                stackId="1"
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      )}
    </Stack>
  );
});
