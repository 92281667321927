import { defineMessages } from 'react-intl';

import { PlanRuleCategoryColor } from '@amalia/payout-definition/plans/types';

export const ruleCategoryColorMessages = defineMessages<PlanRuleCategoryColor>({
  [PlanRuleCategoryColor.BLUE]: { defaultMessage: 'Blue' },
  [PlanRuleCategoryColor.BROWN]: { defaultMessage: 'Brown' },
  [PlanRuleCategoryColor.CYAN]: { defaultMessage: 'Cyan' },
  [PlanRuleCategoryColor.GREEN]: { defaultMessage: 'Green' },
  [PlanRuleCategoryColor.GREY]: { defaultMessage: 'Grey' },
  [PlanRuleCategoryColor.MAGENTA]: { defaultMessage: 'Magenta' },
  [PlanRuleCategoryColor.ORANGE]: { defaultMessage: 'Orange' },
  [PlanRuleCategoryColor.RED]: { defaultMessage: 'Red' },
  [PlanRuleCategoryColor.YELLOW]: { defaultMessage: 'Yellow' },
} as const);
