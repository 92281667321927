import { get, noop } from 'lodash';
import { Fragment, memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type FormulaBuilderFormulaBlockForm } from '@amalia/amalia-lang/formula/form/types';
import { Tooltip } from '@amalia/design-system/components';
import { Portal } from '@amalia/ext/react/components';

import { useFormulaBuilderContext } from '../formula-builder/FormulaBuilder.context';
import { FormulaConditionTag, type FormulaConditionTagProps } from '../formula-condition-tag/FormulaConditionTag';

import { FormulaPortal, type FormulaPortalProps } from './formula-portal/FormulaPortal';

export type FormulaConditionTagFormulaProps = {
  readonly condition: FormulaBuilderFormulaBlockForm;
  readonly path: string;
  readonly onChange?: FormulaPortalProps['onChange'];
  readonly onDelete?: FormulaConditionTagProps<FormulaBuilderFormulaBlockForm>['onDelete'];
};

export const FormulaConditionTagFormula = memo(function FormulaConditionTagFormula({
  condition,
  path,
  onChange = noop,
  onDelete = undefined,
}: FormulaConditionTagFormulaProps) {
  const { portalElement, activeConditionId, errors: builderErrors } = useFormulaBuilderContext();

  const isActive = condition.id === activeConditionId;

  const errors: string[] = useMemo(
    () =>
      [
        get(builderErrors, `${path}.label`) as string | undefined,
        get(builderErrors, `${path}.formula`) as string | undefined,
      ].filter(Boolean),
    [builderErrors, path],
  );

  return (
    <Fragment>
      <FormulaConditionTag
        condition={condition}
        errors={errors}
        label={
          <Tooltip content={<FormattedMessage defaultMessage="Click to edit formula" />}>
            <span>{condition.label.trim() || <FormattedMessage defaultMessage="Custom condition" />}</span>
          </Tooltip>
        }
        onDelete={onDelete}
      />

      {isActive ? (
        <Portal element={portalElement}>
          <FormulaPortal
            condition={condition}
            path={path}
            onChange={onChange}
          />
        </Portal>
      ) : null}
    </Fragment>
  );
});
