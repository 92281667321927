import { memo } from 'react';

import { type ComputedHighlightedKpi, HighlightedKpiIdentifier } from '@amalia/payout-definition/plans/types';

import { KpisTooltipKpiBounds } from './kpis-tooltip-kpi-bounds/KpisTooltipKpiBounds';
import { KpisTooltipKpiTitleAndValue } from './kpis-tooltip-kpi-title-and-value/KpisTooltipKpiTitleAndValue';
import * as styles from './StatementKpisTooltip.styles';

export type StatementKpisTooltipProps = {
  readonly kpis?: Record<HighlightedKpiIdentifier, ComputedHighlightedKpi>;
  readonly isForecast?: boolean;
};

export const StatementKpisTooltip = memo(function StatementKpisTooltip({
  kpis = undefined,
  isForecast = false,
}: StatementKpisTooltipProps) {
  return (
    <div css={styles.statementKpisTooltip}>
      <div css={styles.statementKpisTooltipRow}>
        <KpisTooltipKpiTitleAndValue
          isForecast={isForecast}
          kpi={kpis?.primary}
          kpiId={HighlightedKpiIdentifier.PRIMARY}
        />

        <KpisTooltipKpiTitleAndValue
          isForecast={isForecast}
          kpi={kpis?.secondary}
          kpiId={HighlightedKpiIdentifier.SECONDARY}
        />
      </div>

      <div css={styles.statementKpisTooltipRow}>
        <KpisTooltipKpiBounds kpi={kpis?.primary} />
        <KpisTooltipKpiBounds kpi={kpis?.secondary} />
      </div>
    </div>
  );
});
