import { memo, type ReactNode } from 'react';

import { Group } from '@amalia/design-system/components';

import { TextType, Text } from '../typography';

interface ContextMenuElementProps {
  readonly icon?: ReactNode;
  readonly text: string;
}

export const ContextMenuElement = memo(function ContextMenuElement({ icon, text }: ContextMenuElementProps) {
  return (
    <Group
      align="center"
      gap={8}
    >
      {icon}

      <Text type={TextType.MENU_ACTION}>{text}</Text>
    </Group>
  );
});

ContextMenuElement.displayName = 'ContextMenuElement';
