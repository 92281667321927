import { memo } from 'react';

import { type CellSelectOption } from '@amalia/design-system/components';
import { type UserRole } from '@amalia/tenants/users/types';

import { UserRoleBadge } from '../../../roles';

export const SelectedRoleOption = memo(function SelectedRoleOption({
  option,
}: {
  readonly option: CellSelectOption<UserRole>;
}) {
  return <UserRoleBadge role={option.value} />;
});
