import { Formik } from 'formik';
import { memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import { Group, Modal } from '@amalia/design-system/components';

import { type FilterOverwriteRemoveCreationRequestDetails } from './overwrite.types';
import { OVERWRITE_CONTEXT } from './OverwriteModal';
import { OverwriteOptions } from './OverwriteOption';
import { OverwriteRecapInfos } from './recapitulation/OverwriteRecapitulationInfo';

type FilterOverwriteRemoveModalProps = {
  readonly isOpen: boolean;
  readonly currentFilterOverwriteRemoveObjectDetails: FilterOverwriteRemoveCreationRequestDetails;
  readonly handleSubmit: (state: any) => void;
  readonly handleClose: () => void;
};

const initialValues = {
  isApplyToOverall: false,
};

export const FilterOverwriteRemoveModalContainer = memo(function FilterOverwriteRemoveModalContainer({
  isOpen,
  handleClose,
  currentFilterOverwriteRemoveObjectDetails,
  handleSubmit,
}: FilterOverwriteRemoveModalProps) {
  const { formatMessage } = useIntl();

  const formValidationSchema = Yup.object().shape({
    isApplyToOverall: Yup.boolean(),
  });

  const onSubmit = useCallback(
    (values: { isApplyToOverall?: boolean }) => {
      handleClose();

      const changed = {
        [currentFilterOverwriteRemoveObjectDetails.rowId]: {
          change: OVERWRITE_CONTEXT.FILTER_ROW_REMOVE,
          isApplyToOverall: values.isApplyToOverall,
          overwriteRequest: currentFilterOverwriteRemoveObjectDetails,
        },
      };

      handleSubmit({ changed });
    },
    [currentFilterOverwriteRemoveObjectDetails, handleClose, handleSubmit],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formValidationSchema}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, values, resetForm }) => (
        <Modal
          isOpen={isOpen}
          onClose={handleClose}
        >
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>
                {currentFilterOverwriteRemoveObjectDetails.definitionName ? (
                  <FormattedMessage
                    defaultMessage="Overwrite - Remove {name}"
                    values={{ name: currentFilterOverwriteRemoveObjectDetails.definitionName }}
                  />
                ) : (
                  <FormattedMessage defaultMessage="Overwrite - Remove a row" />
                )}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {/* Information recapitulation */}
              <OverwriteRecapInfos
                currentFilterOverwriteRemoveObjectDetails={currentFilterOverwriteRemoveObjectDetails}
                overwriteContext={OVERWRITE_CONTEXT.FILTER_ROW_REMOVE}
              />

              <Group justify="center">
                {/* Options (Apply to whom) */}
                <OverwriteOptions
                  isApplyToOverall={values.isApplyToOverall}
                  setIsApplyToOverall={setFieldValue}
                />
              </Group>
            </Modal.Body>
          </Modal.Content>

          <Modal.Actions>
            <Modal.CancelAction />

            <Modal.MainAction
              aria-label={formatMessage(
                { defaultMessage: 'Remove row {id}' },
                { id: currentFilterOverwriteRemoveObjectDetails.rowNameOrId },
              )}
              onClick={() => {
                onSubmit(values);
                resetForm();
              }}
            >
              <FormattedMessage defaultMessage="Apply" />
            </Modal.MainAction>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  );
});
