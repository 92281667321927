import { type ForwardedRef, forwardRef, memo } from 'react';

import { Badge, BadgeHue, type BadgeProps } from '@amalia/design-system/components';
import { type MergeAll } from '@amalia/ext/typescript';
import { type PlanCategory, PlanRuleCategoryColor } from '@amalia/payout-definition/plans/types';

import { getPlanRuleCategoryColor } from '../../helpers/getPlanRuleCategoryColor';

const PlanRuleCategoryColorToBadgeHue: Record<PlanRuleCategoryColor, BadgeHue> = {
  [PlanRuleCategoryColor.BLUE]: BadgeHue.BLUE,
  [PlanRuleCategoryColor.BROWN]: BadgeHue.BROWN,
  [PlanRuleCategoryColor.CYAN]: BadgeHue.CYAN,
  [PlanRuleCategoryColor.GREEN]: BadgeHue.GREEN,
  [PlanRuleCategoryColor.GREY]: BadgeHue.GREY,
  [PlanRuleCategoryColor.MAGENTA]: BadgeHue.MAGENTA,
  [PlanRuleCategoryColor.ORANGE]: BadgeHue.ORANGE,
  [PlanRuleCategoryColor.RED]: BadgeHue.RED,
  [PlanRuleCategoryColor.YELLOW]: BadgeHue.YELLOW,
} as const;

export type PlanRuleCategoryBadgeProps = MergeAll<
  [
    Omit<BadgeProps, 'children' | 'variant'>,
    {
      category: PlanCategory;
    },
  ]
>;

const PlanRuleCategoryBadgeForwardRef = forwardRef(function PlanRuleCategoryBadge(
  { category, ...props }: PlanRuleCategoryBadgeProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <Badge
      {...props}
      ref={ref}
      variant={PlanRuleCategoryColorToBadgeHue[getPlanRuleCategoryColor(category)]}
    >
      {category.name}
    </Badge>
  );
});

export const PlanRuleCategoryBadge = Object.assign(memo(PlanRuleCategoryBadgeForwardRef), {
  Size: Badge.Size,
});
