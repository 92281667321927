import { css } from '@emotion/react';
import { type ComponentPropsWithoutRef, cloneElement, forwardRef, type ForwardedRef } from 'react';

import { type TablerIconElement } from '@amalia/design-system/components';
import { type MergeAll } from '@amalia/ext/typescript';

export type IndicatorIconProps = MergeAll<
  [
    Omit<ComponentPropsWithoutRef<'div'>, 'children'>,
    {
      icon: TablerIconElement;
    },
  ]
>;

export const IndicatorIcon = forwardRef(function IndicatorIcon(
  { icon, ...props }: IndicatorIconProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      {...props}
      ref={ref}
      css={(theme) => css`
        display: flex;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        border-radius: ${theme.ds.borderRadiuses.squared};

        &,
        > * {
          flex: none;
        }
      `}
    >
      {cloneElement(icon, {
        size: 14.4,
      })}
    </div>
  );
});
