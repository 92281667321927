export enum MessageStatus {
  CREATED = 'created',
  EDITED = 'edited',
  DELETED = 'deleted',
}

export interface MessageContent {
  type: MessageTypeEnum;
  content: string;
  userId?: string;
}

export enum MessageTypeEnum {
  text = 'text',
  mention = 'mention',
}

export interface CommentThreadMessage {
  id: string;

  // Message content.
  content: MessageContent[];

  // User that wrote the message.
  authorId: string;

  // created date of the message.
  createdAt: Date;

  // last updated date of the message.
  updatedAt: Date;

  status: MessageStatus;
}
