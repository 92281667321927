import { type Transaction } from '@tiptap/pm/state';
import escapeStringRegexp from 'escape-string-regexp';
import { useMemo } from 'react';

import {
  FORMULA_TOKEN_NODE_NAME,
  generateFormulaTokenNodeHtml,
} from '../../components/formula-editor-content/token-extension/TokenExtension';
import { type FormulaEditorToken } from '../../types/formulaEditorToken';

export const getFormulaEditorTextContent = (transaction: Transaction) => {
  let textContent = '';
  transaction.doc.descendants((node) => {
    if (node.isText) {
      textContent += node.textContent;
    } else if (node.type.name === FORMULA_TOKEN_NODE_NAME) {
      textContent += node.attrs.formula;
    }
  });
  return textContent;
};

/**
 * This hook is used to generate tiptap formula editor content: it replaces the matched formula tokens in the formula by their html representation.
 * Example: "SUM(1, 2)" will be replaced by "<span class="formula-token">SUM(1, 2)</span>"
 * @param formula
 * @param tokens
 * @param allObjects
 * @param enableAutoComplete
 */
export const useFormulaEditorContent = (formula: string, tokens: FormulaEditorToken[]) =>
  useMemo(() => {
    if (!formula) {
      return '';
    }

    return tokens.reduce(
      (replacedFormula, token) =>
        replacedFormula.replace(
          new RegExp(`(?<!\\w|\\.)(${escapeStringRegexp(token.formula)})(?!\\w|\\.)`, 'gu'),
          generateFormulaTokenNodeHtml(token.formula),
        ),
      formula,
    );
  }, [formula, tokens]);
