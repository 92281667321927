import { useCallback, useState, type MouseEvent } from 'react';

import { eventStopPropagationAndPreventDefault } from '../../../utils/common/eventHelpers';

export const useContextMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    eventStopPropagationAndPreventDefault(event);
  }, []);

  const handleCloseMenu = useCallback((event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(null);
    eventStopPropagationAndPreventDefault(event);
  }, []);

  return {
    anchorEl,
    isOpen: !!anchorEl,
    handleOpenMenu,
    handleCloseMenu,
  };
};
