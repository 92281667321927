import { type Theme, css } from '@emotion/react';

export const HAS_ERROR_CLASSNAME = 'hasError';

export const formulaEditor = (theme: Theme) => css`
  &.${HAS_ERROR_CLASSNAME} {
    > .ProseMirror {
      &-focused {
        box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.danger[100])};
      }

      &[contenteditable='true'] {
        border-color: ${theme.ds.colors.danger[500]};
      }
    }
  }

  > .ProseMirror {
    ${theme.ds.typographies.bodyBaseMedium};
    line-height: 25px;

    border-radius: ${theme.ds.borderRadiuses.squared};
    border: 1px solid ${theme.ds.colors.gray[100]};
    box-shadow: ${theme.ds.shadows.soft};

    outline: none;
    width: 100%;
    height: 100%;

    transition: ${theme.ds.transitions.default()};

    color: ${theme.ds.colors.gray[900]};
    background-color: ${theme.ds.colors.gray[0]};

    padding: 11px 15px;

    &-focused {
      box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.primary[50])};
      border-color: ${theme.ds.colors.primary[200]};
    }

    span.suggestion {
      padding: 4px;
      border-radius: ${theme.ds.borderRadiuses.squared};
      background-color: ${theme.ds.colors.gray[50]};
      color: ${theme.ds.colors.gray[400]};
    }

    p.is-editor-empty:first-child::before {
      color: ${theme.ds.colors.gray[400]};
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }

    &:not([contenteditable='true']) {
      color: ${theme.ds.colors.gray[300]};
      background-color: ${theme.ds.colors.gray[50]};

      p.is-editor-empty:first-child::before {
        color: ${theme.ds.colors.gray[200]};
      }
    }
  }
`;
