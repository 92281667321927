import { type ForwardedRef, forwardRef, memo } from 'react';

import { IconButton, type IconButtonProps } from '../../../general/icon-button/IconButton';
import { useDropdownContext } from '../Dropdown.context';

export type DropdownIconButtonProps = IconButtonProps;

const DropdownIconButtonForwardRef = forwardRef(function DropdownIconButton(
  props: DropdownIconButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { isOpen } = useDropdownContext();

  return (
    <IconButton
      {...props}
      ref={ref}
      isActive={props.isActive ?? isOpen}
    />
  );
});

export const DropdownIconButton = Object.assign(memo(DropdownIconButtonForwardRef), {
  Size: IconButton.Size,
  Variant: IconButton.Variant,
});
