import { css } from '@emotion/react';
import { IconMessage2 } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedList, FormattedMessage, useIntl } from 'react-intl';

import { type Statement, type DatasetRow } from '@amalia/core/types';
import { Tooltip } from '@amalia/design-system/components';
import { assert } from '@amalia/ext/typescript';
import { type Dataset } from '@amalia/payout-calculation/types';
import { StatementThreadScopesType, type StatementThread } from '@amalia/payout-collaboration/comments/types';
import { type PlanRule } from '@amalia/payout-definition/plans/types';

import { type ComputedPlanRuleFieldsToDisplayWithSubTitle } from '../../../RowsTable/RowsTable';
import { getThreadsOnRow } from '../../../RowsTable/RowsTable.utils';
import { IndicatorIcon } from '../indicator-icon/IndicatorIcon';

export type RowThreadsIndicatorProps = {
  readonly datasetRow: DatasetRow;
  readonly dataset: Dataset;
  readonly fields: ComputedPlanRuleFieldsToDisplayWithSubTitle[];
  readonly statementThreads: Record<string, StatementThread>;
  readonly rule?: PlanRule;
  readonly statement?: Statement;
};

export const RowThreadsIndicator = memo(function RowThreadsIndicator({
  datasetRow,
  dataset,
  fields,
  statementThreads,
  rule,
  statement,
}: RowThreadsIndicatorProps) {
  const { formatMessage } = useIntl();

  const unreviewedThreadFieldNames = getThreadsOnRow(statementThreads, datasetRow, rule?.id, statement, dataset)
    .filter(({ thread }) => !thread.isReviewed)
    .map(({ computedScope }) => {
      assert(
        computedScope?.type === StatementThreadScopesType.OBJECT,
        'Threads should have been filtered on object scope.',
      );
      return fields.find((field) => field.name === computedScope.field)?.label;
    })
    .filter(Boolean);

  return (
    !!unreviewedThreadFieldNames.length && (
      <Tooltip
        content={
          unreviewedThreadFieldNames.length === 1 ? (
            <FormattedMessage
              defaultMessage="There is an open thread on the “{columnName}” column of this record."
              values={{ columnName: unreviewedThreadFieldNames[0] }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="There are open threads on the {columnNames} columns of this record."
              values={{
                columnNames: (
                  <FormattedList
                    type="conjunction"
                    value={unreviewedThreadFieldNames.map((fieldName) =>
                      formatMessage({ defaultMessage: '“{columnName}”' }, { columnName: fieldName }),
                    )}
                  />
                ),
              }}
            />
          )
        }
      >
        <IndicatorIcon
          icon={<IconMessage2 />}
          css={(theme) => css`
            background-color: ${theme.ds.colors.primary[50]};
            color: ${theme.ds.colors.primary[500]};
          `}
        />
      </Tooltip>
    )
  );
});
