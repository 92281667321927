import { http } from '@amalia/core/http/client';
import { type Currency } from '@amalia/tenants/companies/currency-rates/types';

const apiEndpoint = '/currencies';

export class CurrenciesApiClient {
  public static async getCurrencies(year: string): Promise<Currency[]> {
    const { data } = await http.get<Currency[]>(`${apiEndpoint}/?year=${year}`);
    return data.sort((c1, c2) => c1.date - c2.date);
  }
}
