import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.LINEAR, AmaliaFunctionCategory.INDICES);

func.nbParamsRequired = 2;

func.description = 'Compare a parameter to tiers in a table and return a calculated value linear to the tier';

func.params = [
  { name: 'parameter', description: 'Variables, fields, properties etc.' },
  {
    name: 'table',
    description:
      'Table containing (min and max) and corresponding values. The third column defines the return value corresponding to the lower bound of the interval of the row.',
  },
];

func.examples = [
  {
    desc: 'Returns 0.6 since 0.55 falls between 0.5 and 0.6 where the lower bound is 0.5 and the upper bound is 0.7',
    formula: 'LINEAR(0.55, [[0, 0.3, 0],[0.3, 0.5, 0],[0.5, 0.6, 0.5],[0.6, 0.7, 0.7]])' as AmaliaFormula,
  },
  {
    desc: 'Returns the corresponding linear value of the interval of Target Reach according to the linear curve.',
    formula: 'LINEAR(statement.targetReach, statement.payoutGrid)' as AmaliaFormula,
  },
];

func.exec = (tierToCompare: number, table: [number, number, number][]): number => {
  let toReturn = 0;
  let index = 0;
  for (const tier of table) {
    if (tier[1] >= tierToCompare) {
      if (index === 0) {
        toReturn = tier[2];
      } else if (index === table.length - 1) {
        toReturn = tier[2];
      } else {
        toReturn =
          tier[2] + ((tierToCompare - tier[0]) * (table[index + 1][2] - tier[2])) / (table[index + 1][0] - tier[0]);
      }
      break;
    }
    index += 1;
  }
  return toReturn;
};

export default func;
