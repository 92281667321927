import { type AmaliaFormula, FormulaKeyword } from '@amalia/amalia-lang/formula/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';

export type FormulaKeywordBase = {
  id: FormulaKeyword;
  formula: AmaliaFormula;
  format: FormatsEnum;
};

export const FORMULA_KEYWORDS: Record<FormulaKeyword, FormulaKeywordBase> = {
  [FormulaKeyword.USER_ID]: {
    id: FormulaKeyword.USER_ID,
    format: FormatsEnum.text,
    formula: 'user.id',
  },
  [FormulaKeyword.USER_EXTERNAL_ID]: {
    id: FormulaKeyword.USER_EXTERNAL_ID,
    format: FormatsEnum.text,
    formula: 'user.externalId',
  },
  [FormulaKeyword.USER_TEAM_MEMBERS]: {
    id: FormulaKeyword.USER_TEAM_MEMBERS,
    format: FormatsEnum.table,
    formula: 'user.teamMembers',
  },
  [FormulaKeyword.USER_FIRST_NAME]: {
    id: FormulaKeyword.USER_FIRST_NAME,
    format: FormatsEnum.text,
    formula: 'user.firstName',
  },
  [FormulaKeyword.USER_LAST_NAME]: {
    id: FormulaKeyword.USER_LAST_NAME,
    format: FormatsEnum.text,
    formula: 'user.lastName',
  },
  [FormulaKeyword.USER_CURRENCY]: {
    id: FormulaKeyword.USER_CURRENCY,
    format: FormatsEnum.text,
    formula: 'user.currency',
  },
  [FormulaKeyword.USER_EMAIL]: {
    id: FormulaKeyword.USER_EMAIL,
    format: FormatsEnum.text,
    formula: 'user.email',
  },
  [FormulaKeyword.USER_HRIS_ID]: {
    id: FormulaKeyword.USER_HRIS_ID,
    format: FormatsEnum.text,
    formula: 'user.hrisId',
  },
  [FormulaKeyword.TEAM_MEMBERS]: {
    id: FormulaKeyword.TEAM_MEMBERS,
    format: FormatsEnum.table,
    formula: 'team.members',
  },
  [FormulaKeyword.TEAM_MANAGERS]: {
    id: FormulaKeyword.TEAM_MANAGERS,
    format: FormatsEnum.table,
    formula: 'team.managers',
  },
  [FormulaKeyword.TEAM_EMPLOYEES]: {
    id: FormulaKeyword.TEAM_EMPLOYEES,
    format: FormatsEnum.table,
    formula: 'team.employees',
  },
  [FormulaKeyword.TEAM_NAME]: {
    id: FormulaKeyword.TEAM_NAME,
    format: FormatsEnum.text,
    formula: 'team.name',
  },
  [FormulaKeyword.STATEMENT_PERIOD_ID]: {
    id: FormulaKeyword.STATEMENT_PERIOD_ID,
    format: FormatsEnum.text,
    formula: 'statementPeriod.id',
  },
  [FormulaKeyword.STATEMENT_PERIOD_CURRENT_DATE]: {
    id: FormulaKeyword.STATEMENT_PERIOD_CURRENT_DATE,
    format: FormatsEnum.date,
    formula: 'statementPeriod.currentDate',
  },
  [FormulaKeyword.STATEMENT_PERIOD_START_DATE]: {
    id: FormulaKeyword.STATEMENT_PERIOD_START_DATE,
    format: FormatsEnum.date,
    formula: 'statementPeriod.startDate',
  },
  [FormulaKeyword.STATEMENT_PERIOD_END_DATE]: {
    id: FormulaKeyword.STATEMENT_PERIOD_END_DATE,
    format: FormatsEnum.date,
    formula: 'statementPeriod.endDate',
  },
  [FormulaKeyword.STATEMENT_PERIOD_START_OF_MONTH]: {
    id: FormulaKeyword.STATEMENT_PERIOD_START_OF_MONTH,
    format: FormatsEnum.date,
    formula: 'statementPeriod.startOfMonth',
  },
  [FormulaKeyword.STATEMENT_PERIOD_END_OF_MONTH]: {
    id: FormulaKeyword.STATEMENT_PERIOD_END_OF_MONTH,
    format: FormatsEnum.date,
    formula: 'statementPeriod.endOfMonth',
  },
  [FormulaKeyword.STATEMENT_PERIOD_START_OF_QUARTER]: {
    id: FormulaKeyword.STATEMENT_PERIOD_START_OF_QUARTER,
    format: FormatsEnum.date,
    formula: 'statementPeriod.startOfQuarter',
  },
  [FormulaKeyword.STATEMENT_PERIOD_END_OF_QUARTER]: {
    id: FormulaKeyword.STATEMENT_PERIOD_END_OF_QUARTER,
    format: FormatsEnum.date,
    formula: 'statementPeriod.endOfQuarter',
  },
  [FormulaKeyword.STATEMENT_PERIOD_START_OF_SEMESTER]: {
    id: FormulaKeyword.STATEMENT_PERIOD_START_OF_SEMESTER,
    format: FormatsEnum.date,
    formula: 'statementPeriod.startOfSemester',
  },
  [FormulaKeyword.STATEMENT_PERIOD_END_OF_SEMESTER]: {
    id: FormulaKeyword.STATEMENT_PERIOD_END_OF_SEMESTER,
    format: FormatsEnum.date,
    formula: 'statementPeriod.endOfSemester',
  },
  [FormulaKeyword.STATEMENT_PERIOD_START_OF_YEAR]: {
    id: FormulaKeyword.STATEMENT_PERIOD_START_OF_YEAR,
    format: FormatsEnum.date,
    formula: 'statementPeriod.startOfYear',
  },
  [FormulaKeyword.STATEMENT_PERIOD_END_OF_YEAR]: {
    id: FormulaKeyword.STATEMENT_PERIOD_END_OF_YEAR,
    format: FormatsEnum.date,
    formula: 'statementPeriod.endOfYear',
  },
  [FormulaKeyword.PLAN_ASSIGNEMENT_EFFECTIVE_AS_OF]: {
    id: FormulaKeyword.PLAN_ASSIGNEMENT_EFFECTIVE_AS_OF,
    format: FormatsEnum.date,
    formula: 'planAssignement.effectiveAsOf',
  },
  [FormulaKeyword.PLAN_ASSIGNEMENT_EFFECTIVE_UNTIL]: {
    id: FormulaKeyword.PLAN_ASSIGNEMENT_EFFECTIVE_UNTIL,
    format: FormatsEnum.date,
    formula: 'planAssignement.effectiveUntil',
  },
  [FormulaKeyword.PLAN_ASSIGNEMENT_PLAN_ID]: {
    id: FormulaKeyword.PLAN_ASSIGNEMENT_PLAN_ID,
    format: FormatsEnum.text,
    formula: 'planAssignement.planId',
  },
  [FormulaKeyword.PLAN_ASSIGNEMENT_PLAN_NAME]: {
    id: FormulaKeyword.PLAN_ASSIGNEMENT_PLAN_NAME,
    format: FormatsEnum.text,
    formula: 'planAssignement.planName',
  },
  [FormulaKeyword.TEAM_ASSIGNMENT_EFFECTIVE_AS_OF]: {
    id: FormulaKeyword.TEAM_ASSIGNMENT_EFFECTIVE_AS_OF,
    format: FormatsEnum['date-time'],
    formula: 'teamAssignement.effectiveAsOf',
  },
  [FormulaKeyword.TEAM_ASSIGNMENT_EFFECTIVE_UNTIL]: {
    id: FormulaKeyword.TEAM_ASSIGNMENT_EFFECTIVE_UNTIL,
    format: FormatsEnum['date-time'],
    formula: 'teamAssignement.effectiveUntil',
  },
  [FormulaKeyword.FILTER_METRICS_BY_USER_TEAM_MEMBER]: {
    id: FormulaKeyword.FILTER_METRICS_BY_USER_TEAM_MEMBER,
    format: FormatsEnum.boolean,
    formula: 'filter.metricsByUserTeamMember',
  },
  [FormulaKeyword.FILTER_METRICS_BY_TEAM_MEMBER]: {
    id: FormulaKeyword.FILTER_METRICS_BY_TEAM_MEMBER,
    format: FormatsEnum.boolean,
    formula: 'filter.metricsByTeamMember',
  },
  [FormulaKeyword.FILTER_METRICS_BY_TEAM_EMPLOYEE]: {
    id: FormulaKeyword.FILTER_METRICS_BY_TEAM_EMPLOYEE,
    format: FormatsEnum.boolean,
    formula: 'filter.metricsByTeamEmployee',
  },
  [FormulaKeyword.FILTER_METRICS_BY_TEAM_MANAGER]: {
    id: FormulaKeyword.FILTER_METRICS_BY_TEAM_MANAGER,
    format: FormatsEnum.boolean,
    formula: 'filter.metricsByTeamManager',
  },
} as const;
