import { http } from '@amalia/core/http/client';
import { type CompanyCurrency } from '@amalia/tenants/companies/currency-rates/types';

const API_ENDPOINT = '/company_currencies';

export class CompanyCurrencyRatesApiClient {
  public static async create(
    companyCurrency: Pick<CompanyCurrency, 'currency' | 'rate' | 'symbol'>,
  ): Promise<CompanyCurrency> {
    const { data } = await http.post<CompanyCurrency>(`${API_ENDPOINT}`, companyCurrency);
    return data;
  }

  // TODO: change endpoint backend to be PATCH /company_currencies/{id}
  public static async update(
    companyCurrency: Pick<CompanyCurrency, 'currency' | 'rate' | 'symbol'>,
  ): Promise<CompanyCurrency> {
    const { data } = await http.patch<CompanyCurrency>(`${API_ENDPOINT}`, companyCurrency);
    return data;
  }

  public static async delete(companyCurrencyId: string): Promise<void> {
    await http.delete(`${API_ENDPOINT}/${companyCurrencyId}`);
  }

  public static async list(year: string): Promise<CompanyCurrency[]> {
    const { data } = await http.get<CompanyCurrency[]>(`${API_ENDPOINT}/?year=${year}`);
    return data;
  }
}
