import { DataConnectorTypes } from '@amalia/data-capture/connectors/types';
import { type Asset } from '@amalia/ext/react/types';
import {
  IconDataAdp,
  IconDataAdpSmall,
  IconDataBamboo,
  IconDataBambooSmall,
  IconDataBigQuery,
  IconDataBigQuerySmall,
  IconDataGSheet,
  IconDataGSheetSmall,
  IconDataHubspot,
  IconDataHubspotSmall,
  IconDataMicrosoft,
  IconDataMicrosoftSmall,
  IconDataMySql,
  IconDataMySqlSmall,
  IconDataNetsuite,
  IconDataNetsuiteSmall,
  IconDataPersonio,
  IconDataPersonioSmall,
  IconDataPostgreSql,
  IconDataPostgreSqlSmall,
  IconDataPowerBi,
  IconDataPowerBiSmall,
  IconDataRedshift,
  IconDataRedshiftSmall,
  IconDataSageHr,
  IconDataSageHrSmall,
  IconDataSalesforce,
  IconDataSalesforceSmall,
  IconDataSapsf,
  IconDataSapsfSmall,
  IconDataSnowflake,
  IconDataSnowflakeSmall,
  IconDataSqlServer,
  IconDataSqlServerSmall,
  IconDataWorkday,
  IconDataWorkdaySmall,
  IconDataZoho,
  IconDataZohoSmall,
} from '@amalia/frontend/ui-icons';

export type IntegrationLogos = {
  Logo: Asset;
  CompactLogo: Asset;
};

export const VENDOR_LOGOS: Record<DataConnectorTypes, IntegrationLogos> = {
  [DataConnectorTypes.SALESFORCE]: {
    Logo: IconDataSalesforce,
    CompactLogo: IconDataSalesforceSmall,
  },
  [DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT]: {
    Logo: IconDataSalesforce,
    CompactLogo: IconDataSalesforceSmall,
  },
  [DataConnectorTypes.SALESFORCE_THIRD_ACCOUNT]: {
    Logo: IconDataSalesforce,
    CompactLogo: IconDataSalesforceSmall,
  },
  [DataConnectorTypes.HUBSPOT]: {
    Logo: IconDataHubspot,
    CompactLogo: IconDataHubspotSmall,
  },
  [DataConnectorTypes.NETSUITE]: {
    Logo: IconDataNetsuite,
    CompactLogo: IconDataNetsuiteSmall,
  },
  [DataConnectorTypes.GOOGLESHEET]: {
    Logo: IconDataGSheet,
    CompactLogo: IconDataGSheetSmall,
  },
  [DataConnectorTypes.REDSHIFT]: {
    Logo: IconDataRedshift,
    CompactLogo: IconDataRedshiftSmall,
  },
  [DataConnectorTypes.BIGQUERY]: {
    Logo: IconDataBigQuery,
    CompactLogo: IconDataBigQuerySmall,
  },
  [DataConnectorTypes.SNOWFLAKE]: {
    Logo: IconDataSnowflake,
    CompactLogo: IconDataSnowflakeSmall,
  },
  [DataConnectorTypes.POSTGRESQL]: {
    Logo: IconDataPostgreSql,
    CompactLogo: IconDataPostgreSqlSmall,
  },
  [DataConnectorTypes.POWERBI]: {
    Logo: IconDataPowerBi,
    CompactLogo: IconDataPowerBiSmall,
  },
  [DataConnectorTypes.MSDYNAMICS]: {
    Logo: IconDataMicrosoft,
    CompactLogo: IconDataMicrosoftSmall,
  },
  [DataConnectorTypes.SQLSERVER]: {
    Logo: IconDataSqlServer,
    CompactLogo: IconDataSqlServerSmall,
  },
  [DataConnectorTypes.MYSQL]: {
    Logo: IconDataMySql,
    CompactLogo: IconDataMySqlSmall,
  },
  [DataConnectorTypes.ADPWORKFORCENOW]: {
    Logo: IconDataAdp,
    CompactLogo: IconDataAdpSmall,
  },
  [DataConnectorTypes.BAMBOOHR]: {
    Logo: IconDataBamboo,
    CompactLogo: IconDataBambooSmall,
  },
  [DataConnectorTypes.PERSONIO]: {
    Logo: IconDataPersonio,
    CompactLogo: IconDataPersonioSmall,
  },
  [DataConnectorTypes.SAGEHR]: {
    Logo: IconDataSageHr,
    CompactLogo: IconDataSageHrSmall,
  },
  [DataConnectorTypes.SAPSUCCESSFACTORS]: {
    Logo: IconDataSapsf,
    CompactLogo: IconDataSapsfSmall,
  },
  [DataConnectorTypes.WORKDAY]: {
    Logo: IconDataWorkday,
    CompactLogo: IconDataWorkdaySmall,
  },
  [DataConnectorTypes.ZOHO]: {
    Logo: IconDataZoho,
    CompactLogo: IconDataZohoSmall,
  },
} as const;
