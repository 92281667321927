import { type Dispatch, memo, type SetStateAction, useMemo } from 'react';

import { type ComputedVariableWithVariableIdAndLabel, isBeingReviewed } from '@amalia/core/types';
import { type OverwriteCreationRequestDetails } from '@amalia/data-correction/overwrites/components';
import { Typography } from '@amalia/design-system/components';
import { useCompanyCustomization } from '@amalia/frontend/web-data-layers';
import { ActionsEnum, subject, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { useStatementDetailContext } from '@amalia/lib-ui';
import { type ComputedVariable, type ComputeEngineResult } from '@amalia/payout-calculation/types';
import { type PlanRule } from '@amalia/payout-definition/plans/types';

import { ForecastedKPI } from '../forecasted-kpi/ForecastedKPI';
import { OverwritableKPI } from '../overwritable-kpi/OverwritableKPI';
import { type SectionWithComputedKPIS } from '../useKPIDisplay';
import { useKPIThread } from '../useKPIThread';

import * as styles from './Section.styles';
import { SectionContainer } from './Section.styles';

type SectionProps = {
  readonly section: SectionWithComputedKPIS;
  readonly ruleDefinition: PlanRule;
  readonly clearOverwrite: (variable: ComputedVariable<ComputeEngineResult>) => Promise<void>;
  readonly openOverwriteModal: () => void;
  readonly setOverwriteObjectDetails: Dispatch<SetStateAction<OverwriteCreationRequestDetails>>;
  readonly statementRuleComputedVariables: ComputedVariableWithVariableIdAndLabel[];
};

export const Section = memo(function Section({
  section,
  ruleDefinition,
  clearOverwrite,
  openOverwriteModal,
  setOverwriteObjectDetails,
  statementRuleComputedVariables,
}: SectionProps) {
  const ability = useAbilityContext();
  const statementContext = useStatementDetailContext();
  const { legacyKpiCardView } = useCompanyCustomization();
  const isWorkflowBegun = useMemo(() => isBeingReviewed(statementContext.statement), [statementContext]);
  const { openStatementThread, threadsPerKpi } = useKPIThread(
    statementContext,
    ruleDefinition,
    statementRuleComputedVariables,
  );

  return (
    <SectionContainer>
      {!!section.name && (
        <Typography
          css={styles.sectionName}
          variant={Typography.Variant.BODY_LARGE_MEDIUM}
        >
          {section.name}
        </Typography>
      )}
      <div css={[styles.kpisContainer, legacyKpiCardView ? styles.cardViewGrid : styles.kpiList]}>
        {section.kpis.map((variable) =>
          statementContext.isForecastedView ? (
            <ForecastedKPI
              key={variable.id}
              computedVariable={variable}
              openOverwriteModal={openOverwriteModal}
              resetOverwrite={clearOverwrite}
              ruleDefinition={ruleDefinition}
              setOverwriteObjectDetails={setOverwriteObjectDetails}
              canSimulate={
                ability.can(
                  ActionsEnum.simulate,
                  subject(SubjectsEnum.Forecasted_Statement, {
                    ...statementContext,
                    plan: statementContext.statement.plan,
                    period: statementContext.statement.period,
                    userId: statementContext.statement.userId,
                  }),
                ) && !!statementContext.statement.plan.isSimulationEnabled
              }
              variableDefinition={
                statementContext.statement.results.definitions.variables[variable.variableMachineName]
              }
            />
          ) : (
            <OverwritableKPI
              key={variable.id}
              computedVariable={variable}
              isReadOnly={isWorkflowBegun || ability.cannot(ActionsEnum.overwrite, SubjectsEnum.Statement)}
              openOverwriteModal={openOverwriteModal}
              openStatementThread={openStatementThread}
              resetOverwrite={clearOverwrite}
              ruleDefinition={ruleDefinition}
              setOverwriteObjectDetails={setOverwriteObjectDetails}
              statementThread={threadsPerKpi[variable.id]}
              variableDefinition={
                statementContext.statement.results.definitions.variables[variable.variableMachineName]
              }
            />
          ),
        )}
      </div>
    </SectionContainer>
  );
});
