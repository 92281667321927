export enum ActionsEnum {
  view = 'view',
  view_list = 'view_list',
  manage = 'manage',
  modify = 'modify',
  create = 'create',
  tracing = 'tracing',
  review = 'review',
  calculate = 'calculate',
  refresh = 'refresh',
  export = 'export',
  adjust = 'adjust',
  customize = 'customize',
  view_statement_threads = 'view_statement_threads',
  add_statement_comments = 'add_statement_comments',
  review_statement_thread = 'review_statement_thread',
  overwrite = 'overwrite',
  automate = 'automate',
  view_assignments = 'view_assignments',
  modify_assignments = 'modify_assignments',
  assign_values = 'assign_values',
  reset = 'reset',
  delete = 'delete',
  view_settings = 'view_settings',
  modify_settings = 'modify_settings',
  view_rates = 'view_rates',
  modify_rates = 'modify_rates',
  view_directory = 'view_directory',
  modify_directory_email_domain = 'modify_directory_email_domain',
  simulate = 'simulate',
  impersonate = 'impersonate',
  toggle_deactivation = 'toggle_deactivation',
  // Statement only, if you have visibility view on multiple persons.
  // TODO: there is probably a better way to do this.
  switch_to_another_user = 'switch_to_another_user',
  invite = 'invite',
}
