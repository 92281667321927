import { css, useTheme } from '@emotion/react';
import { isNil } from 'lodash';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, Link } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { type Calculation, CalculationStatus, formatUserFullName } from '@amalia/core/types';
import { AlertBanner, Group, LinearProgress, Modal, Typography } from '@amalia/design-system/components';

import { CalculationProgressModalStep } from './CalculationProgressModalStep';

interface CalculationProgressionModalProps {
  readonly isOpen: boolean;
  readonly closeModal: () => void;
  readonly stopCalculation?: () => void;
  readonly calculation: Partial<Calculation>;
  readonly calculated?: number;
  readonly total?: number;
}

export const CalculationProgressionModal = memo(function CalculationProgressionModal({
  isOpen,
  closeModal,
  stopCalculation,
  calculation,
  calculated,
  total,
}: CalculationProgressionModalProps) {
  const theme = useTheme();

  const {
    errors,
    warnings,
  }: {
    errors: Record<string, { id: string; details: string }[]>;
    warnings: Record<string, { id: string; details: string }[]>;
  } = useMemo(() => {
    const resultErrors: Record<string, { id: string; details: string }[]> = {};
    const resultWarnings: Record<string, { id: string; details: string }[]> = {};

    (calculation.descriptor || []).forEach((d) => {
      d.batches.forEach((b) => {
        if ([CalculationStatus.ERROR, CalculationStatus.INCOMPLETE].includes(b.status)) {
          b.users.forEach((u) => {
            if (!u.result?.error) {
              return;
            }
            let listToAddError = resultErrors;
            if (u.result.error.includes('has no value for this period')) {
              listToAddError = resultWarnings;
            }

            // If we don't have already this error, create it
            if (!(u.result.error in listToAddError)) {
              listToAddError[u.result.error] = [];
            }

            // Push the statement
            listToAddError[u.result.error].push({
              id: u.result.statementId,
              details: `${d.periodName} - ${b.planName} - ${formatUserFullName(u)}`,
            });
          });
        }
      });
    });

    if (calculation.error && !(calculation.error in resultErrors)) {
      resultErrors[calculation.error] = [];
    }

    return { errors: resultErrors, warnings: resultWarnings };
  }, [calculation.descriptor, calculation.error]);

  return (
    <Modal
      isOpen={isOpen}
      size={Modal.Size.LARGE}
      onClose={closeModal}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Calculation progress" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Object.keys(errors).length > 0 && (
            <AlertBanner variant={AlertBanner.Variant.ERROR}>
              {Object.entries(errors).map(([error, statements]) => (
                <details key={error}>
                  <summary>
                    <FormattedMessage
                      defaultMessage="{error} ({statementsCount, plural, one {# statement} other {# statements}})"
                      values={{ error, statementsCount: statements.length }}
                    />
                  </summary>
                  {statements.map((s) => (
                    <div key={s.id}>
                      <Link to={generatePath(routes.STATEMENT, { id: s.id })}>{s.details}</Link>
                    </div>
                  ))}
                </details>
              ))}
            </AlertBanner>
          )}

          {Object.keys(warnings).length > 0 && (
            <AlertBanner variant={AlertBanner.Variant.WARNING}>
              {Object.entries(warnings).map(([warning, statements]) => (
                <details key={warning}>
                  <summary>
                    <FormattedMessage
                      defaultMessage="{error} ({statementsCount, plural, one {# statement} other {# statements}})"
                      values={{ error: warning, statementsCount: statements.length }}
                    />
                  </summary>
                  {statements.map((s) => (
                    <div key={s.id}>
                      <Link to={generatePath(routes.STATEMENT, { id: s.id })}>{s.details}</Link>
                    </div>
                  ))}
                </details>
              ))}
            </AlertBanner>
          )}

          {(calculation.descriptor || []).map((step, index) => (
            <CalculationProgressModalStep
              key={`step_${index}`} // eslint-disable-line react/no-array-index-key
              index={index}
              step={step}
            />
          ))}
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />

        {!isNil(calculated) && !isNil(total) && (
          <Group
            grow
            align="center"
            gap={8}
            css={css`
              margin: 0 8px;
            `}
          >
            <div
              css={css`
                flex: 1;
              `}
            >
              <LinearProgress
                backgroundColor={theme.ds.colors.gray[100]}
                progress={total ? calculated / total : 0}
                progressColor={theme.ds.colors.primary[400]}
              />
            </div>

            <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
              <FormattedMessage
                defaultMessage="{current, number}/{total, number}"
                values={{ current: calculated, total }}
              />
            </Typography>
          </Group>
        )}

        {!!stopCalculation && (
          <Modal.MainAction onClick={stopCalculation}>
            <FormattedMessage defaultMessage="Stop calculation" />
          </Modal.MainAction>
        )}
      </Modal.Actions>
    </Modal>
  );
});
