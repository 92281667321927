import { IconHelp } from '@tabler/icons-react';
import clsx from 'clsx';
import { type ReactNode, memo, useCallback, type ComponentPropsWithoutRef, cloneElement, Fragment } from 'react';

import { Portal } from '@amalia/ext/react/components';
import { type MergeAll } from '@amalia/ext/typescript';

import { type TablerIconElement } from '../../../general/icons/types';
import { Typography } from '../../../general/typography/Typography';
import { UnstyledButton } from '../../../general/unstyled-button/UnstyledButton';
import { Group } from '../../../layout/group/Group';
import { Stack } from '../../../layout/stack/Stack';
import { Tooltip } from '../../../overlays/tooltip/Tooltip';
import { useTabsContext } from '../Tabs.context';
import { type TabValue } from '../Tabs.types';

import * as styles from './Tab.styles';

export type TabProps<TTabValue extends TabValue = TabValue> = MergeAll<
  [
    Omit<ComponentPropsWithoutRef<'button'>, 'onClick'>,
    {
      /** Tab value to compare to the value passed to Tabs to determine the active tab. */
      readonly value: TTabValue;
      /** Tab label. */
      readonly label: ReactNode;
      /** Optional tab help tooltip. */
      readonly tooltip?: ReactNode;
      /** Optional icon. */
      readonly icon?: TablerIconElement;
      /** Tab content (mounted only when the tab is selected). */
      readonly children?: ReactNode;
    },
  ]
>;

const TabBase = function Tab<const TTabValue extends TabValue = TabValue>({
  value,
  label,
  tooltip = undefined,
  icon = undefined,
  children,
  ...props
}: TabProps<TTabValue>) {
  const { value: contextValue, onChange, contentContainerElement } = useTabsContext<TTabValue>();

  const handleClick = useCallback(() => onChange(value), [onChange, value]);

  const isSelected = contextValue === value;

  return (
    <Fragment>
      <UnstyledButton
        {...props}
        className={clsx(props.className, { [styles.SELECTED_CLASSNAME]: isSelected })}
        css={styles.tab}
        onClick={handleClick}
      >
        <Stack
          gap={2}
          justify="space-between"
        >
          <Group
            align="center"
            css={styles.label}
            gap={6}
          >
            {!!icon && cloneElement(icon, { size: 18 })}

            <Typography variant={Typography.Variant.BODY_LARGE_MEDIUM}>{label}</Typography>

            {!!tooltip && (
              <Tooltip content={tooltip}>
                <IconHelp size={16} />
              </Tooltip>
            )}
          </Group>

          <div css={styles.border} />
        </Stack>
      </UnstyledButton>

      {!!isSelected && <Portal element={contentContainerElement}>{children}</Portal>}
    </Fragment>
  );
};

export const Tab = memo(TabBase) as typeof TabBase;
