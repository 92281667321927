import styled from '@emotion/styled';
import { IconCheck, IconRefresh, IconSend } from '@tabler/icons-react';
import { Fragment, memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { type ButtonProps, CountBadge, DataGrid, IconLoading, Tooltip } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';
import { log } from '@amalia/kernel/logger/client';
import { UserProfileApiClient } from '@amalia/tenants/users/profile/api-client';
import { useInviteUsers } from '@amalia/tenants/users/profile/state';
import { type UserProfile } from '@amalia/tenants/users/profile/types';

import { ConfirmBulkInviteUsersModal } from '../../../../invitation/confirm-modal/ConfirmBulkInviteUsersModal';

const SuccessIcon = styled(IconCheck)`
  color: ${({ theme }) => theme.ds.colors.success[500]};
`;

export type BulkSendInvitationActionProps = {
  readonly users: Pick<UserProfile, 'email' | 'firstName' | 'id' | 'lastName'>[];
  readonly selectedUserIds: string[];
  readonly setSelectedUserIds: (selectedUserIds: string[]) => void;
};

export const BulkSendInvitationAction = memo(function BulkSendInvitationAction({
  users,
  selectedUserIds,
  setSelectedUserIds,
}: BulkSendInvitationActionProps) {
  const { isConfirmModalOpen, setConfirmModalOpenTrue, setConfirmModalOpenFalse } = useBoolState(
    false,
    'confirmModalOpen',
  );

  const { mutateAsync: sendInvitations, isPending, isSuccess } = useInviteUsers(selectedUserIds);

  const onClickInvite = useCallback(() => {
    sendInvitations()
      .then(() => {
        /* In case of success for every invitation, reset the table selection. */
        setSelectedUserIds([]);
      })
      .catch((err: unknown) => {
        /* On error, only keep as selected the rows for which we failed to send the invitation */
        log.error(err);

        if (!UserProfileApiClient.isBulkInvitationError(err)) {
          return;
        }

        const { errors } = err.response.data;
        setSelectedUserIds(errors.map(({ id }) => id));
      });
    setConfirmModalOpenFalse();
  }, [sendInvitations, setSelectedUserIds, setConfirmModalOpenFalse]);

  const isButtonDisabled = isPending || isSuccess || !selectedUserIds.length;

  const buttonProps = {
    disabled: isButtonDisabled,
    variant: DataGrid.Action.Variant.PRIMARY,
    countBadge: !isButtonDisabled ? <CountBadge>{selectedUserIds.length}</CountBadge> : undefined,
  } satisfies Partial<ButtonProps>;

  const label = <FormattedMessage defaultMessage="Send invitations" />;

  if (isPending) {
    return (
      <DataGrid.Action
        {...buttonProps}
        icon={<IconLoading icon={IconRefresh} />}
      >
        {label}
      </DataGrid.Action>
    );
  }

  if (isSuccess) {
    return (
      <DataGrid.Action
        {...buttonProps}
        icon={<SuccessIcon />}
      >
        {label}
      </DataGrid.Action>
    );
  }

  return (
    <Fragment>
      <Tooltip
        content={
          isButtonDisabled ? (
            <FormattedMessage defaultMessage="Select at least one user to enable this action." />
          ) : null
        }
      >
        <DataGrid.Action
          {...buttonProps}
          icon={<IconSend />}
          onClick={setConfirmModalOpenTrue}
        >
          {label}
        </DataGrid.Action>
      </Tooltip>

      <ConfirmBulkInviteUsersModal
        isOpen={isConfirmModalOpen}
        selectedUserIds={selectedUserIds}
        users={users}
        onClose={setConfirmModalOpenFalse}
        onSubmit={onClickInvite}
      />
    </Fragment>
  );
});
