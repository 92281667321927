import { last } from 'lodash';
import { useEffect, useState } from 'react';

import { type LayerId, useLayersContext } from './Layers.context';

const BASE_Z_INDEX = 3000;
const Z_INDEX_INCREMENT = 100;

export const useLayer = ({ isOpen }: { isOpen: boolean }) => {
  const [layerId, setLayerId] = useState<LayerId | undefined>(undefined);
  const { onOpenLayer, onCloseLayer, layerIds } = useLayersContext();

  useEffect(() => {
    if (isOpen) {
      const layerId = onOpenLayer();
      setLayerId(layerId);
      return () => onCloseLayer(layerId);
    }
    return setLayerId(undefined);
  }, [isOpen, onOpenLayer, onCloseLayer]);

  const layerIndex = layerId ? layerIds.indexOf(layerId) : -1;

  return {
    isTopLayer: layerId === last(layerIds),
    layerIndex,
    layerZIndex: layerIndex >= 0 ? BASE_Z_INDEX + layerIndex * Z_INDEX_INCREMENT : -1,
  };
};
