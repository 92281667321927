import { type RefCallback, useCallback, useState, useRef, type DependencyList } from 'react';

import { useMergedRef } from '../use-merged-ref/useMergedRef';
import { type UseResizeObserverOptions, useResizeObserver } from '../use-resize-observer/useResizeObserver';
import { useUpdateEffect } from '../use-update-effect/useUpdateEffect';

const isTextCutOff = <TElement extends HTMLElement>(element: TElement | null) =>
  !!element && (element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight);

export type UseTextOverflowsValue<TElement extends HTMLElement> = {
  ref: RefCallback<TElement>;
  doesTextOverflow: boolean;
};

/**
 * Find out if a text overflows (when using text-overflow). This uses ResizeObserver.
 *
 * @param deps - Dependencies to update the text overflow state (aka pass the content of the element being observed).
 */
export const useTextOverflows = <TElement extends HTMLElement>(
  deps: DependencyList = [],
): UseTextOverflowsValue<TElement> => {
  const [doesTextOverflow, setDoesTextOverflow] = useState<boolean>(false);

  const onResize: UseResizeObserverOptions<TElement>['onResize'] = useCallback(
    (_, element) => setDoesTextOverflow(isTextCutOff(element)),
    [],
  );

  const observerRef = useResizeObserver({ onResize });
  const internalRef = useRef<TElement>(null);

  const mergedRef = useMergedRef<TElement>(observerRef, internalRef);

  // eslint-disable-next-line react-hooks/exhaustive-deps -- Array must be static.
  useUpdateEffect(() => setDoesTextOverflow(isTextCutOff(internalRef.current)), deps);

  return {
    ref: mergedRef,
    doesTextOverflow,
  };
};
