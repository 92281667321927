import { css } from '@emotion/react';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertBanner, IconLoading, Modal } from '@amalia/design-system/components';

import { AvatarEditor } from './AvatarEditor';
import { useAvatarEditor } from './useAvatarEditor';

const modalBodyStyles = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export type AvatarEditorModalProps = {
  readonly isModalOpen: boolean;
  readonly onClose: () => void;
};

export const AvatarEditorModal = memo(function AvatarEditorModal({ isModalOpen, onClose }: AvatarEditorModalProps) {
  const {
    avatarBase64,
    checkFileValidity,
    error: avatarBase64Error,
    isPending: isAvatarBase64Loading,
    updateAvatarPreview,
    submitAvatar,
  } = useAvatarEditor();

  const submitAvatarAndCloseModal = useCallback(async () => {
    await submitAvatar();

    // do not close the modal in case of error
    onClose();
  }, [onClose, submitAvatar]);

  return (
    <div>
      <Modal
        isDismissable
        data-testid="avatar-editor-modal"
        isOpen={isModalOpen}
        size={Modal.Size.MEDIUM}
        variant={Modal.Variant.DEFAULT}
        onClose={onClose}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              <FormattedMessage defaultMessage="Upload new avatar" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div css={modalBodyStyles}>
              {!!avatarBase64Error && (
                <AlertBanner variant={AlertBanner.Variant.ERROR}>{avatarBase64Error}</AlertBanner>
              )}

              <AvatarEditor
                onBeforeFileLoad={checkFileValidity}
                onCrop={updateAvatarPreview}
              />
            </div>
          </Modal.Body>
        </Modal.Content>

        <Modal.Actions>
          <Modal.CancelAction />

          <Modal.MainAction
            disabled={!avatarBase64 || isAvatarBase64Loading}
            icon={isAvatarBase64Loading ? <IconLoading /> : undefined}
            type="submit"
            onClick={submitAvatarAndCloseModal}
          >
            <FormattedMessage defaultMessage="Update my avatar!" />
          </Modal.MainAction>
        </Modal.Actions>
      </Modal>
    </div>
  );
});
