import { type ForwardedRef, forwardRef, memo } from 'react';

import { Input, type InputProps } from '@amalia/design-system/components';
import { type FormikFieldProps } from '@amalia/ext/formik';
import { type FocusEventHandlers } from '@amalia/ext/react/hooks';

import { useTriggerChangeOnBlur } from './useTriggerChangeOnBlur';

export type IntegrationIdFormikInputProps<TName extends 'externalId' | 'hrisId'> = Omit<
  FormikFieldProps<InputProps, 'text'>,
  'onChange' | 'value'
> &
  Partial<FocusEventHandlers<HTMLInputElement>> & {
    readonly name: TName;
  };

const IntegrationIdFormikInputBase = forwardRef(function IntegrationIdFormikInput<
  TName extends 'externalId' | 'hrisId',
>({ name, onBlur, ...props }: IntegrationIdFormikInputProps<TName>, ref: ForwardedRef<HTMLInputElement>) {
  const fieldProps = useTriggerChangeOnBlur({ name, onBlur });

  return (
    <Input
      ref={ref}
      {...props}
      {...fieldProps}
    />
  );
});

export const IntegrationIdFormikInput = memo(IntegrationIdFormikInputBase) as typeof IntegrationIdFormikInputBase;
