import { memo, useCallback } from 'react';

import { type ComputedStatementDefinitions } from '@amalia/core/types';
import { DatasetButton, DatasetButtonVariant } from '@amalia/lib-ui-business';
import {
  type Dataset,
  type FilterDataset,
  type MetricsDataset,
  type QuotaDataset,
} from '@amalia/payout-calculation/types';

type StatementRuleDatasetMenuItemProps = Readonly<{
  computedStatementDefinitions: ComputedStatementDefinitions;
  dataset: FilterDataset | MetricsDataset | QuotaDataset;
  hasMultipleDatasets: boolean;
  selectedDataset: Dataset;
  selectDatasetToDisplay: (dataset: Dataset) => void;
}>;

export const StatementRuleDatasetMenuItem = memo(function StatementRuleDatasetMenuItem({
  computedStatementDefinitions,
  dataset,
  hasMultipleDatasets,
  selectedDataset,
  selectDatasetToDisplay,
}: StatementRuleDatasetMenuItemProps) {
  const handleClickDatasetButton = useCallback(() => {
    selectDatasetToDisplay(dataset);
  }, [dataset, selectDatasetToDisplay]);

  const customObjectDefinition =
    computedStatementDefinitions?.customObjects[dataset?.customObjectDefinition.machineName];

  const filterDefinition = computedStatementDefinitions?.filters?.[dataset.filterMachineName];

  return (
    <DatasetButton
      customObjectDefinitionType={customObjectDefinition?.type}
      hasMultipleDatasets={hasMultipleDatasets}
      isActive={selectedDataset.id === dataset.id}
      label={filterDefinition.name}
      variant={DatasetButtonVariant.STATEMENT}
      onClick={handleClickDatasetButton}
    />
  );
});
