import { IconLoader } from '@tabler/icons-react';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { MenuDropdown } from '@amalia/design-system/components';
import { useRandomizeAvatar } from '@amalia/tenants/users/profile/state';

export const RandomizeAvatarDropdownItem = memo(function RandomizeAvatarDropdownItem() {
  const { mutate: randomizeAvatar } = useRandomizeAvatar();

  const onClickRandomizeAvatar = useCallback(() => randomizeAvatar(), [randomizeAvatar]);

  return (
    <MenuDropdown.Item
      data-testid="randomize-avatar-dropdown-item"
      icon={<IconLoader />}
      label={<FormattedMessage defaultMessage="Randomize Amalia avatar" />}
      onClick={onClickRandomizeAvatar}
    />
  );
});
