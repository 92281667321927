import { css } from '@emotion/react';
import { memo } from 'react';

export type YAxisTickProps = {
  // Recharts props. They are not all listed here because it's typed as any in recharts :perplexed-g:.
  readonly tickFormatter?: (value?: number | string | null) => string;
  readonly payload?: {
    value?: number;
  };
};

export const YAxisTick = memo(function YAxisTick({ tickFormatter, payload, ...props }: YAxisTickProps) {
  return (
    <text
      {...props}
      dx={8}
      dy={4}
      css={(theme) => css`
        ${theme.ds.typographies.bodyXsmallRegular};
        fill: ${theme.ds.colors.gray[500]};
      `}
    >
      {tickFormatter?.(payload?.value)}
    </text>
  );
});
