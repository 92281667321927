import moment from 'moment';

import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.startOfQuarter, AmaliaFunctionCategory.DATES);

func.nbParamsRequired = 1;

func.description = 'Return the first day of quarter of a given date';

func.params = [
  {
    name: 'date',
    description:
      'Date to apply function on: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
    validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
    validTokenValues: {
      [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
    },
    validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
  },
];

func.examples = [
  {
    desc: 'Returns "2023-04-01"',
    formula: 'startOfQuarter(toDate("2023-05-19"))' as AmaliaFormula,
    result: 1_680_307_200,
  },
  {
    desc: "Returns first day of quarter of the user's plan assignment start date.",
    formula: 'startOfQuarter(planAssignement.effectiveAsOf)' as AmaliaFormula,
  },
  {
    desc: 'Returns first day of quarter of opportunity close date.',
    formula: 'startOfQuarter(opportunity.closeDate)' as AmaliaFormula,
  },
];

func.exec = (date: moment.MomentInput | string): number | null =>
  +moment(date, 'X').startOf('quarter').format('X') || null;

export default func;
