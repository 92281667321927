import { memo, useCallback, useState } from 'react';

import { useShallowObjectMemo } from '@amalia/ext/react/hooks';

import { Dropdown, type DropdownProps } from '../dropdown/Dropdown';

import { MenuDropdownItem } from './menu-dropdown-item/MenuDropdownItem';
import { MenuDropdownItemLink } from './menu-dropdown-item-link/MenuDropdownItemLink';
import { type MenuDropdownContextProps, MenuDropdownContextProvider } from './MenuDropdown.context';

export type MenuDropdownProps = DropdownProps;

const MenuDropdownBase = memo(function MenuDropdown({
  returnFocus = false,
  isOpen: controlledIsOpen,
  onChangeIsOpen: controlledOnChangeIsOpen,
  children,
  ...props
}: MenuDropdownProps) {
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(false);

  const isOpen = controlledIsOpen ?? uncontrolledIsOpen;
  const onChangeIsOpen = controlledOnChangeIsOpen ?? setUncontrolledIsOpen;

  const handleClosePopover = useCallback(() => {
    onChangeIsOpen(false);
  }, [onChangeIsOpen]);

  const menuDropdownContext: MenuDropdownContextProps = useShallowObjectMemo({
    onClosePopover: handleClosePopover,
  });

  return (
    <MenuDropdownContextProvider value={menuDropdownContext}>
      <Dropdown
        {...props}
        isOpen={isOpen}
        returnFocus={returnFocus}
        onChangeIsOpen={onChangeIsOpen}
      >
        {children}
      </Dropdown>
    </MenuDropdownContextProvider>
  );
});

export const MenuDropdown = Object.assign(MenuDropdownBase, {
  Item: MenuDropdownItem,
  ItemLink: MenuDropdownItemLink,
  Button: Dropdown.Button,
  IconButton: Dropdown.IconButton,
});
