import { css } from '@emotion/react';
import { memo, forwardRef, type ForwardedRef, type ComponentPropsWithoutRef } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { type ColumnDefinition } from '../../Table.types';

import * as styles from './TableCell.styles';

export type TableCellProps = MergeAll<
  [
    (
      | MergeAll<
          [
            ComponentPropsWithoutRef<'td'>,
            {
              as: 'td';
            },
          ]
        >
      | MergeAll<
          [
            ComponentPropsWithoutRef<'th'>,
            {
              as: 'th';
            },
          ]
        >
    ),
    {
      /** Does this cell stick to the left. */
      isStickyLeft?: boolean;
      /** Left offset in px when sticky. */
      stickyLeftOffset?: number;
      /** Does this cell stick to the right. If both isStickyLeft and isStickyRight are true, isStickyLeft takes precedence. */
      isStickyRight?: boolean;
      /** Column size. */
      size?: ColumnDefinition['size'];
    },
  ]
>;

const TableCellForwardRef = forwardRef(function TableCell(
  {
    as: CellComponent,
    children,
    isStickyLeft = false,
    stickyLeftOffset = 0,
    isStickyRight = false,
    size,
    ...props
  }: TableCellProps,
  ref: ForwardedRef<HTMLTableCellElement>,
) {
  return (
    <CellComponent
      {...props}
      ref={ref}
      data-sticky-left={!!isStickyLeft}
      data-sticky-right={!!isStickyRight && !isStickyLeft}
      css={[
        styles.tableCell,
        isStickyLeft &&
          css`
            left: ${stickyLeftOffset}px;
          `,
        size !== null &&
          css`
            /**
             * If size is 0 => we want the cell to take as little space as possible (mostly for icon actions).
             * We put the width at 1% (0% does not work for Firefox, but it looks the same),
             * We put a initial max-width and a initial min-width.
             */
            /** If size is > 0 => width/max-width/min-width are size. */
            /** If size is undefined => width is 240px, max-width is 240px, min-width is initial. */
            width: ${typeof size === 'number' ? (size === 0 ? '1%' : `${size}px`) : '240px'};
            max-width: ${typeof size === 'number' ? (size ? `${size}px` : 'initial') : '240px'};
            min-width: ${typeof size === 'number' && size ? `${size}px` : 'initial'};
          `,
      ]}
    >
      {children}
    </CellComponent>
  );
});

export const TableCell = memo(TableCellForwardRef);
