import { combineReducers } from 'redux';

import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { type RateTableData, type CompanyCurrency } from '@amalia/tenants/companies/currency-rates/types';

import { type ReduxAction } from '../types';

import { CURRENCIES_ACTIONS } from './constants';
import { type CurrenciesReducer } from './types';

export default combineReducers<CurrenciesReducer, ReduxAction>({
  isLoading: (state = 0, action: ReduxAction) => {
    switch (action.type) {
      case CURRENCIES_ACTIONS.START:
        return state + 1;
      case CURRENCIES_ACTIONS.ERROR:
      case CURRENCIES_ACTIONS.SET_CURRENCY_RATE:
      case CURRENCIES_ACTIONS.SET_CURRENCIES_WITH_COMPANY_CURRENCIES:
      case CURRENCIES_ACTIONS.COMPANY_CURRENCY_CREATE:
      case CURRENCIES_ACTIONS.COMPANY_CURRENCY_UPDATE:
      case CURRENCIES_ACTIONS.COMPANY_CURRENCY_DELETE:
        return state - 1;
      default:
        return state;
    }
  },
  // Currency array with company currencies, per year
  currenciesWithCompanyCurrencies: (state = [], action: ReduxAction) => {
    switch (action.type) {
      case CURRENCIES_ACTIONS.SET_CURRENCIES_WITH_COMPANY_CURRENCIES:
        return action.payload.currencies.map((currency) => {
          // Get all company currencies linked to this currency
          const relatedCompanyCurrencies = action.payload.companyCurrencies.filter(
            (cc: CompanyCurrency) => cc.currency.id === currency.id,
          );

          return {
            // Copy currency
            ...currency,

            // If there are company currencies, copy them into an object, with their symbol as key
            overwrites: relatedCompanyCurrencies.reduce(
              (acc: Record<CurrencySymbolsEnum, CompanyCurrency>, companyCurrency: CompanyCurrency) => {
                acc[companyCurrency.symbol] = {
                  ...companyCurrency,
                  // Keep the original value into amaliaRate attribute
                  amaliaRate: currency.rates[companyCurrency.symbol],
                } as CompanyCurrency;
                return acc;
              },
              {} as Record<CurrencySymbolsEnum, RateTableData>,
            ),
            rates: {
              // Copy all currency rates
              ...currency.rates,
              // Then, apply company currencies values
              ...relatedCompanyCurrencies.reduce(
                (acc: Record<CurrencySymbolsEnum, number>, companyCurrency: CompanyCurrency) => {
                  acc[companyCurrency.symbol] = companyCurrency.rate;
                  return acc;
                },
                {} as Record<CurrencySymbolsEnum, number>,
              ),
            },
          };
        }) satisfies typeof state;
      case CURRENCIES_ACTIONS.COMPANY_CURRENCY_DELETE: {
        return state.map((currency) => ({
          // Copy original currency
          ...currency,
          overwrites: {
            // Copy also its overwrites
            ...currency.overwrites,
            // Then, overwrite the previous overwrite symbol to undefined to cancel it
            ...(currency.id === action.payload.companyCurrency.currency.id
              ? {
                  [action.payload.companyCurrency.symbol]: undefined,
                }
              : {}),
          },
          rates: {
            // Copy the original rates
            ...currency.rates,
            // then, reapply the original rate on the overwrite symbol
            ...(currency.id === action.payload.companyCurrency.currency.id
              ? {
                  [action.payload.companyCurrency.symbol]: action.payload.companyCurrency.amaliaRate,
                }
              : {}),
          },
        }));
      }
      case CURRENCIES_ACTIONS.COMPANY_CURRENCY_CREATE:
      case CURRENCIES_ACTIONS.COMPANY_CURRENCY_UPDATE:
        return state.map((currency) => ({
          // Copy the original currency
          ...currency,
          overwrites: {
            // With its overwrites
            ...currency.overwrites,
            // Then, set the overwrite on the correct currency and symbol
            ...(currency.id === action.payload.companyCurrency.currency.id
              ? {
                  [action.payload.companyCurrency.symbol]: {
                    // Copy the overwrite
                    ...action.payload.companyCurrency,
                    // To get the original rate,
                    // Either we already have a company currency, or we get the rate from currency.rates
                    amaliaRate:
                      currency.overwrites?.[action.payload.companyCurrency.symbol]?.amaliaRate ||
                      currency.rates[action.payload.companyCurrency.symbol],
                  },
                }
              : {}),
          },
          rates: {
            // Copy the original rates
            ...currency.rates,
            // Then, set the new rate on the overwrite symbol
            ...(currency.id === action.payload.companyCurrency.currency.id
              ? {
                  [action.payload.companyCurrency.symbol]: action.payload.companyCurrency.rate,
                }
              : {}),
          },
        }));
      default:
        return state;
    }
  },
});
