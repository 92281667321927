import { type JSXElementConstructor, memo, type ReactElement } from 'react';

import { type CellIconActionProps } from '../cell-icon-action/CellIconAction';
import { CellWithActions, type CellWithActionsProps } from '../cell-with-actions/CellWithActions';

type CellIconActionElement = ReactElement<CellIconActionProps, JSXElementConstructor<CellIconActionProps>>;

type OptionalCellIconActionElement = CellIconActionElement | false | null;

export type CellActionsProps = Omit<CellWithActionsProps, 'actions' | 'children' | 'hideActions'> & {
  /** Children should be <Table.Cell.Action />[]. */
  readonly children: OptionalCellIconActionElement | OptionalCellIconActionElement[];
};

const CellActionsBase = memo(function CellActions({ children, ...props }: CellActionsProps) {
  return (
    <CellWithActions
      {...props}
      actions={children}
      hideActions={false}
    />
  );
});

export const CellActions = Object.assign(CellActionsBase, {
  // Use this column id for actions column so they will be automatically sticky if last.
  // Cannot use a symbol because we use the ids as strings when calling APIs and stuff, and using toString would negate the uniqueness of symbols.
  columnId: 'actions',
} as const);
