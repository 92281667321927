import styled from '@emotion/styled';

import { ModalSize } from '../Modal.types';

export const ModalContent = styled.div<{ noBottomPadding?: boolean }>`
  display: flex;
  flex-direction: column;

  flex: 1;
  overflow-y: auto;

  transition: ${({ theme }) => theme.ds.transitions.default('padding', 'gap')};

  .${ModalSize.SMALL} & {
    padding: ${({ noBottomPadding }) => (noBottomPadding ? '16px 0 0' : '16px 0')};
    gap: 24px;
  }

  .${ModalSize.MEDIUM} & {
    padding: ${({ noBottomPadding }) => (noBottomPadding ? '24px 0 0' : '24px 0')};
    gap: 32px;
  }

  .${ModalSize.LARGE} & {
    padding: ${({ noBottomPadding }) => (noBottomPadding ? '32px 0 0' : '32px 0')};
    gap: 32px;
  }

  .${ModalSize.XLARGE} & {
    padding: ${({ noBottomPadding }) => (noBottomPadding ? '24px 0 0' : '24px 0')};
    gap: 24px;
  }
`;
