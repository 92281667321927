import { uniq } from 'lodash';

import { assert } from '@amalia/ext/typescript';

import { defineAbilityFor } from '../abilities';
import { type AuthenticatedContext } from '../authenticatedContext';
import { type Ability, type ActionsEnum, type SubjectsEnum } from '../types';

import { SubsetAccessEnum } from './enums';

// Not importing the type from TypeORM to avoid importing typeorm types in the frontend bundle.
type SelectQueryBuilder = { andWhere: (value: string, parameters?: Record<string, unknown>) => void };

export class SubsetsAccessHelper {
  /**
   * Given an authenticated context, browse the rules of the current action/subject tuple to find a subset.
   *
   * @param authenticatedContext
   * @param action
   * @param subject
   * @private
   */
  public static getSubset(authenticatedContext: AuthenticatedContext, action: ActionsEnum, subject: SubjectsEnum) {
    assert(!!authenticatedContext, 'Missing authenticated context');
    const ability = defineAbilityFor(authenticatedContext);
    return this.getSubsetFromAbility(ability, action, subject);
  }

  /**
   * Given an ability, browse the rules of the current action/subject tuple to find a subset.
   *
   * @param ability
   * @param action
   * @param subject
   * @private
   */
  private static getSubsetFromAbility(ability: Ability, action: ActionsEnum, subject: SubjectsEnum) {
    assert(!!ability, 'Missing ability');
    assert(!!action, 'Missing action');
    assert(!!subject, 'Missing subject');

    const rules = ability.rulesFor(action, subject);

    if (!rules.length) {
      return SubsetAccessEnum.NOTHING;
    }

    assert(rules.length === 1, 'More than a rule eligible for subsets');

    const conditions = rules[0].conditions as { subset: SubsetAccessEnum } | null;

    return conditions?.subset || SubsetAccessEnum.NOTHING;
  }

  /**
   * Adds conditions to the query builder regarding of the current access rights.
   * @param authenticatedContext
   * @param action
   * @param subject
   * @param field
   * @param qb
   */
  public static addConditionForQueryBuilder(
    authenticatedContext: AuthenticatedContext,
    action: ActionsEnum,
    subject: SubjectsEnum,
    field: string,
    qb: SelectQueryBuilder,
  ) {
    const subsetAccess = this.getSubset(authenticatedContext, action, subject);
    this.addSubsetAccessToQueryBuilder(subsetAccess, qb, authenticatedContext, field);
  }

  private static addSubsetAccessToQueryBuilder(
    subsetAccess: SubsetAccessEnum,
    qb: SelectQueryBuilder,
    authenticatedContext: AuthenticatedContext,
    field: string,
  ) {
    switch (subsetAccess) {
      case SubsetAccessEnum.NOTHING:
        qb.andWhere('0 = 1');
        break;
      case SubsetAccessEnum.MATCH_MANAGEES: {
        const mySubordinatesIds = authenticatedContext.hierarchy
          .getSubordinates(new Date())
          .map((ta) => ta.user.id)
          .concat(authenticatedContext.user.id);

        qb.andWhere(`${field} IN (:...mymanagees)`, { mymanagees: mySubordinatesIds });
        break;
      }
      case SubsetAccessEnum.MATCH_TEAMS: {
        const myTeamIds = uniq([
          // Empty array if user is not manager of any team.
          ...authenticatedContext.hierarchy.getTeamIdsWhereUserIsManager(new Date()),
          // Add employee assignments.
          ...authenticatedContext.hierarchy.getUserTeamAssignments(new Date()).map((ta) => ta.teamId),
        ]);

        if (myTeamIds.length === 0) {
          qb.andWhere('0 = 1');
        } else {
          qb.andWhere(`${field} IN (:...myteams)`, { myteams: myTeamIds });
        }
        break;
      }
      case SubsetAccessEnum.EVERYTHING:
      default:
        // Do nothing
        break;
    }
  }
}
