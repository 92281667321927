import { type ColumnDefinition } from '../table/Table.types';

export type ColumnVisibilityState = Record<ColumnDefinition['id'], boolean>;

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export type ColumnSorting = {
  id: ColumnDefinition['id'];
  direction: SortDirection;
};

export type ColumnSortingState = ColumnSorting[];

export type ColumnOrderState = ColumnDefinition['id'][];
