import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { AlertBanner, AlertBannerVariant } from '@amalia/design-system/components';

type PayoutAndPerformanceChartAlertProps = {
  readonly recordsLength: number;
  readonly isError: boolean;
  readonly isPlanSelected: boolean;
  readonly isKpiAvailable: boolean;
};

export const PayoutAndPerformanceChartAlert = memo(function PayoutAndPerformanceChartAlert({
  recordsLength,
  isError,
  isKpiAvailable,
  isPlanSelected,
}: PayoutAndPerformanceChartAlertProps) {
  const { formatMessage } = useIntl();

  const [alert, variant] = useMemo(() => {
    let alertVariant: AlertBannerVariant;
    let alertMessage: string;
    if (isError) {
      alertVariant = AlertBannerVariant.ERROR;
      alertMessage = formatMessage({
        defaultMessage:
          'Oops! Something went wrong while loading the data. Please try again later or contact support for assistance.',
      });
    } else if (recordsLength === 0) {
      alertVariant = AlertBannerVariant.WARNING;
      alertMessage = formatMessage({
        defaultMessage:
          'No data available for the selected view. Please make sure you have valid statements for the selected view',
      });
    } else if (isPlanSelected && !isKpiAvailable) {
      alertVariant = AlertBannerVariant.WARNING;
      alertMessage = formatMessage({
        defaultMessage:
          'Payout data is available for the selected view, but KPIs are not currently accessible. Please contact your administrator for any doubt.',
      });
    }

    return [alertMessage, alertVariant];
  }, [formatMessage, isError, isKpiAvailable, isPlanSelected, recordsLength]);

  return (
    !!alert && (
      <AlertBanner
        data-testid="performance-chart-alert-banner"
        variant={variant}
      >
        {alert}
      </AlertBanner>
    )
  );
});
