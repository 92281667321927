import { memo, useMemo } from 'react';
import { FormattedList, FormattedMessage } from 'react-intl';

import { Modal, Typography } from '@amalia/design-system/components';
import { TypographyVariant } from '@amalia/design-system/meta';
import { type TeamAssignment } from '@amalia/tenants/assignments/teams/types';
import { type UserInfo } from '@amalia/tenants/users/profile/types';

import { UserRoleLabel } from '../../../../roles';

export type ConfirmDemotionModalProps = {
  readonly isOpen: boolean;
  readonly onCancel: VoidFunction;
  readonly onConfirm: VoidFunction;
  readonly nextRole: UserInfo['role'];
  readonly user: Pick<UserInfo, 'firstName' | 'id' | 'lastName' | 'role'>;
  readonly teamAssignments: Pick<TeamAssignment, 'team'>[];
};

export const ConfirmDemotionModal = memo(function ConfirmDemotionModal({
  isOpen,
  onCancel,
  onConfirm,
  nextRole,
  user,
  teamAssignments,
}: ConfirmDemotionModalProps) {
  const { firstName, lastName, role } = user;

  const teamNames = useMemo(() => teamAssignments.map(({ team }) => team?.name).filter(Boolean), [teamAssignments]);

  return (
    <Modal
      isDismissable
      data-testid="confirm-demotion-modal"
      isOpen={isOpen}
      size={Modal.Size.MEDIUM}
      variant={Modal.Variant.DEFAULT}
      onClose={onCancel}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage
              defaultMessage="{firstName} {lastName} is assigned as manager"
              values={{
                firstName,
                lastName,
              }}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage
            defaultMessage="{firstName} {lastName} is currently manager of {teamsCount, plural, one {# team} other {# teams}}: {teamNames}.{br}If you update their role from {previousRole} to {nextRole}, {firstName} {lastName} will be unassigned from {teamsCount, plural, one {this team} other {these teams}}."
            values={{
              firstName,
              lastName,
              teamsCount: teamNames.length,
              teamNames: (
                <Typography variant={TypographyVariant.BODY_BASE_BOLD}>
                  <FormattedList
                    style="short"
                    type="unit"
                    value={teamNames}
                  />
                </Typography>
              ),
              previousRole: <UserRoleLabel role={role} />,
              nextRole: <UserRoleLabel role={nextRole} />,
            }}
          />
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />
        <Modal.MainAction
          type="submit"
          onClick={onConfirm}
        >
          <FormattedMessage defaultMessage="Update role" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
