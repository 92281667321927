import { type Theme, css } from '@emotion/react';

export const formulaBuilder = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: start;
`;

export const conditionsGroups = css`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  align-items: center;
  justify-items: start;
`;

export const logicalOperator = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[400]};
`;
