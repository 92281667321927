import { useTheme } from '@emotion/react';
import { IconCheck, IconExclamationCircle, IconPencil } from '@tabler/icons-react';
import clsx from 'clsx';
import {
  type ComponentPropsWithoutRef,
  type ForwardedRef,
  forwardRef,
  memo,
  type ReactNode,
  type ChangeEvent,
  useCallback,
} from 'react';
import { useIntl } from 'react-intl';

import { TypographyVariant } from '@amalia/design-system/meta';
import { useForwardedRef } from '@amalia/ext/react/hooks';
import { type MergeAll } from '@amalia/ext/typescript';

import { IconButton } from '../../general/icon-button/IconButton';
import { IconButtonSize } from '../../general/icon-button/IconButton.types';
import { TextOverflow } from '../../general/text-overflow/TextOverflow';
import { Typography } from '../../general/typography/Typography';
import { Tooltip, type TooltipProps } from '../../overlays/tooltip/Tooltip';

import * as styles from './QuickEdit.styles';
import { quickEditTestIds } from './QuickEdit.testIds';
import { QuickEditVariant } from './QuickEdit.types';
import { type UseQuikEditOptions, useQuickEdit } from './useQuickEdit';

const QUICK_EDIT_TYPOGRAPHY_VARIANT_MAPPING: Record<QuickEditVariant, TypographyVariant> = {
  [QuickEditVariant.HEADING_1_MEDIUM]: TypographyVariant.HEADING_1_MEDIUM,
  [QuickEditVariant.HEADING_1_BOLD]: TypographyVariant.HEADING_1_BOLD,
  [QuickEditVariant.HEADING_2_MEDIUM]: TypographyVariant.HEADING_2_MEDIUM,
  [QuickEditVariant.HEADING_2_BOLD]: TypographyVariant.HEADING_2_BOLD,
  [QuickEditVariant.HEADING_3_MEDIUM]: TypographyVariant.HEADING_3_MEDIUM,
  [QuickEditVariant.HEADING_3_BOLD]: TypographyVariant.HEADING_3_BOLD,
  [QuickEditVariant.HEADING_4_MEDIUM]: TypographyVariant.HEADING_4_MEDIUM,
  [QuickEditVariant.HEADING_4_BOLD]: TypographyVariant.HEADING_4_BOLD,
  [QuickEditVariant.BODY_LARGE_REGULAR]: TypographyVariant.BODY_LARGE_REGULAR,
  [QuickEditVariant.BODY_LARGE_MEDIUM]: TypographyVariant.BODY_LARGE_MEDIUM,
  [QuickEditVariant.BODY_LARGE_BOLD]: TypographyVariant.BODY_LARGE_BOLD,
  [QuickEditVariant.BODY_BASE_MEDIUM]: TypographyVariant.BODY_BASE_MEDIUM,
  [QuickEditVariant.BODY_BASE_BOLD]: TypographyVariant.BODY_BASE_BOLD,
  [QuickEditVariant.BODY_SMALL_MEDIUM]: TypographyVariant.BODY_SMALL_MEDIUM,
  [QuickEditVariant.BODY_SMALL_BOLD]: TypographyVariant.BODY_SMALL_BOLD,
  [QuickEditVariant.BODY_XSMALL_MEDIUM]: TypographyVariant.BODY_XSMALL_MEDIUM,
  [QuickEditVariant.BODY_XSMALL_BOLD]: TypographyVariant.BODY_XSMALL_BOLD,
} as const;

const QUICK_EDIT_BUTTON_SIZE_MAPPING: Record<QuickEditVariant, IconButtonSize> = {
  [QuickEditVariant.HEADING_1_MEDIUM]: IconButtonSize.MEDIUM,
  [QuickEditVariant.HEADING_1_BOLD]: IconButtonSize.MEDIUM,
  [QuickEditVariant.HEADING_2_MEDIUM]: IconButtonSize.MEDIUM,
  [QuickEditVariant.HEADING_2_BOLD]: IconButtonSize.MEDIUM,
  [QuickEditVariant.HEADING_3_MEDIUM]: IconButtonSize.MEDIUM,
  [QuickEditVariant.HEADING_3_BOLD]: IconButtonSize.MEDIUM,
  [QuickEditVariant.HEADING_4_MEDIUM]: IconButtonSize.MEDIUM,
  [QuickEditVariant.HEADING_4_BOLD]: IconButtonSize.MEDIUM,
  [QuickEditVariant.BODY_LARGE_REGULAR]: IconButtonSize.SMALL,
  [QuickEditVariant.BODY_LARGE_MEDIUM]: IconButtonSize.SMALL,
  [QuickEditVariant.BODY_LARGE_BOLD]: IconButtonSize.SMALL,
  [QuickEditVariant.BODY_BASE_MEDIUM]: IconButtonSize.SMALL,
  [QuickEditVariant.BODY_BASE_BOLD]: IconButtonSize.SMALL,
  [QuickEditVariant.BODY_SMALL_MEDIUM]: IconButtonSize.SMALL,
  [QuickEditVariant.BODY_SMALL_BOLD]: IconButtonSize.SMALL,
  [QuickEditVariant.BODY_XSMALL_MEDIUM]: IconButtonSize.SMALL,
  [QuickEditVariant.BODY_XSMALL_BOLD]: IconButtonSize.SMALL,
} as const;

const QUICK_EDIT_ERROR_ICON_SIZE_MAPPING: Record<QuickEditVariant, number> = {
  [QuickEditVariant.HEADING_1_MEDIUM]: 20,
  [QuickEditVariant.HEADING_1_BOLD]: 20,
  [QuickEditVariant.HEADING_2_MEDIUM]: 20,
  [QuickEditVariant.HEADING_2_BOLD]: 20,
  [QuickEditVariant.HEADING_3_MEDIUM]: 18,
  [QuickEditVariant.HEADING_3_BOLD]: 18,
  [QuickEditVariant.HEADING_4_MEDIUM]: 18,
  [QuickEditVariant.HEADING_4_BOLD]: 18,
  [QuickEditVariant.BODY_LARGE_REGULAR]: 16,
  [QuickEditVariant.BODY_LARGE_MEDIUM]: 16,
  [QuickEditVariant.BODY_LARGE_BOLD]: 16,
  [QuickEditVariant.BODY_BASE_MEDIUM]: 16,
  [QuickEditVariant.BODY_BASE_BOLD]: 16,
  [QuickEditVariant.BODY_SMALL_MEDIUM]: 14,
  [QuickEditVariant.BODY_SMALL_BOLD]: 14,
  [QuickEditVariant.BODY_XSMALL_MEDIUM]: 14,
  [QuickEditVariant.BODY_XSMALL_BOLD]: 14,
} as const;

export type QuickEditProps = MergeAll<
  [
    Omit<ComponentPropsWithoutRef<'input'>, 'onBlur'>,
    Pick<UseQuikEditOptions, 'isEditing' | 'onChange' | 'onChangeIsEditing' | 'schema'>,
    {
      /** Content must be a string. */
      value?: string;
      /** Override value in read-only mode. */
      formattedValue?: ReactNode;
      /** Override schema error if needed. */
      error?: ReactNode;
      /** Text variant. */
      variant?: QuickEditVariant;
      /** Override default tooltip of edit button. */
      editTooltip?: TooltipProps['content'];
    },
  ]
>;

const QuickEditForwardRef = forwardRef(function QuickEdit(
  {
    value = '',
    formattedValue = undefined,
    onChange = undefined,
    isEditing: controlledIsEditing = undefined,
    onChangeIsEditing: controlledOnChangeIsEditing = undefined,
    schema = undefined,
    error: propsError = undefined,
    variant = QuickEditVariant.BODY_BASE_MEDIUM,
    disabled = false,
    className = undefined,
    type = 'text',
    editTooltip,
    ...props
  }: QuickEditProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const inputRef = useForwardedRef<HTMLInputElement>(ref);

  const {
    internalValue,
    validationError,
    isInEditMode,
    isLoading,
    setInternalValue,
    handleSubmit,
    handleStartEditing,
    handleConfirm,
  } = useQuickEdit({
    value,
    onChange,
    isEditing: controlledIsEditing,
    onChangeIsEditing: controlledOnChangeIsEditing,
    schema,
    disabled,
    inputRef,
  });

  const error = propsError || validationError;

  const textRef = useForwardedRef<HTMLDivElement>();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setInternalValue(event.target.value),
    [setInternalValue],
  );

  return (
    <form
      className={clsx(className, variant, { [styles.HAS_ERROR_CLASSNAME]: !!error })}
      css={styles.quickEdit}
      onSubmit={handleSubmit}
    >
      {!!isInEditMode && (
        <input
          {...props}
          ref={inputRef}
          aria-invalid={props['aria-invalid'] || !!error}
          readOnly={isLoading}
          type={type}
          value={internalValue}
          css={[
            theme.ds.typographies[QUICK_EDIT_TYPOGRAPHY_VARIANT_MAPPING[variant]],
            styles.quickEditInput(textRef.current?.getBoundingClientRect().width),
          ]}
          onBlur={validationError ? undefined : handleConfirm}
          onChange={handleChange}
        />
      )}

      <Typography
        ref={textRef}
        aria-hidden={isInEditMode}
        as={TextOverflow}
        css={[styles.quickEditReadonly, isInEditMode && styles.offscreen]}
        variant={QUICK_EDIT_TYPOGRAPHY_VARIANT_MAPPING[variant]}
      >
        {formattedValue ?? value}
      </Typography>

      {!disabled && (
        <div css={styles.indicatorsContainer}>
          {!!error && !!isInEditMode && (
            <Tooltip content={error}>
              <IconExclamationCircle
                color={theme.ds.colors.danger[500]}
                data-testid={quickEditTestIds.errorAnchor}
                size={QUICK_EDIT_ERROR_ICON_SIZE_MAPPING[variant]}
              />
            </Tooltip>
          )}

          {isInEditMode ? (
            <IconButton
              key="submit"
              data-testid={quickEditTestIds.submitButton}
              disabled={!!validationError}
              icon={<IconCheck />}
              isLoading={isLoading}
              label={formatMessage({ defaultMessage: 'Confirm' })}
              size={QUICK_EDIT_BUTTON_SIZE_MAPPING[variant]}
              variant={IconButton.Variant.SUCCESS}
              onClick={handleConfirm}
            />
          ) : (
            <IconButton
              key="edit"
              data-testid={quickEditTestIds.editButton}
              icon={<IconPencil />}
              label={editTooltip ?? formatMessage({ defaultMessage: 'Edit' })}
              size={QUICK_EDIT_BUTTON_SIZE_MAPPING[variant]}
              onClick={handleStartEditing}
            />
          )}
        </div>
      )}
    </form>
  );
});

export const QuickEdit = Object.assign(memo(QuickEditForwardRef), {
  Variant: QuickEditVariant,
});
