import { type Theme, css } from '@emotion/react';

import { RadioButtonGroupSize } from '../RadioButtonGroup.types';

export const radioButtonOption = (theme: Theme) => css`
  display: flex;

  transition: ${theme.ds.transitions.default()};

  color: ${theme.ds.colors.gray[500]};
  background-color: ${theme.ds.colors.gray[50]};

  border-left: 1px solid ${theme.ds.colors.gray[100]};

  cursor: pointer;

  &.checked + & {
    border-color: ${theme.ds.colors.gray[50]};
  }

  &.disabled {
    cursor: not-allowed;

    color: ${theme.ds.colors.gray[300]};
    background-color: ${theme.ds.colors.gray[100]};
    border-color: ${theme.ds.colors.gray[200]};

    &.checked + & {
      border-color: ${theme.ds.colors.gray[100]};
    }

    &.checked {
      color: ${theme.ds.colors.gray[400]};
      background-color: ${theme.ds.colors.gray[200]};

      &:not(:first-of-type) {
        border-color: ${theme.ds.colors.gray[200]};
      }
    }
  }

  &:not(.disabled) {
    &.checked {
      color: ${theme.ds.colors.primary[500]};
      background-color: ${theme.ds.colors.primary[50]};

      &:not(:first-of-type) {
        border-color: ${theme.ds.colors.primary[50]};
      }
    }
  }

  &.${RadioButtonGroupSize.SMALL} {
    padding: 2px 6px 2px 5px;
    min-height: 22px;
  }

  &.${RadioButtonGroupSize.MEDIUM} {
    padding: 4.5px 12px 4.5px 11px;
    min-height: 30px;
  }

  &:first-of-type {
    border-left: none;

    border-radius: ${theme.ds.borderRadiuses.squared} 0 0 ${theme.ds.borderRadiuses.squared};

    &.${RadioButtonGroupSize.SMALL} {
      padding-left: 6px;
    }

    &.${RadioButtonGroupSize.MEDIUM} {
      padding-left: 12px;
    }
  }

  &:last-of-type {
    border-radius: 0 ${theme.ds.borderRadiuses.squared} ${theme.ds.borderRadiuses.squared} 0;
  }
`;

export const content = css`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const hidden = css`
  display: block;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;

  appearance: none;
  position: absolute;
  outline: none;
`;

export const icon = css`
  flex: none;

  display: flex;
  align-items: center;
`;

export const help = css`
  flex: none;
`;
