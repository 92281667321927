import { get, noop } from 'lodash';
import { cloneElement, memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type FormulaBuilderFunctionBlockUserForm } from '@amalia/amalia-lang/formula/form/types';
import {
  FormulaBuilderUserOperatorNoArgs,
  FormulaBuilderUserOperatorOneArg,
  ValueOrAttributeType,
} from '@amalia/amalia-lang/formula/types';
import { Dropdown, Tooltip } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';
import { isEnum } from '@amalia/ext/typescript';

import { useGetFormulaBuilderAttributeLabel } from '../../hooks/use-get-formula-builder-attribute-label/useGetFormulaBuilderAttributeLabel';
import { useFormulaBuilderContext } from '../formula-builder/FormulaBuilder.context';
import { FormulaConditionTag, type FormulaConditionTagProps } from '../formula-condition-tag/FormulaConditionTag';

import { operatorTooltipMessage, valueOrAttributeValuesMessages } from './FormulaConditionTagFunctionUser.messages';
import * as styles from './FormulaConditionTagFunctionUser.styles';
import { FunctionUserPopover } from './function-user-popover/FunctionUserPopover';
import { OPERATOR_ICON_MAPPING } from './function-user-popover/operator.mappers';

export type FormulaConditionTagFunctionUserProps = {
  readonly condition: FormulaBuilderFunctionBlockUserForm;
  readonly path: string;
  readonly onChange?: FormulaConditionTagProps<FormulaBuilderFunctionBlockUserForm>['onChange'];
  readonly onDelete?: FormulaConditionTagProps<FormulaBuilderFunctionBlockUserForm>['onDelete'];
};

export const FormulaConditionTagFunctionUser = memo(function FormulaConditionTagFunctionUser({
  condition,
  path,
  onChange = noop,
  onDelete = undefined,
}: FormulaConditionTagFunctionUserProps) {
  const { activeConditionId, errors: builderErrors } = useFormulaBuilderContext();
  const isActive = condition.id === activeConditionId;

  const getFormulaBuilderAttributeLabel = useGetFormulaBuilderAttributeLabel();
  const propertyName = getFormulaBuilderAttributeLabel(condition.args[0]);

  const isInitialOpen = !!condition.isDraft && isActive;
  const { isOpen, setOpen, setOpenFalse } = useBoolState(isInitialOpen, 'open');

  const errors: string[] = useMemo(
    () =>
      [
        get(builderErrors, `${path}.args[1].value`) as string | undefined,
        get(builderErrors, `${path}.args[1].caseSensitive`) as string | undefined,
      ].filter(Boolean),
    [builderErrors, path],
  );
  const getTooltipContent = () => {
    if (!condition.operator) {
      return <FormattedMessage defaultMessage="Click to edit condition" />;
    }

    if (isEnum(condition.operator, FormulaBuilderUserOperatorNoArgs)) {
      return (
        <FormattedMessage
          defaultMessage="{operator}"
          values={{
            operator: <FormattedMessage {...operatorTooltipMessage[condition.operator]} />,
          }}
        />
      );
    }

    return (
      <FormattedMessage
        defaultMessage="{operator}: {value}"
        values={{
          operator: <FormattedMessage {...operatorTooltipMessage[condition.operator]} />,
          value: condition.args[1] && (
            <FormattedMessage
              {...valueOrAttributeValuesMessages[condition.args[1].type]}
              values={{
                value:
                  condition.args[1].type === ValueOrAttributeType.VALUE
                    ? condition.args[1].value
                    : getFormulaBuilderAttributeLabel(condition.args[1]),
              }}
            />
          ),
        }}
      />
    );
  };

  const getTagLabel = () => {
    if (isEnum(condition.operator, FormulaBuilderUserOperatorNoArgs)) {
      return (
        <div css={styles.tagLabelContainer}>
          {propertyName}
          {cloneElement(OPERATOR_ICON_MAPPING[condition.operator], {
            size: 16,
          })}
        </div>
      );
    }

    if (isEnum(condition.operator, FormulaBuilderUserOperatorOneArg)) {
      return (
        <div css={styles.tagLabelContainer}>
          {propertyName}
          {cloneElement(OPERATOR_ICON_MAPPING[condition.operator], {
            size: 16,
            // Need to add display flex here to prevent the icon from shifting to the right
            style: {
              display: 'flex',
            },
          })}
          {condition.args[1].type === ValueOrAttributeType.VALUE
            ? condition.args[1].value
            : getFormulaBuilderAttributeLabel(condition.args[1])}
        </div>
      );
    }

    return propertyName;
  };
  return (
    <Dropdown
      isOpen={isOpen}
      content={
        <FunctionUserPopover
          condition={condition}
          onChange={onChange}
          onClose={setOpenFalse}
        />
      }
      onChangeIsOpen={setOpen}
    >
      <FormulaConditionTag
        condition={condition}
        errors={errors}
        label={
          <Tooltip
            content={getTooltipContent()}
            title={
              propertyName ? (
                <FormattedMessage
                  defaultMessage="Filter on “{propertyName}”"
                  values={{ propertyName }}
                />
              ) : null
            }
          >
            <span>{getTagLabel()}</span>
          </Tooltip>
        }
        onDelete={onDelete}
      />
    </Dropdown>
  );
});
