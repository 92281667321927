import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useGetFormulaBuilderAttributeLabel } from '@amalia/amalia-lang/formula/components';
import { type ValueOrAttributeForm } from '@amalia/amalia-lang/formula/form/types';
import { ValueOrAttributeType } from '@amalia/amalia-lang/formula/types';

import { valueOrAttributeValuesMessages } from '../formula-condition-tag-function-string/FormulaConditionTagFunctionString.messages';

export type FormulaConditionOneArgGenericTagProps = {
  readonly propertyName: string;
  readonly arg?: ValueOrAttributeForm;
};

export const FormulaConditionOneArgGenericTag = memo(function FormulaConditionOneArgGenericTag({
  propertyName,
  arg,
}: FormulaConditionOneArgGenericTagProps) {
  const getFormulaBuilderAttributeLabel = useGetFormulaBuilderAttributeLabel();

  return (
    <FormattedMessage
      defaultMessage="{propertyName}{hasValue, select, true {: {value}} other {}}"
      values={{
        propertyName,
        hasValue:
          !!arg && (arg.type === ValueOrAttributeType.VALUE ? !!arg.value : !!getFormulaBuilderAttributeLabel(arg)),
        value: !!arg && (
          <FormattedMessage
            {...valueOrAttributeValuesMessages[arg.type]}
            values={{
              value: arg.type === ValueOrAttributeType.VALUE ? arg.value : getFormulaBuilderAttributeLabel(arg),
            }}
          />
        ),
      }}
    />
  );
});
