import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { Layout } from '@amalia/core/layout/components';
import { routes } from '@amalia/core/routes';
import { AmaliaHead } from '@amalia/design-system/ext';
import { RootRoutes } from '@amalia/ext/react-router-dom';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { AbilityRouteProtector } from '@amalia/kernel/auth/state';

const PlanDesignerWrapped = lazy(() => import('./DesignerNew/PlanDesignerWrapped'));
const ToDesigner = lazy(() => import('./DesignerNew/ToDesigner'));

export const DesignerRoutes = memo(function DesignerRoutes() {
  const { formatMessage } = useIntl();

  return (
    <Layout currentPage="designer">
      <RootRoutes>
        <Route
          path={routes.DESIGNER_OBJECT}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.modify, SubjectsEnum.Plan)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Designer' })} />
              <PlanDesignerWrapped />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.DESIGNER_PLAN}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.modify, SubjectsEnum.Plan)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Designer' })} />
              <PlanDesignerWrapped />
            </AbilityRouteProtector>
          }
        />

        {/* Link to designer without a plan => load a plan and redirect to designer. */}
        <Route
          path={routes.DESIGNER}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.modify, SubjectsEnum.Plan)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Designer' })} />
              <ToDesigner />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});
