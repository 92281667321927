import {
  type VariableWithPlan,
  type CreateVariable,
  type UpdateVariable,
  type Variable,
  type VariableObjectsEnum,
} from '@amalia/amalia-lang/tokens/types';
import { http } from '@amalia/core/http/client';

export class VariablesApiClient {
  public static async list(types?: VariableObjectsEnum[], userIds?: string[], planIds?: string[]): Promise<Variable[]> {
    const qs = [
      ...(types ? types.map((t) => `types=${t}`) : []),
      ...(userIds ? userIds.map((u) => `userIds=${u}`) : []),
      ...(planIds ? planIds.map((u) => `planIds=${u}`) : []),
    ]
      .filter(Boolean)
      .join('&');

    const { data } = await http.get<Variable[]>(`/variables/?${qs}`);
    return data;
  }

  public static async get(variableId: string): Promise<Variable> {
    const { data } = await http.get<Variable>(`/variables/${variableId}`);
    return data;
  }

  public static async create(
    variable: CreateVariable,
    createdFrom?: { ruleId?: string; planId?: string; activeFilterId?: string },
  ): Promise<Variable> {
    const { data } = await http.post<Variable>('/variables', variable, {
      params: {
        planId: createdFrom?.planId,
        ruleId: createdFrom?.ruleId,
        activeFilterId: createdFrom?.activeFilterId,
      },
    });
    return data;
  }

  public static async update(variable: UpdateVariable, updatedFrom?: { planId?: string }): Promise<Variable> {
    const { data } = await http.put<Variable>('/variables/', variable, {
      params: {
        planId: updatedFrom?.planId,
      },
    });
    return data;
  }

  public static async delete(variableId: string, planId?: string): Promise<void> {
    await http.delete(`/variables/${variableId}`, {
      params: {
        planId,
      },
    });
  }

  public static async duplicateInNewContext(
    variablesId: string[],
    from: { planId: string; ruleId: string; activeFilterId?: string },
  ): Promise<{ isErrorWhileDuplicating: boolean }> {
    const { data } = await http.post<{ isErrorWhileDuplicating: boolean }>(
      '/variables/duplicateInContext',
      variablesId,
      {
        params: {
          planId: from.planId,
          ruleId: from.ruleId,
          activeFilterId: from.activeFilterId,
        },
      },
    );
    return data;
  }

  public static async getByObjectId(customDefinitionObjectId: string) {
    const { data } = await http.get<VariableWithPlan[]>(`/variables/customObject/${customDefinitionObjectId}`);
    return data;
  }
}
