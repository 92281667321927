import { http } from '@amalia/core/http/client';
import { type Notification, type PaginatedResponse } from '@amalia/core/types';

export class NotificationsApiClient {
  public static async fetchNotifications(
    page: number,
    pageSize: number = 24,
  ): Promise<{
    paginatedNotifications: PaginatedResponse<Notification>;
    unreadCount: number;
  }> {
    const {
      data: { unreadCount, ...rest },
    } = await http.get<PaginatedResponse<Notification> & { unreadCount: number }>('/notifications', {
      params: { page, limit: pageSize },
    });
    return {
      paginatedNotifications: rest,
      unreadCount,
    };
  }

  public static async fetchUnreadCount(): Promise<number> {
    // Cannot use limit: 0 because the plugin returns all notifications instead.
    return (await NotificationsApiClient.fetchNotifications(1, 1)).unreadCount;
  }

  public static async markAsRead(lastCheck: Date): Promise<void> {
    await http.post('/notifications/read-notifications', { lastCheck });
  }
}
