import {
  DndContext,
  DragOverlay,
  PointerSensor,
  defaultDropAnimationSideEffects,
  useSensor,
  useSensors,
  type DropAnimation,
} from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IconPlus } from '@tabler/icons-react';
import { Fragment, memo, useCallback, useMemo, useState, type ComponentProps } from 'react';
import { FormattedMessage } from 'react-intl';

import { isForbiddenError } from '@amalia/core/http/client';
import { Button } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';
import { useUrlState } from '@amalia/ext/react-router-dom';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { ChartSVG } from '@amalia/reporting/dashboards-v2/components';
import {
  useCreateDashboardChart,
  useFindChartsOnDashboard,
  useFindDashboard,
  useGetHomeDashboardId,
  usePatchDashboardChart,
} from '@amalia/reporting/dashboards-v2/state';
import {
  type ChartType,
  type CreateDashboardChartRequest,
  type DashboardChart,
  type PatchDashboardChartRequest,
} from '@amalia/reporting/dashboards-v2/types';

import { DashboardChartWrapper } from './charts/DashboardChartWrapper';
import { DashboardChartWrapperDragOverlay } from './charts/DashboardChartWrapperDragOverlay';
import { DashboardV2DetailsForbiddenLayout } from './DashboardV2DetailsForbiddenLayout';
import { DashboardV2Header } from './DashboardV2DetailsHeader';
import { useChartLayoutConfiguration } from './hooks/useChartLayoutConfiguration';
import { DashboardChartConfigurationModal } from './modals/configuration-modal/DashboardChartConfigurationModal';
import { DashboardChartDeleteModal } from './modals/DashboardChartDeleteModal';
import { DashboardSetHomeModal } from './modals/DashboardSetHomeModal';
import { DashboardShareModal } from './modals/DashboardShareModal';
import { DashboardUnsetHomeModal } from './modals/DashboardUnsetHomeModal';

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '1',
      },
    },
  }),
};

const CreateChartContainer = styled.div`
  width: 680px;
  height: 275px;
  background-image: url(${ChartSVG});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const ChartsGridLayout = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(12, 1fr);
  margin-top: 40px;
`;

type DashboardV2DetailsProps = {
  readonly dashboardId: string;
  readonly readOnly?: boolean;
};

export const DashboardV2Details = memo(function DashboardV2Details({
  dashboardId,
  readOnly = false,
}: DashboardV2DetailsProps) {
  const [isShareModalOpenUrlState, setIsShareModalOpenUrlState] = useUrlState<string>('share', '');

  const [selectedChart, setSelectedChart] = useState<DashboardChart<ChartType> | null>(null);

  const { isChartConfigurationModalOpen, setChartConfigurationModalOpenTrue, setChartConfigurationModalOpenFalse } =
    useBoolState(false, 'chartConfigurationModalOpen');

  const { isShareDashboardModalOpen, setShareDashboardModalOpenTrue, setShareDashboardModalOpenFalse } = useBoolState(
    isShareModalOpenUrlState === 'true',
    'shareDashboardModalOpen',
  );

  const { isChartDeleteModalOpen, setChartDeleteModalOpenTrue, setChartDeleteModalOpenFalse } = useBoolState(
    false,
    'chartDeleteModalOpen',
  );

  const { isSetHomeModalOpen, setSetHomeModalOpenFalse, setSetHomeModalOpenTrue } = useBoolState(
    false,
    'setHomeModalOpen',
  );
  const { isUnsetHomeModalOpen, setUnsetHomeModalOpenFalse, setUnsetHomeModalOpenTrue } = useBoolState(
    false,
    'unsetHomeModalOpen',
  );

  const { data: dashboard, error: findDashboardError } = useFindDashboard(dashboardId);
  const { data: dashboardCharts = [] } = useFindChartsOnDashboard(dashboardId);
  const { data: dashboardAsHome } = useGetHomeDashboardId();

  const isDashboardSetAsHome = dashboardAsHome?.dashboardId === dashboardId;

  const { mutate: createChart } = useCreateDashboardChart(dashboardId);
  const { mutate: patchChart } = usePatchDashboardChart(dashboardId, selectedChart?.id ?? null);

  const ability = useAbilityContext();
  const canModifyDashboard = ability.can(ActionsEnum.modify, SubjectsEnum.DashboardsV2) && !readOnly;

  const {
    chartLayoutConfigDragged,
    chartsLayoutConfig,
    dashboardDragged,
    handleChartLayoutSizeChange,
    handleDragEnd,
    handleDragOver,
    handleDragStart,
  } = useChartLayoutConfiguration(dashboardId, dashboard?.chartsLayoutConfig, dashboardCharts);

  const resetChartConfigurationModal = useCallback(() => {
    setSelectedChart(null);
    setChartConfigurationModalOpenFalse();
  }, [setChartConfigurationModalOpenFalse]);

  const resetChartDeleteModal = useCallback(() => {
    setSelectedChart(null);
    setChartDeleteModalOpenFalse();
  }, [setChartDeleteModalOpenFalse]);

  const handleCreateChart = useCallback(
    (createDashboardRequest: CreateDashboardChartRequest<ChartType>) => {
      createChart(createDashboardRequest, { onSuccess: setChartConfigurationModalOpenFalse });
    },
    [createChart, setChartConfigurationModalOpenFalse],
  );

  const handlePatchChart = useCallback(
    (patchDashboardRequest: PatchDashboardChartRequest<ChartType>) => {
      patchChart(patchDashboardRequest, {
        onSuccess: () => resetChartConfigurationModal(),
      });
    },
    [patchChart, resetChartConfigurationModal],
  );

  const handleClickEditChart = useCallback(
    (chart: DashboardChart<ChartType>) => () => {
      setSelectedChart(chart);
      setChartConfigurationModalOpenTrue();
    },
    [setChartConfigurationModalOpenTrue],
  );

  const handleClickDeleteChart = useCallback(
    (chart: DashboardChart<ChartType>) => () => {
      setSelectedChart(chart);
      setChartDeleteModalOpenTrue();
    },
    [setChartDeleteModalOpenTrue],
  );

  const sensors = useSensors(useSensor(PointerSensor));

  const chartConfigurationsProps: ComponentProps<typeof DashboardChartConfigurationModal> = useMemo(
    () => ({
      onClose: resetChartConfigurationModal,
      onSubmit: selectedChart ? handlePatchChart : handleCreateChart,
      dashboardChart: selectedChart,
    }),
    [selectedChart, resetChartConfigurationModal, handlePatchChart, handleCreateChart],
  );

  const handleCloseShareModal = useCallback(() => {
    setShareDashboardModalOpenFalse();
    if (isShareModalOpenUrlState) {
      setIsShareModalOpenUrlState('false');
    }
  }, [isShareModalOpenUrlState, setIsShareModalOpenUrlState, setShareDashboardModalOpenFalse]);

  if (isForbiddenError(findDashboardError)) {
    return <DashboardV2DetailsForbiddenLayout />;
  }

  return (
    <Fragment>
      <DashboardV2Header
        dashboard={dashboard}
        isCreateButtonDisplayed={!!dashboardCharts.length}
        isSetAsHomePage={isDashboardSetAsHome}
        readOnly={readOnly}
        onOpenCreateModal={setChartConfigurationModalOpenTrue}
        onOpenSetHomeModal={setSetHomeModalOpenTrue}
        onOpenShareModal={setShareDashboardModalOpenTrue}
        onOpenUnsetHomeModal={setUnsetHomeModalOpenTrue}
      />
      <div
        css={css`
          margin-top: 40px;
        `}
      >
        {!dashboardCharts.length && !!canModifyDashboard && (
          <CreateChartContainer>
            <Button
              icon={<IconPlus />}
              onClick={setChartConfigurationModalOpenTrue}
            >
              <FormattedMessage defaultMessage="Create a chart" />
            </Button>
          </CreateChartContainer>
        )}

        {!!dashboardCharts.length && (
          <DndContext
            sensors={sensors}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
            onDragStart={handleDragStart}
          >
            <SortableContext
              items={chartsLayoutConfig}
              strategy={() => null}
            >
              <ChartsGridLayout>
                {chartsLayoutConfig.map((chartLayoutConfig) => {
                  const chart = dashboardCharts.find((ch) => ch.id === chartLayoutConfig.chartId);
                  if (!chart) {
                    return null;
                  }
                  return (
                    <DashboardChartWrapper
                      key={chartLayoutConfig.id}
                      chartLayoutConfig={chartLayoutConfig}
                      dashboardChart={chart}
                      isEditModeActive={!!canModifyDashboard}
                      onChartLayoutSizeChange={handleChartLayoutSizeChange}
                      onClickDelete={handleClickDeleteChart(chart)}
                      onClickEdit={handleClickEditChart(chart)}
                    />
                  );
                })}
              </ChartsGridLayout>
            </SortableContext>
            <DragOverlay dropAnimation={dropAnimation}>
              {!!dashboardDragged && (
                <DashboardChartWrapperDragOverlay
                  chartLayoutConfig={chartLayoutConfigDragged!}
                  dashboardChart={dashboardDragged}
                />
              )}
            </DragOverlay>
          </DndContext>
        )}
      </div>

      {!!isChartConfigurationModalOpen && <DashboardChartConfigurationModal {...chartConfigurationsProps} />}

      {!!selectedChart && !!isChartDeleteModalOpen && (
        <DashboardChartDeleteModal
          chartId={selectedChart.id}
          chartName={selectedChart.name}
          dashboardId={dashboardId}
          onCloseModal={resetChartDeleteModal}
        />
      )}
      {!!dashboard && !!canModifyDashboard && (
        <DashboardShareModal
          dashboardId={dashboard.id}
          isOpen={isShareDashboardModalOpen}
          onCloseModal={handleCloseShareModal}
        />
      )}
      {!!dashboard && (
        <DashboardSetHomeModal
          dashboardId={dashboard.id}
          dashboardName={dashboard.name}
          isOpen={isSetHomeModalOpen}
          onCloseModal={setSetHomeModalOpenFalse}
        />
      )}
      {!!dashboard && (
        <DashboardUnsetHomeModal
          dashboardName={dashboard.name}
          isOpen={isUnsetHomeModalOpen}
          onCloseModal={setUnsetHomeModalOpenFalse}
        />
      )}
    </Fragment>
  );
});
