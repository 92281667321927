import {
  type FormEventHandler,
  type ReactNode,
  type RefObject,
  useCallback,
  useLayoutEffect,
  useState,
  type MouseEvent,
} from 'react';
import { type Schema, ValidationError } from 'yup';

import { useBoolState, useUpdateEffect } from '@amalia/ext/react/hooks';
import { toError } from '@amalia/ext/typescript';

const getValidationError = (value: string, schema?: Schema) => {
  if (!schema) {
    return null;
  }

  try {
    schema.validateSync(value);
    return null;
  } catch (err) {
    if (ValidationError.isError(err)) {
      return err.message;
    }

    return toError(err).message;
  }
};

export type UseQuikEditOptions = {
  /** Content must be a string. */
  value: string;
  /** Callback when clicking confirm. */
  onChange?: (value: string) => Promise<void> | void;
  /** Validation schema. Must be synchronous. */
  schema?: Schema;
  /** Is disabled. */
  disabled?: boolean;
  /** Is in edit mode. */
  isEditing?: boolean;
  /** Callback when editing mode changes. */
  onChangeIsEditing?: (isEditing: boolean) => void;
  /** Quick edit input ref. */
  inputRef: RefObject<HTMLInputElement>;
};

export type UseQuickEditValue = {
  /** Value of the input. */
  internalValue: string;
  /** Validation error. */
  validationError: ReactNode;
  /** Is in edit mode. */
  isEditing: boolean;
  /** Is in edit mode and not disabled. */
  isInEditMode: boolean;
  /** Is loading. */
  isLoading: boolean;
  /** Set the internal value. */
  setInternalValue: (value: string) => void;
  /** Set the edit mode. */
  onChangeIsEditing: (isEditing: boolean) => void;
  /** Handle submit. */
  handleSubmit: FormEventHandler<HTMLFormElement>;
  /** Handle start editing. */
  handleStartEditing: () => void;
  /** Handle confirm. */
  handleConfirm: () => Promise<void>;
};

export const useQuickEdit = ({
  value,
  onChange,
  schema,
  disabled,
  isEditing: controlledIsEditing,
  onChangeIsEditing: controlledOnChangeIsEditing,
  inputRef,
}: UseQuikEditOptions): UseQuickEditValue => {
  const [internalValue, setInternalValue] = useState(value);
  const [uncontrolledIsEditing, setUncontrolledIsEditing] = useState(controlledIsEditing ?? false);
  const { isLoading, setLoadingTrue, setLoadingFalse } = useBoolState(false, 'loading');

  const isEditing = controlledIsEditing ?? uncontrolledIsEditing;
  const onChangeIsEditing = controlledOnChangeIsEditing ?? setUncontrolledIsEditing;

  // When disabled, consider the control as not being in edit mode.
  const isInEditMode = isEditing && !disabled;

  // If the value changes, update the internal value.
  useLayoutEffect(() => {
    setInternalValue(value);
  }, [value]);

  // Focus the input when entering edit mode. Need to do it in an effect because edit mode can be controlled by the parent.
  useUpdateEffect(() => {
    if (isInEditMode) {
      inputRef.current?.focus();
    }
  }, [isInEditMode, inputRef]);

  const handleConfirm = useCallback(async () => {
    setLoadingTrue();
    try {
      await onChange?.(internalValue);
      onChangeIsEditing(false);
    } finally {
      setLoadingFalse();
    }
  }, [internalValue, onChange, onChangeIsEditing, setLoadingFalse, setLoadingTrue]);

  // Validate the value when it changes. Error passed as prop overrides the validation error.
  const validationError: ReactNode = getValidationError(internalValue, schema);

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      if (!validationError) {
        handleConfirm().catch(() => {
          // Do nothing. Error should be caught in onChange.
        });
      }
    },
    [validationError, handleConfirm],
  );

  // Cannot use useBoolState because onChangeIsEditing can be controlled by the parent.
  const handleStartEditing = useCallback(
    (event?: MouseEvent<HTMLButtonElement>) => {
      event?.stopPropagation();
      onChangeIsEditing(true);
    },
    [onChangeIsEditing],
  );

  return {
    internalValue,
    validationError,
    isEditing,
    isInEditMode,
    isLoading,
    setInternalValue,
    onChangeIsEditing,
    handleSubmit,
    handleStartEditing,
    handleConfirm,
  };
};
