export enum OmolioMessageType {
  answer = 'answer',
  question = 'question',
}

export type OmolioMessage = {
  id: string;
  conversationId: string;
  content: string;
  type: OmolioMessageType;
};
