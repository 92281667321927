import { type ReactNode } from 'react';

/**
 * Throw this error in case of a handlable error when submitting a form.
 * E.g. when submitting a form but the backend returns 4XX because of a duplicate identifier.
 */
export class SubmissionError extends Error {
  public errorMessage: ReactNode = null;

  public constructor(errorMessage: ReactNode) {
    super('Submission error');

    this.errorMessage = errorMessage;

    // Remove this constructor from stack trace.
    Error.captureStackTrace(this, SubmissionError);
  }
}
