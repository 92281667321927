import { sortBy } from 'lodash';

import { type FormulaEditorToken } from '@amalia/amalia-lang/formula/components';
import { TokenType } from '@amalia/amalia-lang/tokens/types';

export const sortTokens = (tokens: FormulaEditorToken[]) =>
  sortBy(tokens, (token) => {
    switch (token.type) {
      case TokenType.FUNCTION:
        return 1;
      case TokenType.KEYWORD:
        return 2;
      case TokenType.QUOTA:
        return 3;
      case TokenType.FILTER:
        return 4;
      case TokenType.PROPERTY:
        return 5;
      case TokenType.FIELD:
        return 6;
      case TokenType.VARIABLE:
        return 7;
      case TokenType.VIRTUAL_PROPERTY:
        return 8;
      case TokenType.LINK:
        return 9;
      default:
        return 10;
    }
  });
