import { css, type Theme } from '@emotion/react';

export const leftBarUser =
  (isImpersonating = false) =>
  (theme: Theme) => css`
    height: 56px;
    border-top: 1px solid ${theme.ds.colors.gray[100]};
    background-color: ${theme.ds.colors.gray[isImpersonating ? 50 : 0]};

    display: flex;
    align-items: center;
    gap: 8px;

    padding: 0 6px 0 14px;
  `;

export const userName = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  flex: 1;
  min-width: 0;
`;
