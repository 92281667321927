import { css } from '@emotion/react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertBanner, Typography } from '@amalia/design-system/components';

import { LayoutUnauthenticatedContent } from '../layout-unauthenticated/content/LayoutUnauthenticatedContent';
import { LayoutUnauthenticated } from '../layout-unauthenticated/LayoutUnauthenticated';

import { ReactComponent as AccountNotInvitedIllustration } from './accountNotInvitedIllustration.svg';

export const AccountNotInvitedPage = memo(function AccountNotInvitedPage() {
  return (
    <LayoutUnauthenticated>
      <LayoutUnauthenticatedContent
        title={<FormattedMessage defaultMessage="Your account is being prepared!" />}
        footer={
          <Typography
            variant={Typography.Variant.BODY_XSMALL_REGULAR}
            css={(theme) => css`
              color: ${theme.ds.colors.gray[700]};
            `}
          >
            <FormattedMessage defaultMessage="*If you didn't receive anything, don't forget to check your spam folder, or ask your Amalia admin for help." />
          </Typography>
        }
        message={
          <AlertBanner
            inline
            variant={AlertBanner.Variant.INFO}
          >
            <FormattedMessage defaultMessage="Your account is being prepared!{br}You'll receive an invitation as soon as your account is ready*." />
          </AlertBanner>
        }
        picture={
          <AccountNotInvitedIllustration
            height={235.55}
            width={424}
          />
        }
      />
    </LayoutUnauthenticated>
  );
});
