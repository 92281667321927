import { css } from '@emotion/react';

export const footer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  margin-top: 16px;
`;

export const header = css`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
`;

export const headerRight = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;

  margin-left: auto; // Stick to the right.
`;

export const smallActions = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`;

export const extraActions = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`;

export const pageSizeAndTotal = css`
  display: flex;
  align-items: center;
  gap: 16px;
`;
