import { noop } from 'lodash';
import { createContext, useContext } from 'react';

export type MenuDropdownContextProps = {
  onClosePopover: () => void;
};

const MenuDropdownContext = createContext<MenuDropdownContextProps>({
  onClosePopover: noop,
});

export const MenuDropdownContextProvider = MenuDropdownContext.Provider;

export const useMenuDropdownContext = () => useContext(MenuDropdownContext);
