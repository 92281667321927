import { type Theme } from '@emotion/react';

import { DEFAULT_THEME_BORDER_RADIUSES } from './ds/borderRadiuses';
import { DEFAULT_THEME_COLORS, DEFAULT_THEME_HUES } from './ds/colors';
import { DEFAULT_THEME_GLOBAL_STYLES } from './ds/globalStyles';
import { DEFAULT_THEME_SHADOWS } from './ds/shadows';
import { DEFAULT_THEME_TRANSITIONS } from './ds/transitions';
import { DEFAULT_THEME_TYPOGRAPHIES } from './ds/typographies';

export const DEFAULT_THEME = {
  ds: {
    borderRadiuses: DEFAULT_THEME_BORDER_RADIUSES,
    colors: DEFAULT_THEME_COLORS,
    hues: DEFAULT_THEME_HUES,
    globalStyles: DEFAULT_THEME_GLOBAL_STYLES,
    shadows: DEFAULT_THEME_SHADOWS,
    transitions: DEFAULT_THEME_TRANSITIONS,
    typographies: DEFAULT_THEME_TYPOGRAPHIES,
  },
} as const satisfies Theme;
