import { css, useTheme } from '@emotion/react';
import {
  FloatingFocusManager,
  type FloatingFocusManagerProps,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import clsx from 'clsx';
import { noop } from 'lodash';
import { type ReactNode, memo, type ComponentPropsWithoutRef, useCallback } from 'react';

import { useBoolState, useShallowObjectMemo } from '@amalia/ext/react/hooks';
import { type MergeAll } from '@amalia/ext/typescript';

import { useLayer } from '../layers-context/useLayer';

import { ModalActions } from './modal-actions/ModalActions';
import { ModalBody } from './modal-body/ModalBody';
import { ModalCancelAction } from './modal-cancel-action/ModalCancelAction';
import { ModalContent } from './modal-content/ModalContent';
import { ModalDescription } from './modal-description/ModalDescription';
import { ModalHeader } from './modal-header/ModalHeader';
import { ModalMainAction } from './modal-main-action/ModalMainAction';
import { ModalTitle } from './modal-title/ModalTitle';
import { ModalContext, type ModalContextValue } from './Modal.context';
import * as styles from './Modal.styles';
import { ModalSize, ModalVariant } from './Modal.types';

export type ModalProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Is the modal open. */
      isOpen?: boolean;
      /** Close handler. */
      onClose?: () => void;
      /** Modal variant. */
      variant?: ModalVariant;
      /** Modal size. */
      size?: ModalSize;
      /** Should close on press escape or click outside. */
      isDismissable?: boolean;
      /** Set initial focus when the modal is open. Set to -1 to ignore. */
      initialFocus?: FloatingFocusManagerProps['initialFocus'];
      /** Override FloatingFocusManager default behavior. */
      closeOnFocusOut?: FloatingFocusManagerProps['closeOnFocusOut'];
      /** Modal layout. */
      children: ReactNode;
    },
  ]
>;

const ModalBase = memo(function Modal({
  isOpen = false,
  onClose = noop,
  variant = ModalVariant.DEFAULT,
  size = ModalSize.MEDIUM,
  isDismissable = true,
  initialFocus = undefined,
  closeOnFocusOut = false,
  children,
  ...props
}: ModalProps) {
  const theme = useTheme();

  const handleClose = useCallback(() => onClose(), [onClose]);

  const { refs, context } = useFloating<HTMLDivElement>({
    open: isOpen,
    // The Modal component only handles closing state so this should be safe.
    onOpenChange: handleClose,
  });

  // Add a transition on show/hide modal. Use the default transition parameters.
  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    duration: theme.ds.transitions.default.durationMs,
    common: {
      transitionProperty: 'opacity, transform, width',
      transitionTimingFunction: theme.ds.transitions.default.easing,
    },

    initial: { opacity: 0, transform: 'translateY(-20px)' },
    open: { opacity: 1, transform: 'translateY(0)' },
    close: { opacity: 0, transform: 'translateY(20px)' },
  });

  const { isTopLayer, layerZIndex } = useLayer({ isOpen: isMounted });

  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
    enabled: isDismissable && isTopLayer,
  });

  // Add accessibility role of dialog.
  const role = useRole(context, { role: 'dialog' });

  // Register interactions declared above.
  const { getFloatingProps } = useInteractions([dismiss, role]);

  // Overlay transition styles. Use the default transition parameters.
  const { styles: overlayTransitionStyles } = useTransitionStyles(context, {
    duration: theme.ds.transitions.default.durationMs,
    common: {
      transitionProperty: 'opacity',
      transitionTimingFunction: theme.ds.transitions.default.easing,
    },
  });

  const { isTriedToDismiss, setTriedToDismissTrue, setTriedToDismissFalse } = useBoolState(false, 'triedToDismiss');

  const contextValue = useShallowObjectMemo<ModalContextValue>({
    onCloseModal: handleClose,
    variant,
    size,
  });

  return (
    !!isMounted && (
      <FloatingPortal>
        <FloatingOverlay
          lockScroll
          style={overlayTransitionStyles}
          css={[
            styles.overlay,
            css`
              z-index: ${layerZIndex};
            `,
          ]}
        >
          {!isDismissable && (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions -- It's not supposed to be accessible.
            <div
              css={styles.preventClickAway}
              onMouseDown={setTriedToDismissTrue}
            />
          )}

          <ModalContext.Provider value={contextValue}>
            <FloatingFocusManager
              modal
              closeOnFocusOut={closeOnFocusOut}
              context={context}
              initialFocus={initialFocus}
            >
              <div
                ref={refs.setFloating}
                {...getFloatingProps({ ...props, className: clsx(props.className, size, variant) })}
                css={[styles.modal, isTriedToDismiss && styles.triedToDismiss]}
                style={{
                  ...props.style,
                  ...transitionStyles,
                }}
                // Need to remove the style after the animation ends so it can restart next time the user clicks outside of the modal.
                onAnimationEnd={setTriedToDismissFalse}
              >
                {size !== ModalSize.XLARGE && (
                  <div
                    className={variant}
                    css={styles.border}
                  />
                )}

                {children}
              </div>
            </FloatingFocusManager>
          </ModalContext.Provider>
        </FloatingOverlay>
      </FloatingPortal>
    )
  );
});

export const Modal = Object.assign(memo(ModalBase), {
  Size: ModalSize,
  Variant: ModalVariant,
  Content: ModalContent,
  Header: ModalHeader,
  Title: ModalTitle,
  Description: ModalDescription,
  Body: ModalBody,
  Actions: ModalActions,
  CancelAction: ModalCancelAction,
  MainAction: ModalMainAction,
});
