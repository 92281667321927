import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertBanner } from '@amalia/design-system/components';
import { DrawingStatementCalculationError } from '@amalia/lib-ui';

import * as styles from './StatementSummaryWidgetCalculationError.styles';

export const StatementSummaryWidgetCalculationError = memo(function StatementSummaryWidgetCalculationError() {
  return (
    <div css={styles.container}>
      <div css={styles.alert}>
        <AlertBanner variant={AlertBanner.Variant.ERROR}>
          <FormattedMessage defaultMessage="Your statement ran into errors during calculation. Please contact your admin for the issue." />
        </AlertBanner>
      </div>

      <DrawingStatementCalculationError css={styles.illustration} />
    </div>
  );
});
