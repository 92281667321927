import styled from '@emotion/styled';
import { Fragment, memo, type ReactElement, type ReactNode } from 'react';

import { Group, Modal, Stack, type ModalMainActionProps } from '@amalia/design-system/components';
import { Spinner, SpinnerBoundary } from '@amalia/lib-ui';

import { ChartPreviewPlaceholder } from './preview-placeholder/ChartPreviewPlaceholder';

const DashboardChartConfigurationModalLayoutBody = styled(Group)`
  overflow-y: auto;
  width: 100%;
  flex: 1;
  border-top: 1px solid ${({ theme }) => theme.ds.colors.gray[100]};
`;

const DashboardChartConfigurationModalLayoutLeftPanel = styled.div`
  padding: 24px 32px;
  flex-shrink: 0;
  width: 320px;
  overflow-y: auto;
  border-right: 1px solid ${({ theme }) => theme.ds.colors.gray[100]};
`;

const DashboardChartConfigurationModalLayoutRightPanel = styled(Stack)`
  width: 100%;
  margin: auto;
`;

const DashboardChartConfigurationModalLayoutPreview = styled.div`
  display: flex;

  > *:only-child {
    margin: auto; // Center children without ignoring padding.
  }

  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 24px 32px;
`;

export type DashboardChartConfigurationModalLayout = {
  readonly isLoading: boolean;
  readonly isFormValid: boolean;
  readonly chartPreview: ReactNode;
  readonly settings: ReactNode;
  readonly submitButton: ReactElement<ModalMainActionProps>;
  readonly title: ReactNode;
};

export const DashboardChartConfigurationModalLayout = memo(function DashboardChartConfigurationModalLayout({
  chartPreview,
  isFormValid,
  isLoading,
  settings,
  submitButton,
  title,
}: DashboardChartConfigurationModalLayout) {
  return (
    <Fragment>
      <Modal.Content noBottomPadding>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <DashboardChartConfigurationModalLayoutBody>
          <DashboardChartConfigurationModalLayoutLeftPanel>{settings}</DashboardChartConfigurationModalLayoutLeftPanel>

          <DashboardChartConfigurationModalLayoutRightPanel>
            <DashboardChartConfigurationModalLayoutPreview>
              {(() => {
                if (isLoading) {
                  return (
                    <SpinnerBoundary>
                      <Spinner />
                    </SpinnerBoundary>
                  );
                }

                if (isFormValid && chartPreview) {
                  return chartPreview;
                }

                return <ChartPreviewPlaceholder />;
              })()}
            </DashboardChartConfigurationModalLayoutPreview>
          </DashboardChartConfigurationModalLayoutRightPanel>
        </DashboardChartConfigurationModalLayoutBody>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />

        {submitButton}
      </Modal.Actions>
    </Fragment>
  );
});
