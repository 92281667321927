import { type FormikFieldProps, useFormikFieldAdapter } from '@amalia/ext/formik';

import { PlanAwardIconSelect, type PlanAwardIconSelectProps } from '../PlanAwardIconSelect';

export type FormikPlanAwardIconSelectProps = FormikFieldProps<PlanAwardIconSelectProps>;

export const FormikPlanAwardIconSelect = function FormikPlanAwardIconSelect({
  validate, // Omit validate and any props not passed to PlanAwardIconSelect.
  ...props
}: FormikPlanAwardIconSelectProps) {
  const formikFieldProps = useFormikFieldAdapter<PlanAwardIconSelectProps['value']>({
    ...props,
    validate,
  });

  return (
    <PlanAwardIconSelect
      {...props}
      {...formikFieldProps}
    />
  );
};
