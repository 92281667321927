import { type ReactNode } from 'react';

import { type SelectOptionValue, type SelectDropdownOption } from '../../overlays/select-dropdown/SelectDropdown.types';

export enum SelectSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

export type SelectOption<TValue extends SelectOptionValue = SelectOptionValue> = SelectDropdownOption<TValue> & {
  /** Label of the selected option inside the control on a single or multi select select component. */
  valueLabel?: ReactNode;
  /** Label of the selected option inside the control on a single select component. Overrides valueLabel. */
  singleValueLabel?: ReactNode;
  /** Label of the selected option inside the control on a multi select component. Overrides valueLabel. */
  multiValueLabel?: ReactNode;
};

export type SingleValueLabelProps<TOption extends SelectOption = SelectOption> = {
  /** Selected option. */
  option: TOption;
  /** Size of the field. */
  size: SelectSize;
  /** Is the field disabled. */
  disabled?: boolean;
};

export type MultiValueLabelProps<TOption extends SelectOption = SelectOption> = {
  /** Selected option. */
  option: TOption;
  /** Size of the field. */
  size: SelectSize;
  /** Is the field disabled. */
  disabled?: boolean;
};
