import { makeStyles } from '@mui/styles';
import { memo, useMemo } from 'react';

import { type AmaliaThemeType } from '@amalia/ext/mui/theme';

import { TextType, Text } from '../../typography';

import { SelectFieldBase, type SelectFieldBaseProps } from './SelectField';

interface MiniSelectFieldProps extends SelectFieldBaseProps {
  readonly options: {
    value: string;
    label: any;
    summary: any;
  }[];
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  formatSelect: {
    border: 0,
    backgroundColor: 'transparent',
    color: theme.palette.link.main,

    '& $formatSelectOption h6': {
      position: 'absolute',
      top: '-10000px',
      left: '-10000px',
    },
  },
  container: {
    flex: 0,
  },
  formatSelectOption: {
    color: theme.palette.link.main,

    '& h6': {
      display: 'inline',
      color: theme.palette.common.black,
      marginLeft: theme.spacing(1),
    },
  },
}));

export const MiniSelectField = memo(function MiniSelectField({ options, ...props }: MiniSelectFieldProps) {
  const classes = useStyles();
  const FORMAT_OPTIONS = useMemo(
    () =>
      options.map(({ value, summary, label }) => ({
        value,
        label: (
          <div className={classes.formatSelectOption}>
            {summary}
            <Text
              as="h6"
              type={TextType.SUBTITLE}
            >
              {label}
            </Text>
          </div>
        ),
      })),
    [classes, options],
  );

  return (
    <SelectFieldBase
      {...props}
      className={classes.formatSelect}
      containerClassName={classes.container}
      options={FORMAT_OPTIONS}
    />
  );
});
