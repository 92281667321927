import moment from 'moment';

import { getFormatFromMachineName, validateValueWithFormat } from '@amalia/amalia-lang/formula/evaluate/shared';
import { type VariableDefinition } from '@amalia/amalia-lang/tokens/types';
import {
  CalculationType,
  type CreateDatasetOverwriteRequest,
  type CreateStatementOverwriteRequest,
  type DatasetRow,
  type DeleteDatasetOverwriteRequest,
  formatDatasetCell,
  OverwriteTypesEnum,
  type PatchCustomObjectRequest,
  type Statement,
} from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { OVERWRITE_CONTEXT } from '@amalia/data-correction/overwrites/components';
import { OverwriteScopeEnum } from '@amalia/data-correction/overwrites/types';
import { type Dataset } from '@amalia/payout-calculation/types';
import { type StatementThread, StatementThreadScopesType } from '@amalia/payout-collaboration/comments/types';

export const FORMATS_TO_FORCE_TO_SHOW = [FormatsEnum.date, FormatsEnum.percent];

// Value that needs to be parsed to show parsed value in the table editing
export const FORMATS_TO_FORCE_FOR_VALUE = [FormatsEnum.date];

export type RowsTableColumn = {
  title: string;
  subTitle?: string;
  name: string;
};

export type RowTableOverwrite = {
  statementId: string;
  objectExternalId: string;
  definitionName: string;
  overwriteType?: OverwriteTypesEnum;
  overwriteRequest?: CreateStatementOverwriteRequest | DeleteDatasetOverwriteRequest | PatchCustomObjectRequest;
};

export const RowsTableUtils = {
  /**
   * Creates an overwrite.
   *
   * Overwrite can be a statement or Field overwrite if done on a statement variable or field on a dataset.
   * Or a customobject patch if done on a native property.
   * Or a Filter Row Overwrites Add or Remove
   * @param dataset
   * @param datasetRows
   * @param currentStatement
   * @param changed
   */
  onCommitChanges: (
    dataset: Dataset,
    datasetRows: DatasetRow[],
    currentStatement: Statement,
    { changed }: any,
  ): RowTableOverwrite | null => {
    const customObjectDefinition =
      currentStatement.results.definitions.customObjects[dataset.customObjectDefinition.machineName];
    let result: RowTableOverwrite = null;

    if (datasetRows && changed) {
      // Loop on each row that have been edited.
      Object.keys(changed).forEach((rowKey) => {
        // Fetch the row in the dataset.
        const row = datasetRows[+rowKey];

        // IF a Filter Row Overwrite
        if (changed[rowKey].change && changed[rowKey].change === OVERWRITE_CONTEXT.FILTER_ROW_REMOVE) {
          result = {
            statementId: currentStatement.id,
            objectExternalId: row.externalId,
            definitionName: customObjectDefinition.machineName,
            overwriteType: OverwriteTypesEnum.FILTER_ROW_REMOVE,
            overwriteRequest: RowsTableUtils.buildDeleteDatasetRowRequest(
              changed,
              rowKey,
              changed[rowKey].overwriteRequest.definitionId,
              changed[rowKey].overwriteRequest.filterId,
            ),
          };
        }
        // Loop on each column that have been edited.
        Object.keys(changed[rowKey]?.content || {}).forEach((columnName: string) => {
          // Grab the new value the user just typed in the field.
          let rawNewValue = changed[rowKey].content[columnName];

          // Fetch the current field value, to build the overwrite source value.
          const currentFieldValue = row.content[columnName];

          // Get column format to validate it
          const { format: columnFormat, isRequired: isFieldRequired } = getFormatFromMachineName(
            columnName,
            currentStatement.results,
            dataset.customObjectDefinition.machineName,
          );

          // validate data against custom object definition or computed object definition
          try {
            validateValueWithFormat(rawNewValue, columnFormat, isFieldRequired);
          } catch (error) {
            // If there's a validation error, print it
            throw new Error(`Validation error: ${(error as Error).message}`);
          }

          // Edge case: put back the date in timestamp when overwriting a date variable
          const variableDefinition = currentStatement?.results.definitions.variables[columnName];

          if (variableDefinition?.format === FormatsEnum.date && rawNewValue) {
            // If we have a date overwrite in a date variable cell, send the overwrite as a timestamp
            const timestampDateStr = moment.utc(rawNewValue, 'YYYY-MM-DD').format('X');
            if (timestampDateStr) {
              rawNewValue = parseInt(timestampDateStr, 10);
            }
          }

          // Edge case: ensure we have a number here when overwriting a percent
          if (rawNewValue && columnFormat === FormatsEnum.percent) {
            rawNewValue = parseFloat(rawNewValue);
          }

          const overwriteValue = {
            // Careful, typeof null is object.
            [columnName]:
              typeof currentFieldValue === 'object' && currentFieldValue !== null
                ? { ...currentFieldValue, value: rawNewValue }
                : rawNewValue,
          };

          // Apply overwrites and launch calculation.
          if (row.externalId) {
            result = {
              // If overwrite is done on a plan variable, it's a statement overwrite.
              statementId: variableDefinition ? currentStatement.id : undefined,
              objectExternalId: row.externalId,
              definitionName: customObjectDefinition.machineName,
              overwriteRequest: RowsTableUtils.buildOverwriteRequest(
                changed,
                overwriteValue,
                rowKey,
                currentStatement,
                variableDefinition,
                customObjectDefinition,
                columnName,
                row,
              ),
            };
          } else {
            throw new Error('Row not found in the dataset');
          }
        });
      });
    }

    return result;
  },

  buildDeleteDatasetRowRequest: (
    changed: any,
    rowKey: string,
    definitionId,
    filterId,
  ): DeleteDatasetOverwriteRequest => ({
    scope: changed[rowKey].isApplyToOverall ? OverwriteScopeEnum.GLOBAL : OverwriteScopeEnum.STATEMENT,
    definitionId,
    filterId,
    calculationType: CalculationType.STATEMENT,
  }),

  buildOverwriteRequest: (
    changed: any,
    overwriteValue: any,
    rowKey: string,
    statement: Statement,
    variableDefinition: VariableDefinition,
    customObjectDefinition: Pick<CustomObjectDefinition, 'id'>,
    columnName: string,
    row: DatasetRow,
  ): CreateDatasetOverwriteRequest | PatchCustomObjectRequest => {
    // Statement field overwrite.
    if (variableDefinition) {
      return {
        field: columnName,
        overwriteValue,
        rowExternalId: row.externalId,
        definitionId: customObjectDefinition.id,
        scope: changed[rowKey].isApplyToOverall ? OverwriteScopeEnum.GLOBAL : OverwriteScopeEnum.STATEMENT,
        calculationType: CalculationType.STATEMENT,
        variableId: variableDefinition.id,
      };
    }

    // Data property overwrite.
    return {
      field: columnName,
      overwriteValue,
      createdOnStatementId: statement.id,
      scope: changed[rowKey].isApplyToOverall ? OverwriteScopeEnum.GLOBAL : OverwriteScopeEnum.STATEMENT,
    };
  },
  /**
   * Get the cell value, not formatted, as it should be overwritten / taking into account as value
   * examples:
   * - percent: 0.2, date: '2021-07-12'...
   */
  getCellValue: (row: DatasetRow, columnName: any, currentStatement?: Statement): string => {
    // If column name match a date variable, format its original value
    const variableDefinition = currentStatement?.results.definitions.variables[columnName];

    const cellFormat = variableDefinition?.format;

    const cellValue = row?.content?.[columnName];

    // If it's a currency, the real value is its value
    if (cellValue?.symbol) {
      return cellValue.value;
    }

    // if it's a date, format it as YYYY-MM-DD
    if (cellFormat && FORMATS_TO_FORCE_FOR_VALUE.includes(cellFormat)) {
      const formattedCell = formatDatasetCell(cellValue, cellFormat);
      return formattedCell?.toString() || '';
    }

    // Don't call formatDatasetCell here: we want overwrittable values here, not fancy ones
    return cellValue;
  },

  /**
   * Get the formatted value, the one that will be printed in the table
   * examples:
   * - percent: '20 %', date: '2021-07-12', etc
   */
  getFormattedCellValue: (valueToFormat: any, cellFormat: FormatsEnum | undefined, isOverwrite: boolean) => {
    const formattedValue =
      isOverwrite || valueToFormat !== null
        ? formatDatasetCell(
            valueToFormat,
            cellFormat && FORMATS_TO_FORCE_TO_SHOW.includes(cellFormat) ? cellFormat : undefined,
          )
        : '';

    return formattedValue === ''
      ? '—' // Never show an empty cell, show a dash instead.
      : formattedValue;
  },

  getColumnDescriptions: (currentStatement: Statement, columns: RowsTableColumn[]): Record<string, string> => {
    const result: Record<string, string> = {};

    columns.forEach(({ name }) => {
      if (currentStatement?.results?.definitions?.variables?.[name]) {
        result[name] = currentStatement.results.definitions.variables[name].description || '';
      }
    });

    return result;
  },

  /**
   * Retrieve thread linked to given cell (externalId + columnName + ruleId).
   * @param customObjectDefinitionId
   * @param externalId
   * @param columnName
   * @param ruleId
   * @param statementThreads
   */
  getCellCommentThread(
    customObjectDefinitionId: string,
    externalId: string,
    columnName: string,
    ruleId: string,
    statementThreads: Record<string, StatementThread>,
  ): StatementThread {
    if (!statementThreads) {
      return null;
    }

    return (
      Object.values(statementThreads).find(
        (statementThread: StatementThread) =>
          statementThread.computedScope?.type === StatementThreadScopesType.OBJECT &&
          statementThread.computedScope?.id === externalId &&
          statementThread.computedScope?.field === columnName &&
          (!statementThread.computedScope?.ruleId || statementThread.computedScope?.ruleId === ruleId) &&
          statementThread.computedScope?.definitionId === customObjectDefinitionId,
      ) || null
    );
  },
};

export const getThreadsOnRow = (
  statementThreads: Record<string, StatementThread>,
  row: DatasetRow,
  ruleId: string,
  currentStatement: Statement,
  dataset: Dataset,
): StatementThread[] => {
  if (!currentStatement || !dataset || !statementThreads) {
    return [];
  }

  const customObjectDefinition =
    currentStatement.results.definitions.customObjects[dataset.customObjectDefinition.machineName];

  const externalId = row?.externalId || '';
  return Object.values(statementThreads).filter(
    (statementThread: StatementThread) =>
      statementThread.computedScope?.type === StatementThreadScopesType.OBJECT &&
      statementThread.computedScope?.id === externalId &&
      (!statementThread.computedScope?.ruleId || statementThread.computedScope?.ruleId === ruleId) &&
      statementThread.computedScope?.definitionId === customObjectDefinition?.id,
  );
};

export const isAThreadOnARow = (
  statementThreads: Record<string, StatementThread>,
  row: DatasetRow,
  ruleId: string,
  currentStatement: Statement,
  dataset: Dataset,
) => !!getThreadsOnRow(statementThreads, row, ruleId, currentStatement, dataset).length;
