import { memo, forwardRef, type ForwardedRef } from 'react';

import { TableCell, type TableCellProps } from '../table-cell/TableCell';

export type TableDataCellProps = Omit<TableCellProps, 'as'>;

const TableDataCellForwardRef = forwardRef(function TableDataCell(
  { children, ...props }: TableDataCellProps,
  ref: ForwardedRef<HTMLTableCellElement>,
) {
  return (
    <TableCell
      {...props}
      ref={ref}
      as="td"
    >
      {children}
    </TableCell>
  );
});

export const TableDataCell = memo(TableDataCellForwardRef);
