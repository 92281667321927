import { ClassNames } from '@emotion/react';
import clsx from 'clsx';
import { cloneElement, memo } from 'react';

import { type TablerIconElement } from '../../../../general/icons/types';

export type DropdownItemIconProps = {
  readonly icon: TablerIconElement;
  readonly disabled?: boolean;
};

export const DropdownItemIcon = memo(function DropdownItemIcon({ icon, disabled }: DropdownItemIconProps) {
  return (
    <ClassNames>
      {({ css }) =>
        cloneElement(icon, {
          width: icon.props.width || icon.props.size || 18,
          height: icon.props.height || icon.props.size || 18,
          color: disabled || !icon.props.color ? 'currentColor' : icon.props.color,
          className: clsx(
            icon.props.className,
            css`
              flex: none;
            `,
          ),
        })
      }
    </ClassNames>
  );
});
