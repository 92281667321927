import { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { getWorkflowDefinition, type Statement } from '@amalia/core/types';
import { Modal } from '@amalia/design-system/components';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import {
  type WorkflowDefinition,
  type WorkflowMap,
  type WorkflowStatementState,
  WorkflowStatementStateAction,
} from '@amalia/payout-collaboration/workflows/types';

import {
  type StatementWorkflowAdditionalOtpions,
  StatementWorkflowPresentation,
} from './statement-workflow-presentation/StatementWorkflowPresentation';

interface StatementWorkflowProps {
  readonly statement: Statement;
  readonly onNextStep?: (canValidStep: WorkflowStatementStateAction, checked: boolean, isLast?: boolean) => any;
  readonly onPreviousStep?: () => void;
  readonly onReset?: () => void;
  readonly additionalOptions?: StatementWorkflowAdditionalOtpions;
  readonly workflowMap: WorkflowMap;
  readonly workflowDetails: { steps: Partial<WorkflowStatementState>[]; currentStepNumber: number };
  readonly statementHeaderView?: boolean;
}

export const StatementWorkflow = memo(function StatementWorkflow({
  statement,
  onPreviousStep,
  onNextStep,
  onReset,
  additionalOptions,
  workflowMap,
  workflowDetails,
  statementHeaderView = false,
}: StatementWorkflowProps) {
  const ability = useAbilityContext();

  const [isResetWorkflowModalOpen, setIsResetWorkflowModalOpen] = useState<boolean>(false);
  const [tooltipInteractive, setTooltipInteractive] = useState<boolean>(true);
  const [tooltipOpened, setTooltipOpened] = useState<boolean>(false);

  const hasRightToResetWorkflow = useMemo(
    () => onReset && ability.can(ActionsEnum.reset, SubjectsEnum.Workflow),
    [ability, onReset],
  );

  const handleResetModalClose = useCallback(() => {
    setIsResetWorkflowModalOpen(false);
    setTooltipInteractive(true);
  }, []);

  const onResetClick = useCallback(() => {
    setIsResetWorkflowModalOpen(true);
    setTooltipOpened(false);
    setTooltipInteractive(false);
  }, []);

  const onResetConfirm = useCallback(() => {
    setIsResetWorkflowModalOpen(false);
    setTooltipInteractive(true);
    onReset?.();
  }, [onReset]);

  const currentWorkflow: WorkflowDefinition | undefined = useMemo(
    () => getWorkflowDefinition(statement, workflowMap),
    [statement, workflowMap],
  );

  const { steps, currentStepNumber } = workflowDetails;

  const onNextStepProxy = useCallback(
    (canValidStep: WorkflowStatementStateAction, checked: boolean) => {
      const isNext = [WorkflowStatementStateAction.NEXT, WorkflowStatementStateAction.FORCE].includes(canValidStep);
      onNextStep?.(canValidStep, checked, isNext ? steps.length === currentStepNumber + 1 : false);
    },
    [currentStepNumber, onNextStep, steps],
  );

  return currentWorkflow && steps ? (
    <Fragment>
      <StatementWorkflowPresentation
        additionalOptions={additionalOptions}
        hasRightToResetWorkflow={hasRightToResetWorkflow}
        setTooltipOpened={setTooltipOpened}
        statement={statement}
        statementHeaderView={statementHeaderView}
        stepNumber={currentStepNumber}
        steps={steps}
        tooltipInteractive={tooltipInteractive}
        tooltipOpened={tooltipOpened}
        workflow={currentWorkflow}
        onNextStep={onNextStep ? onNextStepProxy : undefined}
        onPreviousStep={onPreviousStep}
        onReset={onResetClick}
      />

      <Modal
        isOpen={isResetWorkflowModalOpen}
        onClose={handleResetModalClose}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              <FormattedMessage defaultMessage="Reset workflow?" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <FormattedMessage defaultMessage="You are about to reset the validation workflow for this statement.{br}All the users that already reviewed this statement will have to review it again." />
          </Modal.Body>
        </Modal.Content>

        <Modal.Actions>
          <Modal.CancelAction />

          <Modal.MainAction onClick={onResetConfirm}>
            <FormattedMessage defaultMessage="Reset" />
          </Modal.MainAction>
        </Modal.Actions>
      </Modal>
    </Fragment>
  ) : null;
});
