import { without } from 'lodash';
import { useMemo } from 'react';

import { UserHrisIdSourceItem, UserHrisIdSourceLabel } from '@amalia/data-capture/connectors/assets';
import { type SelectOption } from '@amalia/design-system/components';
import { UserHrisIdSource } from '@amalia/tenants/users/types';

export type HrisIdSourceOption = SelectOption<UserHrisIdSource>;

// Alphabetically sort the sources, but put OTHERSOURCE & NONE last
const sourcesOrder = without(Object.values(UserHrisIdSource), UserHrisIdSource.OTHERSOURCE, UserHrisIdSource.NONE)
  .toSorted((a, b) => a.localeCompare(b))
  .concat([UserHrisIdSource.OTHERSOURCE, UserHrisIdSource.NONE]);

export const useHrisIdSourceOptions = ({
  withoutValues = [],
  hideIconWhenSelected = false,
}: {
  withoutValues?: UserHrisIdSource[];
  hideIconWhenSelected?: boolean;
} = {}): HrisIdSourceOption[] =>
  useMemo(
    () =>
      without(Object.values(UserHrisIdSource), ...withoutValues)
        .sort((a, b) => sourcesOrder.indexOf(a) - sourcesOrder.indexOf(b))
        .map((hrisIdSource) => ({
          filterLabel: hrisIdSource,
          value: hrisIdSource,
          label: <UserHrisIdSourceItem hrisIdSource={hrisIdSource} />,
          ...(hideIconWhenSelected && {
            valueLabel: <UserHrisIdSourceLabel hrisIdSource={hrisIdSource} />,
          }),
        })),
    [withoutValues, hideIconWhenSelected],
  );
