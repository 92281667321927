import { type Editor } from '@tiptap/core';

import { TokenType } from '@amalia/amalia-lang/tokens/types';

import { type FormulaEditorToken } from '../../types/formulaEditorToken';

import { traverseTextNodes } from './traverse-text-nodes';

// This regex will match any machine name that is not preceded by a word or a dot, and followed by a whitespace, parenthesis or comma character.
// it uses negative lookbehind and negative lookahead.
// Example: This will match `SUM`, `filter.provisionedInPeriodFr`, `IF`, `opportunities.stageName`, `Commit`, `0.9`, `1`, `opportunities.payoutGlobal`
//   in SUM(filter.provisionedInPeriodFr, IF(opportunities.stageName = "Commit", 0.9, 1) * opportunities.payoutGlobal)
// You can test online using this link: https://regex101.com/r/EgnYA5/1
const SEARCH_TOKEN_REGEXP = /(?<![.\w])(?<machineName>[\w.]+)(?<separator>\W)/gu;

const replaceTokenInFormula = (
  editor: Editor,
  nodePosition: number,
  token: FormulaEditorToken,
  separator: string,
  start: number,
  end: number,
) => {
  // If the token is a function and the separator is an open parenthesis, '(' is already included in the token editorContentToApply, so we need to include it in the replacement range.
  const shouldReplaceSeparator = token.type === TokenType.FUNCTION && separator === '(';

  const range = {
    from: nodePosition + start,
    to: nodePosition + end + (shouldReplaceSeparator ? separator.length : 0),
  };
  editor.commands.appendToken({ token, range });
};

export const searchAndReplaceTokensInFormula = (
  editor: Editor,
  nodePosition: number,
  formula: string,
  tokens: FormulaEditorToken[],
  replace: (
    editor: Editor,
    nodeposition: number,
    token: FormulaEditorToken,
    separator: string,
    start: number,
    end: number,
  ) => void,
) => {
  let match: RegExpExecArray | null;
  while ((match = SEARCH_TOKEN_REGEXP.exec(formula)) !== null) {
    const searchedMachineName = match.groups?.machineName;
    const separator = match.groups?.separator;
    if (!searchedMachineName || !separator) {
      continue;
    }

    const token = tokens.find((token) => token.formula === searchedMachineName);
    if (token) {
      const start = match.index;
      const end = match.index + searchedMachineName.length;
      replace(editor, nodePosition, token, separator, start, end);
    }
  }
};

export const compileTextNodesToFormulaTokenNodes = (editor: Editor, tokens: FormulaEditorToken[]) => {
  const cursorPos = editor.state.selection.from;
  const typedCharacter = editor.view.state.doc.textBetween(cursorPos - 1, cursorPos);

  // If last typed character forms part of a word character and is not a separator, it means the formula is being written, so we stop there (to prevent premature replacement while typing a token that starts with the same keyword as another token).
  // Example: if the user wants to type opportunities.stageName, he starts typing `opportunities` we don't want to replace opportunities when he types the `s` character.
  if (/^[\w.]$/u.test(typedCharacter)) {
    return;
  }

  // Editor doc contains text and html nodes (in our case text and FormulaTokenNode).
  // Here we want to search if text nodes contains text that should be transformed to FormulaTokenNode, so we only need to traverse text nodes.
  traverseTextNodes(editor.state.doc, (node, position) =>
    searchAndReplaceTokensInFormula(editor, position, node.text!, tokens, replaceTokenInFormula),
  );
};
