import { defineMessages } from 'react-intl';

import { TokenType } from '@amalia/amalia-lang/tokens/types';

export const tokenTypeLabels = defineMessages<TokenType>({
  [TokenType.PLAN]: { defaultMessage: '{count, plural, one {Plan} other {Plans}}' },
  [TokenType.RULE]: { defaultMessage: '{count, plural, one {Rule} other {Rules}}' },
  [TokenType.FILTER]: { defaultMessage: '{count, plural, one {Filter} other {Filters}}' },
  [TokenType.VARIABLE]: { defaultMessage: '{count, plural, one {Variable} other {Variables}}' },
  [TokenType.FIELD]: { defaultMessage: '{count, plural, one {Field} other {Fields}}' },
  [TokenType.PROPERTY]: { defaultMessage: '{count, plural, one {Property} other {Properties}}' },
  [TokenType.VIRTUAL_PROPERTY]: { defaultMessage: '{count, plural, one {Virtual} other {Virtuals}}' },
  [TokenType.LINK]: { defaultMessage: '{count, plural, one {Link} other {Links}}' },
  [TokenType.QUOTA]: { defaultMessage: '{count, plural, one {Quota} other {Quotas}}' },
  [TokenType.FUNCTION]: { defaultMessage: '{count, plural, one {Function} other {Functions}}' },
  [TokenType.KEYWORD]: { defaultMessage: '{count, plural, one {Keyword} other {Keywords}}' },
  [TokenType.OBJECT]: { defaultMessage: '{count, plural, one {Object} other {Objects}}' },
});

export const noTokensLabels = defineMessages<TokenType>({
  [TokenType.PLAN]: { defaultMessage: 'No plans' },
  [TokenType.RULE]: { defaultMessage: 'No rules' },
  [TokenType.FILTER]: { defaultMessage: 'No filters' },
  [TokenType.VARIABLE]: { defaultMessage: 'No variables' },
  [TokenType.FIELD]: { defaultMessage: 'No fields' },
  [TokenType.PROPERTY]: { defaultMessage: 'No properties' },
  [TokenType.VIRTUAL_PROPERTY]: { defaultMessage: 'No virtuals' },
  [TokenType.LINK]: { defaultMessage: 'No links' },
  [TokenType.QUOTA]: { defaultMessage: 'No quotas' },
  [TokenType.FUNCTION]: { defaultMessage: 'No functions' },
  [TokenType.KEYWORD]: { defaultMessage: 'No keywords' },
  [TokenType.OBJECT]: { defaultMessage: 'No objects' },
});
