import { css } from '@emotion/react';
import { type ForwardedRef, forwardRef, memo, type ComponentPropsWithoutRef } from 'react';

export type UnstyledButtonProps = ComponentPropsWithoutRef<'button'>;

const UnstyledButtonForwardRef = forwardRef(function UnstyledButton(
  { type = 'button', ...props }: UnstyledButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <button
      {...props}
      ref={ref}
      type={type}
      css={css`
        all: unset;
        &:not(:disabled) {
          cursor: pointer;
        }
      `}
    />
  );
});

export const UnstyledButton = memo(UnstyledButtonForwardRef);
