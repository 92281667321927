import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ChangelogReleaseType } from '@amalia/amalia-meta/changelog/shared';
import { Badge, BadgeHue, type BadgeProps } from '@amalia/design-system/components';
import { type MergeAll } from '@amalia/ext/typescript';

import { changelogEntryReleaseTypeMessages } from '../../../intl/changelog.messages';

const ChangelogReleaseTypeToBadgeVariant = {
  [ChangelogReleaseType.NEW_RELEASE]: BadgeHue.GREEN,
  [ChangelogReleaseType.IMPROVEMENT]: BadgeHue.YELLOW,
} as const satisfies Record<ChangelogReleaseType, BadgeProps['variant']>;

export type ChangelogEntryReleaseTypeBadgeProps = MergeAll<
  [
    Omit<BadgeProps, 'children' | 'variant'>,
    {
      readonly releaseType: ChangelogReleaseType;
    },
  ]
>;

export const ChangelogEntryReleaseTypeBadge = memo(function ChangelogEntryReleaseTypeBadge({
  releaseType,
  size = Badge.Size.SMALL,
  ...props
}: ChangelogEntryReleaseTypeBadgeProps) {
  return (
    <Badge
      {...props}
      size={size}
      variant={ChangelogReleaseTypeToBadgeVariant[releaseType]}
    >
      <FormattedMessage {...changelogEntryReleaseTypeMessages[releaseType]} />
    </Badge>
  );
});
