import { combineReducers } from 'redux';

import { assert } from '@amalia/ext/typescript';
import { type PlanForecast } from '@amalia/payout-definition/plans/types';

import { switchDisplayStatus } from '../plans/reducer';
import { type ReduxAction } from '../types';

import { PLAN_FORECAST_ACTIONS } from './constants';
import {
  type PlansForecastEditKpisToDisplayAction,
  type PlanForecastsReducer,
  type PlansForecastEditKpiToDisplayStatusAction,
  type PlanForecastsEditFiltersToDisplayAction,
  type PlanForecastsEditFilterToDisplayStatusAction,
  type PlanForecastsEditFieldsToDisplayAction,
  type PlanForecastsEditFieldToDisplayStatusAction,
} from './types';

export default combineReducers<PlanForecastsReducer, ReduxAction>({
  isLoading: (state = 0, action) => {
    switch (action.type) {
      case PLAN_FORECAST_ACTIONS.START:
        return state + 1;
      case PLAN_FORECAST_ACTIONS.ERROR:
      case PLAN_FORECAST_ACTIONS.SET_PLAN_FORECAST:
        return state - 1;
      default:
        return state;
    }
  },
  currentPlanForecast: (state = null, action) => {
    switch (action.type) {
      case PLAN_FORECAST_ACTIONS.SET_PLAN_FORECAST:
        return action.payload.planForecast;

      case PLAN_FORECAST_ACTIONS.EDIT_DATASET: {
        assert(state, 'currentPlanForecast is not defined');

        return {
          ...state,
          forecastConfig: {
            ...state.forecastConfig,
            datasets: {
              ...state.forecastConfig?.datasets,
              [action.payload.datasetId]: action.payload.formula,
            },
          },
        };
      }

      case PLAN_FORECAST_ACTIONS.EDIT_FIELD: {
        assert(state, 'currentPlanForecast is not defined');
        return {
          ...state,
          forecastConfig: {
            ...state.forecastConfig,
            fields: {
              ...state.forecastConfig?.fields,
              [action.payload.fieldId]: action.payload.formula,
            },
          },
        };
      }

      case PLAN_FORECAST_ACTIONS.EDIT_KPI: {
        assert(state, 'currentPlanForecast is not defined');
        return {
          ...state,
          forecastConfig: {
            ...state.forecastConfig,
            kpis: {
              ...state.forecastConfig?.kpis,
              [action.payload.kpiId]: action.payload.formula,
            },
          },
        };
      }

      case PLAN_FORECAST_ACTIONS.EDIT_OBJECTS_TO_DISPLAY: {
        assert(state, 'currentPlanForecast is not defined');
        return {
          ...state,
          objectsToDisplay: {
            ...state.objectsToDisplay,
            [action.payload.ruleId]: action.payload.objectsToDisplay,
          },
        };
      }

      case PLAN_FORECAST_ACTIONS.EDIT_KPIS_TO_DISPLAY:
        return state ? editKpisToDisplay(state, action) : null;
      case PLAN_FORECAST_ACTIONS.EDIT_KPI_TO_DISPLAY_STATUS:
        return state ? editKpiToDisplayStatus(state, action) : null;
      case PLAN_FORECAST_ACTIONS.EDIT_FILTERS_TO_DISPLAY:
        return state ? editFiltersToDisplay(state, action) : null;
      case PLAN_FORECAST_ACTIONS.EDIT_FILTER_TO_DISPLAY_STATUS:
        return state ? editFilterToDisplayStatus(state, action) : null;
      case PLAN_FORECAST_ACTIONS.EDIT_FIELDS_TO_DISPLAY:
        return state ? editFieldsToDisplay(state, action) : null;
      case PLAN_FORECAST_ACTIONS.EDIT_FIELD_TO_DISPLAY_STATUS:
        return state ? editFieldToDisplayStatus(state, action) : null;
      default:
        return state;
    }
  },
});

// Kpis to display edition
const editKpisToDisplay = (state: PlanForecast, action: PlansForecastEditKpisToDisplayAction) => ({
  ...state,
  objectsToDisplay: {
    ...state.objectsToDisplay,
    [action.payload.ruleId]: {
      ...state.objectsToDisplay?.[action.payload.ruleId],
      kpisToDisplay: action.payload.kpisToDisplay,
    },
  },
});

const editKpiToDisplayStatus = (state: PlanForecast, action: PlansForecastEditKpiToDisplayStatusAction) => ({
  ...state,
  objectsToDisplay: {
    ...state.objectsToDisplay,
    [action.payload.ruleId]: {
      ...state.objectsToDisplay?.[action.payload.ruleId],
      kpisToDisplay: (state.objectsToDisplay?.[action.payload.ruleId].kpisToDisplay || []).map((kpiSection) => ({
        ...kpiSection,
        kpis: (kpiSection.kpis || []).map((kpiToDisplay) =>
          kpiToDisplay.id === action.payload.kpiToDisplayId
            ? { ...kpiToDisplay, displayStatus: switchDisplayStatus(kpiToDisplay.displayStatus) }
            : kpiToDisplay,
        ),
      })),
    },
  },
});

// Filters to display edition
const editFiltersToDisplay = (state: PlanForecast, action: PlanForecastsEditFiltersToDisplayAction) => ({
  ...state,
  objectsToDisplay: {
    ...state.objectsToDisplay,
    [action.payload.ruleId]: {
      ...state.objectsToDisplay?.[action.payload.ruleId],
      filtersToDisplay: action.payload.filtersToDisplay,
    },
  },
});

const editFilterToDisplayStatus = (state: PlanForecast, action: PlanForecastsEditFilterToDisplayStatusAction) => ({
  ...state,
  objectsToDisplay: {
    ...state.objectsToDisplay,
    [action.payload.ruleId]: {
      ...state.objectsToDisplay?.[action.payload.ruleId],
      filtersToDisplay: (state.objectsToDisplay?.[action.payload.ruleId].filtersToDisplay || []).map(
        (filterToDisplay) =>
          filterToDisplay.id === action.payload.filterToDisplayId
            ? { ...filterToDisplay, displayStatus: switchDisplayStatus(filterToDisplay.displayStatus) }
            : filterToDisplay,
      ),
    },
  },
});

// Fields to display edition
const editFieldsToDisplay = (state: PlanForecast, action: PlanForecastsEditFieldsToDisplayAction) => ({
  ...state,
  objectsToDisplay: {
    ...state.objectsToDisplay,
    [action.payload.ruleId]: {
      ...state.objectsToDisplay?.[action.payload.ruleId],
      filtersToDisplay: (state.objectsToDisplay?.[action.payload.ruleId].filtersToDisplay || []).map(
        (filterToDisplay) =>
          filterToDisplay.id === action.payload.filterId
            ? { ...filterToDisplay, fieldsToDisplay: action.payload.fieldsToDisplay }
            : filterToDisplay,
      ),
    },
  },
});

const editFieldToDisplayStatus = (state: PlanForecast, action: PlanForecastsEditFieldToDisplayStatusAction) => ({
  ...state,
  objectsToDisplay: {
    ...state.objectsToDisplay,
    [action.payload.ruleId]: {
      ...state.objectsToDisplay?.[action.payload.ruleId],
      filtersToDisplay: (state.objectsToDisplay?.[action.payload.ruleId].filtersToDisplay || []).map(
        (filterToDisplay) =>
          filterToDisplay.id === action.payload.filterId
            ? {
                ...filterToDisplay,
                fieldsToDisplay: filterToDisplay.fieldsToDisplay.map((fieldToDisplay) =>
                  fieldToDisplay.name === action.payload.fieldMachinename
                    ? { ...fieldToDisplay, displayStatus: switchDisplayStatus(fieldToDisplay.displayStatus) }
                    : fieldToDisplay,
                ),
              }
            : filterToDisplay,
      ),
    },
  },
});
