import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { groupBy, keyBy } from 'lodash';
import { memo, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertBanner, Button } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';
import { UserProfileApiClient } from '@amalia/tenants/users/profile/api-client';
import { useAuthorizedProfiles, useInviteUsersState } from '@amalia/tenants/users/profile/state';

const BannerContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const BannerContent = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;
`;

const ErrorList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;

  gap: 8px;
`;

export const BulkInvitationErrorBanner = memo(function BulkInvitationErrorBanner() {
  const { isDismissed, setDismissedTrue, setDismissedFalse } = useBoolState(false, 'dismissed');
  const state = useInviteUsersState();
  const { data } = useAuthorizedProfiles();

  const usersRecord = useMemo(() => keyBy(data, 'id'), [data]);

  useEffect(() => {
    // Reset dismissed state when the state changes (reopen the banner when there is a new error)
    setDismissedFalse();
  }, [state, setDismissedFalse]);

  if (isDismissed || !state || !data.length) {
    return null;
  }

  const { status, error: mutationError } = state;

  if (status !== 'error' || !mutationError) {
    return null;
  }

  if (!UserProfileApiClient.isBulkInvitationError(mutationError)) {
    /* Not a bulk invitation error, only display the error message as reason */
    return (
      <AlertBanner
        withBorder
        variant={AlertBanner.Variant.ERROR}
        css={css`
          width: 100%;
        `}
      >
        <FormattedMessage
          defaultMessage="Invitations could not be sent: {reason}"
          values={{ reason: mutationError.message }}
        />
      </AlertBanner>
    );
  }

  const { errors, total } = mutationError.response.data;

  /* If every error is the same, display it as a single error message at the top, otherwise display each error next to the user item*/
  const [{ error: firstError }] = errors;
  const errorGroups = groupBy(errors, 'error.message');
  const isSameErrorForEveryone = Object.keys(errorGroups).length === 1;

  return (
    <AlertBanner
      withBorder
      variant={AlertBanner.Variant.ERROR}
      css={css`
        width: 100%;
        max-height: 160px;

        & > div {
          width: 100%;
        }
      `}
    >
      <BannerContentContainer>
        <BannerContent>
          <span>
            <FormattedMessage
              defaultMessage="Invitations for {count} out of {total} users could not be sent: {isSameErrorForEveryone, select, true {{ reason }} other {}}"
              values={{
                count: errors.length,
                total,
                isSameErrorForEveryone,
                reason: firstError.message,
              }}
            />
          </span>
          <ErrorList>
            {errors.map(({ id, error }) => {
              const user = id in usersRecord ? usersRecord[id] : null;

              if (!user) {
                return null;
              }

              const { firstName, lastName, email } = user;

              return (
                <li key={id}>
                  <FormattedMessage
                    defaultMessage="{firstName} {lastName} ({email}){isSameErrorForEveryone, select, false {: {reason}} other {}}"
                    values={{
                      firstName,
                      lastName,
                      email,
                      isSameErrorForEveryone,
                      reason: error.message,
                    }}
                  />
                </li>
              );
            })}
          </ErrorList>
        </BannerContent>
        <Button
          variant={Button.Variant.LIGHT}
          onClick={setDismissedTrue}
        >
          <FormattedMessage defaultMessage="Close this message" />
        </Button>
      </BannerContentContainer>
    </AlertBanner>
  );
});
