import { type UserContract } from '@amalia/tenants/users/types';

type MinUserType = Pick<UserContract, 'firstName' | 'lastName'>;

/**
 * Format a user full name.
 * Machine names don't have a firstName so it is ignored.
 *
 * @param user - User.
 * @returns User full name.
 */
export const formatUserFullName = (user?: Partial<MinUserType> | null): string =>
  [user?.firstName, user?.lastName].filter(Boolean).join(' ').trim();
