import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal } from '@amalia/design-system/components';

interface DeleteMessageModalProps {
  readonly isOpen: boolean;
  readonly onSubmit: () => Promise<void>;
  readonly closeModal: () => void;
}

export const DeleteMessageModal = memo(function DeleteMessageModal({
  isOpen,
  onSubmit,
  closeModal,
}: DeleteMessageModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      variant={Modal.Variant.DANGER}
      onClose={closeModal}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Delete comment" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            <FormattedMessage defaultMessage="Are you sure you want to delete this comment?" />
          </p>
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction onClick={closeModal} />

        <Modal.MainAction onClick={onSubmit}>
          <FormattedMessage defaultMessage="Delete" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
