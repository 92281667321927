import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { type PaymentCategory } from '@amalia/core/types';
import { useBoolState } from '@amalia/ext/react/hooks';
import {
  selectCurrentPeriod,
  selectCurrentStatement,
  selectPaymentsByCategoryForCurrentStatement,
  selectStatementPaymentAmountByCategory,
} from '@amalia/frontend/web-data-layers';
import { PAYMENT_CATEGORIES_LABELS } from '@amalia/lib-ui';
import { RuleAccordion } from '@amalia/lib-ui-business';
import { RuleLayout } from '@amalia/payout-definition/plans/rules/components';

import { StatementPaymentsDataGrid } from './statement-payments-data-grid/StatementPaymentsDataGrid';

interface StatementPaymentsProps {
  readonly category: PaymentCategory;
}

export const StatementPayments = memo(function StatementPayments({ category }: StatementPaymentsProps) {
  const { isRuleExpanded, toggleRuleExpanded } = useBoolState(true, 'ruleExpanded');

  const statement = useSelector(selectCurrentStatement);
  const currentPeriod = useSelector(selectCurrentPeriod);
  const paymentsByCategory = useSelector(selectPaymentsByCategoryForCurrentStatement);
  const paymentAmountsByCategory = useSelector(selectStatementPaymentAmountByCategory);

  const mainKpi = useMemo(
    () => ({
      value: {
        currencySymbol: statement.currency,
        amount: paymentAmountsByCategory[category],
      },
      type: category,
    }),
    [statement, paymentAmountsByCategory, category],
  );

  const paymentsToShow = useMemo(
    // We want payment for the selected category that have a value and > 0
    () => (paymentsByCategory[category] || []).filter((p) => !!p.value),
    [paymentsByCategory, category],
  );

  return (
    <RuleAccordion
      header={
        <RuleAccordion.Header
          isExpanded={isRuleExpanded}
          label={<FormattedMessage {...PAYMENT_CATEGORIES_LABELS[category]} />}
          machineName={category}
          mainKpi={mainKpi}
          onToggleExpanded={toggleRuleExpanded}
        />
      }
    >
      <RuleLayout>
        <RuleLayout.Dataset>
          <StatementPaymentsDataGrid
            category={category}
            payments={paymentsToShow}
            period={currentPeriod}
            statement={statement}
          />
        </RuleLayout.Dataset>
      </RuleLayout>
    </RuleAccordion>
  );
});
