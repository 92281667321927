import { css } from '@emotion/react';
import { memo } from 'react';

import { useStatementDetailContext } from '@amalia/lib-ui';
import { RuleLayout } from '@amalia/payout-definition/plans/rules/components';
import { type PlanRule } from '@amalia/payout-definition/plans/types';

import { ChartItem } from './ChartItem';

interface ChartsProps {
  readonly ruleDefinition: PlanRule;
}

export const Charts = memo(function Charts({ ruleDefinition }: ChartsProps) {
  const { statement } = useStatementDetailContext();

  return (
    <RuleLayout.Charts>
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 24px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;
            gap: 24px;
          `}
        >
          {(ruleDefinition.charts || []).map((c) => (
            <ChartItem
              key={c.id}
              chart={c}
              statement={statement}
            />
          ))}
        </div>
      </div>
    </RuleLayout.Charts>
  );
});
