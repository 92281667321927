import { memo } from 'react';
import { useSelector } from 'react-redux';
import useAsyncEffect from 'use-async-effect';

import { useThunkDispatch, refreshTodosCount, selectTodosCount } from '@amalia/frontend/web-data-layers';
import { TodosPresentation } from '@amalia/lib-ui-business';

const Todos = memo(function Todos() {
  const dispatch = useThunkDispatch();
  const todoCount = useSelector(selectTodosCount);

  useAsyncEffect(() => dispatch(refreshTodosCount()), []);

  return <TodosPresentation todosCount={todoCount} />;
});

export default Todos;
