import styled from '@emotion/styled';

import { Paper } from '@amalia/design-system/components';

export const DashboardWidget = styled(Paper)`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 24px 40px;

  gap: 24px;
`;
