import { css, type Theme } from '@emotion/react';

import { ModalSize, ModalVariant } from './Modal.types';

export const modal = (theme: Theme) => css`
  background-color: ${theme.ds.colors.gray[0]};
  border-radius: ${theme.ds.borderRadiuses.squared};
  box-shadow: ${theme.ds.shadows.hard};

  display: flex;
  flex-direction: column;

  &.${ModalSize.SMALL} {
    width: 400px;
    max-height: 352px;
  }

  &.${ModalSize.MEDIUM} {
    width: 600px;
    max-height: 528px;
  }

  &.${ModalSize.LARGE} {
    width: 800px;
    max-height: 704px;
  }

  &.${ModalSize.XLARGE} {
    width: 1000px;
    height: 620px;
  }
`;

export const border = (theme: Theme) => css`
  height: 4px;
  border-radius: ${theme.ds.borderRadiuses.squared} ${theme.ds.borderRadiuses.squared} 0 0;

  flex: none;

  &.${ModalVariant.DANGER} {
    background-color: ${theme.ds.colors.danger[300]};
  }

  &.${ModalVariant.DEFAULT} {
    background-color: ${theme.ds.colors.primary[300]};
  }
`;

export const overlay = (theme: Theme) => css`
  display: grid;
  place-items: center;

  background-color: ${theme.ds.colors.gray[900]}19;
`;

export const preventClickAway = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  /* Leave scrollbar accessible. */
  right: 20px;
`;

export const triedToDismiss = (theme: Theme) => css`
  animation: headshake ${theme.ds.transitions.default.durationMs * 2}ms ${theme.ds.transitions.default.easing};
  animation-fill-mode: forwards;

  @keyframes headshake {
    0% {
      transform: translateX(0);
    }

    13% {
      transform: translateX(-12px);
    }

    37% {
      transform: translateX(10px);
    }

    63% {
      transform: translateX(-6px);
    }

    87% {
      transform: translateX(4px);
    }

    100% {
      transform: translateX(0);
    }
  }
`;
