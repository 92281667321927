import { createContext, useContext } from 'react';

import { assert } from '@amalia/ext/typescript';

import { type TabValue } from './Tabs.types';

export type TabsContextValue<TTabValue extends TabValue = TabValue> = {
  value: TTabValue;
  onChange: (value: TTabValue) => void;
  contentContainerElement: HTMLElement | null;
};

export const TabsContext = createContext<TabsContextValue | null>(null);

export const useTabsContext = <TTabValue extends TabValue = TabValue>(): TabsContextValue<TTabValue> => {
  const context = useContext(TabsContext);
  assert(context, 'useTabsContext must be used within a <TabsContext.Provider>');
  return context as TabsContextValue<TTabValue>;
};
