import { css } from '@emotion/react';
import { nth } from 'lodash';
import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { formatValue, reformatDateString } from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { CardWithTabs } from '@amalia/design-system/components';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { useFormattedPeriodName } from '@amalia/payout-definition/periods/components';
import { type Period } from '@amalia/payout-definition/periods/types';
import {
  type PayoutAndPerformanceChartStatistics,
  type PayoutAndPerformanceChartStatisticsRecord,
} from '@amalia/reporting/custom-reports/shared';

import { findPayoutAndPerformanceChartStatisticsKpiColumns } from '../payout-and-performance-chart-widget/kpiColumns.finder';

import { CardContent } from './card-with-kpis/CardContent';
import { useYtdRecord } from './useYtdRecord';

const findMonthRecord = (ytdStats: PayoutAndPerformanceChartStatistics, month: string) =>
  ytdStats?.records?.find((r) => r.ruleMetricPeriod__month === month);

const formatTabValue = (record?: PayoutAndPerformanceChartStatisticsRecord, userCurrency?: CurrencySymbolsEnum) =>
  record
    ? formatValue(
        record?.ruleMetricPayment__value?.value ?? 0,
        FormatsEnum.currency,
        record?.ruleMetricPayment__value?.symbol ?? userCurrency,
      )
    : undefined;

export type TabCardsKpisProps = {
  readonly ytdStats: PayoutAndPerformanceChartStatistics;
  readonly currentPeriod: Period;
  readonly userCurrency?: CurrencySymbolsEnum;
};

export const TabCardsKpis = memo(function TabCardsKpis({ ytdStats, currentPeriod, userCurrency }: TabCardsKpisProps) {
  const { formatMessage } = useIntl();

  const { currentPeriodLongLabel, currentPeriodShortLabel, previousPeriodShortLabel, previousPeriodLongLabel } =
    useFormattedPeriodName(currentPeriod);

  const currentPeriodRecord = useMemo(
    () => findMonthRecord(ytdStats, currentPeriodShortLabel),
    [currentPeriodShortLabel, ytdStats],
  );
  const previousPeriodRecord = useMemo(
    () => findMonthRecord(ytdStats, previousPeriodShortLabel),
    [previousPeriodShortLabel, ytdStats],
  );

  const [kpi1, kpi2] = useMemo(() => {
    const kpis = findPayoutAndPerformanceChartStatisticsKpiColumns(ytdStats);

    return [nth(kpis, 0), nth(kpis, 1)];
  }, [ytdStats]);

  const ytdRecords = useMemo(
    () =>
      ytdStats?.records?.filter(
        (r) =>
          reformatDateString(r.ruleMetricPeriod__month, 'MM/YYYY', 'YYYY') ===
          reformatDateString(currentPeriodShortLabel, 'MM/YYYY', 'YYYY'),
      ),
    [ytdStats, currentPeriodShortLabel],
  );

  const ytdAverageRecord: PayoutAndPerformanceChartStatisticsRecord = useYtdRecord(ytdRecords, kpi1, kpi2);

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
      `}
    >
      <CardWithTabs defaultTab="current">
        <CardWithTabs.Tab
          data={formatTabValue(previousPeriodRecord, userCurrency)}
          label={previousPeriodLongLabel}
          value="previous"
        >
          <CardContent
            kpi1={kpi1}
            kpi2={kpi2}
            record={previousPeriodRecord}
          />
        </CardWithTabs.Tab>

        <CardWithTabs.Tab
          data={formatTabValue(currentPeriodRecord, userCurrency)}
          label={currentPeriodLongLabel}
          value="current"
        >
          <CardContent
            kpi1={kpi1}
            kpi2={kpi2}
            record={currentPeriodRecord}
          />
        </CardWithTabs.Tab>

        <CardWithTabs.Tab
          data={formatTabValue(ytdAverageRecord, userCurrency)}
          label={formatMessage({ defaultMessage: 'Year to date' })}
          value="next"
        >
          <CardContent
            kpi1={kpi1}
            kpi2={kpi2}
            record={ytdAverageRecord}
          />
        </CardWithTabs.Tab>
      </CardWithTabs>
    </div>
  );
});
