import { css } from '@emotion/react';
import { memo, type ReactNode, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { Switch, type AlertBannerProps } from '@amalia/design-system/components';

export type StatementSummaryReviewDisabledProps = {
  /** Alert message. */
  readonly alert?: ReactElement<AlertBannerProps>;
  /** Optional action (e.g. a button to show the review workflow status). */
  readonly action?: ReactNode;
};

export const StatementSummaryReviewDisabled = memo(function StatementSummaryReviewDisabled({
  alert,
  action,
}: StatementSummaryReviewDisabledProps) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
      `}
    >
      <Switch
        disabled
        label={<FormattedMessage defaultMessage="Review" />}
      />

      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
        `}
      >
        {alert}
        {action}
      </div>
    </div>
  );
});
