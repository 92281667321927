import { useQuery } from '@tanstack/react-query';

import { PeriodsApiClient } from '@amalia/payout-definition/api-client';
import { type PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';

import { PERIOD_QUERY_KEYS } from './query-keys';

export const usePeriod = (date: string, frequency: PeriodFrequencyEnum) =>
  useQuery({
    queryKey: [PERIOD_QUERY_KEYS.PERIOD, date, frequency],
    queryFn: () => PeriodsApiClient.getPeriodByDate(date, frequency),
    placeholderData: (pastValue) => pastValue ?? undefined,
  });
