import { type ForwardedRef, forwardRef } from 'react';

import { type FormikFieldProps, useFormikFieldAdapter } from '@amalia/ext/formik';

import { TextArea, type TextAreaProps } from '../TextArea';

export type FormikTextAreaProps = FormikFieldProps<TextAreaProps>;

const FormikTextAreaBase = forwardRef(function FormikTextArea(
  {
    validate, // Omit validate and any props not passed to TextArea.
    ...props
  }: FormikTextAreaProps,
  ref: ForwardedRef<HTMLTextAreaElement>,
) {
  const formikFieldProps = useFormikFieldAdapter<TextAreaProps['value']>({ validate, ...props });

  return (
    <TextArea
      {...props}
      {...formikFieldProps}
      ref={ref}
    />
  );
});

export const FormikTextArea = Object.assign(FormikTextAreaBase, {
  Size: TextArea.Size,
});
