import { type FormatsEnum } from '@amalia/data-capture/fields/types';

export enum ChartSeriesType {
  BAR = 'bar',
  LINE = 'line',
  DOTTED_LINE = 'dotted-line',
}

export type ChartSeries = {
  label: string;
  name: string;
  color: string;
  type: ChartSeriesType;
  format: FormatsEnum;
  dataKey: string;
};

/**
 * DataKey can be a compound Field such as PAYMENT__value.value.
 * Definition name in this case is PAYMENT__value.
 * @param name
 */
export const getDefinitionNameFromDataKey = (name: string) => name?.split('.')?.[0];
