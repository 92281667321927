import { type ForwardedRef, forwardRef, memo } from 'react';

import { Button, type ButtonProps } from '../../../general/button/Button';
import { useDropdownContext } from '../Dropdown.context';

export type DropdownButtonProps = ButtonProps;

const DropdownButtonForwardRef = forwardRef(function DropdownButton(
  { children, ...props }: DropdownButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { isOpen } = useDropdownContext();

  return (
    <Button
      {...props}
      ref={ref}
      isActive={props.isActive ?? isOpen}
    >
      {children}
    </Button>
  );
});

export const DropdownButton = Object.assign(memo(DropdownButtonForwardRef), {
  Size: Button.Size,
  Variant: Button.Variant,
  IconPosition: Button.IconPosition,
});
