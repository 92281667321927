import { css } from '@emotion/react';
import { isNil } from 'lodash';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Popover, Typography, type PopoverProps, Button, Stack } from '@amalia/design-system/components';
import { type MergeAll } from '@amalia/ext/typescript';

import { ForecastAmount } from '../forecast-amount/ForecastAmount';

import * as styles from './ForecastSimulationPopover.styles';

export type ForecastSimulationPopoverProps = MergeAll<
  [
    Omit<PopoverProps, 'content' | 'shouldTriggerOnHover'>,
    {
      readonly formattedAchievedValue?: number | string;
      readonly formattedForecastedValue?: number | string;
      readonly onDeleteOverwrite?: () => void;
      readonly overwriteInfo?: {
        readonly author: string;
        readonly date: string;
        readonly sourceValue: number | string;
        readonly value: number | string;
      };
    },
  ]
>;

export const ForecastSimulationPopover = memo(function ForecastSimulationPopover({
  formattedAchievedValue,
  formattedForecastedValue,
  overwriteInfo,
  onDeleteOverwrite,
  children,
  ...props
}: ForecastSimulationPopoverProps) {
  const isValueForecasted = !isNil(formattedForecastedValue) && formattedForecastedValue !== formattedAchievedValue;

  return (
    <Popover
      {...props}
      shouldTriggerOnHover
      disabled={props.disabled || (!isValueForecasted && !overwriteInfo)}
      content={
        <Popover.Layout
          data-testid="forecast-simulation-popover"
          css={css`
            max-height: unset; // Disable overflow on Popover.
          `}
        >
          <Popover.Header>
            <Popover.Title>
              {!!isValueForecasted && !overwriteInfo && <FormattedMessage defaultMessage="Forecast details" />}

              {!!isValueForecasted && !!overwriteInfo && (
                <FormattedMessage defaultMessage="Forecast & simulation details" />
              )}

              {!isValueForecasted && !!overwriteInfo && <FormattedMessage defaultMessage="Simulation details" />}
            </Popover.Title>
          </Popover.Header>

          {!!isValueForecasted && (
            <div css={[styles.valuesGrid, styles.sectionContainer]}>
              <Typography variant={Typography.Variant.BODY_SMALL_MEDIUM}>
                <FormattedMessage defaultMessage="Achieved value" />
              </Typography>

              <Typography
                data-testid="achieved-value"
                variant={Typography.Variant.BODY_BASE_MEDIUM}
              >
                {formattedAchievedValue}
              </Typography>

              <Typography variant={Typography.Variant.BODY_SMALL_MEDIUM}>
                <FormattedMessage defaultMessage="Forecasted value" />
              </Typography>

              <ForecastAmount
                data-testid="forecasted-value"
                variant={Typography.Variant.BODY_BASE_MEDIUM}
              >
                {formattedForecastedValue}
              </ForecastAmount>
            </div>
          )}

          {!!overwriteInfo && (
            <Stack
              css={styles.sectionContainer}
              gap={16}
            >
              <div css={styles.valuesGrid}>
                <Typography variant={Typography.Variant.BODY_SMALL_MEDIUM}>
                  <FormattedMessage defaultMessage="Original value" />
                </Typography>

                <Typography
                  data-testid="simulation-original-value"
                  variant={Typography.Variant.BODY_BASE_MEDIUM}
                >
                  {overwriteInfo.sourceValue}
                </Typography>

                <Typography variant={Typography.Variant.BODY_SMALL_MEDIUM}>
                  <FormattedMessage defaultMessage="New value" />
                </Typography>

                <ForecastAmount
                  css={styles.overwrittenValue}
                  data-testid="simulation-new-value"
                  variant={Typography.Variant.BODY_BASE_MEDIUM}
                >
                  {overwriteInfo.value}
                </ForecastAmount>
              </div>

              <Stack
                align="flex-start"
                gap={8}
              >
                <Typography
                  as="div"
                  variant={Typography.Variant.BODY_XSMALL_REGULAR}
                >
                  <FormattedMessage
                    defaultMessage="Overwritten by {author}{br}on {date}"
                    values={{
                      author: overwriteInfo.author,
                      date: overwriteInfo.date,
                    }}
                  />
                </Typography>

                {!!onDeleteOverwrite && (
                  <Button
                    variant={Button.Variant.PRIMARY}
                    onClick={onDeleteOverwrite}
                  >
                    <FormattedMessage defaultMessage="Clear simulation" />
                  </Button>
                )}
              </Stack>
            </Stack>
          )}
        </Popover.Layout>
      }
    >
      {children}
    </Popover>
  );
});
