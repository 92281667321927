import { http, qsStringify } from '@amalia/core/http/client';
import { type CreatePlanAssignment, type PlanAssignment } from '@amalia/payout-definition/plans/types';

import { type PlanAssignmentWithUser } from '../../types/planAssignments';

const apiEndpoint = '/assignments';

export const createPlanAssignment = async (planAssignments: CreatePlanAssignment[]): Promise<PlanAssignment[]> => {
  const { data } = await http.post<PlanAssignment[]>(`${apiEndpoint}`, planAssignments);
  return data;
};

export const updatePlanAssignment = async (planAssignment: PlanAssignment): Promise<PlanAssignment> => {
  const { data } = await http.patch<PlanAssignment>(`${apiEndpoint}`, planAssignment);
  return data;
};

export const deletePlanAssignment = async (planAssignment: PlanAssignment): Promise<void> => {
  await http.delete(`${apiEndpoint}`, {
    data: planAssignment,
  });
};

export const listPlanAssignmentsForPlan = async (planId: string): Promise<PlanAssignmentWithUser[]> => {
  const { data } = await http.get<PlanAssignmentWithUser[]>(`${apiEndpoint}/?planId=${planId}&relations=user`);
  return data;
};

export const listPlanAssignmentsForPlans = async (planIds: string[]): Promise<PlanAssignmentWithUser[]> => {
  const { data } = await http.get<PlanAssignmentWithUser[]>(
    `${apiEndpoint}/?${qsStringify({ planId: planIds, relations: 'user' })}`,
  );
  return data;
};
