import { css } from '@emotion/react';
import { memo } from 'react';

import { type ChartSeriesMetadata, ChartSeriesType } from '../../types/charts.types';

export type ChartSeriesIconProps = {
  readonly series: ChartSeriesMetadata;
};

export const ChartSeriesIcon = memo(function ChartSeriesIcon({ series }: ChartSeriesIconProps) {
  switch (series.type) {
    case ChartSeriesType.BAR:
      return (
        <div
          css={(theme) => css`
            flex: none;

            border-radius: ${theme.ds.borderRadiuses.squared};
            height: 12px;
            width: 12px;

            background-color: ${series.color};
          `}
        />
      );
    default:
      return null;
  }
});
