export const USERS_STATEMENTS_ACTIONS = {
  START: 'USER_STATEMENTS/START',
  ERROR: 'USER_STATEMENTS/ERROR',

  SET_USER_STATEMENTS_PAGE_SUCCESS: 'USER_STATEMENTS/SET_USER_STATEMENTS_PAGE_SUCCESS',
  SET_USER_STATEMENTS_LIST_SUCCESS: 'USER_STATEMENTS/SET_USER_STATEMENTS_LIST_SUCCESS',
  CLEAR_USER_STATEMENTS: 'USER_STATEMENTS/CLEAR_STATEMENTS',
  CLEAR_USER_STATEMENTS_FACETS: 'USER_STATEMENTS/CLEAR_USER_STATEMENTS_FACETS',

  MASS_REVIEW_STATEMENTS: 'USER_STATEMENTS/MASS_REVIEW_STATEMENTS',

  CHANGE_LIST_SELECTED_OPTIONS: 'USER_STATEMENTS/CHANGE_LIST_SELECTED_OPTIONS',
} as const;
