import { css } from '@emotion/react';
import { memo } from 'react';
import { FormattedList } from 'react-intl';

import { formatUserFullName } from '@amalia/core/types';
import { Group } from '@amalia/design-system/components';
import { AvatarGroup } from '@amalia/lib-ui';
import { type UserComputed } from '@amalia/tenants/users/types';

interface CalculationProgressModalStepBatchUsersProps {
  readonly users: UserComputed[];
  readonly subTitle?: string;
}

export const CalculationProgressModalStepBatchUsers = memo(function CalculationProgressModalStepBatchUsers({
  users,
  subTitle,
}: CalculationProgressModalStepBatchUsersProps) {
  return (
    <Group
      inline
      align="center"
      gap={16}
      css={(theme) => css`
        padding: 8px;
        border: 1px solid ${theme.ds.colors.primary[400]};
        border-radius: ${theme.ds.borderRadiuses.squared};
      `}
    >
      <div>
        <AvatarGroup
          max={3}
          users={users}
        />
      </div>

      <div>
        <div>
          <FormattedList
            style="short"
            type="unit"
            value={users.map((u) => formatUserFullName(u))}
          />
        </div>
        <div>{subTitle}</div>
      </div>
    </Group>
  );
});
