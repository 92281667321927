interface TableSlice {
  min: number;
  max: number;
  percent: number;
}

export const getTableSlice = (element: any): TableSlice => ({
  min: element[0],
  max: element[1],
  percent: element[2],
});

export const getRowFieldAmount = (row: any, field: string): number => {
  // Can't use optional chaining here because if the value is 0 it'll crash.
  const fieldValue =
    typeof row[field] === 'object' && Object.hasOwn(row[field], 'value') ? row[field].value : row[field];

  if (Number.isNaN(+fieldValue)) {
    throw new Error(`${field} value is not a number`);
  }

  return parseFloat(fieldValue);
};
