import { useCallback } from 'react';

import { type VariableDefinition } from '@amalia/amalia-lang/tokens/types';
import { isBeingReviewed, type DatasetRow, type Statement, type Overwrite } from '@amalia/core/types';
import { type Property } from '@amalia/data-capture/fields/types';
import { isCustomObjectDefinition, type BaseCustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { useShallowObjectMemo } from '@amalia/ext/react/hooks';
import { ActionsEnum, SubjectsEnum, subject } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { isCurrencyValue, type CurrencyValue } from '@amalia/kernel/monetary/types';
import { DatasetType, type Dataset } from '@amalia/payout-calculation/types';
import { type PlanRule } from '@amalia/payout-definition/plans/types';

import { type ComputedPlanRuleFieldsToDisplayWithSubTitle } from '../../../../RowsTable/RowsTable';

export const useDatasetRecordsDataGridCellOverwrite = ({
  datasetRow,
  dataset,
  rule,
  field,
  rowIndex,
  onClearOverwrite,
  statement = undefined,
  customObjectDefinition = undefined,
  isForecasted = false,
  variableDefinition = undefined,
  propertyDefinition = undefined,
}: {
  datasetRow: DatasetRow;
  dataset: Dataset;
  field: ComputedPlanRuleFieldsToDisplayWithSubTitle;
  rowIndex: number;
  rule?: PlanRule;
  statement?: Statement;
  customObjectDefinition?: BaseCustomObjectDefinition;
  isForecasted?: boolean;
  variableDefinition?: VariableDefinition;
  propertyDefinition?: Property;
  onClearOverwrite: (overwrite: Overwrite) => void;
}) => {
  const ability = useAbilityContext();

  const cellOverwrite = datasetRow.overwrites?.find((overwrite) => overwrite.field === field.name);

  const isOverwriteEnabled =
    !isForecasted &&
    dataset.type === DatasetType.filter &&
    ability.can(ActionsEnum.overwrite, subject(SubjectsEnum.Statement, statement)) &&
    statement &&
    !isBeingReviewed(statement) &&
    ![
      'id',
      'userId',
      'statementId',
      ...(customObjectDefinition?.externalIds || []),
      isCustomObjectDefinition(customObjectDefinition) ? customObjectDefinition.nameField : undefined,
    ]
      .filter(Boolean)
      .includes(field.name);

  const format = propertyDefinition?.format || variableDefinition?.format;
  const rawValue = datasetRow.content[field.name] as CurrencyValue | boolean | number | string | null;

  const overwriteDetails = useShallowObjectMemo({
    format,
    ref: propertyDefinition?.ref,
    rule: rule?.name,
    oldValue: isCurrencyValue(rawValue) ? rawValue.value : rawValue,
    field: field.name,
    fieldName: field.label,
    opportunityObject: customObjectDefinition?.name,
    rowNameOrId: (datasetRow.content.name as string) || (datasetRow.content.id as string) || datasetRow.externalId,
    rowId: rowIndex,
    isProperty: !!propertyDefinition,
  });

  const handleClearOverwrite = useCallback(() => onClearOverwrite(cellOverwrite), [cellOverwrite, onClearOverwrite]);

  return {
    cellOverwrite,
    isOverwriteEnabled,
    overwriteDetails,
    handleClearOverwrite,
  };
};
