import { css, type Theme } from '@emotion/react';

export const IS_SELECTED_CLASSNAME = 'isSelected';
export const HAS_SUBTITLE_CLASSNAME = 'hasSubtitle';
export const WITH_BORDER_TOP_CLASSNAME = 'withBorderTop';

export const fatTab = (theme: Theme) => css`
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-decoration: none;
  user-select: text;

  transition: ${theme.ds.transitions.default('background-color', 'border-color')};

  min-width: 150px;
  min-height: 110px;
  flex: 1;

  background-color: ${theme.ds.colors.gray[50]};

  --base-padding-top: 24px;
  --padding-top: var(--base-padding-top);
  --border-top-width: 0;

  padding: var(--padding-top) 32px 20px;
  border-top: var(--border-top-width) solid transparent;

  &.${HAS_SUBTITLE_CLASSNAME} {
    --base-padding-top: 18px;
  }

  &.${WITH_BORDER_TOP_CLASSNAME} {
    --padding-top: calc(var(--base-padding-top) - 1px);
    --border-top-width: 1px;
    border-color: ${theme.ds.colors.gray[100]};
  }

  &:not(:only-child).${IS_SELECTED_CLASSNAME} {
    --padding-top: calc(var(--base-padding-top) - 3px);
    --border-top-width: 3px;
    border-color: ${theme.ds.colors.primary[300]};
  }

  &:only-child,
  &.${IS_SELECTED_CLASSNAME} {
    background-color: ${theme.ds.colors.gray[0]};
  }

  &:only-child {
    [data-fat-tab-data='true'] {
      ${theme.ds.typographies.heading2Medium};
    }
  }

  :not(.${IS_SELECTED_CLASSNAME}) + &:not(.${IS_SELECTED_CLASSNAME}) {
    &::before {
      position: absolute;
      content: '';
      top: var(--border-top-width);
      left: 0;
      bottom: 0;
      right: 0;
      border-left: 1px solid ${theme.ds.colors.gray[100]};
      pointer-events: none;
    }
  }

  &:focus-visible {
    z-index: 1;
    outline: 1px solid ${theme.ds.colors.primary[200]};
    box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.primary[50])};
  }
`;
