import { css, type Theme } from '@emotion/react';

export const IS_EMPTY_CLASSNAME = 'is-empty';

export const statementKpi = (theme: Theme) => css`
  display: flex;
  align-items: center;
  gap: 6px;

  list-style-type: none;

  color: ${theme.ds.colors.gray[700]};

  &.${IS_EMPTY_CLASSNAME} {
    color: ${theme.ds.colors.gray[400]};
  }
`;
