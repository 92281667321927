import { Fragment, memo, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { Table } from '@amalia/design-system/components';
import { useFormikFieldAdapter } from '@amalia/ext/formik';
import { assert } from '@amalia/ext/typescript';
import { useUserCurrentAndFutureTeamAssignments } from '@amalia/tenants/assignments/teams/state';
import { TeamRole } from '@amalia/tenants/assignments/teams/types';
import { type UserInfo } from '@amalia/tenants/users/profile/types';
import { type UserRole } from '@amalia/tenants/users/types';

import { ConfirmDemotionModal } from './confirm-demotion/ConfirmDemotionModal';
import { shouldConfirmDemotion } from './confirm-demotion/shouldConfirmDemotion';
import { SelectedRoleOption } from './SelectedRoleOption';
import { useRoleOptions } from './useRoleOptions';

export type RoleSelectCellProps = {
  readonly row: Pick<UserInfo, 'firstName' | 'id' | 'lastName' | 'role'>;
};

export const RoleSelectCell = memo(function RoleSelectCell({ row }: RoleSelectCellProps) {
  const { formatMessage } = useIntl();
  const roleOptions = useRoleOptions();

  const { data: teamAssignments, isLoading } = useUserCurrentAndFutureTeamAssignments(row.id, {
    teamRole: TeamRole.TEAM_MANAGER,
  });

  const [nextRole, setNextRole] = useState<UserRole | null>(null);

  const { onChange: onChangeField, ...inputProps } = useFormikFieldAdapter<UserRole>({
    name: 'role',
    type: 'text',
  });

  const onConfirm = useCallback(() => {
    assert(nextRole, 'nextRole must be defined');
    onChangeField(nextRole);
    setNextRole(null);
  }, [nextRole, onChangeField]);

  const onCancel = useCallback(() => {
    setNextRole(null);
  }, []);

  const onChange = useCallback(
    (value: UserRole) => {
      assert(teamAssignments, 'teamAssignments must have been loaded');

      if (shouldConfirmDemotion(value) && teamAssignments.length > 0) {
        setNextRole(value);
        return;
      }
      onChangeField(value);
    },
    [teamAssignments, onChangeField],
  );

  return isLoading || !teamAssignments ? (
    <Table.Cell.Loading />
  ) : (
    <Fragment>
      <Table.Cell.Select
        {...inputProps}
        data-testid="user-info-table-select-role"
        isClearable={false}
        options={roleOptions}
        row={row}
        SingleValueComponent={SelectedRoleOption}
        placeholder={formatMessage({
          defaultMessage: 'Role',
        })}
        onChange={onChange}
      />
      {!!nextRole && (
        <ConfirmDemotionModal
          isOpen
          nextRole={nextRole}
          teamAssignments={teamAssignments}
          user={row}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    </Fragment>
  );
});
