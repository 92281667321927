import { css } from '@emotion/react';
import { type ComponentPropsWithoutRef, memo } from 'react';

import { Avatar } from '@amalia/lib-ui';
import { type UserProfile } from '@amalia/tenants/users/profile/types';

import { UserAvatarAnchor } from './form/UserAvatarAnchor';
import { useUserAvatarAbilities } from './useUserAvatarAbilities';

const containerStyle = css`
  position: relative;
`;

const avatarStyle = css`
  --avatar-size: 64px;

  width: var(--avatar-size);
  height: var(--avatar-size);
`;

const avatarAnchorStyle = css`
  position: absolute;
  bottom: 0;
  right: 0;
  translate: 25% 25%;
`;

export type UserAvatarProps = ComponentPropsWithoutRef<'div'> & {
  readonly user: Pick<UserProfile, 'firstName' | 'id' | 'lastName' | 'pictureURL'>;
};

export const UserAvatar = memo(function UserAvatar({ user, ...props }: UserAvatarProps) {
  const { isAvatarEditable } = useUserAvatarAbilities(user);

  return (
    <div
      css={containerStyle}
      {...props}
      data-testid="user-avatar-container"
    >
      <Avatar
        css={avatarStyle}
        user={user}
      />

      {!!isAvatarEditable && <UserAvatarAnchor css={avatarAnchorStyle} />}
    </div>
  );
});
