import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { http } from '@amalia/core/http/client';
import { UserApiClient } from '@amalia/tenants/users/api-client';

import { AUTHZ_CONTEXT_QUERY_KEYS } from './queries.keys';

export const useFetchAuthenticatedContext = () => {
  const { isAuthenticated: isAuthenticatedWithAuth0 } = useAuth0();
  // Here we use isAuthenticatedWithAuth0 to make sure the user is authenticated
  // For mocked test, we look if the mocked jwt token is set
  const { data: authenticatedContext, ...rest } = useQuery({
    queryKey: [AUTHZ_CONTEXT_QUERY_KEYS.AUTHENTICATED_CONTEXT],
    queryFn: () => UserApiClient.fetchAuthenticatedContext(),
    enabled: !!http.getJwt() || isAuthenticatedWithAuth0,
    refetchOnWindowFocus: false,
  });

  return {
    authenticatedContext,
    ...rest,
  };
};

export const useImpersonateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [AUTHZ_CONTEXT_QUERY_KEYS.AUTHENTICATED_CONTEXT],
    mutationFn: UserApiClient.impersonate,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [AUTHZ_CONTEXT_QUERY_KEYS.AUTHENTICATED_CONTEXT] });
    },
  });
};
