import { css } from '@emotion/react';
import { makeStyles } from '@mui/styles';
import { IconCaretLeft, IconReload } from '@tabler/icons-react';
import { last } from 'lodash';
import {
  type Dispatch,
  Fragment,
  memo,
  type MouseEventHandler,
  type SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { type Statement } from '@amalia/core/types';
import { Button, Group } from '@amalia/design-system/components';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';
import { selectUsersMap } from '@amalia/frontend/web-data-layers';
import { Tooltip } from '@amalia/lib-ui';
import { WorkflowProgressBar } from '@amalia/payout-calculation/statements/components';
import {
  type WorkflowDefinition,
  type WorkflowStatementState,
  WorkflowStatementStateAction,
} from '@amalia/payout-collaboration/workflows/types';

import { WorkflowStepper } from '../../../../workflow/WorkflowStepper/WorkflowStepper';
import { useWorkflowStepName } from '../../useWorkflowStepName';

import { StatementWorkflowPresentationHeaderView } from './statement-workflow-presentation-header-view/StatementWorkflowPresentationHeaderView';
import { useStatementWorkflowPresentation } from './useStatementWorkflowPresentation';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '300px',
    margin: '0 auto',
  },
  actions: {
    padding: theme.spacing(2),
  },
}));

export interface StatementWorkflowAdditionalOtpions {
  forceShowProgressBar?: boolean;
}

interface StatementWorkflowPresentationProps {
  readonly statement: Statement;
  readonly workflow: WorkflowDefinition;
  readonly steps: Partial<WorkflowStatementState>[];
  readonly stepNumber: number;
  readonly onNextStep?: (canValidStep: WorkflowStatementStateAction, checked: boolean) => void;
  readonly onPreviousStep?: MouseEventHandler;
  readonly onReset?: MouseEventHandler;
  readonly tooltipInteractive?: boolean;
  readonly setTooltipOpened: Dispatch<SetStateAction<boolean>>;
  readonly tooltipOpened?: boolean;
  readonly additionalOptions?: StatementWorkflowAdditionalOtpions;
  readonly hasRightToResetWorkflow?: boolean;
  readonly statementHeaderView?: boolean;
}

export const StatementWorkflowPresentation = memo(function StatementWorkflowPresentation({
  statement,
  workflow,
  steps,
  stepNumber,
  onNextStep,
  onPreviousStep,
  onReset,
  tooltipInteractive,
  tooltipOpened,
  setTooltipOpened,
  additionalOptions,
  hasRightToResetWorkflow,
  statementHeaderView,
}: StatementWorkflowPresentationProps) {
  const classes = useStyles();
  const { formatStepName } = useWorkflowStepName();
  const usersMap = useSelector(selectUsersMap);

  const { isReviewed, haveValidatedStep, canValidSteps, canValidThisStep, canToggleStep, canBackStep, canResetSteps } =
    useStatementWorkflowPresentation({
      workflow,
      hasRightToResetWorkflow,
      onPreviousStep,
      onReset,
      statement,
      stepNumber,
    });

  // Compute last validated step name.
  const stepToPrint: {
    // Name of the step to be printed
    name: string;
    // Tells if the step name needs to be preceded by the text "Reviewed By"
    printReviewedBy: boolean;
  } = useMemo(() => {
    // If staatement is complete, just print "Reviewed"
    if (statement.workflowComplete) {
      return {
        name: '',
        printReviewedBy: false,
      };
    }
    // If user hasn't the right to validate current step AND he already has validated a previous step,
    // Show the previous validated step name
    if (isReviewed && haveValidatedStep) {
      const indexOfLastValidatedStep = last(
        // map into [index, right] to filter the step current user had the right to validate, then get its index
        // to get the step name from the workflow steps
        Object.entries(canValidSteps).filter(([, right]) => right !== WorkflowStatementStateAction.UNAUTHORIZED),
      )?.[0];

      if (indexOfLastValidatedStep) {
        return {
          name: formatStepName(
            steps[parseInt(indexOfLastValidatedStep, 10)]?.stepName || steps[stepNumber]?.stepName || '',
          ),
          printReviewedBy: true,
        };
      }
    }
    // Otherwise, toggle is disabled and current step name is printed
    return {
      name: formatStepName(steps[stepNumber]?.stepName || ''),
      printReviewedBy: true,
    };
  }, [haveValidatedStep, canValidSteps, steps, statement, isReviewed, stepNumber, formatStepName]);

  const handleTooltipClose = useCallback(() => setTooltipOpened(false), [setTooltipOpened]);
  const handleTooltipOpen = useCallback(() => setTooltipOpened(true), [setTooltipOpened]);

  const handleClickNextStep = useCallback(
    (checked: boolean) => onNextStep?.(canValidThisStep, checked),
    [canValidThisStep, onNextStep],
  );

  const isShowProgressBar = steps.length > 1 || additionalOptions?.forceShowProgressBar;

  if (statementHeaderView) {
    return (
      <StatementWorkflowPresentationHeaderView
        canBackStep={canBackStep}
        canResetSteps={canResetSteps}
        canToggleStep={canToggleStep}
        handleClickNextStep={handleClickNextStep}
        isReviewed={isReviewed}
        isShowProgressBar={isShowProgressBar}
        isTooltipOpen={tooltipOpened}
        setTooltipOpened={setTooltipOpened}
        stepCount={workflow.steps.length}
        stepNumber={stepNumber}
        steps={steps}
        stepToPrint={stepToPrint}
        onPreviousStep={onPreviousStep}
        onReset={onReset}
      />
    );
  }

  return (
    <Fragment>
      {isShowProgressBar ? (
        <div className={classes.root}>
          <Tooltip
            disableInteractive={!tooltipInteractive}
            open={tooltipOpened}
            title={
              <Fragment>
                <div>
                  <WorkflowStepper
                    compact={stepNumber === 0}
                    usersMap={usersMap}
                    workflowState={steps}
                  />
                </div>
                {canBackStep || canResetSteps ? (
                  <Group
                    className={stepNumber > 0 ? classes.actions : undefined}
                    justify="space-between"
                    css={css`
                      text-align: right;
                    `}
                  >
                    {canBackStep && onPreviousStep ? (
                      <Button
                        icon={<IconCaretLeft />}
                        variant={Button.Variant.LIGHT}
                        onClick={onPreviousStep}
                      >
                        <FormattedMessage defaultMessage="Undo last step" />
                      </Button>
                    ) : null}
                    {canResetSteps && onReset ? (
                      <Button
                        icon={<IconReload />}
                        variant={Button.Variant.LIGHT}
                        onClick={onReset}
                      >
                        <FormattedMessage defaultMessage="Reset the workflow" />
                      </Button>
                    ) : null}
                  </Group>
                ) : null}
              </Fragment>
            }
            onClose={handleTooltipClose}
            onOpen={handleTooltipOpen}
          >
            <WorkflowProgressBar
              currentStep={stepNumber}
              totalSteps={workflow.steps.length}
            />
          </Tooltip>
        </div>
      ) : null}
    </Fragment>
  );
});
