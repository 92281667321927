import { type FormikErrors, type FormikValues, useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';

export type UseFormikAutosaveOptions<TValues extends FormikValues = FormikValues> = {
  /** Called with the current values. */
  onSave: (values: TValues, errors: FormikErrors<TValues>) => void;
  /** Time to wait when the values change before calling onSave. */
  timeoutMs?: number;
};

export const useFormikAutosave = <TValues extends FormikValues = FormikValues>({
  onSave,
  timeoutMs = 1000,
}: UseFormikAutosaveOptions<TValues>) => {
  // Keep a ref on the onSave function, so it does not reset the timeout if the function reference is unstable.
  const onSaveRef = useRef(onSave);
  onSaveRef.current = onSave;

  // Subscribe to values change.
  const { values, errors, initialValues } = useFormikContext<TValues>();

  // When the values change, call the onSave function after timeoutMs.
  // Reset the timeout if values change again before timeoutMs expires.
  useEffect(() => {
    if (!isEqual(values, initialValues)) {
      const timeoutHandle = setTimeout(() => onSaveRef.current(values, errors), timeoutMs);
      return () => clearTimeout(timeoutHandle);
    }
    return undefined;
  }, [initialValues, values, errors, timeoutMs]);
};
