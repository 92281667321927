import { createSelector } from 'reselect';

import { type RootState } from '../types';

export const selectLastStatementsFetchParams = (state: RootState) => state.statements.lastparams;

export const selectStatements = (state: RootState) => state.statements.map;

export const selectUserStatements = (state: RootState) => state.statements.userStatements;

export const selectCurrentStatement = (state: RootState) => state.statements.currentStatement;

export const selectCurrentForecastedStatement = (state: RootState) => state.statements.currentForecastedStatement;

export const selectCurrentPeriod = (state: RootState) => state.statements.currentPeriod;

export const selectStatementsIsLoading = (state: RootState) => !!state.statements.isLoading;

export const selectUserStatementsSorted = createSelector([selectUserStatements], (statementsMap) =>
  Object.values(statementsMap).sort((a, b) => (a.period!.startDate >= b.period!.startDate ? 1 : -1)),
);
