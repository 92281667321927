import { css } from '@emotion/react';
import { memo, type ReactNode, type MouseEvent } from 'react';

import { type FormatsEnum, type PropertyRef } from '@amalia/data-capture/fields/types';
import { Popover, type PopoverProps } from '@amalia/design-system/components';
import { type StatementThread } from '@amalia/payout-collaboration/comments/types';

import { OverwriteTooltipContent } from './overwrite-tooltip-content/OverwriteTooltipContent';

export type OverwriteTooltipProps = Omit<PopoverProps, 'children' | 'content' | 'shouldTriggerOnHover'> & {
  readonly isReadOnly: boolean;
  readonly author: string;
  readonly date: string;
  /** FIXME: any are you okay? */
  readonly oldValue: any;
  readonly newValue: any;
  readonly newValueRaw?: any;
  readonly valueFormat?: FormatsEnum;
  readonly valueRef?: PropertyRef;
  readonly handleOnClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  readonly cellStatementThread?: StatementThread;
  readonly openStatementThread?: () => void;
  readonly children?: ReactNode;
};

export const OverwriteTooltip = memo(function OverwriteTooltip({
  isReadOnly,
  author,
  date,
  newValue,
  newValueRaw,
  oldValue,
  valueFormat,
  valueRef,
  handleOnClick,
  children,
  cellStatementThread,
  openStatementThread,
  ...restProps
}: OverwriteTooltipProps) {
  return (
    <Popover
      {...restProps}
      shouldTriggerOnHover
      content={
        <OverwriteTooltipContent
          author={author}
          cellStatementThread={cellStatementThread}
          date={date}
          handleOnClick={handleOnClick}
          isReadOnly={isReadOnly}
          newValue={newValue}
          newValueRaw={newValueRaw}
          oldValue={oldValue}
          openStatementThread={openStatementThread}
          valueFormat={valueFormat}
          valueRef={valueRef}
        />
      }
    >
      <div
        css={(theme) => css`
          color: ${theme.ds.colors.primary[400]};
        `}
      >
        {children}
      </div>
    </Popover>
  );
});
