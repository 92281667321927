import { noop } from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';

import { type CustomObject, type FiltersType } from '@amalia/core/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { useCustomObjectsPage, usePatchCustomObject } from '@amalia/data-capture/records/state';
import {
  OVERWRITE_CONTEXT,
  type OverwriteCreationRequestDetails,
  OverwriteModalContainer,
} from '@amalia/data-correction/overwrites/components';
import { OverwriteScopeEnum } from '@amalia/data-correction/overwrites/types';
import {
  DataGrid,
  type DataGridProps,
  getPageCount,
  SortDirection,
  useDataGridStateInLocalStorage,
  useSnackbars,
} from '@amalia/design-system/components';
import { useShallowObjectMemo } from '@amalia/ext/react/hooks';
import { useUrlState } from '@amalia/ext/react-router-dom';
import { useCurrentUser } from '@amalia/kernel/auth/state';
import { CustomTableContext, type CustomTableContextInterface } from '@amalia/lib-ui';

import CustomTableUtils from './CustomTable.utils';
import { useCustomTableColumns } from './useCustomTableColumns';

export type CustomTableProps = {
  readonly objectDefinition: CustomObjectDefinition;
  readonly filters: FiltersType[] | null;
  // In option show only some fields
  readonly fieldsToDisplay?: string[];
  readonly isSyncRunning?: boolean;
  readonly actions?: DataGridProps['actions'];
};

export const CustomTable = memo(function CustomTable({
  objectDefinition,
  filters,
  fieldsToDisplay,
  isSyncRunning,
  actions,
}: CustomTableProps) {
  const { snackError } = useSnackbars();
  const { data: currentUser } = useCurrentUser();
  const [overwriteModalInfo, setOverwriteModalInfo] = useState<OverwriteCreationRequestDetails>(null);
  const [searchInQs] = useUrlState('search', '');

  const {
    page,
    setPage,
    pageSize,
    setPageSize,
    columnVisibility,
    setColumnVisibility,
    columnSorting,
    setColumnSorting,
    columnPinning,
    setColumnPinning,
    searchText,
    setSearchText,
  } = useDataGridStateInLocalStorage({
    key: {
      pageSize: ['data-capture', 'records', 'pageSize'],
      default: ['data-capture', 'records', objectDefinition.id],
    },
    fieldsToPersist: ['pageSize', 'columnVisibility', 'columnSorting', 'columnPinning'],
    initialState: {
      searchText: searchInQs,
    },
  });

  const { data: customObjectsPage, isLoading: isCustomObjectsLoading } = useCustomObjectsPage({
    objectDefinitionName: objectDefinition.machineName,
    pagination: {
      page: page + 1,
      search: searchText,
      limit: pageSize,
      sort: columnSorting?.[0]?.id,
      desc: columnSorting?.[0]?.direction === SortDirection.DESC,
    },
    filters,
    overwrite: true,
    isSyncRunning,
  });

  const { mutateAsync: onPatch } = usePatchCustomObject(objectDefinition.machineName);

  const onCommitChanges = useCallback(
    async ({ changed }: any) => {
      if (changed && objectDefinition?.properties) {
        const overwriteToCreate = CustomTableUtils.onCommitChanges(
          objectDefinition,
          customObjectsPage,
          { changed },
          snackError,
        );
        if (overwriteToCreate) {
          await onPatch({
            definitionMachineName: objectDefinition.machineName,
            objectExternalId: overwriteToCreate.objectId,
            patch: {
              field: overwriteToCreate.field,
              overwriteValue: overwriteToCreate.overwriteValue,
              scope: OverwriteScopeEnum.GLOBAL,
            },
          }).catch(noop);
        }
      }
    },
    [onPatch, objectDefinition, customObjectsPage, snackError],
  );

  const handleCloseModal = useCallback(() => {
    setOverwriteModalInfo(null);
  }, []);

  useEffect(() => {
    if (!isCustomObjectsLoading && page > customObjectsPage?.totalItems / pageSize) {
      setPage(0);
    }
  }, [customObjectsPage, page, pageSize, isCustomObjectsLoading, setPage]);

  const columns = useCustomTableColumns(objectDefinition, fieldsToDisplay);

  const contextContent: CustomTableContextInterface = useShallowObjectMemo({
    objectDefinition,
    customObjectListDetails: customObjectsPage,
    setOverwriteModalInfo,
  });

  /**
   * The unique identifier of each row can be a compound of multiple properties.
   * I'm assuming that the fields used for the compound key are always stringifiable.
   */
  const rowKey = useCallback(
    (row: CustomObject) => objectDefinition.externalIds.map((field) => row.content[field]).join('_'),
    [objectDefinition.externalIds],
  );

  return (
    <CustomTableContext.Provider value={contextContent}>
      <DataGrid
        actions={actions}
        columns={columns}
        data={customObjectsPage?.items || []}
        isLoading={isCustomObjectsLoading || !objectDefinition || !customObjectsPage?.items}
        rowKey={rowKey}
        totalItems={customObjectsPage?.totalItems}
        columnPinning={
          <DataGrid.ColumnPinning
            isActive={columnPinning}
            onChange={setColumnPinning}
          />
        }
        columnSorting={
          <DataGrid.ColumnSorting.Single
            columnSorting={columnSorting}
            onChangeColumnSorting={setColumnSorting}
          />
        }
        columnVisibility={
          <DataGrid.ColumnVisibility
            columnVisibility={columnVisibility}
            onChangeColumnVisibility={setColumnVisibility}
          />
        }
        pageSize={
          <DataGrid.PageSize
            value={pageSize}
            onChange={setPageSize}
          />
        }
        pagination={
          Number.isFinite(customObjectsPage?.totalItems) ? (
            <DataGrid.Pagination
              page={page}
              pageCount={getPageCount(customObjectsPage?.totalItems, pageSize)}
              onChangePage={setPage}
            />
          ) : undefined
        }
        search={
          <DataGrid.Search
            value={searchText}
            onChange={setSearchText}
          />
        }
      />

      {!!overwriteModalInfo && (
        <OverwriteModalContainer
          isOpen
          currentObjectDetails={overwriteModalInfo}
          currentUser={currentUser}
          handleClose={handleCloseModal}
          handleSubmit={onCommitChanges}
          overwriteContext={OVERWRITE_CONTEXT.DATA}
        />
      )}
    </CustomTableContext.Provider>
  );
});
