import { css } from '@emotion/react';
import { Fragment, memo, useState } from 'react';
import { useIntl } from 'react-intl';

import { Skeleton } from '@amalia/design-system/components';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { type PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';
import {
  CustomReportsPresetsEnum,
  type PayoutAndPerformanceChartStatistics,
} from '@amalia/reporting/custom-reports/shared';

import { DashboardWidget } from '../DashboardWidget/DashboardWidget';
import { type PayoutMode } from '../types/payoutMode';
import { WidgetTitle } from '../WidgetTitle/WidgetTitle';

import { PayoutAndPerformanceChartAlert } from './alert/PayoutAndPerformanceChartAlert';
import { PayoutAndPerformanceChart, type PayoutAndPerformanceChartProps } from './chart/PayoutAndPerformanceChart';
import { type ChartSeries } from './chartSeries';
import { Legend as CustomLegend } from './legend/Legend';
import { PayoutModeButton } from './payout-mode-button/PayoutModeButton';
import { SeriesSelector } from './series-selector/SeriesSelector';
import { useChartSeries } from './useChartSeries';

export type PayoutAndPerformanceChartWidgetProps = {
  readonly isLoading: boolean;
  readonly isErrorLoading: boolean;
  readonly currency: CurrencySymbolsEnum;
  readonly statistics: PayoutAndPerformanceChartStatistics;
  readonly payoutMode: PayoutMode;
  readonly setPayoutMode: (payoutMode: PayoutMode) => void;
  readonly frequency?: PeriodFrequencyEnum;
  readonly onClickBar?: PayoutAndPerformanceChartProps['onClickBar'];
  readonly isRecordActiveFn?: PayoutAndPerformanceChartProps['isRecordActiveFn'];
};

/**
 * Payout and performance chart: displays payout as bar chart and kpis as line chart.
 * You can choose between 2 payout modes: last 12 months or year to date.
 */
export const PayoutAndPerformanceChartWidget = memo(function PayoutAndPerformanceChartWidget({
  statistics,
  currency,
  isLoading,
  isErrorLoading,
  payoutMode,
  setPayoutMode,
  frequency,
  onClickBar,
  isRecordActiveFn,
}: PayoutAndPerformanceChartWidgetProps) {
  const { formatMessage } = useIntl();

  const [hiddenSeries, setHiddenSeries] = useState<ChartSeries['name'][]>([]);

  const { seriesMetadata } = useChartSeries(statistics);

  return (
    <DashboardWidget>
      <WidgetTitle
        customReportId={CustomReportsPresetsEnum.PRESET_EARNED_OVER_TIME}
        title={formatMessage({ defaultMessage: 'Commission & performance evolution' })}
        actions={
          <Fragment>
            <PayoutModeButton
              payoutMode={payoutMode}
              setPayoutMode={setPayoutMode}
            />
            <SeriesSelector
              hiddenSeries={hiddenSeries}
              seriesMetadata={seriesMetadata}
              setHiddenSeries={setHiddenSeries}
            />
          </Fragment>
        }
      />

      {isLoading ? (
        <Skeleton
          visible
          css={css`
            width: 100%;
            height: 300px;
          `}
        />
      ) : (
        <Fragment>
          <PayoutAndPerformanceChartAlert
            isKpiAvailable
            isPlanSelected
            isError={isErrorLoading}
            recordsLength={statistics?.records?.length ?? 0}
          />

          <div
            css={css`
              height: 250px;
            `}
          >
            <PayoutAndPerformanceChart
              currency={currency}
              frequency={frequency}
              hiddenSeries={hiddenSeries}
              isRecordActiveFn={isRecordActiveFn}
              statistics={statistics}
              onClickBar={onClickBar}
            />
          </div>

          <CustomLegend
            hiddenSeries={hiddenSeries}
            seriesRecords={seriesMetadata}
          />
        </Fragment>
      )}
    </DashboardWidget>
  );
});
