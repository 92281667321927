import { css, type Theme } from '@emotion/react';
import '@amalia/design-system/components';

export const container = css`
  min-width: 400px;
  max-width: 576px;
  max-height: unset;
`;

export const valueContainer = (theme: Theme) => css`
  padding: 16px 24px;

  border: 1px solid ${theme.ds.colors.gray[100]};
  border-radius: ${theme.ds.borderRadiuses.squared};
`;

export const value = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  gap: 8px;
`;

export const footer = css`
  width: 100%;
`;
