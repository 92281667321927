import { Fragment, memo } from 'react';

import { type ChangelogEntry } from '@amalia/amalia-meta/changelog/shared';

export type NotionTextContentProps = {
  readonly notionRichTextContent: ChangelogEntry['content'];
};

export const NotionTextContent = memo(function NotionTextContent({ notionRichTextContent }: NotionTextContentProps) {
  return (
    <Fragment>
      {notionRichTextContent.map((content, index) => (
        <span
          key={index} // eslint-disable-line react/no-array-index-key -- Content is static.
          style={{
            // Converts \n to line breaks.
            whiteSpace: 'pre-wrap',
            // Bold.
            fontWeight: content.annotations.bold ? 'bold' : undefined,
          }}
        >
          {content.plain_text}
        </span>
      ))}
    </Fragment>
  );
});
