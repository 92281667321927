import { memo } from 'react';
import { useIntl } from 'react-intl';

import { RadioButtonGroup } from '@amalia/design-system/components';
import { RelativePeriodKeyword } from '@amalia/payout-definition/periods/types';

import { type PayoutMode } from '../../types/payoutMode';

type PayoutModeButtonProps = {
  readonly payoutMode: PayoutMode;
  readonly setPayoutMode: (payoutMode: PayoutMode) => void;
};

export const PayoutModeButton = memo(function PayoutModeButton({ payoutMode, setPayoutMode }: PayoutModeButtonProps) {
  const { formatMessage } = useIntl();
  return (
    <RadioButtonGroup
      name="switch-payout-mode"
      value={payoutMode}
      options={[
        {
          value: RelativePeriodKeyword.LAST_12_MONTHS,
          label: formatMessage({ defaultMessage: 'Last 12 months' }),
        },
        {
          value: RelativePeriodKeyword.CURRENT_YEAR,
          label: formatMessage({ defaultMessage: 'Current year' }),
        },
      ]}
      onChange={(newValue) => setPayoutMode(newValue)}
    />
  );
});
