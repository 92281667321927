import { IconChevronRight } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type ConnectorDefinitionWithLogos } from '@amalia/data-capture/connectors/assets';
import { DataConnectorTypes, type ConnectorResponse } from '@amalia/data-capture/connectors/types';
import { Badge, ButtonLink, Card, Typography } from '@amalia/design-system/components';

import { dataConnectorCategoriesMessages } from '../../intl/messages';

import * as styles from './ConnectorCard.styles';
import { connectorCardTestIds } from './ConnectorCard.testIds';

export type ConnectorCardProps = {
  /** Connector definition (name, logo, etc.). */
  readonly connectorDefinition: ConnectorDefinitionWithLogos;
  /** Connector connection. */
  readonly connector?: ConnectorResponse;
};

export const ConnectorCard = memo(function ConnectorCard({ connectorDefinition, connector }: ConnectorCardProps) {
  return (
    <Card>
      <div css={styles.connectorCardBody}>
        <connectorDefinition.CompactLogo css={styles.logo} />

        <div css={styles.textContainer}>
          <Typography
            as="div"
            css={styles.connectorName}
            variant={Typography.Variant.BODY_LARGE_BOLD}
          >
            {connectorDefinition.name}

            {/* The salesforce second account is a dirty hack today so I'm not spending too much time on it. */}
            {connectorDefinition.connectorType === DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT && (
              <Badge
                size={Badge.Size.SMALL}
                variant={Badge.Hue.BLUE}
              >
                <FormattedMessage defaultMessage="Second account" />
              </Badge>
            )}
            {connectorDefinition.connectorType === DataConnectorTypes.SALESFORCE_THIRD_ACCOUNT && (
              <Badge
                size={Badge.Size.SMALL}
                variant={Badge.Hue.BLUE}
              >
                <FormattedMessage defaultMessage="Third account" />
              </Badge>
            )}
          </Typography>

          <Typography
            as="div"
            css={styles.connectorCategory}
            variant={Typography.Variant.BODY_SMALL_REGULAR}
          >
            <FormattedMessage {...dataConnectorCategoriesMessages[connectorDefinition.category]} />
          </Typography>
        </div>
      </div>

      <Card.Footer>
        {connector && connector.isLoggedIn ? (
          <Badge
            size={Badge.Size.SMALL}
            variant={Badge.Status.SUCCESS}
          >
            <FormattedMessage defaultMessage="Connected" />
          </Badge>
        ) : (
          <Badge
            size={Badge.Size.SMALL}
            variant={Badge.Status.MISSING}
          >
            <FormattedMessage defaultMessage="Not connected" />
          </Badge>
        )}

        <Card.Actions>
          <ButtonLink
            data-testid={connectorCardTestIds.configureButton(connectorDefinition.connectorType)}
            icon={<IconChevronRight />}
            iconPosition={ButtonLink.IconPosition.RIGHT}
            variant={ButtonLink.Variant.PRIMARY_TEXT}
            to={
              connector?.isLoggedIn ? connectorDefinition.linkAlreadyConnected : connectorDefinition.linkFirstConnection
            }
          >
            <FormattedMessage defaultMessage="Configure" />
          </ButtonLink>
        </Card.Actions>
      </Card.Footer>
    </Card>
  );
});
