import { type Theme, css } from '@emotion/react';

export const pageHeaderContainer = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

// TODO: Remove this after the Layout is in his own lib
const LEFT_BAR_WIDTH = 156;

export const pageHeaderContainerSticky = (theme: Theme) => css`
  position: fixed;
  top: 0;
  left: ${LEFT_BAR_WIDTH}px;
  z-index: 1299;
  background-color: ${theme.ds.colors.gray[0]};
  padding: 8px 24px;
  right: 0;

  box-shadow: ${theme.ds.shadows.soft};

  animation: smooth-appear 0.3s ease-in-out;
  @keyframes smooth-appear {
    from {
      top: -100px;
    }
    to {
      top: 0;
    }
  }
`;

export const alertContainer = css`
  margin-top: 12px;

  &:empty {
    display: none;
  }
`;
