import { select, scaleBand, range, axisBottom, max, scaleLinear, axisLeft, axisRight, type NumberValue } from 'd3';
import { useRef, useEffect, memo } from 'react';

import { buildChartClasses, defineCommonSVGUtils, getAxisClasses, useChartStyles } from './chart.styles';
import { type CardinalChartOptionsProps, type CardinalChartProps, type CardinalGraphAxis } from './chart.types';

export interface CommonBarOptionsProps extends CardinalChartOptionsProps {
  // Padding between bars, in percent (0.5 => 25% padding each side)
  paddingBetweenBars?: number;
  // 0 for pure rectangle, 1 for full rounded, between 0 and 1 to adjust
  radiusCoefficient?: number;
  // True if we want to show labels
  // Warning: labels are postioned absolutely (so possibly overflowing from graph viewbox)
  showDataLabels?: boolean;
  // Override bar width
  barWidth?: number;
}

interface BarOptionsProps extends CommonBarOptionsProps {}

interface BarProps extends CardinalChartProps {
  readonly options?: BarOptionsProps;
}

export const Bar = memo(function Bar({ data, height, width, margin, styles, options, modifiers }: BarProps) {
  const classes = useChartStyles();
  const d3Container = useRef(null);

  useEffect(() => {
    if (data && d3Container.current) {
      const svg = select(d3Container.current);
      svg.selectAll('*').remove();

      defineCommonSVGUtils(svg);

      // X Axis
      // X is a scale band
      const x = scaleBand()
        // Which goes from 0 to the number of data rows
        .domain(range(data.length).map((i) => i.toString()))
        // Which corresponds in pixels to the left (margin) to the right (width - margin)
        .range([margin, width - margin])
        // Add a bit of padding between each bar
        .padding(options?.paddingBetweenBars || 0.1);

      x.domain(data.map((d) => d.x?.toString()));

      // Building the X axis
      const xAxis = (g: CardinalGraphAxis) =>
        g
          // Translate it to the bottom (height - margin)
          .attr('transform', `translate(0, ${height - margin})`)
          // Add common class
          .attr('class', getAxisClasses(classes, 'dimensional', styles))
          // And building it
          .call(axisBottom(x));

      // Y Axis
      // Compute the max of the values
      const yMax: NumberValue = max(data, (d) => d.value) || 0;

      // Y is a linear scale
      const y = scaleLinear()
        // which goes from 0 to the max of the values
        .domain([0, yMax])
        .nice()
        // which corresponds in pixels from the bottom (height - margin) to the top (margin)
        .range([height - margin, margin]);

      const yAxisFn = options?.isRightVerticalAxis ? axisRight : axisLeft;

      // Building the Y Axis
      const yAxis = (g: CardinalGraphAxis) =>
        g
          // Translate it to the left (margin) or right (width - margin) accordingly
          .attr('transform', `translate(${options?.isRightVerticalAxis ? width - margin : margin}, 0)`)
          // Add common class
          .attr('class', getAxisClasses(classes, 'main', styles))
          // Calling the right function and adjusting the ticks for better readability
          .call(yAxisFn(y).ticks(height / 80));

      // Building SVG
      // Adding the axles
      if (styles?.rotateXAxisLabels) {
        svg
          .append('g')
          .call(xAxis)
          .selectAll('text')
          .style('text-anchor', 'end')
          .attr('dx', '-.8em')
          .attr('dy', '.15em')
          .attr('transform', 'rotate(-35)');
      } else {
        svg.append('g').call(xAxis);
      }

      svg.append('g').call(yAxis);

      const barRectWidth = options?.barWidth || x.bandwidth();

      // Add a group for the chart
      svg
        .append('g')
        .attr('class', buildChartClasses(classes, styles, true))
        // Select all rectangle
        .selectAll('rect')
        // Match corresponding data
        .data(data)
        // For each rectangle
        .join('rect')
        // X is the position computed from the data index (i)
        .attr('x', (d) => x(d.x.toString()) + (x.bandwidth() / 2 - barRectWidth / 2))
        // Y is the position according to the data value
        .attr('y', (d) => y(d.value))
        // The height is the difference between the bottom and the value
        .attr('height', (d) => (y(0) || height - margin) - (y(d.value) || 0))
        // Width is the accorded space from the bandwidth
        .attr('width', barRectWidth)
        // Radius
        .attr('rx', (options?.radiusCoefficient || 0) * barRectWidth * 0.5)
        .attr('ry', (options?.radiusCoefficient || 0) * barRectWidth * 0.5);

      // Add dataLabels if enabled
      if (options?.showDataLabels) {
        svg
          .append('g')
          .selectAll('rect')
          // For each rect, map data and create a text node
          .data(data)
          .enter()
          .append('text')
          // Anchor it in the middle to center it
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          // For X, take the x of the bar and center it in the middle of it
          .attr('x', (d) => x(d.x.toString()) + x.bandwidth() * 0.5)
          // For Y, take the value and remove some numbers to align it above
          .attr('y', (d) => y(d.value) - 15)
          // Take the value as the label
          .text((d) => (d.valueLabel || d.value).toString());
      }

      // Add additional grid lines
      if (options?.showAdditionalGridLines) {
        svg
          .append('g')
          .lower()
          .attr('class', classes.chartGrid)
          .attr('transform', `translate(${margin}, 0)`)
          .call(
            axisLeft(y)
              .ticks(height / 80)
              .tickSize(-width + 2 * margin)
              .tickFormat(() => ''),
          );
      }

      (modifiers || []).forEach((m) => {
        m({
          svg,
          y,
          x,
          data,
          margin,
          styles,
          height,
          width,
          classes,
        });
      });
    }
  }, [data, d3Container, margin, height, width, classes, styles, options, modifiers]);

  return (
    <svg
      ref={d3Container}
      className="d3-component"
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
    />
  );
});
