import { useCallback } from 'react';

import { type AttributeValueForm } from '@amalia/amalia-lang/formula/form/types';
import {
  type FormulaKeywordDisplayDetails,
  useFormulaKeywordsDisplayDetails,
} from '@amalia/amalia-lang/formula/keywords/components';
import {
  type AttributeValueField,
  type AttributeValueKeyword,
  type AttributeValueProperty,
  type AttributeValueQuota,
  type AttributeValueRelationship,
  type AttributeValueVariable,
} from '@amalia/amalia-lang/formula/types';
import { TokenType, type Variable } from '@amalia/amalia-lang/tokens/types';
import { type TracingTypes } from '@amalia/core/types';
import { type Relationship } from '@amalia/payout-definition/plans/types';

import { useFormulaBuilderContext } from '../../components/formula-builder/FormulaBuilder.context';

type AttributeValueAttribute<TAttribute extends AttributeValueForm> = TAttribute extends AttributeValueKeyword
  ? FormulaKeywordDisplayDetails | undefined
  : TAttribute extends AttributeValueProperty
    ? TracingTypes.Property | undefined
    : TAttribute extends AttributeValueField
      ? Variable | undefined
      : TAttribute extends AttributeValueQuota
        ? Variable | undefined
        : TAttribute extends AttributeValueRelationship
          ? { relationship: Relationship; property: TracingTypes.Property } | undefined
          : TAttribute extends AttributeValueVariable
            ? Variable | undefined
            : undefined;

export type UseGetFormulaBuilderAttributeValue = <TAttribute extends AttributeValueForm>(
  attribute: TAttribute,
) => AttributeValueAttribute<TAttribute>;

/**
 * Return a method to get the name of an attribute, bound to the context.
 * @returns Callback.
 */
export function useGetFormulaBuilderAttribute(): UseGetFormulaBuilderAttributeValue {
  const { planObjects } = useFormulaBuilderContext();
  const formulaKeywordsDisplayDetails = useFormulaKeywordsDisplayDetails();

  /** For each type of AttributeValueForm, find the related attribute and display its name. Fallback to formula notation. */
  const getFormulaBuilderAttribute = useCallback(
    (attribute: AttributeValueForm): AttributeValueAttribute<AttributeValueForm> => {
      // Ignore empty field values.
      if (!('fieldType' in attribute)) {
        return undefined;
      }

      switch (attribute.fieldType) {
        case TokenType.LINK: {
          const relationship = (planObjects?.[TokenType.LINK] as Relationship[] | undefined)?.find(
            (relationship) =>
              relationship.fromDefinitionMachineName === attribute.objectMachineName &&
              relationship.name === attribute.relationshipMachineName,
          );

          const property =
            relationship &&
            (
              [
                ...(planObjects?.[TokenType.PROPERTY] || []),
                ...(planObjects?.[TokenType.VIRTUAL_PROPERTY] || []),
              ] as TracingTypes.Property[]
            ).find(
              (property) =>
                property.definition.machineName === relationship.toDefinitionMachineName &&
                property.machineName === attribute.propertyMachineName,
            );

          return relationship && property ? { relationship, property } : undefined;
        }

        case TokenType.VARIABLE: {
          return (planObjects?.[TokenType.VARIABLE] as Variable[] | undefined)?.find(
            (variable) => variable.machineName === attribute.machineName,
          );
        }

        case TokenType.PROPERTY:
        case TokenType.VIRTUAL_PROPERTY: {
          return (planObjects?.[attribute.fieldType] as TracingTypes.Property[] | undefined)?.find(
            (property) =>
              property.definition.machineName === attribute.objectMachineName &&
              property.machineName === attribute.propertyMachineName,
          );
        }

        case TokenType.FIELD: {
          return (planObjects?.[TokenType.FIELD] as Variable[] | undefined)?.find(
            (property) =>
              property.object?.machineName === attribute.objectMachineName &&
              property.machineName === attribute.propertyMachineName,
          );
        }

        case TokenType.QUOTA: {
          return (planObjects?.[TokenType.QUOTA] as Variable[] | undefined)?.find(
            (property) => property.type === attribute.quotaType && property.machineName === attribute.machineName,
          );
        }

        case TokenType.KEYWORD:
          return formulaKeywordsDisplayDetails[attribute.keyword];

        default:
          return undefined;
      }
    },
    [planObjects, formulaKeywordsDisplayDetails],
  );

  return getFormulaBuilderAttribute as UseGetFormulaBuilderAttributeValue;
}
