import { createContext } from 'react';

import { type SelectOption } from '@amalia/design-system/components';

export interface SARouterContextInterface {
  selectedCompanies: string[];
  companies: SelectOption<string>[];
}

export const SARouterContext = createContext<SARouterContextInterface>({} as SARouterContextInterface);
