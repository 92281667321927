import { http } from '@amalia/core/http/client';
import { type OmolioMessage } from '@amalia/omolio/types';

export abstract class OmolioApiClient {
  public static readonly API_ENDPOINT = '/omolio';

  public static async prompt(omolioQuestion: OmolioMessage): Promise<string> {
    const { data } = await http.post<string>(`${OmolioApiClient.API_ENDPOINT}/prompt`, omolioQuestion);
    return data;
  }
}
