import { memo, useCallback, type MouseEventHandler } from 'react';

import { Dropdown } from '../../dropdown/Dropdown';
import { type DropdownItemButtonProps } from '../../dropdown/dropdown-item-button/DropdownItemButton';
import { useMenuDropdownContext } from '../MenuDropdown.context';

export type MenuDropdownItemProps = DropdownItemButtonProps;

export const MenuDropdownItem = memo(function MenuDropdownItem({
  onClick = undefined,
  ...props
}: MenuDropdownItemProps) {
  const { onClosePopover } = useMenuDropdownContext();

  const onClickProxy: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      onClick?.(event);
      onClosePopover();
    },
    [onClick, onClosePopover],
  );

  return (
    <Dropdown.ItemButton
      {...props}
      onClick={onClickProxy}
    />
  );
});
