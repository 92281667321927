import { useEffect, useState } from 'react';

export const useDebouncedValue = <TValue>(value: TValue, delayMs: number = 300): TValue => {
  const [debouncedValue, setDebouncedValue] = useState<TValue>(value);

  useEffect(() => {
    const timeoutHandle = setTimeout(() => {
      setDebouncedValue(value);
    }, delayMs);

    return () => {
      clearTimeout(timeoutHandle);
    };
  }, [value, delayMs]);

  return debouncedValue;
};
