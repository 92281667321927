import { type Theme, css } from '@emotion/react';

export const HAS_TITLE_CLASSNAME = 'hasTitle';
export const IS_INTERACTIVE_CLASSNAME = 'isInteractive';

export const tooltipContainer = css`
  z-index: 10000;
`;

export const tooltip = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  gap: 2px;

  align-items: flex-start;

  background-color: ${theme.ds.colors.gray[800]};
  color: ${theme.ds.colors.gray[0]};

  border-radius: ${theme.ds.borderRadiuses.squared};
  box-shadow: ${theme.ds.shadows.soft};

  padding: 4px 8px;

  width: max-content;

  &:not(.${IS_INTERACTIVE_CLASSNAME}) {
    // When not using safePolygon, we need to add a pointer-events: none to the tooltip otherwise some events (mouseleave) are not fired.
    pointer-events: none;
  }

  &.${HAS_TITLE_CLASSNAME} {
    padding: 6px 12px;
  }
`;

export const arrow = (theme: Theme) => css`
  fill: ${theme.ds.colors.gray[800]};
`;

export const title = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[400]};
`;
