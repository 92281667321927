import { useFormikContext } from 'formik';
import moment from 'moment';
import { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { DatePicker, Input } from '@amalia/lib-ui';
import { type UserContract } from '@amalia/tenants/users/types';

import { OverwriteFormField, type OverwriteFormValuesShape } from '../../overwrite.types';

import { messages } from './selectors.messages';
import { type CommonOverwriteSelectorProps } from './selectors.types';

export type DateOverwriteSelectorProps = CommonOverwriteSelectorProps & {
  readonly currentUser: UserContract;
};

export const DateOverwriteSelector = memo(function DateOverwriteSelector({ currentUser }: DateOverwriteSelectorProps) {
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useFormikContext<OverwriteFormValuesShape>();

  const handleChange = useCallback(
    (date: Date) => setFieldValue(OverwriteFormField.NEW_VALUE, moment(date).format('YYYY-MM-DD')),
    [setFieldValue],
  );

  return (
    <DatePicker
      autoFocus
      ariaLabelledBy={OverwriteFormField.NEW_VALUE}
      customInput={<Input />}
      id={OverwriteFormField.NEW_VALUE}
      language={currentUser.language}
      name={OverwriteFormField.NEW_VALUE}
      placeholderText={formatMessage(messages[FormatsEnum.date])}
      popperProps={{ strategy: 'fixed' }}
      value={values.newValue}
      onChange={handleChange}
    />
  );
});
