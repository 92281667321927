import { Fragment, memo } from 'react';

import { type ColumnOrderAndVisibilityProps } from '../column-order-and-visibility/ColumnOrderAndVisibility';

export type ColumnOrderProps = Required<Pick<ColumnOrderAndVisibilityProps, 'columnOrder' | 'onChangeColumnOrder'>>;

export const ColumnOrder = memo(function ColumnOrder(_: ColumnOrderProps) {
  /**
   * For consistency in the DataGrid interface, column ordering uses the same interface.
   * It is passed to ColumnOrderAndVisibility in DataGrid.
   */
  return <Fragment>{null}</Fragment>;
});
