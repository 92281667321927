import clsx from 'clsx';
import { memo, useCallback, type ReactNode, type ComponentPropsWithoutRef } from 'react';

import { TypographyVariant } from '@amalia/design-system/meta';
import { type UseResizeObserverOptions, useResizeObserver } from '@amalia/ext/react/hooks';
import { type MergeAll } from '@amalia/ext/typescript';

import { Typography } from '../../../../../general/typography/Typography';
import { SelectSize } from '../../../Select.types';

import * as styles from './MultiValue.styles';

const SIZE_TYPOGRAPHY_MAPPING: Record<SelectSize, TypographyVariant> = {
  [SelectSize.SMALL]: TypographyVariant.BODY_SMALL_REGULAR,
  [SelectSize.MEDIUM]: TypographyVariant.BODY_BASE_REGULAR,
};

export type MultiValueProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Select control size. */
      size: SelectSize;
      /** Is the control disabled. */
      disabled?: boolean;
      /** Callback when the width of the container changes. */
      onChangeWidth?: (width: number) => void;
      /** Children. */
      children?: ReactNode;
    },
  ]
>;

export const MultiValue = memo(function MultiValue({
  size,
  disabled,
  children,
  onChangeWidth,
  ...props
}: MultiValueProps) {
  const setWidth: UseResizeObserverOptions<HTMLDivElement>['onResize'] = useCallback(
    ({ width }) => onChangeWidth?.(width),
    [onChangeWidth],
  );

  const ref = useResizeObserver({ onResize: setWidth });

  return (
    <div
      {...props}
      ref={ref}
      css={styles.multiValue}
      className={clsx(props.className, size, {
        [styles.IS_DISABLED_CLASSNAME]: disabled,
      })}
    >
      <Typography variant={SIZE_TYPOGRAPHY_MAPPING[size]}>{children}</Typography>
    </div>
  );
});
