import { isNil } from 'lodash';

/**
 * Sorts two values, but if one of them is null or undefined, it will be placed at the end of the list.
 *
 * @param a - First value to compare.
 * @param b - Second value to compare.
 * @param compare - Function to compare the values.
 * @returns -1 if a < b, 0 if a == b, 1 if a > b.
 */
const safeSort = <T = unknown>(
  a: T | null | undefined,
  b: T | null | undefined,
  compare: (a: T, b: T) => number,
): number => (isNil(a) && isNil(b) ? 0 : isNil(a) ? 1 : isNil(b) ? -1 : compare(a, b));

export type DateValue = Date | number | string;

export const sortDates = (a?: DateValue | null, b?: DateValue | null) =>
  safeSort(a, b, (aa, bb) => new Date(aa).getTime() - new Date(bb).getTime());

export const sortStrings = (a?: string | null, b?: string | null) => safeSort(a, b, (aa, bb) => aa.localeCompare(bb));

export const sortNumbers = (a?: number | null, b?: number | null) => safeSort(a, b, (aa, bb) => aa - bb);
