import { useFormikContext } from 'formik';
import { memo, useCallback } from 'react';

import { FormLayout } from '@amalia/design-system/components';
import {
  type ChartDisplaySettings,
  ChartType,
  type DashboardChartConfiguration,
} from '@amalia/reporting/dashboards-v2/types';

import { KPICardChartFilters } from './KPICardChartFilters';
import { SimpleBarChartFilters } from './SimpleBarChartFilters';

export const DashboardChartConfigurationFiltersTab = memo(function DashboardChartConfigurationFiltersTab() {
  const { values, setFieldValue } = useFormikContext<DashboardChartConfiguration<ChartType>>();

  const handleChangeFilterStatus = useCallback(
    async (filterName: keyof ChartDisplaySettings<ChartType>['filters'], newChecked: boolean) => {
      await setFieldValue(`displaySettings.filters.${filterName}.isEnabled`, newChecked);
    },
    [setFieldValue],
  );

  return (
    <FormLayout size={FormLayout.Size.SMALL}>
      {(() => {
        switch (values.type) {
          case ChartType.KPI_CARD_CHART:
            return (
              <KPICardChartFilters
                filters={values.displaySettings.filters}
                onFilterChange={handleChangeFilterStatus}
              />
            );
          case ChartType.SIMPLE_BAR_CHART:
            return (
              <SimpleBarChartFilters
                filters={values.displaySettings.filters}
                onFilterChange={handleChangeFilterStatus}
              />
            );
          default:
            return null;
        }
      })()}
    </FormLayout>
  );
});
