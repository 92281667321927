export type BufferedFunction<TArgs extends unknown[], TReturnType = unknown> = (...args: TArgs) => Promise<TReturnType>;

/**
 * Buffer list of calls to a function to execute them all in one request.
 * From the caller point of view, it's transparent.
 *
 * @param exec - The method to call with all buffered calls.
 * @param findInAccResponses - The method to find the result of a call in the response of the exec method.
 * @param timeoutMs - The time to wait before executing the buffered calls.
 * @returns The buffered function.
 */
export const bufferCalls = <TArgs extends unknown[], TExecReturnType = unknown, TFetchReturnType = TExecReturnType[]>(
  exec: (args: TArgs[]) => Promise<TFetchReturnType>,
  findInAccResponses: (args: TArgs, result: TFetchReturnType) => TExecReturnType,
  timeoutMs: number = 100,
) => {
  /** Buffer of parameters of pending exec call ([[args,of,1st,call], [args,of,2nd,call]]). */
  let bufferedArgs: TArgs[] = [];
  /** Promise of the current exec call or pending exec call. If null, there is no call. */
  let bufferPromise: Promise<TFetchReturnType> | null = null;

  return async (...args: TArgs) => {
    /** Push current call args to the buffered args. */
    bufferedArgs.push(args);

    /** If there is no pending call, start the timeout. */
    if (!bufferPromise) {
      bufferPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          // Get a reference to the current buffered args because it's reset after.
          const currentBufferedArgsRef = bufferedArgs;

          // Reset buffer so next call will launch a new request.
          bufferPromise = null;
          bufferedArgs = [];

          // Execute the buffered calls with the list of buffered args.
          exec(currentBufferedArgsRef).then(resolve, reject);
        }, timeoutMs);
      });
    }

    /** Wait for the result and return the value from the exec accumulated responses. */
    const result = await bufferPromise;
    return findInAccResponses(args, result);
  };
};
