import { cloneDeep } from 'lodash';
import { type Dispatch, type SetStateAction, useCallback, useState } from 'react';

import { EMPTY_USER_FILTERS, type UserFilters } from './types';

export const useUsersTableFilters = ({
  setPage,
  selectedUserIds,
  setSelectedUserIds,
}: {
  setPage: Dispatch<SetStateAction<number>>;
  selectedUserIds: string[];
  setSelectedUserIds: Dispatch<SetStateAction<string[]>>;
}) => {
  const [filters, setFilters] = useState<UserFilters>(EMPTY_USER_FILTERS);
  const [bufferedFilters, setBufferedFilters] = useState<Partial<UserFilters> | null>(null);
  const shouldBufferFilters = selectedUserIds.length > 0;

  const handleFiltersChange = useCallback(
    (newFilters: Partial<UserFilters>) => {
      setFilters((prev) => ({
        ...prev,
        ...newFilters,
      }));

      setPage(0);
    },
    [setPage],
  );

  const onChangeFilters = useCallback(
    (newFilters: Partial<UserFilters>) => {
      if (shouldBufferFilters) {
        setBufferedFilters(newFilters);
        return;
      }

      handleFiltersChange(newFilters);
    },
    [shouldBufferFilters, handleFiltersChange],
  );

  const onCancelChangeFilters = useCallback(() => {
    // FIXME(gautier): this is a workaround to force reset the controlled state of Filters.FilterSelect
    // We thought it would be better to add confirmationModal on the Filters component itself
    setFilters((prev) => cloneDeep(prev));
    // Drop the buffered value
    setBufferedFilters(null);
  }, []);

  const onConfirmChangeFilters = useCallback(() => {
    if (!bufferedFilters) {
      return;
    }

    handleFiltersChange(bufferedFilters);
    setSelectedUserIds([]);
    setBufferedFilters(null);
  }, [bufferedFilters, handleFiltersChange, setSelectedUserIds]);

  return {
    filters,
    setFilters: onChangeFilters,
    isConfirmFiltersModalOpen: !!bufferedFilters,
    onCancelChangeFilters,
    onConfirmChangeFilters,
  };
};
