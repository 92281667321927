import { lazy, memo } from 'react';

import { Layout, PageContainer } from '@amalia/core/layout/components';
import { useFeatureFlag } from '@amalia/frontend/web-data-layers';
import { CompanyFeatureFlags } from '@amalia/tenants/companies/types';

const DashboardPage = lazy(() => import('./DashboardPage'));

export const DashboardView = memo(function DashboardView() {
  const { isFeatureEnabled: isDashboardV2FFEnabled } = useFeatureFlag(CompanyFeatureFlags.DASHBOARDS_V2);
  return (
    <Layout currentPage={!isDashboardV2FFEnabled ? 'dashboard' : 'dashboardsV2'}>
      <PageContainer showLoadingBar={false}>
        <DashboardPage />
      </PageContainer>
    </Layout>
  );
});
