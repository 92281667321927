import { useMutation } from '@tanstack/react-query';

import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import { type OmolioMessage } from '@amalia/omolio/types';

import { OmolioApiClient } from './api-client/omolio.api-client';

export const useOmolioPrompt = () => {
  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: ['omolio', 'prompt'],
    mutationFn: (omolioQuestion: OmolioMessage) => OmolioApiClient.prompt(omolioQuestion),
    onError: (error) => {
      snackError(toError(error).message);
    },
  });
};
