import { type Theme, css } from '@emotion/react';

export const statementDetailsContainer = (isStatementReviewed: boolean) => (theme: Theme) => css`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 16px;
  border-bottom: ${isStatementReviewed && `4px solid ${theme.ds.colors.primary[500]}`};
`;

export const histogramContainer = css`
  display: flex;
`;

export const alertContainer = css`
  &:empty {
    display: none;
  }
`;

export const summaryContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
`;

export const tracingText = (theme: Theme) => css`
  color: ${theme.ds.hues.blue[500]};
`;

export const alertsContainer = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  &:empty {
    display: none;
  }
`;
