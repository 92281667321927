import { http } from '@amalia/core/http/client';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';

const e = encodeURIComponent;

export class CustomObjectDefinitionsApiClient {
  public static async delete(objectDefinition: CustomObjectDefinition) {
    await http.delete(`/objects-definitions/${e(objectDefinition.id)}`);
  }

  public static async list(): Promise<CustomObjectDefinition[]> {
    const { data } = await http.get<CustomObjectDefinition[]>('/objects-definitions');
    return data;
  }

  public static async listVirtual(): Promise<CustomObjectDefinition[]> {
    const { data } = await http.get<CustomObjectDefinition[]>('/virtual-custom-object-definitions');
    return data;
  }
}
