import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Fragment, memo, useMemo, type ReactNode } from 'react';
import { Cell, Pie, PieChart } from 'recharts';

import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { Divider, Tooltip, Typography } from '@amalia/design-system/components';
import { ColorCategory, HueCategory, ShadowVariant, TypographyVariant } from '@amalia/design-system/meta';
import { isCurrencyValue } from '@amalia/kernel/monetary/types';
import { makeUniqueCustomReportFieldIdentifier } from '@amalia/reporting/custom-reports/shared';
import {
  ChartDisplayContext,
  type ChartType,
  type DashboardChartConfiguration,
  type DashboardChartResult,
} from '@amalia/reporting/dashboards-v2/types';

import { useFormatChartValue } from '../../common/hooks/useFormatChartValue';

const CHART_SIZE = 88;

const KPICardChartContainer = styled.div<{ chartDisplayContext: ChartDisplayContext }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ theme }) => theme.ds.borderRadiuses.squared};
  box-shadow: ${({ theme, chartDisplayContext }) =>
    chartDisplayContext === ChartDisplayContext.IN_CONFIGURATION ? theme.ds.shadows[ShadowVariant.HARD] : 'none'};
`;

const KPICardChartFiltersAndKPI = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const KPICardChartFilters = styled.div`
  padding: 6px 16px;
`;

const KPICardChartKPI = styled.div`
  padding: 0 32px;
  // To avoid glitch when the dounghnut is toogled
  min-height: ${CHART_SIZE}px;
  display: flex;
  align-items: center;
`;

const KPICardChartKPIAndDoughnutSeparator = styled(Divider.Vertical)<{
  chartDisplayContext: ChartDisplayContext;
}>`
  color: ${({ theme }) => theme.ds.colors[ColorCategory.GRAY][100]};
  // To avoid the divider not taking the full height
  margin: ${({ chartDisplayContext }) =>
    chartDisplayContext === ChartDisplayContext.IN_CONFIGURATION ? '0' : '-16px 0'};
`;

const KPICardChartDoughnut = styled.div<{
  chartDisplayContext: ChartDisplayContext;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ chartDisplayContext }) =>
    chartDisplayContext === ChartDisplayContext.IN_CONFIGURATION ? '16px' : '16px 32px'};
  margin-right: ${({ chartDisplayContext }) =>
    chartDisplayContext === ChartDisplayContext.IN_CONFIGURATION ? '0' : '-32px'};
`;

interface KPICardChartProps {
  readonly chartConfiguration: DashboardChartConfiguration<ChartType.KPI_CARD_CHART>;
  readonly chartData?: DashboardChartResult;
  readonly chartFilters: ReactNode;
  readonly chartDisplayContext: ChartDisplayContext;
}

export const KPICardChart = memo(function KPICardChart({
  chartConfiguration,
  chartData,
  chartFilters,
  chartDisplayContext,
}: KPICardChartProps) {
  const theme = useTheme();
  const formatChartValue = useFormatChartValue();

  const column = useMemo(
    () =>
      (chartData?.columns || []).find(
        (column) =>
          column.identifier ===
          makeUniqueCustomReportFieldIdentifier(chartData!.source, chartConfiguration.displaySettings.kpi),
      ),
    [chartConfiguration, chartData],
  );

  const valueFormatted = useMemo(() => {
    if (!chartData || !column) {
      return null;
    }

    const value = chartData.items[0]?.[column.identifier];

    if (isCurrencyValue(value)) {
      const currency = value.symbol;
      return formatChartValue({
        value: { value: value.value, symbol: currency },
        format: FormatsEnum.currency,
      });
    }

    return formatChartValue({ value, format: column.format });
  }, [chartData, column, formatChartValue]);

  const shouldDisplayDoughnut =
    chartConfiguration.displaySettings.isDoughnutEnabled && column?.format === FormatsEnum.percent;

  const chartValues = useMemo(() => {
    if (!shouldDisplayDoughnut || !chartData) {
      return [{ value: 0 }, { value: 0 }];
    }

    const value = ((chartData.items[0]?.[column.identifier] as number | null) ?? 0) * 100;
    const isValueOver100 = value > 100;

    return [{ value: isValueOver100 ? 100 : value }, { value: isValueOver100 ? 0 : 100 - value }];
  }, [chartData, column, shouldDisplayDoughnut]);

  return (
    <KPICardChartContainer chartDisplayContext={chartDisplayContext}>
      <KPICardChartFiltersAndKPI>
        <KPICardChartFilters>{chartFilters}</KPICardChartFilters>
        <KPICardChartKPI>
          <Tooltip content={valueFormatted}>
            <Typography
              variant={TypographyVariant.HEADING_2_MEDIUM}
              css={css`
                max-width: 350px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              `}
            >
              {valueFormatted}
            </Typography>
          </Tooltip>
        </KPICardChartKPI>
      </KPICardChartFiltersAndKPI>

      {!!shouldDisplayDoughnut && (
        <Fragment>
          <KPICardChartKPIAndDoughnutSeparator chartDisplayContext={chartDisplayContext} />
          <KPICardChartDoughnut chartDisplayContext={chartDisplayContext}>
            <PieChart
              height={CHART_SIZE}
              width={CHART_SIZE}
            >
              <Pie
                blendStroke
                data={chartValues}
                dataKey="value"
                endAngle={-90}
                innerRadius="75%"
                outerRadius="100%"
                startAngle={270}
              >
                <Cell
                  key="accomplished"
                  fill={theme.ds.hues[HueCategory.BLUE][900]}
                />
                <Cell
                  key="remaining"
                  fill={theme.ds.colors[ColorCategory.GRAY_SECONDARY][100]}
                />
              </Pie>
            </PieChart>
          </KPICardChartDoughnut>
        </Fragment>
      )}
    </KPICardChartContainer>
  );
});
