import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useBoolState } from '@amalia/ext/react/hooks';
import { selectCurrentPeriod, selectUsersMap } from '@amalia/frontend/web-data-layers';
import { useStatementDetailContext } from '@amalia/lib-ui';
import { LeaderboardDetails, RuleAccordion } from '@amalia/lib-ui-business';
import { useChallengeResult } from '@amalia/payout-calculation/statements/components';
import { type ComputedRule } from '@amalia/payout-calculation/types';
import { RuleLayout } from '@amalia/payout-definition/plans/rules/components';
import { type PlanCategory, type PlanRule } from '@amalia/payout-definition/plans/types';
import { useCurrentCompany } from '@amalia/tenants/companies/state';
import { useTeams } from '@amalia/tenants/teams/state';

interface RuleChallengeProps {
  readonly rule: PlanRule;
  readonly computedRule: ComputedRule;
  readonly category?: PlanCategory;
  readonly activeRuleId?: string;
}

export const RuleChallenge = memo(function RuleChallenge({
  rule,
  computedRule,
  category,
  activeRuleId,
}: RuleChallengeProps) {
  const identifier = `${category?.name || 'none'}-${rule.ruleMachineName}`;

  const usersMap = useSelector(selectUsersMap);
  const { teamsMap } = useTeams();
  const { data: company } = useCurrentCompany();
  const currentPeriod = useSelector(selectCurrentPeriod);

  const { isRuleExpanded, toggleRuleExpanded } = useBoolState(activeRuleId === rule.id, 'ruleExpanded');

  const { statement } = useStatementDetailContext();

  const comparisonVariableDefinition = useMemo(
    () =>
      Object.values(statement?.results.definitions.variables).find(
        (v) => v.id === rule.configuration?.challengeComparisonVariableId,
      ),
    [rule, statement.results],
  );

  const { challenge, mainKpi } = useChallengeResult({ originalStatement: statement, rule });

  // Completely mask the area when the challenge doesn't have a result.
  // It happens if the challenge is deactivated for this period, or if all statements are in error
  // for that period.
  if (!challenge) {
    return null;
  }

  return (
    <RuleAccordion
      header={
        <RuleAccordion.Header
          key={rule.id}
          category={category}
          helpLabel={rule.description}
          isExpanded={isRuleExpanded}
          label={rule.name}
          machineName={identifier}
          mainKpi={mainKpi}
          onToggleExpanded={toggleRuleExpanded}
        />
      }
    >
      <RuleLayout>
        {!!computedRule && !!challenge?.leaderboard && !!comparisonVariableDefinition && (
          <RuleLayout.Dataset>
            <LeaderboardDetails
              challenge={challenge}
              company={company}
              comparisonVariable={comparisonVariableDefinition}
              currentPeriod={currentPeriod}
              teamsMap={teamsMap}
              usersMap={usersMap}
            />
          </RuleLayout.Dataset>
        )}
      </RuleLayout>
    </RuleAccordion>
  );
});
