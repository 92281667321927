import { css } from '@emotion/react';

export const pagination = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const buttonsContainer = css`
  display: flex;
  align-items: center;
  gap: 4px;

  flex: none;
`;
