import { type ForwardedRef, forwardRef, memo } from 'react';

import { TableRow, type TableRowProps } from '../table-row/TableRow';

import * as styles from './TableDataRow.styles';

export type TableDataRowProps = TableRowProps;

const TableDataRowForwardRef = forwardRef(function TableDataRow(
  { children, ...props }: TableDataRowProps,
  ref: ForwardedRef<HTMLTableRowElement>,
) {
  return (
    <TableRow
      {...props}
      ref={ref}
      css={styles.tableDataRow}
    >
      {children}
    </TableRow>
  );
});

export const TableDataRow = memo(TableDataRowForwardRef);
