export enum UserRoleForAccessControl {
  AMALIA_TECH_ADMIN = 'AMALIA_TECH_ADMIN',
  AMALIA_ADMIN = 'AMALIA_ADMIN',
  ADMIN = 'ADMIN',
  READ_ONLY_ADMIN = 'READ_ONLY_ADMIN',
  FINANCE = 'FINANCE',
  MANAGER = 'MANAGER',
  READ_ONLY_MANAGER = 'READ_ONLY_MANAGER',
  EMPLOYEE = 'EMPLOYEE',
  READ_ONLY_EMPLOYEE = 'READ_ONLY_EMPLOYEE',
  DEACTIVATED_USER = 'DEACTIVATED_USER',
}
