import styled from '@emotion/styled/macro';
import { IconInfoCircle } from '@tabler/icons-react';
import { memo, type ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { type FormulaEditorTokenTooltipContent } from '@amalia/amalia-lang/formula/components';
import { IconButton, Popover } from '@amalia/design-system/components';

const PopoverLayout = styled(Popover.Layout)`
  min-height: 48px;
  min-width: 360px;
`;

const PopoverBody = styled(Popover.Body)`
  padding: 8px 16px;
`;

const BodyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

type FunctionTokenPopoverPros = {
  readonly content: FormulaEditorTokenTooltipContent;
  readonly children: ReactElement;
  readonly onClick: () => void;
  readonly isOpen?: boolean;
};

export const FunctionTokenPopover = memo(function FunctionTokenPopover({
  content,
  children,
  isOpen,
  onClick,
}: FunctionTokenPopoverPros) {
  const { formatMessage } = useIntl();

  return (
    <Popover
      autoFocus={false}
      distance={20}
      initialFocus={-1}
      isOpen={isOpen}
      placement="bottom-start"
      content={
        <PopoverLayout>
          <PopoverBody>
            <BodyContainer>
              <div>{content.body}</div>
              <div>
                <IconButton
                  icon={<IconInfoCircle />}
                  label={formatMessage({ defaultMessage: 'Open function documentation' })}
                  onClick={onClick}
                />
              </div>
            </BodyContainer>
          </PopoverBody>
        </PopoverLayout>
      }
    >
      {children}
    </Popover>
  );
});
