import { Fragment, memo } from 'react';

import { useFeatureFlag } from '@amalia/frontend/web-data-layers';

import { type FeatureFlagProps } from './FeatureFlag.types';

export type FeatureFlagDisabledProps = FeatureFlagProps;

export const FeatureFlagDisabled = memo(function FeatureFlagDisabled({
  children,
  featureFlag,
}: FeatureFlagDisabledProps) {
  const { isFeatureEnabled } = useFeatureFlag(featureFlag);
  // need fragment to see as component
  return !isFeatureEnabled && <Fragment>{children}</Fragment>;
});
