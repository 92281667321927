import { http } from '@amalia/core/http/client';
import { type CustomReportConfigurationField } from '@amalia/reporting/custom-reports/shared';
import {
  type DashboardChartConfiguration,
  type ChartType,
  type CreateDashboardChartRequest,
  type Dashboard,
  type DashboardChart,
  type DashboardChartResult,
  type DashboardChartUserFilters,
  type PatchDashboardChartRequest,
  type DashboardChartFacetOption,
  type ChartFacet,
} from '@amalia/reporting/dashboards-v2/types';

export class DashboardChartsApiClient {
  private static readonly PATH_PREFIX = '/dashboards-v2';

  //////////////////////////
  // QUERY METHODS
  //////////////////////////

  public static async getChartData<TChartType extends ChartType>(
    dashboardId: Dashboard['id'],
    chartId: DashboardChart<TChartType>['id'],
    filters: DashboardChartUserFilters,
  ): Promise<DashboardChartResult> {
    const { data } = await http.post<DashboardChartResult>(
      `${DashboardChartsApiClient.PATH_PREFIX}/${dashboardId}/charts/${chartId}/data`,
      filters,
    );
    return data;
  }

  public static async getChartFacet<TChartType extends ChartType, TChartFacet extends ChartFacet>(
    dashboardId: Dashboard['id'],
    chartId: DashboardChart<TChartType>['id'],
    field: CustomReportConfigurationField,
  ): Promise<DashboardChartFacetOption<TChartFacet>[]> {
    const { data } = await http.post<DashboardChartFacetOption<TChartFacet>[]>(
      `${DashboardChartsApiClient.PATH_PREFIX}/${dashboardId}/charts/${chartId}/facet-options`,
      { field },
    );

    return data;
  }

  public static async getChartPreviewData<TChartType extends ChartType>(
    dashboardChartConfiguration: DashboardChartConfiguration<TChartType>,
  ): Promise<DashboardChartResult> {
    const { data } = await http.post<DashboardChartResult>(
      `${DashboardChartsApiClient.PATH_PREFIX}-charts/preview`,
      dashboardChartConfiguration,
    );
    return data;
  }

  public static async findCharts<TChartType extends ChartType>({
    customReportId,
  }: {
    customReportId: string;
  }): Promise<DashboardChart<TChartType>[]> {
    const params: URLSearchParams = new URLSearchParams();
    params.append('customReportId', customReportId);

    const { data } = await http.get<DashboardChart<TChartType>[]>(`${DashboardChartsApiClient.PATH_PREFIX}-charts`, {
      params,
    });
    return data;
  }

  public static async findChartsOnDashboard(dashboardId: Dashboard['id']): Promise<DashboardChart<ChartType>[]> {
    const { data } = await http.get<DashboardChart<ChartType>[]>(
      `${DashboardChartsApiClient.PATH_PREFIX}/${dashboardId}/charts`,
    );
    return data;
  }

  //////////////////////////
  // MUTATION METHODS
  //////////////////////////

  public static async createChart<TChartType extends ChartType>(
    dashboardId: Dashboard['id'],
    createDashboardChartRequest: CreateDashboardChartRequest<TChartType>,
  ): Promise<DashboardChart<TChartType>> {
    const { data } = await http.post<DashboardChart<TChartType>>(
      `${DashboardChartsApiClient.PATH_PREFIX}/${dashboardId}/charts`,
      createDashboardChartRequest,
    );
    return data;
  }

  public static async patchChart<TChartType extends ChartType>(
    dashboardId: Dashboard['id'],
    chartId: DashboardChart<TChartType>['id'],
    patchDashboardChartRequest: PatchDashboardChartRequest<TChartType>,
  ): Promise<DashboardChart<TChartType>> {
    const { data } = await http.patch<DashboardChart<TChartType>>(
      `${DashboardChartsApiClient.PATH_PREFIX}/${dashboardId}/charts/${chartId}`,
      patchDashboardChartRequest,
    );
    return data;
  }

  public static async deleteChart(
    dashboardId: Dashboard['id'],
    chartId: DashboardChart<ChartType>['id'],
  ): Promise<void> {
    await http.delete(`${DashboardChartsApiClient.PATH_PREFIX}/${dashboardId}/charts/${chartId}`);
  }
}
