import { css, type Theme } from '@emotion/react';

export const tabs = css`
  position: relative;
  min-width: fit-content;
  align-self: flex-start;
`;

export const border = (theme: Theme) => css`
  position: absolute;
  z-index: -1;
  bottom: 0;
  width: 100%;

  height: 2px;
  background-color: ${theme.ds.colors.gray[200]};
  border-radius: 1px;
`;

export const contentContainer = css`
  &:empty {
    display: none;
  }
`;
