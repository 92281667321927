import { css, type Theme } from '@emotion/react';

export const container = css`
  height: 100%;
`;

export const tabsContainer = css`
  display: flex;
`;

export const tab = (theme: Theme) => css`
  &:first-of-type {
    border-top-left-radius: ${theme.ds.borderRadiuses.squared};
  }

  &:last-of-type {
    border-top-right-radius: ${theme.ds.borderRadiuses.squared};
  }
`;

export const tabContent = (theme: Theme) => css`
  padding: 40px 40px;
  transition: ${theme.ds.transitions.default()};
`;
