import { UTCDateMini } from '@date-fns/utc';
import { useMemo } from 'react';

export const useUtcDate = (referenceDate: Date = new Date()) => {
  const year = referenceDate.getFullYear();
  const month = referenceDate.getMonth();
  const date = referenceDate.getDate();

  return useMemo(() => new UTCDateMini(year, month, date), [year, month, date]);
};
