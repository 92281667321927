import { type ReactNode, memo, Fragment } from 'react';
import { createPortal } from 'react-dom';

export type PortalProps = {
  /** Element where to mount the portal. */
  readonly element?: HTMLElement | null;
  /** Content to render in the portal. */
  readonly children?: ReactNode;
};

export const Portal = memo(function Portal({ element = null, children = null }: PortalProps) {
  return <Fragment>{element ? createPortal(children, element) : null}</Fragment>;
});
