import { css, type Theme } from '@emotion/react';

export const IS_DISABLED_CLASSNAME = 'is-disabled';

export const singleValue = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[900]};
  transition: ${theme.ds.transitions.default('color')};

  &.${IS_DISABLED_CLASSNAME} {
    color: ${theme.ds.colors.gray[300]};
  }
`;
