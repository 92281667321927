import {
  Icon123,
  IconCalendar,
  IconColumns,
  IconCurrencyDollar,
  IconFilter,
  IconKey,
  IconLetterCase,
  IconLink,
  IconMathFunction,
  IconPercentage,
  IconTable,
  IconUser,
  IconVariable,
  IconViewfinder,
} from '@tabler/icons-react';
import { type FunctionComponent, type SVGProps } from 'react';

import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { FormatsEnum, PropertyRef } from '@amalia/data-capture/fields/types';
import { type TablerIconComponent } from '@amalia/design-system/components';

import { IconTokenBoolean } from '../assets';

export const tokenIcons: Record<TokenType, TablerIconComponent | null> = {
  [TokenType.FUNCTION]: IconMathFunction,
  [TokenType.KEYWORD]: IconKey,
  [TokenType.LINK]: IconLink,
  [TokenType.FILTER]: IconFilter,
  [TokenType.FIELD]: IconColumns,
  [TokenType.PROPERTY]: IconColumns,
  [TokenType.VIRTUAL_PROPERTY]: IconColumns,
  [TokenType.OBJECT]: IconColumns,
  [TokenType.QUOTA]: IconViewfinder,
  [TokenType.VARIABLE]: IconVariable,
  [TokenType.PLAN]: null,
  [TokenType.RULE]: null,
};

export const formatIcons: Record<FormatsEnum, FunctionComponent<SVGProps<SVGSVGElement>> | TablerIconComponent> = {
  [FormatsEnum.number]: Icon123,
  [FormatsEnum.currency]: IconCurrencyDollar,
  [FormatsEnum.percent]: IconPercentage,
  [FormatsEnum.text]: IconLetterCase,
  [FormatsEnum.date]: IconCalendar,
  [FormatsEnum['date-time']]: IconCalendar,
  [FormatsEnum.boolean]: IconTokenBoolean,
  [FormatsEnum.table]: IconTable,
};

export const propertyIcons: Record<PropertyRef, TablerIconComponent> = {
  [PropertyRef.USER]: IconUser,
};
