import { css } from '@emotion/react';
import { ClickAwayListener, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import clsx from 'clsx';
import { uniqBy } from 'lodash';
import { type ElementType, memo, type ReactNode, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Checkbox, Group, SearchInput, Typography } from '@amalia/design-system/components';
import { useBoolState, useUniqueId } from '@amalia/ext/react/hooks';
import { type UserComputed } from '@amalia/tenants/users/types';

import { useLoadMore } from '../../../../../utils/hooks/useLoadMore';
import { useUserSelectStyles } from '../common';

import { UserListItem, type UserListItemProps } from './UserListItem';

export const USER_SELECT_DIALOG_CLASSNAME = 'user-select-dialog';

export type UserSelectRenderParams<TUser extends UserComputed> = {
  dropdownId: string;
  value: TUser | TUser[] | null;
  onClick: VoidFunction;
  open: boolean;
};

export type UserSelectRender<TUser extends UserComputed> = (params: UserSelectRenderParams<TUser>) => ReactNode;

export { type UserListItemProps } from './UserListItem';

export type UserSelectProps<TUser extends UserComputed = UserComputed> = {
  /** List of users to display. */
  readonly users: TUser[] | null;
  /** If true, disable the select. */
  readonly disabled?: boolean;
  /** If true, the select is centered. */
  readonly centered?: boolean;
  /** Children render function, used to render Select Anchor */
  readonly children?: UserSelectRender<TUser>;
  /** Title of the select. */
  readonly title?: ReactNode;
  /** Options to customize the select. */
  readonly options?: {
    hideSelectAll?: boolean;
    hideUnselectAll?: boolean;
  };
  /** If true, display avatars in the list. */
  readonly withAvatarsInList?: boolean;
  /** If true, give focus to the search input when the select is open. */
  readonly giveFocusSearchOnOpen?: boolean;
  /** Custom item component. */
  readonly itemComponent?: ElementType<UserListItemProps>;

  readonly className?: string;

  readonly onChange: (value: TUser[]) => void;
} & (
  | {
      /** If true, allow multiple selection. */
      multiple: true;
      value: TUser[] | null;
    }
  | {
      multiple?: false;
      value: TUser | null;
    }
);

const UserSelectBase = function UserSelect<TUser extends UserComputed = UserComputed>({
  users,
  onChange,
  multiple,
  value,
  title,
  options,
  children,
  disabled,
  withAvatarsInList,
  centered,
  giveFocusSearchOnOpen,
  itemComponent,
  className,
}: UserSelectProps<TUser>) {
  const dropdownId = useUniqueId({ prefix: 'user-select' });

  const classes = useUserSelectStyles({ multiple });

  // ============ OPTIONS TOOLTIP CONTROL ============
  const { isOpen, setOpenFalse, toggleOpen } = useBoolState(false, 'open');

  // ============ SEARCH CONTROL ============
  const [search, setSearch] = useState<string>('');

  const usersFiltered = useMemo(() => {
    if (!search) {
      return users;
    }

    return users.filter((user) =>
      `${user.firstName?.toLowerCase()} ${user.lastName?.toLowerCase()}`.includes(search.toLowerCase()),
    );
  }, [users, search]);

  const { elementsCapped: usersCapped, onClickLoadMore, count, total } = useLoadMore(usersFiltered);

  // ============ ON CHANGE ============
  const onChangeProxy = useCallback(
    (user: TUser) => {
      if (multiple) {
        // Change value if multiple.
        const newValue = uniqBy(
          value.find((elm) => elm.id === user.id) ? [...value.filter((elm) => elm.id !== user.id)] : [...value, user],
          'email',
        );
        onChange(newValue);
      } else {
        // Otherwise close the modal then change the value.
        setOpenFalse();
        onChange([user]);
      }
    },
    [multiple, onChange, value, setOpenFalse],
  );

  const toggleSelectAll = useCallback(() => {
    if (users === null) {
      // If we don't have users, we don't do anything.
      return;
    }

    if (multiple && value?.length) {
      // If we have a value, we unselect all.
      onChange([]);

      return;
    }

    // Otherwise, we select all.
    onChange(users);
  }, [multiple, value, users, onChange]);

  const shouldDisplaySelectAll = (() => {
    if (!multiple || !value) {
      return false;
    }

    const areUsersSelected = !!value.length;

    // We hide:
    // - If no users are selected, and we decide to hide the "select all" option.
    // - If users are selected, and we decide to hide the "unselect all" option.
    return !((options?.hideSelectAll && !areUsersSelected) || (options?.hideUnselectAll && areUsersSelected));
  })();

  const UserListItemComponent = itemComponent || UserListItem;

  return (
    <ClickAwayListener onClickAway={setOpenFalse}>
      <div
        className={className}
        css={css`
          position: relative;
        `}
      >
        {children({ dropdownId, onClick: toggleOpen, value, open: isOpen }) ?? null}
        {isOpen && !disabled ? (
          <div
            className={clsx(classes.dialog, centered && classes.dialog__centered, USER_SELECT_DIALOG_CLASSNAME)}
            id={dropdownId}
          >
            {title !== null && (
              <div>
                <span className={classes.dialogTitle}>{title || <FormattedMessage defaultMessage="Filter rep" />}</span>
              </div>
            )}
            <div>
              <SearchInput
                autoFocus={!!giveFocusSearchOnOpen}
                value={search}
                onChange={setSearch}
              />
            </div>
            <List
              dense
              className={classes.userList}
              role="listbox"
            >
              {shouldDisplaySelectAll && multiple ? (
                <ListItemButton
                  key="selectUser_all"
                  component="li"
                  onClick={toggleSelectAll}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={value?.length === users?.length}
                      indeterminate={!!value?.length && value.length !== users?.length}
                    />
                  </ListItemIcon>
                  <ListItemText primary={<FormattedMessage defaultMessage="Select all" />} />
                </ListItemButton>
              ) : null}
              {usersCapped.map((user: TUser) => (
                <UserListItemComponent
                  key={`selectUser_${user.id}`}
                  multiple={multiple}
                  user={user}
                  value={value ? (Array.isArray(value) ? value : [value]) : null}
                  withAvatarsInList={withAvatarsInList}
                  onChange={onChangeProxy}
                />
              ))}
              {usersCapped.length === 0 && (
                <Group
                  justify="center"
                  css={css`
                    margin-top: 12.8px;
                  `}
                >
                  <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
                    <FormattedMessage defaultMessage="No user matching these filters" />
                  </Typography>
                </Group>
              )}
              {onClickLoadMore ? (
                <ListItemButton
                  component="li"
                  onClick={onClickLoadMore}
                >
                  <i>
                    <FormattedMessage
                      defaultMessage="Load more ({count, number}/{total, number})"
                      values={{ count, total }}
                    />
                  </i>
                </ListItemButton>
              ) : null}
            </List>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export const UserSelect = memo(UserSelectBase) as typeof UserSelectBase;
