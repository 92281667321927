import { http } from '@amalia/core/http/client';
import { type TeamPlanAssignment } from '@amalia/tenants/teams/types';

export class PlanTeamAssignmentsApiClient {
  public static async getTeamPlanAssignments(teamId: string): Promise<TeamPlanAssignment[]> {
    const { data } = await http.get<TeamPlanAssignment[]>(`/teams/${teamId}/plan-assignments`);
    return data;
  }

  public static async addTeamPlanAssignment(
    teamId: string,
    planAssignment: TeamPlanAssignment,
  ): Promise<TeamPlanAssignment> {
    const { data } = await http.post<TeamPlanAssignment>(`/teams/${teamId}/plan-assignments`, planAssignment);
    return data;
  }

  public static async updateTeamPlanAssignment(
    teamId: string,
    planAssignment: TeamPlanAssignment,
  ): Promise<TeamPlanAssignment> {
    const { data } = await http.put<TeamPlanAssignment>(`/teams/${teamId}/plan-assignments`, planAssignment);
    return data;
  }

  public static async removeTeamPlanAssignment(teamId: string, planAssignment: TeamPlanAssignment) {
    const { data } = await http.delete(`/teams/${teamId}/plan-assignments`, { data: planAssignment });
    return data;
  }
}
