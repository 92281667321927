import { IconCheck } from '@tabler/icons-react';
import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type ForwardedRef, forwardRef, memo, type ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { type Schema } from 'yup';

import { getOrCreatePortalNode, Portal } from '@amalia/ext/react/components';
import { useBoolState, useForwardedRef, useUniqueId } from '@amalia/ext/react/hooks';
import { type MergeAll } from '@amalia/ext/typescript';

import { useQuickEdit } from '../../../../data-input/quick-edit/useQuickEdit';
import { IconAction } from '../../../../general/icon-action/IconAction';
import { Tooltip } from '../../../../overlays/tooltip/Tooltip';
import { TableDataCellContent } from '../../layout/table-data-cell-content/TableDataCellContent';
import { type RowData } from '../../Table.types';
import { CellErrorIndicator } from '../cell-error-indicator/CellErrorIndicator';

import { CellTextFieldValue } from './cell-text-field-value/CellTextFieldValue';
import * as styles from './CellTextField.styles';
import { cellTextFieldTestIds } from './CellTextField.testIds';

const CELL_TEXT_FIELD_PORTAL_ID = 'cell-text-field-portal';

const DEFAULT_FORMAT_VALUE = (value: string) => value;

export type CellTextFieldProps<TRowData extends RowData = RowData> = MergeAll<
  [
    Omit<ComponentPropsWithoutRef<'input'>, 'data-form-type' | 'form' | 'onBlur' | 'readOnly' | 'size'>,
    {
      /** Content must be a string. */
      value?: string;
      /** Format value, e.g. for numbers (only in readonly mode for simplicity). */
      formatValue?: (value: string, row: TRowData) => ReactNode;
      /** Current row. */
      row: TRowData;
      /** Callback when clicking confirm. */
      onChange?: (value: string, row: TRowData) => void;
      /** Tooltip shown on hover and focus. */
      tooltip?: ReactNode;
      /** Suffix. */
      suffix?: ReactNode;
      /** Is in edit mode. */
      isEditing?: boolean;
      /** Callback when editing mode changes. */
      onChangeIsEditing?: (isEditing: boolean) => void;
      /** Validation schema. Must be synchronous. */
      schema?: Schema;
      /** Override schema error if needed. */
      error?: ReactNode;
      /** Warning message. If there is an error as well, the error will be displayed instead. */
      warning?: ReactNode;
      /** Blur the value. Only works when disabled is true. */
      isBlurred?: boolean;
    },
  ]
>;

const CellTextFieldForwardRef = forwardRef(function CellTextField<TRowData extends RowData = RowData>(
  {
    row,
    value = '',
    formatValue = DEFAULT_FORMAT_VALUE,
    onChange = undefined,
    tooltip = undefined,
    suffix = undefined,
    isEditing: controlledIsEditing = undefined,
    onChangeIsEditing: controlledOnChangeIsEditing = undefined,
    schema = undefined,
    error: propsError = undefined,
    warning = undefined,
    disabled = false,
    className = undefined,
    isBlurred: propsIsBlurred = false,
    ...props
  }: CellTextFieldProps<TRowData>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { formatMessage } = useIntl();
  const inputRef = useForwardedRef<HTMLInputElement>(ref);

  const handleChange = useCallback((value: string) => onChange?.(value, row), [onChange, row]);

  const {
    internalValue,
    validationError,
    isInEditMode,
    isLoading,
    setInternalValue,
    handleSubmit,
    handleStartEditing,
    onChangeIsEditing,
    handleConfirm,
  } = useQuickEdit({
    value,
    onChange: handleChange,
    isEditing: controlledIsEditing,
    onChangeIsEditing: controlledOnChangeIsEditing,
    schema,
    disabled,
    inputRef,
  });

  const error = propsError || validationError;
  const isBlurred = disabled && propsIsBlurred;

  const formId = useUniqueId({ prefix: 'form#' });

  const formattedValue = formatValue(internalValue, row);

  const focusProps = !disabled ? { onFocus: handleStartEditing, tabIndex: 0 } : undefined;
  const { isValidationTooltipOpen, setValidationTooltipOpen } = useBoolState(false, 'validationTooltipOpen');

  return (
    <div className={className}>
      {/* We want to use QuickEdit + Formik. This would render a form in a form, which isn't allowed, so we render this one in a portal. */}
      <Portal element={getOrCreatePortalNode(CELL_TEXT_FIELD_PORTAL_ID)}>
        {/* This form is used to handle pressing Enter to submit the QuickEdit form, but not an eventual parent form. */}
        <form
          id={formId}
          onSubmit={handleSubmit}
        />
      </Portal>

      <Tooltip
        content={tooltip}
        isOpen={!isValidationTooltipOpen && (isInEditMode || undefined)} // Force open in edition mode but keep automatic behavior in other cases. Needed because the anchor is not focusable. Always hide when validation tooltip is open.
      >
        {/* Display as label when in edit mode so we can click anywhere to focus the input. */}
        <TableDataCellContent
          {...focusProps}
          as={isInEditMode ? 'label' : 'div'}
          css={styles.cellTextField}
          className={clsx({
            [styles.HAS_ERROR_CLASSNAME]: !!error,
            [styles.HAS_WARNING_CLASSNAME]: !error && !!warning,
            [styles.IS_DISABLED_CLASSNAME]: !!disabled,
          })}
        >
          {isInEditMode ? (
            <input
              {...props}
              ref={inputRef}
              aria-invalid={props['aria-invalid'] || !!error}
              css={styles.input}
              data-form-type="other"
              form={formId}
              readOnly={isLoading}
              size={1}
              value={internalValue}
              onBlur={validationError ? () => onChangeIsEditing(false) : handleConfirm}
              onChange={(event) => setInternalValue(event.target.value)}
            />
          ) : (
            <CellTextFieldValue
              formattedValue={formattedValue}
              isBlurred={isBlurred}
              placeholder={props.placeholder}
            />
          )}

          {!!suffix && <span css={styles.suffix}>{suffix}</span>}

          {!disabled && (
            <div css={styles.indicatorsContainer}>
              <CellErrorIndicator
                data-testid={cellTextFieldTestIds.errorAnchor}
                error={error}
                isTooltipOpen={isValidationTooltipOpen}
                warning={warning}
                onChangeIsTooltipOpen={setValidationTooltipOpen}
              />

              {!!(isInEditMode && isLoading) && (
                <IconAction
                  isLoading
                  icon={<IconCheck />}
                  label={formatMessage({ defaultMessage: 'Confirm' })}
                  size={IconAction.Size.SMALL}
                  variant={IconAction.Variant.SUCCESS}
                />
              )}
            </div>
          )}
        </TableDataCellContent>
      </Tooltip>
    </div>
  );
});

export const CellTextField = memo(CellTextFieldForwardRef) as <TRowData extends RowData = RowData>(
  props: CellTextFieldProps<TRowData> & { ref?: ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof CellTextFieldForwardRef>;
