import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal, type ModalProps } from '@amalia/design-system/components';

type RemoveConditionGroupModalProps = {
  readonly isOpen: ModalProps['isOpen'];
  readonly onClose: ModalProps['onClose'];
  readonly onGroupDeletion: () => void;
};

export const RemoveConditionGroupModal = memo(function RemoveConditionGroupModal({
  isOpen,
  onClose,
  onGroupDeletion,
}: RemoveConditionGroupModalProps) {
  return (
    <Modal
      isDismissable
      isOpen={isOpen}
      variant={Modal.Variant.DANGER}
      onClose={onClose}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Delete group of conditions" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage defaultMessage="Are you sure you want to delete this group?" />
        </Modal.Body>
      </Modal.Content>
      <Modal.Actions>
        <Modal.CancelAction />
        <Modal.MainAction onClick={onGroupDeletion}>
          <FormattedMessage defaultMessage="Delete" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
