import { type ForwardedRef, forwardRef } from 'react';

import { IconButton, type IconButtonProps } from '../../../general/icon-button/IconButton';
import { useOptionalDropdownContext } from '../../../overlays/dropdown/Dropdown.context';
import { useSideMenuItemContext } from '../side-menu-item/SideMenuItem.context';

export type SideMenuItemActionProps = Omit<IconButtonProps, 'size'>;

export const SideMenuItemAction = forwardRef(function SideMenuItemAction(
  props: SideMenuItemActionProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { disabled } = useSideMenuItemContext();
  const dropdownContext = useOptionalDropdownContext();

  return (
    <IconButton
      {...props}
      ref={ref}
      disabled={props.disabled || disabled}
      isActive={props.isActive ?? dropdownContext?.isOpen}
      size={IconButton.Size.SMALL}
    />
  );
});
