import { css } from '@emotion/react';
import { IconLayoutGrid, IconTimeline } from '@tabler/icons-react';
import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type VariableDefinition } from '@amalia/amalia-lang/tokens/types';
import { RadioButtonGroup } from '@amalia/design-system/components';
import { useStateInLocalStorage } from '@amalia/lib-ui';
import { type Period } from '@amalia/payout-definition/periods/types';
import { ChallengeLeaderboardTable } from '@amalia/payout-definition/plans/rules/challenges/components';
import { type Challenge } from '@amalia/payout-definition/plans/types';
import { type Company } from '@amalia/tenants/companies/types';
import { type TeamMap } from '@amalia/tenants/teams/types';
import { type UsersMap } from '@amalia/tenants/users/types';

import { LeaderboardHistogram } from '../LeaderboardHistogram';

enum LEADERBOARD_VIEW {
  LIST = 'list',
  CHART = 'chart',
}

export interface LeaderboardDetailsProps {
  readonly challenge: Challenge;
  readonly usersMap: UsersMap;
  readonly teamsMap: TeamMap;
  readonly currentPeriod: Period;
  readonly company: Company;
  readonly comparisonVariable: Pick<VariableDefinition, 'format' | 'name'>;
}

export const LeaderboardDetails = memo(function LeaderboardDetails({
  comparisonVariable,
  challenge,
  usersMap,
  teamsMap,
  currentPeriod,
  company,
}: LeaderboardDetailsProps) {
  const { formatMessage } = useIntl();

  const viewOptions = useMemo(
    () => [
      {
        value: LEADERBOARD_VIEW.LIST,
        icon: <IconLayoutGrid />,
        label: formatMessage({ defaultMessage: 'Switch to list view' }),
        showOnlyIcon: true,
      },
      {
        value: LEADERBOARD_VIEW.CHART,
        icon: <IconTimeline />,
        label: formatMessage({ defaultMessage: 'Switch to chart view' }),
        showOnlyIcon: true,
      },
    ],
    [formatMessage],
  );

  const [selectedView, setSelectedView] = useStateInLocalStorage<LEADERBOARD_VIEW>(
    LEADERBOARD_VIEW.LIST,
    'leaderboard_mode',
  );

  return (
    <div>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          margin-bottom: 16px;
        `}
      >
        {!challenge.rule?.configuration?.challengeHideResults && (
          <RadioButtonGroup<LEADERBOARD_VIEW>
            name="leaderboardView"
            options={viewOptions}
            value={selectedView}
            onChange={setSelectedView}
          />
        )}
      </div>
      {selectedView === 'list' ? (
        <ChallengeLeaderboardTable
          challenge={challenge}
          company={company}
          comparisonVariable={comparisonVariable}
          currentPeriod={currentPeriod}
          usersMap={usersMap}
        />
      ) : (
        <LeaderboardHistogram
          challenge={challenge}
          company={company}
          comparisonVariable={comparisonVariable}
          currentPeriod={currentPeriod}
          teamsMap={teamsMap}
          usersMap={usersMap}
        />
      )}
    </div>
  );
});
