import { groupBy } from 'lodash';
import { Fragment, memo, useMemo } from 'react';

import { type ChangelogEntry, ChangelogImportance } from '@amalia/amalia-meta/changelog/shared';
import { useLatestChangelogEntries } from '@amalia/amalia-meta/changelog/state';
import { useAuthenticatedContext } from '@amalia/kernel/auth/state';

import { ChangelogModal } from './components/changelog-modal/ChangelogModal';
import { ChangelogPopup } from './components/changelog-popup/ChangelogPopup';

type ChangelogsByImportance = Record<ChangelogImportance, ChangelogEntry[]>;

export const ChangelogProvider = memo(function ChangelogProvider() {
  const { authenticatedContext } = useAuthenticatedContext();

  const { data: entries } = useLatestChangelogEntries(
    // Hide for superadmins since their last connection date never changes.
    authenticatedContext.meta?.isSuperAdmin ? null : authenticatedContext.login?.previousLastConnection,
  );

  const entriesByImportance = useMemo(
    () => groupBy(entries?.entries, 'importance') as Partial<ChangelogsByImportance>,
    [entries],
  );

  return (
    <Fragment>
      {!!entriesByImportance[ChangelogImportance.MEDIUM]?.length && (
        <ChangelogPopup changelogEntries={entriesByImportance[ChangelogImportance.MEDIUM]} />
      )}

      {!!entriesByImportance[ChangelogImportance.HIGH]?.length && (
        <ChangelogModal changelogEntries={entriesByImportance[ChangelogImportance.HIGH]} />
      )}
    </Fragment>
  );
});
