import { type Dispatch, type SetStateAction, createContext, useContext } from 'react';

import { assert } from '@amalia/ext/typescript';

import { type PagesEnum } from './shortcutCommands.types';

export type ShortcutCommandContextValue = {
  setPages: Dispatch<SetStateAction<PagesEnum[]>>;
  resetDialog: () => void;
  resetSearch: () => void;
  closeDialog: () => void;
};

export const ShortcutCommandContext = createContext<ShortcutCommandContextValue | null>(null);

export const useShortcutCommandContext = (): ShortcutCommandContextValue => {
  const context = useContext(ShortcutCommandContext);
  assert(context, 'useShortcutCommandContext must be used within a <ShortcutCommandContext.Provider>');
  return context;
};
