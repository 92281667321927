import { useTheme } from '@emotion/react';
import { IconArchive, IconUsers } from '@tabler/icons-react';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ColorCategory } from '@amalia/design-system/meta';
import { useTeams } from '@amalia/tenants/teams/state';

import { CommandGroup } from '../../cmdk-styled/CommandGroup';
import { CommandItem } from '../../cmdk-styled/CommandItem';
import { useActions } from '../../commands-actions/useActions';
import { PagesEnum } from '../../shortcutCommands.types';

export const ListTeamsCommand = memo(function ListTeamsCommand() {
  const theme = useTheme();
  const { teamsList } = useTeams({ sortByName: true, showArchivedTeams: true });

  const { primaryAction } = useActions(PagesEnum.LIST_TEAMS);

  const teamsListOptions = useMemo(
    () =>
      teamsList
        .map((team) => ({
          label: team.name,
          id: team.id,
          isArchived: team.archived,
        }))
        .sort((a) => (a.isArchived ? 1 : -1)),
    [teamsList],
  );

  return (
    <CommandGroup
      Icon={IconUsers}
      title={<FormattedMessage defaultMessage="Teams list" />}
    >
      {teamsListOptions.map(({ id, label, isArchived }) => (
        <CommandItem
          key={id}
          keywords={[id, label]}
          label={label}
          value={id}
          leftElement={
            !!isArchived && (
              <IconArchive
                className="defaultIcon"
                color={theme.ds.colors[ColorCategory.GRAY][800]}
              />
            )
          }
          onSelect={primaryAction?.onSelect}
        />
      ))}
      <div />
    </CommandGroup>
  );
});
