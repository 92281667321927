import { useCallback, useEffect, useState } from 'react';

import { type FormulaEditorToken } from '@amalia/amalia-lang/formula/components';
import { type TokenType } from '@amalia/amalia-lang/tokens/types';

/**
 * If we have more than 150 items, we don't open all groups by default.
 */
const TOKEN_SIZE_LIMIT = 150;

/**
 * By default, only the FUNCTION group is opened.
 */
const initialGroupsState = {
  FIELD: false,
  FILTER: false,
  FUNCTION: true,
  KEYWORD: false,
  LINK: false,
  OBJECT: false,
  PLAN: false,
  PROPERTY: false,
  QUOTA: false,
  RULE: false,
  VARIABLE: false,
  VIRTUAL_PROPERTY: false,
};

export const useOpenedGroups = (items: FormulaEditorToken[]) => {
  const [openedGroups, setOpenedGroups] = useState<Record<TokenType, boolean>>(initialGroupsState);

  /**
   * If we have less than 150 items, we can open all groups by default.
   */
  useEffect(() => {
    if (items.length < TOKEN_SIZE_LIMIT) {
      setOpenedGroups((openedGroups) => ({
        ...openedGroups,
        FIELD: true,
        FILTER: true,
        KEYWORD: true,
        LINK: true,
        OBJECT: true,
        PLAN: true,
        PROPERTY: true,
        QUOTA: true,
        RULE: true,
        VARIABLE: true,
        VIRTUAL_PROPERTY: true,
      }));
    }
  }, [items]);

  const toggleGroup = (group: TokenType) => {
    setOpenedGroups((openedGroups) => ({
      ...openedGroups,
      [group]: !openedGroups[group],
    }));
  };

  const openGroup = (group: TokenType) => {
    setOpenedGroups((openedGroups) => ({
      ...openedGroups,
      [group]: true,
    }));
  };

  const resetOpenGroups = useCallback(() => {
    setOpenedGroups(initialGroupsState);
  }, []);

  return {
    openedGroups,
    openGroup,
    resetOpenGroups,
    toggleGroup,
  };
};
