import { useEffect, useState } from 'react';

export const useFeedback = ({ timeoutMs = 2000 } = {}) => {
  const [value, setValue] = useState(false);

  // Reset success state after 2 seconds
  useEffect(() => {
    if (!value) {
      return undefined;
    }

    const timeout = setTimeout(() => setValue(false), timeoutMs);
    return () => clearTimeout(timeout);
  }, [value, setValue, timeoutMs]);

  return [value, setValue] as const;
};
