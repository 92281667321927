import { type ReactNode, memo, type ReactElement } from 'react';

import { Collapse, Paper } from '@amalia/design-system/components';

import { RuleAccordionHeader, type RuleAccordionHeaderProps } from './rule-accordion-header/RuleAccordionHeader';

export type RuleAccordionProps = {
  readonly header: ReactElement<RuleAccordionHeaderProps>;
  readonly children: ReactNode;
};

const RuleAccordionBase = function RuleAccordion({ header, children }: RuleAccordionProps) {
  return (
    <Paper>
      {header}

      <Collapse
        lazy
        isOpen={header.props.isExpanded}
      >
        {children}
      </Collapse>
    </Paper>
  );
};

export const RuleAccordion = Object.assign(memo(RuleAccordionBase), {
  Header: RuleAccordionHeader,
});
