import { css } from '@emotion/react';
import { useKeyPress } from 'ahooks';
import { Command } from 'cmdk';
import { isEmpty, isNil } from 'lodash';
import { type KeyboardEvent, memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Typography, useLayer } from '@amalia/design-system/components';
import { ComponentSwitch } from '@amalia/ext/react/components';
import { useShallowObjectMemo } from '@amalia/ext/react/hooks';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';

import { AdminCommandsGroup } from './admin-commands/AdminCommandsGroup';
import { ListCustomObjectsCommand } from './admin-commands/custom-objects/ListCustomObjectsCommand';
import { ListReportsCommand } from './admin-commands/custom-reports/ListReportsCommand';
import { ListPlansCommand } from './admin-commands/plans/ListPlansCommand';
import { ListTeamsCommand } from './admin-commands/teams/ListTeamsCommand';
import { ListUsersCommand } from './admin-commands/users/ListUsersCommand';
import { CommandDialog } from './cmdk-styled/CommandDialog';
import { ActionsFooter } from './commands-actions/ActionsFooter';
import { ShortcutCommandContext, type ShortcutCommandContextValue } from './ShortcutCommands.context';
import { PagesEnum } from './shortcutCommands.types';
import { ListCompaniesCommand } from './super-admin-commands/company/ListCompaniesCommand';
import { SuperAdminCommandsGroup } from './super-admin-commands/SuperAdminCommandsGroup';
import { useShortcutCommands } from './useShortcutCommands';

export const ShortcutCommands = memo(function ShortcutCommands() {
  const ability = useAbilityContext();
  const { formatMessage } = useIntl();

  const {
    setSearch,
    setPages,
    setCommandsModalOpen,
    setCommandsModalOpenFalse,
    toggleCommandsModalOpen,
    page,
    search,
    isCommandsModalOpen,
    pages,
    isCommandsLoading,
  } = useShortcutCommands();

  const { layerZIndex } = useLayer({ isOpen: isCommandsModalOpen });

  const canUseCommands = ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin);

  const handlePressCtrlK = useCallback(
    (event: UIEvent) => {
      if (!canUseCommands) {
        return;
      }
      event.preventDefault();
      toggleCommandsModalOpen();
    },
    [canUseCommands, toggleCommandsModalOpen],
  );

  useKeyPress(['ctrl.k', 'meta.k'], handlePressCtrlK, { exactMatch: true });

  const resetDialog = useCallback(() => {
    setSearch('');
    setPages([]);
  }, [setPages, setSearch]);

  const closeDialog = useCallback(() => {
    resetDialog();
    setCommandsModalOpenFalse();
  }, [resetDialog, setCommandsModalOpenFalse]);

  const resetSearch = useCallback(() => {
    setSearch('');
  }, [setSearch]);

  const contextValue = useShallowObjectMemo<ShortcutCommandContextValue>({
    setPages,
    resetDialog,
    resetSearch,
    closeDialog,
  });

  const handleCommandDialogKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      // if we press escape, and we are in the default page we should close the modal
      if (event.key === 'Escape' && isNil(page)) {
        event.preventDefault();
        setCommandsModalOpen(false);
        return;
      }
      // if we press backspace and the search is empty we should go back to the previous page
      if (event.key === 'Backspace' && !search) {
        event.preventDefault();
        setPages((pages) => pages.slice(0, -1));
        return;
      }
      if (event.key === 'Escape') {
        event.preventDefault();
        resetSearch();
        setPages((pages) => pages.slice(0, -1));
      }
    },
    [page, resetSearch, search, setCommandsModalOpen, setPages],
  );

  return (
    <CommandDialog
      label={formatMessage({ defaultMessage: 'Commands menu' })}
      open={isCommandsModalOpen}
      css={css`
        z-index: ${layerZIndex};
      `}
      onKeyDown={handleCommandDialogKeyDown}
      onOpenChange={setCommandsModalOpen}
    >
      <ShortcutCommandContext.Provider value={contextValue}>
        <Command.Input
          placeholder={formatMessage({ defaultMessage: 'Search…' })}
          value={search}
          onValueChange={setSearch}
        />
        <Command.List>
          {isCommandsLoading ? (
            <Command.Loading>
              <FormattedMessage defaultMessage="Hang on…" />
            </Command.Loading>
          ) : (
            <Command.Empty>
              <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>
                <FormattedMessage defaultMessage="No results found." />
              </Typography>
            </Command.Empty>
          )}

          {isEmpty(pages) && (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 16px;
              `}
            >
              <SuperAdminCommandsGroup />
              <AdminCommandsGroup />
            </div>
          )}
          <ComponentSwitch value={page}>
            <ComponentSwitch.Item value={PagesEnum.LIST_COMPANIES}>
              <ListCompaniesCommand />
            </ComponentSwitch.Item>
            <ComponentSwitch.Item value={PagesEnum.LIST_PLANS}>
              <ListPlansCommand />
            </ComponentSwitch.Item>
            <ComponentSwitch.Item value={PagesEnum.LIST_USERS}>
              <ListUsersCommand />
            </ComponentSwitch.Item>
            <ComponentSwitch.Item value={PagesEnum.LIST_REPORTS}>
              <ListReportsCommand />
            </ComponentSwitch.Item>
            <ComponentSwitch.Item value={PagesEnum.LIST_CUSTOM_OBJECTS}>
              <ListCustomObjectsCommand />
            </ComponentSwitch.Item>
            <ComponentSwitch.Item value={PagesEnum.LIST_TEAMS}>
              <ListTeamsCommand />
            </ComponentSwitch.Item>
          </ComponentSwitch>
        </Command.List>
        <ActionsFooter page={page} />
      </ShortcutCommandContext.Provider>
    </CommandDialog>
  );
});
