import { http } from '@amalia/core/http/client';
import { type PaginatedResponse, type TodoDefinition as Todo } from '@amalia/core/types';

export async function fetchTodosCount(): Promise<number> {
  const { data: todosCount } = await http.get<number>('/todos/count');
  return todosCount;
}

export async function fetchTodos(
  filters: string[],
  searchText?: string,
  page = 0,
  limit = 10,
  sortColumn?: string,
  sortOrder = 'asc',
): Promise<PaginatedResponse<Partial<Todo>>> {
  let params = `?page=${page}&limit=${limit}`;
  Object.values(filters).forEach((filter: string) => {
    params += `&filter=${filter}`;
  });

  if (sortColumn) {
    params += `&sort=${sortColumn}&${sortOrder}`;
  }

  if (searchText) {
    params += `&q=${searchText}`;
  }

  const {
    data: { items: responseItems, totalItems, pageCount },
  } = await http.get<PaginatedResponse<Partial<Todo>>>(`/todos${params}`);

  return {
    totalItems,
    items: responseItems,
    pageCount,
  };
}
