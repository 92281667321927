import { noop } from 'lodash';
import { Fragment, memo } from 'react';

import { type FormulaBuilderFunctionBlockDateTwoArgsForm } from '@amalia/amalia-lang/formula/form/types';
import { type ValueOrAttributeType } from '@amalia/amalia-lang/formula/types';
import { type RadioButtonOptionShape } from '@amalia/design-system/components';

import { useFunctionDateHandlers } from '../../function-date-handler/useFunctionDateHandlers';
import { ValueOrAttributeDateSelector } from '../value-or-attribute-date-selector/ValueOrAttributeDateSelector';

export type FunctionDateTwoArgsPopoverProps = {
  readonly condition: FormulaBuilderFunctionBlockDateTwoArgsForm;
  readonly onChange?: (condition: FormulaBuilderFunctionBlockDateTwoArgsForm) => void;
  readonly valueOrAttributeOptions: RadioButtonOptionShape<ValueOrAttributeType>[];
};

export const FunctionDateTwoArgsPopover = memo(function FunctionDateTwoArgsPopover({
  condition,
  onChange = noop,
  valueOrAttributeOptions,
}: FunctionDateTwoArgsPopoverProps) {
  const {
    handleChangeAttributeValue,
    handleChangeManualValue,
    handleChangeOptionTransform,
    handleChangeOptionTransformValue,
    handleChangeValueOrAttribute,
  } = useFunctionDateHandlers<FormulaBuilderFunctionBlockDateTwoArgsForm>({ condition, onChange });

  return (
    <Fragment>
      <ValueOrAttributeDateSelector
        arg={condition.args[1]}
        argIndex={1}
        valueOrAttributeOptions={valueOrAttributeOptions}
        onChangeAttributeValue={handleChangeAttributeValue}
        onChangeManualValue={handleChangeManualValue}
        onChangeOptionTransform={handleChangeOptionTransform}
        onChangeOptionTransformValue={handleChangeOptionTransformValue}
        onChangeValueOrAttribute={handleChangeValueOrAttribute}
      />

      <ValueOrAttributeDateSelector
        arg={condition.args[2]}
        argIndex={2}
        valueOrAttributeOptions={valueOrAttributeOptions}
        onChangeAttributeValue={handleChangeAttributeValue}
        onChangeManualValue={handleChangeManualValue}
        onChangeOptionTransform={handleChangeOptionTransform}
        onChangeOptionTransformValue={handleChangeOptionTransformValue}
        onChangeValueOrAttribute={handleChangeValueOrAttribute}
      />
    </Fragment>
  );
});
