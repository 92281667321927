import { IconReload } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertBanner, Button } from '@amalia/design-system/components';
import { LayoutUnauthenticated, LayoutUnauthenticatedContent } from '@amalia/kernel/auth/components';

import { ReactComponent as UnexpectedErrorIllustration } from './UnexpectedErrorIllustration.svg';

const handleReload = () => window.location.reload();

export const UnexpectedErrorPage = memo(function UnexpectedErrorPage() {
  return (
    <LayoutUnauthenticated>
      <LayoutUnauthenticatedContent
        title={<FormattedMessage defaultMessage="Oops, something went wrong" />}
        action={
          <Button
            icon={<IconReload />}
            iconPosition={Button.IconPosition.RIGHT}
            onClick={handleReload}
          >
            <FormattedMessage defaultMessage="Reload the page" />
          </Button>
        }
        message={
          <AlertBanner
            inline
            variant={AlertBanner.Variant.ERROR}
          >
            <FormattedMessage defaultMessage="We're sorry, but it looks like an unexpected error occurred while processing your request." />
          </AlertBanner>
        }
        picture={
          <UnexpectedErrorIllustration
            height={235.55}
            width={424}
          />
        }
      />
    </LayoutUnauthenticated>
  );
});
