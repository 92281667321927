import { assert, isEnum } from '@amalia/ext/typescript';

import { LanguagesEnum } from './languages';

export const toLanguage = (value: string): LanguagesEnum => {
  const language = value.toLowerCase();

  assert(isEnum(language, LanguagesEnum), `Unknown language ${value}`);

  return language;
};
