import { type SelectDropdownOption } from '../SelectDropdown.types';

/**
 * Return true if at least one option is selected.
 * It does not only check if checkedOptionsValues is not empty to handle wrong values.
 *
 * @param options - Flat list of options.
 * @param checkedOptionsValues - Values of checked options.
 * @returns True if at least one option is selected.
 */
export const useHasSomeOptionSelected = <TOption extends SelectDropdownOption = SelectDropdownOption>(
  options: TOption[],
  checkedOptionsValues: TOption['value'][],
) => options.some((option) => checkedOptionsValues.includes(option.value));
