import { type ForwardedRef, forwardRef, memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { type MergeAll } from '@amalia/ext/typescript';

import {
  DropdownItemCheckbox,
  type DropdownItemCheckboxProps,
} from '../../dropdown/dropdown-item-checkbox/DropdownItemCheckbox';

export type SelectDropdownSelectAllItemProps = MergeAll<
  [
    Omit<DropdownItemCheckboxProps, 'label' | 'onClick'>,
    {
      onChange: (checked: boolean) => void;
    },
  ]
>;

const SelectDropdownSelectAllItemForwardRef = forwardRef(function SelectDropdownSelectAllItem(
  { onChange, checked, ...props }: SelectDropdownSelectAllItemProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const handleClick = useCallback(() => onChange(!checked), [onChange, checked]);

  return (
    <DropdownItemCheckbox
      {...props}
      ref={ref}
      checked={checked}
      label={<FormattedMessage defaultMessage="Select all" />}
      onClick={handleClick}
    />
  );
});

export const SelectDropdownSelectAllItem = memo(SelectDropdownSelectAllItemForwardRef);
