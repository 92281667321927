import { IconArrowLeft } from '@tabler/icons-react';
import { noop } from 'lodash';
import { memo, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  type FormulaBuilderFunctionBlockDateForm,
  type FormulaBuilderFunctionBlockDateTwoArgsForm,
  isFormulaBuilderFunctionBlockDateNoArgsForm,
  type FormulaBuilderFunctionBlockDateOneArgForm,
} from '@amalia/amalia-lang/formula/form/types';
import {
  type DateOptions,
  FormulaBuilderDateOperatorNoArgs,
  FormulaBuilderDateOperatorOneArg,
  FormulaBuilderDateOperatorTwoArgs,
  ValueOrAttributeType,
} from '@amalia/amalia-lang/formula/types';
import { Dropdown, IconButton, type InputProps, Typography } from '@amalia/design-system/components';
import { TypographyVariant } from '@amalia/design-system/meta';
import { ComponentSwitch } from '@amalia/ext/react/components';
import { isEnum } from '@amalia/ext/typescript';

import { useGetFormulaBuilderAttributeLabel } from '../../../hooks/use-get-formula-builder-attribute-label/useGetFormulaBuilderAttributeLabel';
import { useValueOrAttributeOptions } from '../../../hooks/use-value-or-attribute-options/useValueOrAttributeOptions';
import { useFormulaBuilderContext } from '../../formula-builder/FormulaBuilder.context';
import { type FormulaConditionTagProps } from '../../formula-condition-tag/FormulaConditionTag';

import {
  fieldOperatorMessages,
  operatorMessagesTooltip,
  operatorMessagesWithoutTooltip,
  operatorMessagesWithTooltip,
} from './FunctionDatePopover.messages';
import * as styles from './FunctionDatePopover.styles';
import { FunctionDateOneArgPopover } from './one-arg/FunctionDateOneArgPopover';
import { OPERATOR_ICON_MAPPING } from './operator.mappers';
import { FunctionDateTwoArgsPopover } from './two-args/FunctionDateTwoArgsPopover';

enum FunctionDatePopoverStep {
  OPERATOR = 'OPERATOR',
  OTHER = 'OTHER',
}

const operatorHasTooltip = (
  operator: FormulaBuilderDateOperatorNoArgs,
): operator is
  | FormulaBuilderDateOperatorNoArgs.IN_MONTH
  | FormulaBuilderDateOperatorNoArgs.IN_PERIOD
  | FormulaBuilderDateOperatorNoArgs.QUARTER_TO_DATE
  | FormulaBuilderDateOperatorNoArgs.YEAR_TO_DATE =>
  [
    FormulaBuilderDateOperatorNoArgs.YEAR_TO_DATE,
    FormulaBuilderDateOperatorNoArgs.QUARTER_TO_DATE,
    FormulaBuilderDateOperatorNoArgs.IN_MONTH,
    FormulaBuilderDateOperatorNoArgs.IN_PERIOD,
  ].includes(operator);

export const getOptionTransformValue = (value: DateOptions['options']['value']): InputProps['value'] =>
  value === null ? '' : value.toString();

export type FunctionDatePopoverProps = {
  readonly condition: FormulaBuilderFunctionBlockDateForm;
  readonly onChange?: FormulaConditionTagProps<FormulaBuilderFunctionBlockDateForm>['onChange'];
  readonly onClose?: () => void;
};

export const FunctionDatePopover = memo(function FunctionDatePopover({
  condition,
  onChange = noop,
  onClose = noop,
}: FunctionDatePopoverProps) {
  const { customObjectDefinition } = useFormulaBuilderContext();

  const getFormulaBuilderAttributeLabel = useGetFormulaBuilderAttributeLabel();
  const propertyName = getFormulaBuilderAttributeLabel(condition.args[0]);

  const valueOrAttributeOptions = useValueOrAttributeOptions(propertyName, customObjectDefinition?.name);

  const [step, setStep] = useState<FunctionDatePopoverStep>(
    condition.isDraft || isFormulaBuilderFunctionBlockDateNoArgsForm(condition)
      ? FunctionDatePopoverStep.OPERATOR
      : FunctionDatePopoverStep.OTHER,
  );

  const handleGoToOperatorStep = useCallback(() => setStep(FunctionDatePopoverStep.OPERATOR), []);

  const handleChangeOperator = useCallback(
    (
      operator: FormulaBuilderDateOperatorNoArgs | FormulaBuilderDateOperatorOneArg | FormulaBuilderDateOperatorTwoArgs,
    ) => {
      const getOperatorArgs = () => {
        const emptyAttribute = { type: ValueOrAttributeType.ATTRIBUTE, options: { transform: null, value: null } };

        if (isEnum(operator, FormulaBuilderDateOperatorOneArg)) {
          return [condition.args[0], emptyAttribute];
        }

        if (isEnum(operator, FormulaBuilderDateOperatorTwoArgs)) {
          return [condition.args[0], emptyAttribute, emptyAttribute];
        }
        return [condition.args[0]];
      };

      const args = getOperatorArgs();

      onChange({
        ...condition,
        operator,
        args,
        isDraft: !isEnum(operator, FormulaBuilderDateOperatorNoArgs),
      } as FormulaBuilderFunctionBlockDateForm);
      if (!isEnum(operator, FormulaBuilderDateOperatorNoArgs)) {
        setStep(FunctionDatePopoverStep.OTHER);
      } else {
        onClose();
      }
    },
    [onChange, condition, onClose],
  );

  return (
    <div>
      <ComponentSwitch value={step}>
        <ComponentSwitch.Item value={FunctionDatePopoverStep.OPERATOR}>
          <Dropdown.Title>{propertyName}</Dropdown.Title>

          {Object.values(FormulaBuilderDateOperatorNoArgs).map((operator) => (
            <Dropdown.ItemOption
              key={operator}
              checked={condition.operator === operator}
              icon={OPERATOR_ICON_MAPPING[operator]}
              label={<FormattedMessage {...operatorMessagesWithTooltip[operator]} />}
              tooltip={
                operatorHasTooltip(operator) ? <FormattedMessage {...operatorMessagesTooltip[operator]} /> : undefined
              }
              onClick={() => handleChangeOperator(operator)}
            />
          ))}

          {Object.values({
            ...FormulaBuilderDateOperatorOneArg,
            ...FormulaBuilderDateOperatorTwoArgs,
          }).map((operator) => (
            <Dropdown.ItemOption
              key={operator}
              checked={condition.operator === operator}
              icon={OPERATOR_ICON_MAPPING[operator]}
              label={<FormattedMessage {...operatorMessagesWithoutTooltip[operator]} />}
              onClick={() => handleChangeOperator(operator)}
            />
          ))}
        </ComponentSwitch.Item>

        {!isFormulaBuilderFunctionBlockDateNoArgsForm(condition) && (
          <ComponentSwitch.Item value={FunctionDatePopoverStep.OTHER}>
            <div css={styles.otherStepPopover}>
              <div css={styles.headerContainer}>
                <IconButton
                  icon={<IconArrowLeft />}
                  label={<FormattedMessage defaultMessage="Go back to operator" />}
                  onClick={handleGoToOperatorStep}
                />

                {!!condition.operator && (
                  <Typography variant={TypographyVariant.BODY_SMALL_BOLD}>
                    <FormattedMessage
                      {...fieldOperatorMessages[condition.operator]}
                      values={{ propertyName }}
                    />
                  </Typography>
                )}
              </div>

              {!!condition.operator && (
                <div css={styles.form}>
                  {isEnum(condition.operator, FormulaBuilderDateOperatorOneArg) ? (
                    <FunctionDateOneArgPopover
                      condition={condition as FormulaBuilderFunctionBlockDateOneArgForm}
                      valueOrAttributeOptions={valueOrAttributeOptions}
                      onChange={onChange}
                    />
                  ) : (
                    <FunctionDateTwoArgsPopover
                      condition={condition as FormulaBuilderFunctionBlockDateTwoArgsForm}
                      valueOrAttributeOptions={valueOrAttributeOptions}
                      onChange={onChange}
                    />
                  )}
                </div>
              )}
            </div>
          </ComponentSwitch.Item>
        )}
      </ComponentSwitch>
    </div>
  );
});
