import { css } from '@emotion/react';
import { memo } from 'react';

import { type ComputedStatementDefinitions } from '@amalia/core/types';

import { type DatasetWithFields } from './datasetWithField.type';
import { StatementRuleDatasetMenuItem } from './statement-rule-dataset-menu-item/StatementRuleDatasetMenuItem';

type DatasetMenuProps = Readonly<{
  datasets: DatasetWithFields[];

  computedStatementDefinitions: ComputedStatementDefinitions;

  selectDatasetToDisplay: (dataset: DatasetWithFields) => void;

  selectedDataset: DatasetWithFields;
}>;

export const StatementRuleDatasetMenu = memo(function StatementRuleDatasetMenu({
  datasets,
  computedStatementDefinitions,
  selectedDataset,
  selectDatasetToDisplay,
}: DatasetMenuProps) {
  return (
    <div
      css={css`
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
      `}
    >
      {datasets.map((d) => (
        <StatementRuleDatasetMenuItem
          key={d.id}
          computedStatementDefinitions={computedStatementDefinitions}
          dataset={d}
          hasMultipleDatasets={datasets.length > 1}
          selectDatasetToDisplay={selectDatasetToDisplay}
          selectedDataset={selectedDataset}
        />
      ))}
    </div>
  );
});
