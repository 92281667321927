import { css } from '@emotion/react';
import { type ReactNode, memo } from 'react';

import { Paper, Typography } from '@amalia/design-system/components';

export type LayoutUnauthenticatedContentProps = {
  readonly title: ReactNode;
  readonly message: ReactNode;
  /** Svg. Its size must be set. */
  readonly picture?: ReactNode;
  readonly action?: ReactNode;
  readonly footer?: ReactNode;
};

const SMALL_SCREEN_MAX_WIDTH = 1000;

export const LayoutUnauthenticatedContent = memo(function LayoutUnauthenticatedContent({
  picture,
  title,
  message,
  action,
  footer,
}: LayoutUnauthenticatedContentProps) {
  return (
    <Paper
      css={css`
        display: flex;
        flex-direction: column;
        gap: 72px;
        padding: 72px 120px 80px;
        max-width: 1130px;
        min-width: min-content;
        margin: 0 auto;

        @media screen and (max-width: ${SMALL_SCREEN_MAX_WIDTH}px) {
          gap: 56px;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 56px;

          @media screen and (max-width: ${SMALL_SCREEN_MAX_WIDTH}px) {
            flex-wrap: wrap;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 40px;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 32px;
            `}
          >
            <Typography
              variant={Typography.Variant.LARGE_HEADING_BOLD}
              css={css`
                text-wrap: balance;
              `}
            >
              {title}
            </Typography>

            {!!message && <div>{message}</div>}
          </div>

          {!!action && (
            <div
              css={css`
                @media screen and (min-width: ${SMALL_SCREEN_MAX_WIDTH + 1}px) {
                  display: none;
                }
              `}
            >
              {action}
            </div>
          )}
        </div>

        {!!picture && <div>{picture}</div>}
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 24px;

          &:empty {
            display: none;
          }
        `}
      >
        {!!action && (
          <div
            css={css`
              @media screen and (max-width: ${SMALL_SCREEN_MAX_WIDTH}px) {
                display: none;
              }
            `}
          >
            {action}
          </div>
        )}
        {!!footer && <div>{footer}</div>}
      </div>
    </Paper>
  );
});
