import { http } from '@amalia/core/http/client';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';

const apiEndpoint = '/currency_rates';

export class CurrencyRatesApiClient {
  public static async getCurrencyRate(symbol: CurrencySymbolsEnum, date: number): Promise<number> {
    const { data } = await http.get<number>(`${apiEndpoint}/${symbol}?date=${date}`);
    return data;
  }
}
