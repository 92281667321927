import { css, useTheme } from '@emotion/react';
import { makeStyles } from '@mui/styles';
import { IconChevronRight } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { DesignerTokenIcon, getTokenColor, tokenTypeLabels } from '@amalia/amalia-lang/tokens/components';
import { type TokenType } from '@amalia/amalia-lang/tokens/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { Typography } from '@amalia/design-system/components';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';

type UseStylesProps = {
  color: string;
  selected: boolean;
  compact?: boolean;
};

const useStyles = makeStyles<AmaliaThemeType, UseStylesProps>((theme) => ({
  numberContainer: {
    borderRadius: '4px',
    minWidth: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: (props: UseStylesProps) => props.color,
    height: '15px',
    padding: '2px 4px',
  },
  item: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'start',
    fontSize: '12px',
    fontWeight: 600,
    minHeight: (props) => (props.compact ? '20px' : '40px'),
    minWidth: (props) => props.compact && '100px',
    cursor: 'pointer',
    justifySelf: 'stretch',
    backgroundColor: (props) => (!props.compact && props.selected ? theme.palette.grey['100'] : ''),
    position: (props) => (props.compact ? 'relative' : undefined),
    left: (props) => (props.compact ? '2px' : undefined),
    borderRight: (props) =>
      props.compact &&
      (props.selected ? `2px solid ${theme.palette.tertiary.main}` : `2px solid ${theme.palette.grey[200]}`),
    paddingRight: (props) => props.compact && theme.spacing(1),
  },
}));

interface FormulaEditorGroupButtonProps {
  readonly designerObjectsType: TokenType;
  readonly handleHover?: (event: any) => void;
  readonly handleClick: (event: any) => void;
  readonly matchesFound?: number;
  readonly selected: boolean;
  readonly compact?: boolean;
  readonly object?: CustomObjectDefinition;
}

export const FormulaEditorGroupButton = memo(function FormulaEditorGroupButton({
  designerObjectsType,
  handleHover,
  handleClick,
  matchesFound,
  selected,
  compact,
  object,
}: FormulaEditorGroupButtonProps) {
  const theme = useTheme();

  const { formatMessage } = useIntl();
  const classes = useStyles({
    color: getTokenColor(designerObjectsType, theme),
    selected,
    compact,
  });

  if (compact) {
    return (
      <div
        aria-hidden="true"
        className={classes.item}
        onClick={handleClick}
      >
        <Typography variant={Typography.Variant.BODY_SMALL_BOLD}>
          <FormattedMessage
            {...tokenTypeLabels[designerObjectsType]}
            values={{ count: undefined }}
          />
        </Typography>
        <div className={classes.numberContainer}>{matchesFound || 0}</div>
      </div>
    );
  }

  return (
    <div
      aria-hidden="true"
      className={classes.item}
      onClick={handleClick}
      onMouseEnter={handleHover}
    >
      {!object && (
        <DesignerTokenIcon
          color={theme.ds.colors.gray[900]}
          height={20}
          tokenType={designerObjectsType}
          width={20}
        />
      )}

      <Typography
        data-testid={object ? object.name : ''}
        variant={Typography.Variant.BODY_BASE_BOLD}
      >
        {object ? object.name : formatMessage(tokenTypeLabels[designerObjectsType], { count: undefined })}
      </Typography>

      <div className={classes.numberContainer}>{matchesFound || 0}</div>

      <IconChevronRight
        size={16}
        css={css`
          margin-left: auto;
        `}
      />
    </div>
  );
});
