import { css } from '@emotion/react';

import { CheckboxIconSize } from '../../general/checkbox-icon/CheckboxIcon.types';

export const label = css`
  align-self: flex-start;

  position: relative;

  // Offset the checkbox icon from the top to make it visually centered with the first row of its label.
  // Don't offset if no label (only child).
  &:not(:only-child) {
    &.${CheckboxIconSize.SMALL} {
      top: 1px;
    }

    &.${CheckboxIconSize.MEDIUM} {
      top: 1.5px;
    }

    &.${CheckboxIconSize.LARGE} {
      top: 2px;
    }
  }
`;

export const hidden = css`
  display: block;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;

  appearance: none;
  position: absolute;
  outline: none;
`;
