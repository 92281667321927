import { omit, groupBy } from 'lodash';
import { createSelector } from 'reselect';

import { PaymentCategory, type PaymentAggregatedList, type PaymentAggregatedListByUser } from '@amalia/core/types';

import { type RootState } from '../types';

export const selectCurrentPaymentCategory = (state: RootState) => state.payments.currentPaymentCategory;

// STATEMENT
export const selectPaymentsByCategoryForCurrentStatement = (state: RootState) =>
  state.payments.listByCategoryForCurrentStatement;

export const selectContextualizedPaymentsForCurrentStatent = (state: RootState) =>
  state.payments.contextualizedPayments;

export const selectStatementPaymentAmountByCategory = (state: RootState) =>
  state.payments.amountByCategoryForCurrentStatement;

export const selectStatementPaymentForExternalIdAndRuleId = createSelector(
  [
    selectContextualizedPaymentsForCurrentStatent,
    (_, props: { ruleId?: string; externalId: string }) => props.ruleId,
    (_, props: { ruleId?: string; externalId: string }) => props.externalId,
  ],
  (contextualizedPayments, ruleId, externalId) =>
    contextualizedPayments.find((p) => p.dealExternalId === externalId && p.ruleId === ruleId),
);

// COMPANY
export const selectCompanyAmountByCategory = createSelector(
  [(state: RootState) => state.payments.amountsByCategoryForCompany],
  (amountsByCategoryForCompany): Partial<PaymentAggregatedList> =>
    omit(amountsByCategoryForCompany, 'containsHoldRules'),
);

export const selectStatementCardPaymentAmountForCurrentCategory = createSelector(
  [selectCompanyAmountByCategory, selectCurrentPaymentCategory, (_, props: { userId: string }) => props.userId],
  // return all payment aggregates that corresponds to the selected category and statement
  (amountsByCategoryForCompany, currentPaymentCategory, userId) =>
    (amountsByCategoryForCompany[currentPaymentCategory] || []).filter((p) => p.userId === userId),
);

export const selectPeriodContainsStatementWithHoldRules = (state: RootState) =>
  !!state.payments.amountsByCategoryForCompany.containsHoldRules;

export const selectCompanyTotals = createSelector(
  [
    selectCompanyAmountByCategory,
    (_, props: { forceRate?: number | null; planId?: string; teamId?: string }) => props.forceRate,
  ],
  (amountsByCategoryForCompany, forceRate) => {
    const computeTotal = (category: PaymentCategory) =>
      amountsByCategoryForCompany[category]?.reduce((acc, p) => acc + p.sum / (forceRate || p.rate), 0);

    const result: Record<PaymentCategory, number | undefined> = {
      // for each category, get the amounts, filter them by the list of statements to print, then sum them
      // Applicate the rate of the payment (or forceRate if passed)
      [PaymentCategory.achievement]: computeTotal(PaymentCategory.achievement) || 0,
      [PaymentCategory.hold]: computeTotal(PaymentCategory.hold),
      [PaymentCategory.paid]: computeTotal(PaymentCategory.paid),
      [PaymentCategory.adjustments]: undefined,
    };

    return result;
  },
);

export const selectPaymentsIsLoading = (state: RootState) => !!state.payments.isLoading;

export const selectTotalsByUser = createSelector(
  [selectCompanyAmountByCategory],
  (amountsByCategoryForCompany) =>
    ({
      [PaymentCategory.achievement]: groupBy(amountsByCategoryForCompany[PaymentCategory.achievement], 'userId'),
      [PaymentCategory.hold]: groupBy(amountsByCategoryForCompany[PaymentCategory.hold], 'userId'),
      [PaymentCategory.paid]: groupBy(amountsByCategoryForCompany[PaymentCategory.paid], 'userId'),
      [PaymentCategory.adjustments]: {},
    }) as PaymentAggregatedListByUser,
);
