import { defineMessages, type MessageDescriptor } from 'react-intl';

import { PeriodFrequencyEnum, RelativePeriodKeyword } from '@amalia/payout-definition/periods/types';

export const periodFrequenciesMessages: Readonly<Record<PeriodFrequencyEnum, MessageDescriptor>> =
  defineMessages<PeriodFrequencyEnum>({
    [PeriodFrequencyEnum.null]: {
      defaultMessage: 'No frequency',
      description: 'no period frequency',
    },
    [PeriodFrequencyEnum.month]: {
      defaultMessage: 'Monthly',
      description: 'period frequency monthly',
    },
    [PeriodFrequencyEnum.quarter]: {
      defaultMessage: 'Quarterly',
      description: 'period frequency quarterly',
    },
    [PeriodFrequencyEnum.year]: {
      defaultMessage: 'Yearly',
      description: 'period frequency yearly',
    },
  });

export const relativePeriodKeywordsMessages = defineMessages<RelativePeriodKeyword>({
  [RelativePeriodKeyword.CURRENT_PERIOD]: {
    defaultMessage: 'Current period',
    description: 'Relative period keyword',
  },
  [RelativePeriodKeyword.LAST_PERIOD]: { defaultMessage: 'Last period', description: 'Relative period keyword' },
  [RelativePeriodKeyword.LAST_3_MONTHS]: { defaultMessage: 'Last 3 months', description: 'Relative period keyword' },
  [RelativePeriodKeyword.LAST_6_MONTHS]: { defaultMessage: 'Last 6 months', description: 'Relative period keyword' },
  [RelativePeriodKeyword.LAST_12_MONTHS]: {
    defaultMessage: 'Last 12 months',
    description: 'Relative period keyword',
  },
  [RelativePeriodKeyword.LAST_YEAR]: { defaultMessage: 'Last year', description: 'Relative period keyword' },
  [RelativePeriodKeyword.CURRENT_YEAR]: { defaultMessage: 'Current year', description: 'Relative period keyword' },
  [RelativePeriodKeyword.YEAR_TO_DATE]: { defaultMessage: 'Year to date', description: 'Relative period keyword' },
});
