import { createContextualCan } from '@casl/react';
import { memo, type ReactNode, useMemo } from 'react';

import { defineAbilityFor } from '@amalia/kernel/auth/shared';

import { AbilityContext } from '../contexts/ability.context';
import { AuthContext } from '../contexts/auth.context';
import { useAuthenticatedContext } from '../hooks/useAuthenticatedContext';

export const Can = createContextualCan(AbilityContext.Consumer);

interface AccessProtectorProps {
  readonly children: ReactNode;
}

export const AccessProtector = memo(function AccessProtector({ children }: AccessProtectorProps) {
  const { authenticatedContext } = useAuthenticatedContext();
  const ability = useMemo(() => defineAbilityFor(authenticatedContext), [authenticatedContext]);

  return (
    <AuthContext.Provider value={authenticatedContext}>
      <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
    </AuthContext.Provider>
  );
});
