import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { LabelDisplay, LabelVariant, UserPrettyFormat } from '@amalia/data-capture/fields/components';
import { Table } from '@amalia/design-system/components';
import { ActionsEnum, SubjectsEnum, subject } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { type Period } from '@amalia/payout-definition/periods/types';
import { type UserContract } from '@amalia/tenants/users/types';

export type ChallengeLeaderboardCellUserProps = {
  readonly user: UserContract;
  readonly period: Period;
  readonly statementId?: string;
};

export const ChallengeLeaderboardCellUser = memo(function ChallengeLeaderboardCellUser({
  user,
  period,
  statementId,
}: ChallengeLeaderboardCellUserProps) {
  const ability = useAbilityContext();

  const canViewStatement = ability.can(
    ActionsEnum.view,
    // Here we don't have the statement that we would like to check the visibility, because
    // we only have the user id and statement id. Let's consider that if the user has the
    // right to see that challenge results, it means that it's on a non-hidden plan, so
    // he probably can see the other statement. Anyway if he doesn't have access right on
    // the target statement, he's going to have a 403 error when he navigates on it.
    // Fixes https://gitlab.com/amal-ia/amalia-web/-/issues/6742
    subject(SubjectsEnum.Statement, { user: { id: user.id }, period, plan: { isHidden: false } }),
  );

  return (
    <Table.Cell.WithActions
      actions={
        statementId && canViewStatement ? (
          <Table.Cell.Main.Link to={generatePath(routes.STATEMENT, { id: statementId })}>
            <FormattedMessage defaultMessage="View statement" />
          </Table.Cell.Main.Link>
        ) : undefined
      }
    >
      <UserPrettyFormat
        display={LabelDisplay.INLINE}
        firstName={user.firstName}
        lastName={user.lastName}
        pictureURL={user.pictureURL}
        variant={LabelVariant.BOLD}
      />
    </Table.Cell.WithActions>
  );
});
