import { type ForwardedRef, type RefObject, useCallback, useImperativeHandle, useState } from 'react';

import { type FormulaEditorToken } from '@amalia/amalia-lang/formula/components';
import { type TokenType } from '@amalia/amalia-lang/tokens/types';

export type ForwardedMentionListRef = {
  onKeyDown: ({ event }: { event: KeyboardEvent }) => boolean;
};

export const useKeyboardNavigationHandler = (
  groups: { label: TokenType; options: FormulaEditorToken[] }[],
  openGroup: (group: TokenType) => void,
  ref: ForwardedRef<ForwardedMentionListRef>,
  suggestionListContainerRef: RefObject<HTMLDivElement | null>,
  selectFormulaTokenHandler: (formulaEditorToken: FormulaEditorToken) => void,
) => {
  const [selectedGroupIndex, setSelectedGroupIndex] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [selectedFormulaToken, setSelectedFormulaToken] = useState<FormulaEditorToken>();

  const getTokenHtmlElement = useCallback(
    (formulaEditorToken?: FormulaEditorToken) => {
      if (!formulaEditorToken) {
        return undefined;
      }

      const querySelector = `[data-item-type='${formulaEditorToken.type}'][data-item-string-to-match='${formulaEditorToken.formula}']`;
      return suggestionListContainerRef.current?.querySelector(querySelector);
    },
    [suggestionListContainerRef],
  );

  const selectElement = useCallback(
    (groupIndex: number, formulaEditorTokenIndex: number) => {
      const formulaEditorToken = groups[groupIndex].options[formulaEditorTokenIndex];

      const element = getTokenHtmlElement(formulaEditorToken);

      if (element) {
        setSelectedFormulaToken(formulaEditorToken);
        setSelectedIndex(formulaEditorTokenIndex);
        element.scrollIntoView({ behavior: 'auto', block: 'nearest' });
      }
    },
    [groups, getTokenHtmlElement],
  );

  const switchToPreviousGroup = useCallback(() => {
    // If we are already on the first group, do nothing.
    if (selectedGroupIndex === 0) {
      return;
    }

    const newGroupIndex = selectedGroupIndex - 1;
    setSelectedGroupIndex(newGroupIndex);
    selectElement(newGroupIndex, groups[newGroupIndex].options.length - 1);
  }, [groups, selectedGroupIndex, selectElement]);

  const switchToNextGroup = useCallback(() => {
    // If we are already on the last group, do nothing
    if (selectedGroupIndex === Object.keys(groups).length - 1) {
      return;
    }

    const nextGroupIndex = selectedGroupIndex + 1;
    setSelectedGroupIndex(nextGroupIndex);
    selectElement(nextGroupIndex, 0);
  }, [groups, selectedGroupIndex, selectElement]);

  /**
   * Select previous item in group or switch to previous group if we are at the beginning of current group.
   */
  const previousItemHandler = useCallback(() => {
    // Do nothing if there are no groups (search results are empty)
    if (!groups.length) {
      return;
    }

    // If we are on the first element of the current group, we select the last element from previous group.
    if (selectedIndex === 0) {
      switchToPreviousGroup();
      return;
    }

    // Ensure previous group is opened.
    if (selectedIndex === 1) {
      openGroup(groups[selectedGroupIndex - 1].label);
    }

    selectElement(selectedGroupIndex, selectedIndex - 1);
  }, [selectedIndex, selectElement, selectedGroupIndex, switchToPreviousGroup, openGroup, groups]);

  /**
   * Select next item in group or switch to next group if we are at the end of current group.
   */
  const nextItemHandler = useCallback(() => {
    // Do nothing if there are no groups (search results are empty)
    if (!groups.length) {
      return;
    }

    // If we are on the last element of the current group, we select the first element from next group.
    if (groups[selectedGroupIndex].options.length - 1 === selectedIndex) {
      switchToNextGroup();
      return;
    }

    if (groups[selectedGroupIndex].options.length - 2 === selectedIndex) {
      // Check if we stil have groups to open.
      const hasMoreGroups = selectedGroupIndex < groups.length - 1;
      if (hasMoreGroups) {
        openGroup(groups[selectedGroupIndex + 1].label);
      }
    }

    selectElement(selectedGroupIndex, selectedIndex + 1);
  }, [groups, selectedGroupIndex, selectedIndex, selectElement, switchToNextGroup, openGroup]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: KeyboardEvent }) => {
      if (event.key === 'ArrowUp' || (event.key === 'Tab' && event.shiftKey)) {
        previousItemHandler();
        return true;
      }

      if (['ArrowDown', 'Tab'].includes(event.key)) {
        nextItemHandler();
        return true;
      }

      if (event.key === 'Enter') {
        if (selectedFormulaToken) {
          selectFormulaTokenHandler(selectedFormulaToken);
        }
        return true;
      }

      return false;
    },
  }));

  return {
    selectedFormulaToken,
  };
};
