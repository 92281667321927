import { IconExternalLink } from '@tabler/icons-react';
import { type ReactNode, memo, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { formatDate } from '@amalia/core/types';
import { AlertBanner, ButtonLink } from '@amalia/design-system/components';
import { Link } from '@amalia/ext/react-router-dom';

import { LayoutUnauthenticatedContent } from '../layout-unauthenticated/content/LayoutUnauthenticatedContent';
import { LayoutUnauthenticated } from '../layout-unauthenticated/LayoutUnauthenticated';

import { ReactComponent as MaintenancePageIllustration } from './maintenancePageIllustration.svg';

export type MaintenancePageProps = {
  readonly message?: ReactNode;
  readonly startDate?: Date;
  readonly endDate?: Date;
};

export const MaintenancePage = memo(function MaintenancePage({
  message = undefined,
  startDate = undefined,
  endDate = undefined,
}: MaintenancePageProps) {
  return (
    <LayoutUnauthenticated>
      <LayoutUnauthenticatedContent
        title={<FormattedMessage defaultMessage="Amalia is offline" />}
        action={
          <ButtonLink
            icon={<IconExternalLink />}
            iconPosition={ButtonLink.IconPosition.RIGHT}
            variant={ButtonLink.Variant.PRIMARY_LIGHT}
            to={
              <Link
                openInNewTab
                to="https://status.amalia.io"
              />
            }
          >
            <FormattedMessage defaultMessage="Visit our status page for more information" />
          </ButtonLink>
        }
        message={
          <AlertBanner
            inline
            variant={AlertBanner.Variant.WARNING}
          >
            {message || (
              <FormattedMessage defaultMessage="Your app is currently under maintenance, come back later to access your account." />
            )}

            {!!(startDate || endDate) && (
              <Fragment>
                <br />
                <br />
                {!!startDate && !!endDate && (
                  <FormattedMessage
                    defaultMessage="Planned maintenance from around {startDate} to around {endDate}."
                    values={{ startDate: formatDate(startDate, 'LLL'), endDate: formatDate(endDate, 'LLL') }}
                  />
                )}

                {!!startDate && !endDate && (
                  <FormattedMessage
                    defaultMessage="Planned maintenance from around {startDate}."
                    values={{ startDate: formatDate(startDate, 'LLL') }}
                  />
                )}

                {!startDate && !!endDate && (
                  <FormattedMessage
                    defaultMessage="Planned maintenance until around {endDate}."
                    values={{ endDate: formatDate(endDate, 'LLL') }}
                  />
                )}
              </Fragment>
            )}
          </AlertBanner>
        }
        picture={
          <MaintenancePageIllustration
            height={235.55}
            width={424}
          />
        }
      />
    </LayoutUnauthenticated>
  );
});
