export enum PlanRuleCategoryColor {
  BLUE = 'BLUE',
  BROWN = 'BROWN',
  CYAN = 'CYAN',
  GREEN = 'GREEN',
  GREY = 'GREY',
  MAGENTA = 'MAGENTA',
  ORANGE = 'ORANGE',
  RED = 'RED',
  YELLOW = 'YELLOW',
}
