import { css, type Theme } from '@emotion/react';

export const ruleCategoryHeader = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding-left: 4px;
`;

export const leftContainer = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const rightContainer = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const totalContainer = css`
  text-align: right;
  // This aligns the total with the total in the rule accordion header.
  margin-right: 56px;
`;

export const total = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[900]};
`;
