import { memo } from 'react';
import { useIntl } from 'react-intl';

import { CustomObjectImportStatus, type DataRefreshment } from '@amalia/core/types';
import { Badge, type BadgeProps, BadgeStatus, TextOverflow } from '@amalia/design-system/components';

interface RefreshmentStatusBadgeProps {
  readonly refreshment: DataRefreshment;
}

const statusToBadgeVariant: Record<CustomObjectImportStatus, BadgeProps['variant']> = {
  [CustomObjectImportStatus.PENDING]: BadgeStatus.PENDING,
  [CustomObjectImportStatus.STARTED]: BadgeStatus.READY,
  [CustomObjectImportStatus.STOPPING]: BadgeStatus.WARNING,
  [CustomObjectImportStatus.STOPPED]: BadgeStatus.WARNING,
  [CustomObjectImportStatus.SUCCESS]: BadgeStatus.SUCCESS,
  [CustomObjectImportStatus.ERROR]: BadgeStatus.DANGER,
  [CustomObjectImportStatus.INCOMPLETE]: BadgeStatus.WARNING,
};

export const RefreshmentStatusBadge = memo(function RefreshmentStatusBadge({
  refreshment,
}: RefreshmentStatusBadgeProps) {
  const { formatNumber } = useIntl();

  return (
    <TextOverflow
      tooltipContent={`${refreshment.status} (${formatNumber(refreshment.updated)}/${formatNumber(refreshment.totalUpdate)})`}
    >
      <span>
        <Badge
          size={Badge.Size.SMALL}
          variant={statusToBadgeVariant[refreshment.status]}
        >
          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
          {refreshment.status} ({formatNumber(refreshment.updated)}/{formatNumber(refreshment.totalUpdate)})
        </Badge>
      </span>
    </TextOverflow>
  );
});
