import moment from 'moment';

import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.monthsAgo, AmaliaFunctionCategory.DATES);

func.nbParamsRequired = 2;

func.description = 'Return a date n months ago from a given date';

func.params = [
  {
    name: 'date',
    description: 'Date: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
    validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
    validTokenValues: {
      [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
    },
    validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
  },
  { name: 'numberOfMonthsAgo', description: 'Number of months ago, can be negative for months after' },
];

func.examples = [
  {
    desc: 'Returns "2023-03-19" ',
    formula: 'monthsAgo(toDate("2023-05-19"), 2)' as AmaliaFormula,
    result: '1679184000',
  },
  {
    desc: 'Returns "2023-10-19" ',
    formula: 'monthsAgo(toDate("2023-05-19"), -5)' as AmaliaFormula,
    result: '1697673600',
  },
  {
    desc: 'Returns a date 2 months before the Close Date.',
    formula: 'monthsAgo(opportunity.closeDate, 2)' as AmaliaFormula,
  },
  {
    desc: 'Returns a date 1 month after the statement start date.',
    formula: 'monthsAgo(statementPeriod.startDate, -1)' as AmaliaFormula,
  },
];

func.exec = (date: moment.MomentInput | string, numberOfMonths: number | string): string | null =>
  moment(date, 'X').subtract(numberOfMonths, 'months').format('X');

export default func;
