import { css } from '@emotion/react';
import { IconChartBar, IconNumber123 } from '@tabler/icons-react';
import { useFormikContext } from 'formik';
import { pick } from 'lodash';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Divider, FormLayout, Group, IconButton, Typography } from '@amalia/design-system/components';
import { type CustomReport } from '@amalia/reporting/custom-reports/shared';
import { ChartType, type DashboardChartConfiguration } from '@amalia/reporting/dashboards-v2/types';

import { DashboardChartConfigurationModalLabel } from '../../DashboardChartConfigurationModalLabel';

import { KPICardChartContent } from './KPICardChartContent';
import { SimpleBarChartContent } from './SimpleBarChartContent';

interface DashboardConfigurationContentTabProps {
  readonly customReports: CustomReport[];
  readonly onChartTypeChange: (newType: ChartType) => void;
}

export const DashboardConfigurationContentTab = memo(function DashboardConfigurationContentTab({
  customReports,
  onChartTypeChange,
}: DashboardConfigurationContentTabProps) {
  const { values, setValues } = useFormikContext<DashboardChartConfiguration<ChartType>>();

  const handleChangeChartType = useCallback(
    (newType: ChartType) => async () => {
      switch (newType) {
        case ChartType.SIMPLE_BAR_CHART: {
          const actualValues = values as DashboardChartConfiguration<ChartType.KPI_CARD_CHART>;
          await setValues({
            ...values,
            displaySettings: {
              ...pick(actualValues.displaySettings, 'filters'),
              xAxis: { identifier: 'periodStartDate' },
              yAxis: { identifier: '', joins: [] },
            },
            type: newType,
          });
          break;
        }
        case ChartType.KPI_CARD_CHART: {
          const actualValues = values as DashboardChartConfiguration<ChartType.SIMPLE_BAR_CHART>;
          await setValues({
            ...values,
            displaySettings: {
              ...pick(actualValues.displaySettings, 'filters'),
              kpi: { ...actualValues.displaySettings.yAxis },
              isDoughnutEnabled: true,
            },
            type: newType,
          });
          break;
        }
        default:
          throw new Error('Invalid chart type');
      }

      onChartTypeChange(newType);
    },
    [setValues, values, onChartTypeChange],
  );

  return (
    <FormLayout size={FormLayout.Size.SMALL}>
      <FormLayout.Group>
        <DashboardChartConfigurationModalLabel variant={Typography.Variant.BODY_BASE_MEDIUM}>
          <FormattedMessage defaultMessage="Chart type" />
        </DashboardChartConfigurationModalLabel>

        <Group gap={16}>
          <IconButton
            withBackground
            icon={<IconChartBar />}
            isActive={values.type === ChartType.SIMPLE_BAR_CHART}
            label={<FormattedMessage defaultMessage="Simple bar chart" />}
            size={IconButton.Size.LARGE}
            onClick={handleChangeChartType(ChartType.SIMPLE_BAR_CHART)}
          />

          <IconButton
            withBackground
            icon={<IconNumber123 />}
            isActive={values.type === ChartType.KPI_CARD_CHART}
            label={<FormattedMessage defaultMessage="KPI card chart" />}
            size={IconButton.Size.LARGE}
            onClick={handleChangeChartType(ChartType.KPI_CARD_CHART)}
          />
        </Group>
      </FormLayout.Group>

      <Divider.Horizontal
        css={(theme) => css`
          margin: 0 -32px;
          width: calc(100% + 64px);
          background-color: ${theme.ds.colors.gray[100]};
        `}
      />

      {(() => {
        switch (values.type) {
          case ChartType.KPI_CARD_CHART:
            return <KPICardChartContent customReports={customReports} />;
          case ChartType.SIMPLE_BAR_CHART:
            return <SimpleBarChartContent customReports={customReports} />;
          default:
            return null;
        }
      })()}
    </FormLayout>
  );
});
