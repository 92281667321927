import { css } from '@emotion/react';
import { Skeleton } from '@mui/material';
import { mapValues } from 'lodash';
import { memo, useMemo } from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { formatCurrencyAmount } from '@amalia/core/types';
import { Group, Stack } from '@amalia/design-system/components';
import { amaliaTheme, colors as colorPalette } from '@amalia/ext/mui/theme';
import { Text, TextType } from '@amalia/lib-ui';

import { type DataBenchmarkByRule, getUserCurrency, sumTotalForUser } from '../../../utils/dashboard.utils';

type BenchmarkByRuleRowProps = {
  readonly idx: number;
  readonly max?: number;
  readonly row?: DataBenchmarkByRule;
  readonly colors?: string[];
  readonly loading?: boolean;
};

export const BenchmarkByRuleRow = memo(function BenchmarkByRuleRow({
  idx,
  max,
  row,
  colors,
  loading,
}: BenchmarkByRuleRowProps) {
  const amount = useMemo(() => sumTotalForUser(row), [row]);
  const currencySymbol = useMemo(() => getUserCurrency(row), [row]);
  return (
    <Stack
      key={`rank=${idx + 1}`}
      css={css`
        height: 50px;
        margin-bottom: 4px;
        width: 100%;
      `}
    >
      <Group
        css={css`
          padding-left: 4px;
          width: 100%;
        `}
      >
        <Group
          css={css`
            width: 100%;
          `}
        >
          <Group
            css={css`
              position: relative;
              width: 100%;
            `}
          >
            <Text type={TextType.SUBTITLE}>
              {loading ? (
                <Skeleton
                  style={{ width: amaliaTheme.spacing(10) }}
                  variant="text"
                />
              ) : (
                row.label
              )}
            </Text>
          </Group>
          <Text
            type={TextType.SUBTITLE}
            style={{
              textAlign: 'right',
              width: amaliaTheme.spacing(15),
              paddingRight: amaliaTheme.spacing(0.75),
            }}
          >
            {loading ? (
              <Skeleton
                style={{ width: amaliaTheme.spacing(10) }}
                variant="text"
              />
            ) : (
              formatCurrencyAmount({
                amount,
                currencySymbol,
              })
            )}
          </Text>
        </Group>
      </Group>
      {loading ? (
        <Skeleton
          style={{ width: `calc(${100 - idx * 6}%)` }}
          variant="text"
        />
      ) : (
        <ResponsiveContainer
          height="100%"
          width="100%"
        >
          <BarChart
            height={300}
            layout="vertical"
            maxBarSize={max}
            width={500}
            data={[
              {
                ...mapValues(row.stats, (s) => s.value),
                name: row.label,
              },
            ]}
          >
            <XAxis
              hide
              domain={[0, max]}
              type="number"
            />
            <YAxis
              hide
              dataKey="name"
              scale="band"
              type="category"
            />

            {Object.keys(row.stats).map((bar, index) => (
              <Bar
                key={index}
                {...(index === 0 ? { background: { fill: colorPalette['grey-100'] } } : {})}
                barSize={9}
                dataKey={bar}
                fill={colors[index % colors.length]}
                radius={Object.keys(row.stats).length === index + 1 ? [0, 10, 10, 0] : [0, 0, 0, 0]}
                stackId="1"
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      )}
    </Stack>
  );
});
