import { type UserContract, type UserStatusWithDates } from '@amalia/tenants/users/types';

// Fields related to user status and dates
const userStatusProperties = ['clearedAt', 'createdAt', 'invitationSentAt', 'joinedAt', 'status'] as const;
export type UserStatusProperties = (typeof userStatusProperties)[number];

export const userProfileProperties = [
  'clearedAt',
  'createdAt',
  'currency',
  'email',
  'externalId',
  'externalIdSource',
  'firstName',
  'hrisId',
  'hrisIdSource',
  'id',
  'invitationSentAt',
  'joinedAt',
  'lastName',
  'pictureURL',
  'role',
  'language',
] as const;
type UserProfileProperties = (typeof userProfileProperties)[number];

type UserProfilePropertiesWithoutStatusAndDates = Exclude<UserProfileProperties, UserStatusProperties>;

export type UserProfile = Pick<UserContract, UserProfilePropertiesWithoutStatusAndDates> & UserStatusWithDates;

export type UserInfo = Pick<
  UserProfile,
  | 'currency'
  | 'email'
  | 'externalId'
  | 'externalIdSource'
  | 'firstName'
  | 'hrisId'
  | 'hrisIdSource'
  | 'id'
  | 'language'
  | 'lastName'
  | 'role'
>;

export type UpdateUserProfileRequest = UserInfo;

export type UpdateUserProfileFields = (keyof Omit<UpdateUserProfileRequest, 'id'>)[];

export type InviteUsersRequest = UserProfile['id'][];

export type InviteUsersAggregatedError = {
  errors: { error: Error; id: string }[];
  total: number;
};

export const isInviteUsersAggregatedError = (error: unknown): error is InviteUsersAggregatedError => {
  if (typeof error !== 'object' || error === null) {
    return false;
  }

  return 'errors' in error && Array.isArray(error.errors) && 'total' in error && typeof error.total === 'number';
};
