import { isError } from 'lodash';

export const toError = (error: unknown) => {
  if (isError(error)) return error;

  if (typeof error === 'object') {
    try {
      return new Error(JSON.stringify(error));
    } catch (_) {
      return new Error('Could not stringify error');
    }
  }

  return new Error(String(error));
};
