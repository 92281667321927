import { useCallback, useMemo } from 'react';

import { type FilterSelectOption } from '@amalia/design-system/components';

import { type UsersTableData } from '../types';

import { type UserFilters } from './types';
import { useUsersTableFilteredData } from './useUsersTableFilteredData';

export const useUsersTableFilterOptions = <TProperty extends keyof UserFilters>({
  users,
  filters,
  property,
  options,
  onChange,
}: {
  users: UsersTableData[];
  filters: UserFilters;
  property: TProperty;
  options: FilterSelectOption<UsersTableData[TProperty]>[];
  onChange: (filters: Partial<UserFilters>) => void;
}) => {
  const filteredUsers = useUsersTableFilteredData(users, { ...filters, [property]: [] });

  const filteredOptions = useMemo(() => {
    const existingValues = new Set<UsersTableData[TProperty]>(filteredUsers.map((user) => user[property]));

    return options.filter((option) => existingValues.has(option.value));
  }, [filteredUsers, options, property]);

  const onChangeFilter = useCallback(
    (value: UsersTableData[TProperty][]) => {
      onChange({ [property]: value });
    },
    [onChange, property],
  );

  return [filteredOptions, onChangeFilter] as const;
};
