import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { memo, type ReactNode } from 'react';

import { ChangelogProvider } from '@amalia/amalia-meta/changelog/views/changelog';
import { AuthorizationProtector } from '@amalia/kernel/auth/components';
import { AccessProtector } from '@amalia/kernel/auth/state';
import { getEnvironmentVariable } from '@amalia/kernel/config/client';
import { ShortcutCommands } from '@amalia/tools/commands/components';

interface AppProvidersProps {
  readonly children: ReactNode;
}

export const AppProviders = memo(function AppProviders({ children }: AppProvidersProps) {
  return (
    <AuthorizationProtector>
      <AccessProtector>
        <ChangelogProvider />
        {!getEnvironmentVariable('NX_PUBLIC_IS_CYPRESS') && (
          <ReactQueryDevtools
            buttonPosition="bottom-left"
            initialIsOpen={false}
            position="bottom"
          />
        )}
        {children}
        <ShortcutCommands />
      </AccessProtector>
    </AuthorizationProtector>
  );
});
