import { type JSXElementConstructor, memo, type ReactElement, type ReactNode } from 'react';

import { TypographyVariant } from '@amalia/design-system/meta';

import { CellWithActions, type CellWithActionsProps } from '../cell-with-actions/CellWithActions';

import { CellMainLink, type CellMainLinkProps } from './cell-main-link/CellMainLink';

export type CellMainProps = Pick<CellWithActionsProps, 'hideActions' | 'tooltipContent' | 'tooltipPlacement'> & {
  /** Link href. */
  readonly link?: ReactElement<CellMainLinkProps, JSXElementConstructor<CellMainLinkProps>>;
  /** Cell content. */
  readonly children: ReactNode;
};

const CellMainBase = function CellMain({
  link,
  tooltipContent,
  tooltipPlacement,
  hideActions = undefined,
  children,
}: CellMainProps) {
  return (
    <CellWithActions
      actions={link}
      hideActions={hideActions}
      tooltipContent={tooltipContent}
      tooltipPlacement={tooltipPlacement}
      typographyVariant={TypographyVariant.BODY_BASE_MEDIUM}
    >
      {children}
    </CellWithActions>
  );
};

export const CellMain = Object.assign(memo(CellMainBase) as typeof CellMainBase, {
  Link: CellMainLink,
});
