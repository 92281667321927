import { css } from '@emotion/react';
import moment from 'moment/moment';
import { memo, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { type ChangelogEntry } from '@amalia/amalia-meta/changelog/shared';
import { ButtonLink, Group, Modal, Stack, Tooltip, Typography } from '@amalia/design-system/components';
import { Link } from '@amalia/ext/react-router-dom';

import { ChangelogEntryBadges } from '../changelog-entry-badges/ChangelogEntryBadges';
import { NotionTextContent } from '../notion-text-content/NotionTextContent';

export interface ChangelogModalProps {
  readonly changelogEntries: ChangelogEntry[];
  readonly forceOpen?: boolean;
  readonly onClose?: () => unknown;
}

export const ChangelogModal = memo(function ChangelogModal({
  changelogEntries,
  forceOpen,
  onClose,
}: ChangelogModalProps) {
  const [dismissed, setDismissed] = useState(false);

  const onDismiss = useCallback(() => {
    setDismissed(true);
    if (onClose) {
      onClose();
    }
  }, [setDismissed, onClose]);

  if (dismissed && !forceOpen) {
    return null;
  }

  const [changelogEntry] = changelogEntries;

  return (
    <Modal
      isOpen
      size={Modal.Size.LARGE}
      onClose={onDismiss}
    >
      <Modal.Content data-testid="changelog-modal-content">
        <Modal.Header>
          <Modal.Title>{changelogEntry.name}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Group
            align="flex-start"
            gap={40}
          >
            {!!changelogEntry.imageUrl && (
              <img
                alt=""
                src={changelogEntry.imageUrl}
                css={css`
                  width: 400px;
                `}
              />
            )}

            <Stack
              align="flex-start"
              gap={16}
            >
              <Stack
                align="flex-start"
                gap={8}
              >
                <Tooltip content={moment(changelogEntry.date).format('LL')}>
                  <Typography variant={Typography.Variant.BODY_SMALL_REGULAR}>
                    {moment(changelogEntry.date).fromNow()}
                  </Typography>
                </Tooltip>

                <ChangelogEntryBadges changelogEntry={changelogEntry} />
              </Stack>

              <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
                <NotionTextContent notionRichTextContent={changelogEntry.content} />
              </Typography>

              {!!changelogEntry.url && (
                <div
                  css={css`
                    align-self: flex-end;
                  `}
                >
                  <ButtonLink
                    to={
                      <Link
                        openInNewTab
                        to={changelogEntry.url}
                      />
                    }
                    onClick={onDismiss}
                  >
                    {changelogEntry.urlLabel ? (
                      <NotionTextContent notionRichTextContent={changelogEntry.urlLabel} />
                    ) : (
                      <FormattedMessage defaultMessage="Try it out!" />
                    )}
                  </ButtonLink>
                </div>
              )}
            </Stack>
          </Group>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
});
