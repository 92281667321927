import { useAuth0 } from '@auth0/auth0-react';
import { css } from '@emotion/react';
import { IconArrowRight, IconReload } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { AlertBanner, Button } from '@amalia/design-system/components';
import { useLoadingScreen } from '@amalia/lib-ui';

import { LayoutUnauthenticatedContent } from '../layout-unauthenticated/content/LayoutUnauthenticatedContent';
import { LayoutUnauthenticated } from '../layout-unauthenticated/LayoutUnauthenticated';

import { ReactComponent as IFrameAuthenticationIllustration } from './iframeAuthenticationIllustration.svg';

/**
 * This component is used when we embed our app in an IFrame.
 * In this scenario, we are not able to do the full authentication in the IFrame because of some security headers
 * (either in Auth0 or Google auth).
 * As a workaround, user is redirected in a new page where he can authenticate,
 * then as soon as he reloads this page, he is redirected on the homepage.
 * @constructor
 */
export const IFrameAuthentication = function IFrameAuthentication() {
  const [isClicked, setIsClicked] = useState<boolean>();

  const { hideLoading } = useLoadingScreen();

  const { isAuthenticated: isAuthenticatedWithAuth0, isLoading } = useAuth0();
  useEffect(() => {
    if (isLoading === false) {
      hideLoading();
    }

    if (isAuthenticatedWithAuth0) {
      window.location.href = generatePath(routes.ROOT);
    }
  }, [isLoading, isAuthenticatedWithAuth0, hideLoading]);

  const handleClick = () => {
    // If the login button has already been clicked, we reload the page
    // and user will be redirected in the homepage, if login was successful.
    if (isClicked) {
      window.location.reload();
      return;
    }

    setIsClicked(true);
    window.open(generatePath(routes.ROOT), '_blank', 'noopener,noreferrer');
  };

  return (
    !isLoading && (
      <LayoutUnauthenticated>
        <LayoutUnauthenticatedContent
          message={
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 40px;
                align-items: flex-start;
              `}
            >
              {!!isClicked && (
                <AlertBanner inline>
                  <FormattedMessage defaultMessage="Once authenticated in Amalia, please reload to connect to your Amalia account." />
                </AlertBanner>
              )}

              <Button
                icon={isClicked ? <IconReload /> : <IconArrowRight />}
                iconPosition={Button.IconPosition.RIGHT}
                onClick={handleClick}
              >
                {isClicked ? <FormattedMessage defaultMessage="Reload" /> : <FormattedMessage defaultMessage="Login" />}
              </Button>
            </div>
          }
          picture={
            <IFrameAuthenticationIllustration
              height={310}
              width={309}
            />
          }
          title={
            <FormattedMessage
              defaultMessage="Connect to <Brand>Amalia</Brand> using your account"
              values={{
                Brand: (chunks) => (
                  <span
                    css={(theme) => css`
                      color: ${theme.ds.colors.primary[400]};
                    `}
                  >
                    {chunks}
                  </span>
                ),
              }}
            />
          }
        />
      </LayoutUnauthenticated>
    )
  );
};
