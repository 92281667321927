import { css } from '@emotion/react';
import { useFormikContext } from 'formik';
import { Fragment, memo, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ATTRIBUTE_FORMAT_ICON_MAPPING } from '@amalia/amalia-lang/formula/components';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { Divider, FormLayout, FormikSelect, Select, type SelectOption } from '@amalia/design-system/components';
import {
  CustomReportAggregationsForFormat,
  getCustomReportFieldDefinition,
  parseCustomReportFieldPosition,
  stringifyCustomReportFieldPosition,
  type CustomReport,
  type CustomReportAggregationOperation,
} from '@amalia/reporting/custom-reports/shared';
import { useCustomReportSourcesManifests } from '@amalia/reporting/custom-reports/state';
import { CustomReportAggregationLabel } from '@amalia/reporting/custom-reports/views';
import { type ChartType, type DashboardChartConfiguration } from '@amalia/reporting/dashboards-v2/types';

interface SimpleBarChartContentProps {
  readonly customReports: CustomReport[];
}

export const SimpleBarChartContent = memo(function SimpleBarChartContent({
  customReports,
}: SimpleBarChartContentProps) {
  const { formatMessage } = useIntl();

  const { values, setValues } = useFormikContext<DashboardChartConfiguration<ChartType.SIMPLE_BAR_CHART>>();

  const selectedReport = customReports.find((customReport) => customReport.id === values.customReportId);
  const { manifestsMap } = useCustomReportSourcesManifests(selectedReport ?? null, false);

  const xAxisOptions = useMemo<SelectOption<string>[]>(() => [{ value: 'periodStartDate', label: 'Period' }], []);

  const yAxisOptions = useMemo(
    () =>
      selectedReport && Object.keys(manifestsMap).length
        ? selectedReport.configuration.fields
            .map((field) => {
              const fieldDefinition = getCustomReportFieldDefinition(selectedReport, manifestsMap, field);

              if (!fieldDefinition) {
                return null;
              }

              return {
                value: stringifyCustomReportFieldPosition(field),
                label: `${field.alias || fieldDefinition.label}`,
                format: fieldDefinition.format,
                icon: ATTRIBUTE_FORMAT_ICON_MAPPING[fieldDefinition.format],
              };
            })
            .filter(Boolean)
            .filter((c) => [FormatsEnum.number, FormatsEnum.currency, FormatsEnum.percent].includes(c.format))
        : [],
    [selectedReport, manifestsMap],
  );

  const aggregationsOptions = useMemo(() => {
    const yAxisOption = yAxisOptions.find(
      ({ value }) => value === stringifyCustomReportFieldPosition(values.displaySettings.yAxis),
    );

    if (!yAxisOption) {
      return [];
    }

    return CustomReportAggregationsForFormat[yAxisOption.format].map((agg) => ({
      value: agg,
      label: formatMessage(CustomReportAggregationLabel[agg]),
    }));
  }, [formatMessage, yAxisOptions, values]);

  const handleChangeYAxis = useCallback(
    async (newYAxis: string | null) => {
      const newValues: DashboardChartConfiguration<ChartType.SIMPLE_BAR_CHART> = {
        ...values,
        displaySettings: {
          ...values.displaySettings,
          yAxis: { ...values.displaySettings.yAxis, ...parseCustomReportFieldPosition(newYAxis!) },
        },
      };
      await setValues(newValues);
    },
    [values, setValues],
  );

  return (
    <Fragment>
      <FormLayout.Group>
        <Select<SelectOption<string>>
          disabled
          required
          id="displaySettings.xAxis"
          label={<FormattedMessage defaultMessage="X-axis" />}
          options={xAxisOptions}
          value={values.displaySettings.xAxis.identifier}
        />
      </FormLayout.Group>

      <Divider.Horizontal
        css={(theme) => css`
          margin: 0 -32px;
          width: calc(100% + 64px);
          background-color: ${theme.ds.colors.gray[100]};
        `}
      />

      <FormLayout.Group>
        <Select<SelectOption<string>>
          required
          disabled={!selectedReport}
          id="displaySettings.yAxis"
          label={<FormattedMessage defaultMessage="Y-axis" />}
          options={yAxisOptions}
          value={stringifyCustomReportFieldPosition(values.displaySettings.yAxis)}
          placeholder={
            selectedReport
              ? formatMessage({ defaultMessage: 'Choose a column' })
              : formatMessage({ defaultMessage: 'Please select a report first' })
          }
          onChange={handleChangeYAxis}
        />

        <FormikSelect<SelectOption<CustomReportAggregationOperation>>
          required
          disabled={!values.displaySettings.yAxis.identifier}
          id="displaySettings.yAxis.aggregation.operation"
          name="displaySettings.yAxis.aggregation.operation"
          options={aggregationsOptions}
          placeholder={
            values.displaySettings.yAxis.identifier
              ? formatMessage({ defaultMessage: 'Select aggregate' })
              : formatMessage({ defaultMessage: 'Please select an Y-axis first' })
          }
        />
      </FormLayout.Group>
    </Fragment>
  );
});
