import { css } from '@emotion/react';
import { IconSearch } from '@tabler/icons-react';
import { memo, type ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Input, Stack } from '@amalia/design-system/components';

import { Spinner } from '../../loaders/Spinner/Spinner';

interface SearchModalProps {
  readonly isLoading: boolean;
  readonly filter: string;
  readonly onChangeFilter: (value: string) => void;
  readonly className?: string;
  readonly children?: ReactNode;
}

export const SearchModal = memo(function SearchModal({
  children,
  isLoading,
  className,
  filter,
  onChangeFilter,
}: SearchModalProps) {
  const { formatMessage } = useIntl();

  return (
    <Stack
      className={className}
      gap={8}
    >
      <Input
        leftIcon={<IconSearch />}
        name="search-modal-input"
        placeholder={formatMessage({ defaultMessage: 'Search' })}
        value={filter}
        onChange={onChangeFilter}
      />

      <Stack
        gap={8}
        css={css`
          max-height: 300px;
          overflow-y: auto;
        `}
      >
        {isLoading ? <Spinner /> : null}
        {children}
      </Stack>
    </Stack>
  );
});
