import { css } from '@emotion/react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { type TooltipProps } from 'recharts';

import { Group, Paper, Stack, Typography } from '@amalia/design-system/components';
import { type CustomReportRow } from '@amalia/reporting/custom-reports/shared';

import { type ChartSeriesMetadata, type ChartColumnMetadata } from '../../types/charts.types';
import { ChartSeriesIcon } from '../chart-series-icon/ChartSeriesIcon';

export type ChartTooltipProps = TooltipProps<number | string, string> & {
  readonly seriesList: ChartSeriesMetadata[];
  readonly formatLabel: ChartColumnMetadata['formatTooltipValue'];
};

export const ChartTooltip = memo(function ChartTooltip({
  active,
  payload,
  seriesList,
  formatLabel,
  label,
}: ChartTooltipProps) {
  // Active state is when the cursor in inside the chart (otherwise the tooltip never disappears when the cursor leaves the chart area).
  // Payload should never be empty because we use `fillNull={false}` on the Tooltip component.
  if (!active || !payload?.length) {
    return null;
  }

  const formattedLabel = formatLabel(label as number | string);

  return (
    <Paper
      css={css`
        padding: 8px 16px 16px;
      `}
    >
      <Stack
        align="flex-start"
        gap={12}
      >
        <Typography
          variant={Typography.Variant.BODY_XSMALL_BOLD}
          css={(theme) => css`
            ${theme.ds.colors.gray[800]}
          `}
        >
          {formattedLabel}
        </Typography>

        {seriesList.map((series) => {
          const formattedValue = series.columnMetadata.formatTooltipValue(
            (payload.find((item) => item.dataKey === series.columnMetadata.dataKey)?.payload as CustomReportRow)[
              series.columnMetadata.column.identifier
            ],
          );

          return (
            <Group
              key={series.columnMetadata.dataKey}
              align="center"
              gap={10}
            >
              <ChartSeriesIcon series={series} />

              <Typography
                variant={Typography.Variant.BODY_SMALL_MEDIUM}
                css={(theme) => css`
                  ${theme.ds.colors.gray[800]}
                `}
              >
                {series.columnMetadata.column.label}
              </Typography>

              <Typography
                variant={Typography.Variant.BODY_XSMALL_MEDIUM}
                css={(theme) => css`
                  color: ${formattedValue ? theme.ds.colors.gray[800] : theme.ds.colors.gray[500]};
                `}
              >
                {formattedValue || <FormattedMessage defaultMessage="No data" />}
              </Typography>
            </Group>
          );
        })}
      </Stack>
    </Paper>
  );
});
