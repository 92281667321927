// AmaliaFormula will inherit from string type so string operations will be authorized.
export type AmaliaFormula = string & { readonly _?: unique symbol };

export enum AmaliaFunctionCategory {
  ARRAY = 'ARRAY',
  CURRENCY = 'CURRENCY',
  DATES = 'DATES',
  INDICES = 'INDICES',
  MISC = 'MISC',
  NUMBERS = 'NUMBERS',
  STRING = 'STRING',
}

export enum FormulaBuilderFunctionCategory {
  DATE = 'DATE',
  STRING = 'STRING',
  USER = 'USER',
  NUMBER = 'NUMBER',
  PERCENT = 'PERCENT',
  BOOLEAN = 'BOOLEAN',
  CURRENCY = 'CURRENCY',
}

export enum AmaliaFunctionKeys {
  businessDaysTo = 'businessDaysTo',
  CEIL = 'CEIL',
  CONCAT = 'CONCAT',
  CONTAINS = 'CONTAINS',
  convertCurrency = 'convertCurrency',
  COUNT = 'COUNT',
  dateBetween = 'dateBetween',
  dateOffset = 'dateOffset',
  dayNumber = 'dayNumber',
  dayOfWeekNumber = 'dayOfWeekNumber',
  daysAgo = 'daysAgo',
  daysTo = 'daysTo',
  DEFAULT = 'DEFAULT',
  endOfMonth = 'endOfMonth',
  endOfQuarter = 'endOfQuarter',
  endOfYear = 'endOfYear',
  ENDS_WITH = 'ENDS_WITH',
  EXACT = 'EXACT',
  FLOOR = 'FLOOR',
  fromDate = 'fromDate',
  GETATINDEX = 'GETATINDEX',
  GETINTABLE = 'GETINTABLE',
  IF = 'IF',
  IN = 'IN',
  isNotNull = 'isNotNull',
  isNull = 'isNull',
  LEFT = 'LEFT',
  LINEAR = 'LINEAR',
  LOWER = 'LOWER',
  MATCH = 'MATCH',
  MATCH_USERS_ASSIGNED = 'MATCH_USERS_ASSIGNED',
  MAX = 'MAX',
  MIN = 'MIN',
  MOD = 'MOD',
  monthsAgo = 'monthsAgo',
  monthNumber = 'monthNumber',
  monthsTo = 'monthsTo',
  NOT = 'NOT',
  ROUND = 'ROUND',
  rowMarginalIndex = 'rowMarginalIndex',
  rowTiersIndex = 'rowTiersIndex',
  SEARCH = 'SEARCH',
  SLICE = 'SLICE',
  SORT = 'SORT',
  SPLIT = 'SPLIT',
  startOfMonth = 'startOfMonth',
  startOfQuarter = 'startOfQuarter',
  startOfYear = 'startOfYear',
  STARTS_WITH = 'STARTS_WITH',
  SUM = 'SUM',
  TIER = 'TIER',
  toDate = 'toDate',
  toNumber = 'toNumber',
  UNIQUE = 'UNIQUE',
  UPPER = 'UPPER',
  yearNumber = 'yearNumber',
}
