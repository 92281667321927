import { Fragment, memo } from 'react';

import { useShowMore } from '../../dropdown-list/hooks/useShowMore';
import { DropdownListShowMoreButton } from '../../dropdown-list/show-more-button/ShowMoreButton';
import { SelectDropdownItem, type SelectDropdownItemProps } from '../select-dropdown-item/SelectDropdownItem';
import { type SelectDropdownOption } from '../SelectDropdown.types';

export type PaginatedSelectDropdownItemsListProps<
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
> = {
  readonly isMultiple?: TIsMultiple;
  readonly options: TOption[];
  readonly checkedOptionsValues: TOption['value'][];
  /** Option checked change handler. */
  readonly onOptionChange: SelectDropdownItemProps<TOption, TIsMultiple>['onChange'];
};

const PaginatedSelectDropdownItemsListBase = function PaginatedSelectDropdownItemsList<
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
>({
  isMultiple,
  options,
  checkedOptionsValues,
  onOptionChange,
}: PaginatedSelectDropdownItemsListProps<TOption, TIsMultiple>) {
  const { itemsToShow, hasMore, onShowMore } = useShowMore(options);

  return (
    <Fragment>
      {itemsToShow.map((option) => (
        <SelectDropdownItem<TOption, TIsMultiple>
          key={String(option.value)}
          checked={checkedOptionsValues.includes(option.value)}
          isMultiple={isMultiple}
          option={option}
          onChange={onOptionChange}
        />
      ))}

      {!!hasMore && (
        <DropdownListShowMoreButton
          count={itemsToShow.length}
          total={options.length}
          onClick={onShowMore}
        />
      )}
    </Fragment>
  );
};

export const PaginatedSelectDropdownItemsList = memo(
  PaginatedSelectDropdownItemsListBase,
) as typeof PaginatedSelectDropdownItemsListBase;
