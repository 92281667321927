import { memo } from 'react';

export const PdfIcon = memo(function PdfIcon({ size }: { readonly size: number }) {
  return (
    <svg
      fill="#AD2722"
      height={size}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 64 64"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.8 64c-2.7 0-4.4-1.9-5-3.2-.6-1-2.3-4.7 2.4-9.5 2-2.1 5.8-5.9 15.6-9.2 1.3-2.7 2.7-5.7 4.2-8.8 1.6-3.5 2.9-6.5 3.9-9.2-4-6.4-4.7-11.1-4.6-16.4.1-6 4.1-7.6 6.2-7.6.1 0 5.8.2 6.2 8.9.1 3.2-.4 6.9-3.3 14.6 2 3 4.8 6.4 8.3 10.3 1.2 1.4 2.4 2.6 3.4 3.7 1 0 2-.1 3-.1 8.6 0 12.9 1.9 14.7 3 3.8 2.4 3.6 6.2 2.9 7.8-.5 1.3-2.4 3.7-6.2 3.7-.9 0-1.9-.1-2.9-.4-3.2-.9-5.6-2.2-13-9.9-3.4.3-7.2.8-11.2 1.6-3.1.6-6.1 1.3-8.7 2.1-8.2 15.9-12 17.5-13.2 18-.9.4-1.8.6-2.7.6zM16 47.4c-5.9 2.5-8.5 5.1-10 6.7-2.9 3-1.9 4.6-1.9 4.6l.3.3v.1c0 .1.5.9 1.4.9.3 0 .7-.1 1.1-.3.8-.3 3.6-2.2 9.1-12.3zm30.9-5.9c5 5.1 6.7 5.7 8.7 6.2.7.2 1.3.3 1.9.3 1.9 0 2.4-1.1 2.4-1.2.2-.5.5-1.9-1.2-3-1.2-.6-4.5-2.2-11.8-2.3zm-22.7-1.2l-.1.2c1.7-.4 3.6-.9 5.5-1.2 2.9-.6 5.8-1 8.5-1.3l-.1-.1c-.4-.4-.8-.8-1.2-1.3-2.9-3.1-5.2-6-7.1-8.5l-.1.1c-.9 2.1-1.8 4.3-2.9 6.8-.9 1.8-1.8 3.6-2.5 5.3zM28.4 4c-.6.1-2 .5-2.1 3.6-.1 3.3.1 6.7 2.4 11.4 1.9-5.7 2-8.1 2-10-.2-4.2-2-4.9-2.3-5z" />
    </svg>
  );
});

export default PdfIcon;
