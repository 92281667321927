import { keys, replace } from 'lodash';

import { formatDate, type OverwriteResponse, formatUserFullName, formatDatasetCell } from '@amalia/core/types';
import { CsvFileGenerator } from '@amalia/kernel/file-generation/csv';

const transformOverwriteToFlatData = (overwrite: Partial<OverwriteResponse>) => {
  const variableField = overwrite.propertyName || '-';

  const oldValue = keys(overwrite.sourceValue).includes(overwrite.field || '')
    ? overwrite.sourceValue?.[overwrite.field || '']
    : overwrite.sourceValue;
  const newValue = keys(overwrite.overwriteValue).includes(overwrite.field || '')
    ? overwrite.overwriteValue?.[overwrite.field || '']
    : overwrite.overwriteValue;

  return [
    // Scope
    overwrite.scope,
    // User
    overwrite?.statement?.user ? formatUserFullName(overwrite.statement.user) : '',
    // Period
    overwrite?.statement?.period?.name || '',
    // Plan
    overwrite?.statement?.plan?.name || '',
    // Applies to
    overwrite.appliesToExternalId ? overwrite.appliesToExternalId : 'Statement',
    // Applies to (name)
    overwrite.appliesToDealName ? replace(overwrite.appliesToDealName, '"', '') : '',
    // Variable
    variableField,
    // Old value
    formatDatasetCell(oldValue),
    // New value
    formatDatasetCell(newValue),
    // Currency,
    overwrite.currency,
    // Cleared at
    overwrite.clearedAt ? formatDate(overwrite.clearedAt, 'YYYY-MM-DD') : '',
    // Overwritten by
    overwrite.creator ? formatUserFullName(overwrite.creator) : '',
    // Overwritten date
    overwrite.createdAt ? formatDate(overwrite.createdAt, 'YYYY-MM-DD') : '',
  ];
};

export const mapOverwritesToCsv = async (overwrites: Partial<OverwriteResponse>[]) => {
  const data = overwrites.map(transformOverwriteToFlatData);

  const fields = [
    'scope',
    'user',
    'period',
    'plan',
    'appliesTo',
    'appliesTo (name)',
    'variable',
    'old value',
    'new value',
    'currency',
    'cleared at',
    'overwritten by',
    'overwritten date',
  ];

  return new CsvFileGenerator().generate({
    fields,
    data,
  });
};
