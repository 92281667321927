import { http } from '@amalia/core/http/client';
import {
  type Calculation,
  type SaCalculationRequest,
  type RefreshmentAndCalculationStopActions,
} from '@amalia/core/types';

import { type PaginationOf } from './types';

export async function saGetOngoingCalculations(): Promise<Calculation[]> {
  const { data } = await http.get<Calculation[]>('/sa_calculations/ongoing');
  return data;
}

export async function saGetLastCalculations(
  page: number,
  companiesId: string[],
  filterErrors: boolean,
): Promise<PaginationOf<Calculation>> {
  const { data } = await http.get<PaginationOf<Calculation>>('/sa_calculations/last', {
    params: {
      page,
      companiesId,
      filterErrors,
    },
  });
  return data;
}

export async function saSetCalculationStatus(
  calculationId: string,
  action: RefreshmentAndCalculationStopActions,
): Promise<Calculation> {
  const { data } = await http.patch<Calculation>(`/sa_calculations/operation/${calculationId}/${action}`);
  return data;
}

export async function saCreateCalculation(calculation: SaCalculationRequest) {
  const { data } = await http.post<SaCalculationRequest>('/sa_calculations', calculation);
  return data;
}
