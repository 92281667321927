import { type ToOrLinkElement } from '@amalia/ext/react-router-dom';
import { type UserContract } from '@amalia/tenants/users/types';

export enum UsersAvatarStackSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export type UsersAvatarStackUser = {
  user: Pick<UserContract, 'firstName' | 'id' | 'lastName' | 'pictureURL'>;
  to?: ToOrLinkElement<true>;
};
