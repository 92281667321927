import { makeStyles } from '@mui/styles';
import { IconChevronRight, IconDots } from '@tabler/icons-react';
import { Fragment, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Typography } from '@amalia/design-system/components';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';
import { LightTooltip } from '@amalia/lib-ui';

interface ParentTeamGroupProps {
  readonly teamsName: string[];
}

const useStyle = makeStyles((theme: AmaliaThemeType) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(0, 1.25),
    margin: theme.spacing(0, 1.25),
  },
  divider: {
    padding: theme.spacing(0, 1.25),
    marginBottom: theme.spacing(0.625),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: theme.palette.grey['100'],
    padding: theme.spacing(0, 1.25),
    marginBottom: theme.spacing(0.625),
  },
  popper: {
    border: 0,
  },
  tooltipContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  tooltip: {
    padding: theme.spacing(0.5, 1.25),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: '500px !important',
  },
}));

export const ParentTeamGroup = memo(function ParentTeamGroup({ teamsName }: ParentTeamGroupProps) {
  const classes = useStyle();
  const { formatMessage } = useIntl();

  const firstParentTeam = teamsName[0];
  const lastParentTeam = teamsName[teamsName.length - 1];

  const hiddenParentTeams = useMemo(
    () => teamsName.filter((t) => ![firstParentTeam, lastParentTeam].includes(t)),
    [firstParentTeam, lastParentTeam, teamsName],
  );

  return (
    <div className={classes.container}>
      <Typography
        className={classes.title}
        variant={Typography.Variant.BODY_BASE_MEDIUM}
      >
        {firstParentTeam}
      </Typography>

      {teamsName.length > 2 && (
        <div className={classes.tooltipContainer}>
          <div className={classes.divider}>
            <IconChevronRight size={16} />
          </div>

          <LightTooltip
            className={classes.popper}
            placement="top"
            title={
              <div className={classes.tooltip}>
                {hiddenParentTeams.map((teamName, i) => (
                  <div
                    key={teamName}
                    className={classes.tooltipContainer}
                  >
                    {i !== 0 && (
                      <div className={classes.divider}>
                        <IconChevronRight size={16} />
                      </div>
                    )}

                    <Typography
                      className={classes.title}
                      variant={Typography.Variant.BODY_BASE_MEDIUM}
                    >
                      {teamName}
                    </Typography>
                  </div>
                ))}
              </div>
            }
          >
            <div
              aria-label={formatMessage({ defaultMessage: 'View team hierarchy' })}
              className={classes.title}
            >
              <IconDots size={16} />
            </div>
          </LightTooltip>
        </div>
      )}
      {teamsName.length > 1 && (
        <Fragment>
          <div className={classes.divider}>
            <IconChevronRight size={16} />
          </div>

          <Typography
            className={classes.title}
            variant={Typography.Variant.BODY_BASE_MEDIUM}
          >
            {lastParentTeam}
          </Typography>
        </Fragment>
      )}
    </div>
  );
});
