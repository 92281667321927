import { css } from '@emotion/react';
import { memo } from 'react';

import { HighlightedKpiIdentifier } from '@amalia/payout-definition/plans/types';

export type StatementKpiDotProps = {
  readonly kpiId: HighlightedKpiIdentifier;
  readonly isForecast?: boolean;
};

export const StatementKpiDot = memo(function StatementKpiDot({ kpiId, isForecast = false }: StatementKpiDotProps) {
  return (
    <div
      css={[
        (theme) => css`
          display: block;
          content: '';
          width: 6px;
          height: 6px;
          border-radius: ${theme.ds.borderRadiuses.round};
        `,
        kpiId === HighlightedKpiIdentifier.PRIMARY &&
          !isForecast &&
          ((theme) => css`
            background-color: ${theme.ds.colors.primary[400]};
          `),
        kpiId === HighlightedKpiIdentifier.PRIMARY &&
          isForecast &&
          ((theme) => css`
            background-color: ${theme.ds.hues.purple[500]};
          `),
        kpiId === HighlightedKpiIdentifier.SECONDARY &&
          !isForecast &&
          ((theme) => css`
            background-color: ${theme.ds.colors.primary[200]};
          `),
        kpiId === HighlightedKpiIdentifier.SECONDARY &&
          isForecast &&
          ((theme) => css`
            background-color: ${theme.ds.hues.purple[200]};
          `),
      ]}
    />
  );
});
