import { IconPlus } from '@tabler/icons-react';
import { memo, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@amalia/design-system/components';

import { RowsTableContext } from './RowsTableContext';

export const RowsTableAddRecordButton = memo(function RowsTableAddRecordButton() {
  const { toggleAddRecordModal } = useContext(RowsTableContext);

  return (
    <Button
      icon={<IconPlus />}
      variant={Button.Variant.PRIMARY}
      onClick={toggleAddRecordModal}
    >
      <FormattedMessage defaultMessage="Add record" />
    </Button>
  );
});
