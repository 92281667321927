import { type Theme, css } from '@emotion/react';

import { checkboxFormFieldType } from '../../../data-input/checkbox/Checkbox.constants';
import { radioButtonGroupFormFieldType } from '../../../data-input/radio-button-group/RadioButtonGroup.constants';
import { switchFormFieldType } from '../../../data-input/switch/Switch.constants';
import { FormLayoutSize } from '../FormLayout.types';

export const formLayoutGroupTitle = (theme: Theme) => css`
  transition: ${theme.ds.transitions.default()};

  [data-form-layout-size=${FormLayoutSize.SMALL}] & {
    ${theme.ds.typographies.bodySmallBold};
  }

  [data-form-layout-size=${FormLayoutSize.MEDIUM}] & {
    ${theme.ds.typographies.bodyLargeBold};
  }
`;

export const formLayoutGroupDescription = (theme: Theme) => css`
  transition: ${theme.ds.transitions.default()};

  [data-form-layout-size=${FormLayoutSize.SMALL}] & {
    ${theme.ds.typographies.bodyXsmallRegular};
  }

  [data-form-layout-size=${FormLayoutSize.MEDIUM}] & {
    ${theme.ds.typographies.bodyBaseRegular};
  }
`;

export const formLayoutGroup = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  transition: ${theme.ds.transitions.default('gap')};
  width: 100%;

  [data-form-layout-size=${FormLayoutSize.SMALL}] & {
    gap: 24px;
  }

  [data-form-layout-size=${FormLayoutSize.MEDIUM}] & {
    gap: 32px;
  }
`;

export const formLayoutGroupHeader = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  transition: ${theme.ds.transitions.default('gap')};

  [data-form-layout-size=${FormLayoutSize.SMALL}] & {
    gap: 4px;
  }

  [data-form-layout-size=${FormLayoutSize.MEDIUM}] & {
    gap: 6px;
  }
`;

export const formLayoutGroupFields = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  transition: ${theme.ds.transitions.default('gap')};

  [data-form-layout-size=${FormLayoutSize.SMALL}] & {
    gap: 16px;

    >[data-form-field-type=${checkboxFormFieldType}],
    >[data-form-field-type=${switchFormFieldType}] {
      margin: 6px 0;
    }

    >[data-form-field-type=${radioButtonGroupFormFieldType}] {
      margin-bottom: 6px;
    }
  }

  [data-form-layout-size=${FormLayoutSize.MEDIUM}] & {
    gap: 24px;

    >[data-form-field-type=${checkboxFormFieldType}],
    >[data-form-field-type=${switchFormFieldType}] {
      margin: 8px 0;
    }

    >[data-form-field-type=${radioButtonGroupFormFieldType}] {
      margin-bottom: 8px;
    }
  }
`;
