import { type Theme, css } from '@emotion/react';
import styled from '@emotion/styled';

export const DropdownItemContainer = styled.div`
  & + & {
    margin-top: 1px;
  }
`;

export const dropdown = css`
  display: flex;
  flex-direction: column;
  position: relative;

  width: 240px;
  max-height: 240px;

  /* This min-width is here because we can override the width. */
  min-width: 240px;
`;

export const dropdownBody = css`
  --body-padding: 6px;
  flex: 1;
  padding: var(--body-padding);
  overflow-y: auto;
  position: relative;
`;

export const dropdownAction = (theme: Theme) => css`
  border-top: 1px solid ${theme.ds.colors.gray[100]};
`;

export const noOptions = css`
  padding: 0 8px;

  &:only-child {
    padding: 6px 8px;
  }
`;
