import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IconChevronRight } from '@tabler/icons-react';
import { Fragment, memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { getTokenColor } from '@amalia/amalia-lang/tokens/components';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { Divider, Tooltip } from '@amalia/design-system/components';

import {
  type FilterOverwriteRemoveCreationRequestDetails,
  type OverwriteCreationRequestDetails,
} from '../overwrite.types';
import { OVERWRITE_CONTEXT } from '../OverwriteModal';

type OverwriteRecapInfosProps = {
  readonly overwriteContext: string;
  readonly currentObjectDetails?: OverwriteCreationRequestDetails;
  readonly currentFilterOverwriteRemoveObjectDetails?: FilterOverwriteRemoveCreationRequestDetails;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const Item = styled.div`
  ${({ theme }) => theme.ds.typographies.bodyBaseRegular};

  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.ds.borderRadiuses.squared};
  background-color: ${({ theme }) => theme.ds.colors.gray[50]};
  color: ${({ theme }) => theme.ds.colors.gray[900]};
`;

const ObjectItem = styled(Item)`
  background-color: ${({ theme }) => getTokenColor(TokenType.OBJECT, theme)};
`;

export const OverwriteRecapInfos = memo(function OverwriteRecapInfos({
  overwriteContext,
  currentObjectDetails,
  currentFilterOverwriteRemoveObjectDetails,
}: OverwriteRecapInfosProps) {
  const onRowsTableContext = useMemo(
    () => (
      <Container>
        <Tooltip content={<FormattedMessage defaultMessage="Rule" />}>
          <Item>{currentObjectDetails?.rule}</Item>
        </Tooltip>

        <IconChevronRight size={16} />

        <Tooltip content={<FormattedMessage defaultMessage="Opportunity" />}>
          <Item>{currentObjectDetails?.opportunityObject}</Item>
        </Tooltip>

        <IconChevronRight size={16} />

        <Tooltip
          content={
            <FormattedMessage
              defaultMessage="Record ID"
              description="Row ID"
            />
          }
        >
          <Item>{currentObjectDetails?.rowNameOrId}</Item>
        </Tooltip>

        <IconChevronRight size={16} />

        <Tooltip content={<FormattedMessage defaultMessage="Field" />}>
          <ObjectItem>{currentObjectDetails?.fieldName}</ObjectItem>
        </Tooltip>
      </Container>
    ),
    [currentObjectDetails],
  );

  const onFilterRowRemoveContext = useMemo(
    () => (
      <Container>
        <Tooltip content={<FormattedMessage defaultMessage="Rule" />}>
          <Item>{currentFilterOverwriteRemoveObjectDetails?.rule}</Item>
        </Tooltip>

        <IconChevronRight size={16} />

        <Tooltip
          content={
            <FormattedMessage
              defaultMessage="Record ID"
              description="Row ID"
            />
          }
        >
          <ObjectItem>{currentFilterOverwriteRemoveObjectDetails?.rowNameOrId}</ObjectItem>
        </Tooltip>
      </Container>
    ),
    [currentFilterOverwriteRemoveObjectDetails],
  );

  const onKPIContext = useMemo(
    () => (
      <Container>
        <Tooltip content={<FormattedMessage defaultMessage="Rule" />}>
          <Item>{currentObjectDetails?.rule}</Item>
        </Tooltip>

        <IconChevronRight size={16} />

        <Tooltip content={<FormattedMessage defaultMessage="Field" />}>
          <ObjectItem>{currentObjectDetails?.field}</ObjectItem>
        </Tooltip>
      </Container>
    ),
    [currentObjectDetails],
  );

  const onDataSourceContext = useMemo(
    () => (
      <Container>
        <Tooltip content={<FormattedMessage defaultMessage="Opportunity" />}>
          <Item>{currentObjectDetails?.opportunityObject}</Item>
        </Tooltip>

        <IconChevronRight size={16} />

        <Tooltip
          content={
            <FormattedMessage
              defaultMessage="Record ID"
              description="Row ID"
            />
          }
        >
          <Item>{currentObjectDetails?.rowNameOrId}</Item>
        </Tooltip>

        <IconChevronRight size={16} />

        <Tooltip content={<FormattedMessage defaultMessage="Field" />}>
          <ObjectItem>{currentObjectDetails?.fieldName}</ObjectItem>
        </Tooltip>
      </Container>
    ),
    [currentObjectDetails],
  );

  return (
    <Fragment>
      {overwriteContext === OVERWRITE_CONTEXT.DATA && onDataSourceContext}
      {overwriteContext === OVERWRITE_CONTEXT.KPI && onKPIContext}
      {overwriteContext === OVERWRITE_CONTEXT.ROWTABLE && onRowsTableContext}
      {overwriteContext === OVERWRITE_CONTEXT.FILTER_ROW_REMOVE && onFilterRowRemoveContext}

      <div
        css={css`
          margin: 20px 0;
        `}
      >
        <Divider.Horizontal />
      </div>
    </Fragment>
  );
});
