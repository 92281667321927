import { uniqBy } from 'lodash';
import { type MathNode } from 'mathjs';

import {
  type AmaliaFormula,
  type AmaliaFunctionCategory,
  type AmaliaFunctionKeys,
} from '@amalia/amalia-lang/formula/types';
import { type TokenType } from '@amalia/amalia-lang/tokens/types';
import { type FormatsEnum } from '@amalia/data-capture/fields/types';
import { type ComputedFunctionArgs } from '@amalia/payout-calculation/types';

import { type CalculationScope } from '../CalculationParser';

export type AmaliaFunctionReturnType = boolean | number | string | null;

export type AmaliaFunctionArgument = {
  name: string;
  description: string;
  defaultValue?: AmaliaFunctionReturnType;
  /**
   * Token types that are valid for this argument. If not provided, all token types are valid.
   */
  validTokenTypes?: TokenType[];

  /**
   * Token values per token types that are valid for this argument. Useful if you want to restrict the values of a token type.
   * Example: In sum function, second argument can be a function but only `IF` or `DEFAULT` functions are valid.
   */
  validTokenValues?: Partial<Record<TokenType, string[]>>;

  /**
   * Formats that are valid for this argument. If not provided, all formats are valid.
   */
  validFormats?: FormatsEnum[];
};

// FIXME: Should we can make it generic? (return type, category, args).
class AmaliaFunction {
  private static allFunctions: Record<string, AmaliaFunction> = {};

  public static readonly getAllFunctions = () => AmaliaFunction.allFunctions;

  public static readonly getFunctionsEnum = () =>
    Object.keys(AmaliaFunction.allFunctions).reduce<Record<string, string>>((acc, key) => {
      acc[key] = key;
      return acc;
    }, {});

  public static readonly getAllFunctionsArray = (): AmaliaFunction[] =>
    uniqBy(Object.values(AmaliaFunction.allFunctions), 'name');

  public name: AmaliaFunctionKeys;

  public nbParamsRequired?: number;

  public category: AmaliaFunctionCategory;

  public description?: string[] | string;

  public examples?: { desc?: string; formula: AmaliaFormula; result?: AmaliaFunctionReturnType }[];

  public params?: AmaliaFunctionArgument[];

  public hasInfiniteParams?: boolean;

  public hiddenFromLibrary?: boolean;

  // The function to call in order to execute the function.
  public exec?: (...args: any[]) => AmaliaFunctionReturnType;

  // The function to call if the function is rawArgs.
  public execRawArgs?: (args: any[], mathf: any, scope: CalculationScope) => AmaliaFunctionReturnType;

  // Mock the evaluation in formula validation, useful for ComputedFunctionResults for instance.
  public execMock?: (...args: any[]) => AmaliaFunctionReturnType;

  /**
   * Given the args of the current context, generates the ComputedFunctionResult skeleton
   * for this function.
   */
  public generateComputedFunctionResult?: (args: MathNode[]) => ComputedFunctionArgs;

  /**
   * For some functions, avoid classic parsing on some parameters by ignoring them.
   *
   * It usually means that they should be parsed in another context (for instance a dataset or a different period).
   */
  public parametersToEscapeOnParse?: number[];

  // Using an arrow function so the `this` refers to the object at any time.
  public readonly callFunction = (...args: any[]) => {
    if (this.nbParamsRequired !== undefined) {
      for (let i = 0; i < this.nbParamsRequired; i++) {
        if (args[i] === undefined || args[i] === null) {
          throw new Error(`${this.name} is missing parameter at position ${i + 1} or its value is undefined`);
        }
      }
    }

    if (this.params?.length && args.length > this.params.length) {
      throw new Error(`Too many parameters for function ${this.name}`);
    }

    if (!this.exec) {
      throw new Error(`Exec function not provided for ${this.name}`);
    }

    return this.exec(...args);
  };

  public constructor(name: AmaliaFunctionKeys, category: AmaliaFunctionCategory, hiddenFromLibrary: boolean = false) {
    this.name = name;
    this.category = category;
    this.hiddenFromLibrary = hiddenFromLibrary;

    // Register this function into the global enum.
    AmaliaFunction.allFunctions[name] = this;
  }
}

export default AmaliaFunction;
