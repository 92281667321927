import { memo, useCallback } from 'react';

import { Dropdown, Switch, type SwitchProps } from '@amalia/design-system/components';
import { useUniqueId } from '@amalia/ext/react/hooks';

import { type KpiOption } from '../../useKPIDisplay';

export type KpiDisplayDropdownItemProps = {
  readonly value: KpiOption['value'];
  readonly label: KpiOption['label'];
  readonly checked: boolean;
  readonly onChange: (value: KpiOption['value'], checked: boolean) => void;
};

export const KpiDisplayDropdownItem = memo(function KpiDisplayDropdownItem({
  value,
  label,
  checked,
  onChange,
}: KpiDisplayDropdownItemProps) {
  const switchLabelId = useUniqueId({ prefix: 'kpi-display-dropdown-item-switch-label' });

  const handleChange: Required<SwitchProps>['onChange'] = useCallback(
    (checked) => onChange(value, checked),
    [onChange, value],
  );

  return (
    <Dropdown.ItemContainer as="li">
      <Dropdown.ItemContent
        label={<label htmlFor={switchLabelId}>{label}</label>}
        rightActions={
          <Switch
            checked={checked}
            id={switchLabelId}
            size={Switch.Size.SMALL}
            onChange={handleChange}
          />
        }
      />
    </Dropdown.ItemContainer>
  );
});
