import styled from '@emotion/styled';
import { forwardRef, memo, useMemo } from 'react';

import { UserPrettyFormat, type UserPrettyFormatProps } from '@amalia/data-capture/fields/components';
import { ColorCategory, HueCategory } from '@amalia/design-system/meta';
import { useCurrentUser } from '@amalia/kernel/auth/state';
import { OmolioMessageType, type OmolioMessage } from '@amalia/omolio/types';

const OmolioLogoBase64 =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzY1IiBoZWlnaHQ9IjM4NyIgdmlld0JveD0iMCAwIDM2NSAzODciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjAwMTIyMDcgMjAxLjY0NFYzNTkuMzQ4QzAuMDAxMjIwNyAzNjkuMjU2IDEyLjExMTEgMzc0LjA3IDE4LjkxMyAzNjYuODY2TDE1NS4xMjYgMjIyLjYwMkw2My41MTQ4IDEyNS45Mkw2LjAwODQyIDE4Ni41NzdDMi4xNTEyNSAxOTAuNjQ1IDAuMDAxMjIwNyAxOTYuMDM4IDAuMDAxMjIwNyAyMDEuNjQ0WiIgZmlsbD0iIzBFMzhBNiIvPgo8cGF0aCBkPSJNMzY0Ljk5OSAyMDEuMjYyVjM1OS4zNjRDMzY0Ljk5OSAzNjkuMjcgMzUyLjg5NCAzNzQuMDg0IDM0Ni4wOSAzNjYuODg1TDE4Mi40ODQgMTkzLjc1OEwyNzQuMTY4IDk2LjcxODNMMzU4Ljk5MiAxODYuMTk1QzM2Mi44NDkgMTkwLjI2NCAzNjQuOTk5IDE5NS42NTYgMzY0Ljk5OSAyMDEuMjYyWiIgZmlsbD0iIzJGQkZFRSIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTkwLjg1MzggOTYuNzc0NEwxNzcuNTE0IDUuMzQ3NDhDMTgwLjI4OCAyLjQyMTQgMTg0Ljc4NSAyLjQyMTQxIDE4Ny41NTggNS4zNDc0OEwyNzQuMjE5IDk2Ljc3NDRMMjc0LjIxNiA5Ni43NzdIOTAuODU2Mkw5MC44NTM4IDk2Ljc3NDRaTTkxLjE2NjYgOTcuMDkwOEw5MS40MzY4IDk2LjgwNEgyNzQuMjQ1TDI3NC4yNTMgOTYuODEyTDE4Mi41NyAxOTMuODQ3TDkxLjE2NjYgOTcuMDkwOFoiIGZpbGw9IiM5OEU2RkYiLz4KPC9zdmc+Cg==';

const AvatarMessageContainer = styled.div<{ isOmolioAnswer: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isOmolioAnswer }) => (isOmolioAnswer ? 'flex-start' : 'flex-end')};
  gap: 8px;
`;

const AvatarContainer = styled.div<{ isOmolioAnswer: boolean }>`
  width: fit-content;
`;

const MessageContainer = styled.div<{ isOmolioAnswer: boolean }>`
  border-radius: ${({ theme }) => theme.ds.borderRadiuses.squared};
  background-color: ${({ theme, isOmolioAnswer }) =>
    isOmolioAnswer ? theme.ds.hues[HueCategory.BLUE][100] : theme.ds.colors[ColorCategory.GRAY][100]};
  color: black;
  padding: 4px 8px;
  max-width: 650px;

  ul,
  ol {
    list-style-position: inside;
    padding-left: 8px;
  }
`;

interface OmolioPromptMessageProps {
  readonly message: OmolioMessage;
}

const OmolioPromptMessageBase = forwardRef(function OmolioPromptMessage({ message }: OmolioPromptMessageProps) {
  const { data: user } = useCurrentUser();
  const isOmolioAnswer = message.type === OmolioMessageType.answer;

  const userInfos: Pick<UserPrettyFormatProps, 'firstName' | 'isLoading' | 'lastName' | 'pictureURL'> = useMemo(() => {
    if (isOmolioAnswer) {
      return {
        firstName: 'Omolio',
        lastName: 'AI Assistant',
        pictureURL: OmolioLogoBase64,
        isLoading: false,
      };
    }

    return {
      firstName: user.firstName,
      lastName: user.lastName,
      pictureURL: user.pictureURL,
      isLoading: false,
    };
  }, [isOmolioAnswer, user]);

  return (
    <AvatarMessageContainer isOmolioAnswer={isOmolioAnswer}>
      <AvatarContainer isOmolioAnswer={isOmolioAnswer}>
        <UserPrettyFormat {...userInfos} />
      </AvatarContainer>
      {isOmolioAnswer ? (
        <MessageContainer
          isOmolioAnswer
          dangerouslySetInnerHTML={{
            __html: message.content,
          }}
        />
      ) : (
        <MessageContainer isOmolioAnswer={false}>{message.content}</MessageContainer>
      )}
    </AvatarMessageContainer>
  );
});

export const OmolioPromptMessage = memo(OmolioPromptMessageBase);
