import { css } from '@emotion/react';
import { makeStyles } from '@mui/styles';
import { IconCaretDownFilled, IconCaretUpFilled } from '@tabler/icons-react';
import { memo, useCallback } from 'react';
import { FormattedList, FormattedMessage } from 'react-intl';

import { formatUserFullName } from '@amalia/core/types';
import { Group, IconAction } from '@amalia/design-system/components';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';
import { assert } from '@amalia/ext/typescript';
import { type UserComputed } from '@amalia/tenants/users/types';

import { Avatar } from '../../../Avatar';
import { AvatarGroup } from '../../../AvatarGroup';
import { UserSelect, type UserSelectProps } from '../UserSelect/UserSelect';

const useUserSelectAvatarsStyles = makeStyles((theme: AmaliaThemeType) => ({
  renderedName: {
    margin: theme.spacing(0, 1),
    color: theme.palette.secondary.main,
  },
}));

type UserSelectAvatarsProps = UserSelectProps & {
  readonly doRenderName?: boolean;
};

export const UserSelectAvatars = memo(function UserSelectAvatars({ doRenderName, ...props }: UserSelectAvatarsProps) {
  const classes = useUserSelectAvatarsStyles();

  const { multiple, users } = props;

  const renderAvatars = useCallback(
    (selectedUsers: UserComputed | UserComputed[] | null) => {
      if (multiple && Array.isArray(selectedUsers)) {
        return (
          <AvatarGroup
            max={3}
            // If no user is currently selected, show all of them.
            users={(selectedUsers?.length ? selectedUsers : users) || []}
          />
        );
      }
      if (multiple && !selectedUsers) {
        return (
          <AvatarGroup
            max={3}
            // If no user is currently selected, show all of them.
            users={users || []}
          />
        );
      }
      assert(!Array.isArray(selectedUsers), 'UserSelect: multiple is false but value is an array');

      return selectedUsers ? <Avatar user={selectedUsers} /> : <FormattedMessage defaultMessage="No user selected" />;
    },
    [multiple, users],
  );

  const renderName = useCallback(
    (value: UserComputed | UserComputed[] | null) => {
      const name =
        multiple && Array.isArray(value) ? (
          <FormattedList
            style="short"
            type="unit"
            value={value.map(formatUserFullName)}
          />
        ) : value && !Array.isArray(value) ? (
          formatUserFullName(value)
        ) : (
          ''
        );

      return <div className={classes.renderedName}>{name}</div>;
    },
    [classes, multiple],
  );

  const renderDeployButton = useCallback(
    (onClick: VoidFunction, open: boolean) => (
      <div
        css={
          !doRenderName &&
          css`
            position: absolute;
            right: -5px;
            bottom: -5px;
          `
        }
      >
        <IconAction
          icon={open ? <IconCaretUpFilled /> : <IconCaretDownFilled />}
          label={<FormattedMessage defaultMessage="Filter users" />}
          onClick={onClick}
        />
      </div>
    ),
    [doRenderName],
  );

  return (
    <UserSelect {...props}>
      {({ onClick, value, open }) => (
        <Group align="center">
          <Group
            align="center"
            onClick={onClick}
          >
            {renderAvatars(value)}
            {doRenderName ? renderName(value) : null}
          </Group>
          {renderDeployButton(onClick, open)}
        </Group>
      )}
    </UserSelect>
  );
});
