import { type ForwardedRef, forwardRef, memo } from 'react';

import { IconButton, type IconButtonProps } from '../../../general/icon-button/IconButton';

export type PopoverHeaderActionProps = Omit<IconButtonProps, 'size'>;

const PopoverHeaderActionForwardRef = forwardRef(function PopoverHeaderAction(
  props: PopoverHeaderActionProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <IconButton
      {...props}
      ref={ref}
      size={IconButton.Size.SMALL}
    />
  );
});

export const PopoverHeaderAction = memo(PopoverHeaderActionForwardRef);
