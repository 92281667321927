import { css, useTheme } from '@emotion/react';
import { IconRadar } from '@tabler/icons-react';

import { type TablerIconProps } from '@amalia/design-system/components';

export type ForecastIconProps = Omit<TablerIconProps, 'color'>;

export const ForecastIcon = function ForecastIcon({ ...props }: ForecastIconProps) {
  const theme = useTheme();

  return (
    <IconRadar
      {...props}
      color={theme.ds.hues.purple[400]}
      css={css`
        flex: none;
      `}
    />
  );
};
