import { type UserInfo } from '@amalia/tenants/users/profile/types';
import { UserExternalIdSource, UserHrisIdSource } from '@amalia/tenants/users/types';

export type IsIntegrationIdFieldDisabledParameters = {
  name: 'externalId' | 'hrisId';
  userInfo: Pick<UserInfo, 'externalId' | 'externalIdSource' | 'hrisId' | 'hrisIdSource'>;
};

export const isIntegrationIdFieldDisabled = ({ name, userInfo }: IsIntegrationIdFieldDisabledParameters) => {
  switch (name) {
    case 'externalId':
      return userInfo.externalIdSource === UserExternalIdSource.NONE;
    case 'hrisId':
      return userInfo.hrisIdSource === UserHrisIdSource.NONE;
    default:
      throw Error(`Unknown integration id field name: ${name}`);
  }
};
