import { type ElementType, memo } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { type TablerIconProps } from '../icons/types';

import * as styles from './IconOverlap.styles';

export type IconOverlapProps = MergeAll<
  [
    TablerIconProps,
    {
      /** Icon on the background that'll be overlaped. */
      iconBackground: ElementType<TablerIconProps>;
      /** Icon on the foreground. */
      iconForeground: ElementType<TablerIconProps>;
    },
  ]
>;

const DEFAULT_ICON_SIZE = 24;

export const IconOverlap = memo(function IconOverlap({
  iconBackground: IconBackground,
  iconForeground: IconForeground,
  ...props
}: IconOverlapProps) {
  return (
    <div css={styles.overlapContainer(props.size || props.width || DEFAULT_ICON_SIZE)}>
      <IconBackground {...props} />
      <IconForeground {...props} />
    </div>
  );
});
