import {
  ListItem,
  ListItemAvatar,
  type ListItemAvatarProps,
  ListItemText,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { memo, useCallback } from 'react';

import { Checkbox } from '@amalia/design-system/components';
import { type UserComputed } from '@amalia/tenants/users/types';

import { Avatar } from '../../../Avatar';
import { useUserSelectStyles } from '../common';

const UserListItemAvatar = withStyles(() => ({
  root: {
    minWidth: 0,
  },
}))((props: ListItemAvatarProps) => <ListItemAvatar {...props} />);

export interface UserListItemProps<TUser extends UserComputed = UserComputed> {
  /** User to display. */
  readonly user: TUser;
  /** Callback when user is selected. */
  readonly onChange: (user: TUser) => void;
  /** Selected user(s). If multiple, value is an array. */
  readonly value: TUser[] | null;
  /** If true, allow multiple selection. */
  readonly multiple?: boolean;
  /** If true, display avatars in the list. */
  readonly withAvatarsInList?: boolean;
}

const UserListItemBase = function UserListItem<TUser extends UserComputed = UserComputed>({
  user,
  onChange,
  value,
  multiple,
  withAvatarsInList,
}: UserListItemProps<TUser>) {
  const classes = useUserSelectStyles({ multiple });

  const checked = !!value?.some((elm) => elm.id === user.id);

  const handleChange = useCallback(() => {
    onChange(user);
  }, [onChange, user]);

  return (
    <ListItem
      disablePadding
      component="li"
    >
      <ListItemButton
        dense
        onClick={handleChange}
      >
        {!!multiple && (
          <ListItemIcon>
            <Checkbox
              checked={checked}
              data-testid={`selectUser_${user.id}`}
            />
          </ListItemIcon>
        )}

        <ListItemText
          id={`selectUser_${user.id}`}
          primary={`${user.firstName} ${user.lastName}`}
        />

        {!!withAvatarsInList && (
          <UserListItemAvatar>
            <Avatar
              className={classes.avatarList}
              user={user}
            />
          </UserListItemAvatar>
        )}
      </ListItemButton>
    </ListItem>
  );
};

export const UserListItem = memo(UserListItemBase) as typeof UserListItemBase;
