import { type ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { assert, toError } from '@amalia/ext/typescript';
import { useUploadAvatar } from '@amalia/tenants/users/profile/state';

// 5Mo
const MAX_UPLOAD_SIZE = 5_242_880;

/**
 * Hook to handle AvatarEditor component logic.
 */
export const useAvatarEditor = () => {
  const { formatMessage } = useIntl();
  const { snackError } = useSnackbars();

  // We need to await its completion before closing the modal, so we use mutateAsync instead of mutate
  const { mutateAsync: uploadAvatarBase64, error: uploadAvatarBase64Error, isPending } = useUploadAvatar();

  const [avatarBase64, setAvatarBase64] = useState<string | null>(null);
  const [businessError, setBusinessError] = useState<string | null>(null);

  /**
   * Error returned by the API or by the business logic (image too large, file not supported).
   *
   * If there is no error, it returns null.
   */
  const error = useMemo(
    () => businessError || (!!uploadAvatarBase64Error && toError(uploadAvatarBase64Error).message) || null,
    [businessError, uploadAvatarBase64Error],
  );

  const checkFileValidity = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      let error: string | null = null;

      if (!file?.type.startsWith('image')) {
        event.target.value = '';
        error = formatMessage({
          defaultMessage:
            'This file is not supported. Please choose a picture with the following extensions "jpg", "jpeg", "png", "svg"',
        });
      }

      if (file?.size && file.size > MAX_UPLOAD_SIZE) {
        event.target.value = '';
        error = formatMessage({
          defaultMessage: 'Impossible to load the picture. Please choose a picture smaller than 5MB.',
        });
      }

      setBusinessError(error);

      if (error) {
        snackError(error);
      }
    },
    [formatMessage, snackError],
  );

  const submitAvatar = useCallback(async () => {
    assert(
      avatarBase64,
      formatMessage({
        defaultMessage: 'An avatar must be provided',
      }),
    );
    return uploadAvatarBase64(avatarBase64);
  }, [avatarBase64, formatMessage, uploadAvatarBase64]);

  const updateAvatarPreview = useCallback((imageDataBase64: string) => {
    setAvatarBase64(imageDataBase64);
  }, []);

  return useMemo(
    () => ({
      avatarBase64,
      checkFileValidity,
      error,
      isPending,
      submitAvatar,
      updateAvatarPreview,
    }),
    [avatarBase64, checkFileValidity, error, isPending, submitAvatar, updateAvatarPreview],
  );
};
