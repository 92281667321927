import { css } from '@emotion/react';
import { IconHelp } from '@tabler/icons-react';
import { Form } from 'formik';
import { memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import { type Statement, formatUserFullName } from '@amalia/core/types';
import { AlertBanner, Modal, Tooltip, type RadioButtonOptionShape } from '@amalia/design-system/components';
import { FormikForm } from '@amalia/ext/formik';
import { type Period } from '@amalia/payout-definition/periods/types';
import { type PlansMap } from '@amalia/payout-definition/plans/types';
import { type DataExport } from '@amalia/reporting/exports/shared';
import { type TeamMap } from '@amalia/tenants/teams/types';
import { type UsersMap } from '@amalia/tenants/users/types';

import { type ExportFormEventChange, type StatementExportModalContainerProps } from './statementExportModal.types';
import { StatementExportModalForm } from './StatementExportModalForm';

interface StatementExportModalPresentationProps extends Omit<StatementExportModalContainerProps, 'handleConfirm'> {
  readonly handleSubmit: (formValues: Partial<DataExport>) => void;
  readonly defaultValues?: Partial<DataExport>;
  readonly teamsMap: TeamMap;
  readonly plansMap: PlansMap;
  readonly usersMap: UsersMap;
  readonly periodList: Period[];
  readonly onFormChangeHandler: (event: ExportFormEventChange) => void;
  readonly isPlansLoading: boolean;
  readonly isTeamsLoading: boolean;
  readonly isUsersLoading: boolean;
  readonly isPeriodsLoading: boolean;
  readonly exportFormats: RadioButtonOptionShape<DataExport['format'], boolean>[];
  readonly statementsInError: Statement[];
}

export const StatementExportModalPresentation = memo(function StatementExportModalPresentation({
  isOpened,
  handleClose,
  handleSubmit,
  defaultValues,
  teamsMap,
  plansMap,
  usersMap,
  periodList,
  onFormChangeHandler,
  isPeriodsLoading,
  isTeamsLoading,
  isPlansLoading,
  isUsersLoading,
  exportFormats,
  statementsInError,
}: StatementExportModalPresentationProps) {
  const { formatMessage } = useIntl();
  const schema = useMemo(
    () =>
      Yup.object().shape({
        params: Yup.object({
          periodIds: Yup.array().min(1, formatMessage({ defaultMessage: 'Please select at least one period' })),
          teamIds: Yup.array(),
          planIds: Yup.array(),
          userIds: Yup.array(),
        }),
        type: Yup.string(),
      }),
    [formatMessage],
  );
  return (
    <Modal
      isOpen={isOpened}
      size={Modal.Size.LARGE}
      onClose={handleClose}
    >
      <FormikForm
        initialValues={defaultValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, isValid }) => (
          <Form
            css={css`
              display: contents;
            `}
          >
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>
                  <FormattedMessage defaultMessage="Export a statement" />
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <StatementExportModalForm
                  exportFormats={exportFormats}
                  isPeriodsLoading={isPeriodsLoading}
                  isPlansLoading={isPlansLoading}
                  isTeamsLoading={isTeamsLoading}
                  isUsersLoading={isUsersLoading}
                  periodList={periodList}
                  plansMap={plansMap}
                  setFieldValue={setFieldValue}
                  teamsMap={teamsMap}
                  usersMap={usersMap}
                  values={values}
                  onFormChangeHandler={onFormChangeHandler}
                />

                {statementsInError.length > 0 && (
                  <div
                    css={css`
                      margin-top: 16px;
                    `}
                  >
                    <AlertBanner variant={AlertBanner.Variant.WARNING}>
                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                          gap: 8px;
                        `}
                      >
                        <FormattedMessage defaultMessage="Some statements are in error and will not be included." />
                        <Tooltip
                          content={
                            <table>
                              <tbody>
                                {statementsInError.map((statement) => (
                                  <tr key={`${statement.id}-${statement.plan.name}-${statement.user.id}`}>
                                    <td>{formatUserFullName(statement.user)}</td>
                                    <td
                                      css={css`
                                        padding: 0 12px;
                                      `}
                                    >
                                      {statement.plan.name}
                                    </td>
                                    <td>{statement.period.name}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          }
                          css={css`
                            max-height: 400px;
                            overflow-y: auto;
                          `}
                        >
                          <IconHelp
                            size={16}
                            css={css`
                              display: inline-flex;
                            `}
                          />
                        </Tooltip>
                      </div>
                    </AlertBanner>
                  </div>
                )}
              </Modal.Body>
            </Modal.Content>

            <Modal.Actions>
              <Modal.CancelAction />

              <Modal.MainAction
                disabled={!isValid}
                type="submit"
              >
                <FormattedMessage defaultMessage="Make the export" />
              </Modal.MainAction>
            </Modal.Actions>
          </Form>
        )}
      </FormikForm>
    </Modal>
  );
});
