import { type Theme, css } from '@emotion/react';
import { IconX } from '@tabler/icons-react';
import { memo, type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IconButton, Switch, Typography } from '@amalia/design-system/components';
import { type MessageContent } from '@amalia/payout-collaboration/comments/types';

import { CommentScope } from './CommentScope';
import { CommentFormPresentation } from './form/CommentFormPresentation';
import { type CommentScopeInterface } from './useCommentScope';

const rootContainer = (theme: Theme) => css`
  background: ${theme.ds.colors.gray[0]};
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
`;

const header = (theme: Theme) => css`
  border-bottom: 1px solid ${theme.ds.colors.gray[100]};
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  padding: 64px 16px 16px 24px;
`;

const titleContainer = css`
  display: flex;
  padding: 4px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const commentList = css`
  flex: 1;
  overflow-y: auto;
`;

type CommentDrawerPresentationProps = {
  readonly closeOpenedThread: () => void;
  readonly children: ReactNode;
  readonly handleNewComment: (values: MessageContent[]) => Promise<void> | void;
  readonly isReviewed: boolean;
  readonly setIsReviewed: (status: boolean) => void;
  // Object that take `title: value` to be printed
  readonly commentScope?: CommentScopeInterface;
  readonly canReviewThread: boolean;
  readonly canAddComment: boolean;
};

// Presentation layer of the comment drawer
export const CommentDrawerPresentation = memo(function CommentDrawerPresentation({
  children,
  closeOpenedThread,
  handleNewComment,
  isReviewed,
  setIsReviewed,
  commentScope,
  canReviewThread,
  canAddComment,
}: CommentDrawerPresentationProps) {
  const { formatMessage } = useIntl();

  return (
    <div css={rootContainer}>
      <div>
        <div css={header}>
          <div css={titleContainer}>
            <Typography variant={Typography.Variant.BODY_LARGE_BOLD}>
              <FormattedMessage defaultMessage="Comments" />
            </Typography>
            <IconButton
              icon={<IconX />}
              label={formatMessage({ defaultMessage: 'Close comments drawer' })}
              onClick={closeOpenedThread}
            />
          </div>

          {!!commentScope && <CommentScope commentScope={commentScope} />}

          <Switch
            checked={isReviewed}
            disabled={!canReviewThread}
            label={formatMessage({ defaultMessage: 'Reviewed' })}
            size={Switch.Size.SMALL}
            onChange={setIsReviewed}
          />
        </div>
      </div>

      {/* Shows children (thread messages) */}
      <div
        css={commentList}
        id="commentList"
      >
        {children}
      </div>

      {/* New message form */}
      {!!canAddComment && <CommentFormPresentation onSubmit={handleNewComment} />}
    </div>
  );
});
