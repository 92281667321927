import { css } from '@emotion/react';
import { type ForwardedRef, forwardRef } from 'react';

import { Link, type LinkProps } from '@amalia/ext/react-router-dom';

export type TextLinkProps = LinkProps;

export const TextLink = forwardRef(function TextLink(
  { children, ...props }: TextLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  return (
    <Link
      {...props}
      ref={ref}
      css={(theme) => css`
        &:not([data-disabled]) {
          text-decoration: none;

          transition: ${theme.ds.transitions.default()};

          color: ${theme.ds.colors.primary[400]};
          outline: none;

          &:hover {
            color: ${theme.ds.colors.primary[500]};
          }

          &:focus-visible {
            text-decoration: underline;
          }
        }
      `}
    >
      {children}
    </Link>
  );
});
