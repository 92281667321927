import { type CSSProperties } from 'react';

import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { AMALIA_OBJECTS_HIGHLIGHT_COLOR } from '@amalia/ext/mui/theme';
import { type Relationship } from '@amalia/payout-definition/plans/types';

import { IFormulaNode, FormulaNodeEnum } from './IFormulaNode';

export class RelationshipFormulaNode extends IFormulaNode<Relationship> {
  public constructor(
    partialFormula: string,
    startIndex: number,
    nodeObject: Relationship,
    objectDefinition?: CustomObjectDefinition,
    relationship?: Relationship,
  ) {
    super(partialFormula, startIndex, FormulaNodeEnum.RELATIONSHIP, nodeObject, objectDefinition, relationship);
  }

  public getDesignerPath() {
    return this.nodeObject.id ? { type: TokenType.LINK, id: this.nodeObject.id } : null;
  }

  public getStyles(): CSSProperties {
    return { backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.link };
  }

  public getLabel(): string {
    return this.nodeObject.name;
  }
}
