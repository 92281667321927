import { useFormikContext } from 'formik';
import { Fragment, memo, type MutableRefObject, type ReactNode } from 'react';

import { type UserInfo } from '@amalia/tenants/users/profile/types';

import { isIntegrationIdFieldDisabled } from './isDisabled';
import { useFocusInput } from './useFocusInput';

export type ChildrenFunctionParams<TName extends 'externalId' | 'hrisId'> = {
  inputRef: MutableRefObject<HTMLInputElement | null>;
  focusInput: VoidFunction;
  isRequired: boolean;
  isDisabled: boolean;
  value: UserInfo[TName];
};

export type IntegrationFormGroupProps<TName extends 'externalId' | 'hrisId'> = {
  readonly name: TName;
  readonly children: ({ inputRef, focusInput, isDisabled }: ChildrenFunctionParams<TName>) => ReactNode;
};

export const IntegrationFormGroup = memo(function IntegrationFormGroup<TName extends 'externalId' | 'hrisId'>({
  name,
  children,
}: IntegrationFormGroupProps<TName>) {
  const { ref: inputRef, focusInput } = useFocusInput();

  const { values: userInfo } =
    useFormikContext<Pick<UserInfo, 'externalId' | 'externalIdSource' | 'hrisId' | 'hrisIdSource'>>();

  const value = userInfo[name];

  const isDisabled = isIntegrationIdFieldDisabled({
    name,
    userInfo,
  });

  const isRequired = !isDisabled;

  return (
    <Fragment>
      {children({
        inputRef,
        focusInput,
        value,
        isDisabled,
        isRequired,
      })}
    </Fragment>
  );
});
