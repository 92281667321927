import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { type ReactNode, useState, memo } from 'react';
import { registerLocale } from 'react-datepicker';
import useAsyncEffect from 'use-async-effect';

import { ReactIntlProvider } from '@amalia/ext/react-intl';
import { YupLocaleUpdater } from '@amalia/ext/yup';
import { intlConfig, type LocaleBundle } from '@amalia/kernel/intl/components';

import { AmaliaLocalizationContext } from './AmaliaLocalization.context';

const setHtmlLangAttribute = (locale: string) => {
  document.getElementsByTagName('html')[0].setAttribute('lang', locale);
};

export type AmaliaLocalizationProviderProps<TLocale extends string> = {
  readonly importLocale: (locale: TLocale) => Promise<LocaleBundle<TLocale>>;
  readonly locale?: TLocale;
  readonly children?: ReactNode;
};

const AmaliaLocalizationProviderBase = function AmaliaLocalizationProvider<TLocale extends string>({
  importLocale,
  locale = intlConfig.defaultLocale as TLocale,
  children,
}: AmaliaLocalizationProviderProps<TLocale>) {
  const [localeBundle, setLocaleBundle] = useState<LocaleBundle<TLocale> | undefined>();

  useAsyncEffect(async () => {
    const loadedLocaleBundle = await importLocale(locale);

    setLocaleBundle(loadedLocaleBundle);

    // Update document lang.
    setHtmlLangAttribute(locale);

    // Update moment locale.
    moment.locale(locale);

    // Register locale for react-datepicker.
    registerLocale(locale, loadedLocaleBundle.dateFnsLocale);
  }, [locale]);

  return (
    <AmaliaLocalizationContext.Provider value={localeBundle}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        dateLibInstance={moment}
      >
        <ReactIntlProvider
          locale={localeBundle?.locale || intlConfig.defaultLocale}
          messages={localeBundle?.messages}
        >
          <YupLocaleUpdater>{children}</YupLocaleUpdater>
        </ReactIntlProvider>
      </LocalizationProvider>
    </AmaliaLocalizationContext.Provider>
  );
};

export const AmaliaLocalizationProvider = memo(AmaliaLocalizationProviderBase) as typeof AmaliaLocalizationProviderBase;
