import { enqueueSnackbar } from 'notistack';

import { type ReduxAction } from '../types';

import { SNACKBAR_ACTIONS } from './constants';
import { type SnackbarsReducer } from './types';

export default (state: SnackbarsReducer = [], action: ReduxAction) => {
  switch (action.type) {
    case SNACKBAR_ACTIONS.ADD:
      enqueueSnackbar(action.payload.snackbar.message, action.payload.snackbar.options);
      return state;
    default:
      return state;
  }
};
