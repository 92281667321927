import { css } from '@emotion/react';

export const statementKpisTooltip = css`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const statementKpisTooltipRow = css`
  display: flex;
  flex-direction: column;
  gap: 8px;

  &:empty {
    display: none;
  }
`;
