import { css } from '@emotion/react';

export const otherStepPopover = css`
  padding: 6px 12px 16px;
`;

export const headerContainer = css`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 12px;
`;

export const switchContainer = css`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const form = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
