import { useMergeRefs } from '@floating-ui/react';
import { IconCalendar } from '@tabler/icons-react';
import { type ForwardedRef, forwardRef, useCallback } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { Input, type InputProps } from '../../input/Input';
import { type UseFormFieldPropsOptions } from '../../meta/form-field/hooks/useFormFieldProps';

export type DatePickerInputProps = MergeAll<
  [
    UseFormFieldPropsOptions,
    Pick<InputProps, 'readOnly' | 'size' | 'value'>,
    {
      /** Clear handler. */
      onClear?: () => void;
      /** Ref is overridden by react-datepicker so we need to pass it via another prop from DatePicker. */
      innerRef?: ForwardedRef<HTMLInputElement>;
      /** Input change only works with events. */
      onChange?: (event: { target: { value: string } }) => void;
      // Make the input readonly on ranges because we cannot edit it manually (https://github.com/Hacker0x01/react-datepicker/issues/3906).
      selectsRange?: boolean;
    },
  ]
>;

export const DatePickerInput = forwardRef(function DatePickerInput(
  {
    value,
    onClear,
    onChange,
    innerRef = undefined,
    readOnly = false,
    selectsRange = false,
    ...props
  }: DatePickerInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const inputRef = useMergeRefs([ref, innerRef]);

  const handleChange: Required<InputProps>['onChange'] = useCallback(
    (value) => onChange?.({ target: { value } }),
    [onChange],
  );

  return (
    <Input
      {...props}
      ref={inputRef}
      action={value ? <Input.ClearAction onClick={onClear} /> : undefined}
      readOnly={readOnly || selectsRange}
      rightIcon={<IconCalendar />}
      value={value}
      onChange={handleChange}
    />
  );
});
