import { type PaginatedResponse, type TodoDefinition } from '@amalia/core/types';
import { toError } from '@amalia/ext/typescript';

import * as TodosRepository from '../../services/todos/todos.repository';
import { type ThunkResult } from '../types';

import { TODOS_ACTIONS } from './constants';
import {
  type TodosErrorAction,
  type TodosSetTodosAction,
  type TodosSetTodosCountAction,
  type TodosStartAction,
} from './types';

const todosStart = (): TodosStartAction => ({
  type: TODOS_ACTIONS.START,
});

const todosError = (error: Error): TodosErrorAction => ({
  type: TODOS_ACTIONS.ERROR,
  error,
});

const setCount = (todosCount: number): TodosSetTodosCountAction => ({
  type: TODOS_ACTIONS.SET_TODOS_COUNT,
  payload: { todosCount },
});

const setTodos = (todos: PaginatedResponse<Partial<TodoDefinition>>): TodosSetTodosAction => ({
  type: TODOS_ACTIONS.SET_TODOS,
  payload: { todos },
});

export const refreshTodosCount =
  (): ThunkResult<Promise<TodosErrorAction | TodosSetTodosCountAction>> => async (dispatch) => {
    dispatch(todosStart());
    try {
      const todosCount = await TodosRepository.fetchTodosCount();
      return dispatch(setCount(todosCount));
    } catch (error) {
      return dispatch(todosError(toError(error)));
    }
  };

export const fetchTodos =
  (
    filters: string[],
    searchText?: string,
    page = 0,
    limit = 10,
    sortColumn?: string,
    sortOrder = 'asc',
  ): ThunkResult<Promise<TodosErrorAction | TodosSetTodosAction>> =>
  async (dispatch) => {
    dispatch(todosStart());
    try {
      const todos = await TodosRepository.fetchTodos(filters, searchText, page, limit, sortColumn, sortOrder);
      return dispatch(setTodos(todos));
    } catch (e) {
      return dispatch(todosError(toError(e)));
    }
  };
