import { type FormulaBuilderFunctionCategory } from '../amaliaFormula';

import { type FormulaBuilderFunctionBaseBlock } from './formulaBuilder';
import { type AttributeValue, type ValueOrAttribute } from './valueOrAttribute';

export enum FormulaBuilderStringOperatorNoArgs {
  IS_BLANK = 'IS_BLANK',
}

export enum FormulaBuilderStringOperatorOneArg {
  CONTAINS = 'CONTAINS',
  MATCHES = 'MATCHES',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
}

export type FormulaBuilderFunctionBlockString =
  | FormulaBuilderFunctionBlockStringNoArgs
  | FormulaBuilderFunctionBlockStringOneArg;

export type FormulaBuilderFunctionBlockStringBase = FormulaBuilderFunctionBaseBlock & {
  category: FormulaBuilderFunctionCategory.STRING;
  caseSensitive: boolean;
  not: boolean;
};

export type FormulaBuilderFunctionBlockStringNoArgs = FormulaBuilderFunctionBlockStringBase & {
  operator: FormulaBuilderStringOperatorNoArgs;
  args: [AttributeValue];
};

export type FormulaBuilderFunctionBlockStringOneArg = FormulaBuilderFunctionBlockStringBase & {
  operator: FormulaBuilderStringOperatorOneArg;
  args: [AttributeValue, ValueOrAttribute];
};
