import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FormulaBuilderFunctionCategory } from '@amalia/amalia-lang/formula/types';

/**
 * @param conditionCategory
 * @param value
 * @param prettyFormat Format numbers with current locale.
 */
export const useFormatFunctionBlockValue = (
  conditionCategory: FormulaBuilderFunctionCategory,
  value: number | string | null,
  localized: boolean = false,
) => {
  const { formatNumber } = useIntl();
  return useMemo(() => {
    if (!value && value !== 0) {
      return '';
    }

    if (conditionCategory === FormulaBuilderFunctionCategory.PERCENT) {
      return localized ? formatNumber(Number(value) * 100) : `${Number(value) * 100}`;
    }

    return localized && typeof value === 'number' ? formatNumber(value) : `${value}`;
  }, [conditionCategory, value, formatNumber, localized]);
};
