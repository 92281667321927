import { type DragEndEvent, type UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { usePatchDashboard } from '@amalia/reporting/dashboards-v2/state';
import {
  type Dashboard,
  type ChartLayoutConfig,
  type ChartType,
  type DashboardChart,
} from '@amalia/reporting/dashboards-v2/types';

export const useChartLayoutConfiguration = (
  dashboardId: Dashboard['id'],
  currentChartsLayoutConfig: ChartLayoutConfig[] = [],
  dashboardCharts: DashboardChart<ChartType>[] = [],
) => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [chartsLayoutConfig, setChartsLayoutConfig] = useState<ChartLayoutConfig[]>(currentChartsLayoutConfig);

  useEffect(() => {
    if (!isEmpty(currentChartsLayoutConfig)) {
      setChartsLayoutConfig(currentChartsLayoutConfig);
    }
  }, [currentChartsLayoutConfig]);

  const { mutate: patchDashboard } = usePatchDashboard(dashboardId);

  const handleDragStart = ({ active }: DragEndEvent) => {
    setActiveId(active.id);
  };

  const handleDragOver = ({ active, over }: DragEndEvent) => {
    const activeItemIndex = chartsLayoutConfig.findIndex((item) => item.id === active.id);
    const overItemIndex = chartsLayoutConfig.findIndex((item) => item.id === over?.id);
    setChartsLayoutConfig((items) => arrayMove(items, activeItemIndex, overItemIndex));
  };

  const handleDragEnd = useCallback(() => {
    setActiveId(null);
    patchDashboard({ chartsLayoutConfig });
  }, [chartsLayoutConfig, patchDashboard]);

  const { dashboardDragged, chartLayoutConfigDragged } = useMemo(
    () => getDashboardChart(activeId, chartsLayoutConfig, dashboardCharts),
    [activeId, dashboardCharts, chartsLayoutConfig],
  );

  const handleChartLayoutSizeChange = useCallback(
    (id: ChartLayoutConfig['id'], size: ChartLayoutConfig['size']) => {
      const newChartsLayoutConfig = chartsLayoutConfig.map((chartLayoutConfig) =>
        chartLayoutConfig.id === id ? { ...chartLayoutConfig, size } : chartLayoutConfig,
      );

      setChartsLayoutConfig(newChartsLayoutConfig);
      patchDashboard({ chartsLayoutConfig: newChartsLayoutConfig });
    },
    [chartsLayoutConfig, patchDashboard],
  );

  return {
    chartsLayoutConfig,
    handleChartLayoutSizeChange,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    dashboardDragged,
    chartLayoutConfigDragged,
  };
};

const getDashboardChart = (
  activeId: UniqueIdentifier | null,
  items: ChartLayoutConfig[],
  charts: DashboardChart<ChartType>[],
) => {
  const activeItem = items.find((item) => item.id === activeId);

  if (!activeItem) {
    return {
      dashboardDragged: null,
      dashboardSizeDragged: null,
    };
  }

  return {
    dashboardDragged: charts.find((ch) => ch.id === activeItem.chartId),
    chartLayoutConfigDragged: activeItem,
  };
};
