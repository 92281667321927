import { useState, type Dispatch, type SetStateAction, useCallback } from 'react';

import { type ColumnOrderState, type ColumnSortingState, type ColumnVisibilityState } from '../DataGrid.types';

export type DataGridState = {
  page: number;
  pageSize: number;
  columnVisibility: ColumnVisibilityState;
  columnOrder: ColumnOrderState;
  columnSorting: ColumnSortingState;
  searchText: string;
  columnPinning: boolean;
};

export type UseDataGridStateValue = DataGridState & {
  setPage: Dispatch<SetStateAction<DataGridState['page']>>;
  setPageSize: Dispatch<SetStateAction<DataGridState['pageSize']>>;
  setColumnVisibility: Dispatch<SetStateAction<DataGridState['columnVisibility']>>;
  setColumnOrder: Dispatch<SetStateAction<DataGridState['columnOrder']>>;
  setColumnSorting: Dispatch<SetStateAction<DataGridState['columnSorting']>>;
  setSearchText: Dispatch<SetStateAction<DataGridState['searchText']>>;
  setColumnPinning: Dispatch<SetStateAction<DataGridState['columnPinning']>>;
};

export const getDataGridInitialStateDefaults = ({
  page = 0,
  pageSize = 10,
  columnVisibility = {},
  columnOrder = [],
  columnSorting = [],
  searchText = '',
  columnPinning = false,
}: Partial<DataGridState> = {}): DataGridState => ({
  page,
  pageSize,
  columnVisibility,
  columnOrder,
  columnSorting,
  searchText,
  columnPinning,
});

/**
 * Get DataGrid state and setters.
 * When changing the page size, the column sorting or the text filter, the page is reset to 0.
 */
export const useDataGridState = (
  initialState?: Partial<DataGridState> | (() => Partial<DataGridState>),
): UseDataGridStateValue => {
  const [state, setState] = useState<DataGridState>(() =>
    getDataGridInitialStateDefaults(typeof initialState === 'function' ? initialState() : initialState),
  );

  const setPage: UseDataGridStateValue['setPage'] = useCallback(
    (page) =>
      setState((currentState) => ({
        ...currentState,
        page: typeof page === 'function' ? page(currentState.page) : page,
      })),
    [],
  );

  const setPageSize: UseDataGridStateValue['setPageSize'] = useCallback(
    (pageSize) =>
      setState((currentState) => {
        const newPageSize = typeof pageSize === 'function' ? pageSize(currentState.pageSize) : pageSize;

        return {
          ...currentState,
          pageSize: newPageSize,
          page: newPageSize !== currentState.pageSize ? 0 : currentState.page,
        };
      }),
    [],
  );

  const setColumnVisibility: UseDataGridStateValue['setColumnVisibility'] = useCallback(
    (columnVisibility) =>
      setState((currentState) => ({
        ...currentState,
        columnVisibility:
          typeof columnVisibility === 'function' ? columnVisibility(currentState.columnVisibility) : columnVisibility,
      })),
    [],
  );

  const setColumnOrder: UseDataGridStateValue['setColumnOrder'] = useCallback(
    (columnOrder) =>
      setState((currentState) => ({
        ...currentState,
        columnOrder: typeof columnOrder === 'function' ? columnOrder(currentState.columnOrder) : columnOrder,
      })),
    [],
  );

  const setColumnSorting: UseDataGridStateValue['setColumnSorting'] = useCallback(
    (columnSorting) =>
      setState((currentState) => {
        const newColumnSorting =
          typeof columnSorting === 'function' ? columnSorting(currentState.columnSorting) : columnSorting;

        return {
          ...currentState,
          columnSorting: newColumnSorting,
          page: newColumnSorting !== currentState.columnSorting ? 0 : currentState.page,
        };
      }),
    [],
  );

  const setSearchText: UseDataGridStateValue['setSearchText'] = useCallback(
    (searchText) =>
      setState((currentState) => {
        const newSearchText = typeof searchText === 'function' ? searchText(currentState.searchText) : searchText;

        return {
          ...currentState,
          searchText: newSearchText,
          page: newSearchText !== currentState.searchText ? 0 : currentState.page,
        };
      }),
    [],
  );

  const setColumnPinning: UseDataGridStateValue['setColumnPinning'] = useCallback(
    (columnPinning) =>
      setState((currentState) => ({
        ...currentState,
        columnPinning: typeof columnPinning === 'function' ? columnPinning(currentState.columnPinning) : columnPinning,
      })),
    [],
  );

  return {
    ...state,
    setPage,
    setPageSize,
    setColumnVisibility,
    setColumnOrder,
    setColumnSorting,
    setSearchText,
    setColumnPinning,
  };
};
