import { http } from '@amalia/core/http/client';
import { type Statement } from '@amalia/core/types';

export async function saGetStatementsOnError(companiesId: string[]): Promise<Statement[]> {
  const { data } = await http.get<Statement[]>('/sa_statements/with_errors', {
    params: {
      companiesId,
    },
  });
  return data;
}
