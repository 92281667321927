import { http } from '@amalia/core/http/client';
import { type Plan, type PlanForecast } from '@amalia/payout-definition/plans/types';

export async function get(planId: Plan['id'], planForecastId: PlanForecast['id']): Promise<PlanForecast> {
  const { data } = await http.get<PlanForecast>(`/plans/${planId}/forecasts/${planForecastId}`);
  return data;
}

export async function update(
  planId: Plan['id'],
  planForecastId: PlanForecast['id'],
  planForecast: PlanForecast,
): Promise<PlanForecast> {
  const { data } = await http.put<PlanForecast>(`/plans/${planId}/forecasts/${planForecastId}`, { ...planForecast });
  return data;
}
