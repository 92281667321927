import { ReactRenderer } from '@tiptap/react';
import { type SuggestionOptions, type SuggestionProps } from '@tiptap/suggestion';
import { debounce } from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import tippy, { type Instance as TippyInstance, type Props as TippyProps } from 'tippy.js';

import { type FormulaEditorToken } from '@amalia/amalia-lang/formula/components';
import { assert } from '@amalia/ext/typescript';

import {
  FormulaEditorSuggestionDropdown,
  type FormulaEditorSuggestionDropdownProps,
  type ForwardedTokenSuggestionListRef,
} from '../../components/formula-editor-suggestion-dropdown/FormulaEditorSuggestionDropdown';

export type Suggestion = Omit<SuggestionOptions<FormulaEditorToken>, 'editor'>;

/**
 * Inspired from example here
 * @see {@link} https://tiptap.dev/api/nodes/mention
 */
export const useSuggestions = (tokens: FormulaEditorToken[] = []) => {
  const isOpen = useRef<boolean>(false);

  const items: Suggestion['items'] = useMemo(
    () =>
      debounce<({ query }: { query: string }) => FormulaEditorToken[]>(() => tokens, 200, {
        leading: true,
      }),
    [tokens],
  );

  const render: Suggestion['render'] = useCallback(() => {
    let component: ReactRenderer<ForwardedTokenSuggestionListRef, SuggestionProps<FormulaEditorToken>> | null = null;
    let popup: TippyInstance<TippyProps> | null = null;

    return {
      onStart: (props: SuggestionProps<FormulaEditorToken>) => {
        component = new ReactRenderer<ForwardedTokenSuggestionListRef, FormulaEditorSuggestionDropdownProps>(
          FormulaEditorSuggestionDropdown,
          {
            props: {
              ...props,
            },
            editor: props.editor,
          },
        );

        if (!props.clientRect) {
          return;
        }

        [popup] = tippy('body', {
          getReferenceClientRect: () => props.clientRect?.() ?? document.body.getBoundingClientRect(),
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });

        isOpen.current = true;
      },

      onUpdate: (props: SuggestionProps<FormulaEditorToken>) => {
        assert(component, 'component should have been set with onStart');

        component.updateProps(props);

        assert(popup, 'popup should have been set with onStart');
        popup.setProps({
          getReferenceClientRect: () => props.clientRect?.() ?? document.body.getBoundingClientRect(),
        });
      },

      onKeyDown: (props: { event: KeyboardEvent }) => {
        assert(component, 'component should have been set with onStart');
        assert(popup, 'popup should have been set with onStart');

        if (props.event.key === 'Escape') {
          popup.hide();

          return true;
        }

        return component.ref?.onKeyDown(props) ?? false;
      },

      onExit: () => {
        isOpen.current = false;

        assert(popup, 'popup should have been set with onStart');
        popup.destroy();

        assert(component, 'component should have been set with onStart');
        component.destroy();
      },
    };
  }, []);

  /**
   * @see {@link} https://tiptap.dev/api/utilities/suggestion
   */
  const suggestion: Omit<SuggestionOptions<FormulaEditorToken>, 'editor'> = useMemo(
    () => ({
      allowSpaces: true,
      allowedPrefixes: null,
      command: ({ editor, range, props }) => {
        editor
          .chain()
          .focus()
          .insertContentAt(range, props.editorContentToApply)
          .setTextSelection(range.from + props.editorContentToApply.length)
          .run();
      },
      items,
      decorationTag: 'span',
      render,
    }),
    [items, render],
  );

  return {
    suggestion,
    isOpen,
  };
};
