import { IconAddressBook, IconDatabase, IconFileDescription, IconTable, IconUsers } from '@tabler/icons-react';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';

import { BaseCommandItem } from '../cmdk-styled/BaseCommandItem';
import { CommandGroup } from '../cmdk-styled/CommandGroup';
import { useShortcutCommandContext } from '../ShortcutCommands.context';
import { PagesEnum } from '../shortcutCommands.types';

export const AdminCommandsGroup = memo(function AdminCommandsGroup() {
  const ability = useAbilityContext();
  const { setPages, resetSearch } = useShortcutCommandContext();
  const canViewSuperAdmin = ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin);

  const handleSelectListPlans = useCallback(() => {
    setPages((prev) => [...prev, PagesEnum.LIST_PLANS]);
    resetSearch();
  }, [resetSearch, setPages]);

  const handleSelectListUsers = useCallback(() => {
    setPages((prev) => [...prev, PagesEnum.LIST_USERS]);
    resetSearch();
  }, [resetSearch, setPages]);

  const handleSelectListReports = useCallback(() => {
    setPages((prev) => [...prev, PagesEnum.LIST_REPORTS]);
    resetSearch();
  }, [resetSearch, setPages]);

  const handleSelectListCustomObjects = useCallback(() => {
    setPages((prev) => [...prev, PagesEnum.LIST_CUSTOM_OBJECTS]);
    resetSearch();
  }, [resetSearch, setPages]);

  const handleSelectListTeams = useCallback(() => {
    setPages((prev) => [...prev, PagesEnum.LIST_TEAMS]);
    resetSearch();
  }, [resetSearch, setPages]);

  if (!canViewSuperAdmin) {
    return null;
  }

  return (
    <CommandGroup title={<FormattedMessage defaultMessage="Admin commands" />}>
      <BaseCommandItem
        Icon={IconFileDescription}
        title={<FormattedMessage defaultMessage="List plans" />}
        onSelect={handleSelectListPlans}
      />
      <BaseCommandItem
        Icon={IconAddressBook}
        title={<FormattedMessage defaultMessage="List users" />}
        onSelect={handleSelectListUsers}
      />
      <BaseCommandItem
        Icon={IconUsers}
        title={<FormattedMessage defaultMessage="List teams" />}
        onSelect={handleSelectListTeams}
      />
      <BaseCommandItem
        Icon={IconTable}
        title={<FormattedMessage defaultMessage="List reports" />}
        onSelect={handleSelectListReports}
      />
      <BaseCommandItem
        Icon={IconDatabase}
        title={<FormattedMessage defaultMessage="List custom objects" />}
        onSelect={handleSelectListCustomObjects}
      />
    </CommandGroup>
  );
});
