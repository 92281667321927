import { makeStyles } from '@mui/styles';
import { IconLink } from '@tabler/icons-react';
import { memo, useCallback } from 'react';

import { Button } from '@amalia/design-system/components';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';

import { useOpenInNewTab } from '../../utils/hooks/useOpenInNewTab';

export enum FIGMA_LINKS {
  BUTTONS = 'BUTTONS',
  INPUT_BUTTONS = 'INPUT_BUTTONS',
  FONTS = 'FONTS',
  SELECTORS = 'SELECTORS',
  SELECTOR_FILTERS = 'SELECTOR_FILTERS',
  INPUTS = 'INPUTS',
  SEARCH_INPUTS = 'SEARCH_INPUTS',
  INFOS = 'INFOS',
  DATAGRID = 'DATAGRID',
  USERS = 'USERS',
  POPUPS = 'POPUPS',
  STATEMENT = 'STATEMENT',
  ICONS = 'ICONS',
  GRAPHS = 'GRAPHS',
  DESIGNER_COLORS = 'DESIGNER_COLORS',
  COLORS = 'COLORS',
  LOGOS = 'LOGOS',
  HEADER = 'HEADER',
  FILTER_BARS = 'FILTER_BARS',
}

const links: Record<FIGMA_LINKS, { link: string; name: string }> = {
  [FIGMA_LINKS.BUTTONS]: { link: '12016%3A44396&t=MFolrEVaSmruLhbL-4', name: 'Buttons' },
  [FIGMA_LINKS.INPUT_BUTTONS]: { link: '10616%3A38051&t=MFolrEVaSmruLhbL-4', name: 'Input Buttons' },
  [FIGMA_LINKS.FONTS]: { link: '10615%3A37998&t=MFolrEVaSmruLhbL-4', name: 'Fonts' },
  [FIGMA_LINKS.SELECTORS]: { link: '12027%3A22704&t=MFolrEVaSmruLhbL-4', name: 'Selectors' },
  [FIGMA_LINKS.SELECTOR_FILTERS]: { link: '12027%3A23452&t=MFolrEVaSmruLhbL-4', name: 'Selector (Filters)' },
  [FIGMA_LINKS.INPUTS]: { link: '10616%3A38091&t=MFolrEVaSmruLhbL-4', name: 'Inputs' },
  [FIGMA_LINKS.SEARCH_INPUTS]: { link: '12035%3A22688&t=MFolrEVaSmruLhbL-4', name: 'Search Inputs' },
  [FIGMA_LINKS.INFOS]: { link: '10639%3A38536&t=MFolrEVaSmruLhbL-4', name: 'Infos' },
  [FIGMA_LINKS.DATAGRID]: { link: '10619%3A38412&t=MFolrEVaSmruLhbL-4', name: 'Datagrid' },
  [FIGMA_LINKS.USERS]: { link: '10639%3A38624&t=MFolrEVaSmruLhbL-4', name: 'Users' },
  [FIGMA_LINKS.POPUPS]: { link: '10639%3A38448&t=MFolrEVaSmruLhbL-4', name: 'Popups' },
  [FIGMA_LINKS.STATEMENT]: { link: '10619%3A38428&t=MFolrEVaSmruLhbL-4', name: 'Statement' },
  [FIGMA_LINKS.ICONS]: { link: '10755%3A47202&t=MFolrEVaSmruLhbL-4', name: 'Icons' },
  [FIGMA_LINKS.GRAPHS]: { link: '10756%3A47950&t=MFolrEVaSmruLhbL-4', name: 'Graphs' },
  [FIGMA_LINKS.DESIGNER_COLORS]: { link: '10753%3A47089&t=MFolrEVaSmruLhbL-4', name: 'Designer Colors' },
  [FIGMA_LINKS.COLORS]: { link: '10667%3A45111&t=MFolrEVaSmruLhbL-4', name: 'Colors' },
  [FIGMA_LINKS.LOGOS]: { link: '10613%3A39317&t=MFolrEVaSmruLhbL-4', name: 'Logos' },
  [FIGMA_LINKS.HEADER]: { link: '10616%3A38169&t=MFolrEVaSmruLhbL-4', name: 'Header' },
  [FIGMA_LINKS.FILTER_BARS]: { link: '10619%3A38420&t=MFolrEVaSmruLhbL-4', name: 'Filter Bars' },
};

const BASE_LINK = 'https://www.figma.com/file/ewNxGhz7H57CITEf1nfHzj/Amalia-V1?node-id=';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  component: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    width: '100%',
    flex: 1,
    backgroundColor: theme.palette.tertiary.contrastText,
    borderRadius: theme.spacing(1),
    color: theme.palette.tertiary.main,
  },
  container: {
    marginTop: theme.spacing(1),
    display: 'flex',
    gridGap: '1rem',
  },
}));

interface LinkToFigmaProps {
  readonly types: FIGMA_LINKS[];
}

export const LinkToFigma = memo(function LinkToFigma({ types }: LinkToFigmaProps) {
  const classes = useStyles();
  const openInNewTab = useOpenInNewTab();

  const handleClick = useCallback(
    (t: FIGMA_LINKS) => {
      openInNewTab(`${BASE_LINK}${links[t].link}`);
    },
    [openInNewTab],
  );

  return (
    // eslint-disable-next-line formatjs/no-literal-string-in-jsx
    <div className={classes.component}>
      Figma Pages linked to this story
      <div className={classes.container}>
        {types.map((t) => (
          <Button
            key={t}
            icon={<IconLink />}
            variant={Button.Variant.PRIMARY}
            onClick={() => handleClick(t)}
          >
            {links[t].name}
          </Button>
        ))}
      </div>
    </div>
  );
});
