import { useCallback } from 'react';

import { type AttributeValueForm } from '@amalia/amalia-lang/formula/form/types';
import { TokenType } from '@amalia/amalia-lang/tokens/types';

import { useGetFormulaBuilderAttribute } from '../use-get-formula-builder-attribute/useGetFormulaBuilderAttribute';

export type UseGetFormulaBuilderAttributeLabelValue = (attribute: AttributeValueForm) => string;

/**
 * Return a method to get the name of an attribute, bound to the context.
 * @returns Callback.
 */
export function useGetFormulaBuilderAttributeLabel(): UseGetFormulaBuilderAttributeLabelValue {
  const getFormulaBuilderAttribute = useGetFormulaBuilderAttribute();

  /** For each type of AttributeValueForm, find the related attribute and display its name. Fallback to formula notation. */
  return useCallback(
    (attribute: AttributeValueForm) => {
      // Ignore empty field values.
      if (!('fieldType' in attribute)) {
        return '';
      }

      switch (attribute.fieldType) {
        case TokenType.LINK: {
          const relationshipProperty = getFormulaBuilderAttribute(attribute);

          return (
            relationshipProperty?.property.name ||
            `${attribute.objectMachineName}.${attribute.relationshipMachineName}.${attribute.propertyMachineName}`
          );
        }

        case TokenType.VARIABLE: {
          const variable = getFormulaBuilderAttribute(attribute);
          return variable?.name || `statement.${attribute.machineName}`;
        }

        case TokenType.PROPERTY:
        case TokenType.VIRTUAL_PROPERTY: {
          const property = getFormulaBuilderAttribute(attribute);
          return property?.name || `${attribute.objectMachineName}.${attribute.propertyMachineName}`;
        }

        case TokenType.FIELD: {
          const field = getFormulaBuilderAttribute(attribute);
          return field?.name || `${attribute.objectMachineName}.${attribute.propertyMachineName}`;
        }

        case TokenType.QUOTA: {
          const quota = getFormulaBuilderAttribute(attribute);
          return quota?.name || `${attribute.quotaType}.${attribute.machineName}`;
        }

        case TokenType.KEYWORD: {
          const keyword = getFormulaBuilderAttribute(attribute);
          return keyword?.name || attribute.keyword;
        }

        default:
          return '';
      }
    },
    [getFormulaBuilderAttribute],
  );
}
