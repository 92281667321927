import { css } from '@emotion/react';
import { memo, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { LabelDisplay, LabelVariant, UserPrettyFormat } from '@amalia/data-capture/fields/components';
import { Badge, BadgeHue, Dropdown, MenuDropdown, Stack } from '@amalia/design-system/components';
import { fuzzyFilter } from '@amalia/ext/filters';
import { type Dashboard } from '@amalia/reporting/dashboards-v2/types';

type DashboardSharedWithBadgeProps = {
  readonly dashboardPermissions: Dashboard['permissions'];
};

export const DashboardSharedWithBadge = memo(function DashboardSharedWithBadge({
  dashboardPermissions,
}: DashboardSharedWithBadgeProps) {
  const [userDropdownSearchText, setUserDropdownSearchText] = useState('');

  const userAllowedToViewDashboardFiltered = useMemo(() => {
    if (!userDropdownSearchText) {
      return dashboardPermissions?.users || [];
    }
    return (dashboardPermissions?.users || []).filter((user) =>
      fuzzyFilter(user.firstName! + user.lastName, userDropdownSearchText),
    );
  }, [dashboardPermissions?.users, userDropdownSearchText]);

  if (!dashboardPermissions?.users?.length) {
    return (
      <Badge
        size={Badge.Size.SMALL}
        variant={BadgeHue.GREY}
      >
        <FormattedMessage defaultMessage="Admin access only" />
      </Badge>
    );
  }
  return (
    <MenuDropdown
      content={
        <Stack
          gap={10}
          css={css`
            margin: 6px;
          `}
        >
          {userAllowedToViewDashboardFiltered.map((user) => (
            <Dropdown.ItemContainer key={user.id}>
              <UserPrettyFormat
                key={user.id}
                display={LabelDisplay.INLINE}
                firstName={user.firstName}
                lastName={user.lastName}
                pictureURL={user.pictureURL}
                variant={LabelVariant.BOLD}
              />
            </Dropdown.ItemContainer>
          ))}
        </Stack>
      }
      searchInput={
        <Dropdown.SearchInput
          value={userDropdownSearchText}
          onChange={setUserDropdownSearchText}
        />
      }
    >
      <Badge
        size={Badge.Size.SMALL}
        variant={BadgeHue.BLUE}
        css={css`
          cursor: pointer;
        `}
      >
        <FormattedMessage
          defaultMessage="Shared with {managersCount, plural, one {# manager} other {# managers}}"
          values={{ managersCount: dashboardPermissions.users.length }}
        />
      </Badge>
    </MenuDropdown>
  );
});
