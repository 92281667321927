import { useIsFetching, useQuery } from '@tanstack/react-query';
import { keyBy } from 'lodash';
import { useEffect, useMemo } from 'react';

import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import { TeamsApiClient } from '@amalia/tenants/teams/api-client';
import { type TeamContract, type TeamMap } from '@amalia/tenants/teams/types';

import { TEAM_QUERY_KEYS } from './queries.keys';

export const useTeams = (
  {
    sortByName,
    userIds,
    planIds,
    showArchivedTeams = true,
  }: { sortByName?: boolean; userIds?: string[]; planIds?: string[]; showArchivedTeams?: boolean } = {},
  options?: { enabled?: boolean },
) => {
  const { snackError } = useSnackbars();

  const {
    data: teams,
    isError,
    error,
    ...rest
  } = useQuery({
    queryKey: [TEAM_QUERY_KEYS.TEAMS, userIds, planIds],
    queryFn: () => TeamsApiClient.getTeams(userIds, planIds),
    select: (data) => (showArchivedTeams ? data : data.filter((team) => !team.archived)),
    ...options,
  });

  useEffect(() => {
    if (isError) {
      snackError(toError(error).message);
    }
  }, [isError, error, snackError]);

  const { teamsList, teamsMap }: { teamsList: TeamContract[]; teamsMap: TeamMap } = useMemo(
    () => ({
      teamsList: sortByName ? (teams || []).sort((a, b) => a.name.localeCompare(b.name)) : teams || [],
      teamsMap: keyBy(teams || [], 'id'),
    }),
    [teams, sortByName],
  );

  return {
    teamsList,
    teamsMap,
    isError,
    ...rest,
  };
};

export const useTeamsHierarchy = ({ showArchivedTeams }: { showArchivedTeams?: boolean } = {}) => {
  const { snackError } = useSnackbars();

  const {
    data: teamsHierarchy,
    isError,
    error,
    isSuccess,
  } = useQuery({
    queryKey: [TEAM_QUERY_KEYS.TEAMS, 'hierarchy', showArchivedTeams],
    queryFn: () => TeamsApiClient.getTeamHierarchy(showArchivedTeams),
    placeholderData: [],
  });

  useEffect(() => {
    if (isError) {
      snackError(toError(error).message);
    }
  }, [isError, error, snackError]);

  return {
    teamsHierarchy,
    isSuccess,
    isError,
  };
};

export const useIsTeamsLoading = () => useIsFetching({ queryKey: [TEAM_QUERY_KEYS.TEAMS] }) > 0;
