import { defineMessages } from 'react-intl';

import { UserStatus } from '@amalia/tenants/users/types';

export const UserStatusLabels = defineMessages<UserStatus>({
  [UserStatus.ACTIVE]: {
    defaultMessage: 'Active',
    description: 'User status',
  },
  [UserStatus.DEACTIVATED]: {
    defaultMessage: 'Deactivated',
    description: 'User status',
  },
  [UserStatus.INACTIVE]: {
    defaultMessage: 'Inactive',
    description: 'User status',
  },
  [UserStatus.INVITED]: {
    defaultMessage: 'Invited',
    description: 'User status',
  },
} as const);
