import { CalculationParser, type ParserScope } from '@amalia/amalia-lang/formula/evaluate/shared';
import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';

import AmaliaFunction from '../../AmaliaFunction';
import { getValueOrFormula } from '../../utils';

const func = new AmaliaFunction(AmaliaFunctionKeys.MATCH_USERS_ASSIGNED, AmaliaFunctionCategory.MISC);

func.nbParamsRequired = 3;

func.description = 'Return true if the ID of a team member matches a field or property';

func.params = [
  {
    name: 'role.scope.depth',
    description: `Range of users to match for depending on role, scope and depth:
    - Role can be EMPLOYEE, MANAGER or MEMBER (both).
    - Scope can be ALL or SUB (OPTIONAL); by default it is ALL.
    - Depth is any integer for the range of levels to include from the location of the current user with 0 representing infinity (OPTIONAL); by default it's 0.`,
  },
  {
    name: 'userField',
    description: 'Field of the user, to compare the record to such as user.externalId.',
  },
  {
    name: 'recordOwner',
    description: 'Field of the record containing the owner.',
  },
  {
    name: 'date',
    description:
      'Field containing the date to check against assignments (OPTIONAL); by default it is not filtered on a date.',
    defaultValue: null,
  },
  {
    name: 'filterCurrentUserAssignments',
    description:
      'Either true or false; if true, filter results by current user assignment (OPTIONAL); by default it is true.',
    defaultValue: true,
  },
];

func.examples = [
  {
    desc: `If the user is a manager, then returns true if the opportunity owner is one of the members (employee or manager) of the user's immediate team or of all children teams.
    If the user is an employee, then returns true if the opportunity owner is one of the members (employee or manager) on the user's immediate team.`,
    formula: 'MATCH_USERS_ASSIGNED("MEMBER", user.externalId, opportunity.owner)' as AmaliaFormula,
  },
  {
    desc: "If the user is a manager, then returns true if the opportunity owner is a manager of the user's subteams. If the user is an employee, then it returns false.",
    formula: 'MATCH_USERS_ASSIGNED("MANAGER.SUB.1", user.externalId, opportunity.owner)' as AmaliaFormula,
  },
  {
    desc: `If the user is a manager, then returns true if the opportunity owner is a member (employee or manager) of the user's immediate team and the children team one level below.
      If the user is an employee, then returns true only if the opportunity owner is a member of my immediate team.`,
    formula: 'MATCH_USERS_ASSIGNED("MEMBER.ALL.2", user.externalId, opportunity.owner)' as AmaliaFormula,
  },
  {
    desc: `If the user is a manager, then returns true if the opportunity owner is an employee of the user's immediate team or in all children teams even if they weren't the team manager at the time of signature date.
      If the user is an employee, then returns true if the opportunity owner is an employee on the user's immediate team.`,
    formula:
      'MATCH_USERS_ASSIGNED("EMPLOYEE", user.externalId, opportunity.owner, opportunity.signatureDate, false)' as AmaliaFormula,
  },
];

func.execMock = (): number => 1;

func.generateComputedFunctionResult = (args) => ({
  scope: getValueOrFormula(args[0]),
  userField: getValueOrFormula(args[1]),
  recordOwner: getValueOrFormula(args[2]),
  date: args[3] && getValueOrFormula(args[3]),
  filterCurrentUserAssignments: args[4] ? getValueOrFormula<boolean>(args[4]) : undefined,
});

func.execRawArgs = (args: any[], _, scope: ParserScope) =>
  CalculationParser.getFunctionResult(args, scope, AmaliaFunctionKeys.MATCH_USERS_ASSIGNED);

export default func;
