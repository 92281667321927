import { SimpleSelect, type SimpleSelectProps } from '../../../data-input/simple-select/SimpleSelect';
import { type SimpleSelectOption } from '../../../data-input/simple-select/SimpleSelect.types';
import { type SelectOptionGroup } from '../../../overlays/select-dropdown/SelectDropdown.types';

export type BreadcrumbsSelectItemProps<
  TOption extends SimpleSelectOption = SimpleSelectOption,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
> = SimpleSelectProps<TOption, TUseOptionAsValue, TGroup>;

export const BreadcrumbsSelectItem = function BreadcrumbsSelectItem<
  TOption extends SimpleSelectOption = SimpleSelectOption,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
>(props: BreadcrumbsSelectItemProps<TOption, TUseOptionAsValue, TGroup>) {
  return (
    <div>
      <SimpleSelect {...props} />
    </div>
  );
};
