import { css } from '@emotion/react';

import { TypographyVariant } from '@amalia/design-system/meta';

import { LabelVariant } from '../../types';

export const TYPOGRAPHY_VARIANT = {
  [LabelVariant.DIMMED]: TypographyVariant.BODY_BASE_REGULAR,
  [LabelVariant.ACCENTUATED]: TypographyVariant.BODY_BASE_MEDIUM,
} as const;

export const flex = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const nullIcon = css`
  --icon-size: 16px;
  width: var(--icon-size);
  height: var(--icon-size);
`;
