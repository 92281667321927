import { type ForwardedRef, forwardRef, memo } from 'react';

import { IconButton, type IconButtonProps } from '../../../../general/icon-button/IconButton';
import { useOptionalDropdownContext } from '../../../../overlays/dropdown/Dropdown.context';

export type ColumnActionProps = Omit<IconButtonProps, 'size' | 'withBackground'>;

const ColumnActionForwardRef = forwardRef(function ColumnAction(
  props: ColumnActionProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  // Show as active when dropdown is open in case this button is used with a MenuDropdown.
  const dropdownContext = useOptionalDropdownContext();

  return (
    <IconButton
      {...props}
      ref={ref}
      isActive={props.isActive ?? dropdownContext?.isOpen}
      size={IconButton.Size.SMALL}
    />
  );
});

export const ColumnAction = Object.assign(memo(ColumnActionForwardRef), {
  Variant: IconButton.Variant,
});
