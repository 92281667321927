import { type ForwardedRef, forwardRef, memo, type ReactNode, useState } from 'react';

import { SelectorTrigger, type SelectorTriggerProps } from '../../../general/selector-trigger/SelectorTrigger';
import { TextOverflow } from '../../../general/text-overflow/TextOverflow';
import { Popover } from '../../../overlays/popover/Popover';

import * as styles from './BreadcrumbsPopoverItem.styles';

export type BreadcrumbsPopoverItemProps = Omit<SelectorTriggerProps, 'as'> & {
  readonly popoverContent: ReactNode;
  readonly children: SelectorTriggerProps['children'];
};

const BreadcrumbsPopoverItemForwardRef = forwardRef(function BreadcrumbsPopoverItem(
  { children, popoverContent, ...props }: BreadcrumbsPopoverItemProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  return (
    <div>
      <Popover
        content={popoverContent}
        css={styles.selectItemPopover}
        isOpen={isPopoverOpen}
        onChangeIsOpen={setIsPopoverOpen}
      >
        <SelectorTrigger
          {...props}
          ref={ref}
          as={TextOverflow}
          isActive={isPopoverOpen}
        >
          {children}
        </SelectorTrigger>
      </Popover>
    </div>
  );
});

export const BreadcrumbsPopoverItem = memo(BreadcrumbsPopoverItemForwardRef);
