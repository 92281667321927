import { css } from '@emotion/react';
import styled from '@emotion/styled';
import moment from 'moment';
import { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { type CalculationRequest, REFRESHMENTS_ONGOING_STATUSES } from '@amalia/core/types';
import { ConnectorLogo } from '@amalia/data-capture/connectors/components';
import { AlertBanner, Badge, Checkbox, Modal, Skeleton, Typography } from '@amalia/design-system/components';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { type LaunchCalculation } from '@amalia/lib-ui';
import { useCalculationAnalyze } from '@amalia/payout-calculation/compute-engine/state';

interface CalculationAnalyzeModalProps {
  readonly isOpen: boolean;
  readonly closeModal: () => void;
  readonly launchCalculation: LaunchCalculation;
  readonly calculationRequest: CalculationRequest;
}

const SkeletonListElement = styled(Skeleton)`
  height: 18px;
  width: 100%;
  min-width: 100px;
`;

export const CalculationAnalyzeModal = memo(function CalculationAnalyzeModal({
  isOpen,
  closeModal,
  launchCalculation,
  calculationRequest,
}: CalculationAnalyzeModalProps) {
  const { data: calculationAnalyze, isLoading, error } = useCalculationAnalyze(isOpen ? calculationRequest : undefined);

  const statementsCount = useMemo(
    () =>
      calculationAnalyze?.calculation.descriptor
        .flatMap((step) => step.batches.flatMap((batch) => batch.users.length))
        .reduce((acc, curr) => acc + curr, 0),
    [calculationAnalyze],
  );

  const [objects, setObjects] = useState<NonNullable<CalculationRequest['dataConnectorObjectsNames']>>([]);

  const handleCheckObject = useCallback(
    (checked: boolean, target: NonNullable<CalculationRequest['dataConnectorObjectsNames']>[0]) => {
      setObjects((v) =>
        checked ? [...v, target] : v.filter((elt) => !(elt.name === target.name && elt.type === target.type)),
      );
    },
    [setObjects],
  );

  const handleClickConfirm = useCallback(async () => {
    closeModal();
    await launchCalculation({ dataConnectorObjectsNames: objects });
  }, [closeModal, launchCalculation, objects]);

  const ability = useAbilityContext();
  const canRefreshData = ability.can(ActionsEnum.refresh, SubjectsEnum.Data);

  return (
    <Modal
      isOpen={isOpen}
      size={Modal.Size.LARGE}
      onClose={closeModal}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Compute statements" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {(() => {
            if (error) {
              return <AlertBanner variant={AlertBanner.Variant.ERROR}>{error.message}</AlertBanner>;
            }

            const willDeleteMessage = !!calculationAnalyze?.calculation.deletedStatementIds?.length && (
              <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
                <br />
                <br />
                <FormattedMessage
                  defaultMessage="This computation will delete {count, plural, one {one statement} other {# statements}}."
                  values={{ count: calculationAnalyze.calculation.deletedStatementIds.length }}
                />
              </Typography>
            );

            if (statementsCount === 0) {
              return (
                <Skeleton visible={isLoading}>
                  <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
                    <FormattedMessage defaultMessage="No statements to compute for the current filter." />
                  </Typography>
                  {willDeleteMessage}
                </Skeleton>
              );
            }

            if (canRefreshData) {
              return (
                <Fragment>
                  <Skeleton visible={isLoading}>
                    <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
                      <FormattedMessage
                        defaultMessage="Computing {count, plural, one {one statement} other {# statements}} in the current period could take longer if you choose to update data sources used in {count, plural, one {this statement} other {those statements}}. However, you can launch the computation without updating data sources."
                        values={{ count: statementsCount }}
                      />
                    </Typography>
                    {willDeleteMessage}
                  </Skeleton>
                  <div
                    css={css`
                      display: grid;
                      grid-template-columns: 1fr auto;
                      gap: 16px;
                      margin-top: 32px;
                      align-items: flex-end;
                    `}
                  >
                    <Typography
                      variant={Typography.Variant.BODY_SMALL_MEDIUM}
                      css={css`
                        padding-left: 32px;
                      `}
                    >
                      <FormattedMessage defaultMessage="Data source" />
                    </Typography>
                    <Typography variant={Typography.Variant.BODY_SMALL_MEDIUM}>
                      <FormattedMessage defaultMessage="Last updated at" />
                    </Typography>

                    {isLoading
                      ? Array(3)
                          .fill(0)
                          .map((_, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Fragment key={index}>
                              <Checkbox label={<SkeletonListElement visible />} />
                              <SkeletonListElement visible />
                            </Fragment>
                          ))
                      : Object.entries(calculationAnalyze?.records || {})
                          .toSorted((a, b) => a[1].source.label.localeCompare(b[1].source.label))
                          .map(([object, { source, lastRefresh, connectorType }]) => {
                            const isManual = source.frequencyCron === 'manually';
                            const isOngoing = !!(
                              lastRefresh && REFRESHMENTS_ONGOING_STATUSES.includes(lastRefresh.status)
                            );
                            const isChecked = objects.some((o) => o.type === connectorType && o.name === source.name);

                            // Only show alias if it's different than the label.
                            const alias = source.alias && source.alias !== source.label ? ` (${source.alias})` : '';

                            return (
                              <Fragment key={object}>
                                <Checkbox
                                  checked={isChecked}
                                  disabled={isManual || isOngoing}
                                  value={source.name}
                                  label={
                                    <div
                                      css={css`
                                        display: flex;
                                        align-items: center;
                                        gap: 8px;
                                      `}
                                    >
                                      <ConnectorLogo connectorType={connectorType} />
                                      <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>
                                        {source.label}
                                        {alias}
                                      </Typography>
                                      {!!isManual && (
                                        <Badge
                                          size={Badge.Size.SMALL}
                                          variant={Badge.Hue.ORANGE}
                                        >
                                          <FormattedMessage defaultMessage="Manual update only" />
                                        </Badge>
                                      )}
                                    </div>
                                  }
                                  onChange={(checked: boolean) =>
                                    handleCheckObject(checked, {
                                      name: source.name,
                                      type: connectorType,
                                    })
                                  }
                                />
                                <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
                                  {!!lastRefresh &&
                                    (isOngoing ? (
                                      <FormattedMessage defaultMessage="Update ongoing" />
                                    ) : (
                                      moment(lastRefresh.updatedAt).format('LLL')
                                    ))}
                                </Typography>
                              </Fragment>
                            );
                          })}
                  </div>
                </Fragment>
              );
            }

            return (
              <Skeleton visible={isLoading}>
                <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
                  <FormattedMessage
                    defaultMessage="You're about to compute {count, plural, one {one statement} other {# statements}}."
                    values={{ count: statementsCount }}
                  />
                </Typography>
                {willDeleteMessage}
              </Skeleton>
            );
          })()}
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />
        <Modal.MainAction
          disabled={!!error}
          onClick={handleClickConfirm}
        >
          <FormattedMessage defaultMessage="Confirm" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
