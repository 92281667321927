import { css } from '@emotion/react';
import { memo } from 'react';

import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';

import { CurrencyLabel } from './CurrencyLabel';
import { CurrencySymbol } from './CurrencySymbol';

export type CurrencyDisplayProps = {
  readonly currencySymbol: CurrencySymbolsEnum;
};

export const CurrencyDisplay = memo(function CurrencyDisplay({ currencySymbol }: CurrencyDisplayProps) {
  return (
    <span
      css={css`
        display: flex;
        align-items: center;
        gap: 6px;
      `}
    >
      <CurrencySymbol currencySymbol={currencySymbol} />
      <CurrencyLabel currencySymbol={currencySymbol} />
    </span>
  );
});
