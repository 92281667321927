import { lazy } from 'react';

import { Layout } from '@amalia/core/layout/components';

const CommissionReportContainer = lazy(() => import('./CommissionReportContainer'));

export const CommissionReportView = function CommissionReportView() {
  return (
    <Layout currentPage="reporting">
      <CommissionReportContainer />
    </Layout>
  );
};
