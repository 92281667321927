import { css } from '@emotion/react';
import { forwardRef, memo, type ComponentPropsWithoutRef, type ForwardedRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { formatUserFullName } from '@amalia/core/types';
import { Avatar, ButtonLink, Group, Tooltip, UnstyledButton } from '@amalia/design-system/components';
import { TypographyVariant } from '@amalia/design-system/meta';
import { isLinkElement } from '@amalia/ext/react-router-dom';
import { type MergeAll } from '@amalia/ext/typescript';

import { UsersAvatarStackDropdown } from './dropdown/UsersAvatarStackDropdown';
import { UsersAvatarStackSize, type UsersAvatarStackUser } from './UsersAvatarStack.types';

const UsersAvatarStackSizeToAvatarSize = {
  [UsersAvatarStackSize.SMALL]: 24,
  [UsersAvatarStackSize.MEDIUM]: 30,
  [UsersAvatarStackSize.LARGE]: 36,
} as const satisfies Record<UsersAvatarStackSize, number>;

const UsersAvatarStackSizeToTypographyVariant = {
  [UsersAvatarStackSize.SMALL]: TypographyVariant.BODY_XSMALL_REGULAR,
  [UsersAvatarStackSize.MEDIUM]: TypographyVariant.BODY_SMALL_REGULAR,
  [UsersAvatarStackSize.LARGE]: TypographyVariant.BODY_BASE_REGULAR,
} as const satisfies Record<UsersAvatarStackSize, TypographyVariant>;

// Overlap between avatars in pixels.
const AVATAR_OVERLAP = 6;

export type UsersAvatarStackProps = MergeAll<
  [
    Omit<ComponentPropsWithoutRef<'div'>, 'children'>,
    {
      /** List of users to show. */
      readonly users?: UsersAvatarStackUser[];
      /** Maximum number of avatars to show. The avatar with the remaining users is included in that number. */
      readonly maxUsers?: number;
      /** Size of avatars. */
      readonly size?: UsersAvatarStackSize;
    },
  ]
>;

const DEFAULT_USERS: Required<UsersAvatarStackProps>['users'] = [];

const UsersAvatarStackForwardRef = forwardRef(function UsersAvatarStack(
  { users = DEFAULT_USERS, maxUsers = 3, size = UsersAvatarStackSize.MEDIUM, ...props }: UsersAvatarStackProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const shouldShowExtraAvatar = users.length > maxUsers;
  const usersToShow = shouldShowExtraAvatar ? users.slice(0, maxUsers - 1) : users;
  const avatarsCount = Math.min(users.length, maxUsers);

  return (
    <Group
      {...props}
      ref={ref}
      align="center"
      css={css`
        width: ${avatarsCount * UsersAvatarStackSizeToAvatarSize[size] - (avatarsCount - 1) * AVATAR_OVERLAP}px;

        &:empty {
          display: none;
        }

        > * {
          flex: none;
        }
      `}
    >
      {usersToShow.map(({ user, to }, index) => (
        <Tooltip
          key={user.id}
          content={
            <Group
              align="center"
              gap={8}
            >
              <span>{formatUserFullName(user)}</span>

              {!!to && (
                <ButtonLink
                  to={to}
                  variant={ButtonLink.Variant.PRIMARY_LIGHT}
                >
                  {(isLinkElement(to) ? to.props.children : undefined) || <FormattedMessage defaultMessage="View" />}
                </ButtonLink>
              )}
            </Group>
          }
        >
          <Avatar
            size={UsersAvatarStackSizeToAvatarSize[size]}
            user={user}
            css={(theme) => css`
              border: 1px solid ${theme.ds.colors.gray[0]};
              position: relative;
              left: ${index * AVATAR_OVERLAP * -1}px;
            `}
          />
        </Tooltip>
      ))}

      {!!shouldShowExtraAvatar && (
        <UsersAvatarStackDropdown users={users}>
          <UnstyledButton
            css={(theme) => css`
              ${theme.ds.typographies[UsersAvatarStackSizeToTypographyVariant[size]]};

              display: flex;
              align-items: center;
              justify-content: center;
              flex: none;
              box-sizing: border-box;

              width: ${UsersAvatarStackSizeToAvatarSize[size]}px;
              height: ${UsersAvatarStackSizeToAvatarSize[size]}px;

              border: 1px solid ${theme.ds.colors.gray[0]};
              border-radius: ${theme.ds.borderRadiuses.round};

              transition: ${theme.ds.transitions.default('height', 'width', 'font-size')};
              overflow: hidden;

              background-color: ${theme.ds.colors.graySecondary[50]};
              color: ${theme.ds.colors.primary[700]};

              position: relative;
              left: ${(maxUsers - 1) * AVATAR_OVERLAP * -1}px;
            `}
          >
            <FormattedMessage
              defaultMessage="+{count, number}"
              values={{ count: users.length - maxUsers + 1 }}
            />
          </UnstyledButton>
        </UsersAvatarStackDropdown>
      )}
    </Group>
  );
});

export const UsersAvatarStack = Object.assign(memo(UsersAvatarStackForwardRef), {
  Size: UsersAvatarStackSize,
});
