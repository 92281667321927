import { defineMessages } from 'react-intl';

import { FormulaBuilderUserOperatorNoArgs, FormulaBuilderUserOperatorOneArg } from '@amalia/amalia-lang/formula/types';

export const operatorNoArgsMessages = defineMessages<FormulaBuilderUserOperatorNoArgs>({
  [FormulaBuilderUserOperatorNoArgs.IS_SELECTED]: {
    defaultMessage: 'is selected',
  },

  [FormulaBuilderUserOperatorNoArgs.IS_BLANK]: {
    defaultMessage: 'is blank',
  },

  [FormulaBuilderUserOperatorNoArgs.IS_NOT_BLANK]: {
    defaultMessage: 'is not blank',
  },
});

export const operatorOneArgMessages = defineMessages<FormulaBuilderUserOperatorOneArg>({
  [FormulaBuilderUserOperatorOneArg.MATCHES]: {
    defaultMessage: 'matches',
  },

  [FormulaBuilderUserOperatorOneArg.NOT_MATCHES]: {
    defaultMessage: 'does not match',
  },
});

export const fieldOperatorMessages = defineMessages<
  FormulaBuilderUserOperatorNoArgs | FormulaBuilderUserOperatorOneArg
>({
  [FormulaBuilderUserOperatorNoArgs.IS_SELECTED]: {
    defaultMessage: '“{propertyName}” is selected',
  },

  [FormulaBuilderUserOperatorNoArgs.IS_BLANK]: {
    defaultMessage: '“{propertyName}” is blank',
  },

  [FormulaBuilderUserOperatorNoArgs.IS_NOT_BLANK]: {
    defaultMessage: '“{propertyName}” is not blank',
  },

  [FormulaBuilderUserOperatorOneArg.MATCHES]: {
    defaultMessage: '“{propertyName}” exactly matches',
  },
  [FormulaBuilderUserOperatorOneArg.NOT_MATCHES]: {
    defaultMessage: '“{propertyName}” does not exactly match',
  },
});
