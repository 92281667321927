import { useFormikContext } from 'formik';
import { Fragment, memo, type ReactNode, useCallback } from 'react';

import { Select, type SelectOption, type SelectProps } from '@amalia/design-system/components';
import { type FormikFieldProps, useFormikFieldAdapter } from '@amalia/ext/formik';
import { useBoolState } from '@amalia/ext/react/hooks';
import { type MergeAll } from '@amalia/ext/typescript';
import { log } from '@amalia/kernel/logger/client';
import { type UserInfo } from '@amalia/tenants/users/profile/types';

import { ConfirmClearIntegrationIdSourceModal } from './modal/ConfirmClearIntegrationIdSourceModal';

export type IntegrationIdSourceFormikSelectProps<
  TName extends 'externalId' | 'hrisId',
  TOption extends SelectOption = SelectOption,
> = MergeAll<
  [
    Omit<FormikFieldProps<SelectProps<TOption>>, 'isClearable' | 'name' | 'onChange'>,
    {
      integrationIdName: TName;
      integrationSourceName: TName extends 'externalId'
        ? 'externalIdSource'
        : TName extends 'hrisId'
          ? 'hrisIdSource'
          : never;
      nullValue: TOption['value'];
      confirmModalTitle: ReactNode;
      confirmModalBody: ReactNode;
      focusInput: VoidFunction;
    },
  ]
>;

export const IntegrationIdSourceFormikSelect = memo(function IntegrationIdSourceFormikSelect<
  TName extends 'externalId' | 'hrisId',
  TOption extends SelectOption = SelectOption,
>({
  integrationIdName,
  integrationSourceName,
  nullValue,
  confirmModalTitle,
  confirmModalBody,
  focusInput,
  ...props
}: IntegrationIdSourceFormikSelectProps<TName, TOption>) {
  const { setFieldValue, values } = useFormikContext<Pick<UserInfo, TName | typeof integrationSourceName>>();

  const { onChange: onChangeField, ...formikFieldProps } = useFormikFieldAdapter<TOption['value']>({
    ...props,
    name: integrationSourceName,
    type: 'text',
  });

  const { isConfirmModalOpen, setConfirmModalOpenTrue, setConfirmModalOpenFalse } = useBoolState(
    false,
    'confirmModalOpen',
  );

  const integrationId = values[integrationIdName];

  const setSourceAndFocusIdField = useCallback(
    (source: TOption['value']) => {
      onChangeField(source);

      requestAnimationFrame(() => {
        if (source === nullValue) {
          setFieldValue(integrationIdName, null, true).catch(log.error);
        }
      });

      focusInput();
    },
    [focusInput, integrationIdName, nullValue, setFieldValue, onChangeField],
  );

  const onChange: typeof onChangeField = useCallback(
    (value) => {
      const source = value ?? nullValue;
      if (source === nullValue && !!integrationId) {
        setConfirmModalOpenTrue();
        return;
      }

      setSourceAndFocusIdField(source);
    },
    [nullValue, integrationId, setConfirmModalOpenTrue, setSourceAndFocusIdField],
  );

  const onCancel = useCallback(() => {
    setConfirmModalOpenFalse();
  }, [setConfirmModalOpenFalse]);

  const onConfirmClearIntegrationIdSource = useCallback(() => {
    setConfirmModalOpenFalse();
    setSourceAndFocusIdField(nullValue);
  }, [nullValue, setConfirmModalOpenFalse, setSourceAndFocusIdField]);

  return (
    <Fragment>
      <Select<TOption>
        {...props}
        {...formikFieldProps}
        isClearable
        onChange={onChange}
      />
      {!!isConfirmModalOpen && (
        <ConfirmClearIntegrationIdSourceModal
          body={confirmModalBody}
          isOpen={isConfirmModalOpen}
          title={confirmModalTitle}
          onCancel={onCancel}
          onConfirm={onConfirmClearIntegrationIdSource}
        />
      )}
    </Fragment>
  );
});
