import { Formik, type FormikConfig, type FormikProps, type FormikValues } from 'formik';
import { noop } from 'lodash';
import { type ForwardedRef, forwardRef, memo, useCallback, type ReactElement } from 'react';

import { type Merge } from '@amalia/ext/typescript';

import { SubmissionError } from '../../errors/submissionError';
import { type FormikStatusSubmissionError, FormikStatusType } from '../../types/formikStatus';

export type FormikFormProps<
  TValues extends FormikValues = FormikValues,
  TSubmitResponse = void,
  TSubmitError extends Error = Error,
> = Merge<
  Omit<FormikConfig<TValues>, 'innerRef'>,
  {
    /** Submit handler. Override Formik's default handler to allow returning anything. */
    onSubmit: (...args: Parameters<FormikConfig<TValues>['onSubmit']>) => Promise<TSubmitResponse> | TSubmitResponse;
    /** Called on a successful submit with the return value of onSubmit. */
    onSubmitSuccess?: (response: TSubmitResponse) => void;
    /** Called on a failed submit with the thrown Error. */
    onSubmitFailure?: (error: TSubmitError) => void;
  }
>;

const FormikFormBase = function FormikForm<
  TValues extends FormikValues = FormikValues,
  TSubmitResponse = void,
  TSubmitError extends Error = Error,
>(
  {
    onSubmit,
    onSubmitSuccess = noop,
    onSubmitFailure = noop,
    validateOnMount = true,
    children = undefined,
    ...props
  }: FormikFormProps<TValues, TSubmitResponse, TSubmitError>,
  ref: ForwardedRef<FormikProps<TValues>>,
) {
  const handleSubmit: FormikConfig<TValues>['onSubmit'] = useCallback(
    async (values, formikHelpers) => {
      // Reset the form status when attempting a new submission.
      formikHelpers.setStatus(null);

      try {
        // Submit the form and call the onSubmitSuccess callback with the result.
        onSubmitSuccess(await onSubmit(values, formikHelpers));
      } catch (err) {
        // If the onSubmit method threw a SubmissionError, set the error as formik status.
        // Use this to show the user that there was a global validation error.
        if (err instanceof SubmissionError) {
          formikHelpers.setStatus({
            type: FormikStatusType.SUBMISSION_ERROR,
            error: err,
          } satisfies FormikStatusSubmissionError);
        }

        // Call onSubmitFailure either way.
        onSubmitFailure(err as TSubmitError);
      }
    },
    [onSubmit, onSubmitSuccess, onSubmitFailure],
  );

  return (
    <Formik<TValues>
      {...props}
      innerRef={ref}
      validateOnMount={validateOnMount}
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  );
};

export const FormikForm = memo(forwardRef(FormikFormBase)) as <
  TValues extends FormikValues = FormikValues,
  TSubmitResponse = void,
  TSubmitError extends Error = Error,
>(
  props: FormikFormProps<TValues, TSubmitResponse, TSubmitError> & { ref?: ForwardedRef<FormikProps<TValues>> },
) => ReactElement | null;
