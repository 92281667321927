import { type RefCallback, useEffect, useMemo, useState } from 'react';

export type UseResizeObserverOptions<TElement extends Element> = {
  onResize: (dimensions: { height: number; width: number }, element: TElement) => void;
};

/**
 * Use this hook to detect when an element resizes.
 *
 * @param params - Params.
 * @param params.element - The element.
 * @param params.onResize - Callback when the element resizes.
 */
export const useResizeObserver = <TElement extends Element>({
  onResize,
}: UseResizeObserverOptions<TElement>): RefCallback<TElement> => {
  const [element, setElement] = useState<TElement | null>(null);

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver(([target]) => {
        const { blockSize: height, inlineSize: width } = target.borderBoxSize[0];
        onResize({ height, width }, target.target as TElement);
      }),
    [onResize],
  );

  useEffect(() => {
    if (element) {
      resizeObserver.observe(element);

      return () => {
        onResize({ height: 0, width: 0 }, element);
        resizeObserver.disconnect();
      };
    }

    return undefined;
  }, [element, onResize, resizeObserver]);

  return setElement;
};
