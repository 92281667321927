import { memo, useState } from 'react';
import { generatePath, useNavigate, useLocation, useParams } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import { routes } from '@amalia/core/routes';
import { DataConnectorsApiClient } from '@amalia/data-capture/connectors/state';
import { type DataConnectorTypes } from '@amalia/data-capture/connectors/types';
import { toError } from '@amalia/ext/typescript';
import { AuthenticationErrorPage } from '@amalia/kernel/auth/components';

export const OAuthCallback = memo(function OAuthCallback() {
  const { connector } = useParams<Record<string, string>>();
  const [error, setError] = useState<string | undefined>();
  const queryParams = useLocation().search;
  const navigate = useNavigate();
  useAsyncEffect(async () => {
    if (queryParams) {
      try {
        await DataConnectorsApiClient.authorize(queryParams, connector as DataConnectorTypes);
        navigate(generatePath(routes.DATA_CONNECTOR, { connectorType: connector }));
      } catch (e) {
        setError(toError(e).message);
      }
    }
  }, [queryParams]);
  return error ? <AuthenticationErrorPage message={error} /> : null;
});
