import { css, type Theme } from '@emotion/react';
import { makeStyles } from '@mui/styles';
import { IconSend, IconPencil } from '@tabler/icons-react';
import { type Editor } from '@tiptap/core';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { type JSONContent, useEditor } from '@tiptap/react';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { FormulaEditorContent } from '@amalia/amalia-lang/formula/components';
import { Button, useSnackbars } from '@amalia/design-system/components';
import { assert, toError } from '@amalia/ext/typescript';
import { selectCurrentStatement } from '@amalia/frontend/web-data-layers';
import { PayoutCollaborationCommentsApiClient } from '@amalia/payout-collaboration/comments/api-client';
import { useEditableStatementCommentContext } from '@amalia/payout-collaboration/comments/components';
import { useUpdateCommentMutation } from '@amalia/payout-collaboration/comments/state';
import { type MessageContent } from '@amalia/payout-collaboration/comments/types';

import { useStatementUsers } from '../mentions/useStatementUsers';
import { useSuggestions } from '../mentions/useSuggestions';

import { useFormatCommentMessage } from './useFormatCommentMessage';

const useStyles = makeStyles({
  mention: {
    // new theme primary[400]
    color: '#0c84f2',
    // new theme secondary[50]
    background: '#ecf7ff',
    borderRadius: '4px',
    padding: '4px',
  },
  input: {
    '&.is-editor-empty:first-child::before': {
      content: 'attr(data-placeholder)',
      color: '#adb5bd',
      float: 'left',
      height: 0,
      pointerEvents: 'none',
      fontSize: '14px',
    },
    minHeight: '80px',
    alignItems: 'center',
    padding: '12px 16px',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
  },
});

type CommentFormPresentationProps = {
  readonly onSubmit: (values: MessageContent[]) => Promise<void> | void;
};

/**
 * New comment message form
 */
export const CommentFormPresentation = memo(function CommentFormPresentation({
  onSubmit,
}: CommentFormPresentationProps) {
  const currentStatement = useSelector(selectCurrentStatement);

  const { formatMessage } = useIntl();
  const { snackError } = useSnackbars();
  const editionContext = useEditableStatementCommentContext();
  const { fromContentToEditor, fromEditorToContent } = useFormatCommentMessage();

  const classes = useStyles();

  // Ref are used here because setState doesn't work well with tiptap Editor
  const editorContentRef = useRef<JSONContent>();
  const editorRef = useRef<Editor>();

  const statementUsers = useStatementUsers(
    PayoutCollaborationCommentsApiClient.getUsersAllowedToViewStatement,
    currentStatement,
  );

  const { suggestion, isOpen: isMentionPopupOpened } = useSuggestions(statementUsers);

  const { mutateAsync: editComment } = useUpdateCommentMutation();

  const handleSendComment = useCallback(async () => {
    if (!editorContentRef.current) {
      return;
    }
    assert(editorContentRef.current.content);
    // We have to pass inside the 'doc' object to have the real content of the text area
    const comment = fromEditorToContent(editorContentRef.current.content[0].content);

    if (!comment.length) {
      return;
    }
    editorRef.current?.commands.clearContent(true);
    if (editionContext && editionContext.editedCommentId) {
      const { statementThreadId, editedCommentId, setEditedCommentId, setEditedCommentContent } = editionContext;
      assert(currentStatement.id && statementThreadId);
      await editComment({
        statementId: currentStatement.id,
        statementThreadId,
        messageId: editedCommentId,
        content: comment,
      });
      setEditedCommentId(null);
      setEditedCommentContent([]);
    } else {
      await onSubmit(comment);
    }
  }, [currentStatement.id, editComment, fromEditorToContent, onSubmit, editionContext]);

  const editor = useEditor(
    {
      extensions: [
        Document,
        Paragraph.configure({
          HTMLAttributes: {
            class: classes.input,
            id: 'commentMessage',
          },
        }),
        Text,
        HardBreak,
        Placeholder.configure({
          emptyEditorClass: 'is-editor-empty',
          placeholder: formatMessage({ defaultMessage: 'Add a comment…' }),
        }),
        Mention.configure({
          HTMLAttributes: {
            class: classes.mention,
          },
          suggestion,
        }),
      ],
      injectCSS: false,
      editorProps: {
        handleDOMEvents: {
          keydown: (_, event) => {
            if (event.key === 'Enter' && !event.shiftKey && !isMentionPopupOpened.current) {
              event.preventDefault();
              handleSendComment().catch((e) => snackError(toError(e).message));
            }
            return false;
          },
        },
        attributes: {
          id: 'commentMessageEditor',
        },
      },
      onCreate: ({ editor: currentEditor }) => {
        editorRef.current = currentEditor;
      },
      onUpdate: ({ editor: currentEditor }) => {
        editorContentRef.current = currentEditor.getJSON();
      },
    },
    [suggestion, handleSendComment],
  );

  const edittedCommentJson = useMemo(() => {
    if (!editionContext) {
      return null;
    }
    const { editedCommentContent } = editionContext;
    return !editedCommentContent.length ? null : fromContentToEditor(editedCommentContent);
  }, [editionContext, fromContentToEditor]);

  useEffect(() => {
    if (editor && edittedCommentJson) {
      editor.commands.setContent(edittedCommentJson, true);
    }
  }, [editor, edittedCommentJson]);
  return (
    <div
      css={(theme: Theme) => css`
        border-top: 1px solid ${theme.ds.colors.gray[100]};
        display: flex;
        padding: 12px 16px;
        flex-direction: column;
        text-align: left;
        align-items: flex-end;
        gap: 12px;
      `}
    >
      <div
        css={css`
          flex: 1;
          width: 100%;
        `}
      >
        <FormulaEditorContent
          editor={editor}
          css={css`
            > .ProseMirror {
              padding: 0;
            }
          `}
        />
      </div>
      <div>
        {editionContext && editionContext.editedCommentId ? (
          <Button
            icon={<IconPencil />}
            onClick={handleSendComment}
          >
            <FormattedMessage defaultMessage="Edit comment" />
          </Button>
        ) : (
          <Button
            icon={<IconSend />}
            onClick={handleSendComment}
          >
            <FormattedMessage defaultMessage="Send comment" />
          </Button>
        )}
      </div>
    </div>
  );
});
