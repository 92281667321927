import { css, type Theme } from '@emotion/react';

export const SELECTED_CLASSNAME = 'selected';

export const planAwardIconSelectOption = (theme: Theme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;

  border-radius: ${theme.ds.borderRadiuses.squared};

  outline-offset: -1px;

  transition: ${theme.ds.transitions.default('box-shadow', 'background-color')};

  &:hover {
    background-color: ${theme.ds.colors.gray[50]};
  }

  &:focus-visible {
    outline: 1px solid ${theme.ds.colors.primary[200]};
    box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.primary[50])};
  }

  &.${SELECTED_CLASSNAME} {
    &,
    &:hover,
    &:focus-visible {
      background-color: ${theme.ds.colors.primary[50]};
      outline: 1px solid ${theme.ds.colors.primary[300]};
    }
  }
`;
