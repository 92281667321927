import { defineMessages } from 'react-intl';

import { SortDirection } from '../../DataGrid.types';

export const sortDirectionMessages = defineMessages<SortDirection>({
  [SortDirection.ASC]: {
    defaultMessage: 'Sort “{columnName}” in ascending order',
  },

  [SortDirection.DESC]: {
    defaultMessage: 'Sort “{columnName}” in descending order',
  },
} as const);
