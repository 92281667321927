import { type Theme, css } from '@emotion/react';

export const datePickerBaseHeader = (theme: Theme) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 8px 16px;

  color: ${theme.ds.colors.gray[900]};
`;
