import { css } from '@emotion/react';
import { type Property } from 'csstype';
import { type ForwardedRef, forwardRef, type HTMLProps, type ElementType } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

export type StackProps = MergeAll<
  [
    Omit<HTMLProps<HTMLElement>, 'ref'>,
    {
      /** Override the default element for the variant. */
      readonly as?: ElementType;
      readonly align?: Property.AlignItems;
      readonly justify?: Property.JustifyContent;
      readonly gap?: Property.Gap<number | string>;
      readonly grow?: Property.FlexGrow | boolean | number;
    },
  ]
>;

export const Stack = forwardRef(function Stack(
  {
    as: Component = 'div',
    align = undefined,
    justify = undefined,
    gap = undefined,
    grow = undefined,
    children = undefined,
    ...props
  }: StackProps,
  ref: ForwardedRef<HTMLElement>,
) {
  const gapStr = typeof gap === 'number' ? `${gap}px` : gap;
  const growStr = typeof grow === 'boolean' ? (grow ? '1' : '0') : grow;

  return (
    <Component
      {...props}
      ref={ref}
      css={css`
        display: flex;
        flex-direction: column;
        align-items: ${align};
        justify-content: ${justify};
        gap: ${gapStr};
        flex-grow: ${growStr};
      `}
    >
      {children}
    </Component>
  );
});
