import { without } from 'lodash';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type SelectOption } from '@amalia/design-system/components';
import { useFeatureFlag } from '@amalia/frontend/web-data-layers';
import { CompanyFeatureFlags } from '@amalia/tenants/companies/types';
import { UserRole } from '@amalia/tenants/users/types';

import { UserRoleLabels } from '../../../roles';

export type RoleOption = SelectOption<UserRole>;

export const toRoleOption = (role: UserRole, label: string, option?: Partial<RoleOption>): RoleOption => ({
  ...option,
  value: role,
  label,
});

export const useRoleOptions = (): RoleOption[] => {
  const { formatMessage } = useIntl();
  const { isFeatureEnabled: isFinanceUserRoleFeatureEnabled } = useFeatureFlag(CompanyFeatureFlags.FINANCE_USER_ROLE);

  const filteredRoles = useMemo(
    () =>
      without(
        Object.values(UserRole),
        ...[UserRole.READ_ONLY_EMPLOYEE, !isFinanceUserRoleFeatureEnabled && UserRole.FINANCE].filter(Boolean),
      ),
    [isFinanceUserRoleFeatureEnabled],
  );

  return useMemo(
    () => filteredRoles.map((role) => toRoleOption(role, formatMessage(UserRoleLabels[role]))),
    [formatMessage, filteredRoles],
  );
};
