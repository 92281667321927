import { withStyles } from '@mui/styles';
import { IconCaretDownFilled } from '@tabler/icons-react';
import clsx from 'clsx';
import { memo } from 'react';

const iconStyles = {
  selectIcon: {
    right: 12,
    top: 'unset',
  },
};

export const FilterBarSelectExpandIcon = memo(
  withStyles(iconStyles)(({ className, classes, ...rest }: any) => (
    <IconCaretDownFilled
      {...rest}
      className={clsx(className, classes.selectIcon)}
    />
  )),
);
