import { IconArrowLeft } from '@tabler/icons-react';
import { noop } from 'lodash';
import { memo, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  useGetFormulaBuilderAttributeLabel,
  type FormulaConditionTagProps,
  useFormulaBuilderContext,
} from '@amalia/amalia-lang/formula/components';
import {
  type FormulaBuilderFunctionBlockUserForm,
  type FormulaBuilderFunctionBlockUserOneArgForm,
} from '@amalia/amalia-lang/formula/form/types';
import {
  FormulaBuilderUserOperatorNoArgs,
  FormulaBuilderUserOperatorOneArg,
  ValueOrAttributeType,
} from '@amalia/amalia-lang/formula/types';
import { Dropdown, IconButton, Typography } from '@amalia/design-system/components';
import { TypographyVariant } from '@amalia/design-system/meta';
import { ComponentSwitch } from '@amalia/ext/react/components';
import { isEnum } from '@amalia/ext/typescript';

import { fieldOperatorMessages, operatorNoArgsMessages, operatorOneArgMessages } from './FunctionUserPopover.messages';
import * as styles from './FunctionUserPopover.styles';
import { FunctionUserOneArgPopover } from './one-arg/FunctionUserOneArgPopover';
import { OPERATOR_ICON_MAPPING } from './operator.mappers';

enum FunctionUserPopoverStep {
  OPERATOR = 'OPERATOR',
  OTHER = 'OTHER',
}

export type FunctionUserPopoverProps = {
  readonly condition: FormulaBuilderFunctionBlockUserForm;
  readonly onChange: FormulaConditionTagProps<FormulaBuilderFunctionBlockUserForm>['onChange'];
  readonly onClose?: () => void;
};

export const FunctionUserPopover = memo(function FunctionUserPopover({
  condition,
  onChange = noop,
  onClose = noop,
}: FunctionUserPopoverProps) {
  const { customObjectDefinition } = useFormulaBuilderContext();
  const getFormulaBuilderAttributeLabel = useGetFormulaBuilderAttributeLabel();
  const propertyName = getFormulaBuilderAttributeLabel(condition.args[0]);

  const [step, setStep] = useState<FunctionUserPopoverStep>(
    condition.isDraft || !condition.operator || isEnum(condition.operator, FormulaBuilderUserOperatorNoArgs)
      ? FunctionUserPopoverStep.OPERATOR
      : FunctionUserPopoverStep.OTHER,
  );

  const handleGoToOperatorStep = useCallback(() => setStep(FunctionUserPopoverStep.OPERATOR), []);

  const handleChangeOperator = useCallback(
    (operator: FormulaBuilderUserOperatorNoArgs | FormulaBuilderUserOperatorOneArg) => {
      const args = isEnum(operator, FormulaBuilderUserOperatorOneArg)
        ? [condition.args[0], { type: ValueOrAttributeType.ATTRIBUTE }]
        : [condition.args[0]];

      onChange({
        ...condition,
        operator,
        args,
        isDraft: !isEnum(operator, FormulaBuilderUserOperatorNoArgs),
      } as FormulaBuilderFunctionBlockUserForm);

      if (isEnum(operator, FormulaBuilderUserOperatorOneArg)) {
        setStep(FunctionUserPopoverStep.OTHER);
      } else {
        onClose();
      }
    },
    [condition, onChange, onClose],
  );
  return (
    <div>
      <ComponentSwitch value={step}>
        <ComponentSwitch.Item value={FunctionUserPopoverStep.OPERATOR}>
          <Dropdown.Title>{propertyName}</Dropdown.Title>

          {Object.values(FormulaBuilderUserOperatorNoArgs).map((operator) => (
            <Dropdown.ItemOption
              key={operator}
              checked={condition.operator === operator}
              icon={OPERATOR_ICON_MAPPING[operator]}
              label={<FormattedMessage {...operatorNoArgsMessages[operator]} />}
              tooltip={
                operator === FormulaBuilderUserOperatorNoArgs.IS_SELECTED ? (
                  <FormattedMessage
                    defaultMessage='Owner of "{datasource}" is matching member selected on the statement'
                    values={{
                      datasource: customObjectDefinition?.name || '',
                    }}
                  />
                ) : undefined
              }
              onClick={() => handleChangeOperator(operator)}
            />
          ))}
          {Object.values(FormulaBuilderUserOperatorOneArg).map((operator) => (
            <Dropdown.ItemOption
              key={operator}
              checked={condition.operator === operator}
              icon={OPERATOR_ICON_MAPPING[operator]}
              label={<FormattedMessage {...operatorOneArgMessages[operator]} />}
              onClick={() => handleChangeOperator(operator)}
            />
          ))}
        </ComponentSwitch.Item>

        <ComponentSwitch.Item value={FunctionUserPopoverStep.OTHER}>
          <div css={styles.otherStepPopover}>
            <div css={styles.headerContainer}>
              <IconButton
                icon={<IconArrowLeft />}
                label={<FormattedMessage defaultMessage="Go back to operator" />}
                onClick={handleGoToOperatorStep}
              />

              {!!condition.operator && (
                <Typography variant={TypographyVariant.BODY_SMALL_BOLD}>
                  <FormattedMessage
                    {...fieldOperatorMessages[condition.operator]}
                    values={{ propertyName }}
                  />
                </Typography>
              )}
            </div>
            <FunctionUserOneArgPopover
              condition={condition as FormulaBuilderFunctionBlockUserOneArgForm}
              onChange={onChange}
            />
          </div>
        </ComponentSwitch.Item>
      </ComponentSwitch>
    </div>
  );
});
