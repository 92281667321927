import styled from '@emotion/styled';

export const PlanRulesLayout = Object.assign(
  styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    // Compensate the padding of the first and last children.
    margin: -8px 0;
  `,
  {
    Category: Object.assign(
      styled.div`
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 8px 0;
      `,
      {
        Rules: styled.div`
          /**
           * I could have use a display column + gap 16px, but it's not working very well with react-beautiful-dnd.
           * So I'm adding padding to each element inside the rules, and canceling the margin of the first and last element by setting a negative margin on the container.
           * It works, but it's not very clean. It doesn't work on categories but I don't know why (probably caused by the extra padding on the container).
           */
          margin: -8px 0;
          > * {
            padding: 8px 0;
          }
        `,
      },
    ),
  },
);
