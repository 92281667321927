import { css } from '@emotion/react';
import { memo } from 'react';

import { UserExternalIdSourceLogo } from '@amalia/data-capture/connectors/assets';
import { UserExternalIdSource } from '@amalia/tenants/users/types';

import { testIds } from './test-ids';

export const UserNoIntegrationsLogo = memo(function UserNoIntegrationsLogo() {
  return (
    <UserExternalIdSourceLogo
      data-testid={testIds.noIntegrations}
      externalIdSource={UserExternalIdSource.NONE}
      css={(theme) => css`
        color: ${theme.ds.colors.gray[600]};
      `}
    />
  );
});
