import { toDate, toTimestamp } from '@amalia/ext/dates';
import {
  type ComputedTeamAssignment,
  type TeamAssignment,
  type TeamAssignmentDateFormat,
} from '@amalia/tenants/assignments/teams/types';

export class TeamAssignmentsTransformer {
  public static computeTeamAssignments(
    assignments: TeamAssignmentDateFormat[],
  ): Record<string, Partial<ComputedTeamAssignment>> {
    // So here I'm building a temporary dictionary to index, by userId, current employee and manager assignment.
    // The values of this hashmap contain data from both assignments if they exist.
    return assignments.reduce<Record<string, Partial<ComputedTeamAssignment>>>((acc, assignment) => {
      acc[assignment.userId] = {
        id: assignment.userId,
        userId: assignment.userId,
        assignments: [
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          ...(acc[assignment.userId]?.assignments || []),
          {
            id: assignment.id,
            status: true,
            teamRole: assignment.teamRole,
            effectiveAsOf: assignment.effectiveAsOf || null,
            effectiveUntil: assignment.effectiveUntil || null,
          },
        ],
      };
      return acc;
    }, {});
  }

  public static formatTeamAssignmentFromApi(assignment: TeamAssignment): TeamAssignmentDateFormat {
    return {
      ...assignment,
      effectiveUntil: assignment.effectiveUntil ? toDate(assignment.effectiveUntil) : null,
      effectiveAsOf: assignment.effectiveAsOf ? toDate(assignment.effectiveAsOf) : null,
    };
  }

  public static formatTeamAssignmentToApi(assignment: TeamAssignmentDateFormat): TeamAssignment {
    return {
      ...assignment,
      effectiveUntil: assignment.effectiveUntil ? toTimestamp(assignment.effectiveUntil) : null,
      effectiveAsOf: assignment.effectiveAsOf ? toTimestamp(assignment.effectiveAsOf) : null,
    };
  }
}
