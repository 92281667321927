import { isNil } from 'lodash';
import { type ForwardedRef, forwardRef, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Badge, type BadgeProps, BadgeSize } from '@amalia/design-system/components';
import { canSeeHiddenPlan } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { type Plan } from '@amalia/payout-definition/plans/types';

export type PlanVisibilityBadgeProps = Pick<BadgeProps, 'size'> & {
  readonly isPlanHidden?: Plan['isHidden'] | null;
};

const PlanVisibilityBadgeBase = forwardRef(function PlanVisibilityBadge(
  { isPlanHidden, ...badgeProps }: PlanVisibilityBadgeProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const ability = useAbilityContext();

  // Non-admin user cannot see hidden plans, so there is no need to show the badge
  // since everything they'll see on the app are live plans.
  if (!canSeeHiddenPlan(ability)) {
    return null;
  }

  // In case the plan visibility is not defined, we don't show the badge.
  if (isNil(isPlanHidden)) {
    return null;
  }

  return isPlanHidden ? (
    <Badge
      {...badgeProps}
      ref={ref}
      variant={Badge.Status.WARNING}
    >
      <FormattedMessage defaultMessage="Hidden" />
    </Badge>
  ) : (
    <Badge
      {...badgeProps}
      ref={ref}
      variant={Badge.Status.SUCCESS}
    >
      <FormattedMessage defaultMessage="Live" />
    </Badge>
  );
});

export const PlanVisibilityBadge = Object.assign(memo(PlanVisibilityBadgeBase), {
  Size: BadgeSize,
});
