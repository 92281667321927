import { css } from '@emotion/react';
import { makeStyles } from '@mui/styles';
import { IconChevronLeft, IconSearch } from '@tabler/icons-react';
import clsx from 'clsx';
import { keyBy } from 'lodash';
import moment from 'moment';
import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  formatTotal,
  formatUserFullName,
  formatValueOrPrintRemovedLabel,
  formatValueTotal,
  type Overwrite,
} from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { OverwriteTooltip } from '@amalia/data-correction/overwrites/components';
import { IconButton } from '@amalia/design-system/components';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';

const HANDSHAKE_SIZE = 50;

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '12px',
    backgroundColor: theme.palette.common.white,
    width: '25%',
    position: 'relative',
  },
  handshakeContainer: {
    position: 'absolute',
    top: -HANDSHAKE_SIZE / 2,
    left: `calc(50% - ${HANDSHAKE_SIZE / 2}px)`,
    width: HANDSHAKE_SIZE,
    height: HANDSHAKE_SIZE,
    borderRadius: HANDSHAKE_SIZE / 2,
    backgroundColor: theme.palette.grey['100'],
    color: theme.palette.grey['600'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      height: 27,
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'row',

    '& > td': {
      paddingBottom: theme.spacing(1),
    },
  },
  itemClickable: {
    cursor: 'pointer',
  },
  box: {
    flex: 1,
    paddingRight: theme.spacing(2),
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  value: {
    flex: 1,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
}));

interface TracingItem {
  name: string;
  machineName: string;
  value: string;
  color?: string;
  isSelected?: boolean;
  clickable?: boolean;
}

interface TracingItemsProps {
  readonly items: TracingItem[];
  readonly handleClick: any;
  readonly overwrites?: Overwrite[];
  readonly onClickCollapse: () => void;
}

interface TracingItemWithOverwriteProps {
  readonly item: TracingItem;
  readonly overwrite: Overwrite;
}

export const TracingItemWithOverwrite = memo(function TracingItemWithOverwrite({
  item,
  overwrite,
}: TracingItemWithOverwriteProps) {
  return (
    <OverwriteTooltip
      isReadOnly
      author={formatUserFullName(overwrite.creator)}
      date={moment(overwrite.createdAt, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
      newValue={
        overwrite.overwriteValue[item.machineName]?.symbol
          ? formatTotal(
              overwrite.overwriteValue[item.machineName].value,
              FormatsEnum.currency,
              overwrite.overwriteValue[item.machineName].symbol,
              1,
            )
          : formatValueTotal(
              overwrite.overwriteValue[item.machineName]?.value || overwrite.overwriteValue[item.machineName],
            )
      }
      oldValue={
        overwrite.sourceValue[item.machineName]?.symbol
          ? formatTotal(
              overwrite.sourceValue[item.machineName].value,
              FormatsEnum.currency,
              overwrite.sourceValue[item.machineName].symbol,
              1,
            )
          : formatValueTotal(overwrite.sourceValue[item.machineName]?.value || overwrite.sourceValue[item.machineName])
      }
    >
      <span>{formatValueOrPrintRemovedLabel(item.value)}</span>
    </OverwriteTooltip>
  );
});

/**
 * TracingItems
 * Prints a list of variables
 */
export const TracingItems = memo(function TracingItems({
  items,
  handleClick,
  overwrites,
  onClickCollapse,
}: TracingItemsProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  // Build the hashmap of potential overwrites on this line.
  const overwriteMapByColumn = useMemo(() => keyBy(overwrites || [], 'field'), [overwrites]);

  return (
    <div className={classes.root}>
      <div className={classes.handshakeContainer}>
        <IconSearch />
      </div>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
        `}
      >
        <IconButton
          icon={<IconChevronLeft />}
          label={formatMessage({ defaultMessage: 'Close line details' })}
          size={IconButton.Size.LARGE}
          onClick={onClickCollapse}
        />
      </div>
      <table>
        {/* Prints each variable in its own blocks with its value */}
        {items.map((item) => (
          <tr
            key={item.machineName}
            className={clsx(classes.item, item.clickable && classes.itemClickable)}
            onClick={() => item.clickable && handleClick(item)}
          >
            <td
              className={classes.box}
              style={{ color: item.color }}
            >
              {item.name}
            </td>
            <td className={classes.value}>
              {/* If we found an overwrite, use our dedicated component. */}
              {overwriteMapByColumn[item.machineName] ? (
                <TracingItemWithOverwrite
                  item={item}
                  overwrite={overwriteMapByColumn[item.machineName]}
                />
              ) : (
                <span>{item.value}</span>
              )}
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
});
