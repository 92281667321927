import { defineMessages } from 'react-intl';

import {
  FormulaBuilderUserOperatorNoArgs,
  FormulaBuilderUserOperatorOneArg,
  ValueOrAttributeType,
} from '@amalia/amalia-lang/formula/types';

export const operatorTooltipMessage = defineMessages<
  FormulaBuilderUserOperatorNoArgs | FormulaBuilderUserOperatorOneArg
>({
  [FormulaBuilderUserOperatorNoArgs.IS_SELECTED]: {
    defaultMessage: 'is selected',
  },
  [FormulaBuilderUserOperatorNoArgs.IS_BLANK]: {
    defaultMessage: 'is blank',
  },
  [FormulaBuilderUserOperatorNoArgs.IS_NOT_BLANK]: {
    defaultMessage: 'is not blank',
  },
  [FormulaBuilderUserOperatorOneArg.MATCHES]: {
    defaultMessage: 'Exactly matches',
  },
  [FormulaBuilderUserOperatorOneArg.NOT_MATCHES]: {
    defaultMessage: 'Does not exactly match',
  },
});

export const valueOrAttributeValuesMessages = defineMessages<ValueOrAttributeType>({
  [ValueOrAttributeType.VALUE]: {
    defaultMessage: '"{value}"',
  },

  [ValueOrAttributeType.ATTRIBUTE]: {
    defaultMessage: '{value}',
  },
} as const);
