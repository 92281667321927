import { type FormatsEnum } from '@amalia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { type PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';

export enum VariableObjectsEnum {
  statement = 'statement',
  object = 'object',

  user = 'user',
  team = 'team',
  plan = 'plan',
}

export interface VariableFormatOptionsColumn {
  machineName: string;
  name: string;
  format: FormatsEnum;
}

export interface VariableFormatOptionsTable extends VariableFormatOptions {
  columns: VariableFormatOptionsColumn[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface -- Could be retyped later.
export interface VariableFormatOptions {}

export interface VariableFormulaOptions {
  convertCurrency?: {
    from: string;
    to: string;
  };
  round?: {
    precision: number;
  };
  default?: {
    value: number | string;
  };
}

export interface Variable {
  id: string;
  createdAt: Date;
  updatedAt: Date;

  description: string | null;
  formula: string | null;
  formatOptions: VariableFormatOptions | null;
  frequency: PeriodFrequencyEnum;
  type: VariableObjectsEnum;
  planId: string | null;
  ruleId: string | null;

  // If variable is type object, we have the object definition.
  object?: CustomObjectDefinition | null;

  objectId: string | null;
  isQuotaRequired: boolean | null;

  name: string;
  machineName: string;
  format: FormatsEnum;
  formulaOptions: VariableFormulaOptions | null;
}

// TODO - circular dependency with Plan
export interface VariableWithPlan extends Variable {
  plan: {
    id: string;
    name: string;
    machineName: string;
  };
}

export type VariableDefinition = Pick<
  Variable,
  'description' | 'format' | 'formatOptions' | 'formula' | 'formulaOptions' | 'id' | 'machineName' | 'name' | 'type'
>;
export type VariablesMap = Record<string, Variable>;

export type CreateVariable = Pick<
  Partial<Variable>,
  | 'description'
  | 'formatOptions'
  | 'formula'
  | 'formulaOptions'
  | 'frequency'
  | 'isQuotaRequired'
  | 'machineName'
  | 'planId'
  | 'ruleId'
> &
  Pick<Variable, 'format' | 'name' | 'type'> & {
    objectDefinitionId?: string;
  };

export type UpdateVariable = CreateVariable & Pick<Variable, 'id'>;
