import { css } from '@emotion/react';
import { memo } from 'react';

import { Typography, type TypographyProps } from '../../../general/typography/Typography';

export type DropdownTitleProps = Omit<TypographyProps, 'as' | 'variant'>;

export const DropdownTitle = memo(function DropdownTitle({ children, ...props }: DropdownTitleProps) {
  return (
    <Typography
      {...props}
      as="div"
      variant={Typography.Variant.BODY_SMALL_BOLD}
      css={(theme) => css`
        color: ${theme.ds.colors.gray[800]};
        word-break: break-word;
        padding: 3px 8px;
        & + * {
          margin-top: 6px;
        }
      `}
    >
      {children}
    </Typography>
  );
});
