import { type FormikProps } from 'formik';
import { without } from 'lodash';
import { type ForwardedRef, forwardRef, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import { FormikForm, type FormikFormProps } from '@amalia/ext/formik';
import { type MergeAll } from '@amalia/ext/typescript';
import { PlanSyncActions, editCurrentPlan, useThunkDispatch } from '@amalia/frontend/web-data-layers';
import { PlanRuleCategoryColor, type PlanCategory } from '@amalia/payout-definition/plans/types';

import { getPlanRuleCategoryColor } from '../../helpers/getPlanRuleCategoryColor';

import { type PlanRuleCategoryFormValues } from './PlanRuleCategoryForm.types';

type PlanRuleCategoryFormikProps = FormikFormProps<PlanRuleCategoryFormValues, PlanCategory>;

export type PlanRuleCategoryFormProps = MergeAll<
  [
    Omit<PlanRuleCategoryFormikProps, 'enableReinitialize' | 'initialValues' | 'onSubmit' | 'validationSchema'>,
    {
      /** Set category when editing. If not defined, will consider this is creation mode. */
      readonly category?: PlanCategory;
      /** Category names already used. */
      readonly otherCategoryNames?: string[];
    },
  ]
>;

export const PlanRuleCategoryForm = forwardRef(function PlanRuleCategoryForm(
  { category, otherCategoryNames, ...props }: PlanRuleCategoryFormProps,
  ref: ForwardedRef<FormikProps<PlanRuleCategoryFormValues>>,
) {
  const dispatch = useThunkDispatch();

  const hasCategory = !!category;

  const initialValues: PlanRuleCategoryFormValues = useMemo(
    () => ({
      name: category?.name || '',
      color: category?.color || (category ? getPlanRuleCategoryColor(category) : PlanRuleCategoryColor.YELLOW),
    }),
    [category],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        color: Yup.string().oneOf(Object.values(PlanRuleCategoryColor)).required(),
        name: Yup.string()
          .required()
          .min(2)
          .max(100)
          .notOneOf(without(otherCategoryNames ?? [], category?.name)),
      }),
    [otherCategoryNames, category?.name],
  );

  const onSubmit: PlanRuleCategoryFormikProps['onSubmit'] = useCallback(
    async (values) => {
      if (hasCategory) {
        await dispatch(editCurrentPlan(PlanSyncActions.editCategory(values, category.name)));
      } else {
        await dispatch(editCurrentPlan(PlanSyncActions.createCategory(values)));
      }

      return values;
    },
    [hasCategory, category?.name, dispatch],
  );

  return (
    <FormikForm<PlanRuleCategoryFormValues, PlanCategory>
      {...props}
      ref={ref}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    />
  );
});
