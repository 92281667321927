import { IconSlash } from '@tabler/icons-react';
import { type ElementType, memo } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { IconOverlap } from '../icon-overlap/IconOverlap';
import { type TablerIconProps } from '../icons/types';

export type IconNotProps = MergeAll<
  [
    TablerIconProps,
    {
      /** Icon on the background that'll be "slashed". */
      icon: ElementType<TablerIconProps>;
    },
  ]
>;

export const IconNot = memo(function IconNot({ icon: Icon, ...props }: IconNotProps) {
  return (
    <IconOverlap
      iconBackground={Icon}
      iconForeground={IconSlash}
      {...props}
    />
  );
});
