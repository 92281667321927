import { combineReducers } from 'redux';

import { INITIAL_REDUX_PAGINATION, type ReduxAction } from '../types';

import { TODOS_ACTIONS } from './constants';
import { type TodosReducer } from './types';

export default combineReducers<TodosReducer, ReduxAction>({
  isLoading: (state = 0, action) => {
    switch (action.type) {
      case TODOS_ACTIONS.START:
        return state + 1;
      case TODOS_ACTIONS.ERROR:
      case TODOS_ACTIONS.SET_TODOS_COUNT:
      case TODOS_ACTIONS.SET_TODOS:
        return state - 1;
      default:
        return state;
    }
  },
  count: (state = null, action) => {
    switch (action.type) {
      case TODOS_ACTIONS.SET_TODOS_COUNT:
        return action.payload.todosCount;
      default:
        return state;
    }
  },
  list: (state = INITIAL_REDUX_PAGINATION, action) => {
    switch (action.type) {
      case TODOS_ACTIONS.SET_TODOS:
        return action.payload.todos;
      default:
        return state;
    }
  },
});
