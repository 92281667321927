import { css } from '@emotion/react';
import { memo } from 'react';

import { Group, Stack, Typography } from '@amalia/design-system/components';
import { type BadgeConfiguration } from '@amalia/payout-definition/plans/types';

import { PlanAwardIcon } from '../award-icon/PlanAwardIcon';

export type PlanAwardDetailsProps = {
  /** Award configuration. */
  readonly configuration: BadgeConfiguration;
  /** If false, the award is available but not won. */
  readonly isAwarded?: boolean;
};

export const PlanAwardDetails = memo(function PlanAwardDetails({
  configuration,
  isAwarded = true,
}: PlanAwardDetailsProps) {
  return (
    <Group
      align="center"
      gap={16}
    >
      <PlanAwardIcon
        icon={configuration.icon}
        isAwarded={isAwarded}
        size={48}
      />

      <Stack gap={4}>
        <Typography
          variant={Typography.Variant.BODY_BASE_MEDIUM}
          css={(theme) => css`
            color: ${isAwarded ? theme.ds.colors.gray[900] : theme.ds.colors.gray[500]};
          `}
        >
          {configuration.name}
        </Typography>

        {!!configuration.description && (
          <Typography
            variant={Typography.Variant.BODY_XSMALL_REGULAR}
            css={(theme) => css`
              color: ${isAwarded ? theme.ds.colors.gray[700] : theme.ds.colors.gray[400]};
            `}
          >
            {configuration.description}
          </Typography>
        )}
      </Stack>
    </Group>
  );
});
