import styled from '@emotion/styled';

import { FormLayoutSize } from '../FormLayout.types';

export const FormLayoutTitle = styled.h2`
  transition: ${({ theme }) => theme.ds.transitions.default()};

  [data-form-layout-size=${FormLayoutSize.SMALL}] & {
    ${({ theme }) => theme.ds.typographies.bodyLargeBold};
  }

  [data-form-layout-size=${FormLayoutSize.MEDIUM}] & {
    ${({ theme }) => theme.ds.typographies.heading4Bold};
  }
`;
