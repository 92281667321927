import { http } from '@amalia/core/http/client';
import { RelativePeriodKeyword } from '@amalia/payout-definition/periods/types';
import { type PlanIsHiddenQueryChoices } from '@amalia/payout-definition/plans/types';
import {
  CustomReportsPresetsEnum,
  type PayoutAndPerformanceChartStatistics,
  type Statistics,
} from '@amalia/reporting/custom-reports/shared';

const apiEndpoint = '/dashboards';

const getWidget = async <ExpectedReturnType = Statistics>(
  reportId: CustomReportsPresetsEnum,
  filterParams: {
    periodIds?: string[];
    planIds?: string[];
    teamIds?: string[];
    userIds?: string[];
    referenceDate?: string;
    planHiddenStatus?: PlanIsHiddenQueryChoices;
    limit?: number;
  },
): Promise<ExpectedReturnType> => {
  const { data } = await http.get<ExpectedReturnType>(`${apiEndpoint}/widget`, {
    params: {
      reportId,
      ...filterParams,
    },
  });

  return data;
};

const NB_MONTHS_TO_RETRIEVE = 12;

export const getOvertimeWidget = async ({
  periodIds,
  referenceDate,
  userIds,
  planIds,
  teamIds,
  planHiddenStatus,
}: {
  periodIds: RelativePeriodKeyword[];
  referenceDate?: string;
  userIds?: string[];
  planIds?: string[];
  teamIds?: string[];
  planHiddenStatus?: PlanIsHiddenQueryChoices;
}): Promise<PayoutAndPerformanceChartStatistics> =>
  getWidget<PayoutAndPerformanceChartStatistics>(CustomReportsPresetsEnum.PRESET_EARNED_OVER_TIME, {
    periodIds,
    planIds,
    teamIds,
    userIds,
    planHiddenStatus,
    referenceDate,
    limit: NB_MONTHS_TO_RETRIEVE,
  });

export const getOvertimeByPlanWidget = async (
  referenceDate?: string,
  userIds?: string[],
  planIds?: string[],
  teamIds?: string[],
): Promise<PayoutAndPerformanceChartStatistics> =>
  getWidget<PayoutAndPerformanceChartStatistics>(CustomReportsPresetsEnum.PRESET_EARNED_OVER_TIME_BY_PLAN, {
    referenceDate,
    planIds,
    teamIds,
    userIds,
    periodIds: [RelativePeriodKeyword.YEAR_TO_DATE],
    limit: NB_MONTHS_TO_RETRIEVE,
  });

export const getPlanKpisWidget = async (
  periodId: string,
  userIds?: string[],
  planIds?: string[],
  teamIds?: string[],
): Promise<Statistics> =>
  getWidget(CustomReportsPresetsEnum.PRESET_PLAN_KPIS, { periodIds: [periodId], planIds, teamIds, userIds });

export const getLeaderBoardWidget = async (
  periodId: string,
  userIds?: string[],
  planIds?: string[],
  teamIds?: string[],
): Promise<Statistics> =>
  getWidget(CustomReportsPresetsEnum.PRESET_LEADERBOARD, { periodIds: [periodId], planIds, teamIds, userIds });

export const getBenchmarkByPlanWidget = async (
  periodId: string,
  userIds?: string[],
  planIds?: string[],
  teamIds?: string[],
): Promise<Statistics> =>
  getWidget(CustomReportsPresetsEnum.PRESET_BENCHMARK_BY_PLAN, { periodIds: [periodId], planIds, teamIds, userIds });

export const getBenchmarkByRuleWidget = async (
  periodId: string,
  userIds?: string[],
  planIds?: string[],
  teamIds?: string[],
): Promise<Statistics> =>
  getWidget(CustomReportsPresetsEnum.PRESET_BENCHMARK_BY_RULE, { periodIds: [periodId], planIds, teamIds, userIds });
