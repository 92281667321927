import { useEffect, useRef, useCallback } from 'react';

/**
 * Return a function that returns a boolean indicating if the component is mounted.
 * Component is considered mounted right after the first render, and not during first render.
 */
export const useIsMounted = (): (() => boolean) => {
  const mountedRef = useRef(false);
  const get = useCallback(() => mountedRef.current, []);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return get;
};
