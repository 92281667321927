import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import { type CustomReportConfigurationField } from '@amalia/reporting/custom-reports/shared';
import {
  type ChartFacet,
  type ChartType,
  type CreateDashboardChartRequest,
  type Dashboard,
  type DashboardChart,
  type DashboardChartConfiguration,
  type DashboardChartUserFilters,
  type PatchDashboardChartRequest,
} from '@amalia/reporting/dashboards-v2/types';

import { DashboardChartsApiClient } from './api-client';
import { DASHBOARDS_QUERY_KEYS } from './queries.keys';

////////////////////////
// QUERIES
////////////////////////
export const useFindCharts = (
  searchParams: Parameters<typeof DashboardChartsApiClient.findCharts>[0],
  enabled?: boolean,
) =>
  useQuery({
    queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARD_CHARTS, 'find', searchParams],
    queryFn: () => DashboardChartsApiClient.findCharts(searchParams),
    enabled,
  });

export const useFindChartsOnDashboard = (dashboardId: Dashboard['id'], enabled?: boolean) =>
  useQuery({
    queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARD_CHARTS, 'dashboards', dashboardId],
    queryFn: () => DashboardChartsApiClient.findChartsOnDashboard(dashboardId),
    enabled,
  });

export const useGetChartData = <TChartType extends ChartType>(
  dashboardId: Dashboard['id'],
  chartId: DashboardChart<TChartType>['id'],
  filters: DashboardChartUserFilters,
) =>
  useQuery({
    queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARD_CHARTS, 'chart-data', chartId, filters],
    queryFn: () => DashboardChartsApiClient.getChartData(dashboardId, chartId, filters),
  });

export const useGetChartFacet = <TChartType extends ChartType, TChartFacet extends ChartFacet>(
  dashboardId: Dashboard['id'],
  chartId: DashboardChart<TChartType>['id'],
  field: CustomReportConfigurationField,
  enabled?: boolean,
) =>
  useQuery({
    queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARD_CHARTS, 'dashboards', dashboardId, 'charts', chartId, 'facet', field],
    queryFn: () => DashboardChartsApiClient.getChartFacet<TChartType, TChartFacet>(dashboardId, chartId, field),
    initialData: [],
    enabled,
  });

export const usePreviewDashboardCharts = <TChartType extends ChartType>(
  dashboardChartConfiguration: DashboardChartConfiguration<TChartType>,
  enabled?: boolean,
) =>
  useQuery({
    queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARD_CHARTS, 'preview', dashboardChartConfiguration],
    queryFn: () => DashboardChartsApiClient.getChartPreviewData(dashboardChartConfiguration),
    enabled,
  });

//////////////////////////
// MUTATIONS
//////////////////////////

export const useCreateDashboardChart = <TChartType extends ChartType>(dashboardId: Dashboard['id'] | null) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: [DASHBOARDS_QUERY_KEYS.DASHBOARD_CHARTS, 'dashboards', dashboardId, 'charts', 'create'],
    mutationFn: (createChartRequest: CreateDashboardChartRequest<TChartType>) =>
      DashboardChartsApiClient.createChart(dashboardId!, createChartRequest),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Chart created' }));

      // Need to refetch the dashboard to update the charts layout config
      await queryClient.invalidateQueries({
        queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARDS, dashboardId],
      });

      await queryClient.invalidateQueries({
        queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARD_CHARTS],
      });
    },
    onError: (error) => {
      snackError(toError(error).message);
    },
  });
};

export const usePatchDashboardChart = <TChartType extends ChartType>(
  dashboardId: Dashboard['id'] | null,
  chartId: DashboardChart<TChartType>['id'] | null,
) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: [DASHBOARDS_QUERY_KEYS.DASHBOARD_CHARTS, 'dashboards', dashboardId, 'charts', chartId, 'patch'],
    mutationFn: (patchChartRequest: PatchDashboardChartRequest<TChartType>) =>
      DashboardChartsApiClient.patchChart(dashboardId!, chartId!, patchChartRequest),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Chart updated' }));

      await queryClient.invalidateQueries({
        queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARD_CHARTS],
      });
    },
    onError: (error) => {
      snackError(toError(error).message);
    },
  });
};

export const useDeleteDashboardChart = (
  dashboardId: Dashboard['id'] | null,
  chartId: DashboardChart<ChartType>['id'] | null,
) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: [DASHBOARDS_QUERY_KEYS.DASHBOARD_CHARTS, 'dashboards', dashboardId, 'charts', chartId, 'delete'],
    mutationFn: () => DashboardChartsApiClient.deleteChart(dashboardId!, chartId!),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Chart deleted' }));

      // Need to refetch the dashboard to update the charts layout config
      await queryClient.invalidateQueries({
        queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARDS, dashboardId],
      });

      await queryClient.invalidateQueries({
        queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARD_CHARTS],
      });
    },
    onError: (error) => {
      snackError(toError(error).message);
    },
  });
};
