export enum FieldSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum LabelPosition {
  DEFAULT = 'DEFAULT',
  RIGHT = 'RIGHT',
}
