import { v4 as uuid } from 'uuid';

import {
  FormulaBuilderBlockType,
  FormulaBuilderLogicalOperatorType,
  FormulaBuilderFunctionCategory,
  ValueOrAttributeType,
  type AttributeValue,
  type AttributeValueDate,
} from '@amalia/amalia-lang/formula/types';

import {
  type FormulaBuilderFormulaBlockForm,
  type FormulaBuilderLogicalOperatorBlockForm,
  type FormulaBuilderFunctionBlockStringForm,
  type FormulaBuilderForm,
  type FormulaBuilderFunctionBlockDateForm,
  type FormulaBuilderFunctionBlockUserForm,
  type FormulaBuilderFunctionBlockNumberForm,
  type FormulaBuilderFunctionBlockPercentForm,
  type FormulaBuilderFunctionBlockBooleanForm,
  type FormulaBuilderFunctionBlockCurrencyForm,
} from './types';

/**
 * Create an empty formula block.
 */
export const createFormulaBlock = (): FormulaBuilderFormulaBlockForm => ({
  id: uuid(),
  type: FormulaBuilderBlockType.FORMULA,
  label: '',
  isDraft: true,
  formula: '',
});

/**
 * Create an empty string function block.
 */
export const createFunctionStringBlock = (attribute: AttributeValue): FormulaBuilderFunctionBlockStringForm => ({
  id: uuid(),
  type: FormulaBuilderBlockType.FUNCTION,
  category: FormulaBuilderFunctionCategory.STRING,
  operator: null,
  caseSensitive: true,
  not: false,
  args: [
    attribute,
    {
      type: ValueOrAttributeType.ATTRIBUTE,
    },
  ],
  isDraft: true,
});

/**
 * Create an empty date function block.
 */
export const createFunctionDateBlock = (attribute: AttributeValue): FormulaBuilderFunctionBlockDateForm => ({
  id: uuid(),
  type: FormulaBuilderBlockType.FUNCTION,
  category: FormulaBuilderFunctionCategory.DATE,
  operator: null,
  args: [
    {
      ...attribute,
      options: {
        transform: null,
        value: null,
      },
    } satisfies AttributeValueDate,
    {
      type: ValueOrAttributeType.ATTRIBUTE,
      options: {
        transform: null,
        value: null,
      },
    },
  ],
  isDraft: true,
});

/**
 * Create an empty user function block.
 */
export const createFunctionUserBlock = (attribute: AttributeValue): FormulaBuilderFunctionBlockUserForm => ({
  id: uuid(),
  type: FormulaBuilderBlockType.FUNCTION,
  category: FormulaBuilderFunctionCategory.USER,
  operator: null,
  caseSensitive: false,
  args: [attribute],
  isDraft: true,
});

/**
 * Create an empty number function block.
 */
export const createFunctionNumberBlock = (attribute: AttributeValue): FormulaBuilderFunctionBlockNumberForm => ({
  id: uuid(),
  type: FormulaBuilderBlockType.FUNCTION,
  category: FormulaBuilderFunctionCategory.NUMBER,
  operator: null,
  args: [attribute],
  isDraft: true,
});

/**
 * Create an empty percent function block.
 */
export const createFunctionPercentBlock = (attribute: AttributeValue): FormulaBuilderFunctionBlockPercentForm => ({
  id: uuid(),
  type: FormulaBuilderBlockType.FUNCTION,
  category: FormulaBuilderFunctionCategory.PERCENT,
  operator: null,
  args: [attribute],
  isDraft: true,
});

/**
 * Create an empty currency function block.
 */
export const createFunctionCurrencyBlock = (attribute: AttributeValue): FormulaBuilderFunctionBlockCurrencyForm => ({
  id: uuid(),
  type: FormulaBuilderBlockType.FUNCTION,
  category: FormulaBuilderFunctionCategory.CURRENCY,
  operator: null,
  args: [attribute],
  isDraft: true,
});

/**
 * Create an empty boolean function block.
 */
export const createFunctionBooleanBlock = (attribute: AttributeValue): FormulaBuilderFunctionBlockBooleanForm => ({
  id: uuid(),
  type: FormulaBuilderBlockType.FUNCTION,
  category: FormulaBuilderFunctionCategory.BOOLEAN,
  operator: null,
  args: [attribute],
  isDraft: true,
});

/**
 * Create a new logical operator block with an empty formula.
 */
export const createLogicalOperatorBlock = (operand: FormulaBuilderLogicalOperatorBlockForm['operands'][number]) =>
  ({
    id: uuid(),
    type: FormulaBuilderBlockType.LOGICAL_OPERATOR,
    logicalOperator: FormulaBuilderLogicalOperatorType.AND,
    operands: [operand],
  }) satisfies FormulaBuilderLogicalOperatorBlockForm;

/**
 * Set the logical operator of a LogicalOperatorBlock.
 *
 * @param condition - Operator block.
 * @param operator - Logical operator.
 * @returns Updated logical operator block (immutable).
 */
export const setLogicalOperator = <TOperatorBlock extends FormulaBuilderLogicalOperatorBlockForm>(
  condition: TOperatorBlock,
  operator: FormulaBuilderLogicalOperatorType,
): TOperatorBlock => ({
  ...condition,
  logicalOperator: operator,
});

/**
 * Remove an operand from a LogicalOperatorBlock by its id.
 *
 * @param operatorBlock - Logical operator block whose operand to remove.
 * @param operandId - Id of the operand to remove.
 * @returns Updated logical operator block (immutable).
 */
export const removeOperand = <TOperatorBlock extends FormulaBuilderLogicalOperatorBlockForm>(
  operatorBlock: TOperatorBlock,
  operandId: TOperatorBlock['operands'][number]['id'],
): TOperatorBlock => ({
  ...operatorBlock,
  operands: operatorBlock.operands.filter((operand) => operand.id !== operandId),
});

/**
 * Add an operand to the LogicalOperatorBlock. It will be added as last operand.
 *
 * @param operatorBlock - Logical operator block.
 * @param operand - Operand to add.
 * @returns Updated logical operator block (immutable).
 */
export const addOperand = <TOperatorBlock extends FormulaBuilderLogicalOperatorBlockForm>(
  operatorBlock: TOperatorBlock,
  operand: TOperatorBlock['operands'][number],
): TOperatorBlock => ({
  ...operatorBlock,
  operands: [...operatorBlock.operands, operand],
});

/**
 * Replace an operand in a LogicalOperatorBlock. If the operand does not exist, this does nothing.
 *
 * @param operatorBlock - Logical operator block.
 * @param operand - Operand to replace.
 * @returns Updated logical operator block (immutable).
 */
export const setOperand = <TOperatorBlock extends FormulaBuilderLogicalOperatorBlockForm>(
  operatorBlock: TOperatorBlock,
  operand: TOperatorBlock['operands'][number],
): TOperatorBlock => ({
  ...operatorBlock,
  operands: operatorBlock.operands.map((block) => (block.id === operand.id ? operand : block)),
});

/**
 * Update the formula builder root block.
 *
 * @param formulaBuilder - Formula builder.
 * @param rootBlock - Updated root block.
 * @returns Updated formula builder (immutable).
 */
export const setFormulaBuilderRootBlock = (
  formulaBuilder: FormulaBuilderForm,
  rootBlock: FormulaBuilderForm['root'],
): FormulaBuilderForm => ({
  ...formulaBuilder,
  root: rootBlock,
});
