import { useCallback, useRef } from 'react';

export const useFocusInput = () => {
  const ref = useRef<HTMLInputElement | null>(null);

  const focusInput = useCallback(() => {
    /*
     * onChangeField will trigger a schema validation, which may cause the input to be in error
     * It would then be re-rendered and immediately lose focus.
     * Wait for the rerender using a requestAnimationFrame before focusing the input.
     */
    requestAnimationFrame(() => {
      ref.current?.focus();
    });
  }, [ref]);

  return {
    ref,
    focusInput,
  };
};
