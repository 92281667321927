import { type Theme } from '@emotion/react';

import { ColorCategory, HueCategory } from '@amalia/design-system/meta';

export const DEFAULT_THEME_HUES = {
  [HueCategory.BLUE]: {
    50: '#edf4fd',
    100: '#c7def9',
    300: '#85b7f2',
    500: '#4994ec',
    700: '#3469a8',
    900: '#1f3e63',
  },

  [HueCategory.GREEN]: {
    50: '#f1fbf3',
    100: '#d3f2da',
    300: '#a0e4af',
    500: '#69d081',
    700: '#4b945c',
    900: '#2c5736',
  },

  [HueCategory.ORANGE]: {
    50: '#fff6e8',
    100: '#fde2b8',
    300: '#fcbf65',
    500: '#faa019',
    700: '#b27212',
    900: '#69430b',
  },

  [HueCategory.RED]: {
    50: '#fbeeed',
    100: '#f2c9c8',
    300: '#e28b87',
    500: '#d4524c',
    700: '#973a36',
    900: '#592220',
  },

  [HueCategory.CYAN]: {
    50: '#f0f8f9',
    100: '#d0e8ed',
    300: '#99cdd9',
    700: '#49818d',
  },

  [HueCategory.MAGENTA]: {
    50: '#faf1f8',
    100: '#eed4ea',
    300: '#dba3d1',
    700: '#8f5385',
  },

  [HueCategory.BROWN]: {
    50: '#f0ece6',
    100: '#d0c3b0',
    300: '#987c54',
    700: '#482b00',
  },

  [HueCategory.YELLOW]: {
    50: '#fff9e6',
    100: '#ffebb0',
    200: '#ffe28a',
    300: '#ffd454',
    400: '#ffcc33',
    500: '#ffbf00',
    700: '#cf8900',
  },

  [HueCategory.PURPLE]: {
    50: '#f0ebff',
    100: '#d1c0ff',
    200: '#baa2ff',
    300: '#9b78ff',
    400: '#885dff',
    500: '#6a35ff',
    700: '#4b26b5',
  },
} as const satisfies Theme['ds']['hues'];

export const DEFAULT_THEME_COLORS = {
  [ColorCategory.PRIMARY]: {
    50: '#ecf7ff',
    100: '#c2e2ff',
    200: '#9fd0fe',
    300: '#69b7ff',
    400: '#0c84f2',
    500: '#0f38a5',
    700: '#0b2364',
  },

  [ColorCategory.INFO]: DEFAULT_THEME_HUES[HueCategory.BLUE],
  [ColorCategory.SUCCESS]: DEFAULT_THEME_HUES[HueCategory.GREEN],
  [ColorCategory.WARNING]: DEFAULT_THEME_HUES[HueCategory.ORANGE],
  [ColorCategory.DANGER]: DEFAULT_THEME_HUES[HueCategory.RED],

  [ColorCategory.GRAY]: {
    0: '#ffffff',
    50: '#f9f9f9',
    100: '#ededed',
    200: '#d7d7d7',
    300: '#c4c4c4',
    400: '#b2b2b2',
    500: '#8b8b8b',
    600: '#6c6c6c',
    700: '#525252',
    800: '#353535',
    900: '#111111',
  },

  [ColorCategory.GRAY_SECONDARY]: {
    50: '#fafbfd',
    100: '#f0f3f8',
    300: '#c0cbdd',
  },
} as const satisfies Theme['ds']['colors'];
