import { css } from '@emotion/react';
import { FieldArray, useField } from 'formik';
import { memo } from 'react';

import { type Variable } from '@amalia/amalia-lang/tokens/types';
import { Button, Stack } from '@amalia/design-system/components';
import { BadgeConfigurationIcon, BadgeConfigurationType } from '@amalia/payout-definition/plans/types';

import { PlanAwardsAddAwardButton } from './add-award-button/PlanAwardsAddAwardButton';
import { PlanAwardFormFields } from './award-form-fields/PlanAwardFormFields';
import { PlanAwardsFormEmptyState } from './empty-state/PlanAwardsFormEmptyState';
import { type PlanAwardFormValue } from './PlanAwardsForm.types';

const createEmptyBadgeConfiguration = (): PlanAwardFormValue => ({
  name: '',
  description: '',
  icon: BadgeConfigurationIcon.WINNER,
  variableId: null,
  type: BadgeConfigurationType.KPI,
});

export type PlanAwardsFormProps = {
  /** Variables to choose from for each badge. */
  readonly variables: Variable[];
  /**
   * Override default field name.
   * @default 'badgeConfigurations'
   */
  readonly name?: string;
  /** Are all fields disabled. */
  readonly disabled?: boolean;
};

export const PlanAwardsForm = memo(function PlanAwardsForm({
  disabled = false,
  name = 'badgeConfigurations',
  variables,
}: PlanAwardsFormProps) {
  const [{ value: badgeConfigurations }] = useField<PlanAwardFormValue[]>(name);

  return (
    <FieldArray name={name}>
      {({ push }) =>
        badgeConfigurations.some((badge) => !badge.deletedAt) ? (
          <Stack gap={24}>
            {badgeConfigurations.map(
              (badgeConfiguration, index) =>
                !badgeConfiguration.deletedAt && (
                  <div
                    key={index} // eslint-disable-line react/no-array-index-key -- We never delete elements from the array.
                    css={(theme) => css`
                      padding: 16px 12px 24px;
                      border-bottom: 1px solid ${theme.ds.colors.gray[100]};
                    `}
                  >
                    <PlanAwardFormFields
                      disabled={disabled}
                      name={`${name}.${index}`}
                      variables={variables}
                    />
                  </div>
                ),
            )}

            <div
              css={css`
                align-self: flex-start;
              `}
            >
              <PlanAwardsAddAwardButton
                disabled={disabled}
                variant={Button.Variant.PRIMARY_LIGHT}
                onClick={() => push(createEmptyBadgeConfiguration())}
              />
            </div>
          </Stack>
        ) : (
          <PlanAwardsFormEmptyState
            disabled={disabled}
            onAddBadge={() => push(createEmptyBadgeConfiguration())}
          />
        )
      }
    </FieldArray>
  );
});
