import { memo, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal } from '@amalia/design-system/components';

export type ConfirmResetIntegrationEditPopoverModalProps = {
  readonly isOpen: boolean;
  readonly onConfirm: VoidFunction;
  readonly onCancel: VoidFunction;
  readonly children: ReactNode;
};

export const ConfirmResetIntegrationEditPopoverModal = memo(function ConfirmResetIntegrationEditPopoverModal({
  isOpen,
  children,
  onConfirm,
  onCancel,
}: ConfirmResetIntegrationEditPopoverModalProps) {
  return (
    <Modal
      isDismissable
      isOpen={isOpen}
      size={Modal.Size.SMALL}
      variant={Modal.Variant.DANGER}
      onClose={onCancel}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Leave without saving?" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal.Content>
      <Modal.Actions>
        <Modal.CancelAction />
        <Modal.MainAction onClick={onConfirm}>
          <FormattedMessage defaultMessage="Leave and remove" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
