import { type ColorValue } from '@amalia/design-system/meta';
import { type CustomReportColumn, type CustomReportValue } from '@amalia/reporting/custom-reports/shared';

/** These are the type of series, not necessarily ChartType. It's used to determine the icon in the legend and tooltip. */
export enum ChartSeriesType {
  BAR = 'BAR',
}

/** Chart helpers for a CustomReportColumn. */
export type ChartColumnMetadata = {
  /** Column from custom report metadata. */
  readonly column: CustomReportColumn;
  /** Path to data in each payload. */
  readonly dataKey: string;
  /** Format a value in the chart. */
  readonly formatValue: (value: CustomReportValue) => string;
  /** Format a value in the chart. */
  readonly formatTooltipValue: (value?: CustomReportValue) => string;
  /** Format a value for each tick. */
  readonly formatTick: (value?: number | string | null) => string;
};

/** Used for tooltip and legend. */
export type ChartSeriesMetadata = {
  /** Metadata for the column. */
  readonly columnMetadata: ChartColumnMetadata;
  /** Series icon shape. */
  readonly type: ChartSeriesType;
  /** Series icon color. */
  readonly color: ColorValue;
};
