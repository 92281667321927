import { createContext, type Dispatch, type SetStateAction, useContext } from 'react';

import { type MessageContent } from '@amalia/payout-collaboration/comments/types';

export type EditableStatementCommentContextValue = {
  statementThreadId: string | null;
  editedCommentId: string | null;
  editedCommentContent: MessageContent[];
  setEditedCommentId: Dispatch<SetStateAction<string | null>>;
  setEditedCommentContent: Dispatch<SetStateAction<MessageContent[]>>;
};

export const EditableStatementCommentContext = createContext<EditableStatementCommentContextValue | null>(null);

export const useEditableStatementCommentContext = (): EditableStatementCommentContextValue | null =>
  useContext(EditableStatementCommentContext);
