import { css } from '@emotion/react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  UserExternalIdSourceLabel,
  UserExternalIdSourceLogo,
  UserHrisIdSourceLabel,
  UserHrisIdSourceLogo,
} from '@amalia/data-capture/connectors/assets';
import { Tooltip } from '@amalia/design-system/components';
import { UserExternalIdSource, UserHrisIdSource } from '@amalia/tenants/users/types';

import { testIds, UserNoIntegrationsLogo } from './logos';
import { UserIntegrationTooltip } from './tooltip';
import { type UserIntegrationsInfo } from './types';

export type UserIntegrationsProps = {
  readonly user: UserIntegrationsInfo;
};

export const UserIntegrations = memo(function UserIntegrations({ user }: UserIntegrationsProps) {
  const {
    externalId,
    externalIdSource = UserExternalIdSource.OTHERSOURCE,
    hrisId,
    hrisIdSource = UserHrisIdSource.OTHERSOURCE,
  } = user;

  const isExternalIdSourceNone = !externalId || externalIdSource === UserExternalIdSource.NONE;
  const isHrisIdSourceNone = !hrisId || hrisIdSource === UserHrisIdSource.NONE;
  const areBothSourcesNone = isExternalIdSourceNone && isHrisIdSourceNone;

  // Only display one "None" logo if both sources are none

  if (areBothSourcesNone) {
    return (
      <div data-testid="user-integrations-container">
        <Tooltip
          content={<FormattedMessage defaultMessage="No integrations" />}
          placement="bottom"
        >
          <span data-testid="user-integrations-tooltip-anchor">
            <UserNoIntegrationsLogo />
          </span>
        </Tooltip>
      </div>
    );
  }

  return (
    <div
      data-testid="user-integrations-container"
      css={css`
        display: flex;
        align-items: center;
        gap: 12px;

        width: fit-content;
      `}
    >
      {/* Don't display a "None" logo if the other is not "None" */}
      {!isExternalIdSourceNone && (
        <UserIntegrationTooltip
          isOtherSource={externalIdSource === UserExternalIdSource.OTHERSOURCE}
          kind="externalId"
          sourceLabel={<UserExternalIdSourceLabel externalIdSource={externalIdSource} />}
          value={externalId}
        >
          <UserExternalIdSourceLogo
            compact
            data-testid={testIds.externalIdSource(user)}
            externalIdSource={externalId ? externalIdSource : UserExternalIdSource.NONE}
          />
        </UserIntegrationTooltip>
      )}
      {!isHrisIdSourceNone && (
        <UserIntegrationTooltip
          isOtherSource={hrisIdSource === UserHrisIdSource.OTHERSOURCE}
          kind="hrisId"
          sourceLabel={<UserHrisIdSourceLabel hrisIdSource={hrisIdSource} />}
          value={hrisId}
        >
          <UserHrisIdSourceLogo
            compact
            data-testid={testIds.hrisIdSource(user)}
            hrisIdSource={hrisId ? hrisIdSource : UserHrisIdSource.NONE}
          />
        </UserIntegrationTooltip>
      )}
    </div>
  );
});
