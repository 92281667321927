import { css } from '@emotion/react';
import { Suspense, memo, lazy, type LazyExoticComponent } from 'react';

import { Skeleton } from '@amalia/design-system/components';
import { type Asset } from '@amalia/ext/react/types';
import { BadgeConfigurationIcon } from '@amalia/payout-definition/plans/types';

const getLazySvg = async (importedSvg: Promise<{ ReactComponent: Asset }>) => ({
  default: (await importedSvg).ReactComponent,
});

const AwardIcons: Record<BadgeConfigurationIcon, LazyExoticComponent<Asset>> = {
  [BadgeConfigurationIcon.BEST_DEAL]: lazy(() => getLazySvg(import('./assets/badge_bestdeal.svg'))),
  [BadgeConfigurationIcon.FIRST]: lazy(() => getLazySvg(import('./assets/badge_first.svg'))),
  [BadgeConfigurationIcon.SECOND]: lazy(() => getLazySvg(import('./assets/badge_second.svg'))),
  [BadgeConfigurationIcon.THIRD]: lazy(() => getLazySvg(import('./assets/badge_third.svg'))),
  [BadgeConfigurationIcon.INTERNATIONAL]: lazy(() => getLazySvg(import('./assets/badge_international.svg'))),
  [BadgeConfigurationIcon.OVER_PERFORMER]: lazy(() => getLazySvg(import('./assets/badge_overperformer.svg'))),
  [BadgeConfigurationIcon.STAR]: lazy(() => getLazySvg(import('./assets/badge_star.svg'))),
  [BadgeConfigurationIcon.TEAM_PLAYER]: lazy(() => getLazySvg(import('./assets/badge_teamplayer.svg'))),
  [BadgeConfigurationIcon.WINNER]: lazy(() => getLazySvg(import('./assets/badge_winner.svg'))),
  [BadgeConfigurationIcon.WIN_RATE]: lazy(() => getLazySvg(import('./assets/badge_winrate.svg'))),
} as const;

const AwardNotAwardedIcons: Record<BadgeConfigurationIcon, LazyExoticComponent<Asset>> = {
  [BadgeConfigurationIcon.BEST_DEAL]: lazy(() => getLazySvg(import('./assets/badge_bestdeal_bw.svg'))),
  [BadgeConfigurationIcon.FIRST]: lazy(() => getLazySvg(import('./assets/badge_first_bw.svg'))),
  [BadgeConfigurationIcon.SECOND]: lazy(() => getLazySvg(import('./assets/badge_second_bw.svg'))),
  [BadgeConfigurationIcon.THIRD]: lazy(() => getLazySvg(import('./assets/badge_third_bw.svg'))),
  [BadgeConfigurationIcon.INTERNATIONAL]: lazy(() => getLazySvg(import('./assets/badge_international_bw.svg'))),
  [BadgeConfigurationIcon.OVER_PERFORMER]: lazy(() => getLazySvg(import('./assets/badge_overperformer_bw.svg'))),
  [BadgeConfigurationIcon.STAR]: lazy(() => getLazySvg(import('./assets/badge_star_bw.svg'))),
  [BadgeConfigurationIcon.TEAM_PLAYER]: lazy(() => getLazySvg(import('./assets/badge_teamplayer_bw.svg'))),
  [BadgeConfigurationIcon.WINNER]: lazy(() => getLazySvg(import('./assets/badge_winner_bw.svg'))),
  [BadgeConfigurationIcon.WIN_RATE]: lazy(() => getLazySvg(import('./assets/badge_winrate_bw.svg'))),
} as const;

export type PlanAwardIconProps = {
  readonly icon: BadgeConfigurationIcon;
  readonly size: number;
  readonly isAwarded?: boolean;
};

export const PlanAwardIcon = memo(function PlanAwardIcon({ icon, size, isAwarded = true }: PlanAwardIconProps) {
  const AwardIcon = isAwarded ? AwardIcons[icon] : AwardNotAwardedIcons[icon];

  return (
    <Suspense
      fallback={
        <Skeleton
          shape={Skeleton.Shape.ROUND}
          css={css`
            width: ${size}px;
            height: ${size}px;
          `}
        />
      }
    >
      <AwardIcon
        height={size}
        width={size}
      />
    </Suspense>
  );
});
