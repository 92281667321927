import { IconExternalLink } from '@tabler/icons-react';
import { memo, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { type DatasetRow } from '@amalia/core/types';
import { ButtonLink, Table } from '@amalia/design-system/components';
import { Link } from '@amalia/ext/react-router-dom';

import { type DatasetRecordsDataGridRowIndicatorsProps } from '../../row-indicators/DatasetRecordsDataGridRowIndicators';

export type DatasetRecordsDataGridStatementCellProps = {
  readonly datasetRow: DatasetRow;
  readonly rowHighlightMarker?: ReactElement<DatasetRecordsDataGridRowIndicatorsProps>;
};

export const DatasetRecordsDataGridStatementCell = memo(function DatasetRecordsDataGridStatementCell({
  datasetRow,
  rowHighlightMarker,
}: DatasetRecordsDataGridStatementCellProps) {
  const statementId = datasetRow.content.statementId as string;

  return (
    <Table.Cell.WithActions
      actions={rowHighlightMarker}
      hideActions={false}
    >
      {statementId ? (
        <ButtonLink
          icon={<IconExternalLink />}
          iconPosition={ButtonLink.IconPosition.RIGHT}
          size={ButtonLink.Size.SMALL}
          variant={ButtonLink.Variant.PRIMARY_LIGHT}
          to={
            <Link
              openInNewTab
              to={generatePath(routes.STATEMENT, { id: statementId })}
            />
          }
        >
          <FormattedMessage defaultMessage="View statement" />
        </ButtonLink>
      ) : (
        <FormattedMessage defaultMessage="No statement" />
      )}
    </Table.Cell.WithActions>
  );
});
