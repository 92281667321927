import { type RecordContentPropertyType } from '@amalia/data-capture/connectors/types';
import { type OverwriteScopeEnum } from '@amalia/data-correction/overwrites/types';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { type WorkflowStatementState } from '@amalia/payout-collaboration/workflows/types';
import { type Period, PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';
import { type BadgeAward, type HighlightedKpi, type Plan, type RuleType } from '@amalia/payout-definition/plans/types';
import { type TeamAssignment } from '@amalia/tenants/assignments/teams/types';
import { type Company } from '@amalia/tenants/companies/types';
import { type AssignmentType, type MatchScope } from '@amalia/tenants/teams/types';
import { type UserComputed } from '@amalia/tenants/users/types';

import { type CalculationType } from './calculation';
import { type ComputedStatement, type ComputedStatementSummary } from './computedStatement';
import { type TeamOption } from './options';
import { type PaymentCategory } from './payments';
import { type StatementForecast } from './statementForecast';
import { type PlanOption } from './userStatements';

export const StatementFrequency = {
  [PeriodFrequencyEnum.month]: PeriodFrequencyEnum.month,
  [PeriodFrequencyEnum.quarter]: PeriodFrequencyEnum.quarter,
};

export interface Adjustment {
  id?: string;
  name: string;
  paymentPeriodId: string;
  description?: string;
  amount: number;
  rowDefinitionId?: string;
  rowExternalId?: string;
  rowName?: string;
  statement?: Statement;
}

export enum StatementErrorLevel {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export enum StatementErrorType {
  CALCULATION = 'CALCULATION',
  CONFIGURATION = 'CONFIGURATION',
  PAYMENT = 'PAYMENT',
}

export interface StatementError {
  message: string;
  type: StatementErrorType;
  level: StatementErrorLevel;
  stack?: string;
  context?: { variableId?: string; variableName?: string };
}

export interface StatementTeamAssignment {
  id: string;
  teamId: string;
  teamName: string;
}

export interface StatementParentTeams {
  teamId: string;
  teamName: string;
}

export interface Statement {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;

  workflowComplete: boolean;
  workflowSteps?: WorkflowStatementState[];
  workflowCompletedDate?: Date;
  workflowUpdatedAt?: Date;

  badgeAwards?: BadgeAward[];

  results: ComputedStatement;
  resultSummary?: ComputedStatementSummary;
  forecast?: Pick<StatementForecast, 'currency' | 'error' | 'rate' | 'results' | 'total'> & {
    resultSummary?: ComputedStatementSummary;
  };
  forecastId?: string;
  lastCalculationDate?: Date;

  userId?: string;
  user?: UserComputed;
  error?: StatementError | null;
  currency: CurrencySymbolsEnum;
  rate: number;
  total: number;

  adjustments?: Adjustment[];
  plan?: Plan;
  period?: Period;
  company?: Company;

  // Direct team assignments of the user during statement computation.
  directTeamAssignments?: StatementTeamAssignment[] | null;
  // Parent teams of the user during statement computation.
  parentTeamAssignments?: StatementParentTeams[] | null;
}

export interface StatementResponse {
  id: string;
  badgeAwards?: BadgeAward[];
  updatedAt: Date;
  workflowComplete: boolean;
  workflowCompletedDate: Date;
  error?: StatementError;
  currency: CurrencySymbolsEnum;
  rate: number;
  total: number;
  adjustments: Adjustment[];

  user: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    pictureURL: string;
  };

  company: {
    id: string;
    name: string;
  };

  plan: {
    id: string;
    name: string;
    highlightedKpis: HighlightedKpi[];
    forecastId?: string;
    frequency: PeriodFrequencyEnum;
    isSimulationEnabled?: boolean;
    isSimulationAddRecordEnabled?: boolean;
    isForecasted?: boolean;
    workflowId?: string;
    linkToPlanDocumentation?: string;
    isHidden: boolean;
  };

  period: {
    id: string;
    name: string;
    startDate: number;
    endDate: number;
    frequency: PeriodFrequencyEnum;
  };

  results: ComputedStatement;
  resultSummary: ComputedStatementSummary;
  workflowSteps: WorkflowStatementState[];
  workflowUpdatedAt: Date;
  forecastId: string;
  forecast?: Pick<StatementForecast, 'currency' | 'error' | 'rate' | 'results' | 'resultSummary' | 'total'>;
  lastCalculationDate?: Date;
}

export interface CreateStatementOverwriteRequest {
  readonly ruleId?: string;

  readonly scope: OverwriteScopeEnum;

  readonly overwriteValue: RecordContentPropertyType;

  readonly field: string;

  readonly variableId: string;
}

export interface CreateDatasetOverwriteRequest {
  readonly scope: OverwriteScopeEnum;
  readonly overwriteValue: number | object | string | null;
  readonly rowExternalId?: string;
  readonly definitionId?: string;
  readonly field: string;
  readonly filterId?: string;
  readonly calculationType: CalculationType;
  readonly variableId: string;
}

export interface DeleteDatasetOverwriteRequest {
  readonly filterId?: string;

  readonly definitionId?: string;

  readonly scope: OverwriteScopeEnum;

  readonly calculationType: CalculationType;
}

export interface AddDatasetRowsRequest {
  readonly customObjectIds: string[];
}

export interface CurrencyAmount {
  amount: number;
  currencySymbol: CurrencySymbolsEnum;
  currencyRate?: number;
}

export interface Kpi {
  label?: string;
  value: CurrencyAmount | number | null;
}

export interface MainKpi extends Kpi {
  type?: PaymentCategory | RuleType;
  hideTotal?: boolean;
  isSubMainKpi?: boolean;
  isForecastedView?: boolean;
  overrideFormattedValue?: string;
  formattedForecastedValue?: number | string;
  formattedOriginalValue?: number | string;
  isValueForecasted?: boolean;
}

export type AssignmentListScope = {
  [AssignmentType.MINE]: {
    [MatchScope.ALL]: Record<string, TeamAssignment[]>;
  };
  [AssignmentType.EMPLOYEE]: {
    [MatchScope.ALL]: Record<string, TeamAssignment[]>;
    [MatchScope.SUB]: Record<string, TeamAssignment[]>;
  };
  [AssignmentType.MANAGER]: {
    [MatchScope.ALL]: Record<string, TeamAssignment[]>;
    [MatchScope.SUB]: Record<string, TeamAssignment[]>;
  };
  [AssignmentType.MEMBER]: {
    [MatchScope.ALL]: Record<string, TeamAssignment[]>;
    [MatchScope.SUB]: Record<string, TeamAssignment[]>;
  };
};

export type ListSelectedOptions = { team?: TeamOption; plan?: PlanOption };
