import { type FormikFieldProps, useFormikFieldAdapter } from '@amalia/ext/formik';

import { type RowData } from '../../../Table.types';
import { CellDatePicker, type CellDatePickerProps } from '../CellDatePicker';

export type FormikCellDatePickerProps<
  TWithRange extends boolean | undefined = undefined,
  TRowData extends RowData = RowData,
> = FormikFieldProps<CellDatePickerProps<TWithRange, TRowData>>;

export const FormikCellDatePicker = function FormikCellDatePicker<
  TWithRange extends boolean | undefined = undefined,
  TRowData extends RowData = RowData,
>({ validate, ...props }: FormikCellDatePickerProps<TWithRange, TRowData>) {
  const { ...formikFieldProps } = useFormikFieldAdapter<CellDatePickerProps<TWithRange, TRowData>['value']>({
    validate,
    ...props,
  });

  return (
    <CellDatePicker
      {...props}
      {...formikFieldProps}
    />
  );
};
