export enum SubjectsEnum {
  ApiKeys = 'ApiKeys',
  Audit = 'Audit',
  CommentThreadMessage = 'CommentThreadMessage',
  Company = 'Company',
  CustomReport = 'CustomReport',
  DashboardPayments = 'DashboardPayments',
  DashboardsV2 = 'DashboardsV2',
  Data = 'Data',
  Data_Connector = 'Data_Connector',
  Data_Export = 'Data_Export',
  Flow = 'Flow',
  Folders = 'Folders',
  Forecasted_Statement = 'Forecasted_Statement',
  Overwrite_Recap = 'Overwrite_Recap',
  Payment = 'Payment',
  PaymentLock = 'PaymentLock',
  Plan = 'Plan',
  PlanAgreements = 'PlanAgreements',
  Plan_Assignment = 'Plan_Assignment',
  PresetReport = 'PresetReport',
  Quota = 'Quota',
  RepHome = 'RepHome',
  AnalyticsHome = 'AnalyticsHome',
  Statement = 'Statement',
  Team = 'Team',
  Todos = 'Todos',
  User = 'User',
  Workflow = 'Workflow',
  UserProfile = 'UserProfile',
  UserData = 'UserData',

  // Super admin
  SuperAdmin = 'SuperAdmin',
  SuperAdmin_calculations = 'SuperAdmin_calculations',
  SuperAdmin_refreshments = 'SuperAdmin_refreshments',
  SuperAdmin_settings = 'SuperAdmin_settings',
  SuperAdmin_connectors = 'SuperAdmin_connectors',
  SuperAdmin_statements = 'SuperAdmin_statements',
  SuperAdmin_users = 'SuperAdmin_users',
  SuperAdmin_plans = 'SuperAdmin_plans',
  SuperAdmin_companies = 'SuperAdmin_companies',
}
