import { IconSwitchHorizontal } from '@tabler/icons-react';
import { isNil } from 'lodash';
import { Fragment, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Tooltip } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';
import { type Plan } from '@amalia/payout-definition/plans/types';

import { PlanVisibilityBadge } from '../badge/PlanVisibilityBadge';
import { PlanVisibilityModals } from '../modals/PlanVisibilityModals';

export type PlanVisibilitySwitchButtonProps = {
  readonly isPlanHidden?: Plan['isHidden'] | null;
  readonly planId: Plan['id'];
  readonly planName: Plan['name'];
};

export const PlanVisibilitySwitchButton = memo(function PlanVisibilitySwitchButton({
  isPlanHidden,
  planId,
  planName,
}: PlanVisibilitySwitchButtonProps) {
  const { isModalOpen, setModalOpenTrue, setModalOpenFalse } = useBoolState<'modalOpen'>(false, 'modalOpen');

  // In case the plan visibility is not defined, we don't show the switch button.
  if (isNil(isPlanHidden)) {
    return null;
  }

  return (
    <Fragment>
      <Tooltip
        content={
          isPlanHidden ? (
            <FormattedMessage
              defaultMessage="“{planName}” is hidden from users.{br}Click to publish it."
              values={{ planName }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="“{planName}” is published.{br}Click to hide it from users."
              values={{ planName }}
            />
          )
        }
      >
        <Button
          badge={<PlanVisibilityBadge isPlanHidden={isPlanHidden} />}
          icon={<IconSwitchHorizontal />}
          iconPosition={Button.IconPosition.RIGHT}
          variant={Button.Variant.LIGHT}
          onClick={setModalOpenTrue}
        />
      </Tooltip>

      <PlanVisibilityModals
        closeModal={setModalOpenFalse}
        isOpen={isModalOpen}
        isPlanHidden={isPlanHidden}
        planId={planId}
        planName={planName}
      />
    </Fragment>
  );
});
