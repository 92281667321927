import { css } from '@emotion/react';
import { memo } from 'react';

import { Group, Typography } from '@amalia/design-system/components';

import { type ChartSeriesMetadata } from '../../types/charts.types';
import { ChartSeriesIcon } from '../chart-series-icon/ChartSeriesIcon';

export type ChartLegendProps = {
  readonly seriesList: ChartSeriesMetadata[];
};

export const ChartLegend = memo(function ChartLegend({ seriesList }: ChartLegendProps) {
  return (
    <Group
      wrap
      align="center"
      gap={20}
      justify="center"
    >
      {seriesList.map((series) => (
        <Group
          key={series.columnMetadata.dataKey}
          align="center"
          gap={10}
        >
          <ChartSeriesIcon series={series} />

          <Typography
            variant={Typography.Variant.BODY_SMALL_REGULAR}
            css={(theme) => css`
              ${theme.ds.colors.gray[700]}
            `}
          >
            {series.columnMetadata.column.label}
          </Typography>
        </Group>
      ))}
    </Group>
  );
});
