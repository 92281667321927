import { memo } from 'react';

import { type ChangelogEntry } from '@amalia/amalia-meta/changelog/shared';
import { Badge, type BadgeProps } from '@amalia/design-system/components';
import { type MergeAll } from '@amalia/ext/typescript';

export type ChangelogEntryTopicBadgeProps = MergeAll<
  [
    Omit<BadgeProps, 'children' | 'variant'>,
    {
      readonly topic: Required<ChangelogEntry>['topic'];
    },
  ]
>;

export const ChangelogEntryTopicBadge = memo(function ChangelogEntryTopicBadge({
  topic,
  size = Badge.Size.SMALL,
  ...props
}: ChangelogEntryTopicBadgeProps) {
  return (
    <Badge
      {...props}
      size={size}
      variant={Badge.Hue.GREY}
    >
      {topic}
    </Badge>
  );
});
