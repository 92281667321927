import { makeStyles } from '@mui/styles';

import { type AmaliaThemeType, colors } from '@amalia/ext/mui/theme';

// Can't break it into two files because of the hover on a row, which is supposed to change
// the style of the tracing deal icon.

export const useRowsTableStyles = makeStyles((theme: AmaliaThemeType) => ({
  // ======= ROW =======
  rowHighlight: {
    backgroundColor: colors['primary-100'],
  },
  isAdditionalRow: {
    backgroundColor: colors['tertiary-100'],
  },
  rowSelected: {
    backgroundColor: colors['tertiary-200'],
  },

  overwriteCell: {
    '&:hover': {
      '& $commentButtonDisappear': {
        opacity: 1,
      },
      '& $editIcon': {
        opacity: 1,
      },
    },
  },
  editIcon: {
    opacity: 0,
    transition: 'opacity .3s',
  },

  paymentAmountCell: {
    width: '200px',
  },

  // ======= CELL =======
  headerActionsCell: {
    paddingRight: '120px !important',
  },

  commentButtonDisappear: {
    opacity: 0,
    transition: 'opacity .3s',
  },

  commentIconActive: {
    color: theme.palette.link.main,
  },

  paymentIconActive: {
    color: theme.palette.success.main,
  },

  link: {
    color: theme.palette.link.main,
  },
  leftActions: {
    textAlign: 'center',
    zIndex: 2,
    width: 0,
    height: '3rem',
    lineHeight: '150%',
    position: 'relative',
    top: 'calc(.5rem + 4px)',
    padding: '0',
    cursor: 'pointer',
    display: 'block',
    backgroundColor: theme.palette.grey[200],
    borderRight: '1px solid transparent',
    borderTop: '1px solid transparent',
    borderBottom: '1px solid transparent',
    overflow: 'hidden',
    transition: 'width .3s, transform .3s',
    transformOrigin: 'left center',
    borderRadius: '0 1.5rem 1.5rem 0',
    visibility: 'hidden',

    '& svg': {
      height: '1.5rem',
    },

    '&:hover': {
      transform: 'scale(1.1)',
      backgroundColor: theme.palette.grey[200],
    },
  },
  leftActions_opened: {
    maxWidth: '9rem',
    width: '100%',
    visibility: 'visible',
  },
  fixedCell: {
    left: 0,
    // a bit larger than the leftActionsTrigger to avoid not being able to overwrite it.
    minWidth: '4.5rem',
    '&$rowHighlight': {
      backgroundColor: colors['primary-100'],
    },
  },
  // Opening the bubble on hovering the line.
  tableRow: {
    '&:hover $leftActionsTrigger': {
      borderColor: theme.palette.grey[200],
      width: '100%',
      maxWidth: '3rem',
    },
  },
}));
