import { css } from '@emotion/react';
import { IconExternalLink } from '@tabler/icons-react';
import clsx from 'clsx';
import { camelCase } from 'lodash';
import { memo, type ReactNode, useCallback, useState } from 'react';
import { FormattedList, FormattedMessage } from 'react-intl';
import { formatFileSize, useCSVReader } from 'react-papaparse';

import { AlertBanner, Button, Modal, Stack } from '@amalia/design-system/components';
import { Link } from '@amalia/ext/react-router-dom';

import { DEFAULT_REMOVE_HOVER_COLOR, REMOVE_HOVER_COLOR_LIGHT, useBulkCsvModalStyles } from './useBulkCsvModal';

interface BulkCsvImportModalProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly submitUploadedRows: (rows: any[]) => void;
  readonly templateLink?: string;
  readonly mandatoryColumns?: readonly string[];
  readonly optionalColumns?: readonly string[];
  readonly transform?: (value: string, field: number | string) => any;
  readonly name?: string;
  readonly previewComponent?: ReactNode;
  readonly optionalButton?: ReactNode;
  readonly additionalBanner?: ReactNode;
  readonly additionalContent?: ReactNode;
}

export const BulkCsvImportModal = memo(function BulkCsvImportModal({
  isOpen,
  submitUploadedRows,
  onClose,
  templateLink,
  mandatoryColumns,
  optionalColumns,
  transform,
  optionalButton,
  additionalBanner,
  additionalContent,
}: BulkCsvImportModalProps) {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR);

  const [rows, setRows] = useState([]);

  const [isValid, setIsValid] = useState(false);
  const handleClose = useCallback(() => {
    onClose();
    setIsValid(false);
  }, [onClose]);

  const classes = useBulkCsvModalStyles();

  return (
    <Modal
      isOpen={isOpen}
      size={Modal.Size.LARGE}
      onClose={handleClose}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Upload CSV" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {additionalBanner}
          {additionalContent}
          {!!(mandatoryColumns?.length || optionalColumns?.length) && (
            <AlertBanner variant={AlertBanner.Variant.INFO}>
              <Stack>
                {!!mandatoryColumns?.length && (
                  <div>
                    <FormattedMessage
                      defaultMessage="Your CSV file must contain the following {columnCount, plural, one {column} other {columns}}: {columnTitles}."
                      values={{
                        columnCount: mandatoryColumns.length,
                        columnTitles: (
                          <FormattedList
                            style="short"
                            type="unit"
                            value={mandatoryColumns}
                          />
                        ),
                      }}
                    />
                  </div>
                )}

                {!!optionalColumns?.length && (
                  <div>
                    <FormattedMessage
                      defaultMessage="Your CSV file should contain the following optional {columnCount, plural, one {column} other {columns}}: {columnTitles}."
                      values={{
                        columnCount: optionalColumns.length,
                        columnTitles: (
                          <FormattedList
                            style="short"
                            type="unit"
                            value={optionalColumns}
                          />
                        ),
                      }}
                    />
                  </div>
                )}
              </Stack>
            </AlertBanner>
          )}

          {templateLink ? (
            <div className={classes.blocGoogleSheetTemplate}>
              <Link
                openInNewTab
                to={templateLink}
                css={css`
                  text-decoration: none;
                `}
              >
                <Button icon={<IconExternalLink />}>
                  <FormattedMessage defaultMessage="Open Google Sheets template" />
                </Button>
              </Link>
              {optionalButton}
            </div>
          ) : null}

          <div className={classes.boxForm}>
            <CSVReader
              config={{
                skipEmptyLines: true,
                header: true,
                transformHeader: (header: string): string => camelCase(header),
                transform,
              }}
              onDragLeave={(event: DragEvent) => {
                event.preventDefault();
                setZoneHover(false);
              }}
              onDragOver={(event: DragEvent) => {
                event.preventDefault();
                setZoneHover(true);
              }}
              onUploadAccepted={(results) => {
                setRows(results.data);
                setZoneHover(false);
                setIsValid(true);
              }}
              onUploadRejected={() => {
                setIsValid(false);
              }}
            >
              {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }: any) => (
                <div
                  {...getRootProps()}
                  className={clsx(classes.zone, zoneHover && classes.zoneHover)}
                >
                  {acceptedFile ? (
                    <div className={classes.file}>
                      <div className={classes.info}>
                        <span className={classes.size}>{formatFileSize(acceptedFile.size)}</span>
                        <span className={classes.name}>{acceptedFile.name}</span>
                      </div>
                      <div className={classes.progressBar}>
                        <ProgressBar />
                      </div>
                      <div
                        {...getRemoveFileProps()}
                        className={classes.remove}
                        onBlur={() => {}}
                        onFocus={() => {}}
                        onMouseOut={(event: Event) => {
                          event.preventDefault();
                          setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                        }}
                        onMouseOver={(event: Event) => {
                          event.preventDefault();
                          setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                        }}
                      >
                        <Remove color={removeHoverColor} />
                      </div>
                    </div>
                  ) : (
                    <FormattedMessage defaultMessage="Drop CSV file here or click to upload" />
                  )}
                </div>
              )}
            </CSVReader>
          </div>
        </Modal.Body>
      </Modal.Content>
      <Modal.Actions>
        <Modal.CancelAction />

        <Modal.MainAction
          disabled={!isValid}
          onClick={() => {
            submitUploadedRows(rows);
          }}
        >
          <FormattedMessage defaultMessage="Save" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
