import { FormattedMessage } from 'react-intl';

import {
  type Notification,
  NotificationsTypes,
  type NewCommentOnStatementMetadata,
  type NewExportAvailableMetadata,
  type ReviewStatementMetadata,
} from '@amalia/core/types';

export const getNotificationText = (notification: Notification) => {
  if (!notification.contentMetadata) {
    return notification.content;
  }

  switch (notification.type) {
    case NotificationsTypes.NEW_COMMENT_ON_STATEMENT: {
      const commentMetadata: NewCommentOnStatementMetadata =
        notification.contentMetadata as NewCommentOnStatementMetadata;
      if (commentMetadata.statementUserFullName) {
        return (
          <FormattedMessage
            defaultMessage="A statement of {statement_user_fullname} has been commented by {comment_author_fullname}"
            values={{
              statement_user_fullname: commentMetadata.statementUserFullName,
              comment_author_fullname: commentMetadata.commentAuthorFullName,
            }}
          />
        );
      }
      return (
        <FormattedMessage
          defaultMessage="You have been notified on one of your statements by {comment_author_fullname}"
          values={{ comment_author_fullname: commentMetadata.commentAuthorFullName }}
        />
      );
    }
    case NotificationsTypes.NEW_EXPORT_AVAILABLE:
      return (notification.contentMetadata as NewExportAvailableMetadata).isInError ? (
        <FormattedMessage defaultMessage="An error happened while exporting your data. Please contact your administrator to learn more." />
      ) : (
        <FormattedMessage defaultMessage="New export is available. Click here to download it." />
      );
    case NotificationsTypes.REVIEW_STATEMENT:
      return (
        <FormattedMessage
          defaultMessage="Your statement on period {statement_period_name} was just reviewed by {reviewer_fullname}."
          values={{
            statement_period_name: (notification.contentMetadata as ReviewStatementMetadata).statementPeriodName,
            reviewer_fullname: (notification.contentMetadata as ReviewStatementMetadata).reviewerFullName,
          }}
        />
      );
    default:
      return notification.content;
  }
};
