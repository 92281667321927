import { uniq, without } from 'lodash';
import { type Ref, useCallback, useMemo, useState } from 'react';

import { useResizeObserver } from '@amalia/ext/react/hooks';

import { getRowKey } from '../helpers/getRowKey';
import { type TableContextValue } from '../Table.context';
import { type RowData, type RowKey } from '../Table.types';

export type UseTableMultiSelectionValue<TKey extends RowKey> = {
  isSelectionEnabled: boolean;
  isSelectAllDisabled: boolean;
  selectionColumnRef: Ref<HTMLTableCellElement>;
  selectionColumnWidth: number;
  handleSelectAllRows: (isSelected: boolean) => void;
  handleSelectRow: (isSelected: boolean, key: TKey) => void;
  hasSomeRowsSelected: boolean;
  hasAllRowsSelected: boolean;
};

export const useTableMultiSelection = <TData extends RowData = RowData, TKey extends RowKey = RowKey>({
  data,
  rowKey,
  selectedRows,
  onChangeSelectedRows,
  isRowSelectableFn,
  enabled,
}: {
  data: TData[];
  rowKey: TableContextValue<TData, TKey>['rowKey'];
  enabled: boolean;
  selectedRows?: TKey[];
  isRowSelectableFn?: (row: TData) => boolean;
  onChangeSelectedRows?: (selectedRows: TKey[]) => void;
}): UseTableMultiSelectionValue<TKey> => {
  const [{ width: selectionColumnWidth }, setSelectionColumnDimensions] = useState({ width: 0 });
  const selectionColumnRef = useResizeObserver<HTMLTableCellElement>({ onResize: setSelectionColumnDimensions });

  const currentPageSelectableRowKeys = useMemo(() => {
    // Keep only selectable rows if the predicate was provided.
    const selectableRows = isRowSelectableFn ? data.filter(isRowSelectableFn) : data;
    return selectableRows.map((row) => getRowKey(row, rowKey));
  }, [data, isRowSelectableFn, rowKey]);

  const hasSelectableRows = currentPageSelectableRowKeys.length > 0;
  const hasSomeRowsSelected = !!selectedRows && currentPageSelectableRowKeys.some((key) => selectedRows.includes(key));
  const hasAllRowsSelected =
    !!selectedRows && hasSelectableRows && currentPageSelectableRowKeys.every((key) => selectedRows.includes(key));

  const handleSelectAllRows = useCallback(
    (isSelected: boolean) =>
      // There might be multiple pages, so we need to keep values that were already here.
      // When selecting, add all keys of current page.
      // When deselecting, remove all keys of current page.
      onChangeSelectedRows?.(
        isSelected
          ? uniq([...(selectedRows ?? []), ...currentPageSelectableRowKeys])
          : without(selectedRows, ...currentPageSelectableRowKeys),
      ),
    [onChangeSelectedRows, selectedRows, currentPageSelectableRowKeys],
  );

  const handleSelectRow = useCallback(
    (isSelected: boolean, key: TKey) =>
      onChangeSelectedRows?.(isSelected ? uniq([...(selectedRows ?? []), key]) : without(selectedRows, key)),
    [onChangeSelectedRows, selectedRows],
  );

  return {
    isSelectionEnabled: enabled,
    isSelectAllDisabled: !hasSelectableRows,
    selectionColumnRef,
    selectionColumnWidth,
    handleSelectAllRows,
    handleSelectRow,
    hasSomeRowsSelected,
    hasAllRowsSelected,
  };
};
