import { type ClassNamesContent, css } from '@emotion/react';

export const IS_ACTIVE_CLASSNAME = 'is-active';

export const menuItem = css`
  list-style-type: none;
  hyphens: auto;
`;

export const menuItemLink = ({ css, theme }: Pick<ClassNamesContent, 'css' | 'theme'>) => css`
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 5.5px 8px;

  border-radius: ${theme.ds.borderRadiuses.squared};
  text-decoration: none;

  background-color: transparent;
  color: ${theme.ds.colors.gray[800]};

  &,
  > svg {
    transition: ${theme.ds.transitions.default('background-color', 'color')};
  }

  &.${IS_ACTIVE_CLASSNAME} {
    &,
    &:hover {
      background-color: ${theme.ds.colors.primary[50]};
      color: ${theme.ds.colors.primary[500]};
    }
  }

  &:hover {
    background-color: ${theme.ds.colors.gray[50]};
  }
`;
