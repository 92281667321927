import { type CSSProperties } from 'react';

import { colors } from '@amalia/ext/mui/theme';

import { FormulaNodeEnum, IFormulaNode } from './IFormulaNode';

export class OperatorFormulaNode extends IFormulaNode<string> {
  public constructor(partialFormula: string, startIndex: number, nodeObject: string) {
    super(partialFormula, startIndex, FormulaNodeEnum.OPERATOR, nodeObject);
  }

  public getStyles(): CSSProperties {
    return { color: colors['grey-800'] };
  }

  public getDesignerPath() {
    return null;
  }

  public getLabel(): string {
    return this.nodeObject.toString().trim();
  }
}
