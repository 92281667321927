import { css } from '@emotion/react';
import { memo } from 'react';

import { Divider } from '../../../layout/divider/Divider';

export const DropdownDivider = memo(function DropdownDivider() {
  return (
    <Divider.Horizontal
      css={(theme) => css`
        margin: 6px 0;
        background-color: ${theme.ds.colors.gray[100]};
      `}
    />
  );
});
