import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal, Typography } from '@amalia/design-system/components';
import { TypographyVariant } from '@amalia/design-system/meta';
import { type UserProfile } from '@amalia/tenants/users/profile/types';

export type ConfirmInviteUserModalProps = {
  readonly isOpen: boolean;
  readonly onSubmit: () => void;
  readonly onClose: () => void;
  readonly user: Pick<UserProfile, 'email' | 'firstName' | 'id' | 'invitationSentAt' | 'lastName'>;
};

export const ConfirmInviteUserModal = memo(function ConfirmInviteUserModal({
  isOpen,
  onSubmit,
  onClose,
  user,
}: ConfirmInviteUserModalProps) {
  return (
    <Modal
      isDismissable
      isOpen={isOpen}
      size={Modal.Size.MEDIUM}
      variant={Modal.Variant.DEFAULT}
      onClose={onClose}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Send invitation" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage
            defaultMessage="Are you sure you want to invite {username} ({email}) to Amalia?"
            values={{
              username: (
                <Typography variant={TypographyVariant.BODY_BASE_BOLD}>
                  <FormattedMessage
                    defaultMessage="{firstName} {lastName}"
                    values={{ firstName: user.firstName, lastName: user.lastName }}
                  />
                </Typography>
              ),
              email: user.email,
            }}
          />
        </Modal.Body>
      </Modal.Content>
      <Modal.Actions>
        <Modal.CancelAction />
        <Modal.MainAction onClick={onSubmit}>
          <FormattedMessage defaultMessage="Send invitation" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
