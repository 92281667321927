import { css } from '@emotion/react';
import { memo, type ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Tooltip, Typography, UnstyledButton } from '@amalia/design-system/components';
import { useClipboard } from '@amalia/design-system/ext';

export type UserIntegrationLogoProps = {
  readonly kind: 'externalId' | 'hrisId';
  readonly value: string;
  readonly isOtherSource: boolean;
  readonly sourceLabel: ReactNode;
  readonly children: ReactNode;
};

export const UserIntegrationTooltip = memo(function UserIntegrationTooltip({
  kind,
  sourceLabel,
  isOtherSource,
  value,
  children,
}: UserIntegrationLogoProps) {
  const { copy } = useClipboard();

  const onClickLogo = useCallback(() => {
    copy(value);
  }, [copy, value]);

  return (
    <Tooltip
      placement="bottom"
      content={
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <FormattedMessage
            defaultMessage="{sourceLabel} {isOtherSource, select, false {(external source)} other {}}"
            values={{ sourceLabel, isOtherSource }}
          />
          <Typography
            variant={Typography.Variant.BODY_XSMALL_REGULAR}
            css={(theme) => css`
              color: ${theme.ds.colors.gray[400]};
            `}
          >
            <FormattedMessage defaultMessage="Click to copy ID" />
          </Typography>
        </div>
      }
    >
      <UnstyledButton
        data-testid={`user-${kind}-tooltip-anchor`}
        onClick={onClickLogo}
      >
        {children}
      </UnstyledButton>
    </Tooltip>
  );
});
