import { css } from '@emotion/react';
import { IconArrowUpRight } from '@tabler/icons-react';
import { Fragment, memo, type ReactNode } from 'react';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { IconButtonLink, PageHeader, Typography } from '@amalia/design-system/components';
import { TypographyVariant } from '@amalia/design-system/meta';
import { Link } from '@amalia/ext/react-router-dom';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';

export const actionButtonsContainer = css`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
`;

interface WidgetTitleProps {
  readonly customReportId?: string;
  readonly actions?: ReactNode;
  readonly title: ReactNode;
}

export const WidgetTitle = memo(function WidgetTitle({ customReportId, title, actions }: WidgetTitleProps) {
  const ability = useAbilityContext();

  const canVisitCustomReport = customReportId && ability.can(ActionsEnum.view, SubjectsEnum.CustomReport);

  return (
    <PageHeader.Row
      left={
        <Link
          openInNewTab
          to={canVisitCustomReport ? generatePath(routes.CUSTOM_REPORT_ITEM, { reportId: customReportId }) : undefined}
          css={css`
            text-decoration: none;
            all: unset;
            cursor: ${canVisitCustomReport ? 'pointer' : 'default'};
          `}
        >
          <Typography variant={TypographyVariant.HEADING_3_BOLD}>{title}</Typography>
        </Link>
      }
      right={
        <div css={actionButtonsContainer}>
          {!!actions && <Fragment>{actions}</Fragment>}

          {!!canVisitCustomReport && (
            <IconButtonLink
              icon={<IconArrowUpRight />}
              label=""
              to={
                <Link
                  openInNewTab
                  to={
                    canVisitCustomReport
                      ? generatePath(routes.CUSTOM_REPORT_ITEM, { reportId: customReportId })
                      : undefined
                  }
                />
              }
            />
          )}
        </div>
      }
    />
  );
});
