import {
  type AccessorFnColumnDefinition,
  type AccessorFn,
  type AccessorKeyColumnDefinition,
  type DisplayColumnDefinition,
  type RowData,
} from './Table.types';

export class ColumnHelper<TData extends RowData = RowData> {
  /** Create a column definition not tied to a specified property of TData. */
  public display(columnDef: DisplayColumnDefinition<TData>): DisplayColumnDefinition<TData> {
    return columnDef;
  }

  /** Accessor via property name. */
  public accessor<TAccessorKey extends keyof TData, TValue extends TData[TAccessorKey]>(
    accessorKey: TAccessorKey,
    column: Omit<AccessorKeyColumnDefinition<TData, TValue>, 'accessorKey'>,
  ): AccessorKeyColumnDefinition<TData, TValue>;

  /** Accessor via getter. */
  public accessor<
    TAccessorFn extends AccessorFn<TData>,
    TValue extends TAccessorFn extends AccessorFn<TData, infer TReturn> ? TReturn : never,
  >(
    accessorFn: AccessorFn<TData, TValue>,
    column: Omit<AccessorFnColumnDefinition<TData, TValue>, 'accessorFn'>,
  ): AccessorFnColumnDefinition<TData, TValue>;

  /** Implementation of overloads. */
  public accessor<TValue>(
    accessorKeyOrFn: AccessorFn<TData, TValue> | keyof TData,
    column: Omit<
      AccessorFnColumnDefinition<TData, TValue> | AccessorKeyColumnDefinition<TData, TValue>,
      'accessorFn' | 'accessorKey'
    >,
  ): AccessorFnColumnDefinition<TData, TValue> | AccessorKeyColumnDefinition<TData, TValue> {
    return typeof accessorKeyOrFn === 'function'
      ? {
          ...column,
          accessorFn: accessorKeyOrFn,
        }
      : {
          ...column,
          accessorKey: accessorKeyOrFn,
        };
  }
}
