import { useTheme } from '@emotion/react';
import { IconCalendar, IconX } from '@tabler/icons-react';
import clsx from 'clsx';
import { type ComponentPropsWithoutRef, memo, forwardRef, type ForwardedRef, type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { TypographyVariant } from '@amalia/design-system/meta';
import { type MergeAll } from '@amalia/ext/typescript';

import { IconAction } from '../../../../../general/icon-action/IconAction';
import { TextOverflow } from '../../../../../general/text-overflow/TextOverflow';
import { Typography } from '../../../../../general/typography/Typography';
import { UnstyledButton } from '../../../../../general/unstyled-button/UnstyledButton';
import { Group } from '../../../../../layout/group/Group';
import { TableDataCellContent } from '../../../layout/table-data-cell-content/TableDataCellContent';
import { CellErrorIndicator } from '../../cell-error-indicator/CellErrorIndicator';

import * as styles from './CellDatePickerAnchor.styles';

export type CellDatePickerAnchorProps = MergeAll<
  [
    ComponentPropsWithoutRef<'button'>,
    {
      readonly isCalendarOpen?: boolean;
      readonly value?: string;
      readonly placeholder?: ReactNode;
      readonly error?: ReactNode;
      readonly warning?: ReactNode;
      readonly onClear?: () => void;
    },
  ]
>;

const CellDatePickerAnchorForwardRef = forwardRef(function CellDatePickerAnchor(
  { value, isCalendarOpen, placeholder, error, warning, className, onClear, ...props }: CellDatePickerAnchorProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const shouldShowClear = !!isCalendarOpen && !!value && !props.disabled;

  return (
    <div
      css={styles.cellDatePickerAnchor}
      className={clsx(className, {
        [styles.IS_DISABLED_CLASSNAME]: props.disabled,
        [styles.IS_FOCUSED_CLASSNAME]: isCalendarOpen,
        [styles.HAS_ERROR_CLASSNAME]: !!error,
        [styles.HAS_WARNING_CLASSNAME]: !error && !!warning,
      })}
    >
      <UnstyledButton
        {...props}
        ref={ref}
        css={styles.buttonContainer}
      >
        <TableDataCellContent css={styles.cellDatePickerContent(!!error || !!warning, shouldShowClear)}>
          <Typography
            as={TextOverflow}
            css={!value && styles.placeholder}
            variant={TypographyVariant.BODY_BASE_REGULAR}
          >
            {value || placeholder || <FormattedMessage defaultMessage="Pick a date" />}
          </Typography>
        </TableDataCellContent>
      </UnstyledButton>

      <Group
        align="center"
        css={styles.indicatorsContainer}
        gap={6}
      >
        {!!shouldShowClear && (
          <IconAction
            key="clear"
            icon={<IconX />}
            label={formatMessage({ defaultMessage: 'Clear value' })}
            size={IconAction.Size.SMALL}
            variant={IconAction.Variant.DANGER}
            onClick={onClear}
          />
        )}

        <CellErrorIndicator
          error={error}
          warning={warning}
        />

        <IconCalendar
          color={props.disabled ? theme.ds.colors.gray[300] : 'currentColor'}
          css={styles.icon}
          size={19.2}
        />
      </Group>
    </div>
  );
});

export const CellDatePickerAnchor = memo(CellDatePickerAnchorForwardRef);
