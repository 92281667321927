import { type Theme, css } from '@emotion/react';

import { SwitchSize } from './Switch.types';

export const label = css`
  align-self: flex-start;
  flex: none;
`;

export const hidden = css`
  display: block;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;

  appearance: none;
  position: absolute;
  outline: none;
`;

export const switchControl = (theme: Theme) => css`
  position: relative;
  display: flex;
  // Avoid selecting text when clicking twice on the switch
  user-select: none;

  transition: ${theme.ds.transitions.default('background-color', 'box-shadow', 'width', 'height')};

  background-color: ${theme.ds.colors.gray[500]};
  border-radius: ${theme.ds.borderRadiuses.round};

  cursor: pointer;
  user-select: none;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    border-radius: ${theme.ds.borderRadiuses.round};
    background-color: ${theme.ds.colors.gray[0]};

    transition: ${theme.ds.transitions.default()};
  }

  input:disabled ~ & {
    cursor: not-allowed;

    background-color: ${theme.ds.colors.gray[300]};

    &::before {
      background-color: ${theme.ds.colors.gray[100]};
    }
  }

  input:disabled:checked ~ & {
    background-color: ${theme.ds.colors.graySecondary[300]};
  }

  input:not(:disabled):checked ~ & {
    background-color: ${theme.ds.colors.primary[400]};
  }

  input:focus-visible ~ & {
    outline: 1px solid ${theme.ds.colors.primary[200]};
    outline-offset: -1px;

    &.${SwitchSize.SMALL} {
      box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.primary[50])};
    }

    &.${SwitchSize.MEDIUM} {
      box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.primary[50])};
    }

    &.${SwitchSize.LARGE} {
      outline-width: 2px;
      outline-offset: -2px;
      box-shadow: ${theme.ds.shadows.focusLarge(theme.ds.colors.primary[50])};
    }
  }

  input:checked ~ & {
    &.${SwitchSize.SMALL} {
      &::before {
        left: 19px;
      }
    }

    &.${SwitchSize.MEDIUM} {
      &::before {
        left: 23px;
      }
    }

    &.${SwitchSize.LARGE} {
      &::before {
        left: 26px;
      }
    }
  }

  &.${SwitchSize.SMALL} {
    width: 32px;
    height: 16px;

    &::before {
      left: 3px;
      width: 10px;
      height: 10px;
    }
  }

  &.${SwitchSize.MEDIUM} {
    width: 40px;
    height: 20px;

    &::before {
      left: 4px;
      width: 13px;
      height: 13px;
    }
  }

  &.${SwitchSize.LARGE} {
    width: 48px;
    height: 24px;

    &::before {
      left: 6px;
      width: 16px;
      height: 16px;
    }
  }
`;
