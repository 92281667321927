import { last } from 'lodash';

import { ComputeEngineError } from '@amalia/core/types';

export type MathJsError = Error & { data: any };

export class CalculationError extends ComputeEngineError {
  public constructor(e: MathJsError) {
    const message = e.data ? `In ${e.data.fn}, got ${last(e.data.actual)}` : e.message;
    super(message);
  }
}
