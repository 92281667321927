// Loglevel should not be used in the codebase, instead, all log should go through this
// custom logger. This is the only place where we're authorized to import it.
// eslint-disable-next-line no-restricted-imports
import { debug, error, info, setLevel, trace, warn } from 'loglevel';

setLevel('info');

const errorHandler = (...errorMessage: unknown[]) => {
  error(errorMessage);
};

export const log = {
  trace,
  debug,
  info,
  warn,
  error: errorHandler,
};
