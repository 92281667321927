export enum IconButtonVariant {
  CLASSIC = 'CLASSIC',
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
}

export enum IconButtonSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}
