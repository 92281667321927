import { type Dispatch, type SetStateAction, useState, useRef, useCallback } from 'react';

const isStateFn = <S>(setStateAction: SetStateAction<S>): setStateAction is (prevState: S) => S =>
  typeof setStateAction === 'function';

type ReadOnlyRefObject<T> = {
  readonly current: T;
};

export function useStateWithRef<S>(
  initialState: S | (() => S),
): [state: S, setState: Dispatch<SetStateAction<S>>, stateRef: ReadOnlyRefObject<S>];

export function useStateWithRef<S = undefined>(): [
  state: S | undefined,
  setState: Dispatch<SetStateAction<S | undefined>>,
  stateRef: ReadOnlyRefObject<S | undefined>,
];

export function useStateWithRef<S>(
  initialState?: S | (() => S),
): [
  state: S | undefined,
  setState: Dispatch<SetStateAction<S | undefined>>,
  stateRef: ReadOnlyRefObject<S | undefined>,
] {
  const [state, setState] = useState(initialState);
  const stateRef = useRef(state);

  const handleChange: typeof setState = useCallback((newValue) => {
    setState((currentValue) => {
      stateRef.current = isStateFn(newValue) ? newValue(currentValue) : newValue;
      return stateRef.current;
    });
  }, []);

  return [state, handleChange, stateRef];
}
