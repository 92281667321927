import { createContext, useContext } from 'react';

import { type FormLayoutDirection, type FormLayoutSize } from './FormLayout.types';

export type FormLayoutContextValue = {
  size: FormLayoutSize;
  direction: FormLayoutDirection;
};

export const FormLayoutContext = createContext<FormLayoutContextValue | null>(null);

// FormLayoutContext is not mandatory in form components.
export const useFormLayoutContext = (): FormLayoutContextValue | null => useContext(FormLayoutContext);
