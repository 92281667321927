import { memo, type ReactNode } from 'react';

import {
  type ChartType,
  isKpiCardChartConfiguration,
  isSimpleBarChartConfiguration,
  type DashboardChartConfiguration,
  type DashboardChartResult,
  type ChartDisplayContext,
} from '@amalia/reporting/dashboards-v2/types';

import { KPICardChart } from './kpi-card-chart/KPICardChart';
import { SimpleBarChart } from './simple-bar-chart/SimpleBarChart';

interface DashboardChartDisplayProps {
  readonly chartConfiguration: DashboardChartConfiguration<ChartType>;
  readonly chartData?: DashboardChartResult;
  readonly chartFilters: ReactNode;
  readonly error?: Error | null;
  readonly isAnimationActive?: boolean;
  readonly chartDisplayContext: ChartDisplayContext;
}

export const DashboardChartDisplay = memo(function DashboardChartDisplay({
  chartData,
  chartConfiguration,
  chartFilters,
  error,
  isAnimationActive,
  chartDisplayContext,
}: DashboardChartDisplayProps) {
  if (error) {
    return error.message;
  }

  switch (true) {
    case isKpiCardChartConfiguration(chartConfiguration):
      return (
        <KPICardChart
          chartConfiguration={chartConfiguration}
          chartData={chartData}
          chartDisplayContext={chartDisplayContext}
          chartFilters={chartFilters}
        />
      );
    case isSimpleBarChartConfiguration(chartConfiguration):
      return (
        <SimpleBarChart
          chartConfiguration={chartConfiguration}
          chartData={chartData}
          chartFilters={chartFilters}
          isAnimationActive={isAnimationActive}
        />
      );
    default:
      return null;
  }
});
