import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type UserHrisIdSource } from '@amalia/tenants/users/types';

import { userHrisIdSourceLabels } from './UserHrisIdSourceLabel.messages';

export type UserHrisIdSourceLabelProps = {
  readonly hrisIdSource: UserHrisIdSource;
};

export const UserHrisIdSourceLabel = memo(function UserHrisIdSourceLabel({ hrisIdSource }: UserHrisIdSourceLabelProps) {
  const message = userHrisIdSourceLabels[hrisIdSource];
  return <FormattedMessage {...message} />;
});
