import { css } from '@emotion/react';
import { type Dispatch, memo, type SetStateAction } from 'react';

import { formatAmount } from '@amalia/core/types';
import { Group, Typography } from '@amalia/design-system/components';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';

import { type DataDonut } from './DonutChart';

interface DonutAnnotationProps {
  readonly activeSection: number;
  readonly setActiveSection: Dispatch<SetStateAction<number>>;
  readonly annotationIndex: number;
  readonly colors?: string[];
  readonly currency?: CurrencySymbolsEnum;
  readonly entry: DataDonut;
}

export const DonutAnnotation = memo(function DonutAnnotation({
  activeSection,
  setActiveSection,
  annotationIndex,
  colors,
  currency,
  entry,
}: DonutAnnotationProps) {
  return (
    <Group
      key={`cell-${annotationIndex + 1}`}
      align="center"
      data-testid="donut-annotation"
      gap={8}
      css={(theme) => css`
        background-color: ${activeSection === annotationIndex
          ? colors[annotationIndex % colors.length]
          : theme.ds.colors.gray[50]};
        height: 35px;
        padding: 8px;
        width: 100%;
      `}
      onMouseLeave={() => setActiveSection(undefined)}
      onMouseOver={() => setActiveSection(annotationIndex)}
    >
      <div
        id={`donut-annotation-${annotationIndex + 1}`}
        css={(theme) => css`
          width: 10px;
          height: 10px;
          border-radius: ${theme.ds.borderRadiuses.round};
          background-color: ${colors[annotationIndex % colors.length]};
          border: ${activeSection === annotationIndex ? '2px' : '0px'} solid ${theme.ds.colors.gray[0]};
        `}
      />

      <Typography
        variant={Typography.Variant.BODY_BASE_REGULAR}
        css={css`
          flex: 1;
        `}
      >
        {entry.name}
      </Typography>

      <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>{formatAmount(entry.value, currency)}</Typography>
    </Group>
  );
});
