import { isNil } from 'lodash';
import { ConstantNode, FunctionNode, type MathNode } from 'mathjs';

import { commonMathJs } from '@amalia/amalia-lang/amalia-mathjs';
import { type AmaliaFormula } from '@amalia/amalia-lang/formula/types';

export class SanitizeFormula {
  /**
   * Lint formula string.
   * @param formula
   *
   * @deprecated Do not call this method directly (will pass private), use amaliaFormulaToMathJs instead.
   */
  public static readonly sanitizeFormula = (formula: string | null): string | null => {
    // Formula can be falsy (like "0"), but it has to follow the usual path.
    // We only stop if the formula is null or undefined.
    if (isNil(formula)) {
      return formula;
    }

    // Force conversion to string because sometimes we have numbers.
    return (
      `${formula}`
        .replace(/ AND /gu, ' and ')
        .replace(/ OR /gu, ' or ')
        .replace(/ NOT /gu, ' not ')
        .replace(/ XOR /gu, ' xor ')
        // Replace = with == (or else it's an assignment).
        .replace(/([^<>=!])=([^=])/gu, '$1==$2')
    );
  };

  public static replaceNodes(node: MathNode) {
    return node.transform((n: MathNode) => {
      if (commonMathJs.isOperatorNode(n) && n.op === '==') {
        const children = n.args.map((ni) => SanitizeFormula.replaceNodes(ni));
        return new FunctionNode('equal', [new FunctionNode('compareNatural', children), new ConstantNode(0)]);
      }

      return n;
    });
  }

  /**
   * Replace equal by mathjs function node equal, because equal in Mathjs is an assignment.
   *
   * Transform mathjs to any as we are slowly migrating mathjs from javascript to typescript,
   * but there are still lot of issues with our current usage.
   */
  private static replaceEqualByCompareNatural(formula: string | null): string | null {
    // Formula can be falsy (like "0"), but it has to follow the usual path.
    // We only stop if the formula is null or undefined.
    if (isNil(formula)) {
      return formula;
    }

    if (formula === '') {
      return '';
    }

    // documentation => https://mathjs.org/docs/expressions/parsing.html

    const topLevelNode = commonMathJs.parse(formula);

    const transformedReplaceEqual = SanitizeFormula.replaceNodes(topLevelNode);
    return transformedReplaceEqual.toString();
  }

  /**
   * For lisibility, we simplified writing formulas to our users, for instance by using `=` instead
   * of `==`. This function converts Amalia formulas to mathjs compatible formula.
   *
   * @param formula
   */
  public static readonly amaliaFormulaToMathJs = (formula: AmaliaFormula | string) =>
    SanitizeFormula.replaceEqualByCompareNatural(SanitizeFormula.sanitizeFormula(formula));

  /**
   * Parse the formula then unparse it then reparse it to make it beautiful (i guess). See unit tests.
   * @param formula
   */
  public static readonly expressionPrettier = (formula: AmaliaFormula | null): AmaliaFormula => {
    if (!formula) {
      return formula;
    }
    try {
      return commonMathJs
        .parse(SanitizeFormula.sanitizeFormula(formula))
        .toString({
          /**
           * @see https://mathjs.org/docs/reference/functions/format.html
           * Beware! Colossal numbers will be truncated:
           *
           *   ● SanitizeFormula › expressionPrettier › should not replace colossal numbers by their scientific notation
           *
           *     expect(received).toBe(expected) // Object.is equality
           *
           *     Expected: "IF(amount = 12345678901234567890, true, false)"
           *     Received: "IF(amount = 12345678901234567000, true, false)"
           */
          // Make sure the numbers are not converted to scientific notation.
          notation: 'fixed',
        })
        .replace(/ == /gu, ' = ');
    } catch (error) {
      // The formula has error but it's only a prettier, return the formula as-is, we expect the user
      // will correct it anyway since it won't pass form validation.
      return formula;
    }
  };

  public static readonly getPrintableOperator = (operator: string): string => {
    switch (operator?.trim()) {
      case '==':
        return '=';
      case 'xor':
        return 'XOR';
      case 'not':
        return 'NOT';
      case 'or':
        return 'OR';
      case 'and':
        return 'AND';
      default:
        return operator;
    }
  };

  public static readonly replaceDollarRowInFormula = (formula: AmaliaFormula, replacement: string) =>
    formula.replace(/\$row/gu, replacement);
}
