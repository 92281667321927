import { type Company } from '@amalia/tenants/companies/types';

import { type Filter } from './filters';
import { type PlanRuleCategory } from './plans/rules';

export enum ChallengeLaunchNotificationStatus {
  NOT_SENT = 'NOT_SENT',
  SENT = 'SENT',
  SENDING = 'SENDING',
}

export interface RuleConfiguration {
  hideTotal?: boolean;
  mainKpiVariableOverwrite?: string | null;
  forecastPaymentDateFieldId?: string | null;
  paymentDateFieldId?: string | null;
  challengeComparisonVariableId?: string | null;
  challengeConditionVariableId?: string | null;
  challengePricesTableVariableId?: string | null;
  challengeHideResults?: boolean;
  challengeStartDate?: number | null;
  challengeEndDate?: number | null;
  challengeLaunchNotificationStatus?: ChallengeLaunchNotificationStatus;
  slackChannelId?: string | null;
  showHideRuleVariableId?: string | null;
  payMonthly?: boolean | null;
  paymentMonthOffset?: number | null;
}

export enum RuleType {
  PAYOUT = 'PAYOUT',
  NON_PAYOUT = 'NON_PAYOUT',
  SPLIT = 'SPLIT',
  HOLD_AND_RELEASE = 'HOLD_AND_RELEASE',
  LINE_PAYOUT = 'LINE_PAYOUT',
  CHALLENGE = 'CHALLENGE',
}

export const RULE_TYPES_LABELS: Record<RuleType, string> = {
  [RuleType.PAYOUT]: 'Payout',
  [RuleType.LINE_PAYOUT]: 'Payout',
  [RuleType.NON_PAYOUT]: 'Non-Payout',
  [RuleType.SPLIT]: 'Split Payout',
  [RuleType.HOLD_AND_RELEASE]: 'Hold & Release',
  [RuleType.CHALLENGE]: 'Challenge',
};

export enum RuleTypeWithAdjustment {
  ADJUSTMENT = 'ADJUSTMENT',
}

export interface HoldAndReleaseSettings {
  releaseCondition?: string;
  paymentDate?: string;
  forecastPaymentDate?: string;
}

export interface Rule {
  id: string;
  createdAt: Date;
  updatedAt: Date;

  company?: Company;

  name: string;
  machineName: string;
  description: string | null;
  formula: string;
  type: RuleType;

  filter?: Filter;
  filterId: string | null;

  // FIXME: possible circular dep with Plan?
  // plan?: Plan;
  planId: string | null;

  // Split
  splitVariables: string[] | null;

  // H&R
  commissionVariableId: string | null;
  releaseConditionVariableId: string | null;

  // Configuration
  configuration: RuleConfiguration | null;

  // FIXME: they are not on the entity?
  categories?: PlanRuleCategory[];
  filterMachineName?: string;
}

export enum RuleMainType {
  RECORDS = 'RECORDS',
  TOTAL = 'TOTAL',
  CHALLENGE = 'CHALLENGE',
}

export interface RuleInForm extends Omit<Rule, 'createdAt' | 'updatedAt'> {
  ruleMainType: RuleMainType;
  isPayout: boolean;
  isHoldAndRelease: boolean;
  isSplit: boolean;
  valueSelector: string;
}
