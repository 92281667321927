import { IconMessage, IconMessageCheck } from '@tabler/icons-react';
import { memo, type MouseEvent, type MouseEventHandler, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type FormatsEnum, type PropertyRef } from '@amalia/data-capture/fields/types';
import { Button, Group, Popover, Stack, Typography } from '@amalia/design-system/components';
import { useClipboard } from '@amalia/design-system/ext';
import { eventStopPropagationAndPreventDefault } from '@amalia/lib-ui';
import { type StatementThread } from '@amalia/payout-collaboration/comments/types';

import { OverwriteTooltipValue, OverwriteTooltipValueVariant } from './overwrite-tooltip-value/OverwriteTooltipValue';
import * as styles from './OverwriteTooltipContent.styles';

export type OverwriteTooltipContentProps = {
  readonly isReadOnly: boolean;
  readonly author: string;
  readonly date: string;
  /* FIXME: any are you okay? */
  readonly oldValue: any;
  readonly newValue: any;
  readonly newValueRaw?: any;
  readonly valueFormat?: FormatsEnum;
  readonly valueRef?: PropertyRef;
  readonly handleOnClick?: MouseEventHandler<HTMLButtonElement>;
  readonly cellStatementThread?: StatementThread;
  readonly openStatementThread?: () => void;
};

export const OverwriteTooltipContent = memo(function OverwriteTooltipContent({
  isReadOnly,
  author,
  date,
  oldValue,
  newValue,
  newValueRaw,
  valueFormat,
  valueRef,
  handleOnClick,
  cellStatementThread,
  openStatementThread,
}: OverwriteTooltipContentProps) {
  const { copy } = useClipboard();
  const { formatMessage } = useIntl();

  const canClearOverwrite = !isReadOnly && !!handleOnClick;

  const onClickCopyOldValue = useCallback(
    (e: MouseEvent) => {
      eventStopPropagationAndPreventDefault(e);
      copy(`${oldValue}`);
    },
    [oldValue, copy],
  );

  const onClickCopyNewValue = useCallback(
    (e: MouseEvent) => {
      eventStopPropagationAndPreventDefault(e);
      copy(`${newValue}`);
    },
    [newValue, copy],
  );

  const onClickComment = useCallback(() => {
    openStatementThread?.();
  }, [openStatementThread]);

  return (
    <Popover.Layout css={styles.container}>
      <Popover.Header>
        <Popover.Title>
          <Typography variant={Typography.Variant.BODY_SMALL_MEDIUM}>
            <FormattedMessage defaultMessage="Overwrite details" />
          </Typography>
        </Popover.Title>

        {!!openStatementThread && (
          <Popover.HeaderActions>
            <Popover.HeaderAction
              withBackground
              data-testid="chat-button"
              label={formatMessage({ defaultMessage: 'Comment' })}
              icon={
                cellStatementThread?.thread.isReviewed ? (
                  <IconMessageCheck data-testid="chat-button:is-reviewed" />
                ) : (
                  <IconMessage data-testid="chat-button:not:is-reviewed" />
                )
              }
              onClick={onClickComment}
            />
          </Popover.HeaderActions>
        )}
      </Popover.Header>

      <Popover.Body>
        <Stack
          align="flex-start"
          gap={24}
        >
          <Group
            align="baseline"
            css={styles.valueContainer}
            gap={48}
          >
            <div css={styles.value}>
              <OverwriteTooltipValue
                value={oldValue}
                valueFormat={valueFormat}
                valueRef={valueRef}
                variant={OverwriteTooltipValueVariant.OLD}
                onClick={onClickCopyOldValue}
              />
            </div>

            <div css={styles.value}>
              <OverwriteTooltipValue
                rawValue={newValueRaw}
                value={newValue}
                valueFormat={valueFormat}
                valueRef={valueRef}
                variant={OverwriteTooltipValueVariant.NEW}
                onClick={onClickCopyNewValue}
              />
            </div>
          </Group>

          <Group
            align="center"
            css={styles.footer}
            gap={24}
            justify="space-between"
          >
            <div>
              <Typography variant={Typography.Variant.BODY_SMALL_REGULAR}>
                <FormattedMessage
                  defaultMessage="Overwritten by <strong>{author}</strong>{br}on <strong>{date}</strong>"
                  values={{
                    strong: (chunks) => <strong>{chunks}</strong>,
                    author,
                    date,
                  }}
                />
              </Typography>
            </div>

            <div>
              {!!canClearOverwrite && (
                <Button
                  data-testid="clean-overwrite-button"
                  variant={Button.Variant.PRIMARY}
                  onClick={handleOnClick}
                >
                  <FormattedMessage defaultMessage="Clear overwrite" />
                </Button>
              )}
            </div>
          </Group>
        </Stack>
      </Popover.Body>
    </Popover.Layout>
  );
});
