/**
 * Given a min and a max, limit the value in the range.
 */
export const minMaxLimit = (value: number, min: number, max: number): number => Math.max(Math.min(value, max), min);

/**
 * Return false if the number is falsy, but allow the value `0`.
 *
 * @param num
 */
export const numIsDefined = (num: number | null | undefined): boolean => !!num || num === 0;
