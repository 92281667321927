import { http } from '@amalia/core/http/client';
import { type OverwriteFiltersResponse, type OverwriteResponse, type PaginatedResponse } from '@amalia/core/types';

import { type OverwriteConfigFilters } from '../../types/overwrites';

const apiEndpoint = '/overwrites';

export async function getDataFilters(): Promise<OverwriteFiltersResponse> {
  const { data } = await http.get<OverwriteFiltersResponse>(`${apiEndpoint}/filters`);
  return data;
}

export async function getOverwrites(
  searchText?: string,
  page = 0,
  limit = 10,
  sortColumn?: string,
  sortOrder = 'asc',
  selectedFilters: OverwriteConfigFilters | null = null,
): Promise<PaginatedResponse<Partial<OverwriteResponse>>> {
  const {
    items: responseItems,
    totalItems,
    pageCount,
  } = (
    await http.get<PaginatedResponse<Partial<OverwriteResponse>>>(apiEndpoint, {
      params: {
        sort: sortColumn,
        [sortOrder]: sortOrder,
        q: searchText,
        page,
        limit,
        ...selectedFilters,
      },
    })
  ).data;

  return {
    totalItems,
    items: responseItems,
    pageCount,
  };
}
