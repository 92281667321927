import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { assert, toError } from '@amalia/ext/typescript';
import {
  CompanyCurrencyRatesApiClient,
  CurrenciesApiClient,
  CurrencyRatesApiClient,
} from '@amalia/tenants/companies/currency-rates/api-client';
import {
  type RateTableData,
  type CompanyCurrency,
  type Currency,
} from '@amalia/tenants/companies/currency-rates/types';

import { type ThunkResult } from '../types';

import { CURRENCIES_ACTIONS } from './constants';
import {
  type CurrenciesCompanyCurrencyCreateAction,
  type CurrenciesCompanyCurrencyUpdateAction,
  type CurrenciesSetCurrenciesWithCompanyCurrenciesAction,
  type CurrenciesCompanyCurrencyDeleteAction,
  type CurrenciesSetCurrencyRateAction,
  type CurrenciesErrorAction,
  type CurrenciesStartAction,
} from './types';

const currencyStart = (): CurrenciesStartAction => ({
  type: CURRENCIES_ACTIONS.START,
});

const currencyError = (error: Error): CurrenciesErrorAction => ({
  type: CURRENCIES_ACTIONS.ERROR,
  error,
});

// CURRENCY RATES
const setCurrencyRate = (currencyRate: number): CurrenciesSetCurrencyRateAction => ({
  type: CURRENCIES_ACTIONS.SET_CURRENCY_RATE,
  payload: { currencyRate },
});

export const fetchCurrencyRate =
  (
    symbol: CurrencySymbolsEnum,
    date: number,
  ): ThunkResult<Promise<CurrenciesErrorAction | CurrenciesSetCurrencyRateAction>> =>
  async (dispatch) => {
    dispatch(currencyStart());

    try {
      const currencyRate = await CurrencyRatesApiClient.getCurrencyRate(symbol, date);
      return dispatch(setCurrencyRate(currencyRate));
    } catch (error) {
      return dispatch(currencyError(toError(error)));
    }
  };

// COMPANY CURRENCIES
const setCurrenciesWithCompanyCurrencies = (
  currencies: Currency[],
  companyCurrencies: CompanyCurrency[],
): CurrenciesSetCurrenciesWithCompanyCurrenciesAction => ({
  type: CURRENCIES_ACTIONS.SET_CURRENCIES_WITH_COMPANY_CURRENCIES,
  payload: { currencies, companyCurrencies },
});

export const fetchCurrenciesWithCompanyCurrenciesForYear =
  (year: string): ThunkResult<Promise<CurrenciesErrorAction | CurrenciesSetCurrenciesWithCompanyCurrenciesAction>> =>
  async (dispatch) => {
    dispatch(currencyStart());

    try {
      const [currencies, companyCurrencies] = await Promise.all([
        CurrenciesApiClient.getCurrencies(year.toString()),
        CompanyCurrencyRatesApiClient.list(year.toString()),
      ]);

      return dispatch(setCurrenciesWithCompanyCurrencies(currencies, companyCurrencies));
    } catch (error) {
      return dispatch(currencyError(toError(error)));
    }
  };

// COMPANY CURRENCY
const createCompanyCurrencyAction = (companyCurrency: CompanyCurrency): CurrenciesCompanyCurrencyCreateAction => ({
  type: CURRENCIES_ACTIONS.COMPANY_CURRENCY_CREATE,
  payload: { companyCurrency },
});

export const createCompanyCurrency =
  (
    companyCurrencyToCreate: Pick<CompanyCurrency, 'currency' | 'rate' | 'symbol'>,
  ): ThunkResult<Promise<CurrenciesCompanyCurrencyCreateAction | CurrenciesErrorAction>> =>
  async (dispatch) => {
    dispatch(currencyStart());

    try {
      const companyCurrencyCreated = await CompanyCurrencyRatesApiClient.create(companyCurrencyToCreate);
      return dispatch(createCompanyCurrencyAction(companyCurrencyCreated));
    } catch (error) {
      return dispatch(currencyError(toError(error)));
    }
  };

const updateCompanyCurrencyAction = (companyCurrency: CompanyCurrency): CurrenciesCompanyCurrencyUpdateAction => ({
  type: CURRENCIES_ACTIONS.COMPANY_CURRENCY_UPDATE,
  payload: { companyCurrency },
});

export const updateCompanyCurrency =
  (
    companyCurrencyToUpdate: Pick<CompanyCurrency, 'currency' | 'rate' | 'symbol'>,
  ): ThunkResult<Promise<CurrenciesCompanyCurrencyUpdateAction | CurrenciesErrorAction>> =>
  async (dispatch) => {
    dispatch(currencyStart());

    try {
      const companyCurrencyUpdated = await CompanyCurrencyRatesApiClient.update(companyCurrencyToUpdate);
      return dispatch(updateCompanyCurrencyAction(companyCurrencyUpdated));
    } catch (error) {
      return dispatch(currencyError(toError(error)));
    }
  };

const deleteCompanyCurrencyAction = (companyCurrency: RateTableData): CurrenciesCompanyCurrencyDeleteAction => ({
  type: CURRENCIES_ACTIONS.COMPANY_CURRENCY_DELETE,
  payload: { companyCurrency },
});

export const deleteCompanyCurrency =
  (
    companyCurrencyToDelete: RateTableData,
  ): ThunkResult<Promise<CurrenciesCompanyCurrencyDeleteAction | CurrenciesErrorAction>> =>
  async (dispatch) => {
    dispatch(currencyStart());

    try {
      assert(companyCurrencyToDelete.id, 'Company currency was not created yet!');
      await CompanyCurrencyRatesApiClient.delete(companyCurrencyToDelete.id);
      return dispatch(deleteCompanyCurrencyAction(companyCurrencyToDelete));
    } catch (error) {
      return dispatch(currencyError(toError(error)));
    }
  };
