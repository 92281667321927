import { Fragment, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { type Calculation, CalculationStatus } from '@amalia/core/types';
import { AlertBanner, Group, PageHeader } from '@amalia/design-system/components';
import { type CreateAdjustmentDto, selectStatementPaymentAmountByCategory } from '@amalia/frontend/web-data-layers';
import { useStatementDetailContext } from '@amalia/lib-ui';
import { type StatementThread } from '@amalia/payout-collaboration/comments/types';
import { PlanAwardsList } from '@amalia/payout-definition/plans/components';
import { useCurrentCompany } from '@amalia/tenants/companies/state';
import { CompanyFeatureFlags } from '@amalia/tenants/companies/types';
import { FeatureFlag } from '@amalia/tenants/feature-flags/components';

import { CalculationError } from '../../common/CalculationError';
import { StatementSummary } from '../statement-detail-summary/StatementDetailSummary';
import { type StatementViewEnum } from '../StatementDetail';

import { StatementDetailHeaderActions } from './actions/StatementDetailHeaderActions';
import { StatementDetailHeaderBreadcrumbs } from './breadcrumbs/StatementDetailHeaderBreadcrumbs';
import * as styles from './StatementDetailHeader.styles';
import { StatementDetailHeaderPayoutQuotaError } from './StatementDetailHeaderPayoutQuotaError';
import { StatementDetailHeaderUserInformations } from './user-informations/StatementDetailHeaderUserInformations';

interface StatementDetailHeaderProps {
  readonly setSelectAdjustment: (adjustment: CreateAdjustmentDto) => void;
  readonly onClickGoToStatement: (period: string) => void;
  readonly statementCommentThread: StatementThread | null;
  readonly isTracingActive: boolean;
  readonly lastCalculation?: Calculation;
  readonly handleForecastedSwitch: (nextState: StatementViewEnum) => Promise<void>;
}

export const StatementDetailHeader = memo(function StatementDetailHeader({
  setSelectAdjustment,
  onClickGoToStatement,
  statementCommentThread,
  isTracingActive,
  lastCalculation,
  handleForecastedSwitch,
}: StatementDetailHeaderProps) {
  const { statement, isForecastedView } = useStatementDetailContext();
  const { data: company } = useCurrentCompany();
  const paymentTotalByType = useSelector(selectStatementPaymentAmountByCategory);

  return (
    <Fragment>
      <PageHeader
        stickyRow={
          <div css={{ padding: '4px 0' }}>
            <PageHeader.Row
              left={
                <StatementDetailHeaderBreadcrumbs
                  isForecastedView={isForecastedView}
                  statement={statement}
                  withShadow={false}
                />
              }
              right={
                <StatementDetailHeaderActions.StickyRow
                  companyFeatureFlags={company.featureFlags}
                  handleForecastedSwitch={handleForecastedSwitch}
                  paymentTotalByType={paymentTotalByType}
                  setSelectAdjustment={setSelectAdjustment}
                  statementCommentThread={statementCommentThread}
                />
              }
            />
          </div>
        }
      >
        <PageHeader.Row
          left={
            <StatementDetailHeaderBreadcrumbs
              isForecastedView={isForecastedView}
              statement={statement}
            />
          }
          right={
            <StatementDetailHeaderActions.FirstRow
              companyFeatureFlags={company.featureFlags}
              handleForecastedSwitch={handleForecastedSwitch}
              setSelectAdjustment={setSelectAdjustment}
              statementCommentThread={statementCommentThread}
            />
          }
        />
        <PageHeader.Row
          left={
            <Group
              align="center"
              gap={24}
            >
              <StatementDetailHeaderUserInformations
                statement={statement}
                user={statement.user}
              />

              <FeatureFlag.Enabled featureFlag={CompanyFeatureFlags.BADGES}>
                {!!statement.results?.definitions.plan.badgeConfigurations && (
                  <PlanAwardsList
                    awards={statement.badgeAwards || []}
                    configurations={statement.results.definitions.plan.badgeConfigurations}
                    period={statement.period}
                  />
                )}
              </FeatureFlag.Enabled>
            </Group>
          }
          right={
            <StatementDetailHeaderActions.SecondRow
              companyFeatureFlags={company.featureFlags}
              handleForecastedSwitch={handleForecastedSwitch}
              setSelectAdjustment={setSelectAdjustment}
              statementCommentThread={statementCommentThread}
            />
          }
        />
      </PageHeader>

      <div css={styles.summaryContainer}>
        <div css={styles.alertsContainer}>
          {!isTracingActive && lastCalculation?.status === CalculationStatus.ERROR && (
            <CalculationError calculation={lastCalculation} />
          )}

          {!!statement.error && <StatementDetailHeaderPayoutQuotaError />}

          {!!isForecastedView && !statement.forecastId && (
            <AlertBanner
              inline
              withBorder
            >
              <FormattedMessage defaultMessage="There is no forecasted statement calculated for this period." />
            </AlertBanner>
          )}

          {!isForecastedView && !!statement.workflowComplete && (
            <AlertBanner
              inline
              withBorder
              variant={AlertBanner.Variant.SUCCESS}
            >
              <FormattedMessage defaultMessage="Statement reviewed." />
            </AlertBanner>
          )}
        </div>

        <StatementSummary onClickGoToStatement={onClickGoToStatement} />

        {!!isTracingActive && (
          <AlertBanner withBorder>
            <FormattedMessage
              defaultMessage="<span>Tracing</span> is in beta version"
              values={{
                span: (chunks) => <span css={styles.tracingText}>{chunks}</span>,
              }}
            />
          </AlertBanner>
        )}
      </div>
    </Fragment>
  );
});
