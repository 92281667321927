export enum TransitionVariant {
  DEFAULT = 'default',
}

export type TransitionParameters = {
  durationMs: number;
  delayMs: number;
  easing: string;
};

export type TransitionFn = (...properties: string[]) => string;

export type TransitionDefinition = TransitionFn & TransitionParameters;

export type ThemeTransitions = Record<TransitionVariant, TransitionDefinition>;
