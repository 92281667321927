import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Menu } from '@amalia/design-system/components';

import { type PageIdentifier, useNavigationItems } from './useNavigationItems';

export type LeftBarMenuProps = {
  readonly currentPage?: PageIdentifier;
};

export const LeftBarMenu = memo(function LeftBarMenu({ currentPage }: LeftBarMenuProps) {
  const { navigationItems } = useNavigationItems(currentPage);
  return (
    <Menu>
      <Menu.Group>
        {navigationItems.root.map((item) => (
          <Menu.Item
            key={JSON.stringify(item.route)}
            icon={item.icon}
            isActive={item.isSelected}
            to={item.route}
          >
            {item.label}
          </Menu.Item>
        ))}
      </Menu.Group>

      <Menu.Group title={<FormattedMessage defaultMessage="Management" />}>
        {navigationItems.management.map((item) => (
          <Menu.Item
            key={JSON.stringify(item.route)}
            icon={item.icon}
            isActive={item.isSelected}
            to={item.route}
          >
            {item.label}
          </Menu.Item>
        ))}
      </Menu.Group>

      <Menu.Group
        title={
          <FormattedMessage
            defaultMessage="Admin"
            description="Administration section title in menu"
          />
        }
      >
        {navigationItems.admin.map((item) => (
          <Menu.Item
            key={JSON.stringify(item.route)}
            icon={item.icon}
            isActive={item.isSelected}
            to={item.route}
          >
            {item.label}
          </Menu.Item>
        ))}
      </Menu.Group>
    </Menu>
  );
});
