export enum UserRole {
  ADMIN = 'ADMIN',
  READ_ONLY_ADMIN = 'READ_ONLY_ADMIN',
  FINANCE = 'FINANCE',
  MANAGER = 'MANAGER',
  READ_ONLY_MANAGER = 'READ_ONLY_MANAGER',
  EMPLOYEE = 'EMPLOYEE',
  READ_ONLY_EMPLOYEE = 'READ_ONLY_EMPLOYEE',
}

export const userReadOnlyRoleMatcher: Record<UserRole, UserRole> = {
  [UserRole.ADMIN]: UserRole.READ_ONLY_ADMIN,
  [UserRole.READ_ONLY_ADMIN]: UserRole.READ_ONLY_ADMIN,
  [UserRole.FINANCE]: UserRole.FINANCE,
  [UserRole.MANAGER]: UserRole.READ_ONLY_MANAGER,
  [UserRole.READ_ONLY_MANAGER]: UserRole.READ_ONLY_MANAGER,
  [UserRole.EMPLOYEE]: UserRole.READ_ONLY_EMPLOYEE,
  [UserRole.READ_ONLY_EMPLOYEE]: UserRole.READ_ONLY_EMPLOYEE,
};
