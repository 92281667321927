import { memo, useMemo } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { type MergeAll } from '@amalia/ext/typescript';

import { SimpleSelect, type SimpleSelectProps } from '../../../data-input/simple-select/SimpleSelect';
import { type SimpleSelectOption } from '../../../data-input/simple-select/SimpleSelect.types';

const DEFAULT_PAGE_SIZE_OPTIONS = [10, 25, 50, 100];

export type PageSizeProps = MergeAll<
  [
    SimpleSelectProps<SimpleSelectOption<number>, false, never>,
    {
      /** Rows per page options. */
      options?: number[];
      /** Current value. If options was a const array, value must be an option from the array. */
      value: number;
      /** Change handler. */
      onChange: Required<SimpleSelectProps<SimpleSelectOption<number>, false, never>>['onChange'];
    },
  ]
>;

const PageSizeBase = function PageSize({ options = DEFAULT_PAGE_SIZE_OPTIONS, ...props }: PageSizeProps) {
  const simpleSelectOptions: SimpleSelectOption<number>[] = useMemo(
    () =>
      options.map((option) => ({
        value: option,
        label: <FormattedNumber value={option} />,
        filterLabel: option.toString(),
        valueLabel: (
          <FormattedMessage
            defaultMessage="Rows per page: {rowsPerPage, number}"
            values={{ rowsPerPage: option }}
          />
        ),
      })),
    [options],
  );

  return (
    <SimpleSelect<SimpleSelectOption<number>, false, never>
      {...props}
      options={simpleSelectOptions}
    />
  );
};

export const PageSize = memo(PageSizeBase);
