import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { formatCurrencyAmount, PaymentCategory, roundNumber } from '@amalia/core/types';
import { FatTab, type FatTabButtonProps } from '@amalia/design-system/components';
import { TypographyVariant } from '@amalia/design-system/meta';
import { type MergeAll } from '@amalia/ext/typescript';
import { type CurrencyValue } from '@amalia/kernel/monetary/types';
import { ForecastAmount, ForecastSimulationPopover } from '@amalia/payout-calculation/forecasts/components';

export type StatementHeaderPayoutForecastedProps = MergeAll<
  [
    Omit<FatTabButtonProps, 'data' | 'label' | 'subtitle' | 'tooltip'>,
    {
      forecastedTotal: CurrencyValue;
      statementTotal: CurrencyValue;
    },
  ]
>;

export const StatementHeaderPayoutForecasted = memo(function StatementHeaderPayoutForecasted({
  forecastedTotal,
  statementTotal,
  ...props
}: StatementHeaderPayoutForecastedProps) {
  const formattedAchievedValue = formatCurrencyAmount({
    amount: roundNumber(statementTotal.value) || 0,
    currencySymbol: statementTotal.symbol,
  });

  const formattedForecastedValue = formatCurrencyAmount({
    amount: roundNumber(forecastedTotal.value) || 0,
    currencySymbol: forecastedTotal.symbol,
  });

  return (
    <FatTab
      {...props}
      label={<FormattedMessage defaultMessage="Total forecasted" />}
      data={
        <ForecastSimulationPopover
          formattedAchievedValue={formattedAchievedValue}
          formattedForecastedValue={formattedForecastedValue}
        >
          <ForecastAmount
            data-cy={`payout-${PaymentCategory.achievement}`}
            variant={TypographyVariant.HEADING_2_MEDIUM}
          >
            {formattedForecastedValue}
          </ForecastAmount>
        </ForecastSimulationPopover>
      }
    />
  );
});
