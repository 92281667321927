import { IconKey } from '@tabler/icons-react';
import { Fragment, memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type CustomObject } from '@amalia/core/types';
import { type Property } from '@amalia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { ColumnHelper, Tooltip } from '@amalia/design-system/components';

import { OverwrittenTableCell } from './OverwrittenTableCell';
import { TableCell } from './TableCell';

const columnHelper = new ColumnHelper<CustomObject>();

type PrimaryKeyIconProps = Readonly<{
  objectDefinition: CustomObjectDefinition;
  property: Property;
}>;

const PrimaryKeyIcon = memo(function PrimaryKeyIcon({ property, objectDefinition }: PrimaryKeyIconProps) {
  const primaryKeyFields = objectDefinition.externalIds;
  const isPrimaryKey = primaryKeyFields?.includes(property.machineName);

  const primaryKeyProperties = useMemo(
    () => Object.values(objectDefinition.properties).filter((p) => primaryKeyFields?.includes(p.machineName)),
    [objectDefinition.properties, primaryKeyFields],
  );

  if (!isPrimaryKey) {
    return undefined;
  }
  return (
    <Tooltip
      content={
        <FormattedMessage
          defaultMessage="{primaryKeyFields} is the Primary Key. {br}It's unique for every record and is used to identify it when refreshing the data."
          values={{
            primaryKeyFields: primaryKeyProperties.map((p) => p.name).join(', '),
            br: <br />,
          }}
        />
      }
    >
      <IconKey size="14.4" />
    </Tooltip>
  );
});

export const useCustomTableColumns = (objectDefinition: CustomObjectDefinition, fieldsToDisplay: string[]) =>
  useMemo(
    () =>
      Object.entries(objectDefinition?.properties || {})
        .filter(([key]) => fieldsToDisplay?.includes(key) || !fieldsToDisplay)
        .map(([key, value]) => {
          const { machineName, name } = value;

          return columnHelper.display({
            id: machineName,
            header: name,
            subTitle: machineName,
            icon: (
              <PrimaryKeyIcon
                objectDefinition={objectDefinition}
                property={value}
              />
            ),
            isSortable: true,
            size: 240,
            cell: ({ row, rowIndex }) => {
              const overwrite = row?.overwrites?.find((ow) => ow.field === machineName);

              return (
                <Fragment>
                  {overwrite ? (
                    <OverwrittenTableCell
                      columnName={key}
                      overwrite={overwrite}
                      property={value}
                      row={row}
                      rowIndex={rowIndex}
                    />
                  ) : (
                    <TableCell
                      columnName={key}
                      property={value}
                      row={row}
                      rowIndex={rowIndex}
                    />
                  )}
                </Fragment>
              );
            },
          });
        }),
    [fieldsToDisplay, objectDefinition],
  );
