import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { formatDate } from '@amalia/core/types';
import { Badge, type BadgeProps, Tooltip } from '@amalia/design-system/components';
import { toDate } from '@amalia/ext/dates';
import { UserStatus as UserStatusEnum, type UserStatusWithDates } from '@amalia/tenants/users/types';

import { UserStatusLabel } from './UserStatusLabel';

export type UserStatusBadgeProps = Omit<BadgeProps, 'children' | 'variant'> & {
  readonly user: Pick<UserStatusWithDates, 'clearedAt' | 'createdAt' | 'invitationSentAt' | 'joinedAt' | 'status'>;
};

const UserStatusBadgeBase = memo(function UserStatus({ user, ...props }: UserStatusBadgeProps) {
  const { createdAt, clearedAt, invitationSentAt, joinedAt, status } = user;

  const badgeProps = {
    withShadow: true,
    'data-testid': 'user-status-badge',
    size: Badge.Size.LARGE,
    ...props,
  };

  switch (status) {
    case UserStatusEnum.DEACTIVATED:
      return (
        <Tooltip
          placement="bottom"
          content={
            <FormattedMessage
              defaultMessage="Deactivated on {date}"
              values={{
                date: formatDate(toDate(clearedAt as Date), 'YYYY-MM-DD'),
              }}
            />
          }
        >
          <Badge
            {...badgeProps}
            variant={Badge.Status.MISSING}
          >
            <UserStatusLabel status={status} />
          </Badge>
        </Tooltip>
      );
    case UserStatusEnum.ACTIVE:
      return (
        <Tooltip
          placement="bottom"
          content={
            <FormattedMessage
              defaultMessage="Active since {date}"
              values={{
                date: formatDate(toDate(joinedAt as Date), 'YYYY-MM-DD'),
              }}
            />
          }
        >
          <Badge
            {...badgeProps}
            variant={Badge.Status.SUCCESS}
          >
            <UserStatusLabel status={status} />
          </Badge>
        </Tooltip>
      );
    case UserStatusEnum.INVITED:
      return (
        <Tooltip
          placement="bottom"
          content={
            <FormattedMessage
              defaultMessage="Invited on {date}"
              values={{
                date: formatDate(toDate(invitationSentAt as Date), 'YYYY-MM-DD'),
              }}
            />
          }
        >
          <Badge
            {...badgeProps}
            variant={Badge.Status.PENDING}
          >
            <UserStatusLabel status={status} />
          </Badge>
        </Tooltip>
      );
    case UserStatusEnum.INACTIVE:
      return (
        <Tooltip
          placement="bottom"
          content={
            <FormattedMessage
              defaultMessage="Added on {date}"
              values={{
                date: formatDate(toDate(createdAt), 'YYYY-MM-DD'),
              }}
            />
          }
        >
          <Badge
            {...badgeProps}
            variant={Badge.Status.WARNING}
          >
            <UserStatusLabel status={status} />
          </Badge>
        </Tooltip>
      );
  }
});

export const UserStatusBadge = Object.assign(UserStatusBadgeBase, {
  Size: Badge.Size,
});
