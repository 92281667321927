import { type Merge } from '@amalia/ext/typescript';

import { type Variable, VariableObjectsEnum } from './variables';

export const QUOTA_TYPES = [VariableObjectsEnum.plan, VariableObjectsEnum.team, VariableObjectsEnum.user] as const;
export type QuotaType = (typeof QUOTA_TYPES)[number];

/** FIXME: extract quota to own concept */
export type Quota = Merge<
  Variable,
  {
    userId?: string;
    type: QuotaType;
  }
>;

export type UserQuota = Merge<Quota, { type: VariableObjectsEnum.user }>;
export type TeamQuota = Merge<Quota, { type: VariableObjectsEnum.team }>;
export type PlanQuota = Merge<Quota, { type: VariableObjectsEnum.plan }>;
