import { css } from '@emotion/react';
import { memo } from 'react';

import { Avatar, Skeleton, Typography } from '@amalia/design-system/components';

import * as styles from '../NotificationListItem.styles';

const CONTENT_PLACEHOLDER = 'Lorem ipsum dolor sit amet.';
const DATE_PLACEHOLDER = '30 days ago';

export const NotificationListItemSkeleton = memo(function NotificationListItemSkeleton() {
  return (
    <div css={styles.notification}>
      <div css={styles.content}>
        <Skeleton shape={Skeleton.Shape.ROUND}>
          <Avatar
            size={Avatar.Size.SMALL}
            user={{}}
          />
        </Skeleton>

        <div css={styles.textContainer}>
          <Skeleton
            css={css`
              width: 100%;
            `}
          >
            <Typography
              css={styles.text}
              variant={Typography.Variant.BODY_SMALL_MEDIUM}
            >
              {CONTENT_PLACEHOLDER}
            </Typography>
          </Skeleton>
          <Skeleton>
            <Typography
              css={styles.date}
              variant={Typography.Variant.BODY_XSMALL_REGULAR}
            >
              {DATE_PLACEHOLDER}
            </Typography>
          </Skeleton>
        </div>
      </div>
    </div>
  );
});
