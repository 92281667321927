import clsx from 'clsx';
import { memo, useCallback, useMemo } from 'react';

import { formatDate, fromNow, type Notification } from '@amalia/core/types';
import { Avatar, Skeleton, Tooltip, Typography, UnstyledButton } from '@amalia/design-system/components';
import { Link } from '@amalia/ext/react-router-dom';
import { assert } from '@amalia/ext/typescript';
import { DataExportsApiClient } from '@amalia/frontend/web-data-layers';
import { type UsersMap } from '@amalia/tenants/users/types';

import { getNotificationText } from './getNotificationText';
import * as styles from './NotificationListItem.styles';

export type NotificationListItemProps = {
  readonly notification: Notification;
  readonly usersMap: UsersMap;
  readonly onClick: () => void;
};

export const NotificationListItem = memo(function NotificationListItem({
  notification,
  usersMap,
  onClick,
}: NotificationListItemProps) {
  const content = getNotificationText(notification);

  const handleDownloadNotificationExport = useCallback(async () => {
    assert(notification.payload?.exportId, 'This method is only called for export notifications');
    await DataExportsApiClient.downloadDataExportFile(notification.payload.exportId);
    onClick();
  }, [notification, onClick]);

  // The link has the https://app.amalia.io origin in it, but it doesn't work with <Link> so we just remove it.
  const relativeUrl = useMemo(() => {
    try {
      return notification.link
        ? notification.link.replace(new URL(notification.link || '').origin, '') || undefined
        : undefined;
    } catch (err) {
      // Return undefined in case the URL is invalid. It will just render the notification as non-interactive instead of crashing.
      return undefined;
    }
  }, [notification.link]);

  const notificationContent = (
    <div css={styles.content}>
      {!!notification.authorId && (
        <div css={styles.avatarContainer}>
          <Skeleton
            shape={Skeleton.Shape.ROUND}
            visible={!usersMap[notification.authorId]}
          >
            <Avatar
              size={Avatar.Size.SMALL}
              user={usersMap[notification.authorId]}
            />
          </Skeleton>

          {!notification.read && (
            <div css={styles.dotContainer}>
              <div css={styles.dot} />
            </div>
          )}
        </div>
      )}

      <div css={styles.textContainer}>
        <Typography
          css={styles.text}
          variant={Typography.Variant.BODY_SMALL_MEDIUM}
        >
          {content}
        </Typography>

        {!!notification.createdAt && (
          <Tooltip content={formatDate(notification.createdAt, 'lll')}>
            <Typography
              css={styles.date}
              variant={Typography.Variant.BODY_XSMALL_REGULAR}
            >
              {fromNow(notification.createdAt)}
            </Typography>
          </Tooltip>
        )}
      </div>
    </div>
  );

  return notification.payload?.exportId ? (
    <UnstyledButton
      className={clsx({ [styles.IS_UNREAD_CLASSNAME]: !notification.read })}
      css={[styles.notification, styles.notificationInteractive]}
      onClick={handleDownloadNotificationExport}
    >
      {notificationContent}
    </UnstyledButton>
  ) : relativeUrl ? (
    <Link
      className={clsx({ [styles.IS_UNREAD_CLASSNAME]: !notification.read })}
      css={[styles.notification, styles.notificationInteractive]}
      to={relativeUrl}
      onClick={onClick}
    >
      {notificationContent}
    </Link>
  ) : (
    <div
      className={clsx({ [styles.IS_UNREAD_CLASSNAME]: !notification.read })}
      css={styles.notification}
    >
      {notificationContent}
    </div>
  );
});
