import { Collapse } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconChevronUp } from '@tabler/icons-react';
import { memo, useCallback, useEffect, useState, type ReactNode } from 'react';

import { UnstyledButton } from '@amalia/design-system/components';
import { useIsMounted } from '@amalia/ext/react/hooks';

const ANIMATION_DURATION = 0.3;

const useStyles = makeStyles(() => ({
  collapse: {
    transition: `height ${ANIMATION_DURATION}s ease-in-out`,
  },
  iconButtonBottom: {
    transform: 'rotate(180deg)',
    transition: `transform ${ANIMATION_DURATION}s ease-in-out`,
  },
  iconButtonTop: {
    transform: 'rotate(0deg)',
    transition: `transform ${ANIMATION_DURATION}s ease-in-out`,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    cursor: 'pointer',
  },
}));

export type CollapsibleBoxProps = {
  readonly children: ReactNode;
  readonly defaultOpenState?: boolean;
  readonly title: ReactNode;
};

export const CollapsibleBox = memo(function CollapsibleBox({
  children,
  defaultOpenState = true,
  title,
}: CollapsibleBoxProps) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(defaultOpenState);
  const [renderInsideContent, setRenderInsideContent] = useState(false);
  const isMounted = useIsMounted();

  const handleToggle = useCallback(() => {
    setIsOpen((openState) => !openState);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setRenderInsideContent(true);
    } else {
      setTimeout(() => {
        if (isMounted()) {
          setRenderInsideContent(false);
        }
      }, ANIMATION_DURATION);
    }
  }, [isOpen, isMounted]);

  return (
    <div>
      <UnstyledButton
        className={classes.titleContainer}
        data-test-id="toggle collapse"
        onClick={handleToggle}
      >
        {title}

        <IconChevronUp className={isOpen ? classes.iconButtonTop : classes.iconButtonBottom} />
      </UnstyledButton>

      <Collapse
        className={classes.collapse}
        data-testid="collapsible-box-content"
        in={isOpen}
      >
        {renderInsideContent ? children : null}
      </Collapse>
    </div>
  );
});
