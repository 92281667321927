import { ClassNames } from '@emotion/react';
import { type ForwardedRef, forwardRef, memo, cloneElement } from 'react';

import { toLinkElement, type ToOrLinkElement } from '@amalia/ext/react-router-dom';
import { type MergeAll } from '@amalia/ext/typescript';

import { IconButton, type IconButtonProps } from '../../general/icon-button/IconButton';

export type IconButtonLinkProps = MergeAll<
  [
    IconButtonProps,
    {
      to?: ToOrLinkElement<false>;
    },
  ]
>;

const IconButtonLinkForwardRef = forwardRef(function IconButtonLink(
  { to, ...props }: IconButtonLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  const linkElement = toLinkElement(to);

  return (
    <ClassNames>
      {({ css }) =>
        cloneElement(
          linkElement,
          {
            ref,
            tabIndex: -1, // We want to focus the underlying Button, otherwise you need to tab twice to get to the next element.
            disabled: props.disabled ?? linkElement.props.disabled,
            className: css`
              text-decoration: none;
              display: inline-flex;
            `,
          },
          <IconButton {...props} />,
        )
      }
    </ClassNames>
  );
});

export const IconButtonLink = Object.assign(memo(IconButtonLinkForwardRef), {
  Size: IconButton.Size,
  Variant: IconButton.Variant,
});
