import { IconArrowLeft } from '@tabler/icons-react';
import clsx from 'clsx';
import { memo, type MouseEvent, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  type TablerIconElement,
  IconButton,
  type ButtonVariant,
  Stack,
} from '@amalia/design-system/components';

import { TextType, Text } from '../../typography';

import { usePageHeaderStyles } from './usePageLayoutStyles';

export interface PageHeaderButtonProps {
  title: ReactNode | string;
  variant?: ButtonVariant;
  dataTestId?: string;
  icon?: TablerIconElement;
  onClick?: (event: MouseEvent<HTMLButtonElement, any>) => void;
  url?: string;
  disabled?: boolean;
}

interface PageHeaderProps {
  readonly title: ReactNode | string;
  readonly subTitle?: ReactNode | string;
  readonly containerClassName?: string;
  readonly headerContainerClassName?: string;
  readonly buttonActions?: PageHeaderButtonProps[];
  readonly leftActions?: ReactNode | string;
  readonly leftActionsRightSide?: ReactNode | string;
  readonly rightActions?: ReactNode | string;
  readonly onClickGoBack?: () => void;
  readonly children?: ReactNode;
  readonly withFilterBar?: boolean;
}

export const PageHeader = memo(function PageHeader({
  title,
  subTitle,
  containerClassName,
  headerContainerClassName,
  buttonActions,
  leftActions,
  leftActionsRightSide,
  rightActions,
  onClickGoBack,
  children,
  withFilterBar,
}: PageHeaderProps) {
  const classes = usePageHeaderStyles();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  return (
    <section className={clsx(classes.container, containerClassName)}>
      <div className={classes.headerContainer}>
        <div
          className={clsx(
            classes.header,
            children && classes.headerContainerWithHeaderContent,
            headerContainerClassName,
          )}
        >
          {onClickGoBack ? (
            <IconButton
              className={classes.backButton}
              icon={<IconArrowLeft />}
              label={formatMessage({ defaultMessage: 'Go back' })}
              onClick={onClickGoBack}
            />
          ) : null}
          {leftActions ? <div className={classes.leftActions}>{leftActions}</div> : null}
          <Stack
            align="flex-start"
            className={clsx(classes.headerTitle, withFilterBar && classes.headerBorderRight)}
            gap={4}
          >
            <Text
              id="page-header-title"
              type={TextType.PAGE_TITLE}
            >
              {title}
            </Text>
            {subTitle ? (
              <Text
                id="page-header-subtitle"
                type={TextType.PAGE_SUBTITLE}
              >
                {subTitle}
              </Text>
            ) : null}
          </Stack>
          {leftActionsRightSide ? <div className={classes.leftActions}>{leftActionsRightSide}</div> : null}
          <div className={classes.separator} />
          {buttonActions?.map((btnAction) =>
            btnAction.url ? (
              <Button
                key={btnAction.dataTestId || btnAction.url || btnAction.title.toString()}
                data-testid={btnAction.dataTestId}
                disabled={btnAction.disabled}
                icon={btnAction.icon}
                variant={btnAction.variant}
                onClick={() => navigate(btnAction.url)}
              >
                {btnAction.title}
              </Button>
            ) : (
              <Button
                key={btnAction.dataTestId || btnAction.url || btnAction.title.toString()}
                data-testid={btnAction.dataTestId}
                disabled={btnAction.disabled}
                icon={btnAction.icon}
                variant={btnAction.variant}
                onClick={btnAction.onClick}
              >
                {btnAction.title}
              </Button>
            ),
          )}
          {rightActions ? <div className={classes.rightActions}>{rightActions}</div> : null}
        </div>
        {children}
      </div>
    </section>
  );
});
