import { css } from '@emotion/react';
import { Suspense, lazy, memo } from 'react';

import { Button, Group, Skeleton } from '@amalia/design-system/components';

import { PlanAwardsAddAwardButton } from '../add-award-button/PlanAwardsAddAwardButton';

const PlanAwardsFormEmptyStateSvg = lazy(async () => ({
  default: (await import('./PlanAwardsFormEmptyState.svg')).ReactComponent,
}));

export type PlanAwardsFormEmptyStateProps = {
  readonly onAddBadge: () => void;
  readonly disabled?: boolean;
};

export const PlanAwardsFormEmptyState = memo(function PlanAwardsFormEmptyState({
  onAddBadge,
  disabled = false,
}: PlanAwardsFormEmptyStateProps) {
  return (
    <Group
      align="center"
      justify="center"
      css={css`
        position: relative;
      `}
    >
      <Suspense
        fallback={
          <Skeleton
            css={css`
              height: 173px;
              width: 720px;
            `}
          />
        }
      >
        <PlanAwardsFormEmptyStateSvg />
      </Suspense>

      <Group
        align="center"
        justify="center"
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        `}
      >
        <PlanAwardsAddAwardButton
          disabled={disabled}
          size={Button.Size.LARGE}
          variant={Button.Variant.PRIMARY}
          onClick={onAddBadge}
        />
      </Group>
    </Group>
  );
});
