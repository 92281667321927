import { memo, useCallback, useMemo, useState } from 'react';

import { Modal, ModalSize, ModalVariant } from '@amalia/design-system/components';
import { FormikForm } from '@amalia/ext/formik';
import {
  ChartType,
  type DashboardChart,
  type DashboardChartConfiguration,
} from '@amalia/reporting/dashboards-v2/types';

import { DashboardChartConfigurationModalForm } from './DashboardChartConfigurationModalForm';
import { formInitialValues, kpiCardChartValidationSchema, simpleBarChartValidationSchema } from './validation-schemas';

const chartTypeValidationSchemaMapping = {
  [ChartType.KPI_CARD_CHART]: kpiCardChartValidationSchema,
  [ChartType.SIMPLE_BAR_CHART]: simpleBarChartValidationSchema,
} as const;

interface DashboardChartConfigurationModalProps {
  readonly dashboardChart?: DashboardChart<ChartType> | null;
  readonly onClose: () => void;
  readonly onSubmit: (dashboardChart: DashboardChartConfiguration<ChartType>) => unknown;
}

export const DashboardChartConfigurationModal = memo(function DashboardChartConfigurationModal({
  onClose,
  onSubmit,
  dashboardChart,
}: DashboardChartConfigurationModalProps) {
  const initialValues = useMemo(() => dashboardChart ?? formInitialValues, [dashboardChart]);
  const [validationSchema, setValidationSchema] = useState(chartTypeValidationSchemaMapping[initialValues.type]);

  const handleChangeSchemaAfterChartTypeChange = useCallback((newType: ChartType) => {
    setValidationSchema(chartTypeValidationSchemaMapping[newType]);
  }, []);

  return (
    <Modal
      isOpen
      size={ModalSize.XLARGE}
      variant={ModalVariant.DEFAULT}
      onClose={onClose}
    >
      <FormikForm
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <DashboardChartConfigurationModalForm
          dashboardChart={dashboardChart}
          validationSchema={validationSchema}
          onChartTypeChange={handleChangeSchemaAfterChartTypeChange}
        />
      </FormikForm>
    </Modal>
  );
});
