import { css } from '@emotion/react';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { LabelVariant, UserPrettyFormat } from '@amalia/data-capture/fields/components';
import { ColumnHelper, Table, Typography } from '@amalia/design-system/components';
import { fuzzyFilter } from '@amalia/ext/filters';
import { ActionsEnum, userProfileSubject } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { CurrencyLabel } from '@amalia/kernel/monetary/components';
import {
  SendInvitationAction,
  UserIntegrations,
  type UsersTableData,
  UserStatusBadge,
} from '@amalia/tenants/users/profile/components';
import { tenantUsersDocs, tenantUsersRoutes } from '@amalia/tenants/users/profile/shared';
import { canUserBeInvited } from '@amalia/tenants/users/profile/types';

import { UserRoleBadge, UserRoleLabel } from '../roles';

const columnHelper = new ColumnHelper<UsersTableData>();

export const useUsersTableColumns = () => {
  const ability = useAbilityContext();
  const { formatMessage } = useIntl();

  return useMemo(
    () => [
      columnHelper.display({
        id: 'name',
        header: formatMessage({ defaultMessage: 'User name' }),
        filterFn: (user, search) =>
          fuzzyFilter([user.firstName, user.lastName, user.email].filter(Boolean).join(' '), search),
        cell: ({ row: user }) => (
          <Table.Cell.Main
            link={<Table.Cell.Main.Link to={generatePath(tenantUsersRoutes.MEMBER_PAGE, { memberId: user.id })} />}
            tooltipContent={
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                `}
              >
                <FormattedMessage
                  defaultMessage="{firstName} {lastName}"
                  values={{ firstName: user.firstName, lastName: user.lastName }}
                />
                <Typography
                  variant={Typography.Variant.BODY_XSMALL_REGULAR}
                  css={(theme) => css`
                    color: ${theme.ds.colors.gray[400]};
                  `}
                >
                  <FormattedMessage
                    defaultMessage="{email}"
                    values={{ email: user.email }}
                  />
                </Typography>
              </div>
            }
          >
            <UserPrettyFormat
              firstName={user.firstName}
              lastName={user.lastName}
              pictureURL={user.pictureURL}
              subLabel={user.email}
              variant={LabelVariant.BOLD}
            />
          </Table.Cell.Main>
        ),
      }),
      columnHelper.accessor('role', {
        id: 'role',
        header: formatMessage({ defaultMessage: 'Role' }),
        size: 180,
        cell: ({ value }) => (
          <Table.Cell.Text tooltipContent={<UserRoleLabel role={value} />}>
            <UserRoleBadge
              role={value}
              size={UserRoleBadge.Size.MEDIUM}
            />
          </Table.Cell.Text>
        ),
      }),
      columnHelper.accessor('currency', {
        id: 'currency',
        header: formatMessage({ defaultMessage: 'Currency' }),
        size: 80,
        cell: ({ value }) => (
          <Table.Cell.Text tooltipContent={<CurrencyLabel currencySymbol={value} />}>{value}</Table.Cell.Text>
        ),
      }),
      columnHelper.display({
        id: 'integrations',
        header: formatMessage({ defaultMessage: 'Integrations' }),
        size: 0,
        // No fuzzy search on ids because they are so much alike that most results are irrelevant.
        filterFn: (user, search) => [user.externalId, user.hrisId].filter(Boolean).some((id) => id.includes(search)),
        cell: ({ row }) => (
          <Table.Cell.Text tooltipContent={null}>
            <UserIntegrations user={row} />
          </Table.Cell.Text>
        ),
      }),
      columnHelper.accessor('status', {
        id: 'status',
        header: formatMessage({ defaultMessage: 'Status' }),
        size: 140,
        tooltip: (
          <Table.Column.LinkTooltip
            content={formatMessage({ defaultMessage: 'Click to open documentation' })}
            to={tenantUsersDocs.STATUS_HELPDOC}
          />
        ),
        cell: ({ row }) => (
          <Table.Cell.Text tooltipContent={null}>
            <UserStatusBadge
              size={UserStatusBadge.Size.MEDIUM}
              user={row}
            />
          </Table.Cell.Text>
        ),
      }),
      columnHelper.display({
        id: Table.Cell.Actions.columnId,
        header: '',
        size: 0,
        cell: ({ row }) => {
          const canInviteUser = ability.can(ActionsEnum.invite, userProfileSubject(row));

          return (
            <Table.Cell.Actions>
              {!!canInviteUser && canUserBeInvited(row) && <SendInvitationAction user={row} />}
            </Table.Cell.Actions>
          );
        },
      }),
    ],
    [formatMessage, ability],
  );
};
