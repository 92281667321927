import { useTheme } from '@emotion/react';
import { Command } from 'cmdk';
import { type ReactElement, memo, type ReactNode } from 'react';

import { type TablerIconProps, Typography } from '@amalia/design-system/components';
import { ColorCategory } from '@amalia/design-system/meta';

type BaseCommandItemProps = {
  readonly Icon: (props: TablerIconProps) => ReactNode;
  readonly title: ReactElement;
  readonly onSelect: () => void;
};

export const BaseCommandItem = memo(function BaseCommandItem({ Icon, onSelect, title }: BaseCommandItemProps) {
  const theme = useTheme();
  return (
    <Command.Item onSelect={onSelect}>
      <Icon
        className="defaultIcon"
        color={theme.ds.colors[ColorCategory.GRAY][800]}
      />
      <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>{title}</Typography>
    </Command.Item>
  );
});
