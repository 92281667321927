import { css, type Theme } from '@emotion/react';
import { round } from 'lodash';
import { memo } from 'react';

import { formatValue } from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { Stack, Typography } from '@amalia/design-system/components';
import { TypographyVariant } from '@amalia/design-system/meta';
import { DonutChart } from '@amalia/lib-ui';

import { DashboardWidget } from '../DashboardWidget/DashboardWidget';

export const kpiLabel = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[600]};
  text-transform: uppercase;
`;

type DashboardKpiProps = {
  readonly label?: string;
  readonly color: string;
  readonly bg: string;
  readonly value?: number;
  readonly emptyMessage?: string;
  readonly loading?: boolean;
};

export const DashboardKpi = memo(function Kpi({ label, color, bg, value, emptyMessage, loading }: DashboardKpiProps) {
  const kpiValue = value ? round(value * 100) : undefined;

  return (
    <DashboardWidget>
      <DonutChart
        colors={[bg, color]}
        innerRadius={35}
        loading={loading}
        thickness={7}
        data={[
          { name: 'Remainder', value: 100 - (kpiValue || 0) },
          { name: 'Progress', value: kpiValue || 0 },
        ]}
        ReplaceAnnotation={() =>
          kpiValue ? (
            <Stack
              css={css`
                margin-left: 20px;
                height: 100%;
                width: fill-available;
              `}
            >
              <div
                css={css`
                  width: 100%;
                `}
              >
                <Typography
                  css={kpiLabel}
                  variant={TypographyVariant.BODY_BASE_REGULAR}
                >
                  {label}
                </Typography>
              </div>
              <Typography variant={TypographyVariant.HEADING_4_BOLD}>
                {formatValue(value, FormatsEnum.percent)}
              </Typography>
            </Stack>
          ) : (
            <Stack
              justify="center"
              css={css`
                margin-left: 15px;
                width: fill-available;
                height: 100%;
              `}
            >
              <div
                css={css`
                  width: 100%;
                `}
              >
                <Typography
                  variant={TypographyVariant.BODY_BASE_REGULAR}
                  css={(theme) => css`
                    color: ${theme.ds.colors.gray[600]};
                  `}
                >
                  {emptyMessage}
                </Typography>
              </div>
            </Stack>
          )
        }
      />
    </DashboardWidget>
  );
});
