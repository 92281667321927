import { type ReactNode, cloneElement, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type MergeAll } from '@amalia/ext/typescript';

import { SelectorTrigger, type SelectorTriggerProps } from '../../general/selector-trigger/SelectorTrigger';
import { TextOverflow } from '../../general/text-overflow/TextOverflow';
import { useFormLayoutContext } from '../../layout/form-layout/FormLayout.context';
import { FormLayoutSize } from '../../layout/form-layout/FormLayout.types';
import { SelectDropdown, type SelectDropdownProps } from '../../overlays/select-dropdown/SelectDropdown';
import { type SelectOptionGroup } from '../../overlays/select-dropdown/SelectDropdown.types';
import { FormField } from '../meta/form-field/FormField';
import { useFormFieldProps, type UseFormFieldPropsOptions } from '../meta/form-field/hooks/useFormFieldProps';
import { FieldSize } from '../meta/types';

import * as styles from './SimpleSelect.styles';
import { type SimpleSelectOption } from './SimpleSelect.types';

const FORM_LAYOUT_SIZE_FIELD_SIZE_MAPPING: Record<FormLayoutSize, FieldSize> = {
  [FormLayoutSize.SMALL]: FieldSize.SMALL,
  [FormLayoutSize.MEDIUM]: FieldSize.MEDIUM,
};

export type SimpleSelectProps<
  TOption extends SimpleSelectOption = SimpleSelectOption,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
> = MergeAll<
  [
    Omit<SelectorTriggerProps, 'children' | 'isActive'>,
    UseFormFieldPropsOptions,
    Pick<
      SelectDropdownProps<TOption, false, TUseOptionAsValue, false, TGroup>,
      | 'action'
      | 'hideSearchInput'
      | 'onChange'
      | 'options'
      | 'shouldCloseMenuOnSelectOption'
      | 'useOptionAsValue'
      | 'value'
    >,
    {
      /** Dropdown title. Defaults to placeholder. */
      title?: SelectDropdownProps<TOption, false, TUseOptionAsValue, false, TGroup>['title'];
      /** Placeholder. */
      placeholder?: ReactNode;
      /** Field size. */
      size?: FieldSize;
    },
  ]
>;

const SimpleSelectBase = function SimpleSelect<
  TOption extends SimpleSelectOption = SimpleSelectOption,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
>(props: SimpleSelectProps<TOption, TUseOptionAsValue, TGroup>) {
  const { size: formLayoutSize } = useFormLayoutContext() || {};

  const {
    formFieldProps,
    otherProps: {
      value = undefined,
      onChange = undefined,
      options,
      title,
      action,
      size = formLayoutSize ? FORM_LAYOUT_SIZE_FIELD_SIZE_MAPPING[formLayoutSize] : undefined,
      placeholder = null,
      shouldCloseMenuOnSelectOption = undefined,
      useOptionAsValue,
      hideSearchInput = undefined,
      as = TextOverflow,
      ...otherProps
    },
  } = useFormFieldProps<SimpleSelectProps<TOption, TUseOptionAsValue, TGroup>>(props);

  return (
    <FormField
      {...formFieldProps}
      size={size}
    >
      <SelectDropdown<TOption, false, TUseOptionAsValue, false, TGroup>
        action={action}
        hideSearchInput={hideSearchInput}
        isClearable={false}
        options={options}
        shouldCloseMenuOnSelectOption={shouldCloseMenuOnSelectOption}
        title={title || placeholder}
        useOptionAsValue={useOptionAsValue}
        value={value}
        onChange={onChange}
      >
        {({ value: selectedOption, isDropdownOpen }) => (
          <div css={styles.triggerContainer}>
            <SelectorTrigger
              {...otherProps}
              as={as}
              isActive={isDropdownOpen}
            >
              {selectedOption ? (
                <div css={styles.selectedOption}>
                  {!!selectedOption.icon &&
                    cloneElement(selectedOption.icon, {
                      width: 14,
                      height: 14,
                    })}
                  {selectedOption.valueLabel || selectedOption.label}
                </div>
              ) : (
                placeholder || <FormattedMessage defaultMessage="Select a value" />
              )}
            </SelectorTrigger>
          </div>
        )}
      </SelectDropdown>
    </FormField>
  );
};

export const SimpleSelect = memo(SimpleSelectBase) as typeof SimpleSelectBase;
