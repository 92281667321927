import { makeStyles } from '@mui/styles';

import { type AmaliaThemeType, colors } from '@amalia/ext/mui/theme';

import { styleForType, textStyleDetails } from '../../typography';

export const useDatagridStyles = makeStyles((theme: AmaliaThemeType) => ({
  customGridContainer: {
    '& .Layout-root': {
      gap: '16px',
      '& .MuiToolbar-root': {
        minHeight: 'inherit',
        height: '100%',
      },
    },
  },
  root: {
    '& thead tr th:first-child': {
      backgroundColor: colors['grey-100'],
    },
    '& tbody tr td:first-child, & thead tr th:first-child': {
      paddingLeft: '45px',
    },
    '& tbody tr td': {
      padding: '26px',
      lineHeight: '150%',
      alignItems: 'center',
      border: 'none',
    },
    backgroundColor: theme.palette.common.white,
    fontSize: textStyleDetails[styleForType.TABLE_ROW].fontSize,
    '& .MuiTableCell-body, & .MuiTableCell-root': {
      fontSize: textStyleDetails[styleForType.TABLE_ROW].fontSize,
    },
  },
  autoWidth: {
    width: 'initial',
  },
  header: {
    padding: '26px',
    backgroundColor: colors['grey-100'],
    border: 'none',
    minWidth: 0,
    whiteSpace: 'pre-line',
  },
  debugIcon: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: 0,
  },
  headerCellContent: {
    display: 'flex',
    flexDirection: 'column !important' as unknown as 'column',
    alignItems: 'start',
  },
  subTitle: {
    width: '100%',
    color: theme.palette.grey['200'],
  },
  pagingPanelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '& .Pager-pager': {
      padding: 0,
    },

    '& .MuiButton-root:not([disabled])[tabIndex="-1"]': {
      backgroundColor: colors['grey-200'],
    },
  },
  notFoundColumnHeader: {
    border: `2px solid ${colors['orange-500']}`,
    margin: theme.spacing(1.5),
    padding: theme.spacing(1),
    position: 'relative',
    minWidth: theme.spacing(16),
    borderRadius: '0.5em',
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
