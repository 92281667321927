import { useTheme } from '@emotion/react';
import { IconArchive, IconFileDescription } from '@tabler/icons-react';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { BadgeSize } from '@amalia/design-system/components';
import { ColorCategory } from '@amalia/design-system/meta';
import { useCurrentUser } from '@amalia/kernel/auth/state';
import { PlanVisibilityBadge } from '@amalia/payout-definition/plans/components';
import { usePlansList } from '@amalia/superadmin/state';

import { CommandGroup } from '../../cmdk-styled/CommandGroup';
import { CommandItem } from '../../cmdk-styled/CommandItem';
import { useActions } from '../../commands-actions/useActions';
import { PagesEnum } from '../../shortcutCommands.types';

export const ListPlansCommand = memo(function ListPlansCommand() {
  const { data: user } = useCurrentUser();
  const { data: plansList } = usePlansList(user.companyId);
  const theme = useTheme();

  const { primaryAction } = useActions(PagesEnum.LIST_PLANS);

  const plansListOptions = useMemo(
    () =>
      (plansList || [])
        .map((plan) => ({
          name: plan.name,
          id: plan.id,
          isArchived: plan.isArchived,
          isHidden: plan.isHidden,
        }))
        .filter(({ name }) => name)
        .sort((a) => (a.isArchived ? 1 : -1)),
    [plansList],
  );

  return (
    <CommandGroup
      Icon={IconFileDescription}
      title={<FormattedMessage defaultMessage="Plans list" />}
    >
      {plansListOptions.map(({ id, name, isArchived, isHidden }) => (
        <CommandItem
          key={id}
          keywords={[id, name]}
          label={name}
          value={id}
          leftElement={
            !!isArchived && (
              <IconArchive
                className="defaultIcon"
                color={theme.ds.colors[ColorCategory.GRAY][800]}
              />
            )
          }
          rightElement={
            <PlanVisibilityBadge
              isPlanHidden={isHidden}
              size={BadgeSize.SMALL}
            />
          }
          onSelect={primaryAction?.onSelect}
        />
      ))}
    </CommandGroup>
  );
});
