import { createContext, useContext } from 'react';

import { assert } from '@amalia/ext/typescript';

import { type FilterKey } from './Filters.types';

export type FiltersContextValue = {
  /** Hide filter handler. */
  onHideFilter: (filterId: FilterKey) => void;
  /** Id of the last added filters. Used to automatically open its dropdown. */
  lastFilterAddedId: FilterKey | null;
};

export const FiltersContext = createContext<FiltersContextValue | null>(null);

export const useFiltersContext = (): FiltersContextValue => {
  const context = useContext(FiltersContext);
  assert(context, 'useFiltersContext must be used within a <FiltersContext.Provider>');
  return context;
};
