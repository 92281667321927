export enum SubsetAccessEnum {
  NOTHING = 'NOTHING',
  // All my teams.
  MATCH_TEAMS = 'MATCH_TEAMS',
  // All the subordinates at the current date + myself.
  MATCH_MANAGEES = 'MATCH_MANAGEES',
  MATCH_MANAGEES_WITH_DATES = 'MATCH_MANAGEES_WITH_DATES',
  MATCH_TEAMS_AND_MANAGEES = 'MATCH_TEAMS_AND_MANAGEES',
  EVERYTHING = 'EVERYTHING',
}
