import { css } from '@emotion/react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Group, Typography } from '@amalia/design-system/components';
import { DrawingSearchRecordsEmpty } from '@amalia/lib-ui';

interface EmptyModalProps {
  readonly search: string;
}

export const EmptyModal = memo(function EmptyModal({ search }: EmptyModalProps) {
  return (
    <Group
      align="center"
      justify="center"
      css={css`
        padding: 8px 0;
      `}
    >
      <div
        css={css`
          padding: 0 24px;
        `}
      >
        {search ? (
          <Typography
            variant={Typography.Variant.HEADING_2_BOLD}
            css={(theme) => css`
              color: ${theme.ds.colors.gray[700]};
            `}
          >
            <FormattedMessage defaultMessage="No records found." />
          </Typography>
        ) : null}

        <Typography
          variant={Typography.Variant.BODY_LARGE_REGULAR}
          css={(theme) => css`
            color: ${theme.ds.colors.gray[600]};
          `}
        >
          <FormattedMessage
            defaultMessage="Search a record <strong>by name</strong> or <strong>by ID</strong>{br}then <strong>select</strong> it to add it to your statement."
            values={{
              strong: (chunks) => <Typography variant={Typography.Variant.BODY_LARGE_BOLD}>{chunks}</Typography>,
            }}
          />
        </Typography>
      </div>
      <div
        css={css`
          padding: 0 8px;
        `}
      >
        <DrawingSearchRecordsEmpty />
      </div>
    </Group>
  );
});
