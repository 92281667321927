import { css, type Theme } from '@emotion/react';
import { IconGhost } from '@tabler/icons-react';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { Button, ButtonSize, ButtonVariant, Typography } from '@amalia/design-system/components';
import { useAuthenticatedContext } from '@amalia/kernel/auth/state';
import { UserApiClient } from '@amalia/tenants/users/api-client';

const impersonateContainer = (theme: Theme) => css`
  border-top: 1px solid ${theme.ds.colors.gray[100]};
  background-color: ${theme.ds.colors.gray[50]};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 12px 14px;
`;
const purpleGhost = (theme: Theme) => css`
  color: ${theme.ds.colors.primary[300]};
`;

export const LeftBarImpersonateIndicator = memo(function LeftBarImpersonateIndicator() {
  const { authenticatedContext } = useAuthenticatedContext();

  const onResetImpersonation = useCallback(async () => {
    await UserApiClient.resetImpersonate();
    // Reload the page to reset any query cache.
    window.location.pathname = generatePath(routes.ROOT);
  }, []);

  // If current user is not impersonating, we don't display anything
  if (!authenticatedContext.impersonation) {
    return null;
  }

  return (
    <div
      css={impersonateContainer}
      data-testid="impersonate-navbar-box"
    >
      <IconGhost
        css={purpleGhost}
        size={18}
      />
      <Typography variant={Typography.Variant.BODY_XSMALL_REGULAR}>
        <FormattedMessage defaultMessage="While impersonating, you cannot perform any action." />
      </Typography>
      <Button
        size={ButtonSize.SMALL}
        variant={ButtonVariant.PRIMARY}
        onClick={onResetImpersonation}
      >
        <FormattedMessage defaultMessage="End impersonation" />
      </Button>
    </div>
  );
});
