import { css } from '@emotion/react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { Typography } from '@amalia/design-system/components';
import { type ComputedHighlightedKpi } from '@amalia/payout-definition/plans/types';

export type KpisTooltipKpiBoundsProps = {
  readonly kpi?: ComputedHighlightedKpi;
};

export const KpisTooltipKpiBounds = memo(function KpisTooltipKpiBounds({ kpi }: KpisTooltipKpiBoundsProps) {
  return kpi && kpi?.format !== FormatsEnum.percent ? (
    <Typography
      variant={Typography.Variant.BODY_XSMALL_REGULAR}
      css={(theme) => css`
        color: ${theme.ds.colors.gray[300]};
      `}
    >
      <FormattedMessage
        defaultMessage="min: {min, number} - max: {max, number}"
        values={{
          min: kpi.minimumValue,
          max: kpi.maximumValue,
        }}
      />
    </Typography>
  ) : null;
});
