import styled from '@emotion/styled';

export const DropdownFooter = styled.footer`
  position: sticky;
  z-index: 10;
  left: 0px;
  right: 0px;
  padding: 6px;

  bottom: calc(var(--body-padding) * -1);
  margin-bottom: calc(var(--body-padding) * -1);

  background-color: ${({ theme }) => theme.ds.colors.gray[0]};
`;
