import { type Statement } from '@amalia/core/types';
import { type FormatsEnum } from '@amalia/data-capture/fields/types';
import { type ComputedVariable } from '@amalia/payout-calculation/types';

export interface StatementDetailHeaderPayoutTotal {
  label: string;
  value: number | null | undefined;
  format: FormatsEnum;
}

export const getTotalFromVariable = (statement: Statement): StatementDetailHeaderPayoutTotal | undefined => {
  if (!statement.plan.useVariableInsteadOfTotal) {
    return undefined;
  }

  const totalVariable = Object.values(statement.results.definitions.variables).find(
    (v) => v.id === statement.plan.totalVariableId,
  );
  if (!totalVariable) {
    return undefined;
  }

  const totalComputedObject = statement.results.computedObjects.find(
    (co): co is ComputedVariable<number> => (co as ComputedVariable).variableMachineName === totalVariable.machineName,
  );

  return {
    label: totalVariable.name,
    value: totalComputedObject?.value,
    format: totalVariable.format,
  };
};
