import { makeStyles } from '@mui/styles';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type UserStatements } from '@amalia/core/types';
import { Tooltip } from '@amalia/design-system/components';
import { WorkflowProgressBar } from '@amalia/payout-calculation/statements/components';
import { type WorkflowMap, type WorkflowStatementState } from '@amalia/payout-collaboration/workflows/types';

import { StatementWorkflow } from './workflow/StatementWorkflow/StatementWorkflow';

const useStyles = makeStyles({
  progressBarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '300px',
    minWidth: '200px',
    margin: '0 auto',
  },
});

interface UserStatementsProgressBarProps {
  readonly userStatements: UserStatements;
  readonly workflowMap: WorkflowMap;
  readonly workflowDetails: { steps: Partial<WorkflowStatementState>[]; currentStepNumber: number };
}

export const UserStatementsProgressBar = memo(function UserStatementsProgressBar({
  userStatements,
  workflowMap,
  workflowDetails,
}: UserStatementsProgressBarProps) {
  const classes = useStyles();

  if (userStatements.lines.length > 1) {
    const nbCompleted = userStatements.lines.filter((s) => s.workflowComplete).length;

    return (
      <div className={classes.progressBarContainer}>
        <Tooltip
          content={
            <FormattedMessage
              defaultMessage="Completed workflows: {current, number}/{total, number}"
              values={{ current: nbCompleted, total: userStatements.lines.length }}
            />
          }
        >
          <WorkflowProgressBar
            currentStep={nbCompleted}
            totalSteps={userStatements.lines.length}
          />
        </Tooltip>
      </div>
    );
  }
  return (
    <StatementWorkflow
      statement={userStatements.lines[0]}
      workflowDetails={workflowDetails}
      workflowMap={workflowMap}
      additionalOptions={{
        forceShowProgressBar: true,
      }}
    />
  );
});
