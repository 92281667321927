import { css } from '@emotion/react';
import { IconLoader } from '@tabler/icons-react';
import { memo, type ElementType } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { type TablerIconProps } from '../icons/types';

import { spinKeyframes, spinReverseKeyframes } from './IconLoading.styles';

export type IconLoadingProps = MergeAll<
  [
    TablerIconProps,
    {
      /** Icon to spin, it'll default to IconLoader if not provided. */
      icon?: ElementType<TablerIconProps>;
      /** Reverse the animation because not all icons are facing the same direction. Default animation rotates clockwise. */
      reverse?: boolean;
    },
  ]
>;

export const IconLoading = memo(function IconLoading({ icon: Icon = IconLoader, reverse, ...props }: IconLoadingProps) {
  return (
    <Icon
      {...props}
      css={(theme) => css`
        animation: ${reverse ? spinReverseKeyframes : spinKeyframes} ${theme.ds.transitions.default.durationMs * 5}ms
          infinite ease-in-out;
      `}
    />
  );
});
