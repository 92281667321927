import { useIsFetching, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { type FiltersType, type PaginatedQuery } from '@amalia/core/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { CustomObjectsApiClient } from '@amalia/data-capture/records/api-client';
import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';

import { CUSTOM_OBJECTS_KEYS } from './queries.keys';

export const useCustomObjectsPage = ({
  objectDefinitionName,
  pagination,
  filters,
  overwrite = false,
  isSyncRunning,
}: {
  objectDefinitionName: CustomObjectDefinition['machineName'];
  pagination: PaginatedQuery;
  filters?: FiltersType[] | null;
  overwrite?: boolean;
  isSyncRunning?: boolean;
}) =>
  useQuery({
    enabled: !!objectDefinitionName && !!pagination,
    queryKey: [CUSTOM_OBJECTS_KEYS.CUSTOM_OBJECTS_PAGE, objectDefinitionName, pagination, filters, overwrite],
    queryFn: async () => CustomObjectsApiClient.getObjects(objectDefinitionName, pagination, filters, overwrite),
    refetchInterval: isSyncRunning ? 5000 : false,
  });

export const useIsCustomObjectsPageLoading = (objectDefinitionName: CustomObjectDefinition['machineName']) =>
  useIsFetching({ queryKey: [CUSTOM_OBJECTS_KEYS.CUSTOM_OBJECTS_PAGE, objectDefinitionName] }) > 0;

export const usePurgeCustomObjects = (objectDefinitionName: CustomObjectDefinition['machineName']) => {
  const queryClient = useQueryClient();

  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: [CUSTOM_OBJECTS_KEYS.CUSTOM_OBJECTS_PAGE, objectDefinitionName, 'purge'],
    mutationFn: CustomObjectsApiClient.purgeObjectsByDefinition,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CUSTOM_OBJECTS_KEYS.CUSTOM_OBJECTS_PAGE, objectDefinitionName],
      });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const usePatchCustomObject = (objectDefinitionName: CustomObjectDefinition['machineName']) => {
  const queryClient = useQueryClient();

  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: [CUSTOM_OBJECTS_KEYS.CUSTOM_OBJECTS_PAGE, objectDefinitionName, 'patch'],
    mutationFn: CustomObjectsApiClient.patchCustomObject,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CUSTOM_OBJECTS_KEYS.CUSTOM_OBJECTS_PAGE, objectDefinitionName],
      });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useClearCustomObject = (objectDefinitionName: CustomObjectDefinition['machineName']) => {
  const queryClient = useQueryClient();

  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: [CUSTOM_OBJECTS_KEYS.CUSTOM_OBJECTS_PAGE, objectDefinitionName, 'clear'],
    mutationFn: CustomObjectsApiClient.clearCustomObject,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CUSTOM_OBJECTS_KEYS.CUSTOM_OBJECTS_PAGE, objectDefinitionName],
      });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useBulkOverwrite = (objectDefinitionName: CustomObjectDefinition['machineName']) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [CUSTOM_OBJECTS_KEYS.CUSTOM_OBJECTS_PAGE, objectDefinitionName, 'bulk-overwrite'],
    mutationFn: (args: Parameters<(typeof CustomObjectsApiClient)['bulkOverwrite']>) =>
      CustomObjectsApiClient.bulkOverwrite(...args),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CUSTOM_OBJECTS_KEYS.CUSTOM_OBJECTS_PAGE, objectDefinitionName],
      });
    },
  });
};
