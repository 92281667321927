import { defineMessages } from 'react-intl';

import { FormatsEnum } from '@amalia/data-capture/fields/types';

type SelectorFormatMessages = Exclude<FormatsEnum, FormatsEnum.boolean | FormatsEnum.table>;

export const messages = defineMessages<SelectorFormatMessages>({
  [FormatsEnum.currency]: {
    defaultMessage: 'Enter a currency value',
  },
  [FormatsEnum.date]: {
    defaultMessage: 'Enter a date value',
  },
  [FormatsEnum['date-time']]: {
    defaultMessage: 'Enter a date-time value',
  },
  [FormatsEnum.number]: {
    defaultMessage: 'Enter a number value',
  },
  [FormatsEnum.percent]: {
    defaultMessage: 'Enter a percentage value',
  },
  [FormatsEnum.text]: {
    defaultMessage: 'Enter a text value',
  },
} as const);
