import { css } from '@emotion/react';
import { memo } from 'react';

import { type ChangelogEntry } from '@amalia/amalia-meta/changelog/shared';
import { Group } from '@amalia/design-system/components';

import { ChangelogEntryReleaseTypeBadge } from './changelog-entry-release-type-badge/ChangelogEntryReleaseTypeBadge';
import { ChangelogEntryRoleBadge } from './changelog-entry-role-badge/ChangelogEntryRoleBadge';
import { ChangelogEntryTopicBadge } from './changelog-entry-topic-badge/ChangelogEntryTopicBadge';

export type ChangelogEntryBadgesProps = {
  readonly changelogEntry: Pick<ChangelogEntry, 'releaseType' | 'roles' | 'topic'>;
};

export const ChangelogEntryBadges = memo(function ChangelogEntryBadges({ changelogEntry }: ChangelogEntryBadgesProps) {
  return (
    <Group
      wrap
      align="center"
      gap={8}
      css={css`
        &:empty {
          display: none;
        }
      `}
    >
      {!!changelogEntry.releaseType && <ChangelogEntryReleaseTypeBadge releaseType={changelogEntry.releaseType} />}

      {changelogEntry.roles.map((role) => (
        <ChangelogEntryRoleBadge
          key={role}
          role={role}
        />
      ))}

      {!!changelogEntry.topic && <ChangelogEntryTopicBadge topic={changelogEntry.topic} />}
    </Group>
  );
});
