import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo, type ReactNode } from 'react';

import { type AmaliaThemeType } from '@amalia/ext/mui/theme';

interface TableProps {
  readonly className?: string;
  readonly innerRef?: string;
  readonly children: ReactNode;
}

const useStyle = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    // Compensating top and bottom for border spacing.
    margin: '-10px 0',
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
    width: '100%',

    '& > thead > tr': {
      backgroundColor: theme.palette.grey[100],

      '& > th': {
        borderTopStyle: 'solid',
        borderBottomStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.palette.divider,
        padding: '18px',
        '&:first-of-type': {
          borderTopLeftRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
          borderLeftStyle: 'solid',
        },
        '&:last-of-type': {
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius,
          borderRightStyle: 'solid',
        },
      },
    },

    '& > tbody > tr': {
      backgroundColor: theme.palette.common.white,
      '& > td': {
        padding: '20px',
        '&:first-of-type': {
          borderTopLeftRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
        },
        '&:last-of-type': {
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius,
        },
      },
    },
  },
}));

export const Table = memo(function Table({ children, className, innerRef, ...restProps }: TableProps) {
  const classes = useStyle();
  return (
    <div
      ref={innerRef}
      className={clsx(classes.root, className)}
      {...restProps}
    >
      <table className={classes.table}>{children}</table>
    </div>
  );
});
