import { type CSSProperties } from 'react';

import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { type Property } from '@amalia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { AMALIA_OBJECTS_HIGHLIGHT_COLOR } from '@amalia/ext/mui/theme';
import { type Relationship } from '@amalia/payout-definition/plans/types';

import { IFormulaNode, FormulaNodeEnum } from './IFormulaNode';

export class PropertyFormulaNode extends IFormulaNode<Property> {
  public constructor(
    partialFormula: string,
    startIndex: number,
    nodeObject: Property,
    objectDefinition?: CustomObjectDefinition,
    relationship?: Relationship,
  ) {
    super(partialFormula, startIndex, FormulaNodeEnum.OBJECT_FIELD, nodeObject, objectDefinition, relationship);
  }

  public getStyles(): CSSProperties {
    return { backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.object };
  }

  public getDesignerPath(): { type: TokenType; id: string; definitionMachinename?: string } {
    const nodeObject = this.nodeObject;
    const nodeId = nodeObject.machineName;

    return {
      type: TokenType.PROPERTY,
      id: nodeId,
      definitionMachinename: this.objectDefinition?.machineName,
    };
  }

  public getLabel(): string {
    return this.nodeObject.name.trim();
  }
}
