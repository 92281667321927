import styled from '@emotion/styled';
import { Command } from 'cmdk';

import { ColorCategory, ShadowVariant } from '@amalia/design-system/meta';

export const CommandDialog = styled(Command.Dialog)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  user-select: none;
  max-width: 640px;
  height: 100%;
  max-height: 490px;
  width: 100%;
  border-radius: ${({ theme }) => theme.ds.borderRadiuses.squared};
  box-shadow: ${({ theme }) => theme.ds.shadows[ShadowVariant.HARD]};
  border: 0px solid ${({ theme }) => theme.ds.colors[ColorCategory.GRAY][100]};
  border-color: ${({ theme }) => theme.ds.colors[ColorCategory.GRAY][200]};
  backdrop-filter: blur(8px);
  background-color: ${({ theme }) => theme.ds.colors[ColorCategory.GRAY][0]}90;
  outline: none;
  // max here because of the Avatar component and the panel should always be on top of everything

  .defaultIcon {
    width: 18px;
    height: 18px;
  }

  & [cmdk-input] {
    font-family: ${({ theme }) => theme.ds.typographies.bodyBaseMedium.fontFamily};
    font-size: ${({ theme }) => theme.ds.typographies.bodyLargeMedium.fontSize};
    font-weight: 400;

    border: none;
    width: 100%;
    padding: 12px 16px 12px 28px;
    outline: none;
    background: transparent;
    border-bottom: ${({ theme }) => `1px solid ${theme.ds.colors[ColorCategory.GRAY][100]}`};
  }

  & [cmdk-item] {
    content-visibility: auto;
    cursor: pointer;
    height: 40px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 12px;

    user-select: none;
    will-change: background, color;
    transition: all 150ms ease;
    transition-property: none;
    border-radius: ${({ theme }) => theme.ds.borderRadiuses.squared};
  }

  & [cmdk-item][data-selected='true'] {
    background-color: ${({ theme }) => theme.ds.colors.primary[50]};
  }

  & [cmdk-item]:first-child {
    margin-top: 8px;
  }

  & [cmdk-item] + [cmdk-item] {
    margin-top: 4px;
  }

  & [cmdk-list] {
    height: 406px;
    padding: 16px;
    overflow: auto;
    overscroll-behavior: contain;
    scroll-padding-block-end: 40px;
    transition: 100ms ease;
    transition-property: height;
    padding-bottom: 40px;
  }

  & [cmdk-separator] {
    height: 1px;
    width: 100%;
    background: undefined;

    margin: 4px 0;
  }

  & *:not([hidden]) + [cmdk-group] {
    margin-top: 8px;
  }

  & [cmdk-group] {
    position: static;
  }

  & [cmdk-group-heading] {
    user-select: none;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  & [cmdk-group-heading] > span {
    color: ${({ theme }) => theme.ds.colors[ColorCategory.GRAY][800]};
    font-weight: 700;
    letter-spacing: 0.07px;
  }

  & [cmdk-dialog] {
    z-index: undefined;

    position: fixed;
    left: 50%;
    top: undefined;

    transform: translateX(-50%);
  }

  & [cmdk-dialog] [cmdk] {
    width: 640px;
    transform-origin: center center;
    animation: dialogIn undefined forwards;
  }

  & [cmdk-dialog][data-state='closed'] [cmdk] {
    animation: dialogOut undefined forwards;
  }

  & [cmdk-empty] {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    white-space: pre-wrap;
    color: undefined;
  }

  @media (max-width: 640px) {
    & [cmdk-input] {
      font-size: 16px;
    }
  }
`;
