import { ClassNames } from '@emotion/react';
import clsx from 'clsx';
import { type ReactNode, memo, useCallback } from 'react';

import { useBoolState } from '@amalia/ext/react/hooks';
import { type MergeAll } from '@amalia/ext/typescript';

import { type DatePickerValueProps } from '../../../../data-input/date-picker/DatePicker.types';
import { useMapDatePickerProps } from '../../../../data-input/date-picker/hooks/useMapDatePickerProps';
import { DatePickerBase, type DatePickerBaseProps } from '../../../../data-input/date-picker-base/DatePickerBase';
import { type RowData } from '../../Table.types';

import { CellDatePickerAnchor } from './cell-date-picker-anchor/CellDatePickerAnchor';

export type CellDatePickerProps<
  TWithRange extends boolean | undefined = undefined,
  TRowData extends RowData = RowData,
> = MergeAll<
  [
    DatePickerBaseProps<TWithRange>,
    DatePickerValueProps<TWithRange>,
    {
      readonly row: TRowData;
      readonly error?: ReactNode;
      readonly warning?: ReactNode;
      readonly onChange?: (date: Parameters<DatePickerBaseProps<TWithRange>['onChange']>[0], row: TRowData) => void;
    },
  ]
>;

const CellDatePickerBase = function CellDatePicker<
  TWithRange extends boolean | undefined = undefined,
  TRowData extends RowData = RowData,
>({
  value,
  row,
  placeholder,
  endDate,
  startDate,
  selectsRange,
  error,
  warning,
  onCalendarOpen,
  onCalendarClose,
  onChange,
  ...props
}: CellDatePickerProps<TWithRange, TRowData>) {
  const { isCalendarOpen, setCalendarOpenFalse, setCalendarOpenTrue } = useBoolState(false, 'calendarOpen');

  const mappedDatePickerProps = useMapDatePickerProps<TWithRange>({
    value,
    placeholder,
    endDate,
    startDate,
  });

  const handleCalendarOpen = useCallback(() => {
    setCalendarOpenTrue();
    onCalendarOpen?.();
  }, [setCalendarOpenTrue, onCalendarOpen]);

  const handleCalendarClose = useCallback(() => {
    setCalendarOpenFalse();
    onCalendarClose?.();
  }, [setCalendarOpenFalse, onCalendarClose]);

  const handleChange: DatePickerBaseProps<TWithRange>['onChange'] = useCallback(
    (date) => onChange?.(date, row),
    [onChange, row],
  );

  const handleClear = useCallback(
    () =>
      onChange?.(
        (selectsRange ? [null, null] : null) as Parameters<DatePickerBaseProps<TWithRange>['onChange']>[0],
        row,
      ),
    [onChange, row, selectsRange],
  );

  return (
    <ClassNames>
      {({ css }) => (
        <DatePickerBase<TWithRange>
          {...props}
          {...mappedDatePickerProps}
          selectsRange={selectsRange}
          customInput={
            <CellDatePickerAnchor
              error={error}
              isCalendarOpen={isCalendarOpen}
              warning={warning}
              onClear={handleClear}
            />
          }
          wrapperClassName={clsx(
            props.wrapperClassName,
            css`
              &.react-datepicker-wrapper {
                display: block;
              }
            `,
          )}
          onCalendarClose={handleCalendarClose}
          onCalendarOpen={handleCalendarOpen}
          onChange={handleChange}
        />
      )}
    </ClassNames>
  );
};

export const CellDatePicker = memo(CellDatePickerBase) as typeof CellDatePickerBase;
