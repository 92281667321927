import { css } from '@emotion/react';
import { memo } from 'react';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { Link } from '@amalia/ext/react-router-dom';
import { IconAmaliaIoLogoWithText, IconAmaliaIoLogoWithTextChristmas } from '@amalia/frontend/ui-icons';
import { NotificationsWidget } from '@amalia/tenants/users/notifications/components';

const month = new Date().getMonth();

export const LeftBarHeader = memo(function LeftBarHeader() {
  const Icon = month === 11 ? IconAmaliaIoLogoWithTextChristmas : IconAmaliaIoLogoWithText;

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      {/* Christmas logo does not have the same viewbox as the regular logo, so we keep the same box size and let the logo take the space it needs vertically. */}
      <Link
        to={generatePath(routes.ROOT)}
        css={css`
          display: flex;
          height: 17px;
          width: 90px;
          position: relative;
        `}
      >
        <Icon
          css={css`
            position: absolute;
            left: 0;
            bottom: 0;
            height: auto;
            width: 100%;
          `}
        />
      </Link>

      <NotificationsWidget />
    </div>
  );
});
