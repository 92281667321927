import { DataConnectorTypes, DataConnectorCategories } from '@amalia/data-capture/connectors/types';

export interface ConnectorDefinition {
  name: string;
  connectorType: DataConnectorTypes;
  isDisabled?: boolean;
  isOAuth2?: boolean;
  maxFilters: number;
  category: DataConnectorCategories;
  isRealTimeSynchronizationSupported?: boolean;
}

const buildUserConnector = (name: string, connectorType: DataConnectorTypes): ConnectorDefinition => ({
  name,
  connectorType,
  category: DataConnectorCategories.USER,
  maxFilters: Infinity,
});

const USER_CONNECTORS = {
  [DataConnectorTypes.ADPWORKFORCENOW]: buildUserConnector('ADP Workforce Now', DataConnectorTypes.ADPWORKFORCENOW),
  [DataConnectorTypes.BAMBOOHR]: buildUserConnector('BambooHR', DataConnectorTypes.BAMBOOHR),
  [DataConnectorTypes.SAGEHR]: buildUserConnector('Sage HR', DataConnectorTypes.SAGEHR),
  [DataConnectorTypes.SAPSUCCESSFACTORS]: buildUserConnector(
    'SAP SuccessFactors',
    DataConnectorTypes.SAPSUCCESSFACTORS,
  ),
  [DataConnectorTypes.WORKDAY]: buildUserConnector('Workday', DataConnectorTypes.WORKDAY),
  [DataConnectorTypes.PERSONIO]: buildUserConnector('Personio', DataConnectorTypes.PERSONIO),
} as const;

export const CONNECTORS: Record<string, ConnectorDefinition> = {
  [DataConnectorTypes.SALESFORCE]: {
    name: 'Salesforce',
    connectorType: DataConnectorTypes.SALESFORCE,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
    isRealTimeSynchronizationSupported: true,
  },
  [DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT]: {
    name: 'Salesforce',
    connectorType: DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
    isRealTimeSynchronizationSupported: false,
  },
  [DataConnectorTypes.SALESFORCE_THIRD_ACCOUNT]: {
    name: 'Salesforce',
    connectorType: DataConnectorTypes.SALESFORCE_THIRD_ACCOUNT,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
    isRealTimeSynchronizationSupported: false,
  },
  [DataConnectorTypes.HUBSPOT]: {
    name: 'HubSpot',
    connectorType: DataConnectorTypes.HUBSPOT,
    category: DataConnectorCategories.DATA,
    // it's 3 but we add another one by default
    maxFilters: 2,
  },
  [DataConnectorTypes.NETSUITE]: {
    name: 'NetSuite',
    connectorType: DataConnectorTypes.NETSUITE,
    category: DataConnectorCategories.DATA,
    isOAuth2: true,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.GOOGLESHEET]: {
    name: 'Google Sheets',
    connectorType: DataConnectorTypes.GOOGLESHEET,
    category: DataConnectorCategories.DATA,
    maxFilters: 0,
  },
  [DataConnectorTypes.REDSHIFT]: {
    name: 'Redshift',
    connectorType: DataConnectorTypes.REDSHIFT,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.BIGQUERY]: {
    name: 'BigQuery',
    connectorType: DataConnectorTypes.BIGQUERY,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.SNOWFLAKE]: {
    name: 'Snowflake',
    connectorType: DataConnectorTypes.SNOWFLAKE,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.POSTGRESQL]: {
    name: 'PostgreSQL',
    connectorType: DataConnectorTypes.POSTGRESQL,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.POWERBI]: {
    name: 'Power BI',
    connectorType: DataConnectorTypes.POWERBI,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.MSDYNAMICS]: {
    name: 'Microsoft Dynamics',
    connectorType: DataConnectorTypes.MSDYNAMICS,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.SQLSERVER]: {
    name: 'SQL Server',
    connectorType: DataConnectorTypes.SQLSERVER,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.MYSQL]: {
    name: 'MySQL',
    connectorType: DataConnectorTypes.MYSQL,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.ZOHO]: {
    name: 'Zoho',
    connectorType: DataConnectorTypes.ZOHO,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  ...USER_CONNECTORS,
};
