import styled from '@emotion/styled';

import { FormLayoutSize } from '../FormLayout.types';

export const FormLayoutRow = styled.div`
  display: flex;
  align-items: flex-start;

  > * {
    flex: 1;
  }

  transition: ${({ theme }) => theme.ds.transitions.default('gap')};

  [data-form-layout-size=${FormLayoutSize.SMALL}] & {
    gap: 16px;
  }

  [data-form-layout-size=${FormLayoutSize.MEDIUM}] & {
    gap: 24px;
  }
`;
