import { type Statement } from '@amalia/core/types';
import { type UserComputed } from '@amalia/tenants/users/types';

import { type CommentThreadMessage, type MessageContent } from './message';

export type CreateStatementThread = {
  statementId: string;
  computedScope?: StatementThreadScope;
};

export type AddMessageInStatementThread = {
  statementId: string;
  statementThreadId: string;
  message: MessageContent[];
};

export type ReviewStatementThread = {
  statementId: string;
  statementThreadId: string;
  isReviewed: boolean;
};

export type DeleteStatementThreadComment = {
  statementId: string;
  statementThreadId: string;
  messageId: string;
};

export interface StatementThread {
  id: string;

  createdAt: string;

  thread: CommentThread;

  // Statement id.
  statementId: string;
  statement?: Statement;

  // Scope.
  // Careful, it's not the same as "statementThread.scope".
  computedScope?: StatementThreadScope;
}

export interface CommentThread {
  id: string | null;

  messages?: CommentThreadMessage[];

  author?: UserComputed | { id: string };

  isReviewed?: boolean;
}

export enum StatementThreadScopesType {
  KPI = 'kpi',
  OBJECT = 'object',
}

export enum StatementThreadQueryStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  BOTH = 'BOTH',
}

export type StatementThreadScope = {
  type: StatementThreadScopesType;
  /* If type === KPI,
      id = variable.id, fulfill periodStartDate and periodEndDate
    If type === object
      id = externalId (Id of the row), field is the column, definitionId the customObjectDefinitionId, fulfill periodStartDate and periodEndDate
  */
  id?: string;
  /** Rule Id of the Dataset */
  ruleId?: string;
  periodStartDate?: Date;
  periodEndDate?: Date;
} & (DatasetThreadScope | VariableThreadScope);

export type VariableThreadScope = {
  type: StatementThreadScopesType.KPI;
};

export type DatasetThreadScope = {
  type: StatementThreadScopesType.OBJECT;
  /** Name of the Row*/
  name: string;
  /** Column of the dataset */
  field: string;
  /** Custom Object Definition Id */
  definitionId: string;
  /** Dataset Machine Name */
  datasetMachineName?: string;
};
