import { defineMessages } from 'react-intl';

import { FormulaBuilderLogicalOperatorType } from '@amalia/amalia-lang/formula/types';

export const andOrMessages = defineMessages<FormulaBuilderLogicalOperatorType>({
  [FormulaBuilderLogicalOperatorType.AND]: {
    defaultMessage: 'AND',
  },

  [FormulaBuilderLogicalOperatorType.OR]: {
    defaultMessage: 'OR',
  },
} as const);

export const andOrTooltipMessages = defineMessages<FormulaBuilderLogicalOperatorType>({
  [FormulaBuilderLogicalOperatorType.AND]: {
    defaultMessage: 'Click to switch all highlighted AND to OR',
  },

  [FormulaBuilderLogicalOperatorType.OR]: {
    defaultMessage: 'Click to switch all highlighted OR to AND',
  },
} as const);
