import { css } from '@emotion/react';
import { IconTrash } from '@tabler/icons-react';
import { useField } from 'formik';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type Variable } from '@amalia/amalia-lang/tokens/types';
import {
  FormLayout,
  FormikInput,
  FormikSelect,
  FormikTextArea,
  Group,
  IconButton,
  type SelectOption,
} from '@amalia/design-system/components';

import { FormikPlanAwardIconSelect } from '../../award-icon-select/formik-award-icon-select/FormikPlanAwardIconSelect';
import { type PlanAwardFormValue } from '../PlanAwardsForm.types';

type VariableOption = SelectOption<Variable['id']>;

export type PlanAwardFormFieldsProps = {
  /** Variables to choose from. */
  readonly variables: Variable[];
  /** Field name, like `'badgeConfigurations.0'`. */
  readonly name: string;
  /** Are all fields disabled. */
  readonly disabled?: boolean;
};

export const PlanAwardFormFields = memo(function PlanAwardFormFields({
  variables,
  name,
  disabled = false,
}: PlanAwardFormFieldsProps) {
  const { formatMessage } = useIntl();

  const [, , { setValue: setDeletedAt }] = useField<PlanAwardFormValue['deletedAt']>({
    name: `${name}.deletedAt`,
  });

  const handleDelete = useCallback(() => {
    setDeletedAt(new Date()).catch(() => {});
  }, [setDeletedAt]);

  const variableOptions: VariableOption[] = useMemo(
    () =>
      variables.map((variable) => ({
        value: variable.id,
        label: variable.name,
      })),
    [variables],
  );

  return (
    <FormLayout>
      <FormLayout.Group>
        <Group
          align="center"
          gap={32}
        >
          <FormikPlanAwardIconSelect
            disabled={disabled}
            name={`${name}.icon`}
          />

          <FormLayout.Row>
            <FormikInput
              required
              disabled={disabled}
              label={<FormattedMessage defaultMessage="Badge name" />}
              name={`${name}.name`}
            />

            <FormikSelect
              required
              disabled={disabled}
              id={`${name}.variableId`}
              isClearable={false}
              label={<FormattedMessage defaultMessage="Winning condition" />}
              name={`${name}.variableId`}
              options={variableOptions}
            />

            <div
              css={css`
                align-self: flex-end;
                flex: none;
                position: relative;
                bottom: 4px;
              `}
            >
              <IconButton
                disabled={disabled}
                icon={<IconTrash />}
                label={formatMessage({ defaultMessage: 'Remove this badge' })}
                variant={IconButton.Variant.DANGER}
                onClick={handleDelete}
              />
            </div>
          </FormLayout.Row>
        </Group>

        <div
          css={css`
            margin-left: 88px;
          `}
        >
          <FormikTextArea
            disabled={disabled}
            label={<FormattedMessage defaultMessage="Badge description" />}
            name={`${name}.description`}
          />
        </div>
      </FormLayout.Group>
    </FormLayout>
  );
});
