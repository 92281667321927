import { type ComponentPropsWithoutRef, type ForwardedRef, forwardRef, memo } from 'react';

import { useMergedRef, useTextOverflows } from '@amalia/ext/react/hooks';
import { type MergeAll } from '@amalia/ext/typescript';

import { Tooltip, type TooltipProps } from '../../overlays/tooltip/Tooltip';

import * as styles from './TextOverflow.styles';

export type TextOverflowProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      tooltipContent?: TooltipProps['content'];
      tooltipPlacement?: TooltipProps['placement'];
    },
  ]
>;

const TextOverflowForwardRef = forwardRef(function TextOverflow(
  { children, tooltipContent = children, tooltipPlacement, ...props }: TextOverflowProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { ref: overflowRef, doesTextOverflow } = useTextOverflows([children]);

  const mergedRef = useMergedRef(ref, overflowRef);

  return (
    <Tooltip
      content={doesTextOverflow && tooltipContent ? <div css={styles.tooltipContent}>{tooltipContent}</div> : ''}
      placement={tooltipPlacement}
    >
      <div
        {...props}
        ref={mergedRef}
        css={styles.textOverflow}
      >
        {children}
      </div>
    </Tooltip>
  );
});

export const TextOverflow = memo(TextOverflowForwardRef);
