import { css, type Theme } from '@emotion/react';

import { LEFT_BAR_WIDTH } from '../Layout.constants';

export const leftBar = (theme: Theme) => css`
  width: ${LEFT_BAR_WIDTH}px;
  background-color: ${theme.ds.colors.gray[0]};
  border-right: 1px solid ${theme.ds.colors.gray[100]};
  box-shadow: ${theme.ds.shadows.soft};

  overflow: auto;

  display: flex;
  flex-direction: column;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

export const header = (theme: Theme) => css`
  padding: 26px 6px 12px 16px;
  flex: none;

  position: sticky;
  top: 0;
  background-color: ${theme.ds.colors.gray[0]};
`;

export const menu = css`
  padding: 12px;

  flex-grow: 1;
`;

export const footer = (theme: Theme) => css`
  position: sticky;
  bottom: 0;

  flex: none;

  background-color: ${theme.ds.colors.gray[0]};
`;
