import { type Theme } from '@emotion/react';
import { IconPlayerRecord, IconSquareRoundedCheck, IconSquareRoundedX } from '@tabler/icons-react';
import { memo } from 'react';

import { FormulaBuilderBooleanOperatorNoArgs } from '@amalia/amalia-lang/formula/types';
import { IconNot } from '@amalia/design-system/components';

type BooleanOperatorNoArgsToIconProps = {
  readonly operator: FormulaBuilderBooleanOperatorNoArgs;
  readonly theme: Theme;
};

export const BooleanOperatorNoArgsToIcon = memo(function BooleanOperatorNoArgsToIcon({
  operator,
  theme,
}: BooleanOperatorNoArgsToIconProps) {
  switch (operator) {
    case FormulaBuilderBooleanOperatorNoArgs.IS_TRUE:
      return (
        <IconSquareRoundedCheck
          color={theme.ds.hues.green['500']}
          data-testid="icon-square-rounded-check"
          size={16}
        />
      );
    case FormulaBuilderBooleanOperatorNoArgs.IS_FALSE:
      return (
        <IconSquareRoundedX
          color={theme.ds.hues.red['500']}
          data-testid="icon-square-rounded-x"
          size={16}
        />
      );
    case FormulaBuilderBooleanOperatorNoArgs.IS_BLANK:
      return (
        <IconPlayerRecord
          data-testid="icon-player-record"
          size={16}
        />
      );
    case FormulaBuilderBooleanOperatorNoArgs.IS_NOT_BLANK:
      return (
        <IconNot
          data-testid="icon-not-player-record"
          icon={IconPlayerRecord}
          size={16}
        />
      );
    default:
      return null;
  }
});
