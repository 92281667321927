import { type ColumnChooser } from '@devexpress/dx-react-grid';
import { IconEyeOff } from '@tabler/icons-react';
import { useIntl } from 'react-intl';

import { IconButton } from '@amalia/design-system/components';

export const DataGridShowColumnsButton = function DataGridShowColumnsButton({
  buttonRef,
  onToggle,
}: ColumnChooser.ToggleButtonProps) {
  const { formatMessage } = useIntl();
  return (
    <IconButton
      ref={buttonRef}
      icon={<IconEyeOff />}
      label={formatMessage({ defaultMessage: 'Show column chooser' })}
      onClick={onToggle}
    />
  );
};
