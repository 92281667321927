import { type RootState } from '../types';

export const selectLastUserStatementsFetchParams = (state: RootState) => state.userStatements.lastparams;

export const selectUserStatementsList = (state: RootState) => state.userStatements.userStatementsList;

export const selectUserStatementsListCurrentPage = (state: RootState) => state.userStatements.currentPage;

/**
 * @deprecated
 * @param state
 */
export const selectUserStatementsIsLoading = (state: RootState) => !!state.userStatements.isLoading;

export const selectUserStatementsListSelectedOptions = (state: RootState) => state.userStatements.listSelectedOptions;
