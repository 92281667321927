import { uniqueId } from 'lodash';
import { type FunctionNode } from 'mathjs';

import { commonMathJs } from '@amalia/amalia-lang/amalia-mathjs';
import { type FormulaEditorToken } from '@amalia/amalia-lang/formula/components';
import { TokenType } from '@amalia/amalia-lang/tokens/types';

type SuggestionFormulaParsingResult = { function: string; argIndex: number; selectedFilterMachineName?: string };

/**
 * Suggestion parser. When we are in suggestion mode, we have a formula which contains `@` to trigger the suggestion.
 * This parser will traverse the formula to search suggestion context:
 *   - Which function is performed the suggestion.
 *   - Which argument is currently being edited.
 *   - If we have a filter argument previous to the suggestion arg, we want to keep it to filter the items.
 *
 *   Example in `SUM(filter.closedByRepInPeriod, @)`, parsing result will be:
 *   `{ function: 'SUM', argIndex: 1, selectedFilterMachineName: 'filter.closedByRepInPeriod' }`.
 */
export class SuggestionFormulaParser {
  private readonly filterFormulas: string[];

  private readonly suggestionPlaceholder: string;

  private readonly mathjsFormula: string;

  public constructor(
    private readonly formula: string,
    private readonly items: FormulaEditorToken[],
  ) {
    this.suggestionPlaceholder = uniqueId('arobase_');
    this.filterFormulas = this.items.filter((i) => i.type === TokenType.FILTER).map((i) => i.formula);
    this.mathjsFormula = this.buildValidMathjsFormula();
  }

  /**
   * Search function and argument index of the current cursor position.
   * @returns {SuggestionFormulaParsingResult} The function name and the argument index.
   */
  public parse(): SuggestionFormulaParsingResult | undefined {
    const suggestionCharacterIndex = this.mathjsFormula.indexOf(this.suggestionPlaceholder);

    let functionArgAtSuggestionPosition: SuggestionFormulaParsingResult | undefined;
    try {
      const node = commonMathJs.parse(this.mathjsFormula);
      node.traverse((node) => {
        // If we already found the function argument, or if the node is not a function,
        // we don't need to continue the iteration.
        if (functionArgAtSuggestionPosition || node.type !== 'FunctionNode') {
          return;
        }

        const functionNode = node as FunctionNode;

        const suggestionArgIndex = this.findArgIndex(functionNode, suggestionCharacterIndex);

        if (suggestionArgIndex > -1) {
          functionArgAtSuggestionPosition = {
            function: functionNode.fn.name,
            argIndex: suggestionArgIndex,
            selectedFilterMachineName: this.findSelectedFilterInFunctionNode(functionNode, suggestionArgIndex),
          };
        }
      });
    } catch (e) {
      // Formula is invalid, or unknown error, so we didn't find function argument.
      return undefined;
    }

    return functionArgAtSuggestionPosition;
  }

  /**
   * Search for the arg index among function node args that contains the suggestion character.
   * @param node
   * @param suggestionCharacterIndex
   * @private
   */
  private findArgIndex(node: FunctionNode, suggestionCharacterIndex: number) {
    const args = node.args;
    return args.findIndex((argNode) => {
      const argNodeString = argNode.toString();
      const argNodeIndexStart = this.mathjsFormula.indexOf(argNodeString);
      const argNodeIndexEnd = argNodeIndexStart + argNodeString.length;
      return argNodeIndexStart <= suggestionCharacterIndex && suggestionCharacterIndex <= argNodeIndexEnd;
    });
  }

  /**
   * Search for the filter argument that is previous to the suggestion argument.
   * example in `SUM(filter.closedByRepInPeriod, @)`, we have a filter argument `filter.closedByRepInPeriod` as `SUM` function first arg.
   * @param node
   * @param suggestionArgIndex
   * @private
   */
  private findSelectedFilterInFunctionNode(node: FunctionNode, suggestionArgIndex: number) {
    const args = node.args;
    const filterArg = args.find((argNode, index) => {
      const argNodeString = argNode.toString();
      return this.filterFormulas.includes(argNodeString) && index < suggestionArgIndex;
    });

    return filterArg?.toString();
  }

  /**
   * mathjs cannot parse formulas with empty arguments.
   * This function will replace empty arguments with placeholders.
   */
  private buildValidMathjsFormula() {
    const formula = this.formula
      // @ is not a valid character for mathjs, so we replace it.
      .replace('@', this.suggestionPlaceholder);
    // mathjs cannot parse formulas with empty arguments.
    return (
      formula
        // Replace empty arguments with placeholders (uuids to ensure it's unique).
        .replace(/\s*,\s*(?=[,)])/gu, `,${uniqueId('arg_')}`)
        .replace(/\(\s*(?=,)/gu, ')')
    );
  }
}
