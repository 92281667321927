import { IconExternalLink, IconSearch } from '@tabler/icons-react';
import { memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import {
  formatDate,
  formatTotal,
  type Payment,
  PaymentCategory,
  PaymentType,
  type Statement,
} from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import {
  ButtonLink,
  ColumnHelper,
  DataGrid,
  getPageCount,
  Table,
  useControlledDataGridData,
  useDataGridState,
} from '@amalia/design-system/components';
import { fuzzyFilter } from '@amalia/ext/filters';
import { Link } from '@amalia/ext/react-router-dom';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { objectToQs } from '@amalia/lib-ui';
import { type Period } from '@amalia/payout-definition/periods/types';
import { useCurrentCompany } from '@amalia/tenants/companies/state';

import { ForecastPaymentDateCell } from './cells/forecast-payment-date-cell/ForecastPaymentDateCell';

const columnHelper = new ColumnHelper<Payment>();

export type StatementPaymentsDataGridProps = {
  readonly category: PaymentCategory;
  readonly statement: Statement;
  readonly period?: Period;
  readonly payments: Payment[];
};

export const StatementPaymentsDataGrid = memo(function StatementPaymentsDataGrid({
  category,
  statement,
  period,
  payments,
}: StatementPaymentsDataGridProps) {
  const { formatMessage } = useIntl();

  const ability = useAbilityContext();
  const { data: company } = useCurrentCompany();

  const hasRecordColumn = payments.some((payment) => payment.dealExternalId || payment.adjustment);

  const hasPaymentPeriodColumn =
    category === PaymentCategory.hold ||
    payments.some(
      (payment) => payment.paymentPeriod?.frequency && payment.paymentPeriod?.frequency !== payment.period.frequency,
    );

  const hasForecastPaymentDateColumn =
    category === PaymentCategory.hold &&
    payments.some(
      (payment) =>
        (statement?.results.definitions.plan.rules || []).find((r) => r.id === payment.ruleId)?.configuration
          ?.forecastPaymentDateFieldId,
    );

  const hasPaymentDateColumn = (payments || []).some(
    (payment) =>
      (statement?.results.definitions.plan.rules || []).find((r) => r.id === payment.ruleId)?.configuration
        ?.paymentDateFieldId,
  );

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor('id', {
          id: 'id',
          header: formatMessage({ defaultMessage: 'Payment id' }),
          sortingFn: DataGrid.sortingFn.strings,
          filterFn: (paymentId, searchText) => fuzzyFilter(paymentId, searchText),
        }),

        columnHelper.accessor('period', {
          id: 'statement',
          header: formatMessage({ defaultMessage: 'Commission period' }),
          sortingFn: (a, b) => DataGrid.sortingFn.numbers(a.startDate, b.startDate),
          filterFn: (period, searchText) => fuzzyFilter(period.name, searchText),
          size: 240,
          cell: ({ value: paymentPeriod, row: payment }) =>
            company.statementFrequency ? (
              <Table.Cell.WithActions
                actions={
                  <ButtonLink
                    icon={<IconExternalLink />}
                    iconPosition={ButtonLink.IconPosition.RIGHT}
                    size={ButtonLink.Size.SMALL}
                    variant={ButtonLink.Variant.PRIMARY_LIGHT}
                    to={
                      <Link
                        openInNewTab
                        to={generatePath(routes.STATEMENT, { id: payment.statementId })}
                      />
                    }
                  >
                    <FormattedMessage defaultMessage="View statement" />
                  </ButtonLink>
                }
              >
                {paymentPeriod.name}
              </Table.Cell.WithActions>
            ) : null,
        }),

        columnHelper.accessor((payment) => payment.statement.plan?.name, {
          id: 'plan',
          header: formatMessage({ defaultMessage: 'Plan' }),
          sortingFn: DataGrid.sortingFn.strings,
          filterFn: fuzzyFilter,
          cell: ({ value: planName, row: payment }) => (
            <Table.Cell.Text>
              {payment.type === PaymentType.ADJUSTMENT ? (
                <i>
                  <FormattedMessage defaultMessage="Adjustment" />
                </i>
              ) : (
                planName
              )}
            </Table.Cell.Text>
          ),
        }),

        columnHelper.accessor((payment) => payment.rule?.name, {
          id: 'rule',
          header: formatMessage({ defaultMessage: 'Rule' }),
          sortingFn: DataGrid.sortingFn.strings,
        }),

        hasRecordColumn &&
          columnHelper.accessor((payment) => payment.dealName, {
            id: 'object',
            header: formatMessage({ defaultMessage: 'Record' }),
            sortingFn: DataGrid.sortingFn.strings,
            filterFn: fuzzyFilter,
            size: 240,
            cell: ({ value: dealName, row: payment }) => (
              <Table.Cell.WithActions
                actions={
                  payment.dealName && payment.dealObjectName && ability.can(ActionsEnum.view, SubjectsEnum.Data) ? (
                    <ButtonLink
                      icon={<IconExternalLink />}
                      iconPosition={ButtonLink.IconPosition.RIGHT}
                      size={ButtonLink.Size.SMALL}
                      variant={ButtonLink.Variant.PRIMARY_LIGHT}
                      to={
                        <Link
                          openInNewTab
                          to={{
                            pathname: generatePath(routes.DATA_SLUG, { slug: payment.dealObjectName }),
                            search: objectToQs({ search: payment.dealExternalId }),
                          }}
                        />
                      }
                    >
                      <FormattedMessage defaultMessage="View record" />
                    </ButtonLink>
                  ) : undefined
                }
              >
                {dealName}
              </Table.Cell.WithActions>
            ),
          }),

        columnHelper.accessor('value', {
          id: 'value',
          header: formatMessage({ defaultMessage: 'Commission amount' }),
          sortingFn: DataGrid.sortingFn.numbers,
          filterFn: (value, searchText) => fuzzyFilter(`${value}`, searchText),
          cell: ({ value: paymentValue, row: payment }) => (
            <Table.Cell.Text>
              {payment.currency && Number.isFinite(paymentValue)
                ? formatTotal(paymentValue, FormatsEnum.currency, payment.currency)
                : null}
            </Table.Cell.Text>
          ),
        }),

        hasPaymentPeriodColumn &&
          columnHelper.accessor('paymentPeriod', {
            id: 'paymentPeriod',
            header: formatMessage({ defaultMessage: 'Payment period' }),
            sortingFn: (a, b) => DataGrid.sortingFn.numbers(a?.startDate, b?.startDate),
            filterFn: (period, searchText) => fuzzyFilter(period?.name, searchText),
            cell: ({ value: paymentPeriod }) => <Table.Cell.Text>{paymentPeriod?.name}</Table.Cell.Text>,
          }),

        hasForecastPaymentDateColumn &&
          columnHelper.accessor('forecastPaymentDate', {
            id: 'forecastPaymentDate',
            header:
              company.customization?.holdAndReleaseForecastPaymentDateLabel ||
              formatMessage({ defaultMessage: 'Forecast payment date' }),
            sortingFn: DataGrid.sortingFn.dates,
            cell: ({ value: forecastPaymentDate }) =>
              forecastPaymentDate ? (
                <ForecastPaymentDateCell
                  forecastDate={forecastPaymentDate}
                  period={period}
                />
              ) : null,
          }),

        hasPaymentDateColumn &&
          columnHelper.accessor('paymentDate', {
            id: 'paymentDate',
            header:
              company.customization?.holdAndReleasePaymentDateLabel ||
              formatMessage({ defaultMessage: 'Payment date' }),
            sortingFn: DataGrid.sortingFn.dates,
            cell: ({ value: paymentDate }) => (paymentDate ? formatDate(paymentDate, 'YYYY-MM-DD') : null),
          }),

        columnHelper.display({
          id: Table.Cell.Actions.columnId,
          header: '',
          size: 0,
          cell: ({ row: payment }) => (
            <Table.Cell.Actions>
              <Link
                openInNewTab
                to={
                  payment.ruleId && payment.dealExternalId
                    ? generatePath(routes.STATEMENT_RULE_ACTION_EXTERNAL_ID, {
                        id: payment.statementId,
                        ruleid: payment.ruleId,
                        action: 'tracing',
                        externalid: payment.dealExternalId,
                      })
                    : payment.ruleId
                      ? generatePath(routes.STATEMENT_RULE_ACTION, {
                          id: payment.statementId,
                          ruleid: payment.ruleId,
                          action: 'tracing',
                        })
                      : generatePath(routes.STATEMENT, { id: payment.statementId })
                }
              >
                <Table.Cell.IconAction
                  icon={<IconSearch />}
                  label={formatMessage({ defaultMessage: 'Open tracing' })}
                />
              </Link>
            </Table.Cell.Actions>
          ),
        }),
      ].filter(Boolean),
    [
      formatMessage,
      company,
      hasRecordColumn,
      ability,
      hasPaymentPeriodColumn,
      hasPaymentDateColumn,
      hasForecastPaymentDateColumn,
      period,
    ],
  );

  const {
    columnSorting,
    setColumnSorting,
    columnVisibility,
    setColumnVisibility,
    searchText,
    setSearchText,
    page,
    setPage,
    pageSize,
    setPageSize,
    columnOrder,
    setColumnOrder,
    columnPinning,
    setColumnPinning,
  } = useDataGridState({
    columnVisibility: {
      id: false,
      plan: false,
    },
  });

  const { data: controlledData, total } = useControlledDataGridData(columns, payments, {
    columnSorting,
    page,
    pageSize,
    searchText,
  });

  return (
    <DataGrid
      columns={columns}
      data={controlledData}
      rowKey="id"
      totalItems={total}
      columnOrder={
        <DataGrid.ColumnOrder
          columnOrder={columnOrder}
          onChangeColumnOrder={setColumnOrder}
        />
      }
      columnPinning={
        <DataGrid.ColumnPinning
          isActive={columnPinning}
          onChange={setColumnPinning}
        />
      }
      columnSorting={
        <DataGrid.ColumnSorting
          columnSorting={columnSorting}
          onChangeColumnSorting={setColumnSorting}
        />
      }
      columnVisibility={
        <DataGrid.ColumnVisibility
          columnVisibility={columnVisibility}
          onChangeColumnVisibility={setColumnVisibility}
        />
      }
      pageSize={
        <DataGrid.PageSize
          value={pageSize}
          onChange={setPageSize}
        />
      }
      pagination={
        <DataGrid.Pagination
          page={page}
          pageCount={getPageCount(total, pageSize)}
          onChangePage={setPage}
        />
      }
      search={
        <DataGrid.Search
          value={searchText}
          onChange={setSearchText}
        />
      }
    />
  );
});
