import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Command } from 'cmdk';
import { memo, type ReactNode } from 'react';

import { Typography } from '@amalia/design-system/components';

const CommandItemContainer = styled(Command.Item)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type CommandItemProps = {
  readonly leftElement?: ReactNode;
  readonly rightElement?: ReactNode;
  readonly labelElement?: ReactNode;
  readonly label: string;
  readonly value: string;
  readonly onSelect: ((value: string) => void) | undefined;
  readonly keywords?: string[];
};

export const CommandItem = memo(function CommandItem({
  value,
  label,
  leftElement,
  rightElement,
  onSelect,
  labelElement,
  keywords,
}: CommandItemProps) {
  return (
    <CommandItemContainer
      keywords={keywords}
      value={value}
      onSelect={onSelect}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 12px;
        `}
      >
        {leftElement}
        <Typography variant={Typography.Variant.BODY_LARGE_REGULAR}>{label}</Typography>
        {labelElement}
      </div>
      {rightElement}
    </CommandItemContainer>
  );
});

export const buildActionValue = (...elements: (string | null | undefined)[]) => elements.join('__');
