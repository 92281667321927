export const PAYMENTS_ACTIONS = {
  START: 'PAYMENTS/START',
  ERROR: 'PAYMENTS/ERROR',

  SET_PAYMENT_CATEGORY: 'PAYMENTS/SET_PAYMENT_CATEGORY',

  CLEAR_PAYMENTS: 'PAYMENTS/CLEAR_PAYMENTS',

  SET_STATEMENT_PAYMENTS: 'PAYMENTS/SET_STATEMENT_PAYMENTS',
  SET_CONTEXT_PAYMENTS: 'PAYMENTS/SET_CONTEXT_PAYMENTS',
  SET_STATEMENT_AMOUNTS: 'PAYMENTS/SET_STATEMENT_AMOUNTS',
  SET_COMPANY_AMOUNTS: 'PAYMENTS/SET_COMPANY_AMOUNTS',
} as const;
