import { Fragment, memo } from 'react';

import { DropdownListItem } from '../dropdown-list-item/DropdownListItem';
import { type DropdownListItem as DropdownListItemShape } from '../DropdownList.types';
import { useShowMore } from '../hooks/useShowMore';
import { DropdownListShowMoreButton } from '../show-more-button/ShowMoreButton';

export type PaginatedDropdownListItemsProps = {
  readonly items: DropdownListItemShape[];
  readonly id?: string;
};

export const PaginatedDropdownListItems = memo(function PaginatedDropdownListItems({
  items,
  id,
}: PaginatedDropdownListItemsProps) {
  const { itemsToShow, hasMore, onShowMore } = useShowMore(items);

  return (
    <Fragment>
      {itemsToShow.map((item) => (
        <DropdownListItem
          key={item.key}
          id={id}
          item={item}
        />
      ))}

      {!!hasMore && (
        <DropdownListShowMoreButton
          count={itemsToShow.length}
          total={items.length}
          onClick={onShowMore}
        />
      )}
    </Fragment>
  );
});
