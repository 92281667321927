import { css } from '@emotion/react';
import { IconTable } from '@tabler/icons-react';
import { groupBy } from 'lodash';
import { Fragment, memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Badge, BadgeStatus, Typography } from '@amalia/design-system/components';
import { ColorCategory } from '@amalia/design-system/meta';
import { useCustomReportsList } from '@amalia/reporting/custom-reports/state';

import { CommandGroup } from '../../cmdk-styled/CommandGroup';
import { CommandItem } from '../../cmdk-styled/CommandItem';
import { useActions } from '../../commands-actions/useActions';
import { PagesEnum } from '../../shortcutCommands.types';

export const ListReportsCommand = memo(function ListReportsCommands() {
  const { data: reportsList } = useCustomReportsList();

  const { primaryAction } = useActions(PagesEnum.LIST_REPORTS);

  const reportsListOptions = useMemo(
    () =>
      groupBy(
        reportsList.map((report) => ({
          name: report.name,
          id: report.id,
          folderName: report.folder?.name,
          isAccessibleFromApi: report.isAccessibleFromApi,
          source: report.source,
        })),
        'folderName',
      ),
    [reportsList],
  );

  return (
    <Fragment>
      {Object.keys(reportsListOptions).map((folderName) => (
        <CommandGroup
          key={folderName}
          Icon={IconTable}
          title={folderName === 'undefined' ? <FormattedMessage defaultMessage="No folder" /> : folderName}
        >
          {reportsListOptions[folderName].map(({ id, name, isAccessibleFromApi, source }) => (
            <CommandItem
              key={id}
              keywords={[id, name]}
              label={name}
              value={id}
              labelElement={
                !!isAccessibleFromApi && (
                  <Badge
                    size={Badge.Size.SMALL}
                    variant={BadgeStatus.SUCCESS}
                  >
                    <FormattedMessage defaultMessage="Shared with API" />
                  </Badge>
                )
              }
              rightElement={
                <Typography
                  variant={Typography.Variant.BODY_SMALL_REGULAR}
                  css={(theme) => css`
                    color: ${theme.ds.colors[ColorCategory.GRAY][700]};
                  `}
                >
                  {source}
                </Typography>
              }
              onSelect={primaryAction?.onSelect}
            />
          ))}
        </CommandGroup>
      ))}
    </Fragment>
  );
});
