export const downloadFile = (data: Blob, fileName: string, extension: string) => {
  // Create a URL / base64 for the file
  const fileUrl = window.URL.createObjectURL(data);

  // Create a link, simulate a click on it, then remove it
  const tempLink = document.createElement('a');
  tempLink.href = fileUrl;
  tempLink.setAttribute('download', `${fileName}.${extension}`);
  tempLink.click();
  tempLink.remove();
};

export const downloadCsvFile = (data: string, fileName: string = 'export') => {
  // Blob data, inserting a BOM for character encoding
  // ( https://stackoverflow.com/questions/31959487/utf-8-encoidng-issue-when-exporting-csv-file-javascript )
  const blobData = new Blob([`\ufeff${data}`], { type: 'text/csv;charset=utf-8' });
  return downloadFile(blobData, fileName, 'csv');
};
