import { type Key, type ReactNode } from 'react';

import { type DropdownGroupProps } from '../dropdown/dropdown-group/DropdownGroup';
import { type DropdownItemContentProps } from '../dropdown/dropdown-item-content/DropdownItemContent';

export type DropdownListItem = Pick<
  DropdownItemContentProps,
  'disabled' | 'grabHandle' | 'icon' | 'rightActions' | 'secondaryLabel' | 'tooltip'
> & {
  key: Key;
} & (
    | {
        label: ReactNode;
        filterLabel: string;
      }
    | {
        label: string;
        filterLabel?: string;
      }
  );

export type DropdownListItemGroup = Pick<
  DropdownGroupProps,
  'action' | 'countBadge' | 'icon' | 'initialIsOpen' | 'label' | 'tooltip'
> & {
  /** Group items. */
  items: DropdownListItem[];
};

export const isDropdownListItemGroup = (
  itemOrGroup: DropdownListItem | DropdownListItemGroup,
): itemOrGroup is DropdownListItemGroup => 'items' in itemOrGroup;
