import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  clearUserStatementsList,
  fetchUserStatementsList,
  selectUserStatementsIsLoading,
  selectUserStatementsList,
  selectUserStatementsListCurrentPage,
  useThunkDispatch,
} from '@amalia/frontend/web-data-layers';
import { type FindUserStatementsParameters } from '@amalia/payout-calculation/statements/state';
import { type Period } from '@amalia/payout-definition/periods/types';

/**
 * Helper hook to get user statements.
 *
 * TODO: refactor that using react-query, since the hook is now contained properly.
 *
 * However it's not going to be that easy, because we need maybe one hook per use case,
 * since cards view is infinite scroll, list view is paginated, and the home page
 * is "not paginated" (which means "I hope the number of results never go over the
 * default page size").
 *
 * @param searchOptions
 */
export const useUserStatementsList = (searchOptions: FindUserStatementsParameters) => {
  const dispatch = useThunkDispatch();

  const fetchStatements = useCallback(
    async (page: number, force: boolean, period?: Period) => {
      // On the first load, period is not present in redux, so we pass it through here.
      // After that, the treatment should only rely on the one in redux.
      if (force) {
        dispatch(clearUserStatementsList());
      }

      return dispatch(
        fetchUserStatementsList(
          {
            ...searchOptions,
            period: period || searchOptions.period,
          },
          +page,
          force,
        ),
      );
    },
    [dispatch, searchOptions],
  );

  const userStatementsList = useSelector(selectUserStatementsList);
  const userStatementsPage = useSelector(selectUserStatementsListCurrentPage);
  const isLoading = useSelector(selectUserStatementsIsLoading);

  return { userStatementsList, userStatementsPage, fetchStatements, isLoading };
};
