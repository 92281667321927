import { type ReactNode, memo, forwardRef, type ForwardedRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { type MergeAll } from '@amalia/ext/typescript';

import { Button, type ButtonProps } from '../../../general/button/Button';
import { useModalContext } from '../Modal.context';

export type ModalCancelActionProps = MergeAll<
  [
    Omit<ButtonProps, 'size' | 'variant'>,
    {
      /** Override default text. */
      children?: ReactNode;
    },
  ]
>;

const ModalCancelActionForwardRef = forwardRef(function ModalCancelAction(
  { children, ...props }: ModalCancelActionProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { onCloseModal } = useModalContext();

  return (
    <Button
      {...props}
      ref={ref}
      size={Button.Size.MEDIUM}
      variant={Button.Variant.LIGHT_TEXT}
      onClick={props.onClick || onCloseModal}
    >
      {children || <FormattedMessage defaultMessage="Cancel" />}
    </Button>
  );
});

export const ModalCancelAction = memo(ModalCancelActionForwardRef);
