import { type FormulaEditorToken } from '@amalia/amalia-lang/formula/components';
import { DesignerTokenIcon } from '@amalia/amalia-lang/tokens/components';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { VENDOR_LOGOS } from '@amalia/data-capture/connectors/assets';
import { IconAmaliaLogoColoured } from '@amalia/frontend/ui-icons';

export const useDropdownItemIcon = (item: FormulaEditorToken) => {
  if (item.type === TokenType.FILTER) {
    const CompactLogo = item.dataConnectorType
      ? VENDOR_LOGOS[item.dataConnectorType].CompactLogo
      : IconAmaliaLogoColoured;
    return <CompactLogo />;
  }

  return (
    <DesignerTokenIcon
      tokenFormat={item.format}
      tokenType={item.type}
    />
  );
};
