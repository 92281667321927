import { type CSSProperties } from 'react';

import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { AMALIA_OBJECTS_HIGHLIGHT_COLOR } from '@amalia/ext/mui/theme';
import { type Filter, type Relationship } from '@amalia/payout-definition/plans/types';

import { FormulaNodeEnum, IFormulaNode } from './IFormulaNode';

export class FilterFormulaNode extends IFormulaNode<Filter> {
  public constructor(
    partialFormula: string,
    startIndex: number,
    nodeObject: Filter,
    objectDefinition?: CustomObjectDefinition,
    relationship?: Relationship,
  ) {
    super(partialFormula, startIndex, FormulaNodeEnum.FILTER, nodeObject, objectDefinition, relationship);
  }

  public getStyles(): CSSProperties {
    return { backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.filter };
  }

  public getDesignerPath() {
    return this.nodeObject.id ? { type: TokenType.FILTER, id: this.nodeObject.id } : null;
  }

  public getLabel(): string {
    return this.nodeObject.name.trim();
  }
}
