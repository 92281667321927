import {
  Autocomplete as MuiAutocomplete,
  type AutocompleteProps,
  type AutocompleteRenderInputParams,
} from '@mui/material';
import { type ReactNode } from 'react';

import { TextType, Text } from '../../typography';
import { FormElementContainer, InputLabel } from '../inputs/Input/Input';

interface Option {
  value: string;
  label: string;
}

interface AutocompleteFieldProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  readonly label?: ReactNode;
  readonly renderInput: (params: AutocompleteRenderInputParams) => ReactNode;
  readonly containerClassName?: string;
}

export const Autocomplete = function Autocomplete<
  T extends Option,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({ label, containerClassName, ...rest }: AutocompleteFieldProps<T, Multiple, DisableClearable, FreeSolo>) {
  return (
    <FormElementContainer className={containerClassName}>
      {label ? (
        <InputLabel>
          <Text type={TextType.INPUT_NAME}>{label}</Text>
        </InputLabel>
      ) : null}
      <MuiAutocomplete
        getOptionLabel={(option: Option) => option.label}
        size="small"
        {...rest}
      />
    </FormElementContainer>
  );
};
