import { useMemo } from 'react';

import { type UsersTableData } from '../types';

import { type UserFilters } from './types';

export const useUsersTableFilteredData = (users: UsersTableData[], filters: UserFilters) =>
  useMemo(
    () =>
      users.filter((user) =>
        Object.entries(filters).every(
          ([key, value]) =>
            // For each filter, either don't apply it when no value is selected
            // or only include the user when their property's value is selected
            value.length === 0 || value.includes(user[key as keyof UsersTableData] as (typeof value)[number]),
        ),
      ),
    [users, filters],
  );
