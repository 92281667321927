import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertBanner } from '@amalia/design-system/components';

import { StatementSummaryReviewDisabled } from '../../../common/statement-summary-layout/review/review-disabled/StatementSummaryReviewDisabled';

export const StatementDetailSummaryReviewDisabled = memo(function StatementDetailSummaryReviewDisabled() {
  return (
    <StatementSummaryReviewDisabled
      alert={
        <AlertBanner>
          <FormattedMessage defaultMessage="Review is not available on forecast." />
        </AlertBanner>
      }
    />
  );
});
