import { IconFilterPlus, IconTrash } from '@tabler/icons-react';
import { isFunction, partition, without } from 'lodash';
import { memo, useState, useCallback, Fragment, isValidElement, Children, cloneElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useShallowObjectMemo } from '@amalia/ext/react/hooks';

import { IconButton } from '../../general/icon-button/IconButton';
import { Group } from '../../layout/group/Group';
import { MenuDropdown } from '../../overlays/menu-dropdown/MenuDropdown';
import { type SelectOptionGroup } from '../../overlays/select-dropdown/SelectDropdown.types';

import { FilterDatePicker, type FilterDatePickerProps } from './filter-date-picker/FilterDatePicker';
import { FilterSelect, type FilterSelectProps } from './filter-select/FilterSelect';
import { FiltersContext, type FiltersContextValue } from './Filters.context';
import { filtersTestIds } from './Filters.testIds';
import { type FilterSelectOption, type FilterKey } from './Filters.types';
import { clearFilterValue, isEmptyFilterValue, type FilterElement } from './getFilterType';

type FilterSelectElementProps = FilterSelectProps<
  FilterSelectOption,
  boolean | undefined,
  boolean | undefined,
  SelectOptionGroup<FilterSelectOption>
>;

type FilterDatePickerElementProps = FilterDatePickerProps<boolean | undefined>;

type FilterElementProps = FilterDatePickerElementProps | FilterSelectElementProps;

export type FiltersProps = {
  /** List of ids of displayed filters. Static filters are implicitly displayed (not in this list). If omitted, the component controls the state itself. */
  readonly displayedFiltersIds?: FilterKey[];
  /** Callback when the list of displayed filters changes. */
  readonly onChangeDisplayedFiltersIds?: (displayedFiltersIds: FilterKey[]) => void;
  /** Hide "clear filters" button. The button is hidden automatically if there are no displayed filters. */
  readonly isClearFiltersHidden?: boolean;
  /** Disable "clear filters" button. The button is disabled automatically if all filters are empty. */
  readonly isClearFiltersDisabled?: boolean;
  /** List of `<Filters.FilterSelect />` or `<Filters.FilterDate />` elements. Do not encapsulate filters in sub-components, prefer creating hooks to return their props and spreading them on the filter elements instead. */
  readonly children: FilterElement | FilterElement[];
};

const FiltersBase = function Filters({
  displayedFiltersIds: controlledDisplayedFiltersIds,
  onChangeDisplayedFiltersIds: controlledOnChangeDisplayedFiltersIds,
  isClearFiltersHidden: propsIsClearFiltersHidden,
  isClearFiltersDisabled: propsIsClearFiltersDisabled,
  children,
}: FiltersProps) {
  const { formatMessage } = useIntl();

  // List of ids of displayed filters. Static filters are implicitly displayed (not in this list).
  const [uncontrolledDisplayedFiltersIds, setUncontrolledDisplayedFiltersIds] = useState<FilterKey[]>([]);
  const displayedFiltersIds = controlledDisplayedFiltersIds ?? uncontrolledDisplayedFiltersIds;
  const setDisplayedFiltersIds = controlledOnChangeDisplayedFiltersIds ?? setUncontrolledDisplayedFiltersIds;

  // Id of the last filter that was added. Used to open its dropdown on mount.
  const [lastFilterAddedId, setLastFilterAddedId] = useState<FilterKey | null>(null);

  // Filters are deduced from the direct children components.
  const filters = Children.toArray(children).filter(isValidElement<FilterElementProps>);

  // Split filters into static and optional filters.
  const [staticFilters, optionalFilters] = partition(filters, (filter) => filter.props.isStatic);

  // List of optional filters that are not displayed yet.
  const availableFilters = optionalFilters.filter((filter) => !displayedFiltersIds.includes(filter.props.id));

  // List of optional filters that are displayed, in the order in which they were selected.
  const displayedOptionalFilters = displayedFiltersIds
    .map((id) => optionalFilters.find((filter) => filter.props.id === id))
    .filter(Boolean);

  const displayedFilters = [...staticFilters, ...displayedOptionalFilters];

  const someFilterHasValue = filters.some((filter) => !isEmptyFilterValue(filter));

  const isClearFiltersHidden = propsIsClearFiltersHidden ?? (!displayedFilters.length && !someFilterHasValue);

  // Disable the button if there are no added optional filters and no filter has a value.
  const isClearFiltersDisabled =
    propsIsClearFiltersDisabled ?? (!displayedOptionalFilters.length && !someFilterHasValue);

  const handleHideFilter = useCallback(
    (id: FilterKey) => setDisplayedFiltersIds(without(displayedFiltersIds, id)),
    [displayedFiltersIds, setDisplayedFiltersIds],
  );

  const handleAddFilter = useCallback(
    (id: FilterKey) => {
      setDisplayedFiltersIds([...displayedFiltersIds, id]);
      setLastFilterAddedId(id);
    },
    [displayedFiltersIds, setDisplayedFiltersIds, setLastFilterAddedId],
  );

  const handleClearFilters = useCallback(() => {
    setDisplayedFiltersIds([]);
    filters.forEach((filter) => {
      clearFilterValue(filter);
    });
  }, [setDisplayedFiltersIds, filters]);

  const contextValue = useShallowObjectMemo<FiltersContextValue>({
    onHideFilter: handleHideFilter,
    lastFilterAddedId,
  });

  return (
    <FiltersContext.Provider value={contextValue}>
      <Group
        wrap
        align="center"
        gap={8}
      >
        {/**
         * Show the add filter button if there are optional filters. If all optional filters are already displayed, disable the button but still show it.
         * When there are no optional filters, don't show the button.
         */}
        {optionalFilters.length > 0 && (
          <MenuDropdown
            title={<FormattedMessage defaultMessage="Add filter on" />}
            content={
              <Fragment>
                {availableFilters.map((filter) => (
                  <MenuDropdown.Item
                    key={filter.props.id}
                    data-testid={filtersTestIds.addFilter(filter.props.id)}
                    label={
                      filter.props.menuLabel ||
                      (isFunction(filter.props.label) ? filter.props.label(0) : filter.props.label)
                    }
                    onClick={() => handleAddFilter(filter.props.id)}
                  />
                ))}
              </Fragment>
            }
          >
            <MenuDropdown.IconButton
              disabled={!availableFilters.length}
              icon={<IconFilterPlus />}
              label={formatMessage({ defaultMessage: 'Add filters' })}
              size={MenuDropdown.IconButton.Size.SMALL}
            />
          </MenuDropdown>
        )}

        {/* Show static filters first, in the order in which they were defined, then displayed optional filters, in the order in which they were selected. */}
        {displayedFilters.map((filter) => cloneElement(filter, { key: filter.props.id }))}

        {!isClearFiltersHidden && (
          <IconButton
            disabled={isClearFiltersDisabled}
            icon={<IconTrash />}
            label={formatMessage({ defaultMessage: 'Clear filters' })}
            size={IconButton.Size.SMALL}
            variant={IconButton.Variant.DANGER}
            onClick={handleClearFilters}
          />
        )}
      </Group>
    </FiltersContext.Provider>
  );
};

export const Filters = Object.assign(memo(FiltersBase), {
  FilterSelect,
  FilterDatePicker,
});
