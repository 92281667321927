import { useMemo } from 'react';

import { useCurrentUser } from '@amalia/kernel/auth/state';
import { type UserProfile } from '@amalia/tenants/users/profile/types';

/**
 * Hook to get the current logged user abilities on the avatar of another user.
 */
export const useUserAvatarAbilities = ({ id: userProfileId }: Pick<UserProfile, 'id'>) => {
  const { data: currentUser } = useCurrentUser();

  const isAvatarEditable = currentUser.id === userProfileId;

  return useMemo(
    () => ({
      isAvatarEditable,
    }),
    [isAvatarEditable],
  );
};
