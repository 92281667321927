import { IconHelp } from '@tabler/icons-react';
import { memo } from 'react';

import { Tooltip, type TooltipProps } from '../../../../overlays/tooltip/Tooltip';

export type ColumnTooltipProps = Omit<TooltipProps, 'children'>;

export const ColumnTooltip = memo(function ColumnTooltip({ ...props }: ColumnTooltipProps) {
  return (
    <Tooltip {...props}>
      <IconHelp size={14} />
    </Tooltip>
  );
});
