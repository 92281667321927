import { css, type Theme } from '@emotion/react';

export const IS_DISABLED_CLASSNAME = 'is-disabled';
export const IS_FOCUSED_CLASSNAME = 'is-focused';
export const HAS_ERROR_CLASSNAME = 'has-error';
export const HAS_WARNING_CLASSNAME = 'has-warning';

export const cellDatePickerAnchor = (theme: Theme) => css`
  position: relative;
  transition: ${theme.ds.transitions.default('background-color', 'box-shadow')};

  &:not(.${IS_DISABLED_CLASSNAME}) {
    &:hover,
    &.${IS_FOCUSED_CLASSNAME} {
      outline: 1px solid ${theme.ds.colors.primary[200]};

      // Make sure that the outline is above an eventual sticky cell.
      position: relative;
      z-index: 1;

      &.${HAS_ERROR_CLASSNAME} {
        outline-color: ${theme.ds.colors.danger[500]};
        box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.danger[50])};
      }

      &.${HAS_WARNING_CLASSNAME} {
        outline-color: ${theme.ds.colors.warning[500]};
        box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.warning[50])};
      }
    }

    &.${IS_FOCUSED_CLASSNAME} {
      box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.primary[50])};
      background-color: ${theme.ds.colors.gray[0]};
    }
  }
`;

export const buttonContainer = css`
  max-width: 100%;
  display: block;
  width: 100%;

  user-select: text;
`;

export const cellDatePickerContent = (hasErrorOrWarning: boolean, shouldShowClear: boolean) => css`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  box-sizing: border-box;

  /* Add space for error icon, clear button, chevron and gaps between elements. They are positioned in absolute to avoid button in button. */
  padding: 0 ${(hasErrorOrWarning ? 18 + 6 : 0) + (shouldShowClear ? 19.2 + 6 : 0) + 19.2 + 16 + 6}px 0 16px;
`;

export const icon = css`
  flex: none;
`;

export const placeholder = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[400]};

  button:disabled & {
    color: ${theme.ds.colors.gray[300]};
  }
`;

export const indicatorsContainer = css`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);

  > * {
    flex: none;
  }
`;
