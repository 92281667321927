import { type Option, type Statement, type StatementError, type TeamOption } from '@amalia/core/types';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { type CurrencyValue } from '@amalia/kernel/monetary/types';
import {
  type WorkflowDefinition,
  type WorkflowStatementStateAction,
} from '@amalia/payout-collaboration/workflows/types';
import { type PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';
import { type ComputedHighlightedKpi, type HighlightedKpiIdentifier } from '@amalia/payout-definition/plans/types';
import { type UserContract } from '@amalia/tenants/users/types';

/**
 * Statements grouped by user.
 */
export interface UserStatements {
  user: UserContract;
  lines: Statement[];
  currency: CurrencySymbolsEnum;
  rate: number;
  error?: StatementError;
  workflowComplete: boolean;
  total: number;
  statementIds?: string[];
  workflowStatus?: number;
  username?: string;
}

export interface WorkflowCompletedOptions {
  reviewed: Option;
  unreviewed: Option;
}

export interface StatementOption {
  statementId: string;
  planId: string;
  planName: string;
  isPlanHidden: boolean;
}

export type UserOption = Pick<
  UserContract,
  'currency' | 'email' | 'externalId' | 'firstName' | 'id' | 'lastName' | 'pictureURL'
> & {
  statementsOptions?: StatementOption[];
};

export interface PlanOption extends Option {
  frequency: PeriodFrequencyEnum;
  archived?: boolean;
}

/**
 * User statements  facets.
 */
export interface UserStatementsFacets {
  plans?: PlanOption[];
  teams?: TeamOption[];
  users?: UserOption[];

  total?: CurrencyValue;

  workflow?: {
    definition: WorkflowDefinition;
    workflowCompleted?: WorkflowCompletedOptions;

    canValidSteps?: WorkflowStatementStateAction[];
    currentStepNumber?: number;
    steps: {
      statementsCount: number;
      completed: boolean;
      stepName: string;
      from: number;
      to: number;
    }[];
  };

  errors?: {
    message: string;
    statementIds?: string[];
  }[];
}

export interface UserStatementsPage {
  userStatements: UserStatements[];
  total: number;
  currentPage: number;
  kpisAverage: Record<HighlightedKpiIdentifier, ComputedHighlightedKpi | null>;
}

export enum StatusStatementType {
  UNDER_REVIEW = 'underreview',
  REVIEWED = 'reviewed',
  UNREVIEWED = 'unreviewed',
  ERROR = 'error',
  WARNING = 'warning',
}
