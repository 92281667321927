import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { type ConnectorDefinition, CONNECTORS } from '@amalia/core/types';
import { DataConnectorTypes } from '@amalia/data-capture/connectors/types';
import { config } from '@amalia/kernel/config/client';
import { CompanyFeatureFlags, type CompanyPreferences } from '@amalia/tenants/companies/types';

import { type IntegrationLogos, VENDOR_LOGOS } from './connectors.logos';

export interface ConnectorDefinitionWithLogos extends ConnectorDefinition, IntegrationLogos {
  linkAlreadyConnected: string;
  linkFirstConnection: string;
}

// Enrich connector definitions with logos
export const listConnectorsDefinitionWithLogos = (
  preferences?: CompanyPreferences,
  featureFlags?: Record<CompanyFeatureFlags, boolean>,
): Record<string, ConnectorDefinitionWithLogos> => ({
  [DataConnectorTypes.SALESFORCE]: {
    ...CONNECTORS[DataConnectorTypes.SALESFORCE],
    ...VENDOR_LOGOS[DataConnectorTypes.SALESFORCE],
    linkAlreadyConnected: generatePath(routes.DATA_CONNECTOR, { connectorType: DataConnectorTypes.SALESFORCE }),
    linkFirstConnection:
      preferences?.connectors?.salesforce?.oAuthFlow === 'client_credentials'
        ? generatePath(routes.DATA_CONNECTOR_CONNECTION, { connectorType: DataConnectorTypes.SALESFORCE })
        : `${config.api.url}/connectors/salesforce/login?useSandbox=${preferences?.connectors?.salesforce?.useSandbox}`,
  },
  [DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT]: {
    ...CONNECTORS[DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT],
    ...VENDOR_LOGOS[DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT],
    isDisabled: !featureFlags?.[CompanyFeatureFlags.SALESFORCE_SECOND_ACCOUNT],
    linkAlreadyConnected: generatePath(routes.DATA_CONNECTOR, {
      connectorType: DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT,
    }),
    linkFirstConnection:
      preferences?.connectors?.salesforce?.oAuthFlow === 'client_credentials'
        ? generatePath(routes.DATA_CONNECTOR_CONNECTION, {
            connectorType: DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT,
          })
        : `${config.api.url}/connectors/salesforce-second-account/login?useSandbox=${preferences?.connectors?.salesforce?.useSandbox}`,
  },
  [DataConnectorTypes.SALESFORCE_THIRD_ACCOUNT]: {
    ...CONNECTORS[DataConnectorTypes.SALESFORCE_THIRD_ACCOUNT],
    ...VENDOR_LOGOS[DataConnectorTypes.SALESFORCE_THIRD_ACCOUNT],
    isDisabled: !featureFlags?.[CompanyFeatureFlags.SALESFORCE_SECOND_ACCOUNT],
    linkAlreadyConnected: generatePath(routes.DATA_CONNECTOR, {
      connectorType: DataConnectorTypes.SALESFORCE_THIRD_ACCOUNT,
    }),
    linkFirstConnection:
      preferences?.connectors?.salesforce?.oAuthFlow === 'client_credentials'
        ? generatePath(routes.DATA_CONNECTOR_CONNECTION, {
            connectorType: DataConnectorTypes.SALESFORCE_THIRD_ACCOUNT,
          })
        : `${config.api.url}/connectors/salesforce-third-account/login?useSandbox=${preferences?.connectors?.salesforce?.useSandbox}`,
  },
  [DataConnectorTypes.HUBSPOT]: {
    ...CONNECTORS[DataConnectorTypes.HUBSPOT],
    ...VENDOR_LOGOS[DataConnectorTypes.HUBSPOT],
    linkAlreadyConnected: generatePath(routes.DATA_CONNECTOR, { connectorType: DataConnectorTypes.HUBSPOT }),
    linkFirstConnection: `${config.api.url}/connectors/hubspot/login`,
  },
  [DataConnectorTypes.ZOHO]: {
    ...CONNECTORS[DataConnectorTypes.ZOHO],
    ...VENDOR_LOGOS[DataConnectorTypes.ZOHO],
    linkAlreadyConnected: generatePath(routes.DATA_CONNECTOR, { connectorType: DataConnectorTypes.ZOHO }),
    linkFirstConnection: `${config.api.url}/connectors/zoho/login`,
  },
  [DataConnectorTypes.NETSUITE]: {
    ...CONNECTORS[DataConnectorTypes.NETSUITE],
    ...VENDOR_LOGOS[DataConnectorTypes.NETSUITE],
    linkAlreadyConnected: generatePath(routes.DATA_CONNECTOR, { connectorType: DataConnectorTypes.NETSUITE }),
    linkFirstConnection: generatePath(routes.DATA_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.NETSUITE,
    }),
  },
  [DataConnectorTypes.GOOGLESHEET]: {
    ...CONNECTORS[DataConnectorTypes.GOOGLESHEET],
    ...VENDOR_LOGOS[DataConnectorTypes.GOOGLESHEET],
    linkAlreadyConnected: generatePath(routes.DATA_CONNECTOR, { connectorType: DataConnectorTypes.GOOGLESHEET }),
    linkFirstConnection: `${config.api.url}/connectors/googlesheet/login`,
  },
  [DataConnectorTypes.REDSHIFT]: {
    ...CONNECTORS[DataConnectorTypes.REDSHIFT],
    ...VENDOR_LOGOS[DataConnectorTypes.REDSHIFT],
    linkAlreadyConnected: generatePath(routes.DATA_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.REDSHIFT,
    }),
    linkFirstConnection: generatePath(routes.DATA_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.REDSHIFT,
    }),
  },
  [DataConnectorTypes.BIGQUERY]: {
    ...CONNECTORS[DataConnectorTypes.BIGQUERY],
    ...VENDOR_LOGOS[DataConnectorTypes.BIGQUERY],
    linkAlreadyConnected: generatePath(routes.DATA_CONNECTOR, { connectorType: DataConnectorTypes.BIGQUERY }),
    linkFirstConnection: `${config.api.url}/connectors/bigquery/login`,
  },
  [DataConnectorTypes.SNOWFLAKE]: {
    ...CONNECTORS[DataConnectorTypes.SNOWFLAKE],
    ...VENDOR_LOGOS[DataConnectorTypes.SNOWFLAKE],
    linkAlreadyConnected: generatePath(routes.DATA_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.SNOWFLAKE,
    }),
    linkFirstConnection: generatePath(routes.DATA_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.SNOWFLAKE,
    }),
  },
  [DataConnectorTypes.POSTGRESQL]: {
    ...CONNECTORS[DataConnectorTypes.POSTGRESQL],
    ...VENDOR_LOGOS[DataConnectorTypes.POSTGRESQL],
    linkAlreadyConnected: generatePath(routes.DATA_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.POSTGRESQL,
    }),
    linkFirstConnection: generatePath(routes.DATA_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.POSTGRESQL,
    }),
  },
  [DataConnectorTypes.POWERBI]: {
    ...CONNECTORS[DataConnectorTypes.POWERBI],
    ...VENDOR_LOGOS[DataConnectorTypes.POWERBI],
    isDisabled: !featureFlags?.[CompanyFeatureFlags.DATA_CONNECTOR_POWER_BI],
    linkAlreadyConnected: generatePath(routes.DATA_CONNECTOR, { connectorType: DataConnectorTypes.POWERBI }),
    linkFirstConnection: `${config.api.url}/connectors/powerbi/login`,
  },
  [DataConnectorTypes.MSDYNAMICS]: {
    ...CONNECTORS[DataConnectorTypes.MSDYNAMICS],
    ...VENDOR_LOGOS[DataConnectorTypes.MSDYNAMICS],
    linkAlreadyConnected: generatePath(routes.DATA_CONNECTOR, { connectorType: DataConnectorTypes.MSDYNAMICS }),
    linkFirstConnection: generatePath(routes.DATA_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.MSDYNAMICS,
    }),
  },
  [DataConnectorTypes.SQLSERVER]: {
    ...CONNECTORS[DataConnectorTypes.SQLSERVER],
    ...VENDOR_LOGOS[DataConnectorTypes.SQLSERVER],
    linkAlreadyConnected: generatePath(routes.DATA_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.SQLSERVER,
    }),
    linkFirstConnection: generatePath(routes.DATA_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.SQLSERVER,
    }),
  },
  [DataConnectorTypes.MYSQL]: {
    ...CONNECTORS[DataConnectorTypes.MYSQL],
    ...VENDOR_LOGOS[DataConnectorTypes.MYSQL],
    linkAlreadyConnected: generatePath(routes.DATA_CONNECTOR_CONNECTION, { connectorType: DataConnectorTypes.MYSQL }),
    linkFirstConnection: generatePath(routes.DATA_CONNECTOR_CONNECTION, { connectorType: DataConnectorTypes.MYSQL }),
  },
  [DataConnectorTypes.ADPWORKFORCENOW]: {
    ...CONNECTORS[DataConnectorTypes.ADPWORKFORCENOW],
    ...VENDOR_LOGOS[DataConnectorTypes.ADPWORKFORCENOW],
    isDisabled: !featureFlags?.[CompanyFeatureFlags.USER_CONNECTOR_ADP],
    linkAlreadyConnected: generatePath(routes.DATA_USER_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.ADPWORKFORCENOW,
    }),
    linkFirstConnection: generatePath(routes.DATA_USER_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.ADPWORKFORCENOW,
    }),
  },
  [DataConnectorTypes.BAMBOOHR]: {
    ...CONNECTORS[DataConnectorTypes.BAMBOOHR],
    ...VENDOR_LOGOS[DataConnectorTypes.BAMBOOHR],
    isDisabled: !featureFlags?.[CompanyFeatureFlags.USER_CONNECTOR_BAMBOO],
    linkAlreadyConnected: generatePath(routes.DATA_USER_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.BAMBOOHR,
    }),
    linkFirstConnection: generatePath(routes.DATA_USER_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.BAMBOOHR,
    }),
  },
  [DataConnectorTypes.PERSONIO]: {
    ...CONNECTORS[DataConnectorTypes.PERSONIO],
    ...VENDOR_LOGOS[DataConnectorTypes.PERSONIO],
    linkAlreadyConnected: generatePath(routes.DATA_CONNECTOR, { connectorType: DataConnectorTypes.PERSONIO }),
    linkFirstConnection: generatePath(routes.DATA_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.PERSONIO,
    }),
  },
  [DataConnectorTypes.SAGEHR]: {
    ...CONNECTORS[DataConnectorTypes.SAGEHR],
    ...VENDOR_LOGOS[DataConnectorTypes.SAGEHR],
    isDisabled: !featureFlags?.[CompanyFeatureFlags.USER_CONNECTOR_SAGE],
    linkAlreadyConnected: generatePath(routes.DATA_USER_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.SAGEHR,
    }),
    linkFirstConnection: generatePath(routes.DATA_USER_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.SAGEHR,
    }),
  },
  [DataConnectorTypes.SAPSUCCESSFACTORS]: {
    ...CONNECTORS[DataConnectorTypes.SAPSUCCESSFACTORS],
    ...VENDOR_LOGOS[DataConnectorTypes.SAPSUCCESSFACTORS],
    isDisabled: !featureFlags?.[CompanyFeatureFlags.USER_CONNECTOR_SAP],
    linkAlreadyConnected: generatePath(routes.DATA_USER_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.SAPSUCCESSFACTORS,
    }),
    linkFirstConnection: generatePath(routes.DATA_USER_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.SAPSUCCESSFACTORS,
    }),
  },
  [DataConnectorTypes.WORKDAY]: {
    ...CONNECTORS[DataConnectorTypes.WORKDAY],
    ...VENDOR_LOGOS[DataConnectorTypes.WORKDAY],
    isDisabled: !featureFlags?.[CompanyFeatureFlags.USER_CONNECTOR_WORKDAY],
    linkAlreadyConnected: generatePath(routes.DATA_USER_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.WORKDAY,
    }),
    linkFirstConnection: generatePath(routes.DATA_USER_CONNECTOR_CONNECTION, {
      connectorType: DataConnectorTypes.WORKDAY,
    }),
  },
});
