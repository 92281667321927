import { memo, useState, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Input, Modal } from '@amalia/design-system/components';

import { type TableBuilderColumn } from './tableBuilder.types';

interface TableBuilderRenameColumnModalProps {
  readonly columnToRename: TableBuilderColumn | undefined;
  readonly onSubmit: (columnName: string) => void;
  readonly onCancel: () => void;
}

export const TableBuilderRenameColumnModal = memo(function TableBuilderRenameColumnModal({
  columnToRename,
  onSubmit,
  onCancel,
}: TableBuilderRenameColumnModalProps) {
  const [value, setValue] = useState(columnToRename?.name || '');

  useEffect(() => {
    setValue(columnToRename?.name || '');
  }, [columnToRename]);

  const onSubmitProxy = useCallback(() => {
    onSubmit(value);
  }, [value, onSubmit]);

  return (
    <Modal
      isOpen={!!columnToRename}
      onClose={onCancel}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Rename column" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Input
            id="renameColumnInput"
            value={value}
            onChange={setValue}
          />
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />

        <Modal.MainAction
          data-testid="rename-column-btn"
          disabled={!value}
          onClick={onSubmitProxy}
        >
          <FormattedMessage defaultMessage="Apply" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
