import { hashKey } from '@tanstack/react-query';
import { pick } from 'lodash';
import { useCallback, useEffect } from 'react';
import { type Simplify } from 'type-fest';
import * as Yup from 'yup';

import { useDeepMemo } from '@amalia/ext/react/hooks';
import { assert } from '@amalia/ext/typescript';

import { SortDirection } from '../DataGrid.types';

import { type DataGridState, useDataGridState } from './useDataGridState';

const DATAGRID_KEY_PREFIX = 'datagrid';

const schema = Yup.object({
  page: Yup.number(),
  pageSize: Yup.number(),
  columnVisibility: Yup.object(), // Yup doesn't support Record types.
  columnOrder: Yup.array(Yup.string().required()),
  columnSorting: Yup.array(
    Yup.object({
      id: Yup.string().required(),
      direction: Yup.string().oneOf(Object.values(SortDirection)).required(),
    }),
  ),
  searchText: Yup.string(),
  columnPinning: Yup.boolean(),
});

type PersistableDataGridStateKey = keyof Omit<DataGridState, 'page'>;

const getFromLocalStorage = (key: string, fieldsToPersist: PersistableDataGridStateKey[]) => {
  const localStorageState = localStorage.getItem(key);
  try {
    return localStorageState
      ? (schema.validateSync(pick(JSON.parse(localStorageState), fieldsToPersist), { strict: true }) as DataGridState)
      : undefined;
  } catch (err) {
    return undefined;
  }
};

export type LocalStorageKey = readonly unknown[];

export const useDataGridStateInLocalStorage = <
  TFieldsToPersist extends PersistableDataGridStateKey = PersistableDataGridStateKey,
>({
  key,
  initialState,
  fieldsToPersist = [
    'pageSize',
    'columnVisibility',
    'columnOrder',
    'columnSorting',
    'searchText',
    'columnPinning',
  ] as TFieldsToPersist[],
}: {
  /**
   * Key to use in local storage.
   * This is an object with the key for each persisted field. If a field is not specified, it will use the default key.
   * As a shortcut, you can directly pass the default key if all fields are persisted to the same local storage key.
   */
  key:
    | LocalStorageKey
    | Simplify<{ [k in TFieldsToPersist]?: LocalStorageKey } & { default: LocalStorageKey }>
    | { [k in TFieldsToPersist]: LocalStorageKey };
  /** Only persist a subset of fields of the data grid state. Defaults to persist everything except the page. */
  fieldsToPersist?: TFieldsToPersist[];
  /** Initial state. Fields that are persisted in local storage overrides these values. */
  initialState?: Partial<DataGridState> | (() => Partial<DataGridState>);
}) => {
  // Make a map of hashedKey => fields to persist for this key.
  const fieldsByKey = useDeepMemo(
    () =>
      fieldsToPersist.reduce(
        (acc, field) => {
          // Determine key for this field based on the key prop.
          // If key is an array => it's a shortcut for { default: key }.
          // If field is in key => use that key specifically.
          // If field is not in key => use the default key.
          const fieldKey: LocalStorageKey | undefined = Array.isArray(key)
            ? key
            : field in key
              ? (key as { [k in TFieldsToPersist]: LocalStorageKey })[field]
              : 'default' in key
                ? key.default
                : undefined;

          assert(
            fieldKey,
            `useDataGridStateInLocalStorage: Missing key for field "${field}". Either pass a key for each field or a default key.`,
          );

          const hashedKey = hashKey(([DATAGRID_KEY_PREFIX] as unknown[]).concat(fieldKey));
          acc[hashedKey] = hashedKey in acc ? acc[hashedKey] : [];
          acc[hashedKey].push(field);
          return acc;
        },
        {} as Record<string, TFieldsToPersist[]>,
      ),
    [fieldsToPersist, key],
  );

  // Init state from local storage.
  const dataGridState = useDataGridState(() => ({
    ...(typeof initialState === 'function' ? initialState() : initialState),
    ...Object.entries(fieldsByKey).reduce(
      (acc, [hashedKey, fields]) => ({ ...acc, ...getFromLocalStorage(hashedKey, fields) }),
      {},
    ),
  }));

  // When the state changes, persist it in local storage.
  useEffect(
    () =>
      Object.entries(fieldsByKey).forEach(([hashedKey, fields]) => {
        localStorage.setItem(hashedKey, JSON.stringify(pick(dataGridState, fields)));
      }),
    [dataGridState, fieldsByKey],
  );

  const handleClear = useCallback(
    () =>
      Object.keys(fieldsByKey).forEach((hashedKey) => {
        localStorage.removeItem(hashedKey);
      }),
    [fieldsByKey],
  );

  return {
    ...dataGridState,
    clear: handleClear,
  };
};
