import { http } from '@amalia/core/http/client';
import { type Company, type COMPANY_INTEGRATIONS } from '@amalia/tenants/companies/types';

export class CompaniesApiClient {
  public static async getCompany(): Promise<Company> {
    const { data } = await http.get<Company>('/companies');
    return data;
  }

  public static async updateCompany(companyToUpdate: Partial<Company>): Promise<Company> {
    const { data } = await http.patch<Company>('/companies', companyToUpdate);
    return data;
  }

  public static async registerIntegration(integration: COMPANY_INTEGRATIONS, code: string) {
    await http.post(`/integrations/${integration}/register`, { code });
  }
}
