import { FormHelperText, type InputBaseComponentProps } from '@mui/material';
import { memo } from 'react';

import { InfoWithTooltip } from '../../../InfoWithTooltip';
import { TextType, Text } from '../../../typography';
import { formikToMui } from '../../formikToMui';
import { FormElementContainer, Input, InputLabel } from '../Input/Input';

export const TextFieldBase = memo(function TextFieldBase({
  label,
  error,
  hidden,
  containerClassName,
  help,
  ...rest
}: InputBaseComponentProps) {
  return (
    <FormElementContainer
      className={containerClassName}
      hidden={hidden}
    >
      {label ? (
        <InputLabel htmlFor={rest?.id}>
          <Text type={TextType.INPUT_NAME}>
            {label}
            {rest.required ? ' *' : null}
            {help ? <InfoWithTooltip>{help}</InfoWithTooltip> : null}
          </Text>
        </InputLabel>
      ) : null}
      <Input
        {...(rest as InputBaseComponentProps)}
        autoComplete="off"
      />
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormElementContainer>
  );
});

export const TextField = memo<InputBaseComponentProps>(formikToMui(TextFieldBase));
