import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { Layout } from '@amalia/core/layout/components';
import { routes } from '@amalia/core/routes';
import { AmaliaHead } from '@amalia/design-system/ext';
import { RootRoutes } from '@amalia/ext/react-router-dom';
import { ActionsEnum, subject, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { AbilityRouteProtector } from '@amalia/kernel/auth/state';

const PlanAssignmentsContainer = lazy(() => import('./Assignments/PlanAssignmentsContainer'));
const PlanListContainer = lazy(() => import('./Overview/PlanListContainer'));

export const PlansRoutes = memo(function PlansRoutes() {
  const { formatMessage } = useIntl();

  return (
    <Layout currentPage="plans">
      <RootRoutes>
        <Route
          path={routes.PLAN_ASSIGNMENT}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Plan_Assignment)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Plan assignments' })} />
              <PlanAssignmentsContainer />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.PLANS}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, subject(SubjectsEnum.Plan, {}))}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Plans' })} />
              <PlanListContainer />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});
