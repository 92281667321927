import clsx from 'clsx';
import {
  cloneElement,
  type ComponentPropsWithoutRef,
  type ForwardedRef,
  forwardRef,
  memo,
  type ReactNode,
} from 'react';
import { FormattedMessage } from 'react-intl';

import { type MergeAll } from '@amalia/ext/typescript';

import { Tooltip } from '../../overlays/tooltip/Tooltip';
import { IconLoading } from '../icon-loading/IconLoading';
import { type TablerIconElement } from '../icons/types';

import * as styles from './IconButton.styles';
import { IconButtonSize, IconButtonVariant } from './IconButton.types';

const ICON_SIZE_MAPPING_PX: Record<IconButtonSize, number> = {
  [IconButtonSize.SMALL]: 14.4,
  [IconButtonSize.MEDIUM]: 19.2,
  [IconButtonSize.LARGE]: 24,
} as const;

export type IconButtonProps = MergeAll<
  [
    Omit<ComponentPropsWithoutRef<'button'>, 'children'>,
    {
      /** Icon. */
      icon: TablerIconElement;
      /** Label for accessibility. Will be displayed as tooltip and added as aria-label if it's a string. */
      label: ReactNode;
      /** Color. */
      variant?: IconButtonVariant;
      /** Size. */
      size?: IconButtonSize;
      /** Should have background on hover. */
      withBackground?: boolean;
      /** Show a dot on the button. */
      withDot?: boolean;
      /** Force active state. */
      isActive?: boolean;
      /** Force loading state. It will disable the button too. */
      isLoading?: boolean;
      /** While in loading state, show this icon instead. */
      iconLoading?: TablerIconElement;
      /** While in loading state, show this tooltip label instead. */
      labelLoading?: ReactNode;
    },
  ]
>;

const IconButtonForwardRef = forwardRef(function IconButton(
  {
    icon,
    label,
    type = 'button',
    variant = IconButtonVariant.CLASSIC,
    size = IconButtonSize.MEDIUM,
    className = undefined,
    withBackground = false,
    withDot = false,
    isActive = false,
    isLoading = false,
    iconLoading = <IconLoading />,
    labelLoading = <FormattedMessage defaultMessage="Loading…" />,
    disabled = false,
    ...props
  }: IconButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <Tooltip content={isLoading ? labelLoading : label}>
      <button
        {...props}
        ref={ref}
        aria-label={props['aria-label'] ?? (typeof label === 'string' ? label : undefined)}
        css={styles.iconButton}
        disabled={disabled || isLoading}
        type={type}
        className={clsx(className, size, variant, {
          [styles.WITH_BACKGROUND_CLASSNAME]: withBackground,
          [styles.IS_ACTIVE_CLASSNAME]: isActive,
        })}
      >
        {cloneElement(isLoading ? iconLoading : icon, {
          width: ICON_SIZE_MAPPING_PX[size],
          height: ICON_SIZE_MAPPING_PX[size],
          color: disabled || isLoading ? 'currentColor' : (icon.props.color ?? 'currentColor'),
        })}

        {!!withDot && (
          <div
            aria-hidden
            css={styles.dot}
          />
        )}
      </button>
    </Tooltip>
  );
});

export const IconButton = Object.assign(memo(IconButtonForwardRef), {
  Size: IconButtonSize,
  Variant: IconButtonVariant,
});
