import { type Theme, css } from '@emotion/react';

export const kpisTooltipKpiTitleAndValue = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const empty = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[400]};
`;
