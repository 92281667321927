import { type Period } from '@amalia/payout-definition/periods/types';
import { type CustomReport, type CustomReportConfigurationField } from '@amalia/reporting/custom-reports/shared';
import { type Company } from '@amalia/tenants/companies/types';
import { type Team } from '@amalia/tenants/teams/types';
import { type UserContract } from '@amalia/tenants/users/types';

import { type Dashboard } from '../dashboard/dashboard.types';

export enum ChartDisplayContext {
  IN_CONFIGURATION = 'IN_CONFIGURATION',
  IN_DASHBOARD = 'IN_DASHBOARD',
}

export enum ChartType {
  SIMPLE_BAR_CHART = 'SIMPLE_BAR_CHART',
  KPI_CARD_CHART = 'KPI_CARD_CHART',
}

type ChartDisplaySettingsFilters = {
  users: {
    isEnabled: boolean;
    values: UserContract['id'][];
  };
  teams: {
    isEnabled: boolean;
    values: Required<Team>['id'][];
  };
  periods: {
    isEnabled: boolean;
    values: Period['id'][];
  };
};

export type ChartDisplaySettingsForChartType<TChartType extends ChartType> =
  TChartType extends ChartType.SIMPLE_BAR_CHART
    ? {
        xAxis: CustomReportConfigurationField;
        yAxis: CustomReportConfigurationField;
      }
    : TChartType extends ChartType.KPI_CARD_CHART
      ? {
          isDoughnutEnabled: boolean;
          kpi: CustomReportConfigurationField;
        }
      : never;

export type ChartDisplaySettings<TChartType extends ChartType> = ChartDisplaySettingsForChartType<TChartType> & {
  filters: ChartDisplaySettingsFilters;
};

export interface DashboardChart<TChartType extends ChartType> {
  id: string;

  createdAt: Date;

  updatedAt: Date;

  company: Company;

  name: string;

  dashboard: Dashboard;

  dashboardId: Dashboard['id'];

  customReport?: CustomReport;

  customReportId: CustomReport['id'];

  type: TChartType;

  displaySettings: ChartDisplaySettings<TChartType>;
}
