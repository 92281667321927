import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertBanner } from '@amalia/design-system/components';
import { DrawingNotFound } from '@amalia/lib-ui';

import * as styles from './StatementSummaryWidgetQuotaError.styles';

export const StatementSummaryWidgetQuotaError = memo(function StatementSummaryWidgetQuotaError() {
  return (
    <div css={styles.container}>
      <div css={styles.alert}>
        <AlertBanner variant={AlertBanner.Variant.WARNING}>
          <FormattedMessage defaultMessage="Your statement for this period is not available yet due to missing quota value. Please contact your admin for the issue." />
        </AlertBanner>
      </div>

      <DrawingNotFound css={styles.illustration} />
    </div>
  );
});
