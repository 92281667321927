import { makeStyles } from '@mui/styles';

import { type AmaliaThemeType } from '@amalia/ext/mui/theme';

export const useEditorStyles = makeStyles((theme: AmaliaThemeType) => ({
  fieldContainer: {
    marginTop: '0 !important',
  },
  tableBuilderField: {
    width: '100%',
    gridColumn: '1 / 4',
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tableBuilderToggle: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
}));
