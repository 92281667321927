import { css, useTheme } from '@emotion/react';
import { IconHelp } from '@tabler/icons-react';
import { type ReactNode, memo, type ReactElement, type JSXElementConstructor } from 'react';

import { type IconButtonProps } from '../../../general/icon-button/IconButton';
import { TextOverflow } from '../../../general/text-overflow/TextOverflow';
import { Typography } from '../../../general/typography/Typography';
import { Tooltip, type TooltipProps } from '../../../overlays/tooltip/Tooltip';
import { SideMenuItemAction } from '../side-menu-item-action/SideMenuItemAction';

export type SideMenuGroupProps = {
  /** Section label. If not specified, the tooltip and action are ignored. */
  readonly label?: ReactNode;
  /** Section help. */
  readonly tooltip?: TooltipProps['content'];
  /** Option action. */
  readonly action?: ReactElement<IconButtonProps, JSXElementConstructor<IconButtonProps>>;
  /** Section items. */
  readonly children?: ReactNode;
};

const SideMenuGroupBase = function SideMenuGroup({ label, tooltip, action, children }: SideMenuGroupProps) {
  const theme = useTheme();

  return (
    <div
      css={css`
        padding: 0 8px;
      `}
    >
      {!!label && (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 0 ${action ? 6 : 8}px 0 8px;
            height: 32px;
          `}
        >
          <Typography
            as={TextOverflow}
            variant={Typography.Variant.BODY_SMALL_MEDIUM}
            css={(theme) => css`
              color: ${theme.ds.colors.gray[700]};
            `}
          >
            {label}
          </Typography>

          {!!tooltip && (
            <Tooltip content={tooltip}>
              <IconHelp
                color={theme.ds.colors.gray[800]}
                size={14}
                css={css`
                  flex: none;
                `}
              />
            </Tooltip>
          )}

          {!!action && (
            <div
              css={css`
                margin-left: auto;
              `}
            >
              {action}
            </div>
          )}
        </div>
      )}

      <ul
        css={css`
          display: flex;
          flex-direction: column;
          list-style-type: none;
          gap: 1px;
        `}
      >
        {children}
      </ul>
    </div>
  );
};

export const SideMenuGroup = Object.assign(memo(SideMenuGroupBase), {
  Action: SideMenuItemAction,
});
