import { useShallowObjectMemo } from '@amalia/ext/react/hooks';

import { type AvatarUser } from '../Avatar.types';

export const useAvatarUser = ({ firstName, lastName, pictureURL }: AvatarUser) =>
  useShallowObjectMemo({
    firstName,
    lastName,
    pictureURL,
  });
