import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { formatPeriodMarkerDate } from '@amalia/core/types';
import { useSnackbars } from '@amalia/design-system/components';
import { PeriodSelector } from '@amalia/lib-ui-business';
import { StatementsApiClient } from '@amalia/payout-calculation/statements/state';
import { PeriodsApiClient } from '@amalia/payout-definition/api-client';
import { type Period, type PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';
import { type Plan } from '@amalia/payout-definition/plans/types';
import { type UserComputed } from '@amalia/tenants/users/types';

interface StatementPeriodSelectorProps {
  readonly frequency: PeriodFrequencyEnum;
  readonly period: Period;
  readonly plan?: Plan;
  readonly user?: UserComputed;
  readonly isForecast?: boolean;
}

export const StatementPeriodSelector = memo(function StatementPeriodSelector({
  frequency,
  user,
  plan,
  period,
  isForecast,
}: StatementPeriodSelectorProps) {
  const navigate = useNavigate();
  const { snackError } = useSnackbars();
  const { search } = useLocation();

  const goToUserPeriod = useCallback(
    async (dateString: string, route: typeof routes.FORECAST | typeof routes.STATEMENT) => {
      // Search for user statement, then redirect
      const destinationPeriod = await PeriodsApiClient.getPeriodByDate(dateString, frequency);
      const statements = await StatementsApiClient.findStatements(
        destinationPeriod.id,
        plan?.id,
        undefined,
        user?.id ? [user.id] : undefined,
      );

      if (statements.length > 0) {
        navigate(generatePath(route, { id: statements[0].id }));
      } else if (user && plan) {
        navigate(
          generatePath(routes.STATEMENTS_BY_USER_PERIOD, {
            startDate: formatPeriodMarkerDate(destinationPeriod),
            userId: user.id,
            planId: plan.id,
          }),
        );
      } else {
        snackError(<FormattedMessage defaultMessage="Can't navigate: no user nor plan" />);
      }
    },
    [frequency, plan, user, navigate, snackError],
  );

  const onPeriodChange = useCallback(
    async (dateString: string) => {
      const basePath = isForecast ? routes.FORECASTS_BY_DATE : routes.STATEMENTS_BY_DATE;
      const path = isForecast ? routes.FORECAST : routes.STATEMENT;
      if (user) {
        await goToUserPeriod(dateString, path);
      } else {
        navigate({
          pathname: generatePath(basePath, { startDate: dateString }),
          search,
        });
      }
    },
    [isForecast, user, goToUserPeriod, navigate, search],
  );

  return (
    period && (
      <PeriodSelector
        frequency={frequency}
        timestamp={period.startDate}
        onPeriodChange={onPeriodChange}
      />
    )
  );
});
