import { useTheme } from '@emotion/react';
import { IconCreditCard, IconCreditCardPay, IconMessage, IconMessageCheck, IconWriting } from '@tabler/icons-react';
import moment from 'moment';
import { Fragment, memo, type ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { type VariableDefinition } from '@amalia/amalia-lang/tokens/types';
import {
  type Statement,
  type DatasetRow,
  PaymentButtonStatus,
  formatValueOrPrintRemovedLabel,
  formatUserFullName,
  type Overwrite,
} from '@amalia/core/types';
import {
  FieldValuePrettyFormat,
  LabelVariant,
  isFieldValuePrettyFormatCompatible,
} from '@amalia/data-capture/fields/components';
import { type Property } from '@amalia/data-capture/fields/types';
import { type BaseCustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import {
  OVERWRITE_CONTEXT,
  OverwriteModalContainer,
  OverwriteTooltip,
} from '@amalia/data-correction/overwrites/components';
import { Table, Tooltip } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';
import { useCompanyCustomization } from '@amalia/frontend/web-data-layers';
import { useCurrentUser } from '@amalia/kernel/auth/state';
import { PaymentTooltip } from '@amalia/lib-ui-business';
import { type Dataset } from '@amalia/payout-calculation/types';
import { type StatementThread, type StatementThreadScope } from '@amalia/payout-collaboration/comments/types';
import { type PlanRule } from '@amalia/payout-definition/plans/types';

import { type ComputedPlanRuleFieldsToDisplayWithSubTitle } from '../../../RowsTable/RowsTable';
import { type DatasetRecordsDataGridRowIndicatorsProps } from '../../row-indicators/DatasetRecordsDataGridRowIndicators';

import { useDatasetRecordsDataGridCellOverwrite } from './hooks/useDatasetRecordsDataGridCellOverwrite';
import { useDatasetRecordsDataGridCellPayment } from './hooks/useDatasetRecordsDataGridCellPayment';
import { useDatasetRecordsDataGridCellThread } from './hooks/useDatasetRecordsDataGridCellThread';
import { useDatasetRecordsDataGridCellValue } from './hooks/useDatasetRecordsDataGridCellValue';

export type DatasetRecordsDataGridDefaultCellProps = {
  readonly datasetRow: DatasetRow;
  readonly dataset: Dataset;
  readonly statementThreads: Record<string, StatementThread>;
  readonly field: ComputedPlanRuleFieldsToDisplayWithSubTitle;
  readonly rowIndex: number;
  readonly rule?: PlanRule;
  readonly statement?: Statement;
  readonly customObjectDefinition?: BaseCustomObjectDefinition;
  readonly isForecasted?: boolean;
  readonly variableDefinition?: VariableDefinition;
  readonly propertyDefinition?: Property;
  readonly rowHighlightMarker?: ReactElement<DatasetRecordsDataGridRowIndicatorsProps>;
  readonly onOpenStatementThreadPanel: (statementThread?: StatementThread, scope?: StatementThreadScope) => void;
  readonly onClearOverwrite: (overwrite: Overwrite) => void;
  readonly onSubmitOverwrite: (overwrite: unknown) => Promise<void>;
};

export const DatasetRecordsDataGridDefaultCell = memo(function DatasetRecordsDataGridDefaultCell({
  datasetRow,
  dataset,
  statementThreads,
  rule,
  field,
  rowIndex,
  statement = undefined,
  customObjectDefinition = undefined,
  isForecasted = false,
  variableDefinition = undefined,
  propertyDefinition = undefined,
  rowHighlightMarker,
  onOpenStatementThreadPanel,
  onClearOverwrite,
  onSubmitOverwrite,
}: DatasetRecordsDataGridDefaultCellProps) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { data: currentUser } = useCurrentUser();
  const companyCustomization = useCompanyCustomization();

  const { isOverwriteModalOpen, setOverwriteModalOpenTrue, setOverwriteModalOpenFalse } = useBoolState(
    false,
    'overwriteModalOpen',
  );

  const { isThreadEnabled, cellStatementThread, handleOpenThread } = useDatasetRecordsDataGridCellThread({
    datasetRow,
    dataset,
    statementThreads,
    field,
    rule,
    statement,
    customObjectDefinition,
    isForecasted,
    onOpenStatementThreadPanel,
  });

  const { isOverwriteEnabled, cellOverwrite, overwriteDetails, handleClearOverwrite } =
    useDatasetRecordsDataGridCellOverwrite({
      datasetRow,
      dataset,
      field,
      rowIndex,
      rule,
      statement,
      customObjectDefinition,
      isForecasted,
      variableDefinition,
      propertyDefinition,
      onClearOverwrite,
    });

  const { isPaymentEnabled, payment, paymentButtonStatus } = useDatasetRecordsDataGridCellPayment({
    datasetRow,
    statement,
    variableDefinition,
  });

  const { format, rawValue, formattedValue, formattedOverwriteSourceValue, formattedOverwriteValue, truncatedValue } =
    useDatasetRecordsDataGridCellValue({
      datasetRow,
      field,
      variableDefinition,
      propertyDefinition,
      overwrite: cellOverwrite,
    });

  const cellContent = isFieldValuePrettyFormatCompatible(format, propertyDefinition?.ref) ? (
    // @ts-expect-error -- TODO fix this.
    <FieldValuePrettyFormat
      format={format}
      value={rawValue}
      valueRef={propertyDefinition?.ref}
      variant={cellOverwrite ? LabelVariant.ACCENTUATED : LabelVariant.DEFAULT}
    />
  ) : cellOverwrite ? (
    formatValueOrPrintRemovedLabel(formattedValue)
  ) : (
    formattedValue
  );

  return (
    <Fragment>
      <Table.Cell.WithActions
        hideActions={false}
        actions={
          <Fragment>
            {!!isOverwriteEnabled && (
              <Table.Cell.IconAction
                showOnHoverCell
                icon={<IconWriting />}
                aria-label={formatMessage(
                  { defaultMessage: 'Overwrite on {rowExternalId}.values[{column}]' },
                  { rowExternalId: datasetRow.externalId, column: field.name },
                )}
                label={formatMessage(
                  { defaultMessage: 'Overwrite on {rowExternalId}, field {column}' },
                  { rowExternalId: datasetRow.externalId, column: field.name },
                )}
                onClick={setOverwriteModalOpenTrue}
              />
            )}

            {!!isThreadEnabled && (
              <Table.Cell.IconAction
                icon={cellStatementThread?.thread.isReviewed ? <IconMessageCheck /> : <IconMessage />}
                showOnHoverCell={!cellStatementThread || !!cellStatementThread.thread.isReviewed}
                withDot={!!cellStatementThread && !cellStatementThread.thread.isReviewed}
                label={formatMessage(
                  {
                    defaultMessage:
                      'Comment on row {rowExternalId}, field {column}{hasThread, select, true { ({isThreadReviewed, select, true {thread reviewed} other {thread ongoing}})} other {}}',
                  },
                  {
                    rowExternalId: datasetRow.externalId,
                    column: field.name,
                    hasThread: !!cellStatementThread,
                    isThreadReviewed: !!cellStatementThread?.thread.isReviewed,
                  },
                )}
                onClick={handleOpenThread}
              />
            )}

            {!!isPaymentEnabled && (
              <PaymentTooltip
                companyCustomization={companyCustomization}
                formattedValue={formattedValue}
                payment={payment}
                title={variableDefinition.name}
              >
                <Table.Cell.IconAction
                  label=""
                  aria-label={formatMessage(
                    { defaultMessage: 'See payment on row {rowExternalId}, field {column}' },
                    { rowExternalId: datasetRow.externalId, column: field.name },
                  )}
                  icon={
                    paymentButtonStatus === PaymentButtonStatus.paid ? (
                      <IconCreditCard color={theme.ds.colors.success[500]} />
                    ) : (
                      <IconCreditCardPay />
                    )
                  }
                />
              </PaymentTooltip>
            )}

            {rowHighlightMarker}
          </Fragment>
        }
      >
        {cellOverwrite ? (
          <OverwriteTooltip
            author={formatUserFullName(cellOverwrite.creator)}
            cellStatementThread={cellStatementThread}
            date={moment(cellOverwrite.createdAt, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
            handleOnClick={handleClearOverwrite}
            isReadOnly={!isOverwriteEnabled}
            newValue={formattedValue}
            newValueRaw={formattedOverwriteValue}
            oldValue={formattedOverwriteSourceValue}
            openStatementThread={handleOpenThread}
            valueFormat={format}
            valueRef={propertyDefinition?.ref}
          >
            <div data-is-overwritten="true">{cellContent}</div>
          </OverwriteTooltip>
        ) : truncatedValue ? (
          <Tooltip content={truncatedValue}>
            <div>{cellContent}</div>
          </Tooltip>
        ) : (
          cellContent
        )}
      </Table.Cell.WithActions>

      <OverwriteModalContainer
        currentObjectDetails={overwriteDetails}
        currentUser={currentUser}
        handleClose={setOverwriteModalOpenFalse}
        handleSubmit={onSubmitOverwrite}
        isOpen={isOverwriteModalOpen}
        overwriteContext={OVERWRITE_CONTEXT.ROWTABLE}
      />
    </Fragment>
  );
});
