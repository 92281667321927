import { concat, uniq } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import { type DatasetRow, type Overwrite, type PaginatedResponse, type Statement } from '@amalia/core/types';
import { type ColumnSorting, getPageCount, useDataGridState, useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import {
  applyOverwriteToStatementDataset,
  clearOverwriteInStatementDataset,
  fetchUsers,
  useThunkDispatch,
} from '@amalia/frontend/web-data-layers';
import { StatementDatasetsApiClient } from '@amalia/payout-calculation/statements/state';
import { type Dataset, DatasetType } from '@amalia/payout-calculation/types';
import { HidableElementVisibility } from '@amalia/payout-definition/plans/types';

import { type ComputedPlanRuleFieldsToDisplayWithSubTitle } from './RowsTable';

type transformSortOptions = { datasetType?: DatasetType };
// Change sorting fields for some exceptions.
const transformSort = (field?: ColumnSorting, options?: transformSortOptions) => {
  if (!field) return { sort: undefined, desc: undefined };

  if (options?.datasetType === DatasetType.metrics && field.id === 'userId') {
    return { sort: 'userFirstName', desc: field.direction === 'desc' };
  }
  return {
    sort: field.id,
    desc: field.direction === 'desc',
  };
};

const getColumnVisibility = (fields: ComputedPlanRuleFieldsToDisplayWithSubTitle[]) =>
  fields
    .filter((f) => f.displayStatus === HidableElementVisibility.AVAILABLE)
    .reduce(
      (acc, field) => ({
        ...acc,
        [field.name]: false,
      }),
      {},
    );

export const useRowsTableDataFetch = ({
  dataset,
  fields,
  statement,
  datasetRows,
  globalSearchValue,
  forecasted,
}: {
  dataset: Dataset;
  fields: ComputedPlanRuleFieldsToDisplayWithSubTitle[];
  statement?: Statement;
  datasetRows?: DatasetRow[]; // If controlled by the parent.
  globalSearchValue?: string;
  forecasted?: boolean;
}) => {
  const { snackError } = useSnackbars();
  const dispatch = useThunkDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [paginationState, setPaginationState] = useState<PaginatedResponse<DatasetRow> | null>(null);

  const {
    page,
    setPage,
    pageSize,
    setPageSize,
    columnSorting,
    setColumnSorting,
    columnVisibility,
    setColumnVisibility,
    searchText,
    setSearchText,
    columnPinning,
    setColumnPinning,
    columnOrder,
    setColumnOrder,
  } = useDataGridState({
    columnPinning: true,
    searchText: globalSearchValue,
    columnVisibility: getColumnVisibility(fields),
  });

  const [addedRows, setAddedRows] = useState<DatasetRow[]>([]);

  useEffect(() => {
    setSearchText(globalSearchValue || '');
  }, [globalSearchValue, setSearchText]);

  useEffect(() => setColumnVisibility(getColumnVisibility(fields)), [fields, setColumnVisibility]);

  useAsyncEffect(async () => {
    if (datasetRows !== undefined) {
      setPaginationState({
        items: datasetRows,
        totalItems: datasetRows.length,
        pageCount: getPageCount(datasetRows.length, pageSize),
      });
      return;
    }

    if (statement) {
      try {
        setIsLoading(true);
        const res = await StatementDatasetsApiClient.fetchPaginatedDatasetRows(
          statement.id,
          dataset.id,
          {
            // Datagrid counts pages starting at 0, but API starts at 1.
            page: page + 1,
            search: searchText || undefined, // Ignore '' search value.
            limit: pageSize,
            ...transformSort(columnSorting[0], { datasetType: dataset.type }),
          },
          forecasted,
        );

        // If we have a metrics dataset, some users can be mentioned. Go fetch them.
        if (dataset.type === DatasetType.metrics) {
          const usersMentionedInDataset = res.items.map((row) => row.content?.userId as string).filter(Boolean);

          await dispatch(fetchUsers(uniq(usersMentionedInDataset)));
        }

        setPaginationState(res);
        setAddedRows([]);
      } catch (e) {
        snackError(toError(e).message);
      } finally {
        setIsLoading(false);
      }
    }
  }, [
    datasetRows,
    page,
    columnSorting,
    searchText,
    pageSize,
    dataset.id,
    // Create an implicit dependency to the statement, so the
    // dataset reloads if the statement is calculated.
    statement?.updatedAt,
    // And if the user navigates from a statement to another with the same
    // updatedAt. It doesn't happen often, but it does happen (batch workflow
    // operations for instance).
    statement?.id,
    forecasted,
  ]);

  const applyOverwrite = useCallback(
    (overwrite: Overwrite) => {
      const newRows = applyOverwriteToStatementDataset(paginationState.items, overwrite);

      setPaginationState({
        ...paginationState,
        items: newRows,
      });
    },
    [paginationState, setPaginationState],
  );

  const clearOverwrite = useCallback(
    (overwrite: Overwrite) => {
      const newRows = clearOverwriteInStatementDataset(paginationState.items, overwrite);

      setPaginationState({
        ...paginationState,
        items: newRows,
      });
    },
    [paginationState, setPaginationState],
  );

  const pageWithAddedRows: DatasetRow[] = useMemo(
    () => (page === 0 ? concat(addedRows || [], paginationState?.items || []) : paginationState.items || []),
    [addedRows, page, paginationState?.items],
  );

  return {
    paginationState: paginationState ? { totalItems: paginationState.totalItems, rows: pageWithAddedRows } : null,
    isLoading,
    applyOverwrite,
    clearOverwrite,
    datagridState: {
      page,
      setPage,
      pageSize,
      setPageSize,
      columnSorting,
      setColumnSorting,
      searchText,
      setSearchText,
      columnVisibility,
      setColumnVisibility,
      columnPinning,
      setColumnPinning,
      columnOrder,
      setColumnOrder,
    },
    setAddedRows,
  };
};
