import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const kpisContainer = css`
  flex: 1;
`;

export const cardViewGrid = css`
  display: grid;
  grid-template-columns: repeat(auto-fill, 180px);
  gap: 16px;
`;

export const kpiList = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const sectionName = css`
  padding-left: 12px;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
