import { subject } from '@casl/ability';
import {
  IconDots,
  IconMessage,
  IconMessageCheck,
  IconMessagePlus,
  IconPencil,
  IconPlusMinus,
} from '@tabler/icons-react';
import { Fragment, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, Link } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { Group, IconButton, MenuDropdown, RadioButtonGroup } from '@amalia/design-system/components';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { Can } from '@amalia/kernel/auth/state';
import { objectToQs } from '@amalia/lib-ui';

import { StatementExportModal } from '../../../StatementExportModal';
import { StatementViewEnum } from '../../StatementDetail';

import {
  type StatementDetailHeaderActionsProps,
  useStatementDetailHeaderActions,
} from './useStatementDetailHeaderActions';

export const StatementDetailHeaderSecondRow = memo(function StatementDetailHeaderSecondRow(
  props: StatementDetailHeaderActionsProps,
) {
  const {
    statement,
    companyFeatureFlags,
    ability,
    handleForecastedSwitch,
    handleExportModalClose,
    handleExportModalSubmit,
    isWorkflowBegun,
    formatMessage,
    handleNewAdjustment,
    statementCommentThread,
    onStatementCommentsClicked,
    menuIconsList,
    isExportModalOpen,
    isForecastedView,
  } = useStatementDetailHeaderActions(props);
  return (
    <Fragment>
      <Group
        align="center"
        gap={18}
        justify="flex-end"
      >
        {!!companyFeatureFlags.FORECAST && !!statement?.plan?.isForecasted && (
          <RadioButtonGroup
            name="switch-forecast-view"
            value={isForecastedView ? StatementViewEnum.FORECASTED : StatementViewEnum.ACHIEVED}
            options={[
              { value: StatementViewEnum.ACHIEVED, label: <FormattedMessage defaultMessage="Achieved" /> },
              { value: StatementViewEnum.FORECASTED, label: <FormattedMessage defaultMessage="Forecasted" /> },
            ]}
            onChange={handleForecastedSwitch}
          />
        )}

        <Group
          align="center"
          gap={8}
        >
          {ability.can(ActionsEnum.adjust, SubjectsEnum.Statement) && (
            <IconButton
              withBackground
              data-testid="new-adjustment"
              disabled={isWorkflowBegun || isForecastedView}
              icon={<IconPlusMinus />}
              label={formatMessage({ defaultMessage: 'Make an adjustment' })}
              onClick={handleNewAdjustment}
            />
          )}
          {ability.can(ActionsEnum.view_statement_threads, subject(SubjectsEnum.Statement, statement)) && (
            <IconButton
              withBackground
              data-testid="comment-statement"
              disabled={isForecastedView}
              label={formatMessage({ defaultMessage: 'Comments' })}
              icon={
                statementCommentThread ? (
                  statementCommentThread.thread.isReviewed ? (
                    <IconMessageCheck />
                  ) : (
                    <IconMessagePlus />
                  )
                ) : (
                  <IconMessage />
                )
              }
              onClick={onStatementCommentsClicked}
            />
          )}

          <Can
            a={SubjectsEnum.Plan}
            I={ActionsEnum.modify}
          >
            <Link
              to={{
                pathname: generatePath(routes.DESIGNER_PLAN, {
                  planId: statement.plan.id,
                }),
                search: `?${objectToQs({
                  userId: statement.user.id,
                  periodId: statement.period.id,
                })}`,
              }}
            >
              <IconButton
                withBackground
                icon={<IconPencil />}
                label={formatMessage({ defaultMessage: 'Edit in Designer' })}
              />
            </Link>
          </Can>

          <MenuDropdown
            content={
              <div>
                {menuIconsList.map((action) => (
                  <MenuDropdown.Item
                    key={action.label}
                    icon={action.icon}
                    label={action.label}
                    onClick={action.handleClick}
                  />
                ))}
              </div>
            }
          >
            <MenuDropdown.IconButton
              withBackground
              icon={<IconDots />}
              label={formatMessage({ defaultMessage: 'More actions' })}
            />
          </MenuDropdown>
        </Group>
      </Group>

      <StatementExportModal
        handleClose={handleExportModalClose}
        handleConfirm={handleExportModalSubmit}
        isOpened={isExportModalOpen}
        statement={statement}
      />
    </Fragment>
  );
});
