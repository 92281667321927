import { combineReducers } from 'redux';

import { INITIAL_REDUX_PAGINATION, type ReduxAction } from '../types';

import { OVERWRITES_ACTIONS } from './constants';
import { type OverwritesReducer } from './types';

export default combineReducers<OverwritesReducer, ReduxAction>({
  isLoading: (state = 0, action) => {
    switch (action.type) {
      case OVERWRITES_ACTIONS.START:
        return state + 1;
      case OVERWRITES_ACTIONS.ERROR:
      case OVERWRITES_ACTIONS.CLEAR_OVERWRITE:
      case OVERWRITES_ACTIONS.SET_OVERWRITES:
        return state - 1;
      default:
        return state;
    }
  },
  // @ts-expect-error -- Shit's broken.
  listDetails: (state = INITIAL_REDUX_PAGINATION, action) => {
    switch (action.type) {
      case OVERWRITES_ACTIONS.SET_OVERWRITES:
        return action.payload.list;
      default:
        return state;
    }
  },
  pageOptions: (state = {}, action) => {
    switch (action.type) {
      case OVERWRITES_ACTIONS.SET_OVERWRITES:
        return action.payload.pageOptions;
      default:
        return state;
    }
  },
});
