import { type VariantType, useSnackbar, type SnackbarMessage, type OptionsObject } from 'notistack';
import { useCallback, useMemo } from 'react';

export const useSnackbars = () => {
  const { enqueueSnackbar } = useSnackbar();

  const makeSnackFunction = useCallback(
    (variant: VariantType) =>
      (message: SnackbarMessage, moreOptions: Omit<OptionsObject, 'variant'> = {}) =>
        enqueueSnackbar(message, { variant, ...moreOptions }),
    [enqueueSnackbar],
  );

  const snackSuccess = useMemo(() => makeSnackFunction('success'), [makeSnackFunction]);
  const snackError = useMemo(() => makeSnackFunction('error'), [makeSnackFunction]);
  const snackInfo = useMemo(() => makeSnackFunction('info'), [makeSnackFunction]);
  const snackWarning = useMemo(() => makeSnackFunction('warning'), [makeSnackFunction]);

  return {
    snackSuccess,
    snackError,
    snackInfo,
    snackWarning,
  };
};
