import { type Theme, css } from '@emotion/react';

import { IconButtonSize, IconButtonVariant } from './IconButton.types';

export const WITH_BACKGROUND_CLASSNAME = 'background';
export const IS_ACTIVE_CLASSNAME = 'isActive';

export const iconButton = (theme: Theme) => css`
  display: flex;
  align-items: center;
  justify-content: center;

  flex: none;

  background-color: transparent;
  color: ${theme.ds.colors.gray[800]};

  transition: ${theme.ds.transitions.default(
    'background-color',
    'box-shadow',
    'color',
    'border-radius',
    'width',
    'height',
  )};

  border: none;
  border-radius: ${theme.ds.borderRadiuses.round};

  cursor: pointer;

  position: relative;

  &:disabled {
    cursor: not-allowed;

    color: ${theme.ds.colors.gray[300]};

    &.${WITH_BACKGROUND_CLASSNAME} {
      background-color: ${theme.ds.colors.gray[50]};
    }
  }

  &:not(:disabled) {
    &:focus-visible {
      outline-style: solid;
      outline-width: 1px;
      outline-offset: -1px;
    }

    &.${IconButtonSize.LARGE} {
      outline-width: 2px;
      outline-offset: -2px;
    }

    &.${IconButtonVariant.CLASSIC} {
      &:hover,
      &:active,
      &.${IS_ACTIVE_CLASSNAME} {
        background-color: ${theme.ds.colors.primary[50]};
      }

      &:active,
      &.${IS_ACTIVE_CLASSNAME} {
        color: ${theme.ds.colors.primary[500]};
      }

      &:focus-visible {
        outline-color: ${theme.ds.colors.primary[200]};

        &.${IconButtonSize.SMALL} {
          box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.primary[50])};
        }

        &.${IconButtonSize.MEDIUM} {
          box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.primary[50])};
        }

        &.${IconButtonSize.LARGE} {
          box-shadow: ${theme.ds.shadows.focusLarge(theme.ds.colors.primary[50])};
        }
      }
    }

    &.${IconButtonVariant.DANGER} {
      &:hover,
      &:active,
      &.${IS_ACTIVE_CLASSNAME} {
        background-color: ${theme.ds.colors.danger[50]};
      }

      &:active,
      &.${IS_ACTIVE_CLASSNAME} {
        color: ${theme.ds.colors.danger[700]};
      }

      &:focus-visible {
        outline-color: ${theme.ds.colors.danger[500]};

        &.${IconButtonSize.SMALL} {
          box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.danger[100])};
        }

        &.${IconButtonSize.MEDIUM} {
          box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.danger[100])};
        }

        &.${IconButtonSize.LARGE} {
          box-shadow: ${theme.ds.shadows.focusLarge(theme.ds.colors.danger[100])};
        }
      }
    }

    &.${IconButtonVariant.SUCCESS} {
      &:hover,
      &:active,
      &.${IS_ACTIVE_CLASSNAME} {
        background-color: ${theme.ds.colors.success[50]};
      }

      &:active,
      &.${IS_ACTIVE_CLASSNAME} {
        color: ${theme.ds.colors.success[700]};
      }

      &:focus-visible {
        outline-color: ${theme.ds.colors.success[500]};

        &.${IconButtonSize.SMALL} {
          box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.success[100])};
        }

        &.${IconButtonSize.MEDIUM} {
          box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.success[100])};
        }

        &.${IconButtonSize.LARGE} {
          box-shadow: ${theme.ds.shadows.focusLarge(theme.ds.colors.success[100])};
        }
      }
    }
  }

  &.${WITH_BACKGROUND_CLASSNAME} {
    background-color: ${theme.ds.colors.gray[0]};
    box-shadow: ${theme.ds.shadows.hard};
    border-radius: ${theme.ds.borderRadiuses.squared};
  }

  &.${IconButtonSize.SMALL} {
    height: 24px;
    width: 24px;
  }

  &.${IconButtonSize.MEDIUM} {
    height: 32px;
    width: 32px;
  }

  &.${IconButtonSize.LARGE} {
    height: 40px;
    width: 40px;
  }
`;

export const dot = (theme: Theme) => css`
  display: flex;
  background-color: ${theme.ds.colors.danger[500]};

  position: absolute;
  border-radius: ${theme.ds.borderRadiuses.round};

  transition: ${theme.ds.transitions.default('background-color', 'width', 'height', 'bottom', 'right')};

  button:disabled > & {
    background-color: ${theme.ds.colors.gray[400]};
  }

  button.${IconButtonSize.SMALL} > & {
    height: 6px;
    width: 6px;

    right: 4px;
    bottom: 4px;
  }

  button.${IconButtonSize.MEDIUM} > & {
    height: 8px;
    width: 8px;

    right: 6px;
    bottom: 6px;
  }

  button.${IconButtonSize.LARGE} > & {
    height: 10px;
    width: 10px;

    right: 8px;
    bottom: 8px;
  }
`;
