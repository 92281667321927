import { css } from '@emotion/react';
import { forwardRef, memo } from 'react';

import { Button, type ButtonProps } from '../../../general/button/Button';

export type DropdownActionProps = Omit<ButtonProps, 'size' | 'variant'>;

const DropdownActionForwardRef = forwardRef<HTMLButtonElement, DropdownActionProps>(function DropdownAction(
  { children, ...props },
  ref,
) {
  return (
    <Button
      {...props}
      ref={ref}
      size={Button.Size.MEDIUM}
      variant={Button.Variant.PRIMARY_TEXT}
      css={css`
        width: 100%;
        justify-content: center;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      `}
    >
      {children}
    </Button>
  );
});

export const DropdownAction = memo(DropdownActionForwardRef);
