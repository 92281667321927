import { keyBy } from 'lodash';
import { combineReducers } from 'redux';

import { type UserContract } from '@amalia/tenants/users/types';

import { type ReduxAction } from '../types';

import { USERS_ACTIONS } from './constants';
import { type UsersReducer } from './types';

export type UsersActionPayload = {
  users?: UserContract[];
  allUsers?: boolean;
  activeUsers?: boolean;
};

export default combineReducers<UsersReducer, ReduxAction>({
  isLoading: (state = 0, action) => {
    switch (action.type) {
      case USERS_ACTIONS.START:
        return state + 1;
      case USERS_ACTIONS.ERROR:
      case USERS_ACTIONS.SET_USERS:
        return state - 1;
      default:
        return state;
    }
  },
  map: (state = {}, action) => {
    switch (action.type) {
      case USERS_ACTIONS.FLUSH_USERS:
        return {};
      case USERS_ACTIONS.SET_USERS:
        return {
          // Destructuring state to clone it.
          ...state,
          // Make a keyBy object with new users, then destructure it to
          // merge it with the previous state.
          ...keyBy(action.payload.users, 'id'),
        };
      default:
        return state;
    }
  },
  allUsersLoaded: (state = false, action) => {
    switch (action.type) {
      case USERS_ACTIONS.SET_USERS:
        return action.payload.allUsers || state;
      case USERS_ACTIONS.FLUSH_USERS:
        return false;
      default:
        return state;
    }
  },
  allActiveUsersLoaded: (state = false, action) => {
    switch (action.type) {
      case USERS_ACTIONS.SET_USERS:
        return action.payload.activeUsers || state;
      case USERS_ACTIONS.FLUSH_USERS:
        return false;
      default:
        return state;
    }
  },
});
