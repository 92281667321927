import { css, type Theme } from '@emotion/react';

export const HAS_REMOVE_BUTTON_CLASSNAME = 'hasRemoveButton';
export const HAS_ERROR_CLASSNAME = 'hasError';
export const HAS_BADGE_CLASSNAME = 'hasBadge';
export const DISABLED_CLASSNAME = 'disabled';
export const IS_LABEL_HOVERED_CLASSNAME = 'isLabelHovered';
export const IS_DRAFT_CLASSNAME = 'isDraft';
export const IS_ACTIVE_CLASSNAME = 'isActive';
export const IS_FOCUS_VISIBLE_CLASSNAME = 'isFocusVisible';

export const tag = (theme: Theme) => css`
  display: inline-flex;
  // To have the remove button on the left and keep the tab order tag > delete button.
  flex-direction: row-reverse;
  align-items: center;
  gap: 6px;

  padding: 2px 11px;

  border: 1px solid ${theme.ds.colors.gray[100]};
  border-radius: ${theme.ds.borderRadiuses.squared};

  background-color: ${theme.ds.colors.gray[0]};
  color: ${theme.ds.colors.gray[900]};

  /* No transition on padding to avoid bouncing effect when an error pops. */
  transition: ${theme.ds.transitions.default('background-color', 'border-color', 'color', 'box-shadow')};

  &.${HAS_REMOVE_BUTTON_CLASSNAME} {
    padding-left: 7px;
  }

  &.${HAS_ERROR_CLASSNAME} {
    padding-right: 7px;

    &.${IS_FOCUS_VISIBLE_CLASSNAME} {
      outline-color: ${theme.ds.colors.danger[500]};
      box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.danger[100])};
    }
  }

  &.${HAS_BADGE_CLASSNAME} {
    padding-right: 7px;
  }

  &.${DISABLED_CLASSNAME} {
    background-color: ${theme.ds.colors.gray[50]};
    color: ${theme.ds.colors.gray[300]};
  }

  &.${IS_FOCUS_VISIBLE_CLASSNAME} {
    outline: 1px solid ${theme.ds.colors.primary[200]};
    outline-offset: -1px;
    box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.primary[50])};
  }

  &:not(.${DISABLED_CLASSNAME}) {
    &.${HAS_ERROR_CLASSNAME} {
      background-color: ${theme.ds.colors.danger[50]};
      border-color: ${theme.ds.colors.danger[50]};
      color: ${theme.ds.colors.danger[700]};
    }

    &:not(.${HAS_ERROR_CLASSNAME}) {
      &.${IS_DRAFT_CLASSNAME}, &.${IS_ACTIVE_CLASSNAME} {
        color: ${theme.ds.colors.primary[500]};
      }

      &.${IS_LABEL_HOVERED_CLASSNAME}, &.${IS_DRAFT_CLASSNAME}, &.${IS_ACTIVE_CLASSNAME} {
        background-color: ${theme.ds.colors.primary[50]};
        border-color: ${theme.ds.colors.primary[50]};
      }
    }
  }
`;

export const label = (theme: Theme) => css`
  flex: 1;

  cursor: pointer;

  transition: ${theme.ds.transitions.default()};

  &:disabled {
    cursor: not-allowed;
    color: ${theme.ds.colors.gray[300]};
  }

  &:not(:disabled) {
    *:not(.${HAS_ERROR_CLASSNAME}).${IS_DRAFT_CLASSNAME} & {
      color: ${theme.ds.colors.primary[200]};
    }
  }
`;

export const error = (theme: Theme) => css`
  flex: none;

  display: flex;
  align-items: center;

  > svg {
    transition: ${theme.ds.transitions.default()};
  }
`;
