import { http } from '@amalia/core/http/client';
import { type TeamContract, type TeamNode } from '@amalia/tenants/teams/types';

export class TeamsApiClient {
  public static async getTeamHierarchy(showArchived: boolean = false): Promise<TeamNode[]> {
    const { data } = await http.get<TeamNode[]>('/teams/hierarchy', { params: { showArchived } });
    return data;
  }

  public static async createTeam(team: Partial<TeamContract>): Promise<TeamContract> {
    const { data } = await http.post<TeamContract>('/teams/', team);
    return data;
  }

  public static async updateTeam(team: Partial<TeamContract>): Promise<TeamContract> {
    const { data } = await http.put<TeamContract>('/teams/', team);
    return data;
  }

  public static async deleteTeam(id: string): Promise<TeamContract> {
    const { data } = await http.delete<TeamContract>(`/teams/${id}`);
    return data;
  }

  public static async getTeams(userIds?: string[], planIds?: string[]): Promise<TeamContract[]> {
    const qs = [
      ...(userIds ? userIds.map((u) => `userIds=${u}`) : []),
      ...(planIds ? planIds.map((u) => `planIds=${u}`) : []),
    ]
      .filter(Boolean)
      .join('&');

    const { data } = await http.get<TeamContract[]>(`/teams?${qs}`);
    return data;
  }

  public static async getTeam(teamId: string): Promise<TeamContract> {
    const { data } = await http.get<TeamContract>(`/teams/team/${teamId}`);
    return data;
  }
}
