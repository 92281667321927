import { http } from '@amalia/core/http/client';
import { config } from '@amalia/kernel/config/client';
import { type FileDownloadRequest } from '@amalia/reporting/exports/shared';

const apiEndpoint = '/downloads';

// Called from a service when asking for a file download.
// Opens a new tab with the token in the URL to launch download
export async function downloadFileViaToken(fileDownloadRequest: FileDownloadRequest) {
  // Process:
  // 1: user clicks on button
  // 2: the handler generate a FileDownloadRequest with a type (related entity, for example DATA_EXPORT) and a dataId (id of that entity)
  // 3: the handler calls this function
  // 4: sending the request to the API, it generates a token
  // 5: it verifies right for the authenticated user on the requested object
  // 6: it returns the token here
  // 7 : we open a new tab passing the token
  // 8: the file-download cloud run verifies the token's integrity
  // 9: it sends the file for download as a stream
  // 10: the browser downloads it

  const response = await http.post(apiEndpoint, fileDownloadRequest);
  window.open(`${config.apps.fileDownloadUrl}/downloads?token=${response.data}`, '_blank');
}
