import { css, type Theme } from '@emotion/react';

export const ICON_CLASSNAME = 'snackbarIcon';

export const snackbar = (theme: Theme) => css`
  display: flex;
  align-items: center;
  gap: 16px;

  padding: 11px 15px;

  border-radius: ${theme.ds.borderRadiuses.squared};
  border: 1px solid transparent;

  box-shadow: ${theme.ds.shadows.hard};

  transition: ${theme.ds.transitions.default()};

  &.info,
  &.default {
    background-color: ${theme.ds.colors.info[50]};
    border-color: ${theme.ds.colors.info[500]};
    color: ${theme.ds.colors.info[900]};

    .${ICON_CLASSNAME} {
      color: ${theme.ds.colors.info[500]};
    }
  }

  &.warning {
    background-color: ${theme.ds.colors.warning[50]};
    border-color: ${theme.ds.colors.warning[500]};
    color: ${theme.ds.colors.warning[900]};

    .${ICON_CLASSNAME} {
      color: ${theme.ds.colors.warning[500]};
    }
  }

  &.success {
    background-color: ${theme.ds.colors.success[50]};
    border-color: ${theme.ds.colors.success[500]};
    color: ${theme.ds.colors.success[900]};

    .${ICON_CLASSNAME} {
      color: ${theme.ds.colors.success[500]};
    }
  }

  &.error {
    background-color: ${theme.ds.colors.danger[50]};
    border-color: ${theme.ds.colors.danger[500]};
    color: ${theme.ds.colors.danger[900]};

    .${ICON_CLASSNAME} {
      color: ${theme.ds.colors.danger[500]};
    }
  }
`;

export const snackbarContent = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const text = css`
  word-break: break-word;
`;

export const closeButton = css`
  display: flex;
`;
