import { css } from '@emotion/react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@amalia/design-system/components';

import { type CommentScopeInterface } from './useCommentScope';

const scopeLine = css`
  display: flex;
  gap: 4px;
`;

type CommentScopeProps = {
  readonly commentScope: CommentScopeInterface;
};

export const CommentScope = memo(function CommentContext({ commentScope }: CommentScopeProps) {
  return (
    <div>
      {!!commentScope.rule && (
        <div css={scopeLine}>
          <Typography variant={Typography.Variant.BODY_SMALL_MEDIUM}>
            <FormattedMessage
              defaultMessage="Rule: {value}"
              values={{
                value: <Typography variant={Typography.Variant.BODY_SMALL_REGULAR}>{commentScope.rule}</Typography>,
              }}
            />
          </Typography>
        </div>
      )}
      {!!commentScope.dataset && (
        <div css={scopeLine}>
          <Typography variant={Typography.Variant.BODY_SMALL_MEDIUM}>
            <FormattedMessage
              defaultMessage="Dataset: {value}"
              values={{
                value: <Typography variant={Typography.Variant.BODY_SMALL_REGULAR}>{commentScope.dataset}</Typography>,
              }}
            />
          </Typography>
        </div>
      )}
      {!!commentScope.variable && (
        <div css={scopeLine}>
          <Typography variant={Typography.Variant.BODY_SMALL_MEDIUM}>
            {commentScope.dataSource?.label ? (
              <FormattedMessage
                defaultMessage="Field: {value}"
                values={{
                  value: (
                    <Typography variant={Typography.Variant.BODY_SMALL_REGULAR}>{commentScope.variable}</Typography>
                  ),
                }}
              />
            ) : (
              <FormattedMessage
                defaultMessage="KPI: {value}"
                values={{
                  value: (
                    <Typography variant={Typography.Variant.BODY_SMALL_REGULAR}>{commentScope.variable}</Typography>
                  ),
                }}
              />
            )}
          </Typography>
        </div>
      )}
      {!!commentScope.dataSource?.label && (
        <div css={scopeLine}>
          <Typography variant={Typography.Variant.BODY_SMALL_MEDIUM}>
            <FormattedMessage
              defaultMessage="Record: {value}"
              values={{
                value: (
                  <Typography variant={Typography.Variant.BODY_SMALL_REGULAR}>
                    {commentScope.dataSource.label}
                  </Typography>
                ),
              }}
            />
          </Typography>
        </div>
      )}
    </div>
  );
});
