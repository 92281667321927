import { css, type Theme } from '@emotion/react';

export const IS_SUB_SUMMARY_CLASSNAME = 'is-sub-summary';

export const ruleAccordionHeader = (theme: Theme) => css`
  padding: 12px 16px 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  transition: ${theme.ds.transitions.default('box-shadow')};
  cursor: pointer;

  &:focus-visible {
    outline-offset: -1px;
    outline: 1px solid ${theme.ds.colors.primary[200]};
    box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.primary[50])};
  }

  &.${IS_SUB_SUMMARY_CLASSNAME} {
    cursor: auto;
  }

  &:not(.${IS_SUB_SUMMARY_CLASSNAME}) {
    border-radius: ${theme.ds.borderRadiuses.squared};
    min-height: 68px;
  }
`;

export const leftContainer = css`
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: 0; // For text-overflow.
`;

export const labelDescriptionContainer = css`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0; // For text-overflow.
`;

export const labelContainer = css`
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 0; // For text-overflow.
`;

export const chevronIcon = (theme: Theme) => css`
  flex: none;
  transition: ${theme.ds.transitions.default('transform')};
  color: ${theme.ds.colors.gray[800]};
`;

export const rightContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const kpisContainer = css`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const actionsContainer = (theme: Theme) => css`
  transition: ${theme.ds.transitions.default('opacity')};

  [data-hide-actions='true'] & {
    opacity: 0;
  }

  [data-hide-actions='true']:hover & {
    opacity: 1;
  }

  /* For alignment between all rules if one has no actions. */
  &:empty {
    width: 24px;
  }
`;
