import styled from '@emotion/styled';

import { Paper } from '../../layout/paper/Paper';

import { CardActions } from './card-actions/CardActions';
import { CardFooter } from './card-footer/CardFooter';

export const Card = Object.assign(
  styled(Paper.withComponent('article'))`
    ${({ theme }) => theme.ds.typographies.bodyBaseRegular};
  `,
  {
    Actions: CardActions,
    Footer: CardFooter,
  },
);
