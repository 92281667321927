import { memo } from 'react';
import { type EmptyObject } from 'type-fest';

import { Skeleton } from '../../../../feedback/skeleton/Skeleton';
import { TableDataCellContent } from '../../layout/table-data-cell-content/TableDataCellContent';

const SKELETON_PLACEHOLDER = 'Loremipsum';

export type CellLoadingProps = EmptyObject;

export const CellLoading = memo(function CellLoading() {
  return (
    <TableDataCellContent>
      <Skeleton shape={Skeleton.Shape.ROUND}>{SKELETON_PLACEHOLDER}</Skeleton>
    </TableDataCellContent>
  );
});
