import { css } from '@emotion/react';
import { memo } from 'react';

import { IconPlanVisibility } from './icons/IconPlanVisibility';

export type PlanNameWithVisibilityProps = {
  readonly planName: string;
  readonly isPlanHidden: boolean;
};

export const PlanNameWithVisibility = memo(function PlanNameWithVisibility({
  planName,
  isPlanHidden,
}: PlanNameWithVisibilityProps) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 8px;
      `}
    >
      <IconPlanVisibility isPlanHidden={isPlanHidden} /> {planName}
    </div>
  );
});
