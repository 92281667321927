import { css } from '@emotion/react';

export const formulaPortal = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const nameFieldContainer = css`
  display: flex;
  flex-direction: row-reverse; // Setting as row-reverse so pressing tab from the condition name will go to formula instead of hide button.
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const inputContainer = css`
  flex: 1;
  max-width: 400px;
`;

export const formulaEditor = css`
  word-break: break-word;

  > .ProseMirror {
    min-height: 80px;
  }
`;
