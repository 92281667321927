import { assert, toError } from '@amalia/ext/typescript';
import { PlansApiClient } from '@amalia/payout-definition/api-client';
import {
  type PlanRule,
  type PlanRuleCategory,
  type PlanRuleFilterToDisplay,
  type PlanRuleFieldToDisplay,
  type PlanCategory,
  type Plan,
  type PlanRuleKpiSection,
  type HighlightedKpiIdentifier,
  type CreateHighlightedKpiRequest,
} from '@amalia/payout-definition/plans/types';

import * as PlanRuleCategoriesRepository from '../../services/plans/planRuleCategories.repository';
import { type ThunkResult } from '../types';

import { PLANS_ACTIONS } from './constants';
import { selectCurrentPlan, selectPlansMap, selectPlansMapIsFullyLoaded } from './selectors';
import {
  type PlansSetPlansAction,
  type PlansErrorAction,
  type PlansStartAction,
  type PlansDeletePlanAction,
  type PlansSetCurrentPlanAction,
  type PlansSetHighlightedKpiAction,
  type PlansDeleteHighlightedKpiAction,
  type PlansRenamePlanAction,
  type PlansSetSettingsAction,
  type PlansAddPlanRulesAction,
  type PlansPatchRulesAction,
  type PlansPatchRuleKpisToDisplayAction,
  type PlansPatchRuleKpiToDisplayStatusAction,
  type PlansPatchRuleRemoveKpiToDisplayAction,
  type PlansPatchRuleFiltersToDisplayAction,
  type PlansPatchRuleFilterToDisplayStatusAction,
  type PlansPatchRuleRemoveFilterToDisplayAction,
  type PlansPatchRuleFieldsToDisplayAction,
  type PlansPatchRuleFieldToDisplayStatusAction,
  type PatchRuleRemoveFieldToDisplayAction,
  type PlansRemoveRuleAction,
  type PlansRemoveRuleByIdAction,
  type PlansCreateCategoryAction,
  type PlansDeleteCategoryAction,
  type PlansEditCategoryAction,
  type PlansMoveCategoryAction,
  type PlansMoveRuleAction,
  type PlansEditVisibilityAction,
  type PlansArchivePlanAction,
  type PlansSetCategoriesAction,
} from './types';

const plansStart = (): PlansStartAction => ({
  type: PLANS_ACTIONS.START,
});

const plansError = (error: Error): PlansErrorAction => ({
  type: PLANS_ACTIONS.ERROR,
  error,
});

const setPlans = (plans: Plan[]): PlansSetPlansAction => ({
  type: PLANS_ACTIONS.SET_PLANS,
  payload: { plans },
});

export const fetchPlans =
  (forceFetch: boolean = false): ThunkResult<Promise<PlansErrorAction | PlansSetPlansAction>> =>
  async (dispatch, getState) => {
    dispatch(plansStart());
    const isPlanMapFullyLoaded = selectPlansMapIsFullyLoaded(getState());
    const plansMap = selectPlansMap(getState());

    if (isPlanMapFullyLoaded && !forceFetch) {
      return dispatch(setPlans(Object.values(plansMap)));
    }

    try {
      const plans = await PlansApiClient.list();
      return dispatch(setPlans(plans));
    } catch (error) {
      return dispatch(plansError(toError(error)));
    }
  };

const removeDeletedPlan = (planId: string): PlansDeletePlanAction => ({
  type: PLANS_ACTIONS.DELETE_PLAN,
  payload: { planId },
});

export const deletePlan =
  (planToDeleteId: string): ThunkResult<Promise<PlansDeletePlanAction | PlansErrorAction>> =>
  async (dispatch) => {
    dispatch(plansStart());

    try {
      await PlansApiClient.delete(planToDeleteId);
      return dispatch(removeDeletedPlan(planToDeleteId));
    } catch (error) {
      return dispatch(plansError(toError(error)));
    }
  };

const setCurrentPlan = (plan: Plan | null): PlansSetCurrentPlanAction => ({
  type: PLANS_ACTIONS.SET_CURRENT_PLAN,
  payload: { plan },
});

export const fetchPlan =
  (planId: string | null): ThunkResult<Promise<PlansErrorAction | PlansSetCurrentPlanAction>> =>
  async (dispatch) => {
    dispatch(plansStart());
    try {
      if (planId) {
        const plan = await PlansApiClient.get(planId);
        return dispatch(setCurrentPlan(plan));
      }
      return dispatch(setCurrentPlan(null));
    } catch (error) {
      return dispatch(plansError(toError(error)));
    }
  };

export const clearCurrentPlan = (): ThunkResult<Promise<PlansSetCurrentPlanAction>> => (dispatch) => {
  dispatch(plansStart());
  return Promise.resolve(dispatch(setCurrentPlan(null)));
};

// ================== HIGHLIGHTED KPIS ==================

const setHighlightedKpi = (
  kpi: CreateHighlightedKpiRequest & { identifier: HighlightedKpiIdentifier },
): PlansSetHighlightedKpiAction => ({
  type: PLANS_ACTIONS.SET_HIGHLIGHTED_KPI,
  payload: { kpi },
});

export const addHighlightedKpi =
  (
    planId: string,
    kpi: CreateHighlightedKpiRequest & { identifier: HighlightedKpiIdentifier },
  ): ThunkResult<Promise<PlansErrorAction | PlansSetHighlightedKpiAction>> =>
  async (dispatch) => {
    dispatch(plansStart());

    try {
      await PlansApiClient.upsertHighlightedKpi(planId, kpi);
      return dispatch(setHighlightedKpi(kpi));
    } catch (error) {
      return dispatch(plansError(toError(error)));
    }
  };

const deleteHighlightedKpiAction = (identifier: HighlightedKpiIdentifier): PlansDeleteHighlightedKpiAction => ({
  type: PLANS_ACTIONS.DELETE_HIGHLIGHTED_KPI,
  payload: { identifier },
});

export const deleteHighlightedKpi =
  (
    planId: string,
    identifier: HighlightedKpiIdentifier,
  ): ThunkResult<Promise<PlansDeleteHighlightedKpiAction | PlansErrorAction>> =>
  async (dispatch) => {
    dispatch(plansStart());
    try {
      await PlansApiClient.deleteHighlightedKpi(planId, identifier);
      return dispatch(deleteHighlightedKpiAction(identifier));
    } catch (error) {
      return dispatch(plansError(toError(error)));
    }
  };

// ================== PLAN + PLAN RULES ==================

const renamePlan = (name: string, planId: string): PlansRenamePlanAction => ({
  type: PLANS_ACTIONS.RENAME_PLAN,
  payload: { name, planId },
});

const setSettings = (settings: Partial<Plan>): PlansSetSettingsAction => ({
  type: PLANS_ACTIONS.SET_SETTINGS,
  payload: { settings },
});

const addRulesToPlan = (rules: Partial<PlanRule>[]): PlansAddPlanRulesAction => ({
  type: PLANS_ACTIONS.ADD_PLAN_RULES,
  payload: { rules },
});

const patchRules = (rules: PlanRule[]): PlansPatchRulesAction => ({
  type: PLANS_ACTIONS.ON_PATCH_RULES,
  payload: { rules },
});

const patchRuleKpisToDisplay = (
  ruleId: string,
  kpisToDisplay: PlanRuleKpiSection[],
): PlansPatchRuleKpisToDisplayAction => ({
  type: PLANS_ACTIONS.ON_PATCH_RULE_KPIS_TO_DISPLAY,
  payload: { ruleId, kpisToDisplay },
});

const patchRuleKpiToDisplayStatus = (
  ruleId: string,
  kpiToDisplayId: string,
): PlansPatchRuleKpiToDisplayStatusAction => ({
  type: PLANS_ACTIONS.ON_PATCH_RULE_KPI_TO_DISPLAY_STATUS,
  payload: { ruleId, kpiToDisplayId },
});

const patchRuleRemoveKpiToDisplay = (
  ruleId: string,
  kpiToDisplayId: string,
): PlansPatchRuleRemoveKpiToDisplayAction => ({
  type: PLANS_ACTIONS.ON_PATCH_RULE_REMOVE_KPI_TO_DISPLAY,
  payload: { ruleId, kpiToDisplayId },
});

const patchRuleFiltersToDisplay = (
  ruleId: string,
  filtersToDisplay: PlanRuleFilterToDisplay[],
): PlansPatchRuleFiltersToDisplayAction => ({
  type: PLANS_ACTIONS.ON_PATCH_RULE_FILTERS_TO_DISPLAY,
  payload: { ruleId, filtersToDisplay },
});

const patchRuleFilterToDisplayStatus = (
  ruleId: string,
  filterToDisplayId: string,
): PlansPatchRuleFilterToDisplayStatusAction => ({
  type: PLANS_ACTIONS.ON_PATCH_RULE_FILTER_TO_DISPLAY_STATUS,
  payload: { ruleId, filterToDisplayId },
});

const patchRuleRemoveFilterToDisplay = (
  ruleId: string,
  filterToDisplayId: string,
): PlansPatchRuleRemoveFilterToDisplayAction => ({
  type: PLANS_ACTIONS.ON_PATCH_RULE_REMOVE_FILTER_TO_DISPLAY,
  payload: { ruleId, filterToDisplayId },
});

const patchRuleFieldsToDisplay = (
  ruleId: string,
  filterId: string,
  fieldsToDisplay: PlanRuleFieldToDisplay[],
): PlansPatchRuleFieldsToDisplayAction => ({
  type: PLANS_ACTIONS.ON_PATCH_RULE_FIELDS_TO_DISPLAY,
  payload: { ruleId, filterId, fieldsToDisplay },
});

const patchRuleFieldToDisplayStatus = (
  ruleId: string,
  filterId: string,
  fieldToDisplayMachinename: string,
): PlansPatchRuleFieldToDisplayStatusAction => ({
  type: PLANS_ACTIONS.ON_PATCH_RULE_FIELD_TO_DISPLAY_STATUS,
  payload: { ruleId, filterId, fieldToDisplayMachinename },
});

const patchRuleRemoveFieldToDisplay = (
  ruleId: string,
  filterId: string,
  fieldToDisplayMachinename: string,
): PatchRuleRemoveFieldToDisplayAction => ({
  type: PLANS_ACTIONS.ON_PATCH_RULE_REMOVE_FIELD_TO_DISPLAY,
  payload: { ruleId, filterId, fieldToDisplayMachinename },
});

const removeRule = (rule: PlanRule): PlansRemoveRuleAction => ({
  type: PLANS_ACTIONS.ON_REMOVE_RULE,
  payload: { rule },
});

const removeRuleById = (ruleId: string): PlansRemoveRuleByIdAction => ({
  type: PLANS_ACTIONS.ON_REMOVE_RULE_BY_ID,
  payload: { ruleId },
});

const createCategory = (category: PlanCategory): PlansCreateCategoryAction => ({
  type: PLANS_ACTIONS.CREATE_CATEGORY,
  payload: { category },
});

const deleteCategory = (categoryName: string): PlansDeleteCategoryAction => ({
  type: PLANS_ACTIONS.DELETE_CATEGORY,
  payload: { categoryName },
});

const editCategory = (category: PlanCategory, oldCategoryName: string): PlansEditCategoryAction => ({
  type: PLANS_ACTIONS.EDIT_CATEGORY,
  payload: { category, oldCategoryName },
});

const moveCategory = (fromIndex: number, toIndex: number): PlansMoveCategoryAction => ({
  type: PLANS_ACTIONS.MOVE_CATEGORY,
  payload: { fromIndex, toIndex },
});

const moveRule = (
  ruleId: string,
  fromIndex: number,
  toIndex: number,
  newCategoryName: string | null,
  lastCategoryName: string | null | undefined,
): PlansMoveRuleAction => ({
  type: PLANS_ACTIONS.MOVE_RULE,
  payload: {
    ruleId,
    newCategoryName,
    fromIndex,
    lastCategoryName,
    toIndex,
  },
});

const editVisibility = (planId: string, isHidden: boolean): PlansEditVisibilityAction => ({
  type: PLANS_ACTIONS.EDIT_VISIBILITY,
  payload: {
    planId,
    isHidden,
  },
});

export const PlanSyncActions = {
  renamePlan,
  setSettings,
  addRulesToPlan,
  patchRules,
  patchRuleKpisToDisplay,
  patchRuleKpiToDisplayStatus,
  patchRuleRemoveKpiToDisplay,
  patchRuleFiltersToDisplay,
  patchRuleFilterToDisplayStatus,
  patchRuleRemoveFilterToDisplay,
  patchRuleFieldsToDisplay,
  patchRuleFieldToDisplayStatus,
  patchRuleRemoveFieldToDisplay,
  removeRule,
  removeRuleById,
  createCategory,
  deleteCategory,
  editCategory,
  moveCategory,
  moveRule,
  editVisibility,
} as const;

type SyncAction = ReturnType<(typeof PlanSyncActions)[keyof typeof PlanSyncActions]>;

// We're reusing the same thunk dispatcher for those sync actions because they have
// the same behavior. Because of how the endpoint works, we need apply diff to the plan
// based on what was sent by the action (that is made in the reducer), then PUT it to the API.
export const editCurrentPlan =
  (syncAction: SyncAction): ThunkResult<Promise<PlansErrorAction | PlansSetCurrentPlanAction | SyncAction>> =>
  async (dispatch, getState) => {
    const plan = selectCurrentPlan(getState());
    // Synchronously apply diff to current plan.
    dispatch(syncAction);

    // Get new plan and try to update it.
    const newPlan = selectCurrentPlan(getState());

    try {
      dispatch(plansStart());
      assert(newPlan, 'Plan should be defined at this point');
      const updatedPlan = await PlansApiClient.update(newPlan);
      return dispatch(setCurrentPlan(updatedPlan));
    } catch (error) {
      dispatch(plansStart());
      dispatch(setCurrentPlan(plan));
      return dispatch(plansError(toError(error)));
    }
  };

// ================== PLAN ARCHIVING ==================
const onArchivePlan = (updatedPlan: Plan, archived: boolean): PlansArchivePlanAction => ({
  type: PLANS_ACTIONS.ARCHIVE_PLAN,
  payload: { updatedPlan, archived },
});

export const archivePlan =
  (planId: string, archived: boolean): ThunkResult<Promise<PlansArchivePlanAction | PlansErrorAction>> =>
  async (dispatch) => {
    try {
      dispatch(plansStart());
      const updatedPlan = await PlansApiClient.archive(planId, archived);
      return dispatch(onArchivePlan(updatedPlan, archived));
    } catch (error) {
      return dispatch(plansError(toError(error)));
    }
  };

// ================== PLAN RULES CATEGORIES ==================

const onSetCategories = (ruleCategories: PlanRuleCategory[]): PlansSetCategoriesAction => ({
  type: PLANS_ACTIONS.SET_CATEGORIES,
  payload: { ruleCategories },
});

export const fetchRuleCategories =
  (): ThunkResult<Promise<PlansErrorAction | PlansSetCategoriesAction>> => async (dispatch) => {
    dispatch(plansStart());
    try {
      const ruleCategories = await PlanRuleCategoriesRepository.list();
      return dispatch(onSetCategories(ruleCategories));
    } catch (error) {
      return dispatch(plansError(toError(error)));
    }
  };

export const patchRuleCategories =
  (ruleCategories: PlanRuleCategory[]): ThunkResult<Promise<PlansErrorAction | PlansSetCategoriesAction>> =>
  async (dispatch) => {
    dispatch(plansStart());
    try {
      // Dispatching before calling the API to avoid flickering effect when reordering.
      const dispatchResult = dispatch(onSetCategories(ruleCategories));
      await Promise.all(ruleCategories.map((rc) => PlanRuleCategoriesRepository.update(rc)));
      return dispatchResult;
    } catch (error) {
      // We modified the store before being sure that we could actually do it.
      // If shit went sideways, reset store state.
      await dispatch(fetchRuleCategories());
      return dispatch(plansError(toError(error)));
    }
  };
