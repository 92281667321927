import styled from '@emotion/styled';

export const PopoverLayout = styled.div`
  min-width: 240px;
  max-width: 500px;
  max-height: 280px;
  min-height: 96px;

  display: flex;
  flex-direction: column;
`;
