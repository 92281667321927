import { http } from '@amalia/core/http/client';
import { type PaymentCategory, type PaymentContract } from '@amalia/core/types';

const apiEndpoint = 'statements';

export class StatementPaymentsApiClient {
  public static async getStatementPayments(statementId: string): Promise<PaymentContract[]> {
    const { data } = await http.get<PaymentContract[]>(`${apiEndpoint}/${statementId}/payments`);
    return data;
  }

  public static async getStatementPaymentsPerCategory(
    statementId: string,
  ): Promise<Record<PaymentCategory, Partial<PaymentContract>[]>> {
    const { data } = await http.get<Record<PaymentCategory, Partial<PaymentContract>[]>>(
      `${apiEndpoint}/${statementId}/paymentsPerCategory`,
    );
    return data;
  }

  public static async getStatementAmounts(statementId: string): Promise<Record<PaymentCategory, number>> {
    const { data } = await http.get<Record<PaymentCategory, number>>(`${apiEndpoint}/${statementId}/amounts`);
    return data;
  }
}
