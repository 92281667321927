export enum AvatarSize {
  XSMALL = 'XSMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export type AvatarUser = {
  firstName?: string | null;
  lastName?: string | null;
  pictureURL?: string | null;
};
