import { memo } from 'react';

import { Select, type SelectOption, type SelectProps } from '@amalia/design-system/components';
import { type MergeAll } from '@amalia/ext/typescript';
import { type Period } from '@amalia/payout-definition/periods/types';

import { type PeriodOption } from './PeriodSelect.types';
import { usePeriodOptions } from './usePeriodOptions';
import { usePeriodSelector } from './usePeriodSelector';

type PeriodSelectProps = MergeAll<
  [
    Omit<SelectProps<PeriodOption, true, false>, 'isMultiple' | 'options'>,
    {
      overridesPeriodOptions?: SelectOption<string>[];
      periodList: Period[];
    },
  ]
>;

/**
 * PeriodSelect: a component to select a period / a list of periods / a keyword for period relative compute
 * WARNING: periods must be loaded in redux
 */
export const PeriodSelect = memo(function PeriodSelect({
  onChange,
  overridesPeriodOptions,
  periodList,
  ...props
}: PeriodSelectProps) {
  const periodOptions = usePeriodOptions({
    overridesPeriodOptions,
    periodList,
  });

  const { onChange: onChangePeriods } = usePeriodSelector({
    onChange,
  });

  return (
    <Select
      {...props}
      isMultiple
      options={periodOptions}
      onChange={onChangePeriods}
    />
  );
});
