import { type Variable } from '@amalia/amalia-lang/tokens/types';

import { type Filter } from '../../filters';

export interface PlanRuleChart {
  id: string;
  name: string;
  type: PlanRuleChartEnumType;
  configuration: PlanRuleChartConfiguration;
}

export type PlanRuleChartConfiguration = {
  targetAchievementVariableId?: Variable['id'];
  metricsFilterId?: Filter['id'];
  metricsPropertyMachineName?: string;
};

export enum PlanRuleChartEnumType {
  TARGET_ACHIEVEMENT = 'TARGET_ACHIEVEMENT',
  STATEMENT_METRIC = 'STATEMENT_METRIC',
}
