import { defineMessages } from 'react-intl';

import { ValueOrAttributeType } from '@amalia/amalia-lang/formula/types';

export const valueOrAttributeValuesMessages = defineMessages<ValueOrAttributeType>({
  [ValueOrAttributeType.VALUE]: {
    defaultMessage: '"{value}"',
  },

  [ValueOrAttributeType.ATTRIBUTE]: {
    defaultMessage: '{value}',
  },
} as const);
