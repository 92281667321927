import { isFunction } from 'lodash';
import { type ComponentProps, memo } from 'react';
import { Helmet } from 'react-helmet';
import { type IntlShape, useIntl } from 'react-intl';

import { type MergeAll } from '@amalia/ext/typescript';

export type AmaliaHeadProps = MergeAll<
  [
    ComponentProps<typeof Helmet>,
    {
      title?: string | ((formatMessage: IntlShape['formatMessage']) => string);
    },
  ]
>;
export const AmaliaHead = memo(function AmaliaHead({ title, children, ...props }: AmaliaHeadProps) {
  const { formatMessage } = useIntl();

  return (
    <Helmet {...props}>
      <title>{[isFunction(title) ? title(formatMessage) : title, 'Amalia'].filter(Boolean).join(' | ')}</title>
      {children}
    </Helmet>
  );
});
