import { useTheme } from '@emotion/react';
import { memo, useMemo, type ReactNode } from 'react';
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Stack } from '@amalia/design-system/components';
import {
  type DashboardChartConfiguration,
  type ChartType,
  type DashboardChartResult,
} from '@amalia/reporting/dashboards-v2/types';

import { ChartLegend } from '../../common/components/chart-legend/ChartLegend';
import { ChartTooltip } from '../../common/components/chart-tooltip/ChartTooltip';
import { type ChartSeriesMetadata, ChartSeriesType } from '../../common/types/charts.types';

import { useChartColumn } from './hooks/useChartColumn';
import { useYAxisWidth } from './hooks/useYAxisWidth';
import { BarLabel } from './ticks/bar-label/BarLabel';
import { XAxisTick } from './ticks/x-axis/XAxisTick';
import { YAxisTick } from './ticks/y-axis/YAxisTick';

export type SimpleBarChartProps = {
  readonly chartConfiguration: DashboardChartConfiguration<ChartType.SIMPLE_BAR_CHART>;
  readonly chartData?: DashboardChartResult;
  readonly chartFilters: ReactNode;
  readonly isAnimationActive?: boolean;
};

export const SimpleBarChart = memo(function SimpleBarChart({
  chartConfiguration,
  chartData,
  chartFilters,
  isAnimationActive = true,
}: SimpleBarChartProps) {
  const theme = useTheme();

  const { chartRef, width: yAxisWidth } = useYAxisWidth();

  const xAxisColumn = useChartColumn({
    chartData,
    columnIdentifier: chartConfiguration.displaySettings.xAxis,
  });

  const yAxisColumn = useChartColumn({
    chartData,
    columnIdentifier: chartConfiguration.displaySettings.yAxis,
  });

  const seriesList: ChartSeriesMetadata[] = useMemo(
    () =>
      [
        yAxisColumn && {
          columnMetadata: yAxisColumn,
          color: theme.ds.hues.blue[900],
          type: ChartSeriesType.BAR,
        },
      ].filter(Boolean),
    [theme, yAxisColumn],
  );

  if (!xAxisColumn || !yAxisColumn) {
    return null;
  }

  // TODO(CHARTS): handle when there are too many rows? The chart will be too crowded.

  return (
    <Stack gap={16}>
      {chartFilters}

      <ResponsiveContainer
        height={162}
        width="100%"
      >
        <BarChart
          ref={chartRef}
          data={chartData?.items}
          margin={{ top: 18, right: 0, bottom: 0, left: 0 }}
        >
          <CartesianGrid
            stroke={theme.ds.colors.gray[100]}
            strokeWidth={0.5}
            vertical={false}
          />

          <Tooltip
            allowEscapeViewBox={{ x: false, y: true }}
            cursor={{ fill: theme.ds.colors.gray[100] }}
            filterNull={false}
            reverseDirection={{ x: false, y: true }}
            content={
              <ChartTooltip
                formatLabel={xAxisColumn.formatTooltipValue}
                seriesList={seriesList}
              />
            }
          />

          <Bar
            barSize={32} // TODO(CHARTS): handle different sizing. We should use a ResizeObserver to determine the size of bars etc.
            dataKey={yAxisColumn.dataKey}
            fill={theme.ds.hues.blue[900]}
            isAnimationActive={isAnimationActive}
            name={yAxisColumn.column.label}
            radius={[2, 2, 0, 0]}
          >
            <LabelList
              content={<BarLabel />}
              dataKey={yAxisColumn.column.identifier}
              formatter={yAxisColumn.formatValue}
              position="top"
            />
          </Bar>

          <XAxis
            axisLine={{ stroke: theme.ds.colors.gray[500] }}
            dataKey={xAxisColumn.dataKey}
            height={24}
            tick={<XAxisTick />}
            tickFormatter={xAxisColumn.formatTick}
            tickLine={false}
          />

          <YAxis
            axisLine={false}
            dataKey={yAxisColumn.dataKey}
            orientation="left"
            tick={<YAxisTick />}
            tickFormatter={yAxisColumn.formatTick}
            tickLine={false}
            tickMargin={8}
            width={yAxisWidth ? yAxisWidth + 8 : undefined}
          />
        </BarChart>
      </ResponsiveContainer>

      <ChartLegend seriesList={seriesList} />
    </Stack>
  );
});
