import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo, type ReactNode } from 'react';

import { Button, Paper, Stack, Typography } from '@amalia/design-system/components';

import { DrawingSetupConnectors, DrawingSetupReports } from '../../assets';

export const IMAGES_LIBRARY = {
  SETUP_CONNECTORS: DrawingSetupConnectors,
  SETUP_REPORTS: DrawingSetupReports,
};

interface PlaceholderProps {
  readonly title: ReactNode;
  readonly children: ReactNode;
  readonly image: keyof typeof IMAGES_LIBRARY;
  readonly callToActionText?: ReactNode;
  readonly onClickCallToAction: () => void;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

const ImageContainer = styled.div`
  text-align: center;

  & > svg {
    max-width: 280px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Placeholder = memo(function Placeholder({
  title,
  children: description,
  callToActionText,
  image,
  onClickCallToAction,
}: PlaceholderProps) {
  const Image = IMAGES_LIBRARY[image];

  return (
    <Container>
      <Paper
        css={css`
          max-width: 600px;
          padding: 32px;
        `}
      >
        <Stack gap={32}>
          <Typography variant={Typography.Variant.HEADING_1_MEDIUM}>{title}</Typography>
          <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>{description}</Typography>
          <ImageContainer>
            <Image />
          </ImageContainer>
          {callToActionText ? (
            <ButtonContainer>
              <Button
                variant={Button.Variant.PRIMARY}
                onClick={onClickCallToAction}
              >
                {callToActionText}
              </Button>
            </ButtonContainer>
          ) : null}
        </Stack>
      </Paper>
    </Container>
  );
});
