import { css, type Theme } from '@emotion/react';

export const cellTextFieldValue = css`
  flex: 1;
`;

export const disabledPlaceholder = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[300]};
`;

export const blurred = (theme: Theme) => css`
  background-color: ${theme.ds.colors.gray[100]};
  max-width: 45px;
  width: 100%;
  height: ${theme.ds.typographies.bodyBaseRegular.lineHeight};
  display: block;
`;
