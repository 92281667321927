import { subject } from '@casl/ability';
import { type Theme, css } from '@emotion/react';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { Fragment, memo, useCallback } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { LabelDisplay, LabelVariant, UserPrettyFormat } from '@amalia/data-capture/fields/components';
import { IconButton, Typography, useSnackbars } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';
import { Link } from '@amalia/ext/react-router-dom';
import { toError } from '@amalia/ext/typescript';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { useDeleteCommentMutation } from '@amalia/payout-collaboration/comments/state';
import { type CommentThreadMessage, MessageStatus } from '@amalia/payout-collaboration/comments/types';
import { type UserContract } from '@amalia/tenants/users/types';

import * as styles from './CommentMessage.styles';
import { CommentMessageContent } from './CommentMessageContent';
import { DeleteMessageModal } from './DeleteMessageModal';
import { useEditableStatementCommentContext } from './editable-context/editableStatementCommentContext';

type CommentMessageProps = {
  /** Author of the message, could be undefined if we didn't manage to get user's infos */
  readonly author?: UserContract;
  /** The message to display */
  readonly message: CommentThreadMessage;
  readonly statementId: string;
  readonly statementThreadId: string;
};

// Shows a specific comment from a thread
export const CommentMessage = memo(function CommentMessage({
  author,
  message,
  statementId,
  statementThreadId,
}: CommentMessageProps) {
  const { snackError } = useSnackbars();
  const { formatMessage } = useIntl();
  const ability = useAbilityContext();
  const editionContext = useEditableStatementCommentContext();

  const {
    isOpenedDeleteMessageModal,
    setOpenedDeleteMessageModalTrue: openModal,
    setOpenedDeleteMessageModalFalse: closeModal,
  } = useBoolState(false, 'openedDeleteMessageModal');

  const { mutateAsync: deleteComment } = useDeleteCommentMutation();
  const deleteCommentHandler = useCallback(async (): Promise<void> => {
    try {
      await deleteComment({ messageId: message.id, statementId, statementThreadId });
      closeModal();
    } catch (e) {
      snackError(toError(e).message);
    }
  }, [closeModal, deleteComment, message.id, snackError, statementId, statementThreadId]);

  const editCommentHandler = useCallback(() => {
    if (editionContext) {
      editionContext.setEditedCommentId(message.id);
      editionContext.setEditedCommentContent(message.content);
    }
  }, [message.content, message.id, editionContext]);

  const canDelete = ability.can(ActionsEnum.delete, subject(SubjectsEnum.CommentThreadMessage, message));
  const canEdit = ability.can(ActionsEnum.modify, subject(SubjectsEnum.CommentThreadMessage, message));
  const canViewAudit = ability.can(ActionsEnum.view, SubjectsEnum.Audit);
  const isMessageEdited = [MessageStatus.EDITED, MessageStatus.DELETED].includes(message.status);

  const isMessageAlreadyInEdition = editionContext ? editionContext.editedCommentId === message.id : false;

  return (
    <Fragment>
      <div css={styles.container(isMessageAlreadyInEdition)}>
        <div css={styles.header}>
          <UserPrettyFormat
            display={LabelDisplay.INLINE}
            firstName={author?.firstName || formatMessage({ defaultMessage: 'Deactivated user' })}
            lastName={author?.lastName || ''}
            pictureURL={author?.pictureURL || ''}
            variant={LabelVariant.BOLD}
          />

          {!!(canDelete || canEdit) && (
            <div css={styles.actions}>
              {!!canEdit && !!editionContext && (
                <IconButton
                  disabled={message.status === MessageStatus.DELETED || isMessageAlreadyInEdition}
                  icon={<IconPencil />}
                  label={<FormattedMessage defaultMessage="Edit comment" />}
                  size={IconButton.Size.SMALL}
                  onClick={editCommentHandler}
                />
              )}
              {!!canDelete && !!editionContext && (
                <IconButton
                  disabled={message.status === MessageStatus.DELETED}
                  icon={<IconTrash />}
                  label={<FormattedMessage defaultMessage="Delete comment" />}
                  size={IconButton.Size.SMALL}
                  variant={IconButton.Variant.DANGER}
                  onClick={openModal}
                />
              )}
            </div>
          )}
        </div>
        <CommentMessageContent message={message} />
        <div css={styles.dateContainer}>
          <Typography
            css={styles.date}
            variant={Typography.Variant.BODY_XSMALL_REGULAR}
          >
            {message.status === MessageStatus.EDITED ? (
              <FormattedMessage
                defaultMessage="{date} (edited)"
                values={{
                  date: (
                    <FormattedDate
                      day="numeric"
                      hour="numeric"
                      minute="numeric"
                      month="short"
                      value={message.updatedAt}
                      weekday="short"
                    />
                  ),
                }}
              />
            ) : (
              <FormattedDate
                day="numeric"
                hour="numeric"
                minute="numeric"
                month="short"
                value={message.createdAt}
                weekday="short"
              />
            )}
          </Typography>
          {!!canViewAudit && !!isMessageEdited && (
            <Typography variant={Typography.Variant.BODY_XSMALL_REGULAR}>
              <Link
                openInNewTab
                to={generatePath(routes.AUDIT)}
                css={(theme: Theme) => css`
                  text-decoration: none;
                  color: ${theme.ds.colors.primary[500]};
                `}
              >
                <FormattedMessage defaultMessage="check audit to know more" />
              </Link>
            </Typography>
          )}
        </div>
      </div>
      <DeleteMessageModal
        closeModal={closeModal}
        isOpen={isOpenedDeleteMessageModal}
        onSubmit={deleteCommentHandler}
      />
    </Fragment>
  );
});
