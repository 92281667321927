import { css } from '@emotion/react';
import { memo } from 'react';

import { Typography } from '@amalia/design-system/components';
import { TypographyVariant } from '@amalia/design-system/meta';

import { type ChartSeries } from '../chartSeries';

import { LegendIcon } from './LegendIcon';

type LegendProps = {
  readonly seriesRecords?: Record<string, ChartSeries>;
  readonly hiddenSeries: ChartSeries['name'][];
};

const legendContainerCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const itemContainerCss = css`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const Legend = memo(function Legend({ seriesRecords, hiddenSeries }: LegendProps) {
  return (
    <div css={legendContainerCss}>
      {Object.values(seriesRecords)
        .filter((series) => !hiddenSeries.includes(series.name))
        .map((series) => (
          <div
            key={series.name}
            css={itemContainerCss}
          >
            <LegendIcon series={series} />
            <div>
              <Typography variant={TypographyVariant.BODY_BASE_MEDIUM}>{series.label}</Typography>
            </div>
          </div>
        ))}
    </div>
  );
});
