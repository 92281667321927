import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import { TeamsApiClient } from '@amalia/tenants/teams/api-client';
import { type TeamContract, type Team } from '@amalia/tenants/teams/types';

import { TEAM_QUERY_KEYS } from './queries.keys';

export const useTeam = (teamId: string) => {
  const { snackError } = useSnackbars();

  const {
    data: team,
    isError,
    error,
    isSuccess,
  } = useQuery({
    queryKey: [TEAM_QUERY_KEYS.TEAMS, teamId],
    queryFn: () => TeamsApiClient.getTeam(teamId),
  });

  useEffect(() => {
    if (isError) {
      snackError(toError(error).message);
    }
  }, [isError, error, snackError]);

  return { team, isSuccess, isError };
};

export const useDeleteTeam = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [TEAM_QUERY_KEYS.TEAMS, 'delete'],
    mutationFn: (teamId: string) => TeamsApiClient.deleteTeam(teamId),
    onSuccess: async (deletedTeam: Team) => {
      snackSuccess(
        formatMessage(
          { defaultMessage: '{teamName} has been successfully deleted.' },
          {
            teamName: deletedTeam.name,
          },
        ),
      );

      await queryClient.invalidateQueries({
        queryKey: [TEAM_QUERY_KEYS.TEAMS],
      });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useUpdateTeam = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [TEAM_QUERY_KEYS.TEAMS, 'update'],
    mutationFn: (team: Partial<TeamContract>) => TeamsApiClient.updateTeam(team),
    onSuccess: async (teamUpdated: TeamContract) => {
      snackSuccess(
        formatMessage(
          { defaultMessage: '{teamName} has been successfully updated.' },
          {
            teamName: teamUpdated.name,
          },
        ),
      );

      await queryClient.invalidateQueries({
        queryKey: [TEAM_QUERY_KEYS.TEAMS],
      });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useCreateTeam = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [TEAM_QUERY_KEYS.TEAMS, 'create'],
    mutationFn: (team: Partial<TeamContract>) => TeamsApiClient.createTeam(team),
    onSuccess: async (teamUpdated: TeamContract) => {
      snackSuccess(
        formatMessage(
          { defaultMessage: '{teamName} has been successfully created.' },
          {
            teamName: teamUpdated.name,
          },
        ),
      );

      await queryClient.invalidateQueries({
        queryKey: [TEAM_QUERY_KEYS.TEAMS],
      });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};
