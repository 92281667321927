import { isEmpty } from 'lodash';

import { sortByIndex } from '@amalia/core/types';
import { type PlanRule } from '@amalia/payout-definition/plans/types';

export const getRulesInCategory = (categoryName: string | null | undefined, rules: PlanRule[]): PlanRule[] => {
  if (!categoryName) {
    return sortByIndex(rules.filter((r) => isEmpty(r.category)));
  }
  return sortByIndex(rules.filter((r) => r.category === categoryName));
};
