import { uniq } from 'lodash';
import { useMemo } from 'react';

import { CURRENCY_SYMBOL_LABELS, type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { CurrencyDisplay } from '@amalia/kernel/monetary/components';
import { useCurrentCompany } from '@amalia/tenants/companies/state';

import { type CurrencyOption } from './types';

export const toCurrencyOption = (
  currencySymbol: CurrencySymbolsEnum,
  option?: Partial<CurrencyOption>,
): CurrencyOption => ({
  ...option,
  value: currencySymbol,
  valueLabel: currencySymbol,
  label: <CurrencyDisplay currencySymbol={currencySymbol} />,
  filterLabel: [currencySymbol, CURRENCY_SYMBOL_LABELS[currencySymbol]].join(' '),
});

export type UseCurrencyOptionsProps = {
  currentValue?: CurrencySymbolsEnum;
};

export const useCurrencyOptions = ({ currentValue }: UseCurrencyOptionsProps = {}): CurrencyOption[] => {
  const { data: company } = useCurrentCompany();

  const companySymbols = useMemo(() => {
    const symbols = Object.values(company.symbols).concat([company.currency]);
    const uniqSymbols = uniq(symbols);

    return uniqSymbols.sort((a, b) => a.localeCompare(b));
  }, [company.currency, company.symbols]);

  const companySymbolsOptions = useMemo(
    () => companySymbols.map((currencySymbol) => toCurrencyOption(currencySymbol)),
    [companySymbols],
  );

  /**
   * If the current value is not in the company symbols, we prepend it to the list of options as disabled.
   * This is to avoid the user selecting a currency that isn't in the company symbols anymore.
   * But we still need to be able to display the current value.
   *
   * Memoized to avoid running .includes if the current value hasn't changed.
   */
  const isCurrentValueNotInCompanySymbols = useMemo(
    () => currentValue && !companySymbols.includes(currentValue),
    [companySymbols, currentValue],
  );

  const disabledCurrentValueOption = useMemo(
    () =>
      currentValue && isCurrentValueNotInCompanySymbols ? [toCurrencyOption(currentValue, { disabled: true })] : [],
    [isCurrentValueNotInCompanySymbols, currentValue],
  );

  return useMemo(
    () => disabledCurrentValueOption.concat(companySymbolsOptions),
    [disabledCurrentValueOption, companySymbolsOptions],
  );
};
