import { css } from '@emotion/react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@amalia/design-system/components';
import { type CommentThreadMessage, MessageStatus, MessageTypeEnum } from '@amalia/payout-collaboration/comments/types';

import * as styles from './CommentMessage.styles';

type CommentMessageContent = {
  /** The message to display */
  readonly message: CommentThreadMessage;

  readonly fullWidth?: boolean;
};

export const CommentMessageContent = memo(function CommentMessageContent({
  message,
  fullWidth,
}: CommentMessageContent) {
  const contentCss = css`
    ${styles.content}
    max-width: ${fullWidth ? '100%' : '240px'};
  `;

  if (message.status === MessageStatus.DELETED) {
    return (
      <div css={contentCss}>
        <Typography
          variant={Typography.Variant.BODY_BASE_REGULAR}
          css={(theme) => css`
            color: ${theme.ds.colors.gray[500]};
          `}
        >
          <FormattedMessage defaultMessage="This comment has been deleted" />
        </Typography>
      </div>
    );
  }

  return (
    <div css={contentCss}>
      {message.content.map(({ content, type }) =>
        type === MessageTypeEnum.mention ? (
          <Typography
            key={content}
            css={styles.mention}
            variant={Typography.Variant.BODY_BASE_REGULAR}
          >
            <FormattedMessage
              defaultMessage="@{mention}"
              values={{ mention: content }}
            />
          </Typography>
        ) : (
          <Typography
            key={content}
            variant={Typography.Variant.BODY_BASE_REGULAR}
          >
            {content}
          </Typography>
        ),
      )}
    </div>
  );
});
