import styled from '@emotion/styled';

import { Paper } from '@amalia/design-system/components';

export const StatementSummaryLayout = Object.assign(
  styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 24px;
  `,
  {
    HistogramKpisContainer: styled(Paper)`
      display: flex;
      flex: 1 0;
    `,

    HistogramContainer: styled.div`
      min-width: 400px;
      border-left: 1px solid ${(props) => props.theme.ds.colors.gray[100]};
    `,

    KpisContainer: styled.div`
      height: 100%;
      min-width: 196px;
    `,

    ReviewPayoutContainer: styled(Paper)`
      display: flex;
      flex-direction: column;
      min-height: 180px;
      flex: 1 0;
      min-width: 600px;
    `,

    ReviewContainer: styled.div`
      padding: 8px 16px 8px 32px;
      flex-grow: 1;
      min-height: 72px;
      display: flex;
      align-items: center;

      > * {
        width: 100%;
      }
    `,

    PayoutContainer: styled.div`
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    `,
  },
);
