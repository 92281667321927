import { type Theme, css } from '@emotion/react';

export const verticalTabsMenuContainer = (theme: Theme) => css`
  background-color: ${theme.ds.colors.gray[50]};
  border-left: 4px solid ${theme.ds.colors.primary[400]};

  padding: 16px 16px 16px 12px;
  width: 216px;
  overflow-y: auto;
`;

export const tabsGroups = css`
  list-style-type: none;

  display: flex;
  flex-direction: column;
  gap: 1px;
`;

export const tabsGroup = css`
  display: flex;
  flex-direction: column;
  gap: 1px;

  > ul {
    list-style-type: none;

    display: flex;
    flex-direction: column;
    gap: 1px;
  }
`;

export const tabsGroupLabel = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[700]};
  padding: 5.5px 12px;
`;
