import { memo } from 'react';

import { type ComputedHighlightedKpi, HighlightedKpiIdentifier } from '@amalia/payout-definition/plans/types';

import { StatementKpi } from '../statement-kpi/StatementKpi';

import * as styles from './StatementKpisList.styles';

export type StatementKpisListProps = {
  readonly kpis?: Record<HighlightedKpiIdentifier, ComputedHighlightedKpi>;
  readonly isForecast?: boolean;
};

export const StatementKpisList = memo(function StatementKpisList({
  kpis = undefined,
  isForecast = false,
}: StatementKpisListProps) {
  return (
    <ul css={styles.statementKpisList}>
      <StatementKpi
        isForecast={isForecast}
        kpi={kpis?.primary}
        kpiId={HighlightedKpiIdentifier.PRIMARY}
      />

      <StatementKpi
        isForecast={isForecast}
        kpi={kpis?.secondary}
        kpiId={HighlightedKpiIdentifier.SECONDARY}
      />
    </ul>
  );
});
