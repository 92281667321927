import { type FormikFieldProps, useFormikFieldAdapter } from '@amalia/ext/formik';

import { type SelectOptionGroup } from '../../../../../overlays/select-dropdown/SelectDropdown.types';
import { type RowData } from '../../../Table.types';
import { CellSelect, type CellSelectProps } from '../CellSelect';
import { type CellSelectOption } from '../CellSelect.types';

export type FormikCellSelectProps<
  TOption extends CellSelectOption = CellSelectOption,
  TRowData extends RowData = RowData,
  TIsMultiple extends boolean | undefined = undefined,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = true,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
> = FormikFieldProps<CellSelectProps<TOption, TRowData, TIsMultiple, TUseOptionAsValue, TIsClearable, TGroup>>;

export const FormikCellSelect = function FormikCellSelect<
  TOption extends CellSelectOption = CellSelectOption,
  TRowData extends RowData = RowData,
  TIsMultiple extends boolean | undefined = undefined,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = true,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
>({
  validate,
  ...props
}: FormikCellSelectProps<TOption, TRowData, TIsMultiple, TUseOptionAsValue, TIsClearable, TGroup>) {
  const { ...formikFieldProps } = useFormikFieldAdapter<
    CellSelectProps<TOption, TRowData, TIsMultiple, TUseOptionAsValue, TIsClearable, TGroup>['value']
  >({
    validate,
    ...props,
  });

  return (
    <CellSelect
      {...props}
      {...formikFieldProps}
    />
  );
};
