import { IconPlus } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Tooltip, type ButtonProps, Button } from '@amalia/design-system/components';

export type PlanAwardsAddAwardButtonProps = Omit<ButtonProps, 'children' | 'icon'>;

export const PlanAwardsAddAwardButton = memo(function PlanAwardsAddAwardButton({
  ...props
}: PlanAwardsAddAwardButtonProps) {
  return (
    <Tooltip
      content={<FormattedMessage defaultMessage="This plan is archived, you can't edit any of its properties." />}
      disabled={!props.disabled}
    >
      <Button
        {...props}
        icon={<IconPlus />}
      >
        <FormattedMessage defaultMessage="Add a badge" />
      </Button>
    </Tooltip>
  );
});
