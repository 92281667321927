import { type ForwardedRef, forwardRef, type ReactElement, memo } from 'react';

import { type FormikFieldProps, useFormikFieldAdapter } from '@amalia/ext/formik';

import { InputSize } from '../../input/Input.types';
import { DatePicker, type DatePickerProps } from '../DatePicker';

export type FormikDatePickerProps<TWithRange extends boolean | undefined = undefined> = FormikFieldProps<
  DatePickerProps<TWithRange>
>;

export const FormikDatePickerForwardRef = forwardRef(function FormikDatePicker<
  TWithRange extends boolean | undefined = undefined,
>(
  {
    validate, // Omit validate and any props not passed to DatePicker.
    ...props
  }: FormikDatePickerProps<TWithRange>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const formikFieldProps = useFormikFieldAdapter<DatePickerProps<TWithRange>['value']>({ validate, ...props });

  return (
    <DatePicker<TWithRange>
      {...props}
      {...formikFieldProps}
      ref={ref}
    />
  );
});

export const FormikDatePicker = Object.assign(
  memo(FormikDatePickerForwardRef) as <TWithRange extends boolean | undefined = undefined>(
    props: FormikDatePickerProps<TWithRange> & { ref?: ForwardedRef<HTMLInputElement> },
  ) => ReactElement | null,
  {
    Size: InputSize,
  },
);
