import { type OptionsObject, type SnackbarMessage } from 'notistack';

export interface SnackbarDefinition {
  message: SnackbarMessage;
  options?: OptionsObject;
}

export const SNACKBAR_ACTIONS = {
  ADD: 'SNACKBARS/ADD',
} as const;
