import { type FormulaBuilderFunctionCategory } from '../amaliaFormula';

import { type FormulaBuilderFunctionBaseBlock } from './formulaBuilder';
import { type AttributeValue } from './valueOrAttribute';

export enum FormulaBuilderBooleanOperatorNoArgs {
  IS_TRUE = 'IS_TRUE',
  IS_FALSE = 'IS_FALSE',
  IS_BLANK = 'IS_BLANK',
  IS_NOT_BLANK = 'IS_NOT_BLANK',
}

export type FormulaBuilderFunctionBlockBoolean = FormulaBuilderFunctionBlockBooleanNoArgs;

export type FormulaBuilderFunctionBlockBooleanBase = FormulaBuilderFunctionBaseBlock & {
  category: FormulaBuilderFunctionCategory.BOOLEAN;
};

export type FormulaBuilderFunctionBlockBooleanNoArgs = FormulaBuilderFunctionBlockBooleanBase & {
  operator: FormulaBuilderBooleanOperatorNoArgs;
  args: [AttributeValue];
};
