import { format } from 'date-fns';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { StatementFrequency } from '@amalia/core/types';
import { Group, Popover } from '@amalia/design-system/components';
import { toDate } from '@amalia/ext/dates';
import { type Period } from '@amalia/payout-definition/periods/types';
import { type BadgeConfiguration } from '@amalia/payout-definition/plans/types';

import { PlanAwardDetails } from '../award-details/PlanAwardDetails';
import { PlanAwardIcon } from '../award-icon/PlanAwardIcon';

export type PlanAwardProps = {
  /** If false, the award is available but not won. */
  readonly isAwarded: boolean;
  /** Award configuration. */
  readonly configuration: BadgeConfiguration;
  /** Current period. */
  readonly period?: Period;
};

export const PlanAward = memo(function PlanAward({ isAwarded, configuration, period }: PlanAwardProps) {
  return (
    <Popover
      shouldTriggerOnHover
      initialFocus={-1}
      content={
        <Popover.Layout>
          <Popover.Header>
            <Popover.Title>
              <FormattedMessage
                defaultMessage="Badge {isAwarded, select, true {earned} other {not earned}}{hasPeriod, select, true {{periodFrequency, select, month { on {period}} quarter { on {period}} other {}}} other {}}"
                values={{
                  isAwarded: String(isAwarded),
                  hasPeriod: String(!!period),
                  periodFrequency: period?.frequency,
                  period: period
                    ? format(
                        toDate(period.startDate),
                        period.frequency === StatementFrequency.month ? 'MMMM yyyy' : 'QQQ yyyy',
                      )
                    : undefined,
                }}
              />
            </Popover.Title>
          </Popover.Header>
          <Popover.Body>
            <PlanAwardDetails
              configuration={configuration}
              isAwarded={isAwarded}
            />
          </Popover.Body>
        </Popover.Layout>
      }
    >
      <Group
        align="center"
        data-badge-icon={configuration.icon}
      >
        <PlanAwardIcon
          icon={configuration.icon}
          isAwarded={isAwarded}
          size={32}
        />
      </Group>
    </Popover>
  );
});
