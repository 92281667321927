import { css } from '@emotion/react';
import { memo } from 'react';

export type XAxisTickProps = {
  // Recharts props. They are not all listed here because it's typed as any in recharts.
  readonly tickFormatter?: (value?: number | string | null) => string;
  readonly payload?: {
    value?: number | string;
  };
};

export const XAxisTick = memo(function XAxisTick({ payload, tickFormatter, ...props }: XAxisTickProps) {
  return (
    <text
      {...props}
      dy={8}
      textAnchor="middle"
      css={(theme) => css`
        ${theme.ds.typographies.bodyXsmallRegular};
        fill: ${theme.ds.colors.gray[700]};
        /* fill: ${theme.ds.hues.blue[900]}; */
      `}
    >
      {tickFormatter?.(payload?.value)}
    </text>
  );
});
