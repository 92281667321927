import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IconLayoutGrid, IconLayoutList, IconTimeline } from '@tabler/icons-react';
import { memo, type ReactNode, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { RadioButtonGroup } from '@amalia/design-system/components';
import { useCompanyCustomization } from '@amalia/frontend/web-data-layers';
import { type CompanyCustomization } from '@amalia/tenants/companies/types';

enum RuleView {
  KPI = 'kpi',
  CHART = 'chart',
}

interface RuleResultAccessoryResultsProps {
  readonly kpisChildren: ReactNode;
  readonly chartsChildren: ReactNode | null;
  readonly noPaddingTop?: boolean;
}

const Container = styled.div<Pick<RuleResultAccessoryResultsProps, 'noPaddingTop'>>`
  margin-top: ${(props: { noPaddingTop?: boolean }) => (props.noPaddingTop ? '0px' : '32px')};
`;

export const RuleResultAccessoryResults = memo(function RuleResultAccessoryResults({
  kpisChildren,
  chartsChildren,
  noPaddingTop,
}: RuleResultAccessoryResultsProps) {
  const [selectedView, setSelectedView] = useState<RuleView>(RuleView.KPI);
  const { formatMessage } = useIntl();
  const { legacyKpiCardView } = useCompanyCustomization() as CompanyCustomization;

  const viewOptions = useMemo(
    () => [
      {
        value: RuleView.KPI,
        icon: legacyKpiCardView ? <IconLayoutGrid /> : <IconLayoutList />,
        label: formatMessage({ defaultMessage: 'Variables' }),
      },
      {
        value: RuleView.CHART,
        icon: <IconTimeline />,
        label: formatMessage({ defaultMessage: 'Charts' }),
      },
    ],
    [formatMessage, legacyKpiCardView],
  );

  const hasCharts = chartsChildren !== null;

  const render = () => {
    // If no charts are available, print only KPIs
    if (!hasCharts) {
      return kpisChildren;
    }

    switch (selectedView) {
      case RuleView.KPI:
        return kpisChildren;
      case RuleView.CHART:
        return chartsChildren;
      default:
        return null;
    }
  };

  return (
    <Container noPaddingTop={noPaddingTop}>
      {!!hasCharts && (
        <div
          css={css`
            padding-bottom: 32px;
          `}
        >
          <RadioButtonGroup<RuleView>
            name="ruleViewRadio"
            options={viewOptions}
            value={selectedView}
            onChange={setSelectedView}
          />
        </div>
      )}

      {render()}
    </Container>
  );
});
