import { css, type Theme } from '@emotion/react';

export const container = css`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 720px;

  &:hover #actions-container > * {
    transition: visibility;
    visibility: inherit;
  }
`;

export const kpiContainer = (theme: Theme) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 4px 16px 4px 12px;
  border-radius: 4px;
  cursor: default;

  > :first-child {
    max-width: 70%;
  }

  &:hover {
    transition: background-color 0.2s;
    background-color: ${theme.ds.colors.gray[50]};
  }
`;

export const nameContainer = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const divider = (theme: Theme) => css`
  flex: 1;
  height: 1px;
  margin: 0 8px;
  align-self: center;
  border-top: ${theme.ds.colors.gray[200]} 1px dashed;
`;

export const value = (theme: Theme) => css`
  font-weight: bold;
  color: ${theme.ds.colors.gray[900]};
`;

export const actionsContainer = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const overwritten = (theme: Theme) => css`
  color: ${theme.ds.colors.primary[400]};
`;

export const isButton = (active?: boolean) => css`
  cursor: ${active ? 'pointer' : 'text !important'};
  user-select: ${active ? 'auto' : 'text !important'};
`;

export const grabHandle = css`
  cursor: grab;
  display: flex;
  align-items: center;
  outline: none;
`;
