import { memo } from 'react';

type KpiLegendProps = Readonly<{
  color: string;
}>;

export const KpiLegend = memo(function KpiLegend({ color }: KpiLegendProps) {
  return (
    <svg
      fill="none"
      height="9"
      viewBox="0 0 9 9"
      width="9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="4.5"
        cy="4.5"
        fill={color}
        r="4"
      />
    </svg>
  );
});
