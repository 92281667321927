import { defineMessages } from 'react-intl';

import {
  FormulaBuilderDateOperatorTwoArgs,
  FormulaBuilderDateOperatorOneArg,
  FormulaBuilderDateOperatorNoArgs,
} from '@amalia/amalia-lang/formula/types';

export const operatorMessagesWithTooltip = defineMessages<FormulaBuilderDateOperatorNoArgs>({
  [FormulaBuilderDateOperatorNoArgs.YEAR_TO_DATE]: {
    defaultMessage: 'Year to date',
  },
  [FormulaBuilderDateOperatorNoArgs.QUARTER_TO_DATE]: {
    defaultMessage: 'Quarter to date',
  },
  [FormulaBuilderDateOperatorNoArgs.IN_MONTH]: {
    defaultMessage: 'In month',
  },
  [FormulaBuilderDateOperatorNoArgs.IN_PERIOD]: {
    defaultMessage: 'In period',
  },
  [FormulaBuilderDateOperatorNoArgs.IS_BLANK]: {
    defaultMessage: 'Is blank',
  },
  [FormulaBuilderDateOperatorNoArgs.IS_NOT_BLANK]: {
    defaultMessage: 'Is not blank',
  },
});

export const operatorMessagesTooltip = defineMessages<
  Exclude<
    FormulaBuilderDateOperatorNoArgs,
    FormulaBuilderDateOperatorNoArgs.IS_BLANK | FormulaBuilderDateOperatorNoArgs.IS_NOT_BLANK
  >
>({
  [FormulaBuilderDateOperatorNoArgs.YEAR_TO_DATE]: {
    defaultMessage: 'The selected date is between the beginning of this year and today.',
  },
  [FormulaBuilderDateOperatorNoArgs.QUARTER_TO_DATE]: {
    defaultMessage: 'The selected date is between the beginning of this quarter and today.',
  },
  [FormulaBuilderDateOperatorNoArgs.IN_MONTH]: {
    defaultMessage: 'The selected date is in the current month.',
  },
  [FormulaBuilderDateOperatorNoArgs.IN_PERIOD]: {
    defaultMessage: 'The selected date is in the current period',
  },
});

export const operatorMessagesWithoutTooltip = defineMessages<
  FormulaBuilderDateOperatorOneArg | FormulaBuilderDateOperatorTwoArgs
>({
  [FormulaBuilderDateOperatorOneArg.DATE_AFTER]: {
    defaultMessage: 'After date',
  },

  [FormulaBuilderDateOperatorOneArg.DATE_STRICTLY_AFTER]: {
    defaultMessage: 'Strictly after date',
  },

  [FormulaBuilderDateOperatorOneArg.DATE_STRICTLY_BEFORE]: {
    defaultMessage: 'Strictly before date',
  },

  [FormulaBuilderDateOperatorOneArg.DATE_BEFORE]: {
    defaultMessage: 'Before date',
  },

  [FormulaBuilderDateOperatorOneArg.DATE_EQUALS]: {
    defaultMessage: 'At a certain date',
  },

  [FormulaBuilderDateOperatorTwoArgs.DATE_BETWEEN]: {
    defaultMessage: 'Between dates',
  },
} as const);

export const fieldOperatorMessages = defineMessages<
  FormulaBuilderDateOperatorOneArg | FormulaBuilderDateOperatorTwoArgs
>({
  [FormulaBuilderDateOperatorOneArg.DATE_STRICTLY_BEFORE]: {
    defaultMessage: '“{propertyName}” strictly before',
  },

  [FormulaBuilderDateOperatorOneArg.DATE_BEFORE]: {
    defaultMessage: '“{propertyName}” before',
  },

  [FormulaBuilderDateOperatorOneArg.DATE_STRICTLY_AFTER]: {
    defaultMessage: '“{propertyName}” strictly after',
  },

  [FormulaBuilderDateOperatorOneArg.DATE_AFTER]: {
    defaultMessage: '“{propertyName}” after',
  },

  [FormulaBuilderDateOperatorOneArg.DATE_EQUALS]: {
    defaultMessage: '“{propertyName}” does not exactly match',
  },

  [FormulaBuilderDateOperatorTwoArgs.DATE_BETWEEN]: {
    defaultMessage: '“{propertyName}” between',
  },
} as const);
