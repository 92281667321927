import { memo } from 'react';
import { useIntl } from 'react-intl';
import { Sector } from 'recharts';

import { formatAmount } from '@amalia/core/types';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { colors } from '@amalia/ext/mui/theme';

interface ActiveShapeProps {
  readonly cx?: number;
  readonly cy?: number;
  readonly innerRadius?: number;
  readonly outerRadius?: number;
  readonly startAngle?: number;
  readonly endAngle?: number;
  readonly fill?: string;
  readonly payload?: { name?: string };
  readonly value?: number;
  readonly currency?: CurrencySymbolsEnum;
  readonly percent?: number;
}
export const ActiveShape = memo(function ActiveShape({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
  payload,
  value,
  currency,
  percent,
}: ActiveShapeProps) {
  const { formatNumber } = useIntl();

  return (
    <g>
      <text
        dy={8}
        fill={colors.black}
        textAnchor="middle"
        x={cx}
        y={cy - 16}
        style={{
          filter: 'brightness(90%)',
          color: colors.black,
        }}
      >
        {formatAmount(value, currency)}
      </text>
      <text
        dy={8}
        fill={colors['grey-700']}
        textAnchor="middle"
        x={cx}
        y={cy}
      >
        {formatNumber(percent, { style: 'percent', maximumFractionDigits: 0 })}
      </text>
      <text
        dy={8}
        fill={colors['grey-700']}
        strokeWidth={1}
        textAnchor="middle"
        x={cx}
        y={cy + 16}
        style={{
          width: '80px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {payload.name.substring(0, 18)}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        endAngle={endAngle}
        fill={fill}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
      />
    </g>
  );
});
