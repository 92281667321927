import clsx from 'clsx';
import { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { UnstyledButton } from '@amalia/design-system/components';
import { type BadgeConfigurationIcon } from '@amalia/payout-definition/plans/types';

import { PlanAwardIcon } from '../../award-icon/PlanAwardIcon';

import * as styles from './PlanAwardIconSelectOption.styles';

export type PlanAwardIconSelectOptionProps = {
  readonly icon: BadgeConfigurationIcon;
  readonly onClick: (icon: BadgeConfigurationIcon) => void;
  readonly isSelected?: boolean;
};

export const PlanAwardIconSelectOption = memo(function PlanAwardIconSelectOption({
  icon,
  onClick,
  isSelected,
}: PlanAwardIconSelectOptionProps) {
  const { formatMessage } = useIntl();

  const handleClick = useCallback(() => onClick(icon), [icon, onClick]);

  return (
    <UnstyledButton
      aria-label={formatMessage({ defaultMessage: 'Pick icon {icon}' }, { icon })}
      className={clsx({ [styles.SELECTED_CLASSNAME]: isSelected })}
      css={styles.planAwardIconSelectOption}
      onClick={handleClick}
    >
      <PlanAwardIcon
        icon={icon}
        size={40}
      />
    </UnstyledButton>
  );
});
