import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { memo, useContext } from 'react';

import { InfoWithTooltip, Text, TextType, useDatagridStyles } from '@amalia/lib-ui';

import { RowsTableContext } from './RowsTableContext';
import { useRowsTableStyles } from './useRowsTableStyles';

export const RowsTableHeaderCell = memo(function RowsTableHeaderCell({
  column,
  children,
  ...restProps
}: TableHeaderRow.CellProps) {
  const classes = useDatagridStyles();
  const classesRowsTable = useRowsTableStyles();

  const { columnDescriptions } = useContext(RowsTableContext);

  const helpLabel = columnDescriptions[column.name];

  return (
    <TableHeaderRow.Cell
      column={column}
      {...restProps}
      className={clsx(
        (restProps as any).className,
        classes.header,
        column.name === 'actions' ? classesRowsTable.headerActionsCell : undefined,
      )}
    >
      <Text type={TextType.TABLE_HEADER}>
        {children}

        {helpLabel ? (
          <InfoWithTooltip>
            <div
              css={css`
                white-space: pre-wrap;
              `}
            >
              {helpLabel}
            </div>
          </InfoWithTooltip>
        ) : null}
      </Text>
    </TableHeaderRow.Cell>
  );
});
