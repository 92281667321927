import { Fragment, type ReactNode, memo } from 'react';

import { useComponentSwitchContext } from '../ComponentSwitch.context';
import { type ComponentSwitchItemValue } from '../ComponentSwitch.types';

export interface ComponentSwitchItemProps {
  /** Render children if this value matches the value of the parent ComponentSwitch. */
  readonly value: ComponentSwitchItemValue | ComponentSwitchItemValue[];
  /** Children to render. */
  readonly children?: ReactNode;
}

export const ComponentSwitchItem = memo(function ComponentSwitchItem({
  value,
  children = null,
}: ComponentSwitchItemProps) {
  const activeValue = useComponentSwitchContext();

  const isActive = Array.isArray(value) ? activeValue !== null && value.includes(activeValue) : value === activeValue;

  return isActive ? <Fragment>{children}</Fragment> : null;
});
