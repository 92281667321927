import { useQueryClient } from '@tanstack/react-query';
import { memo, useState } from 'react';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import { routes } from '@amalia/core/routes';
import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import { AuthenticationErrorPage } from '@amalia/kernel/auth/components';
import { useQueryString } from '@amalia/lib-ui';
import { CompaniesApiClient } from '@amalia/tenants/companies/api-client';
import { COMPANIES_QUERY_KEYS } from '@amalia/tenants/companies/state';
import { type COMPANY_INTEGRATIONS } from '@amalia/tenants/companies/types';

export const IntegrationOauthCallback = memo(function IntegrationOauthCallback() {
  const { integration } = useParams() as { integration: COMPANY_INTEGRATIONS };
  const queryClient = useQueryClient();

  const { code } = useQueryString();
  const navigate = useNavigate();
  const { snackSuccess } = useSnackbars();

  const [error, setError] = useState<string | undefined>();

  useAsyncEffect(async () => {
    if (code) {
      try {
        await CompaniesApiClient.registerIntegration(integration, code);
        // Once integration is set up, refresh the company in react-query.
        await queryClient.invalidateQueries({
          queryKey: [COMPANIES_QUERY_KEYS.CURRENT_COMPANY],
        });
        snackSuccess(`Integration ${integration} set up successfully!`);
        navigate(generatePath(routes.COMPANY_SETTINGS));
      } catch (e) {
        setError(toError(e).message);
      }
    }
  }, []);

  return error ? <AuthenticationErrorPage message={error} /> : null;
});
