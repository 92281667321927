export enum FormulaKeyword {
  'USER_ID' = 'user.id',
  'USER_EXTERNAL_ID' = 'user.externalId',
  'USER_TEAM_MEMBERS' = 'user.teamMembers',
  'USER_FIRST_NAME' = 'user.firstName',
  'USER_LAST_NAME' = 'user.lastName',
  'USER_CURRENCY' = 'user.currency',
  'USER_EMAIL' = 'user.email',
  'USER_HRIS_ID' = 'user.hrisId',
  'TEAM_MEMBERS' = 'team.members',
  'TEAM_MANAGERS' = 'team.managers',
  'TEAM_EMPLOYEES' = 'team.employees',
  'TEAM_NAME' = 'team.name',
  'STATEMENT_PERIOD_ID' = 'statementPeriod.id',
  'STATEMENT_PERIOD_CURRENT_DATE' = 'statementPeriod.currentDate',
  'STATEMENT_PERIOD_START_DATE' = 'statementPeriod.startDate',
  'STATEMENT_PERIOD_END_DATE' = 'statementPeriod.endDate',
  'STATEMENT_PERIOD_START_OF_MONTH' = 'statementPeriod.startOfMonth',
  'STATEMENT_PERIOD_END_OF_MONTH' = 'statementPeriod.endOfMonth',
  'STATEMENT_PERIOD_START_OF_QUARTER' = 'statementPeriod.startOfQuarter',
  'STATEMENT_PERIOD_END_OF_QUARTER' = 'statementPeriod.endOfQuarter',
  'STATEMENT_PERIOD_START_OF_SEMESTER' = 'statementPeriod.startOfSemester',
  'STATEMENT_PERIOD_END_OF_SEMESTER' = 'statementPeriod.endOfSemester',
  'STATEMENT_PERIOD_START_OF_YEAR' = 'statementPeriod.startOfYear',
  'STATEMENT_PERIOD_END_OF_YEAR' = 'statementPeriod.endOfYear',
  'PLAN_ASSIGNEMENT_EFFECTIVE_AS_OF' = 'planAssignement.effectiveAsOf',
  'PLAN_ASSIGNEMENT_EFFECTIVE_UNTIL' = 'planAssignement.effectiveUntil',
  'PLAN_ASSIGNEMENT_PLAN_ID' = 'planAssignement.planId',
  'PLAN_ASSIGNEMENT_PLAN_NAME' = 'planAssignement.planName',
  'TEAM_ASSIGNMENT_EFFECTIVE_AS_OF' = 'teamAssignment.effectiveAsOf',
  'TEAM_ASSIGNMENT_EFFECTIVE_UNTIL' = 'teamAssignment.effectiveUntil',
  'FILTER_METRICS_BY_USER_TEAM_MEMBER' = 'filter.metricsByUserTeamMember',
  'FILTER_METRICS_BY_TEAM_MEMBER' = 'filter.metricsByTeamMember',
  'FILTER_METRICS_BY_TEAM_EMPLOYEE' = 'filter.metricsByTeamEmployee',
  'FILTER_METRICS_BY_TEAM_MANAGER' = 'filter.metricsByTeamManager',
}
