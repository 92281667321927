import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormulaBuilderLogicalOperatorType } from '@amalia/amalia-lang/formula/types';
import { type RadioButtonSingleOption } from '@amalia/design-system/components';

import { andOrMessages, andOrTooltipMessages } from './useAndOrOptions.messages';

export const useAndOrOptions = (): [
  RadioButtonSingleOption<FormulaBuilderLogicalOperatorType>,
  RadioButtonSingleOption<FormulaBuilderLogicalOperatorType>,
] =>
  useMemo(
    () => [
      {
        value: FormulaBuilderLogicalOperatorType.AND,
        label: <FormattedMessage {...andOrMessages[FormulaBuilderLogicalOperatorType.AND]} />,
        help: <FormattedMessage {...andOrTooltipMessages[FormulaBuilderLogicalOperatorType.AND]} />,
      },
      {
        value: FormulaBuilderLogicalOperatorType.OR,
        label: <FormattedMessage {...andOrMessages[FormulaBuilderLogicalOperatorType.OR]} />,
        help: <FormattedMessage {...andOrTooltipMessages[FormulaBuilderLogicalOperatorType.OR]} />,
      },
    ],
    [],
  );
