// Type of available downloads
export enum DownloadType {
  DATA_EXPORT = 'DATA_EXPORT',
}

// Type to request a file download
export interface FileDownloadRequest {
  // Type of file to download (corresponds to an entity)
  type: DownloadType;
  // Id of the concerned entity
  dataId: string;
}
