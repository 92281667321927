import { type ColorValue } from '../colors/types';

export enum ShadowVariant {
  SOFT = 'soft',
  HARD = 'hard',
  FOCUS_XSMALL = 'focusXsmall',
  FOCUS_SMALL = 'focusSmall',
  FOCUS_MEDIUM = 'focusMedium',
  FOCUS_LARGE = 'focusLarge',
}

export type ShadowDefinition = string;

export type ThemeShadows = {
  [ShadowVariant.SOFT]: ShadowDefinition;
  [ShadowVariant.HARD]: ShadowDefinition;
  [ShadowVariant.FOCUS_XSMALL]: (color: ColorValue) => ShadowDefinition;
  [ShadowVariant.FOCUS_SMALL]: (color: ColorValue) => ShadowDefinition;
  [ShadowVariant.FOCUS_MEDIUM]: (color: ColorValue) => ShadowDefinition;
  [ShadowVariant.FOCUS_LARGE]: (color: ColorValue) => ShadowDefinition;
};
