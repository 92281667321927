import { type Theme, css } from '@emotion/react';

export const leftBarActions = (theme: Theme) => css`
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: space-around;

  padding: 4px 6px;

  background-color: ${theme.ds.colors.gray[0]};
`;
