import { css } from '@emotion/react';
import { IconSpeakerphone } from '@tabler/icons-react';
import { memo, type ReactNode } from 'react';

import { Button, Group, Paper, Stack, Typography } from '@amalia/design-system/components';

type InformationBannerProps = Readonly<{
  onClickCTA: () => void;
  buttonTitle: ReactNode;
  title: ReactNode;
  description: ReactNode;
}>;

export const InformationBanner = memo(function InformationBanner({
  onClickCTA,
  buttonTitle,
  title,
  description,
}: InformationBannerProps) {
  return (
    <Paper
      css={(theme) => css`
        padding: 16px 24px;
        border-left: 6px solid ${theme.ds.hues.purple[400]};
      `}
    >
      <Group
        align="center"
        gap={32}
      >
        <Group
          grow
          align="center"
          gap={16}
        >
          <Group
            align="center"
            data-testid="icon-container"
            css={(theme) => css`
              background-color: ${theme.ds.hues.purple[400]};
              color: ${theme.ds.colors.gray[0]};
              border-radius: ${theme.ds.borderRadiuses.squared};
              padding: 8px;

              > svg {
                flex: none;
              }
            `}
          >
            <IconSpeakerphone size={24} />
          </Group>

          <Stack>
            <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>{title}</Typography>
            <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>{description}</Typography>
          </Stack>
        </Group>

        <Button
          variant={Button.Variant.PRIMARY}
          onClick={onClickCTA}
        >
          {buttonTitle}
        </Button>
      </Group>
    </Paper>
  );
});
