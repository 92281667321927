import { makeStyles } from '@mui/styles';
import { IconSearch } from '@tabler/icons-react';
import { type ReactNode, memo, type Dispatch, type SetStateAction } from 'react';
import { useIntl } from 'react-intl';

import { Input } from '@amalia/design-system/components';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';

import { eventStopPropagation, eventStopPropagationAndPreventDefault } from '../../../utils/common/eventHelpers';
import { TextType, Text } from '../typography';

interface ListPopUpProps {
  readonly children: ReactNode;
  readonly buttonComponent?: ReactNode;
  readonly search: string;
  readonly setSearch: Dispatch<SetStateAction<string>>;
  readonly title?: ReactNode | string;
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  container: {
    minWidth: '16rem',
    maxWidth: '16rem',
    maxHeight: '25rem',
    minHeight: '25rem',
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    position: 'relative',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
  },
  title: {
    margin: '10px',
  },
  listContainer: {
    margin: theme.spacing(1, 0),
    height: '17rem',
    position: 'relative',
    overflow: 'auto',
  },
  buttonContainer: {
    padding: '10px 20px',
    textAlign: 'center',
    backgroundColor: theme.palette.grey[100],
    cursor: 'pointer',
    position: 'absolute',
    bottom: 0,
    width: '16rem',
    zIndex: 999,
  },
}));

export const ListPopUp = memo(function ListPopUp({
  children,
  search,
  setSearch,
  title,
  buttonComponent,
}: ListPopUpProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Text
          className={classes.title}
          type={TextType.INPUT_NAME}
        >
          {title || ''}
        </Text>

        <Input
          autoFocus
          action={search ? <Input.ClearAction onClick={() => setSearch('')} /> : null}
          leftIcon={<IconSearch />}
          placeholder={formatMessage({ defaultMessage: 'Search' })}
          value={search}
          onChange={setSearch}
          onClick={eventStopPropagationAndPreventDefault}
          onKeyDown={eventStopPropagation}
        />
      </div>
      <div className={classes.listContainer}>{children}</div>
      {!!buttonComponent && <div className={classes.buttonContainer}>{buttonComponent}</div>}
    </div>
  );
});
