import { useCurrentCompany } from '@amalia/tenants/companies/state';
import { type CompanyCustomization, DEFAULT_REPORTS_PRECISION } from '@amalia/tenants/companies/types';

/**
 * Better be realistic with the type here, if a configuration option disappears for instance.
 */
export const useCompanyCustomization = (): Partial<CompanyCustomization> | undefined => {
  const { data: company } = useCurrentCompany();

  return {
    holdAndReleasePaymentDateLabel: company.customization?.holdAndReleasePaymentDateLabel || '',
    holdAndReleaseForecastPaymentDateLabel: company.customization?.holdAndReleaseForecastPaymentDateLabel || '',
    totalCommissionLabel: company.customization?.totalCommissionLabel || '',
    reportsPrecision: company.customization?.reportsPrecision || DEFAULT_REPORTS_PRECISION,
    legacyKpiCardView: company.customization?.legacyKpiCardView || false,
  };
};
