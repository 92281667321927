import { type ThunkAction } from 'redux-thunk';

import { type CurrenciesReducer, type CurrenciesAction } from './currencies/types';
import {
  type CustomObjectDefinitionsReducer,
  type CustomObjectDefinitionsAction,
} from './customObjectDefinitions/types';
import { type FiltersReducer, type FiltersAction } from './filters/types';
import { type OverwritesReducer, type OverwritesAction } from './overwrites/types';
import { type PaymentsReducer, type PaymentsAction } from './payments/types';
import { type PlanAssignmentsAction, type PlanAssignmentsReducer } from './planAssignments/types';
import { type PlanForecastsReducer, type PlanForecastsActions } from './planForecasts/types';
import { type PlansReducer, type PlansAction } from './plans/types';
import { type SnackbarsReducer, type SnackbarsAction } from './snackbars/types';
import { type StatementsAction, type StatementsReducer } from './statements/types';
import { type TodosReducer, type TodosAction } from './todos/types';
import { type UsersReducer, type UsersAction } from './users/types';
import { type UserStatementsAction, type UserStatementsReducer } from './userStatements/types';

export type ReduxAction =
  | CurrenciesAction
  | CustomObjectDefinitionsAction
  | FiltersAction
  | OverwritesAction
  | PaymentsAction
  | PlanAssignmentsAction
  | PlanForecastsActions
  | PlansAction
  | SnackbarsAction
  | StatementsAction
  | TodosAction
  | UsersAction
  | UserStatementsAction;

export type DefaultRootState = object;

export interface RootState extends DefaultRootState {
  currencies: CurrenciesReducer;
  customObjectDefinitions: CustomObjectDefinitionsReducer;
  filters: FiltersReducer;
  overwrites: OverwritesReducer;
  payments: PaymentsReducer;
  planAssignments: PlanAssignmentsReducer;
  planForecasts: PlanForecastsReducer;
  plans: PlansReducer;
  snackbars: SnackbarsReducer;
  statements: StatementsReducer;
  todos: TodosReducer;
  users: UsersReducer;
  userStatements: UserStatementsReducer;
}

export type ThunkResult<R> = ThunkAction<R, RootState, never, ReduxAction>;

export interface PaginationStoredInRedux<T> {
  currentPage: number | null;
  pageCount: number | null;
  totalItems: number;
  items: T[];
}

export const INITIAL_REDUX_PAGINATION = {
  currentPage: null,
  pageCount: null,
  items: [],
  totalItems: 0,
};
