import { memo } from 'react';

import { type Plan } from '@amalia/payout-definition/plans/types';

import { PlanVisibilityHideModal } from './PlanVisibilityHideModal';
import { PlanVisibilityPublishModal } from './PlanVisibilityPublishModal';
import { type PlanVisibilityModalCommonProps } from './types';

export type PlanVisibilityModalsProps = PlanVisibilityModalCommonProps & {
  readonly isPlanHidden: Plan['isHidden'];
};

export const PlanVisibilityModals = memo(function PlanVisibilityModals({
  closeModal,
  isOpen,
  isPlanHidden,
  planId,
  planName,
}: PlanVisibilityModalsProps) {
  return isPlanHidden ? (
    <PlanVisibilityPublishModal
      closeModal={closeModal}
      isOpen={isOpen}
      planId={planId}
      planName={planName}
    />
  ) : (
    <PlanVisibilityHideModal
      closeModal={closeModal}
      isOpen={isOpen}
      planId={planId}
      planName={planName}
    />
  );
});
