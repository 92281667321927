import { defineMessages } from 'react-intl';

import { OverwriteTypesEnum } from '@amalia/core/types';
import { OverwriteScopeEnum } from '@amalia/data-correction/overwrites/types';

export const overwriteScopeMessages = defineMessages<OverwriteScopeEnum>({
  [OverwriteScopeEnum.FORECAST]: {
    defaultMessage: 'Forecast',
  },
  [OverwriteScopeEnum.GLOBAL]: {
    defaultMessage: 'Global',
  },
  [OverwriteScopeEnum.STATEMENT]: {
    defaultMessage: 'Statement',
  },
} as const);

export const overwriteTypeMessages = defineMessages<OverwriteTypesEnum>({
  [OverwriteTypesEnum.PROPERTY]: {
    defaultMessage: 'Property',
  },
  [OverwriteTypesEnum.FIELD]: {
    defaultMessage: 'Field',
  },
  [OverwriteTypesEnum.KPI]: {
    defaultMessage: 'KPI',
  },
  [OverwriteTypesEnum.PAYMENT]: {
    defaultMessage: 'Payment',
  },
  [OverwriteTypesEnum.FILTER_ROW_ADD]: {
    defaultMessage: 'Add filter row',
  },
  [OverwriteTypesEnum.FILTER_ROW_REMOVE]: {
    defaultMessage: 'Remove filter row',
  },
} as const);
