import { QueryClient } from '@tanstack/react-query';

/**
 * React-query client to be used by the whole application and tests.
 */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});
