import { keyframes } from '@emotion/react';

export const spinKeyframes = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const spinReverseKeyframes = keyframes`
  from { transform: rotate(-0deg); }
  to { transform: rotate(-360deg); }
`;
