import { type UnknownRecord } from 'type-fest';

import { type VariableDefinition } from '@amalia/amalia-lang/tokens/types';
import { type Overwrite, type DatasetRow } from '@amalia/core/types';
import { FormatsEnum, type Property } from '@amalia/data-capture/fields/types';
import { type CurrencyValue } from '@amalia/kernel/monetary/types';

import { type ComputedPlanRuleFieldsToDisplayWithSubTitle } from '../../../../RowsTable/RowsTable';
import { RowsTableUtils } from '../../../../RowsTable/RowsTable.utils';

export const useDatasetRecordsDataGridCellValue = ({
  datasetRow,
  field,
  propertyDefinition,
  variableDefinition,
  overwrite,
}: {
  datasetRow: DatasetRow;
  field: ComputedPlanRuleFieldsToDisplayWithSubTitle;
  propertyDefinition?: Property;
  variableDefinition?: VariableDefinition;
  overwrite?: Overwrite;
}) => {
  const format = propertyDefinition?.format || variableDefinition?.format;
  const rawValue = datasetRow.content[field.name] as CurrencyValue | boolean | number | string | null;

  const formattedValue = RowsTableUtils.getFormattedCellValue(rawValue, format, !!overwrite);

  const isNumber = format && [FormatsEnum.number, FormatsEnum.percent].includes(format) && typeof rawValue === 'number';

  // Only compute the truncated value if it's a small number
  const truncatedValue =
    isNumber && !!rawValue && rawValue <= 0.01
      ? RowsTableUtils.getFormattedCellValue(rawValue, format, !!overwrite)
      : undefined;

  const overwriteSourcevalue = (overwrite?.sourceValue as UnknownRecord | undefined)?.[field.name];

  const formattedOverwriteSourceValue = overwriteSourcevalue
    ? RowsTableUtils.getFormattedCellValue(overwriteSourcevalue, format, true)
    : undefined;

  const overwriteValue = (overwrite?.overwriteValue as UnknownRecord | undefined)?.[field.name];

  const formattedOverwriteValue =
    overwriteValue && (overwriteValue as CurrencyValue).symbol !== (rawValue as CurrencyValue | null)?.symbol
      ? RowsTableUtils.getFormattedCellValue(overwriteValue, format, true)
      : undefined;

  return {
    format,
    rawValue,
    formattedValue,
    truncatedValue,
    formattedOverwriteSourceValue,
    formattedOverwriteValue,
  };
};
