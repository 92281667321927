import { IconUpload } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { MenuDropdown } from '@amalia/design-system/components';

type UploadAvatarDropdownItemProps = {
  readonly onClick: () => void;
};

export const UploadAvatarDropdownItem = memo(function UploadAvatarDropdownItem({
  onClick: handleClickDropdownItem,
}: UploadAvatarDropdownItemProps) {
  return (
    <MenuDropdown.Item
      data-testid="upload-avatar-dropdown-item"
      icon={<IconUpload />}
      label={<FormattedMessage defaultMessage="Upload new avatar" />}
      onClick={handleClickDropdownItem}
    />
  );
});
