import { css } from '@emotion/react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconCheck } from '@tabler/icons-react';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type CalculationDescriptorStep, CALCULATION_ONGOING_STATUSES } from '@amalia/core/types';
import { Group, Typography } from '@amalia/design-system/components';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';

import { CalculationProgressModalStepBatch } from './CalculationProgressModalStepBatch';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  step: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  stepContent: {
    width: '100%',
    paddingLeft: theme.spacing(3),
    boxSizing: 'border-box',

    '& + $stepContent': {
      marginTop: theme.spacing(2),
    },
  },
}));

interface CalculationProgressModalStepProps {
  readonly step: CalculationDescriptorStep;
  readonly index: number;
}

export const CalculationProgressModalStep = memo(function CalculationProgressModalStep({
  step,
  index,
}: CalculationProgressModalStepProps) {
  const classes = useStyles();

  const nbBatches = step.batches.length;
  const nbCompletedBatches = useMemo(
    () => step.batches.filter((b) => !CALCULATION_ONGOING_STATUSES.includes(b.status)).length,
    [step],
  );

  const isStepOngoing = nbCompletedBatches > 0 && nbCompletedBatches < nbBatches;

  const icon = useMemo(() => {
    if (nbCompletedBatches === 0) {
      return <CircularProgress />;
    }
    if (isStepOngoing) {
      return (
        <CircularProgress
          value={(nbCompletedBatches / nbBatches) * 100}
          variant="determinate"
        />
      );
    }
    return (
      <div
        css={(theme) => css`
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: ${theme.ds.colors.primary[400]};
          color: ${theme.ds.colors.gray[0]};
        `}
      >
        <IconCheck size={32} />
      </div>
    );
  }, [nbCompletedBatches, nbBatches, isStepOngoing]);

  return (
    <div className={classes.step}>
      <Group
        align="center"
        gap={16}
      >
        {icon}

        <Typography variant={Typography.Variant.BODY_LARGE_BOLD}>
          <FormattedMessage
            defaultMessage="Step #{stepNumber, number} - {stepPeriod} - {completedBatches, number}/{totalBatches, number}"
            values={{
              stepNumber: index + 1,
              stepPeriod: step.periodName,
              completedBatches: nbCompletedBatches,
              totalBatches: nbBatches,
            }}
          />
        </Typography>
      </Group>

      <div className={classes.stepContent}>
        <div>
          {step.batches.map((batch, i) => (
            <CalculationProgressModalStepBatch
              key={i} // eslint-disable-line react/no-array-index-key
              batch={batch}
            />
          ))}
        </div>
      </div>
    </div>
  );
});
