import { memo } from 'react';

import { Typography, type TypographyProps } from '../../../general/typography/Typography';

import * as styles from './ModalDescription.styles';

export type ModalDescriptionProps = Omit<TypographyProps, 'variant'>;

export const ModalDescription = memo(function ModalDescription({ children, ...props }: ModalDescriptionProps) {
  return (
    <Typography
      {...props}
      css={styles.modalDescription}
      variant={Typography.Variant.BODY_BASE_REGULAR}
    >
      {children}
    </Typography>
  );
});
