import { InputBase, type InputBaseProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconCheck, IconPencil } from '@tabler/icons-react';
import { memo, type MouseEvent, type ReactElement, type ReactNode, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { IconButton } from '@amalia/design-system/components';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';

import { eventStopPropagation } from '../../../../../utils/common/eventHelpers';

interface QuickEditProps {
  readonly currentValue: string;
  readonly onSubmit: (newValue: string) => any;
  readonly children?: ReactElement;
  readonly tooltipTitle?: ReactNode;
  readonly initialEditMode?: boolean;
  readonly inputOptions?: Partial<InputBaseProps>;
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  editIcon: {
    marginLeft: '10px',
  },
  input: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
}));

export const QuickEdit = memo(function QuickEdit({
  currentValue,
  onSubmit,
  children,
  tooltipTitle,
  initialEditMode,
  inputOptions,
}: QuickEditProps) {
  const classes = useStyles();

  const [isEditMode, setIsEditMode] = useState<boolean>(!!initialEditMode);
  const [value, setValue] = useState<string>(currentValue);

  const onChangeProxy = useCallback(
    (event) => {
      setValue(event.target.value);
    },
    [setValue],
  );

  const onClickEdit = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setIsEditMode(true);
    event.stopPropagation();
  }, []);

  const onFinishEditing = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setIsEditMode(false);
      onSubmit(value);
      event.stopPropagation();
    },
    [setIsEditMode, value, onSubmit],
  );

  return isEditMode ? (
    <div className={classes.container}>
      <InputBase
        {...inputOptions}
        autoFocus
        className={classes.input}
        value={value}
        onChange={onChangeProxy}
        onClick={eventStopPropagation}
      />
      <IconButton
        className={classes.editIcon}
        data-testid="quickedit-submit"
        icon={<IconCheck />}
        label={<FormattedMessage defaultMessage="Edit" />}
        size={IconButton.Size.MEDIUM}
        onClick={onFinishEditing}
      />
    </div>
  ) : (
    <div className={classes.container}>
      {children || currentValue}
      <IconButton
        className={classes.editIcon}
        data-testid="quickedit-edit"
        icon={<IconPencil />}
        label={tooltipTitle}
        size={IconButton.Size.MEDIUM}
        onClick={onClickEdit}
      />
    </div>
  );
});
