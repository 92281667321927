import styled from '@emotion/styled';

export const Divider = {
  Horizontal: styled.div`
    width: 100%;
    height: 1px;
    align-self: stretch;
    background-color: ${({ theme }) => theme.ds.colors.gray[200]};
    flex: none;
  `,

  Vertical: styled.div`
    align-self: stretch;
    width: 1px;
    background-color: ${({ theme }) => theme.ds.colors.gray[200]};
    flex: none;
  `,
} as const;
