import { Fragment, memo } from 'react';

import { useFeatureFlag } from '@amalia/frontend/web-data-layers';

import { type FeatureFlagProps } from './FeatureFlag.types';

export type FeatureFlagEnabledProps = FeatureFlagProps;

export const FeatureFlagEnabled = memo(function FeatureFlagEnabled({ children, featureFlag }: FeatureFlagEnabledProps) {
  const { isFeatureEnabled } = useFeatureFlag(featureFlag);
  // need fragment to see as component
  return isFeatureEnabled && <Fragment>{children}</Fragment>;
});
