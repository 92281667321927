import { css } from '@emotion/react';
import { Form, Formik } from 'formik';
import { omit } from 'lodash';
import { Fragment, memo, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useAsyncEffect from 'use-async-effect';
import * as Yup from 'yup';

import { type Adjustment, getCurrencySymbolCharacters, type Statement } from '@amalia/core/types';
import { CustomObjectDefinitionType } from '@amalia/data-capture/record-models/types';
import {
  FormikInput,
  FormikSelect,
  FormikSwitch,
  FormikTextArea,
  FormLayout,
  Modal,
} from '@amalia/design-system/components';
import {
  type CreateAdjustmentDto,
  fetchCustomObjectDefinitions,
  selectCurrentPeriod,
  selectCustomObjectDefinitionsList,
  useThunkDispatch,
} from '@amalia/frontend/web-data-layers';
import { AdjustmentStatementDetails } from '@amalia/lib-ui-business';
import { usePeriods } from '@amalia/payout-definition/periods/components';

const schema = Yup.object().shape({
  amount: Yup.number().required(),
  description: Yup.string(),
  name: Yup.string().required().min(2).max(250),
  rowDefinitionId: Yup.string().when('appliesToRow', {
    is: (v: unknown) => !!v,
    then: (yupSchema) => yupSchema.required(),
  }),
  rowExternalId: Yup.string().when('appliesToRow', {
    is: (v: unknown) => !!v,
    then: (yupSchema) => yupSchema.required(),
  }),
});

interface AdjustmentModalProps {
  readonly adjustment: CreateAdjustmentDto | null;
  readonly onCancel: () => void;
  readonly onSubmit: (values: CreateAdjustmentDto) => void;
  readonly statement: Statement;
}

export const AdjustmentModal = memo(function AdjustmentModal({
  adjustment,
  onCancel,
  onSubmit,
  statement,
}: AdjustmentModalProps) {
  const { formatMessage } = useIntl();
  const dispatch = useThunkDispatch();

  useAsyncEffect(async () => {
    await dispatch(fetchCustomObjectDefinitions());
  }, []);

  const customObjectDefinitions = useSelector(selectCustomObjectDefinitionsList);

  const customObjectDefinitionsOptions = useMemo(
    () =>
      (customObjectDefinitions || [])
        .filter((c) => c.type !== CustomObjectDefinitionType.VIRTUAL)
        .map((c) => ({ label: c.name, value: c.id })),
    [customObjectDefinitions],
  );

  const { periodsList: periods } = usePeriods();

  const currentPeriod = useSelector(selectCurrentPeriod);

  const periodsOptions = useMemo(() => (periods || []).map((c) => ({ label: c.name, value: c.id })), [periods]);

  const statementCurrencySymbolCharacters = useMemo(() => getCurrencySymbolCharacters(statement.currency), [statement]);

  const initialValuesProxy: CreateAdjustmentDto & { appliesToRow: boolean } = useMemo(() => {
    if (!adjustment) {
      return null;
    }
    return {
      ...adjustment,
      appliesToRow: !!adjustment?.rowExternalId,
      rowExternalId: adjustment?.rowExternalId || '',
      rowDefinitionId: adjustment?.rowDefinitionId || '',
      paymentPeriodId:
        adjustment?.paymentPeriodId !== undefined
          ? // Put the period id (and empty string if it's null).
            adjustment.paymentPeriodId || ''
          : // Else select the current statement period.
            periodsOptions.find((p) => p.value === currentPeriod.id)?.value || '',
    };
  }, [adjustment, periodsOptions, currentPeriod]);

  const onSubmitProxy = useCallback(
    (values: CreateAdjustmentDto & { appliesToRow: boolean }) => {
      const keysToOmit = ['appliesToRow'];

      if (!values.appliesToRow) {
        keysToOmit.push('rowDefinitionId');
        keysToOmit.push('rowExternalId');
      }

      onSubmit({
        ...omit(values, keysToOmit),
        amount: parseFloat(`${values.amount}`), // Amount is a string in the form values.
        // For an edition, add the id we had in props.
        id: adjustment.id,
        // Replace '' with null.
        paymentPeriodId: values.paymentPeriodId || null,
      } as Adjustment);
    },
    [onSubmit, adjustment],
  );

  if (statement) {
    return (
      <Modal
        isOpen={!!adjustment}
        onClose={onCancel}
      >
        {adjustment ? (
          <Formik
            validateOnMount
            initialValues={initialValuesProxy}
            validationSchema={schema}
            onSubmit={onSubmitProxy}
          >
            {({ isValid, dirty, values }) => (
              <Form
                css={css`
                  display: contents;
                `}
              >
                <Modal.Content>
                  <Modal.Header>
                    <Modal.Title>
                      <FormattedMessage defaultMessage="Make an adjustment" />
                    </Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <FormLayout>
                      <FormLayout.Group>
                        <AdjustmentStatementDetails statement={statement} />

                        <FormikInput
                          required
                          id="name"
                          label={formatMessage({ defaultMessage: 'Name' })}
                          name="name"
                        />

                        <FormikInput
                          required
                          id="amount"
                          name="amount"
                          type="number"
                          label={formatMessage(
                            { defaultMessage: 'Amount (in {currency})' },
                            { currency: statementCurrencySymbolCharacters },
                          )}
                        />

                        <FormikSelect
                          isClearable
                          id="paymentPeriodId"
                          label={formatMessage({ defaultMessage: 'Payment period' })}
                          name="paymentPeriodId"
                          options={periodsOptions}
                          placeholder={formatMessage({ defaultMessage: 'None' })}
                          tooltip={formatMessage({
                            defaultMessage:
                              'The adjustment will be achieved on the period of the statement, but can be paid on another period.',
                          })}
                        />

                        <FormikSwitch
                          id="appliesToRow"
                          label={formatMessage({ defaultMessage: 'Apply to a record' })}
                          name="appliesToRow"
                          tooltip={formatMessage({
                            defaultMessage:
                              'You can link this adjustment to a record, which will be helpful later when generating reports.',
                          })}
                        />

                        {!!values.appliesToRow && (
                          <Fragment>
                            <FormikSelect
                              required
                              id="rowDefinitionId"
                              isClearable={false}
                              label={formatMessage({ defaultMessage: 'Record type' })}
                              name="rowDefinitionId"
                              options={customObjectDefinitionsOptions}
                            />

                            <FormikInput
                              required
                              id="rowExternalId"
                              label={formatMessage({ defaultMessage: 'Record external ID' })}
                              name="rowExternalId"
                            />
                          </Fragment>
                        )}

                        <FormikTextArea
                          id="description"
                          label={formatMessage({ defaultMessage: 'Description' })}
                          name="description"
                          css={css`
                            min-height: 150px;
                          `}
                        />
                      </FormLayout.Group>
                    </FormLayout>
                  </Modal.Body>
                </Modal.Content>

                <Modal.Actions>
                  <Modal.CancelAction />

                  <Modal.MainAction
                    disabled={!isValid || !dirty}
                    type="submit"
                  >
                    {adjustment.id ? (
                      <FormattedMessage defaultMessage="Update" />
                    ) : (
                      <FormattedMessage defaultMessage="Apply" />
                    )}
                  </Modal.MainAction>
                </Modal.Actions>
              </Form>
            )}
          </Formik>
        ) : null}
      </Modal>
    );
  }

  return null;
});
