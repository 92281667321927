import { css } from '@emotion/react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { formatCurrencyAmount } from '@amalia/core/types';
import { Group, Trend, Typography } from '@amalia/design-system/components';
import { TypographyVariant } from '@amalia/design-system/meta';
import { type CurrencyValue } from '@amalia/kernel/monetary/types';
import { CustomReportsPresetsEnum } from '@amalia/reporting/custom-reports/shared';
import { type UserContract } from '@amalia/tenants/users/types';

import { DashboardWidget } from '../DashboardWidget/DashboardWidget';
import { WidgetTitle } from '../WidgetTitle/WidgetTitle';

import { EarningsCardCurrency } from './EarningsCardCurrency';

type EarningsCardProps = {
  readonly period: string;
  readonly currentPeriodEarning: CurrencyValue;
  readonly previousPeriodEarning?: CurrencyValue;
  readonly user: Partial<UserContract>;
};

export const EarningsCards = memo(function EarningsCards({
  period,
  currentPeriodEarning,
  previousPeriodEarning,
  user,
}: EarningsCardProps) {
  return (
    <DashboardWidget>
      <WidgetTitle
        customReportId={CustomReportsPresetsEnum.PRESET_EARNED_OVER_TIME}
        title={<EarningsCardCurrency symbol={currentPeriodEarning?.symbol || user.currency} />}
      />

      <div>
        <Typography
          id="earnings-widget-period"
          variant={TypographyVariant.BODY_BASE_REGULAR}
          css={(theme) => css`
            color: ${theme.ds.colors.gray[600]};
            padding-top: 4px;
          `}
        >
          <FormattedMessage
            defaultMessage="Earned in {period}"
            values={{ period }}
          />
        </Typography>
        {!!currentPeriodEarning && (
          <Group
            align="center"
            gap={8}
            id="earnings-widget-value"
          >
            <Typography variant={TypographyVariant.HEADING_3_BOLD}>
              {formatCurrencyAmount({
                amount: currentPeriodEarning.value,
                currencySymbol: currentPeriodEarning.symbol,
              })}
            </Typography>

            <Trend
              currentValue={currentPeriodEarning.value}
              previousValue={previousPeriodEarning?.value}
            />
          </Group>
        )}
      </div>
    </DashboardWidget>
  );
});
