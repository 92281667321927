import { type ForwardedRef, forwardRef, memo } from 'react';

import { TableRow, type TableRowProps } from '../table-row/TableRow';

import * as styles from './TableHeaderRow.styles';

export type TableHeaderRowProps = TableRowProps;

const TableHeaderRowForwardRef = forwardRef(function TableHeaderRow(
  { children, ...props }: TableHeaderRowProps,
  ref: ForwardedRef<HTMLTableRowElement>,
) {
  return (
    <TableRow
      {...props}
      ref={ref}
      css={styles.tableHeaderRow}
    >
      {children}
    </TableRow>
  );
});

export const TableHeaderRow = memo(TableHeaderRowForwardRef);
