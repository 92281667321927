import { makeStyles } from '@mui/styles';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@amalia/design-system/components';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';
import {
  type WorkflowCondensedStep,
  type WorkflowDefinition,
  type WorkflowStatementState,
} from '@amalia/payout-collaboration/workflows/types';
import { type UsersMap } from '@amalia/tenants/users/types';

import { useWorkflowStepName } from '../../statement/workflow/useWorkflowStepName';

import { WorkflowStepperStep } from './WorkflowStepperStep';

type WorkflowState = (Partial<WorkflowStatementState> & { numberOfStatementsLabel?: string; completed?: boolean })[];

interface WorkflowStepperProps {
  readonly workflowDefinition?: WorkflowDefinition;
  readonly workflowState?: WorkflowState;
  readonly usersMap: UsersMap;
  readonly compact?: boolean;
  readonly hideTitle?: boolean;
}

const useContainerStyles = makeStyles((theme: AmaliaThemeType) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  title: {
    width: 'max-content',
    margin: '0 auto',
    color: theme.palette.secondary.light,
  },
  stepperContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
}));

export const WorkflowStepper = memo(function WorkflowStepper({
  workflowDefinition,
  workflowState,
  usersMap,
  compact,
  hideTitle = false,
}: WorkflowStepperProps) {
  const { formatStepName } = useWorkflowStepName();

  const stepsToDisplay = useMemo<WorkflowCondensedStep[]>(() => {
    switch (true) {
      // When the workflow is complete, don't use the definition since it can
      // be outdated, use the state instead.
      case !!workflowState:
        return workflowState.map((step) => ({
          stepName: formatStepName(step.stepName) || '',
          completed: step?.completed || !!(step?.date && step?.userId),
          date: step?.date,
          userId: step?.userId,
          numberOfStatementsLabel: step?.numberOfStatementsLabel,
        }));

      // Used in admin screens.
      case !!workflowDefinition:
        return (workflowDefinition?.steps || []).map((step) => ({
          stepName: formatStepName(step.stepName),
          completed: true,
        }));

      // This should happen only during loading, avoid failing.
      default:
        return [];
    }
  }, [workflowDefinition, workflowState, formatStepName]);

  // Only compact on admin screens and todoView.
  const isCompact = useMemo(
    () => compact || (Boolean(workflowDefinition) && !workflowState),
    [compact, workflowDefinition, workflowState],
  );

  const classes = useContainerStyles({ statesCount: stepsToDisplay.length, compact: isCompact });

  return (
    <div className={classes.container}>
      {!hideTitle && (
        <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>
          <FormattedMessage defaultMessage="Workflow recap" />
        </Typography>
      )}
      <div className={classes.stepperContainer}>
        {stepsToDisplay.map((step, index, steps) => (
          <WorkflowStepperStep
            key={index} // eslint-disable-line react/no-array-index-key
            compact={isCompact}
            index={index}
            isLast={index === stepsToDisplay.length - 1}
            isLastStepComplete={steps[index - 1]?.completed || false}
            step={step}
            usersMap={usersMap}
            workflowName={workflowDefinition?.name || ''}
          />
        ))}
      </div>
    </div>
  );
});
