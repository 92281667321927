import { type Theme, css } from '@emotion/react';

export const presentationContainer = css`
  display: flex;
  align-items: center;
`;

export const workflowDetails = css`
  margin-left: auto;

  display: flex;
  align-items: center;
  gap: 16px;

  > * {
    flex-shrink: 0;
  }
`;

export const progressBar = css`
  width: 115px;
`;

export const actions = css`
  display: flex;
  align-items: center;
  gap: 12px;

  margin-top: 16px;

  &:empty {
    display: none;
  }
`;

export const totalStatementsReviewed = (theme: Theme) => css`
  margin-bottom: 16px;
  color: ${theme.ds.colors.gray[800]};
`;
