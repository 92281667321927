import { makeStyles } from '@mui/styles';
import { memo, type ReactNode } from 'react';

import { Paper } from '@amalia/design-system/components';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  toolbar: {
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0, 1),
    minHeight: theme.spacing(8),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  leftActions: {
    marginRight: theme.spacing(2),
  },
  rightActions: {
    marginLeft: 'auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: `0 ${theme.spacing(1)}`,
  },
  separator: {
    flex: 1,
  },
}));

// transform group to element and just apply loop on this
interface ToolbarProps {
  readonly leftActions?: ReactNode | string;
  readonly rightActions?: ReactNode | string;
}

export const Toolbar = memo(function Toolbar({ leftActions, rightActions }: ToolbarProps) {
  const classes = useStyles();
  return (
    <Paper className={classes.toolbar}>
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          {!!leftActions && <div className={classes.leftActions}>{leftActions}</div>}
          {!!rightActions && <div className={classes.rightActions}>{rightActions}</div>}
        </div>
      </div>
    </Paper>
  );
});
