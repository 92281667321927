import moment from 'moment';

import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.fromDate, AmaliaFunctionCategory.DATES);

func.nbParamsRequired = 1;

func.description = 'Convert a timestamp to any date format';
// 'A format can be specified to format the date differently, like `YYYY-MM-DD`. '
// 'The date will be formatted by moment.js (see documentation here https://momentjs.com/docs/#/parsing/string-format/ or examples below).'

func.params = [
  {
    name: 'timestamp',
    description: 'Date in timestamp format: variables, properties, fields, keywords or a timestamp',
  },
  { name: 'format', description: 'Output format that is by default YYYY-MM-DD', defaultValue: 'YYYY-MM-DD' },
];

func.examples = [
  {
    desc: 'Returns 2023-05-23.',
    formula: 'fromDate(1684829783)' as AmaliaFormula,
    result: '2023-05-23',
  },
  {
    desc: 'Returns 23/05/2023.',
    formula: 'fromDate(1684829783, "DD/MM/YYYY")' as AmaliaFormula,
    result: '23/05/2023',
  },
  {
    desc: 'Returns May 23.',
    formula: 'fromDate(1684570583, "MMM YY")' as AmaliaFormula,
    result: 'May 23',
  },
  {
    desc: 'Returns 05/20/23.',
    formula: 'fromDate(1684570583, "MM/DD/YY")' as AmaliaFormula,
    result: '05/20/23',
  },
];

func.exec = (timestamp: number, format: string | null): string | null =>
  moment(timestamp, 'X').format(format || 'YYYY-MM-DD');

export default func;
