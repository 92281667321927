import { type Theme, css } from '@emotion/react';

import { SelectSize } from '../Select.types';

export const IS_OPEN_CLASSNAME = 'open';
export const HAS_ICON_CLASSNAME = 'has-icon';
export const IS_MULTIPLE_CLASSNAME = 'multiple';

export const controlContainer = css`
  position: relative;
`;

export const control = css`
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
`;

export const arrow = (theme: Theme) => css`
  transition: ${theme.ds.transitions.default()};

  &.${IS_OPEN_CLASSNAME} {
    transform: rotate(180deg);
  }
`;

export const valueContainer = (theme: Theme) => css`
  position: absolute;
  pointer-events: none;

  top: 50%;

  transform: translate(0, -50%);

  transition: ${theme.ds.transitions.default('left', 'right', 'transform')};

  &.${SelectSize.SMALL} {
    left: 12px;
    right: 62px;

    &.${IS_MULTIPLE_CLASSNAME} {
      left: 8px;
    }

    &.${HAS_ICON_CLASSNAME}, &.${IS_MULTIPLE_CLASSNAME}.${HAS_ICON_CLASSNAME} {
      left: 34px;
    }
  }

  &.${SelectSize.MEDIUM} {
    left: 16px;
    right: 76px;

    &.${IS_MULTIPLE_CLASSNAME} {
      left: 12px;
    }

    &.${HAS_ICON_CLASSNAME}, &.${IS_MULTIPLE_CLASSNAME}.${HAS_ICON_CLASSNAME} {
      left: 43px;
    }
  }
`;
