import { http } from '@amalia/core/http/client';
import {
  type DashboardShareCandidate,
  type CreateDashboardRequest,
  type Dashboard,
  type PatchDashboardRequest,
  type PostDashboardSharedUsersRequest,
} from '@amalia/reporting/dashboards-v2/types';
import { type UserComputed } from '@amalia/tenants/users/types';

export abstract class DashboardsApiClient {
  public static readonly API_ENDPOINT = '/dashboards/v2';

  //////////////////////////
  // QUERY METHODS
  //////////////////////////

  public static async findDashboard(dashboardId: Dashboard['id']): Promise<Dashboard> {
    const { data } = await http.get<Dashboard>(
      `${DashboardsApiClient.API_ENDPOINT}/${encodeURIComponent(dashboardId)}`,
    );
    return data;
  }

  public static async findDashboards(): Promise<Dashboard[]> {
    const { data } = await http.get<Dashboard[]>(DashboardsApiClient.API_ENDPOINT);
    return data;
  }

  public static async findDashboardShareCandidates(dashboardId: Dashboard['id']): Promise<DashboardShareCandidate[]> {
    const { data } = await http.get<DashboardShareCandidate[]>(
      `${DashboardsApiClient.API_ENDPOINT}/${encodeURIComponent(dashboardId)}/share-candidates`,
    );
    return data;
  }

  public static async getDashboardUsersSharedWith(dashboardId: Dashboard['id']): Promise<DashboardShareCandidate[]> {
    const { data } = await http.get<DashboardShareCandidate[]>(
      `${DashboardsApiClient.API_ENDPOINT}/${encodeURIComponent(dashboardId)}/shared-users`,
    );
    return data;
  }

  public static async isUserAllowedToViewDashboard(): Promise<boolean> {
    try {
      const { data } = await http.get<boolean>(`${DashboardsApiClient.API_ENDPOINT}/is-user-allowed-to-access`);
      return data;
    } catch (e) {
      return false;
    }
  }

  public static async getDashboardAsHome(): Promise<{ dashboardId: string | null }> {
    const { data } = await http.get<{ dashboardId: string | null }>(
      `${DashboardsApiClient.API_ENDPOINT}/home-dashboard`,
    );
    return data;
  }

  //////////////////////////
  // MUTATION METHODS
  //////////////////////////

  public static async createDashboard(createDashboardRequest: CreateDashboardRequest): Promise<Dashboard> {
    const { data } = await http.post<Dashboard>(DashboardsApiClient.API_ENDPOINT, createDashboardRequest);
    return data;
  }

  public static async patchDashboard(
    dashboardId: Dashboard['id'],
    patchDashboardRequest: PatchDashboardRequest,
  ): Promise<Dashboard> {
    const { data } = await http.patch<Dashboard>(
      `${DashboardsApiClient.API_ENDPOINT}/${encodeURIComponent(dashboardId)}`,
      patchDashboardRequest,
    );
    return data;
  }

  public static async deleteDashboard(dashboardId: Dashboard['id']): Promise<void> {
    await http.delete<Dashboard>(`${DashboardsApiClient.API_ENDPOINT}/${encodeURIComponent(dashboardId)}`);
  }

  public static async updateDashboardShareCandidates(
    dashboardId: Dashboard['id'],
    patchDashboardRequest: PostDashboardSharedUsersRequest,
  ): Promise<number> {
    const { data } = await http.post<number>(
      `${DashboardsApiClient.API_ENDPOINT}/${encodeURIComponent(dashboardId)}/shared-users`,
      patchDashboardRequest,
    );
    return data;
  }

  public static async removeDashboardUserSharedWith(
    dashboardId: Dashboard['id'],
    userToRemoveId: UserComputed['id'],
  ): Promise<UserComputed> {
    const { data } = await http.delete<UserComputed>(
      `${DashboardsApiClient.API_ENDPOINT}/${encodeURIComponent(dashboardId)}/shared-users/${userToRemoveId}`,
    );
    return data;
  }

  public static async setDashboardAsHome(dashboardId: Dashboard['id']): Promise<void> {
    await http.post(`${DashboardsApiClient.API_ENDPOINT}/${encodeURIComponent(dashboardId)}/set-as-home`);
  }

  public static async removeDashboardAsHome(): Promise<void> {
    await http.delete(`${DashboardsApiClient.API_ENDPOINT}/home-dashboard`);
  }
}
