import { type Period } from '@amalia/payout-definition/periods/types';

import { type PlanAssignmentWithUser } from '../../types/planAssignments';

export const isPlanAssignmentEffective = (pa: PlanAssignmentWithUser, period: Period) => {
  // If we have no range, planAssignment is always effective.
  if (!pa.effectiveAsOf && !pa.effectiveUntil) return true;

  // If we have only a startDate for the planAssignment, planAssignment is effective if
  // the startDate is before the period end.
  if (pa.effectiveAsOf && !pa.effectiveUntil) return pa.effectiveAsOf <= period.endDate;

  // If we have both a startDate and an endDate for the planAssignment, planAssignment is effective if
  // the startDate is before the period end and the endDate is after the period start.
  // The check on the effectiveUntil is a dummy check to make sure that the effectiveUntil is after the effectiveAsOf.
  if (pa.effectiveAsOf && pa.effectiveUntil)
    return pa.effectiveAsOf <= period.endDate && pa.effectiveUntil >= period.startDate;

  // If we only have an endDate for the planAssignment, planAssignment is effective if
  // the endDate is after the period start.
  return !!pa.effectiveUntil && pa.effectiveUntil >= period.startDate;
};
