import moment from 'moment';

export function convertDateToTimestamp(date: string | null, convertNullToInfinity = false) {
  if (convertNullToInfinity && (date === null || date === '')) {
    return Infinity;
  }
  if (date === null || date === '') {
    return null;
  }
  return moment.utc(date, 'YYYY-MM-DD').unix();
}

export const convertTimestampToDate = (timestamp: number | null) => {
  if (timestamp) {
    return moment.utc(timestamp, 'X').format('YYYY-MM-DD');
  }
  return null;
};

export const semesterRange = (momentDate: moment.Moment): { startDate: moment.Moment; endDate: moment.Moment } => {
  if ([1, 2].includes(momentDate.quarter())) {
    return {
      startDate: momentDate.clone().startOf('year'),
      endDate: momentDate.clone().endOf('year').subtract(6, 'month'),
    };
  }
  return {
    startDate: momentDate.clone().startOf('year').add(6, 'month'),
    endDate: momentDate.clone().endOf('year'),
  };
};
