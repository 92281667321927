import { css, type Theme } from '@emotion/react';

import { FormLayoutSize } from './FormLayout.types';

export const formLayout = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  max-width: 100%;

  transition: ${theme.ds.transitions.default('gap')};

  &[data-form-layout-size=${FormLayoutSize.SMALL}] {
    gap: 32px;
  }

  &[data-form-layout-size=${FormLayoutSize.MEDIUM}] {
    gap: 40px;
  }
`;
