import { Mark } from '@tiptap/core';
import { join } from 'lodash';

import { type AmaliaFunctionWithId, type DesignerAllObjects } from '../../designer/drawer/drawer.types';

/**
 * Extends Tiptap with appendFunction command.
 */
declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    function: {
      /**
       * append function block.
       */
      appendFunction: (attributes: { amaliaFunction: AmaliaFunctionWithId }) => ReturnType;
    };
  }
}

export interface FunctionNodeOptions {
  HTMLAttributes: Record<string, any>;
}

/**
 * Function node lets you add and render amalia functions in TipTap editor.
 */
export const FunctionNode = Mark.create<FunctionNodeOptions>({
  name: 'functionNode',

  renderHTML() {
    return ['span', { class: 'amalia-function' }, 0];
  },

  parseHTML() {
    return [{ tag: 'span.amalia-function' }];
  },

  addCommands() {
    return {
      appendFunction:
        (attributes) =>
        ({ chain }) =>
          chain()
            .setMark(this.name, attributes)
            .insertContent(`${attributes.amaliaFunction?.name}`)
            .unsetMark(this.name)
            .insertContent(
              `(${join(
                (attributes.amaliaFunction?.params || []).map(() => ' '),
                ',',
              )}) `,
            )
            .run(),
    };
  },
});

/**
 * Converts a raw formula to an html one that can be rendered with tiptap editor.
 * @param formula
 * @param designerObjects
 */
export const formulaToHtml = (formula: string, designerObjects?: DesignerAllObjects) => {
  let htmlFormula = formula || '';
  designerObjects?.FUNCTION.forEach((f) => {
    htmlFormula = htmlFormula.replace(
      new RegExp(`${f.name}\\(`, 'gu'),
      `<span class='amalia-function'>${f.name}</span>(`,
    );
  });

  return htmlFormula;
};
