import { http } from '@amalia/core/http/client';
import { type MasterSettings } from '@amalia/superadmin/shared';

export async function saGetAllMasterSettings(): Promise<MasterSettings> {
  const { data } = await http.get<MasterSettings>('/sa_settings');
  return data;
}

export async function saSetMasterSettings(newMasterSettings: Partial<MasterSettings>): Promise<void> {
  return http.patch('/sa_settings', newMasterSettings);
}

export async function saSetImpersonateUser(emailToImpersonate: string): Promise<void> {
  const payload: Partial<MasterSettings> = {
    IMPERSONATE: {
      emailToImpersonate,
    },
  };
  return http.patch('/sa_settings/impersonate', payload);
}
