import styled from '@emotion/styled';

export const PopoverHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  padding: 4px 24px; /* Arbitrary vertical padding to have some padding if the title wraps. */
  gap: 0; /* Gap is handled by PopoverHeaderActions. */

  position: sticky;
  top: 0;
  border-bottom: 1px solid ${({ theme }) => theme.ds.colors.gray[100]};
`;
