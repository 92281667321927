import { css } from '@emotion/react';
import { IconWriting } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedList, FormattedMessage, useIntl } from 'react-intl';

import { OverwriteTypesEnum, type DatasetRow } from '@amalia/core/types';
import { Tooltip } from '@amalia/design-system/components';

import { type ComputedPlanRuleFieldsToDisplayWithSubTitle } from '../../../RowsTable/RowsTable';
import { IndicatorIcon } from '../indicator-icon/IndicatorIcon';

export type RowOverwritesIndicatorProps = {
  readonly datasetRow: DatasetRow;
  readonly fields: ComputedPlanRuleFieldsToDisplayWithSubTitle[];
};

export const RowOverwritesIndicator = memo(function RowOverwritesIndicator({
  datasetRow,
  fields,
}: RowOverwritesIndicatorProps) {
  const { formatMessage } = useIntl();

  // Ignore dataset row add overwrite because we show a different marker for it.
  const overwriteFieldNames = (datasetRow.overwrites || [])
    .filter((overwrite) => overwrite.overwriteType !== OverwriteTypesEnum.FILTER_ROW_ADD)
    .map((overwrite) => fields.find((field) => field.name === overwrite.field)?.label)
    .filter(Boolean);

  return (
    !!overwriteFieldNames.length && (
      <Tooltip
        content={
          overwriteFieldNames.length === 1 ? (
            <FormattedMessage
              defaultMessage="The “{columnName}” column of this record has been overwritten."
              values={{
                columnName: overwriteFieldNames[0],
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="The {columnNames} columns of this record have been overwritten."
              values={{
                columnNames: (
                  <FormattedList
                    type="conjunction"
                    value={overwriteFieldNames.map((fieldName) =>
                      formatMessage({ defaultMessage: '“{columnName}”' }, { columnName: fieldName }),
                    )}
                  />
                ),
              }}
            />
          )
        }
      >
        <IndicatorIcon
          icon={<IconWriting />}
          css={(theme) => css`
            background-color: ${theme.ds.colors.primary[50]};
            color: ${theme.ds.colors.primary[500]};
          `}
        />
      </Tooltip>
    )
  );
});
