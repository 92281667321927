import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { formatUserFullName, type Statement } from '@amalia/core/types';
import { Avatar, Group, Stack, Typography } from '@amalia/design-system/components';
import { getComputedTeamInStatementResults } from '@amalia/payout-calculation/statements/shared';
import { type UserComputed, type UserContract } from '@amalia/tenants/users/types';

type StatementDetailHeaderUserInformationsProps = {
  readonly statement?: Statement;
  readonly user: UserComputed | UserContract;
};

export const StatementDetailHeaderUserInformations = memo(function StatementDetailHeaderUserInformationsBase({
  statement,
  user,
}: StatementDetailHeaderUserInformationsProps) {
  const { formatMessage } = useIntl();

  const teamName = useMemo(() => {
    const computedTeam = getComputedTeamInStatementResults(statement);

    if (!computedTeam) {
      return null;
    }

    return formatMessage(
      { defaultMessage: '{teamName}{isArchived, select, true {(ARCHIVED)} other {}}' },
      {
        teamName: computedTeam.name,
        isArchived: computedTeam.isTeamArchived,
      },
    );
  }, [formatMessage, statement]);

  return (
    <Group
      align="center"
      gap={24}
    >
      <Avatar
        size={48}
        user={user}
      />

      <Stack
        align="flex-start"
        justify="center"
      >
        <Typography variant={Typography.Variant.HEADING_1_BOLD}>{formatUserFullName(user)}</Typography>
        {!!teamName && <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>{teamName}</Typography>}
      </Stack>
    </Group>
  );
});
