import { css } from '@emotion/react';

export const kpisAndFiltersContainer = css`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  justify-content: space-between;

  padding-left: 20px; // Align kpi text to the rule name.
`;

export const filtersContainers = css`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const sectionsContainer = (isCardView: boolean) => css`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${isCardView ? 36 : 24}px;
`;
