import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { qsToObject } from '../common/url';

/**
 * Parses the query string and returns a key => value object.
 */
export const useQueryString = () => {
  const { search } = useLocation();
  return useMemo(() => qsToObject(search), [search]);
};
