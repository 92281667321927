import { type Theme, css } from '@emotion/react';

import { BadgeHue } from '@amalia/design-system/components';
import { sumTextCharCodes } from '@amalia/ext/typescript';

export const rowContainer = (isFirstRow: boolean) => css`
  display: flex;
  gap: 8px;
  margin-top: ${isFirstRow ? 0 : 12}px;
  padding-left: 8px;
`;

export const ruleName = css`
  max-width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
`;

export const ruleNameAndCategoryContainer = css`
  display: flex;
  gap: 8px;
`;

export const dashSeparator = (theme: Theme) => css`
  border-top: 1px dashed ${theme.ds.colors.gray['200']};
  flex: 1;
  height: 1px;
  margin-top: 10px;
`;

const CATEGORY_BADGE_VARIANTS = [
  BadgeHue.BLUE,
  BadgeHue.BROWN,
  BadgeHue.CYAN,
  BadgeHue.GREEN,
  BadgeHue.GREY,
  BadgeHue.MAGENTA,
  BadgeHue.ORANGE,
  BadgeHue.RED,
  BadgeHue.YELLOW,
] as const;

export const getBadgeVariant = (category: string | null) =>
  CATEGORY_BADGE_VARIANTS[sumTextCharCodes(category) % CATEGORY_BADGE_VARIANTS.length];
