import { css } from '@emotion/react';
import { Form } from 'formik';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal, type ModalProps } from '@amalia/design-system/components';

import { PlanRuleCategoryFormFields } from '../plan-rule-category-form-fields/PlanRuleCategoryFormFields';
import { PlanRuleCategoryForm, type PlanRuleCategoryFormProps } from '../PlanRuleCategoryForm';

export type PlanRuleCategoryFormModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & PlanRuleCategoryFormProps;

export const PlanRuleCategoryFormModal = memo(function PlanRuleCategoryFormModal({
  isOpen,
  onClose,
  ...props
}: PlanRuleCategoryFormModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <PlanRuleCategoryForm {...props}>
        {({ isValid, dirty, isSubmitting }) => (
          <Form
            css={css`
              display: contents;
            `}
          >
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>
                  {props.category ? (
                    <FormattedMessage defaultMessage="Edit section" />
                  ) : (
                    <FormattedMessage defaultMessage="Create section" />
                  )}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <PlanRuleCategoryFormFields />
              </Modal.Body>
            </Modal.Content>

            <Modal.Actions>
              <Modal.CancelAction />

              <Modal.MainAction
                disabled={!isValid || !dirty}
                isLoading={isSubmitting}
                type="submit"
              >
                <FormattedMessage defaultMessage="Save" />
              </Modal.MainAction>
            </Modal.Actions>
          </Form>
        )}
      </PlanRuleCategoryForm>
    </Modal>
  );
});
