import { type GetTokenSilentlyOptions } from '@auth0/auth0-react';
import axios, { type AxiosError, type AxiosPromise, type AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { assert } from '@amalia/ext/typescript';
import { config } from '@amalia/kernel/config/client';
import { log } from '@amalia/kernel/logger/client';

axios.defaults.baseURL = config.api.url;
axios.defaults.withCredentials = true;

// ================ AUTH ================

const allowlistedUnauthorizedErrors = ['/companies', '/users/me'];

let tokenInterceptor: number | null = null;

/**
 * Properly cleanup the token interceptor.
 */
const clearTokenInterceptor = () => {
  if (tokenInterceptor) {
    axios.interceptors.request.eject(tokenInterceptor);
    tokenInterceptor = null;
    log.info('Token interceptor cleared');
  }
};

/**
 * Set up an axios interceptor that fetch the access token before each call.
 *
 * It's not clearly written in the Auth0 documentation, but the function they
 * provide in their SDK to get an access token should be called before each
 * call to our API. It will either return the access token it has in cache
 * or call auth0 to perform a refresh token, then use the new access token.
 *
 * @param getAccessTokenSilently
 */
const setTokenInterceptor = (getAccessTokenSilently: (options: GetTokenSilentlyOptions) => Promise<string>) => {
  // Clear the existing interceptor if present.
  clearTokenInterceptor();

  tokenInterceptor = axios.interceptors.request.use(async (requestConfig) => {
    let jwt = '';

    try {
      // Use the Auth0 callback to get an access token.
      jwt = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.auth0.audience,
          scope: config.auth0.scope,
        },
      });
    } catch (e) {
      // If the token is expired and could not be refreshed (for instance,
      // the user logged out), log the error and return an empty jwt.
      // The API will then return a 401 on the next call, and the
      // user will be redirected to the login page.
      log.error(e);
    }

    if (jwt) {
      requestConfig.headers.Authorization = `Bearer ${jwt}`;
    }

    return requestConfig;
  });
};

export const qsStringify: typeof qs.stringify = (params, options) =>
  qs.stringify(params, { arrayFormat: 'repeat', indices: false, ...options });

export class HttpError<T = unknown> extends Error {
  public statusCode: number;

  public payload: T;

  public constructor(message: string, statusCode: number, payload: T) {
    super(message);
    this.statusCode = statusCode;
    this.payload = payload;
  }
}

// FIXME: maybe validate shape of error payload with a yup or zod schema?
export const isHttpError = <T = unknown>(error: unknown): error is HttpError<T> => error instanceof HttpError;

axios.interceptors.response.use(
  (response) => response,
  (error: AxiosError<{ message: string } | undefined> | Error) => {
    // We try to intercept unauthorized errors.
    // If we find one, then the user token is not valid anymore, so refresh on /
    // Allowlisted unauthorized URLs are managed by the authorization protector and must not refresh the page
    assert('response' in error, error);

    const { response, config } = error;

    assert(response, error);

    if (response.status === 401 && !allowlistedUnauthorizedErrors.includes(config?.url ?? '')) {
      window.location.href = generatePath(routes.ROOT);
    }

    const { status, data } = response;

    // If we can find a human-readable message in the error, display it, or else throw the error as-is.
    if (data?.message) {
      const { message, ...payload } = data;
      throw new HttpError(message, status, payload);
    }

    throw error;
  },
);

function setJwt(jwt: string | null) {
  axios.defaults.headers.common.Authorization = jwt ? `Bearer ${jwt}` : '';
}

function getJwt() {
  return axios.defaults.headers.common.Authorization;
}

// ================ AXIOS OVERRIDES ================

// Axios DELETE normally doesn't accept a body, so we're overriding the method everywhere.
const axiosDelete = <T = unknown>(url: string, axiosConfig?: AxiosRequestConfig) =>
  axios({
    method: 'DELETE',
    url,
    ...axiosConfig,
  }) as AxiosPromise<T>;

export const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axiosDelete,
  getJwt,
  setJwt,
  setTokenInterceptor,
  clearTokenInterceptor,
};
