import { DndContext, type DndContextProps } from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { IconEyeEdit, IconGripVertical } from '@tabler/icons-react';
import { memo, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { fuzzyFilter } from '@amalia/ext/filters';

import { Dropdown } from '../../../overlays/dropdown/Dropdown';
import { getMenuColumnName } from '../columns';
import { useDataGridContext } from '../DataGrid.context';
import { type ColumnOrderState, type ColumnVisibilityState } from '../DataGrid.types';
import { useReorderedColumns } from '../hooks/useReorderedColumns';

import {
  ColumnOrderAndVisibilityMenuItem,
  type ColumnOrderAndVisibilityMenuItemProps,
} from './column-order-and-visibility-menu-item/ColumnOrderAndVisibilityMenuItem';
import * as styles from './ColumnOrderAndVisibility.styles';

export type ColumnOrderAndVisibilityProps = {
  /** Column order state. */
  readonly columnOrder?: ColumnOrderState;
  /** Order change handler. */
  readonly onChangeColumnOrder?: (columnOrder: ColumnOrderState) => void;
  /** Column visibility state. */
  readonly columnVisibility?: ColumnVisibilityState;
  /** Visibility change handler. */
  readonly onChangeColumnVisibility?: (columnVisibility: ColumnVisibilityState) => void;
};

export const ColumnOrderAndVisibility = memo(function ColumnOrderAndVisibility({
  columnOrder,
  onChangeColumnOrder,
  columnVisibility,
  onChangeColumnVisibility,
}: ColumnOrderAndVisibilityProps) {
  const { columns } = useDataGridContext();
  const { formatMessage } = useIntl();
  const [searchText, setSearchText] = useState('');

  // Filter out columns that don't have a header, otherwise they have no name in the dropdown.
  const availableColumns = useMemo(
    () =>
      columns.filter((column) => {
        const columnName = getMenuColumnName(column);
        return (
          // Column with no name cannot be reordered or hidden via the widget (because it would have no label in the dropdown).
          !!columnName &&
          // If columnOrder is not defined, we do not show columns that cannot be hidden.
          // If columnOrder is defined, we show all columns with a name (and we disable the visibility switch).
          (columnOrder || column.isHidable !== false) &&
          // Search text filter.
          fuzzyFilter(columnName, searchText)
        );
      }),
    [columns, searchText, columnOrder],
  );

  const reorderedColumns = useReorderedColumns(availableColumns, columnOrder);

  const dndItems = useMemo(() => reorderedColumns.map(({ id }) => id), [reorderedColumns]);

  const handleChangeActivated: Required<ColumnOrderAndVisibilityMenuItemProps>['onChangeIsVisible'] = useCallback(
    (id, isActivated) =>
      onChangeColumnVisibility?.({
        ...columnVisibility,
        [id]: isActivated,
      }),
    [onChangeColumnVisibility, columnVisibility],
  );

  const handleDragEnd: Required<DndContextProps>['onDragEnd'] = useCallback(
    ({ active, over }) => {
      const currentIndex = dndItems.indexOf(active.id as string);
      const targetIndex = dndItems.indexOf(over?.id as string);
      onChangeColumnOrder?.(arrayMove(dndItems, currentIndex, targetIndex));
    },
    [onChangeColumnOrder, dndItems],
  );

  const title =
    columnOrder && columnVisibility
      ? formatMessage({ defaultMessage: 'Show/hide and reorder columns' })
      : columnOrder
        ? formatMessage({ defaultMessage: 'Reorder columns' })
        : formatMessage({ defaultMessage: 'Show/hide columns' });

  return (
    <Dropdown
      title={title}
      content={
        reorderedColumns.length ? (
          <DndContext
            modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              disabled={!columnOrder}
              items={dndItems}
              strategy={verticalListSortingStrategy}
            >
              <ul css={styles.listContainer}>
                {reorderedColumns.map((column) => (
                  <ColumnOrderAndVisibilityMenuItem
                    key={column.id}
                    disableReordering={!!searchText || reorderedColumns.length === 1 || !columnOrder}
                    disableVisibility={!columnVisibility || column.isHidable === false}
                    icon={column.icon}
                    id={column.id}
                    isVisible={columnVisibility?.[column.id] !== false}
                    label={getMenuColumnName(column)}
                    onChangeIsVisible={handleChangeActivated}
                  />
                ))}
              </ul>
            </SortableContext>
          </DndContext>
        ) : undefined
      }
      searchInput={
        // We can't reorder when search text is defined, so only show the search input when column visibility is enabled.
        columnVisibility ? (
          <Dropdown.SearchInput
            placeholder={formatMessage({ defaultMessage: 'Search column' })}
            value={searchText}
            onChange={setSearchText}
          />
        ) : undefined
      }
    >
      <Dropdown.IconButton
        withBackground
        icon={columnVisibility ? <IconEyeEdit /> : <IconGripVertical />} // Change the icon when only reorder is enabled.
        label={title}
      />
    </Dropdown>
  );
});
