import { useTheme } from '@emotion/react';
import { type CSSProperties, memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  ReactAvatarEditMonkeyPatch,
  type ReactAvatarEditMonkeyPatchProps,
} from './react-avatar-edit/ReactAvatarEditMonkeyPatch';

type AvatarEditorProps = Pick<ReactAvatarEditMonkeyPatchProps, 'onBeforeFileLoad' | 'onCrop'>;

export const AvatarEditor = memo(function AvatarEditor({ onBeforeFileLoad, onCrop }: AvatarEditorProps) {
  const theme = useTheme();

  const labelCSS: CSSProperties = useMemo(
    () => ({
      ...theme.ds.typographies.bodyLargeMedium,
      color: theme.ds.colors.gray[700],
      cursor: 'pointer',
      display: 'inline-block',
      width: '100%',
    }),
    [theme],
  );

  return (
    <ReactAvatarEditMonkeyPatch
      backgroundColor={theme.ds.colors.primary[50]}
      height={300}
      label={<FormattedMessage defaultMessage="Click here to load a picture" />}
      labelStyle={labelCSS}
      width={530}
      onBeforeFileLoad={onBeforeFileLoad}
      onCrop={onCrop}
    />
  );
});
