import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal, Typography } from '@amalia/design-system/components';
import { useSetDashboardAsHome } from '@amalia/reporting/dashboards-v2/state';

interface DashboardSetHomeModalProps {
  readonly dashboardId: string;
  readonly isOpen: boolean;
  readonly dashboardName: string;
  readonly onCloseModal: () => void;
}

export const DashboardSetHomeModal = memo(function DashboardSetHomeModal({
  dashboardId,
  dashboardName,
  onCloseModal,
  isOpen,
}: DashboardSetHomeModalProps) {
  const { mutate: setDashboardAsHome } = useSetDashboardAsHome(dashboardId);

  const handleSetConfirm = useCallback(() => {
    setDashboardAsHome();
    onCloseModal();
  }, [onCloseModal, setDashboardAsHome]);

  return (
    <Modal
      isOpen={isOpen}
      size={Modal.Size.MEDIUM}
      onClose={onCloseModal}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Set dashboard as home" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
            <FormattedMessage
              defaultMessage="Are you sure you want to set <bold>“{dashboardName}”</bold> as your homepage?"
              values={{
                bold: (chunk) => <Typography variant={Typography.Variant.BODY_BASE_BOLD}>{chunk}</Typography>,
                dashboardName,
              }}
            />
          </Typography>
          <br />
          <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
            <FormattedMessage defaultMessage="Doing so replaces your current homepage with this dashboard." />
          </Typography>
        </Modal.Body>
      </Modal.Content>
      <Modal.Actions>
        <Modal.CancelAction />
        <Modal.MainAction onClick={handleSetConfirm}>
          <FormattedMessage defaultMessage="Confirm" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
