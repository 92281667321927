import { sumTextCharCodes } from '@amalia/ext/typescript';
import { type PlanCategory, PlanRuleCategoryColor } from '@amalia/payout-definition/plans/types';

const STATEMENT_CATEGORY_COLORS = Object.values(PlanRuleCategoryColor);

/**
 * Return the category defined color, or a deterministic color based on the category name.
 *
 * @param category Plan rule category.
 * @returns Plan rule category color.
 */
export const getPlanRuleCategoryColor = (category: PlanCategory): PlanRuleCategoryColor => {
  if (category.color) {
    return category.color;
  }

  // Sum all the character codes of the category name.
  // This is arbitrary, but deterministic.
  const index = sumTextCharCodes(category.name);

  // Return the color at the index of the sum modulo the number of colors.
  return STATEMENT_CATEGORY_COLORS[index % STATEMENT_CATEGORY_COLORS.length];
};
