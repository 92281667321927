import { type VariableObjectsEnum } from '@amalia/amalia-lang/tokens/types';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';

import { type ComputedFunctionArgs } from './computed-functions';
import { type ComputedOverwrite } from './computed-overwrites';

export enum ComputedItemTypes {
  VARIABLE = 'VARIABLE',
  FUNCTION_RESULT = 'FUNCTION_RESULT',
  RULE = 'RULE',
}

export type ComputeEnginePrimitiveTypes = boolean | number | string | null;

export type ComputeEngineResult = ComputeEnginePrimitiveTypes | ComputeEnginePrimitiveTypes[] | object[] | object;

export type ComputedCurrencyAmount = { value: number; symbol: CurrencySymbolsEnum };

export type ComputeEngineColumnResult = ComputedCurrencyAmount | ComputeEnginePrimitiveTypes;

export const isComputedCurrencyAmount = (value: unknown): value is ComputedCurrencyAmount =>
  typeof value === 'object' && value !== null && 'value' in value && 'symbol' in value;

export interface ComputedItem<TValue extends ComputeEngineResult = ComputeEngineResult> {
  /** A unique id to help us build dependency tree. */
  id: string;
  /** Ids of this item's parents. */
  parentIds: string[];
  /** Item type. */
  type: ComputedItemTypes;
  /** Computed value. Undefined means that it hasn't been computed yet. */
  value?: TValue;
  /** Computed items can have an overwrite. */
  overwrite?: ComputedOverwrite;

  // Currency can be different than user.
  currency?: CurrencySymbolsEnum;
  // Id of computedItems to get currency from.
  // If we found multiple, it means that we should check their homogeneity
  currencyFrom?: string[];

  // Time boundaries.
  startDate?: number;
  endDate?: number;

  evaluations?: ComputedFunctionResult[];

  variableMachineName?: string;
}

export interface ComputedFunctionResult extends ComputedItem {
  type: ComputedItemTypes.FUNCTION_RESULT;
  function: string;

  args?: ComputedFunctionArgs;
}

export interface ComputedVariable<TValue extends ComputeEngineResult = ComputeEngineResult>
  extends ComputedItem<TValue> {
  type: ComputedItemTypes.VARIABLE;
  variableType: VariableObjectsEnum;

  // Machine name that refers to the variable definition.
  variableMachineName: string;

  // For object variables, we have the definition of the object.
  // For instance for `opportunity.amount`, it's `opportunity`.
  customObjectMachineName?: string;

  // Follow those relations in the dataset to find this object.
  relationLevel?: string;
}

export interface ComputedRule extends ComputedItem<number> {
  type: ComputedItemTypes.RULE;
  ruleMachineName: string;
  datasetToDisplayIds?: string[] | null;
}
