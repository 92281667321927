import clsx from 'clsx';
import { memo, type ReactNode } from 'react';

import { usePageSectionStyles } from './usePageLayoutStyles';

interface PageSectionProps {
  readonly children?: ReactNode;
  readonly noBg?: boolean;
  readonly gutters?: boolean;
  readonly containerClassName?: string;
  readonly testId?: string;
}

export const PageSection = memo(function PageSection({
  children,
  containerClassName,
  noBg,
  gutters,
  testId,
}: PageSectionProps) {
  const classes = usePageSectionStyles();
  const className = clsx(
    classes.pageSectionContainer,
    containerClassName,
    noBg && classes.noBg,
    gutters && classes.gutters,
  );

  return (
    <section
      className={className}
      data-testid={testId}
    >
      {children}
    </section>
  );
});
