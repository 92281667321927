import { isArray } from 'lodash';

import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.GETINTABLE, AmaliaFunctionCategory.ARRAY);

func.nbParamsRequired = 3;

func.description = 'Given a table variable, returns the value of a cell.';

func.params = [
  { name: 'table', description: 'The table used to fetch the value', validFormats: [FormatsEnum.table] },
  { name: 'rowIndex', description: 'Number of the row starting at 0.' },
  { name: 'columnIndex', description: 'Number of the column starting at 0.' },
];

func.examples = [
  {
    desc: 'Returns 200',
    formula: 'GETINTABLE([[100], [200], [300]], 1, 0)' as AmaliaFormula,
    result: 200,
  },
  {
    desc: 'Returns 2000',
    formula: 'GETINTABLE([[100, 1000], [200, 2000], [300, 3000]], 1, 1)' as AmaliaFormula,
    result: 2000,
  },
  {
    desc: 'Returns null because the rowIndex calls a value out of the table',
    formula: 'GETINTABLE([[100, 1000], [200, 2000], [300, 3000]], 5, 1)' as AmaliaFormula,
    result: null,
  },
  {
    desc: 'Returns the value of the first cell of the table statement.tierTable',
    formula: 'GETINTABLE(statement.tierTable, 0, 0)' as AmaliaFormula,
  },
];

func.execMock = () => 1;

func.exec = (table: any[][], rowIndex: number, columnIndex: number) => {
  if (!isArray(table)) {
    throw new Error('In GetInTable, table is not an array. Are you sure this is a table variable?');
  }

  if (typeof rowIndex !== 'number' || typeof columnIndex !== 'number') {
    throw new Error('In GetInTable, Row or Column is not numeric');
  }

  const row = table[rowIndex];

  if (!row) {
    return null;
  }

  if (!isArray(row)) {
    throw new Error('In GetInTable, Row is not an array. Does your table have two dimensions?');
  }

  const value = row[columnIndex];

  // Undefined in the calculation engine has no meaning, we prefer `null`.
  return value === undefined ? null : value;
};

export default func;
