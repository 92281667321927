import { css } from '@emotion/react';
import { IconChevronRight } from '@tabler/icons-react';
import { isArray } from 'lodash';
import { memo, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  type DatasetRow,
  formatDatasetCell,
  formatTotal,
  formatValueTotal,
  type Statement,
  TracingTypes,
} from '@amalia/core/types';
import { AlertBanner, Group, IconButton } from '@amalia/design-system/components';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { amaliaTheme } from '@amalia/ext/mui/theme';
import { parseRowMarginalIndex } from '@amalia/frontend/web-data-layers';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { log } from '@amalia/kernel/logger/client';
import { TracingContext } from '@amalia/lib-ui';
import { TracingBlock, TracingItems, useTracingStyles } from '@amalia/lib-ui-business';
import { type ComputedRule, type Dataset, type FilterDataset } from '@amalia/payout-calculation/types';
import { type ComputedPlanRuleFieldsToDisplay } from '@amalia/payout-definition/plans/types';

import { FORMATS_TO_FORCE_TO_SHOW } from '../detail/Rule/RowsTable/RowsTable.utils';

import {
  parseFormula,
  renderFilter,
  renderFormula,
  renderFunctionTracingBlock,
  renderRowMarginalIndex,
  TracingBlockForTable,
} from './TracingUtils';

interface TracingRowProps {
  readonly datasetRow: DatasetRow;
  readonly dataset: Dataset;
  readonly ruleResult: ComputedRule;
  readonly fields: ComputedPlanRuleFieldsToDisplay[];
  readonly statement: Statement;
  readonly currencySymbol: CurrencySymbolsEnum;
  readonly currencyRate: number;
}

/**
 * TracingRow
 * Specific component to trace a row / deal
 */
export const TracingRow = memo(function TracingRow({
  datasetRow,
  dataset,
  ruleResult,
  fields,
  statement,
  currencySymbol,
  currencyRate,
}: TracingRowProps) {
  const classes = useTracingStyles();
  const { formatMessage } = useIntl();

  const [formulaChildren, setFormulaChildren] = useState<{ tracingData: any; index: number; indexInFormula: number }[]>(
    [],
  );
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  // Current user
  const ability = useAbilityContext();
  const { statementDatasets } = useContext(TracingContext);

  // Store fields and filter row for child deal tracing
  const [fieldsAndDatasetRow, setFieldsAndDatasetRow] = useState<{
    datasetRow: DatasetRow;
    fields: ComputedPlanRuleFieldsToDisplay[];
  } | null>(null);

  // Reset fields and filter row to null if formula children was changed
  useEffect(() => setFieldsAndDatasetRow(null), [formulaChildren]);

  // Return the linked computed object
  const getComputedObjectDefinition = useCallback(
    (machineName: string) => statement.results.definitions.variables[machineName],
    [statement],
  );

  // Return the fact a variable is selected or not
  const isSelected = useCallback(
    (machineName: string) => formulaChildren[0]?.tracingData?.machineName === machineName,
    [formulaChildren],
  );

  // Handle specific case for tracing per deal nodes
  const handleNodeForDealTracing = useCallback(
    (node: any) => {
      const currentNode = node;

      // If this is an opportunity node, overwrite its label and value from values from the filter row
      if (
        [
          TracingTypes.FormulaNodeType.custom_object,
          TracingTypes.FormulaNodeType.variable,
          TracingTypes.FormulaNodeType.rowMarginal,
        ].includes(currentNode.type)
      ) {
        // Fetch real value
        const valueWithFormat = datasetRow?.content?.[currentNode.value?.machineName];

        const rowValue =
          valueWithFormat && (valueWithFormat.symbol || valueWithFormat.format || currentNode.value.format)
            ? formatTotal(
                valueWithFormat.value ?? datasetRow?.content?.[currentNode.value?.machineName],
                valueWithFormat.format || currentNode.value.format,
                valueWithFormat.symbol,
                1,
              )
            : formatValueTotal(datasetRow?.content?.[currentNode.value?.machineName]);
        // Fetch real label
        const field = fields.find((f) => f.name === currentNode.value?.name);

        if (rowValue && currentNode.value && !isArray(datasetRow?.content?.[currentNode.value?.machineName])) {
          currentNode.value.total = rowValue;
          if (!currentNode.value.formula) {
            currentNode.value.formula = datasetRow?.content?.[currentNode.value.machineName]?.value
              ? datasetRow?.content?.[currentNode.value.machineName].value.toString()
              : datasetRow?.content?.[currentNode.value.machineName].toString();
          }
        }
        if (field && currentNode.value) {
          currentNode.value.name = field.label;
        }
      }

      // Return the node
      return currentNode;
    },
    [datasetRow, fields],
  );

  const setTracingData = useCallback(
    ({ datasetRow: childDatasetRow, fields: childFields }: TracingTypes.CurrentTracingDataType) => {
      setFieldsAndDatasetRow({
        fields: childFields,
        datasetRow: childDatasetRow,
      });
    },
    [setFieldsAndDatasetRow],
  );

  // This function prints all tracing blocks for deal tracing
  const renderFormulaChild = useCallback(
    ({ tracingData, index, functionParameters }: any): ReactNode => {
      if (!tracingData) {
        return null;
      }

      // Try to find an overwrite on this element.
      const overwrite = (datasetRow?.overwrites || []).find((o) => o.field === tracingData.machineName);

      // Normally there's no filter, but call the renderFilter safeguard function
      if (tracingData.type === TracingTypes.FormulaNodeType.filter_dataset) {
        return renderFilter(
          ability,
          tracingData as FilterDataset,
          statement,
          statementDatasets,
          ruleResult,
          classes,
          setTracingData,
        );
      }

      if (tracingData.type === TracingTypes.FormulaNodeType.rowMarginal) {
        return renderRowMarginalIndex(
          tracingData,
          statement,
          statementDatasets,
          currencySymbol,
          classes,
          fields,
          undefined,
          overwrite,
        );
      }

      // If this is a table, just print the specific tracing table block
      if (tracingData.format === 'table') {
        return (
          <TracingBlockForTable
            key={index}
            result={tracingData}
            statementCurrency={statement.currency}
          />
        );
      }

      // If a function is detected, render it via the renderFunction method
      if (tracingData.function) {
        return renderFunctionTracingBlock(
          index,
          tracingData,
          currencySymbol,
          currencyRate,
          functionParameters,
          statement,
        );
      }

      // Otherwise, print is as a generalist tracing block, managing special cases

      // Get its value
      let value = tracingData.format
        ? formatTotal(tracingData.total, tracingData.format, tracingData.currency || currencySymbol, currencyRate)
        : tracingData.total;

      // If this is an opportunity, overwrite the value with the value from the datasetRow
      if (
        [
          TracingTypes.FormulaNodeType.custom_object,
          TracingTypes.FormulaNodeType.variable,
          TracingTypes.FormulaNodeType.rowMarginal,
        ].includes(tracingData.type)
      ) {
        const valueWithFormat = datasetRow?.content?.[tracingData.machineName];
        value =
          valueWithFormat && (valueWithFormat.symbol || valueWithFormat.format || tracingData.format)
            ? formatTotal(
                valueWithFormat.value ?? datasetRow?.content?.[tracingData.machineName],
                valueWithFormat.format || tracingData.format,
                valueWithFormat.symbol,
                1,
              )
            : formatValueTotal(datasetRow?.content?.[tracingData.machineName]);
      }

      let formulaNodes = [];
      if (tracingData.type === 'custom_object') {
        formulaNodes = [];
      } else {
        // Parse the formula nodes
        formulaNodes = parseFormula(tracingData, ruleResult, statement, statementDatasets, datasetRow, dataset).map(
          handleNodeForDealTracing,
        );
      }

      if (value && typeof value === 'object') {
        log.error('Tracing block value has not been parsed correctly, found for node:', { value, tracingData });
        return 'ERROR';
      }

      // Render a generalist tracing block
      return (
        <TracingBlock
          key={`${tracingData.function || ''}${tracingData.name}`}
          format={tracingData.format}
          formula={tracingData.formula}
          machineName={tracingData.machineName}
          options={{ customObjectMachineName: tracingData.customObjectMachineName }}
          overwrite={overwrite}
          row={datasetRow}
          statementCurrency={statement.currency}
          value={value}
          type={
            TracingTypes.TracingBlockType[(tracingData.type?.toUpperCase() as TracingTypes.TracingBlockType) || ''] ||
            TracingTypes.TracingBlockType.VARIABLE
          }
        >
          {tracingData.formula
            ? renderFormula(
                tracingData,
                index,
                formulaChildren,
                setFormulaChildren,
                currencySymbol,
                currencyRate,
                formulaNodes,
              )
            : null}
        </TracingBlock>
      );
    },
    [
      currencyRate,
      currencySymbol,
      formulaChildren,
      statement,
      ruleResult,
      handleNodeForDealTracing,
      datasetRow,
      dataset,
      classes,
      setTracingData,
      fields,
      ability,
      statementDatasets,
    ],
  );

  // Render a filter row
  const tracingItemsToShow: {
    name: string;
    machineName: string;
    value: string;
    isSelected: boolean;
    clickable: boolean;
    computedObject: any;
    color: string;
  }[] = useMemo(
    () =>
      fields
        // We hide the id and the name (name is printed above tracing)
        .filter((f) => !['id', 'name'].includes(f.name))
        .map(({ name, label }) => {
          const valueWithFormat = datasetRow?.content?.[name];
          const computedObject = getComputedObjectDefinition(name);

          const objectFormat = computedObject?.format;

          const formattedValue =
            formatDatasetCell(
              valueWithFormat,
              FORMATS_TO_FORCE_TO_SHOW.includes(objectFormat) ? objectFormat : undefined,
            )?.toString() || '';

          return {
            // add name, value and color to show items.
            name: label,
            machineName: name,
            value: formattedValue,
            isSelected: isSelected(name),
            clickable: !!computedObject,
            computedObject,
            color:
              amaliaTheme.palette[
                // The color is primary when the variable is selected.
                isSelected(name)
                  ? 'primary'
                  : // Otherwise, it depends on the fact it is linked to a computed object
                    computedObject
                    ? 'link'
                    : 'secondary'
              ].main,
          };
        }),
    [datasetRow, isSelected, fields, getComputedObjectDefinition],
  );

  const handleTracingItemsClick = useCallback(
    (item: any) => {
      // If item was not clickable, do nothing
      if (!item.clickable) {
        return;
      }

      const node = {
        ...item.computedObject,
        value: {
          ...item.computedObject?.value,
          type: TracingTypes.FormulaNodeType.variable,
        },
        type: TracingTypes.FormulaNodeType.variable,
      };

      if (item.computedObject?.formula?.includes('rowMarginalIndex')) {
        node.subFilter = parseRowMarginalIndex(
          node,
          item.computedObject,
          ruleResult,
          statement,
          statementDatasets,
          datasetRow,
        );

        node.type = TracingTypes.FormulaNodeType.rowMarginal;
        node.value.type = TracingTypes.FormulaNodeType.rowMarginal;
      }

      setFormulaChildren([
        {
          // A click on the filter row removes all formula children and set it to the new
          index: 1,
          // Set tracing data as the computed object properties
          tracingData: node,
          indexInFormula: 0,
        },
      ]);
    },
    [datasetRow, ruleResult, statement, statementDatasets],
  );

  const collapseVariablesDisplay = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  return (
    <div
      className={classes.container}
      css={css`
        width: 100%;
        max-width: 100%;
      `}
    >
      {isCollapsed ? (
        <div className={classes.openTracingItemsButton}>
          <IconButton
            aria-controls="tracing-items"
            aria-expanded={!isCollapsed}
            color="tertiary"
            icon={<IconChevronRight />}
            label={formatMessage({ defaultMessage: 'Line details' })}
            onClick={collapseVariablesDisplay}
          />
        </div>
      ) : (
        <TracingItems
          handleClick={handleTracingItemsClick}
          items={tracingItemsToShow}
          overwrites={datasetRow?.overwrites}
          onClickCollapse={collapseVariablesDisplay}
        />
      )}
      <div
        css={css`
          width: ${isCollapsed ? '100%' : '75%'};
        `}
      >
        {formulaChildren?.map(renderFormulaChild)}
        {fieldsAndDatasetRow ? (
          <TracingRow
            currencyRate={currencyRate}
            currencySymbol={currencySymbol}
            dataset={dataset}
            datasetRow={fieldsAndDatasetRow.datasetRow}
            fields={fieldsAndDatasetRow.fields}
            ruleResult={ruleResult}
            statement={statement}
          />
        ) : null}
        {!fieldsAndDatasetRow && !formulaChildren?.length ? (
          <Group
            align="center"
            justify="center"
            css={css`
              margin-top: 64px;
            `}
          >
            <AlertBanner variant={AlertBanner.Variant.INFO}>
              <FormattedMessage defaultMessage="Click on blue variables in the left menu to see how it is calculated." />
            </AlertBanner>
          </Group>
        ) : null}
      </div>
    </div>
  );
});
