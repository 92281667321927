import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type SelectOption } from '@amalia/design-system/components';
import { UserStatus } from '@amalia/tenants/users/types';

import { UserStatusLabels } from '../../../status/status.messages';

const statusOrder = [UserStatus.INACTIVE, UserStatus.INVITED, UserStatus.ACTIVE, UserStatus.DEACTIVATED];

export type StatusOption = SelectOption<UserStatus>;

export const useStatusOptions = (): StatusOption[] => {
  const { formatMessage } = useIntl();

  return useMemo(
    () =>
      Object.values(UserStatus)
        .toSorted((a, b) => statusOrder.indexOf(a) - statusOrder.indexOf(b))
        .map((status) => ({
          value: status,
          label: formatMessage(UserStatusLabels[status]),
        })),
    [formatMessage],
  );
};
