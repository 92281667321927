import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Checkbox, FormLayout } from '@amalia/design-system/components';
import { type ChartDisplaySettings, type ChartType } from '@amalia/reporting/dashboards-v2/types';

type KPICardChartFiltersProps = Pick<ChartDisplaySettings<ChartType>, 'filters'> & {
  readonly onFilterChange: (
    filterName: keyof ChartDisplaySettings<ChartType>['filters'],
    newChecked: boolean,
  ) => Promise<void>;
};

export const KPICardChartFilters = memo(function KPICardChartFilters({
  filters,
  onFilterChange,
}: KPICardChartFiltersProps) {
  const handleChangeFilterStatus = useCallback(
    (filterName: keyof ChartDisplaySettings<ChartType>['filters']) => async (newChecked: boolean) => {
      await onFilterChange(filterName, newChecked);
    },
    [onFilterChange],
  );

  return (
    <FormLayout.Group>
      <Checkbox
        checked={!!filters.periods.isEnabled}
        label={<FormattedMessage defaultMessage="Show filter on periods" />}
        onChange={handleChangeFilterStatus('periods')}
      />

      <Checkbox
        checked={!!filters.teams.isEnabled}
        label={<FormattedMessage defaultMessage="Show filter on teams" />}
        onChange={handleChangeFilterStatus('teams')}
      />

      <Checkbox
        checked={!!filters.users.isEnabled}
        label={<FormattedMessage defaultMessage="Show filter on users" />}
        onChange={handleChangeFilterStatus('users')}
      />
    </FormLayout.Group>
  );
});
