import { css } from '@emotion/react';
import { IconEyeEdit } from '@tabler/icons-react';
import { without } from 'lodash';
import { memo, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  Dropdown,
  useFilterOptions,
  type SelectOptionGroup,
  isSelectOptionGroup,
} from '@amalia/design-system/components';

import { type KpiOption } from '../useKPIDisplay';

import { KpiDisplayDropdownItem } from './kpi-display-dropdown-item/KpiDisplayDropdownItem';

export type KpiDisplayDropdownProps = {
  readonly options: (KpiOption | SelectOptionGroup<KpiOption>)[];
  readonly value: KpiOption['value'][];
  readonly onChange: (value: KpiOption['value'][]) => void;
};

export const KpiDisplayDropdown = memo(function KpiDisplayDropdown({
  options,
  value,
  onChange,
}: KpiDisplayDropdownProps) {
  const { formatMessage } = useIntl();

  const [searchText, setSearchText] = useState('');

  const filteredOptions = useFilterOptions<KpiOption>(options, searchText);

  const handleToggleItem = useCallback(
    (itemValue: KpiOption['value'], checked: boolean) => {
      if (checked) {
        onChange([...value, itemValue]);
      } else {
        onChange(without(value, itemValue));
      }
    },
    [onChange, value],
  );

  return (
    <Dropdown
      title={formatMessage({ defaultMessage: 'Show/hide variables' })}
      content={
        <ul
          css={css`
            list-style-type: none;
          `}
        >
          {filteredOptions.map((optionOrGroup) =>
            isSelectOptionGroup(optionOrGroup) ? (
              <Dropdown.Group
                key={String(optionOrGroup.options[0].value)}
                label={optionOrGroup.label}
              >
                {optionOrGroup.options.map((option) => (
                  <KpiDisplayDropdownItem
                    key={String(option.value)}
                    checked={value.includes(option.value)}
                    label={option.label}
                    value={option.value}
                    onChange={handleToggleItem}
                  />
                ))}
              </Dropdown.Group>
            ) : (
              <KpiDisplayDropdownItem
                key={String(optionOrGroup.value)}
                checked={value.includes(optionOrGroup.value)}
                label={optionOrGroup.label}
                value={optionOrGroup.value}
                onChange={handleToggleItem}
              />
            ),
          )}
        </ul>
      }
      searchInput={
        <Dropdown.SearchInput
          value={searchText}
          onChange={setSearchText}
        />
      }
    >
      <Dropdown.IconButton
        withBackground
        icon={<IconEyeEdit />}
        label={formatMessage({ defaultMessage: 'Show/hide variables' })}
      />
    </Dropdown>
  );
});
