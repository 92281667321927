import { memo, useMemo } from 'react';

import { Group } from '@amalia/design-system/components';
import { type BadgeAward, type BadgeConfiguration } from '@amalia/payout-definition/plans/types';

import { PlanAward, type PlanAwardProps } from '../award/PlanAward';

export type PlanAwardsListProps = {
  readonly awards: BadgeAward[];
  readonly configurations: BadgeConfiguration[];
  readonly period?: PlanAwardProps['period'];
};

export const PlanAwardsList = memo(function PlanAwardsList({ awards, configurations, period }: PlanAwardsListProps) {
  const notAwarded = useMemo(() => {
    const awardedIds = awards.map((b) => b.configurationId);
    return configurations.filter((c) => !c.deletedAt && !awardedIds.includes(c.id));
  }, [configurations, awards]);

  return (
    <Group
      wrap
      align="center"
      gap={12}
    >
      {awards.map((award) => (
        <PlanAward
          key={award.configuration.id}
          isAwarded
          configuration={award.configuration}
          period={period}
        />
      ))}

      {notAwarded.map((awardConfiguration) => (
        <PlanAward
          key={awardConfiguration.id}
          configuration={awardConfiguration}
          isAwarded={false}
          period={period}
        />
      ))}
    </Group>
  );
});
