import { InputBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconSearch } from '@tabler/icons-react';
import clsx from 'clsx';
import { memo, useCallback, type ReactNode, type ChangeEvent } from 'react';
import { useIntl } from 'react-intl';

import { type AmaliaThemeType } from '@amalia/ext/mui/theme';

import { Spinner } from '../../loaders/Spinner/Spinner';

interface SearchDrawerProps {
  readonly isLoading: boolean;
  readonly filter: string;
  readonly onChangeFilter: (value: string) => void;
  readonly className?: string;
  readonly children?: ReactNode;
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  drawer: {
    width: '400px',
    backgroundColor: theme.palette.common.white,
    // Boundary for the Spinner.
    position: 'relative',
  },
  listContainer: {
    height: '400px',
    overflow: 'auto',
  },
  listElement: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  nameContainer: {
    flex: '1',
    paddingLeft: '8px',
  },
  searchInput: {
    flex: '1',
    margin: `0 ${theme.spacing(1)}`,
  },
}));

export const SearchDrawer = memo(function SearchDrawer({
  children,
  isLoading,
  className,
  filter,
  onChangeFilter,
}: SearchDrawerProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const onChangeProxy = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChangeFilter(e.target.value);
    },
    [onChangeFilter],
  );

  return (
    <div className={clsx(className, classes.drawer)}>
      <div className={classes.listElement}>
        <IconSearch size={20} />
        <InputBase
          className={classes.searchInput}
          placeholder={formatMessage({ defaultMessage: 'Search' })}
          value={filter}
          onChange={onChangeProxy}
        />
      </div>
      <div className={classes.listContainer}>
        {isLoading ? <Spinner /> : null}
        {children}
      </div>
    </div>
  );
});
