import { type TransitionDefinition, type TransitionParameters } from './types';

export const makeThemeTransition = (rules: TransitionParameters): TransitionDefinition => {
  const transition = (...properties: string[]) =>
    (properties.length ? properties : ['all'])
      .map((property) => `${property} ${rules.durationMs}ms ${rules.easing} ${rules.delayMs}ms`)
      .join(', ');

  transition.durationMs = rules.durationMs;
  transition.delayMs = rules.delayMs;
  transition.easing = rules.easing;

  return transition;
};
