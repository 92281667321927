import { type SelectDropdownOption } from '../SelectDropdown.types';

/**
 * Return true if all options are selected.
 * It does not only check if checkedOptionsValues is the same length as options to handle wrong/duplicate values.
 *
 * @param options - Flat list of options.
 * @param checkedOptionsValues - Values of checked options.
 * @returns True if all options are selected.
 */
export const useHasEveryOptionSelected = <TOption extends SelectDropdownOption = SelectDropdownOption>(
  options: TOption[],
  checkedOptionsValues: TOption['value'][],
) => options.every((option) => option.disabled || checkedOptionsValues.includes(option.value));
