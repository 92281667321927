import { memo } from 'react';

import { type FormulaEditorToken } from '@amalia/amalia-lang/formula/components';
import { Dropdown } from '@amalia/design-system/components';

import { useDropdownItemIcon } from './useDropdownItemIcon';

type SuggestionDropdownItemProps = Readonly<{
  selectedItem?: FormulaEditorToken;
  item: FormulaEditorToken;
  onClick: (dropdownItem: FormulaEditorToken) => void;
}>;

export const SuggestionDropdownItem = memo(function TokenListFooter({
  item,
  selectedItem,
  onClick,
}: SuggestionDropdownItemProps) {
  const icon = useDropdownItemIcon(item);

  return (
    <Dropdown.ItemButton
      key={item.id}
      data-item-string-to-match={item.formula}
      data-item-type={item.type}
      icon={icon}
      isActive={selectedItem ? selectedItem.formula === item.formula && selectedItem.type === item.type : false}
      label={item.name}
      secondaryLabel={item.objectDefinitionName}
      onClick={() => onClick(item)}
    />
  );
});
