import { Fragment, memo, useCallback } from 'react';

import { type DatasetRow } from '@amalia/core/types';
import { ActionsEnum, subject, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { useStatementDetailContext } from '@amalia/lib-ui';
import { Charts } from '@amalia/lib-ui-business';
import { RuleResultAccessoryResults } from '@amalia/payout-calculation/statements/components';
import { type ComputedRule } from '@amalia/payout-calculation/types';
import { RuleLayout } from '@amalia/payout-definition/plans/rules/components';
import { type ComputedPlanRuleFieldsToDisplay, type PlanRule } from '@amalia/payout-definition/plans/types';

import { DatasetRecordsDataGrid } from './dataset-records-data-grid/DatasetRecordsDataGrid';
import { KPIList } from './kpis/KPIList';
import { StatementRuleDatasetMenu } from './statement-rule-dataset-menu/StatementRuleDatasetMenu';
import { useDatasets } from './statement-rule-dataset-menu/useDatasets';

interface RuleResultProps {
  readonly computedRule: ComputedRule;
  readonly ruleDefinition: PlanRule;
  readonly setCurrentTracingData?: any;
  readonly globalSearchValue?: string;
}

export const RuleResult = memo(function RuleResult({
  computedRule,
  ruleDefinition,
  setCurrentTracingData,
  globalSearchValue,
}: RuleResultProps) {
  const ability = useAbilityContext();
  const canViewPlan = ability.can(ActionsEnum.view, subject(SubjectsEnum.Plan, {}));

  // =============== DEBUG ==============
  const { statement, isForecastedView, objectsToDisplay } = useStatementDetailContext();

  const { datasetsWithFields, selectedDataset, setSelectedDataset } = useDatasets({
    statement,
    isForecastedView,
    objectsToDisplay,
    ruleDefinition,
  });

  const enrichTracingDataWithRule = useCallback(
    (tracingData: { datasetRow: DatasetRow; fields: ComputedPlanRuleFieldsToDisplay[] }) => {
      setCurrentTracingData({
        ...tracingData,
        rule: computedRule,
      });
    },
    [setCurrentTracingData, computedRule],
  );

  return (
    <Fragment>
      {computedRule ? (
        <RuleResultAccessoryResults
          chartsChildren={(ruleDefinition.charts || []).length > 0 ? <Charts ruleDefinition={ruleDefinition} /> : null}
          kpisChildren={<KPIList ruleDefinition={ruleDefinition} />}
        />
      ) : null}

      {!!selectedDataset && !!selectedDataset.fields && (
        <RuleLayout.Dataset>
          <DatasetRecordsDataGrid
            dataset={selectedDataset}
            fields={selectedDataset.fields}
            globalSearchValue={globalSearchValue}
            isForecasted={isForecastedView}
            rule={ruleDefinition}
            statement={statement}
            datasetMenu={
              !!((canViewPlan && datasetsWithFields?.length > 0) || datasetsWithFields?.length > 1) && (
                <StatementRuleDatasetMenu
                  computedStatementDefinitions={statement.results.definitions}
                  datasets={datasetsWithFields}
                  selectDatasetToDisplay={setSelectedDataset}
                  selectedDataset={selectedDataset}
                />
              )
            }
            onSetCurrentTracingData={enrichTracingDataWithRule}
          />
        </RuleLayout.Dataset>
      )}
    </Fragment>
  );
});
