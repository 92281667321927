import { css } from '@emotion/react';

export const table = css`
  width: 100%;

  /** Need to use border-collapse: separate so the border-radius in cells work. */
  border-collapse: separate;
  border-spacing: 0;
`;

export const tableContainer = css`
  position: relative;
  overflow-x: auto;

  padding-bottom: 5px;
  margin-bottom: -5px;
`;

export const alertCell = css`
  padding: 16px;
`;
