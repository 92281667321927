import { useQuery } from '@tanstack/react-query';

import { type StatusStatementType } from '@amalia/core/types';
import { UserStatementsApiClient } from '@amalia/payout-calculation/statements/state';
import { type Period } from '@amalia/payout-definition/periods/types';
import { type PlanIsHiddenQueryChoices } from '@amalia/payout-definition/plans/types';

export const USE_STATEMENTS_FACETS_QUERY_KEY = 'statements-facets';

export type UseStatementFacetsParams = {
  isForecast?: boolean;
  period?: Period;
  planHiddenStatus?: PlanIsHiddenQueryChoices;
  planId?: string;
  statementStatus?: StatusStatementType;
  teamId?: string;
};

export const useStatementFacets = ({
  isForecast,
  period,
  planHiddenStatus,
  planId,
  statementStatus,
  teamId,
}: UseStatementFacetsParams) => {
  const { data: statementsListFacets, ...rest } = useQuery({
    queryKey: [
      USE_STATEMENTS_FACETS_QUERY_KEY,
      isForecast,
      period?.id,
      planHiddenStatus,
      planId,
      statementStatus,
      teamId,
    ],
    queryFn: () =>
      UserStatementsApiClient.getUserStatementsFacets({
        isForecast,
        periodId: period?.id,
        planHiddenStatus,
        planIds: planId ? [planId] : undefined,
        statementStatus,
        teamId,
      }),
    enabled: !!(period?.id || planId),
  });

  return {
    // Provides a default set of facets before initialization.
    statementsListFacets: statementsListFacets || {
      users: [],
      plans: [],
      teams: [],
      workflow: undefined,
    },
    ...rest,
  };
};
