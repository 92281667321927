import { memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormLayout, FormikInput, FormikSelect, type SelectOption } from '@amalia/design-system/components';
import { PlanRuleCategoryColor } from '@amalia/payout-definition/plans/types';

import { PlanRuleCategoryBadge } from '../../plan-rule-category-badge/PlanRuleCategoryBadge';

import { ruleCategoryColorMessages } from './PlanRuleCategoryFormFields.messages';

type ColorOption = SelectOption<PlanRuleCategoryColor>;

export const PlanRuleCategoryFormFields = memo(function PlanRuleCategoryFormFields() {
  const { formatMessage } = useIntl();

  const colorOptions: ColorOption[] = useMemo(
    () =>
      Object.values(PlanRuleCategoryColor)
        .map((color) => ({
          value: color,
          valueLabel: (
            <PlanRuleCategoryBadge category={{ name: formatMessage(ruleCategoryColorMessages[color]), color }} />
          ),
          label: (
            <PlanRuleCategoryBadge
              category={{ name: formatMessage(ruleCategoryColorMessages[color]), color }}
              size={PlanRuleCategoryBadge.Size.SMALL}
            />
          ),
          filterLabel: formatMessage(ruleCategoryColorMessages[color]),
        }))
        .toSorted((a, b) => a.filterLabel.localeCompare(b.filterLabel)),
    [formatMessage],
  );

  return (
    <FormLayout>
      <FormLayout.Group>
        <FormikInput
          autoFocus
          required
          label={<FormattedMessage defaultMessage="Name" />}
          name="name"
        />

        <FormikSelect
          required
          isClearable={false}
          label={<FormattedMessage defaultMessage="Color" />}
          name="color"
          options={colorOptions}
        />
      </FormLayout.Group>
    </FormLayout>
  );
});
