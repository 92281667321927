import { IconPencil } from '@tabler/icons-react';
import { type ComponentPropsWithoutRef, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { IconButton, MenuDropdown } from '@amalia/design-system/components';
import { useIsUpdatingAvatar } from '@amalia/tenants/users/profile/state';

import { UserAvatarDropdown } from './dropdown/UserAvatarDropdown';

export type UserAvatarAnchorProps = ComponentPropsWithoutRef<'div'>;

export const UserAvatarAnchor = memo(function UserAvatarAnchor(props: UserAvatarAnchorProps) {
  const isUpdatingAvatar = useIsUpdatingAvatar();

  return (
    <div {...props}>
      <UserAvatarDropdown disabled={isUpdatingAvatar}>
        <MenuDropdown.IconButton
          withBackground
          icon={<IconPencil />}
          isLoading={isUpdatingAvatar}
          label={<FormattedMessage defaultMessage="Edit avatar" />}
          size={IconButton.Size.SMALL}
        />
      </UserAvatarDropdown>
    </div>
  );
});
