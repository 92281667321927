import { groupBy, uniq } from 'lodash';

import { http } from '@amalia/core/http/client';
import {
  type TeamAssignmentDateFormat,
  type TeamAssignment,
  type TeamRole,
  type ComputedTeamAssignment,
  type ImportedTeamAssignmentsRows,
  type TeamAssignmentsValidateResponse,
} from '@amalia/tenants/assignments/teams/types';

import { TeamAssignmentsTransformer } from './team-assignments.transformer';

export class TeamAssignmentsApiClient {
  public static async getTeamAssignments(teamsIds: string[], teamRole?: TeamRole): Promise<TeamAssignmentDateFormat[]> {
    const { data } = await http.get<TeamAssignment[]>('/team_assignments', {
      params: {
        teamId: teamsIds,
        teamRole: teamRole || undefined,
      },
    });
    return data.map(TeamAssignmentsTransformer.formatTeamAssignmentFromApi);
  }

  public static async getTeamAssignmentsForUser(
    userId: string,
    teamRole?: TeamRole,
  ): Promise<TeamAssignmentDateFormat[]> {
    const { data } = await http.get<TeamAssignment[]>('/team_assignments', {
      params: {
        userId,
        relations: 'team',
        teamRole: teamRole || undefined,
      },
    });

    return data.map(TeamAssignmentsTransformer.formatTeamAssignmentFromApi);
  }

  public static async addTeamAssignment(
    teamId: string,
    userId: string,
    teamRole: TeamRole,
  ): Promise<TeamAssignmentDateFormat> {
    const { data } = await http.post<TeamAssignment>('/team_assignments', {
      team: { id: teamId },
      user: { id: userId },
      teamRole,
    });

    return TeamAssignmentsTransformer.formatTeamAssignmentFromApi(data);
  }

  public static async importBulkTeamAssignments(
    rowsToImport: ImportedTeamAssignmentsRows,
    dryRun: boolean = true,
  ): Promise<TeamAssignmentsValidateResponse> {
    const { data } = await http.post<TeamAssignmentsValidateResponse>('/team_assignments/bulk-import', {
      rowsToImport,
      dryRun,
    });
    return data;
  }

  public static async updateTeamAssignment(
    id: string,
    teamId: string,
    teamAssignment: TeamAssignmentDateFormat,
  ): Promise<TeamAssignmentDateFormat> {
    const { data } = await http.patch<TeamAssignment>(`/team_assignments/${id}`, {
      ...TeamAssignmentsTransformer.formatTeamAssignmentToApi(teamAssignment),
      team: { id: teamId },
    });
    return TeamAssignmentsTransformer.formatTeamAssignmentFromApi(data);
  }

  public static async removeTeamAssignment(id: string, teamId: string) {
    const { data } = await http.delete(`/team_assignments/${id}`, {
      data: {
        team: { id: teamId },
      },
    });
    return data;
  }

  public static async fetchTeamMembersAssignments(teamId: string) {
    const assignments = await this.getTeamAssignments([teamId]);
    return TeamAssignmentsTransformer.computeTeamAssignments(assignments);
  }

  public static async fetchMultipleTeamMembersAssignments(
    teamIds: string[],
  ): Promise<Record<string, Record<string, Partial<ComputedTeamAssignment>>>> {
    const assignments = await this.getTeamAssignments(teamIds);
    const assignmentsPerTeam = groupBy(assignments, 'teamId') as Record<string, TeamAssignmentDateFormat[]>;

    return uniq(Object.keys(assignmentsPerTeam)).reduce<
      Record<string, Record<string, Partial<ComputedTeamAssignment>>>
    >((acc, teamId) => {
      acc[teamId] = TeamAssignmentsTransformer.computeTeamAssignments(assignmentsPerTeam[teamId]);
      return acc;
    }, {});
  }

  public static async applyAssignmentDiff(
    teamId: string,
    previousAssignment: ComputedTeamAssignment | null,
    nextAssignment: ComputedTeamAssignment,
  ): Promise<void> {
    const updatedAssignment = nextAssignment;
    const { userId } = nextAssignment;

    // if assignemnt has 'new' as id, add it to db
    const newAssignIndex = updatedAssignment.assignments.findIndex((ass) => ass.id === 'new');
    if (newAssignIndex !== -1) {
      const assignmentToAdd = updatedAssignment.assignments[newAssignIndex];
      await this.addTeamAssignment(teamId, userId, assignmentToAdd.teamRole);
      // updatedAssignment.assignments[newAssignIndex].id = newAssignFromDatabase.id;
    }
    if (previousAssignment) {
      // Delete and Update assignments
      await Promise.all(
        previousAssignment.assignments.map(async (prevAssign) => {
          const nextAssign = updatedAssignment.assignments.find((ass) => ass.id === prevAssign.id);
          if (!nextAssign?.status) {
            return this.removeTeamAssignment(prevAssign.id, teamId);
          }
          return this.updateTeamAssignment(nextAssign.id, teamId, {
            id: nextAssign.id,
            teamRole: nextAssign.teamRole,
            userId,
            effectiveAsOf: nextAssign.effectiveAsOf,
            effectiveUntil: nextAssign.effectiveUntil,
          } as TeamAssignmentDateFormat);
        }),
      );
    }
  }
}
