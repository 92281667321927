import { type ReactNode, forwardRef, type ForwardedRef } from 'react';

import { Group, type GroupProps, Typography } from '@amalia/design-system/components';
import { TypographyVariant } from '@amalia/design-system/meta';
import { type MergeAll } from '@amalia/ext/typescript';

import { ForecastIcon } from '../forecast-icon/ForecastIcon';

export type ForecastAmountProps = MergeAll<
  [
    GroupProps,
    {
      /** Usable variables for the forecast amount. If a new variant appears in mockups just add it here and in the 2 maps below. */
      readonly variant:
        | TypographyVariant.BODY_BASE_MEDIUM
        | TypographyVariant.BODY_LARGE_MEDIUM
        | TypographyVariant.BODY_LARGE_REGULAR
        | TypographyVariant.HEADING_2_MEDIUM
        | TypographyVariant.HEADING_4_MEDIUM;
      /** Amount. */
      readonly children: ReactNode;
    },
  ]
>;

const TypographyVariantToGap: Record<ForecastAmountProps['variant'], number> = {
  [TypographyVariant.HEADING_2_MEDIUM]: 6,
  [TypographyVariant.HEADING_4_MEDIUM]: 6,
  [TypographyVariant.BODY_LARGE_MEDIUM]: 8,
  [TypographyVariant.BODY_LARGE_REGULAR]: 8,
  [TypographyVariant.BODY_BASE_MEDIUM]: 6,
} as const;

const TypographyVariantToIconSize: Record<ForecastAmountProps['variant'], number> = {
  [TypographyVariant.HEADING_2_MEDIUM]: 19.2,
  [TypographyVariant.HEADING_4_MEDIUM]: 16,
  [TypographyVariant.BODY_LARGE_MEDIUM]: 18,
  [TypographyVariant.BODY_LARGE_REGULAR]: 18,
  [TypographyVariant.BODY_BASE_MEDIUM]: 14.4,
} as const;

export const ForecastAmount = forwardRef(function ForecastAmount(
  { variant, children, ...props }: ForecastAmountProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <Group
      {...props}
      ref={ref}
      align="center"
      gap={TypographyVariantToGap[variant]}
    >
      <ForecastIcon size={TypographyVariantToIconSize[variant]} />

      <Typography variant={variant}>{children}</Typography>
    </Group>
  );
});
