import { css } from '@emotion/react';
import { IconCircleFilled } from '@tabler/icons-react';
import { isNil } from 'lodash';
import { memo } from 'react';

import { HueCategory } from '@amalia/design-system/meta';
import { canSeeHiddenPlan } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { type Plan } from '@amalia/payout-definition/plans/types';

export type PlanVisibilityIconProps = {
  readonly isPlanHidden?: Plan['isHidden'] | null;
};

export const IconPlanVisibility = memo(function PlanVisibilityIcon({ isPlanHidden }: PlanVisibilityIconProps) {
  const ability = useAbilityContext();

  // Non-admin user cannot see hidden plans, so there is no need to show the dot
  // since everything they'll see on the app are live plans.
  if (!canSeeHiddenPlan(ability)) {
    return null;
  }

  // In case the plan visibility is not defined, we don't show the dot.
  if (isNil(isPlanHidden)) {
    return null;
  }

  return (
    <IconCircleFilled
      size={6}
      css={(theme) => css`
        color: ${isPlanHidden ? theme.ds.hues[HueCategory.ORANGE][500] : theme.ds.hues[HueCategory.GREEN][500]};
      `}
    />
  );
});
