import { css } from '@emotion/react';

export const statementKpisList = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: none;

  // To make 0% aligned vertically with 100% on another card.
  min-width: 50px;
`;
