import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IconChevronLeft, IconInfoCircle, IconRotate } from '@tabler/icons-react';
import { type MouseEventHandler, memo, type Dispatch, type SetStateAction } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Button, IconButton, Popover, Switch, Typography } from '@amalia/design-system/components';
import { selectUsersMap } from '@amalia/frontend/web-data-layers';
import { WorkflowProgressBar } from '@amalia/payout-calculation/statements/components';
import { type WorkflowStatementState } from '@amalia/payout-collaboration/workflows/types';

import { WorkflowStepper } from '../../../../../workflow/WorkflowStepper/WorkflowStepper';

const PresentationContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  margin-top: 16px;

  &:empty {
    display: none;
  }
`;

const workflowDetailsStyle = css`
  margin-left: auto;

  display: flex;
  align-items: center;
  gap: 16px;

  > * {
    flex-shrink: 0;
  }
`;

type StatementWorkflowPresentationHeaderViewProps = {
  readonly isReviewed: boolean;
  readonly canToggleStep: boolean;
  readonly stepToPrint: {
    printReviewedBy: boolean;
    name: string;
  };
  readonly handleClickNextStep: (checked: boolean) => void;
  readonly isShowProgressBar: boolean;
  readonly stepNumber: number;
  readonly isTooltipOpen: boolean;
  readonly onReset: MouseEventHandler;
  readonly setTooltipOpened: Dispatch<SetStateAction<boolean>>;
  readonly canBackStep: boolean;
  readonly canResetSteps: boolean;
  readonly onPreviousStep: MouseEventHandler;
  readonly steps: Partial<WorkflowStatementState>[];
  readonly stepCount: number;
};

export const StatementWorkflowPresentationHeaderView = memo(function StatementWorkflowPresentationHeaderView({
  isReviewed,
  canToggleStep,
  stepToPrint,
  handleClickNextStep,
  isShowProgressBar,
  stepNumber,
  isTooltipOpen,
  onReset,
  setTooltipOpened,
  canBackStep,
  canResetSteps,
  onPreviousStep,
  steps,
  stepCount,
}: StatementWorkflowPresentationHeaderViewProps) {
  const { formatMessage } = useIntl();
  const usersMap = useSelector(selectUsersMap);
  return (
    <PresentationContainer>
      <Switch
        checked={isReviewed}
        disabled={!canToggleStep}
        label={
          <span>
            {!!stepToPrint.printReviewedBy && (
              <FormattedMessage
                defaultMessage="Review by {reviewer}"
                values={{ reviewer: stepToPrint.name }}
              />
            )}

            {!!isReviewed && (
              <Typography variant={Typography.Variant.BODY_BASE_BOLD}>
                <FormattedMessage defaultMessage="Reviewed" />
              </Typography>
            )}
          </span>
        }
        onChange={handleClickNextStep}
      />

      {!!isShowProgressBar && (
        <div css={workflowDetailsStyle}>
          <div
            css={css`
              width: 115px;
            `}
          >
            <WorkflowProgressBar
              currentStep={stepNumber}
              totalSteps={stepCount}
            />
          </div>

          <Popover
            isOpen={isTooltipOpen}
            content={
              <Popover.Layout>
                <Popover.Header>
                  <Popover.Title>
                    <FormattedMessage defaultMessage="Workflow summary" />
                  </Popover.Title>
                </Popover.Header>
                <Popover.Body>
                  <WorkflowStepper
                    hideTitle
                    compact={stepNumber === 0}
                    usersMap={usersMap}
                    workflowState={steps}
                  />

                  <ActionsContainer>
                    {!!(canBackStep && onPreviousStep) && (
                      <Button
                        icon={<IconChevronLeft />}
                        size={Button.Size.SMALL}
                        variant={Button.Variant.LIGHT}
                        onClick={onPreviousStep}
                      >
                        <FormattedMessage defaultMessage="Undo last step" />
                      </Button>
                    )}

                    {!!(canResetSteps && onReset) && (
                      <Button
                        icon={<IconRotate />}
                        size={Button.Size.SMALL}
                        variant={Button.Variant.LIGHT}
                        onClick={onReset}
                      >
                        <FormattedMessage defaultMessage="Reset" />
                      </Button>
                    )}
                  </ActionsContainer>
                </Popover.Body>
              </Popover.Layout>
            }
            onChangeIsOpen={setTooltipOpened}
          >
            <IconButton
              icon={<IconInfoCircle />}
              isActive={isTooltipOpen}
              label={formatMessage({ defaultMessage: 'View workflow summary' })}
            />
          </Popover>
        </div>
      )}
    </PresentationContainer>
  );
});
