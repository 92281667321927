import styled from '@emotion/styled';

export const PopoverHeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  /* The buttons have a right padding of 16px instead of 24px so I just reduced the gap in PopoverLayout and shifted the div here :david-goodenough:. */
  position: relative;
  left: 8px;
`;
