import { type Action, type Dispatch, type Middleware, type MiddlewareAPI } from 'redux';

import { addSnackbar } from './snackbars/actions';
import { type ReduxAction } from './types';

export const errorHandler: Middleware = (api: MiddlewareAPI) => (next: Dispatch<Action>) => (action: ReduxAction) => {
  if ('error' in action) {
    api.dispatch(
      addSnackbar({
        message: action.error.message,
        options: { variant: 'error' },
      }),
    );
  }
  return next(action);
};
