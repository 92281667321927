import { TypographyVariant } from '@amalia/design-system/meta';

import { LabelVariant } from '../../types';

export const BooleanLabelTypographyVariantMap: Record<LabelVariant, TypographyVariant> = {
  [LabelVariant.DEFAULT]: TypographyVariant.BODY_BASE_REGULAR,
  [LabelVariant.DIMMED]: TypographyVariant.BODY_BASE_REGULAR,
  [LabelVariant.ACCENTUATED]: TypographyVariant.BODY_BASE_MEDIUM,
  [LabelVariant.BOLD]: TypographyVariant.BODY_BASE_MEDIUM,
} as const;
