import { css } from '@emotion/react';
import { IconPercentage } from '@tabler/icons-react';
import { get, noop } from 'lodash';
import { Fragment, memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  type FormulaBuilderFunctionBlockNumberForm,
  type FormulaBuilderFunctionBlockPercentForm,
  isFormulaBuilderFunctionBlockNumberOrPercentOrCurrencyNoArgsForm,
  type FormulaBuilderFunctionBlockCurrencyForm,
} from '@amalia/amalia-lang/formula/form/types';
import { FormulaBuilderFunctionCategory, ValueOrAttributeType } from '@amalia/amalia-lang/formula/types';
import { Dropdown, Tooltip } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';

import { useFormatFunctionBlockValue } from '../../hooks/use-format-function-block-value/useFormatFunctionBlockValue';
import { useGetFormulaBuilderAttributeLabel } from '../../hooks/use-get-formula-builder-attribute-label/useGetFormulaBuilderAttributeLabel';
import { useFormulaBuilderContext } from '../formula-builder/FormulaBuilder.context';
import { FormulaConditionTag, type FormulaConditionTagProps } from '../formula-condition-tag/FormulaConditionTag';

import { operatorTooltipMessage } from './FormulaConditionTagFunctionNumber.messages';
import * as styles from './FormulaConditionTagFunctionNumber.styles';
import { NumberOperatorNoArgsToIcon } from './FormulaConditionTagFunctionNumberIcon.mapper';
import { FunctionNumberPopover } from './function-number-popover/FunctionNumberPopover';

const getTagLabelIcon = (category: FormulaBuilderFunctionCategory) => {
  if (category === FormulaBuilderFunctionCategory.PERCENT) {
    return (
      <IconPercentage
        data-testid="icon-percentage"
        size="14"
      />
    );
  }
  return null;
};

export type FormulaConditionTagFunctionNumberProps<
  T extends
    | FormulaBuilderFunctionBlockCurrencyForm
    | FormulaBuilderFunctionBlockNumberForm
    | FormulaBuilderFunctionBlockPercentForm,
> = {
  readonly condition: T;
  readonly path: string;
  readonly onChange?: FormulaConditionTagProps<T>['onChange'];
  readonly onDelete?: FormulaConditionTagProps<T>['onDelete'];
};

const FormulaConditionTagFunctionNumberBase = function FormulaConditionTagFunctionNumber<
  T extends
    | FormulaBuilderFunctionBlockCurrencyForm
    | FormulaBuilderFunctionBlockNumberForm
    | FormulaBuilderFunctionBlockPercentForm,
>({ condition, path, onChange = noop, onDelete = undefined }: FormulaConditionTagFunctionNumberProps<T>) {
  const { activeConditionId, errors: builderErrors } = useFormulaBuilderContext();
  const isActive = condition.id === activeConditionId;
  const getFormulaBuilderAttributeLabel = useGetFormulaBuilderAttributeLabel();

  const isInitialOpen = !!condition.isDraft && isActive;
  const { isOpen, setOpen, setOpenFalse } = useBoolState(isInitialOpen, 'open');

  const errors: string[] = useMemo(
    () =>
      [
        get(builderErrors, `${path}.args[1].value`) as string | undefined,
        get(builderErrors, `${path}.args[1].fieldType`) as string | undefined,
        get(builderErrors, `${path}.args[1].options.transform`) as string | undefined,
        get(builderErrors, `${path}.args[1].options.value`) as string | undefined,
      ].filter(Boolean),
    [builderErrors, path],
  );

  const tagLabelIcon = getTagLabelIcon(condition.category);

  const valueFormatted = useFormatFunctionBlockValue(
    condition.category,
    condition.args[1]?.type === ValueOrAttributeType.VALUE ? condition.args[1].value : null,
    true,
  );

  const propertyName = getFormulaBuilderAttributeLabel(condition.args[0]);
  const getTooltipContent = () => {
    if (!condition.operator) {
      return <FormattedMessage defaultMessage="Click to edit condition" />;
    }

    if (isFormulaBuilderFunctionBlockNumberOrPercentOrCurrencyNoArgsForm(condition)) {
      return <FormattedMessage {...operatorTooltipMessage[condition.operator]} />;
    }

    return (
      <FormattedMessage
        {...operatorTooltipMessage[condition.operator]}
        values={{
          value:
            condition.args[1].type === ValueOrAttributeType.VALUE ? (
              <Fragment>
                {valueFormatted}
                {tagLabelIcon}
              </Fragment>
            ) : (
              getFormulaBuilderAttributeLabel(condition.args[1])
            ),
        }}
      />
    );
  };

  const getTagLabel = () => {
    if (!condition.operator) {
      return propertyName;
    }
    if (isFormulaBuilderFunctionBlockNumberOrPercentOrCurrencyNoArgsForm(condition)) {
      return (
        <div css={styles.tagLabelContainer}>
          {propertyName}
          <NumberOperatorNoArgsToIcon
            operator={condition.operator}
            size={16}
          />
        </div>
      );
    }

    return (
      <div css={styles.tagLabelContainer}>
        {propertyName}
        <NumberOperatorNoArgsToIcon
          operator={condition.operator}
          size={14}
        />
        {condition.args[1].type === ValueOrAttributeType.VALUE ? (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 4px;
            `}
          >
            {valueFormatted}
            {tagLabelIcon}
          </div>
        ) : (
          getFormulaBuilderAttributeLabel(condition.args[1])
        )}
      </div>
    );
  };

  return (
    <Dropdown
      isOpen={isOpen}
      content={
        <FunctionNumberPopover
          condition={condition}
          onChange={onChange}
          onClose={setOpenFalse}
        />
      }
      onChangeIsOpen={setOpen}
    >
      <FormulaConditionTag
        condition={condition}
        errors={errors}
        label={
          <Tooltip
            title={propertyName || null}
            content={
              <span
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                {getTooltipContent()}
              </span>
            }
          >
            <span>{getTagLabel()}</span>
          </Tooltip>
        }
        onDelete={onDelete}
      />
    </Dropdown>
  );
};

export const FormulaConditionTagFunctionNumber = memo(
  FormulaConditionTagFunctionNumberBase,
) as typeof FormulaConditionTagFunctionNumberBase;
