import { IconRefresh } from '@tabler/icons-react';
import { Form } from 'formik';
import { capitalize } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import { type DataConnector } from '@amalia/data-capture/connectors/types';
import {
  FormLayout,
  FormikCheckbox,
  FormikInput,
  FormikSelect,
  Modal,
  type ModalProps,
  type SelectOption,
  type SelectOptionGroup,
} from '@amalia/design-system/components';
import { FormikForm, type FormikFormProps } from '@amalia/ext/formik';
import { Spinner, SpinnerBoundary } from '@amalia/lib-ui';

type ObjectSelectOption = SelectOption<string>;

type DataFormValues = {
  willLaunchCalculation: boolean;
  willPrune: boolean;
  willFullSync: boolean;
  startOffset: number;
  dataConnectorObjectsNames: string[];
};

interface DataModalProps {
  readonly isOpen: ModalProps['isOpen'];
  readonly handleRefreshment: (
    dataConnectorObjectNames: { type: string; name: string }[],
    params: {
      willLaunchCalculation: boolean;
      willPrune: boolean;
      willFullSync: boolean;
      startOffset: number;
    },
  ) => void;
  readonly onClose: ModalProps['onClose'];
  readonly defaultDataConnectorObjectName: string;
  readonly isLoading: boolean;
  readonly connectors: DataConnector[];
}

export const DataModal = memo(function DataModal({
  isOpen,
  handleRefreshment,
  onClose,
  defaultDataConnectorObjectName,
  isLoading,
  connectors,
}: DataModalProps) {
  const { formatMessage } = useIntl();

  const initialValues: DataFormValues = useMemo(
    () => ({
      willLaunchCalculation: true,
      willPrune: false,
      willFullSync: false,
      startOffset: 0,
      dataConnectorObjectsNames: [defaultDataConnectorObjectName],
    }),
    [defaultDataConnectorObjectName],
  );

  const schema = useMemo(
    () =>
      Yup.object().shape({
        dataConnectorObjectsNames: Yup.array().min(
          1,
          formatMessage({ defaultMessage: 'Please select at least one object to refresh' }),
        ),
        startOffset: Yup.number().min(0, formatMessage({ defaultMessage: 'Start offset cannot be negative' })),
        willFullSync: Yup.boolean(),
        willLaunchCalculation: Yup.boolean(),
        willPrune: Yup.boolean(),
      }),
    [formatMessage],
  );

  const handleSubmitProxy: FormikFormProps<DataFormValues>['onSubmit'] = useCallback(
    (values, { resetForm }) => {
      const parameters = {
        willLaunchCalculation: values.willLaunchCalculation,
        willPrune: values.willPrune,
        willFullSync: values.willFullSync,
        startOffset: values.startOffset,
      };
      const dataConnectorTypeAndNames: { type: string; name: string }[] = values.dataConnectorObjectsNames.map(
        (item: string) => {
          const [type, name] = item.split('|');
          return { type, name };
        },
      );
      handleRefreshment(dataConnectorTypeAndNames, parameters);
      resetForm();
    },
    [handleRefreshment],
  );

  // this create the optionGroup[] for the select (sorted alphabetically),
  // it takes all customObjectDefinition and group them by type (label)
  // and then put the name (label) and id (value) into the options
  // it also remove the CustomObjectDefinitionType Amalia and Virtual
  // as it's not relevant to have them in the refreshment list
  const dataConnectorObjectsOptions: SelectOptionGroup<ObjectSelectOption>[] = useMemo(
    () =>
      connectors
        .filter((connector) => (connector.source?.objects || []).length > 0)
        .map((connector) => ({
          label: `${capitalize(connector.type)}`,
          options: (connector.source?.objects || [])
            .map((object) => ({
              value: `${connector.type}|${object.alias || object.name}`,
              label: object.alias || object.name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [connectors],
  );

  if (isLoading) {
    return (
      <SpinnerBoundary>
        <Spinner />
      </SpinnerBoundary>
    );
  }

  return (
    <FormikForm<DataFormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmitProxy}
    >
      {({ isValid, isSubmitting }) => (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
        >
          <Form>
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>
                  <FormattedMessage defaultMessage="Refresh data objects" />
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <FormLayout>
                  <FormLayout.Group>
                    <FormikSelect<ObjectSelectOption, true>
                      isMultiple
                      required
                      id="dataConnectorObjectsNames"
                      label={<FormattedMessage defaultMessage="Choose objects to refresh" />}
                      name="dataConnectorObjectsNames"
                      options={dataConnectorObjectsOptions}
                    />

                    <FormLayout.CheckboxGroup>
                      <FormikCheckbox
                        name="willLaunchCalculation"
                        label={
                          <FormattedMessage defaultMessage="Launch calculation for current period statements after data refresh" />
                        }
                      />

                      <FormikCheckbox
                        name="willPrune"
                        label={
                          <FormattedMessage defaultMessage="Remove from Amalia records that were deleted in the source data object" />
                        }
                      />

                      <FormikCheckbox
                        name="willFullSync"
                        label={
                          <FormattedMessage defaultMessage="Update all past records on Amalia and not just new and updated records" />
                        }
                      />
                    </FormLayout.CheckboxGroup>

                    <FormikInput
                      label={<FormattedMessage defaultMessage="Start offset" />}
                      min="0"
                      name="startOffset"
                      type="number"
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Modal.Body>
            </Modal.Content>
            <Modal.Actions>
              <Modal.CancelAction />

              <Modal.MainAction
                disabled={!isValid || isSubmitting}
                icon={<IconRefresh />}
                type="submit"
              >
                <FormattedMessage defaultMessage="Refresh" />
              </Modal.MainAction>
            </Modal.Actions>
          </Form>
        </Modal>
      )}
    </FormikForm>
  );
});

DataModal.displayName = 'DataModal';
