import { type Theme, css } from '@emotion/react';

export const valuesGrid = css`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
`;

export const sectionContainer = (theme: Theme) => css`
  padding: 16px 24px;

  & + & {
    border-top: 1px solid ${theme.ds.colors.gray[100]};
  }
`;

export const overwrittenValue = (theme: Theme) => css`
  color: ${theme.ds.colors.primary[400]};
`;
