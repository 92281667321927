import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { assert } from '@amalia/ext/typescript';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.LEFT, AmaliaFunctionCategory.STRING);

func.nbParamsRequired = 1;

func.description = 'Return the left part of a string based on a given number of characters';

func.params = [
  { name: 'string', description: 'String: variables, fields, properties, string' },
  { name: 'numChars', description: 'Number of characters to take, by default it is 1', defaultValue: '1' },
];

func.examples = [
  {
    desc: 'Returns co.',
    formula: 'LEFT("commission", 2)' as AmaliaFormula,
    result: 'co',
  },
  {
    desc: 'Returns c.',
    formula: 'LEFT("commission")' as AmaliaFormula,
    result: 'c',
  },
  {
    desc: 'Returns commission.',
    formula: 'LEFT("commission", 24)' as AmaliaFormula,
    result: 'commission',
  },
];

func.exec = (text: { toString: () => string } | null | undefined, numChars?: number): string | null => {
  assert(text !== null && text !== undefined, `${AmaliaFunctionKeys.LEFT}: source string is null or undefined`);

  return text.toString().substring(0, numChars || 1);
};

export default func;
