import { type Merge } from '@amalia/ext/typescript';
import { type TeamContract } from '@amalia/tenants/teams/types';
import { type UserContract } from '@amalia/tenants/users/types';

export interface TeamAssignment {
  id: string;
  createdAt: Date;
  updatedAt: Date;

  effectiveAsOf: number | null;
  effectiveUntil: number | null;
  teamRole: TeamRole;

  team?: TeamContract;
  teamId: string;
  user?: UserContract;
  userId: string;

  hierarchy?: string[];
}

export type TeamAssignmentDateFormat = Merge<
  TeamAssignment,
  { effectiveAsOf: Date | null; effectiveUntil: Date | null }
>;

export interface TeamAssignmentWithTeam extends TeamAssignment {
  team: TeamContract;
}

export enum TeamRole {
  TEAM_MANAGER = 'TEAM_MANAGER',
  TEAM_EMPLOYEE = 'TEAM_EMPLOYEE',
}

export interface ComputedTeamSubAssignment {
  id: string;
  status: boolean;
  teamRole: TeamRole;
  effectiveAsOf: Date | null;
  effectiveUntil: Date | null;
}

export interface ComputedTeamAssignment {
  id: string;
  userId: string;

  assignments: ComputedTeamSubAssignment[];
}
