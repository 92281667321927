import { css } from '@emotion/react';
import { type ComponentPropsWithoutRef, memo } from 'react';

import { Group } from '@amalia/design-system/components';

type SearchModalListElementProps = ComponentPropsWithoutRef<typeof Group>;

export const SearchModalListElement = memo(function SearchModalListElement({
  children,
  ...props
}: SearchModalListElementProps) {
  return (
    <Group
      {...props}
      align="center"
      css={(theme) => css`
        padding: 8px;
        background-color: ${theme.ds.colors.gray[50]};
      `}
    >
      {children}
    </Group>
  );
});
