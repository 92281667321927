import { IconX } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../../general/button/Button';
import { Typography, type TypographyProps } from '../../../general/typography/Typography';
import { useModalContext } from '../Modal.context';

import * as styles from './ModalTitle.styles';

export type ModalTitleProps = Omit<TypographyProps, 'variant'>;

export const ModalTitle = memo(function ModalTitle({ children, ...props }: ModalTitleProps) {
  const { onCloseModal } = useModalContext();

  return (
    <div css={styles.modalTitle}>
      <Typography
        {...props}
        variant={Typography.Variant.HEADING_3_BOLD}
      >
        {children}
      </Typography>

      <Button
        icon={<IconX />}
        size={Button.Size.SMALL}
        variant={Button.Variant.LIGHT_TEXT}
        onClick={onCloseModal}
      >
        <FormattedMessage defaultMessage="Close" />
      </Button>
    </div>
  );
});
