import { type Theme, css } from '@emotion/react';

import { SelectSize } from '../../Select.types';

export const multiValueContainer = (theme: Theme) => css`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;

  transition: ${theme.ds.transitions.default('gap')};

  > * {
    flex: none;
  }

  &.${SelectSize.SMALL} {
    gap: 6px;
  }

  &.${SelectSize.MEDIUM} {
    gap: 8px;
  }
`;

export const offscreen = css`
  position: fixed;
  top: -9999px;
  left: -9999px;
`;
