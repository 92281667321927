import { type VariableDefinition } from '@amalia/amalia-lang/tokens/types';
import { type RecordContent, type RecordContentPropertyType } from '@amalia/data-capture/connectors/types';
import { type FormatsEnum } from '@amalia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { type OverwriteScopeEnum } from '@amalia/data-correction/overwrites/types';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { type Period } from '@amalia/payout-definition/periods/types';
import { type Filter, type Plan, type Rule } from '@amalia/payout-definition/plans/types';
import { type UserContract } from '@amalia/tenants/users/types';

import { type Option } from '../index';

import { type CustomObject } from './customObject';
import { type Statement } from './statement';

export enum OverwriteTypesEnum {
  PROPERTY = 'PROPERTY',
  FIELD = 'FIELD',
  KPI = 'KPI',
  PAYMENT = 'PAYMENT',
  FILTER_ROW_ADD = 'FILTER_ROW_ADD',
  FILTER_ROW_REMOVE = 'FILTER_ROW_REMOVE',
}

// Properties used in all overwrite types.
interface OverwriteBaseCommon {
  id: string;

  // Reference to the property or variable machineName
  field: string;

  sourceValue: RecordContent | RecordContentPropertyType;
  overwriteValue: RecordContent | RecordContentPropertyType;

  createdAt?: Date | null;
  clearedAt?: Date | null;
  updatedAt?: Date | null;

  creator: UserContract;
  currency?: CurrencySymbolsEnum;
  overwriteType: OverwriteTypesEnum;

  scope: OverwriteScopeEnum;
}

// Properties that are optional based on the overwrite type
// TODO: this can be split into a union of types (waiting for strict mode).
export interface Overwrite extends OverwriteBaseCommon {
  // PAYMENT
  paymentId?: string;

  // VARIABLES
  statementId?: Statement['id'];
  statement?: Statement;

  variableId?: VariableDefinition['id'];
  variable?: VariableDefinition;

  createdOnStatementId?: Statement['id'];
  createdOnStatement?: Statement;

  // PROPERTY
  appliesToDefinitionId?: string;
  appliesToDefinition?: CustomObjectDefinition;
  appliesToExternalId?: string;
  appliesToDealName?: string;
  appliesToDefinitionName?: string;
  ruleId?: string;

  filterId?: string;
  filter?: Filter;
}

export interface OverwriteResponse
  extends Pick<
    Overwrite,
    | 'appliesToDealName'
    | 'appliesToDefinitionId'
    | 'appliesToExternalId'
    | 'clearedAt'
    | 'createdAt'
    | 'createdOnStatementId'
    | 'currency'
    | 'field'
    | 'id'
    | 'overwriteType'
    | 'overwriteValue'
    | 'paymentId'
    | 'scope'
    | 'sourceValue'
    | 'statementId'
  > {
  creator: Pick<UserContract, 'firstName' | 'lastName' | 'pictureURL'>;
  rule?: Pick<Rule, 'name'>;
  statement?: Pick<Statement, 'id'> & {
    user: Pick<UserContract, 'firstName' | 'lastName' | 'pictureURL'>;
    period: Pick<Period, 'name'>;
    plan: Pick<Plan, 'id' | 'name'>;
  };

  // Added fields for display.
  customObjectDefinitionName?: string;
  customObjectDefinitionMachineName?: string;
  propertyFormat?: FormatsEnum;
  propertyName?: string;
}

export interface OverwriteFiltersResponse {
  users?: Option[];
  periods?: Option[];
  plans?: Option[];
  overwrittenBy?: Option[];
  types?: Option[];
  dataSources?: Option[];
}

export interface ClearOverwriteRequest {
  overwriteId: string;
}

export interface BulkOverwriteCreateRequest {
  statementId?: string;
  createdOnStatementId?: string;

  variableId?: string;
  // OR
  appliesToDefinitionId?: string;
  appliesToExternalId?: string;

  fieldMachineName?: string;
  // OR

  // Objects for rows, number for KPIs.
  sourceValue: number | object | string;
  overwriteValue: number | object | string;

  // Populated on GET
  customObject?: CustomObject;

  paymentId?: string;

  currency?: CurrencySymbolsEnum;

  ruleId?: string;

  overwriteType?: OverwriteTypesEnum;
}
