import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { assert } from '@amalia/ext/typescript';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.SPLIT, AmaliaFunctionCategory.STRING);

func.nbParamsRequired = 3;

func.description = 'Takes a pattern and divides a String into an ordered list of substrings';
// + ' by searching for all matches of this pattern.'
// + ' It returns the n-th resulting substrings'

func.params = [
  { name: 'string', description: 'String: variables, fields, properties, string' },
  {
    name: 'separator',
    description: 'The pattern describing where each split should occur',
  },
  {
    name: 'position',
    description: 'The position of the split part the function should return. Starting at 0 for the first part',
  },
];

func.examples = [
  {
    desc: 'Returns first.',
    formula: 'SPLIT("first,second", ",", 0)' as AmaliaFormula,
    result: 'first',
  },
  {
    desc: 'Returns second.',
    formula: 'SPLIT("first,second", ",", 1)' as AmaliaFormula,
    result: 'second',
  },
  {
    desc: 'Returns null since all split parts are before that position.',
    formula: 'SPLIT("first,second", ",", 30)' as AmaliaFormula,
    result: null,
  },
  {
    desc: 'Returns first.',
    formula: 'SPLIT("first", ",", 0)' as AmaliaFormula,
    result: 'first',
  },
];

func.execMock = () => 'mock';

func.exec = (text: { toString: () => string } | null | undefined, separator: string, index: number): string | null => {
  assert(text !== null && text !== undefined, `${AmaliaFunctionKeys.SPLIT}: source string is null or undefined`);

  return text.toString().split(separator)[index] ?? null;
};

export default func;
