import { defineMessages } from 'react-intl';

import { ChangelogReleaseType, ChangelogRole } from '@amalia/amalia-meta/changelog/shared';

export const changelogEntryReleaseTypeMessages = defineMessages<ChangelogReleaseType>({
  [ChangelogReleaseType.NEW_RELEASE]: { defaultMessage: 'New release' },
  [ChangelogReleaseType.IMPROVEMENT]: { defaultMessage: 'Improvement' },
} as const);

export const changelogEntryRoleMessages = defineMessages<ChangelogRole>({
  [ChangelogRole.ADMIN]: { defaultMessage: 'Admin' },
  [ChangelogRole.MANAGER]: { defaultMessage: 'Manager' },
  [ChangelogRole.SALESREP]: { defaultMessage: 'Sales rep' },
} as const);
