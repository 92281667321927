import { noop } from 'lodash';
import moment from 'moment';
import { type Dispatch, type SetStateAction, memo, useCallback, useMemo } from 'react';

import { type VariableDefinition } from '@amalia/amalia-lang/tokens/types';
import { type ComputedVariableWithVariableIdAndLabel, formatTotal } from '@amalia/core/types';
import { type OverwriteCreationRequestDetails } from '@amalia/data-correction/overwrites/components';
import { OverwriteScopeEnum } from '@amalia/data-correction/overwrites/types';
import { CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { useCompanyCustomization } from '@amalia/frontend/web-data-layers';
import { useStatementDetailContext } from '@amalia/lib-ui';
import { StatementKpi } from '@amalia/lib-ui-business';
import { ForecastSimulationPopover } from '@amalia/payout-calculation/forecasts/components';
import { type ComputedVariable, type ComputeEnginePrimitiveTypes } from '@amalia/payout-calculation/types';
import { type PlanRule } from '@amalia/payout-definition/plans/types';

interface ForecastedKPIProps {
  readonly computedVariable: ComputedVariableWithVariableIdAndLabel;
  readonly variableDefinition: VariableDefinition;
  readonly canSimulate?: boolean;
  readonly setOverwriteObjectDetails: Dispatch<SetStateAction<OverwriteCreationRequestDetails>>;
  readonly openOverwriteModal: () => void;
  readonly ruleDefinition: PlanRule;
  readonly resetOverwrite: (variable: ComputedVariable) => Promise<void>;
}

export const ForecastedKPI = memo(function ForecastedKPIProps({
  computedVariable,
  variableDefinition,
  canSimulate,
  setOverwriteObjectDetails,
  openOverwriteModal,
  ruleDefinition,
  resetOverwrite,
}: ForecastedKPIProps) {
  const {
    statement: { currency, rate },
  } = useStatementDetailContext();
  const { legacyKpiCardView } = useCompanyCustomization();

  const handleOpenOverwriteModal = useCallback(() => {
    setOverwriteObjectDetails({
      format: variableDefinition.format,
      oldValue: (computedVariable.overwrite?.overwriteValue || computedVariable.value) as number,
      currency: computedVariable.currency,
      field: variableDefinition.name,
      isProperty: false,
      machineName: variableDefinition.machineName,
      rule: ruleDefinition.name,
    });
    openOverwriteModal();
  }, [
    openOverwriteModal,
    setOverwriteObjectDetails,
    computedVariable.currency,
    computedVariable.overwrite?.overwriteValue,
    computedVariable.value,
    variableDefinition.format,
    variableDefinition.name,
    variableDefinition.machineName,
    ruleDefinition.name,
  ]);

  const resetOverwriteProxy = useCallback(() => resetOverwrite(computedVariable), [resetOverwrite, computedVariable]);

  const { formattedAchievedValue, formattedForecastedValue, overwriteInfo, isValueForecasted } = useMemo(() => {
    const kpiCurrency = computedVariable.currency || currency || CurrencySymbolsEnum.EUR;
    const isKpiSimulated =
      computedVariable.overwrite && computedVariable.overwrite.scope === OverwriteScopeEnum.FORECAST;
    const isKpiForecasted = computedVariable.achievedValue !== computedVariable.value;

    return {
      formattedAchievedValue: formatTotal(computedVariable.achievedValue, variableDefinition.format, kpiCurrency, rate),
      formattedForecastedValue: formatTotal(
        computedVariable.value as ComputeEnginePrimitiveTypes,
        variableDefinition.format,
        kpiCurrency,
        rate,
      ),
      overwriteInfo: isKpiSimulated && {
        sourceValue: formatTotal(
          computedVariable.overwrite?.sourceValue as ComputeEnginePrimitiveTypes,
          variableDefinition.format,
          kpiCurrency,
          rate,
        ),
        value: formatTotal(
          computedVariable.overwrite?.overwriteValue as ComputeEnginePrimitiveTypes,
          variableDefinition.format,
          kpiCurrency,
          rate,
        ),
        author: computedVariable.overwrite?.creator,
        date: moment(computedVariable.overwrite.createdAt, 'YYYY-MM-DD').format('MMMM Do, YYYY'),
      },
      isValueForecasted: isKpiForecasted,
    };
  }, [computedVariable, currency, variableDefinition.format, rate]);

  return (
    <div key={computedVariable.variableMachineName}>
      <ForecastSimulationPopover
        formattedAchievedValue={formattedAchievedValue}
        formattedForecastedValue={formattedForecastedValue}
        overwriteInfo={overwriteInfo}
        onDeleteOverwrite={resetOverwriteProxy}
      >
        <div>
          <StatementKpi
            canComment={false}
            computedVariable={computedVariable}
            handleOpenModal={handleOpenOverwriteModal}
            isKpiForecasted={!!(isValueForecasted || overwriteInfo)}
            isReadOnly={!canSimulate}
            openStatementThread={noop}
            useLegacyKpiCardView={legacyKpiCardView}
            variableDefinition={variableDefinition}
          />
        </div>
      </ForecastSimulationPopover>
    </div>
  );
});
