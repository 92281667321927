import { useTheme, css } from '@emotion/react';
import { noop } from 'lodash';
import { Fragment, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type FormulaBuilderFunctionBlockBooleanForm } from '@amalia/amalia-lang/formula/form/types';
import { FormulaBuilderBooleanOperatorNoArgs } from '@amalia/amalia-lang/formula/types';
import { Dropdown, Tooltip } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';

import { useGetFormulaBuilderAttributeLabel } from '../../hooks/use-get-formula-builder-attribute-label/useGetFormulaBuilderAttributeLabel';
import { useFormulaBuilderContext } from '../formula-builder/FormulaBuilder.context';
import { FormulaConditionTag, type FormulaConditionTagProps } from '../formula-condition-tag/FormulaConditionTag';

import { formulaBuilderBooleanOperatorNoArgsMessages } from './FormulaConditionTagFunctionBoolean.messages';
import { BooleanOperatorNoArgsToIcon } from './FormulaConditionTagFunctionBooleanIcon.mapper';
import { FunctionBooleanPopover } from './function-boolean-popover/FunctionBooleanPopover';

export type FormulaConditionTagFunctionBooleanProps = {
  readonly condition: FormulaBuilderFunctionBlockBooleanForm;
  readonly onChange?: FormulaConditionTagProps<FormulaBuilderFunctionBlockBooleanForm>['onChange'];
  readonly onDelete?: FormulaConditionTagProps<FormulaBuilderFunctionBlockBooleanForm>['onDelete'];
};

export const FormulaConditionTagFunctionBoolean = memo(function FormulaConditionTagFunctionBoolean({
  condition,
  onChange = noop,
  onDelete = undefined,
}: FormulaConditionTagFunctionBooleanProps) {
  const theme = useTheme();
  const { activeConditionId } = useFormulaBuilderContext();
  const isActive = condition.id === activeConditionId;
  const getFormulaBuilderAttributeLabel = useGetFormulaBuilderAttributeLabel();

  const isInitialOpen = !!condition.isDraft && isActive;
  const { isOpen, setOpen, setOpenFalse } = useBoolState(isInitialOpen, 'open');

  const propertyName = getFormulaBuilderAttributeLabel(condition.args[0]);

  const getTooltipContent = () => {
    if (!condition.operator) {
      return <FormattedMessage defaultMessage="Click to edit condition" />;
    }
    if (
      [FormulaBuilderBooleanOperatorNoArgs.IS_BLANK, FormulaBuilderBooleanOperatorNoArgs.IS_NOT_BLANK].includes(
        condition.operator,
      )
    ) {
      return <FormattedMessage {...formulaBuilderBooleanOperatorNoArgsMessages[condition.operator]} />;
    }
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 6px;
        `}
      >
        <BooleanOperatorNoArgsToIcon
          operator={condition.operator}
          theme={theme}
        />
        <FormattedMessage {...formulaBuilderBooleanOperatorNoArgsMessages[condition.operator]} />
      </div>
    );
  };

  const getTagLabel = () => {
    if (!condition.operator) {
      return propertyName;
    }
    return (
      <Fragment>
        {propertyName}
        <BooleanOperatorNoArgsToIcon
          operator={condition.operator}
          theme={theme}
        />
      </Fragment>
    );
  };

  return (
    <Dropdown
      isOpen={isOpen}
      content={
        <FunctionBooleanPopover
          condition={condition}
          onChange={onChange}
          onClose={setOpenFalse}
        />
      }
      onChangeIsOpen={setOpen}
    >
      <FormulaConditionTag
        condition={condition}
        label={
          <Tooltip
            content={getTooltipContent()}
            title={propertyName || null}
          >
            <span
              css={css`
                display: flex;
                align-items: center;
                gap: 4px;
              `}
            >
              {getTagLabel()}
            </span>
          </Tooltip>
        }
        onDelete={onDelete}
      />
    </Dropdown>
  );
});
