import { css } from '@emotion/react';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { formatValue } from '@amalia/core/types';
import { Typography } from '@amalia/design-system/components';
import { TypographyVariant } from '@amalia/design-system/meta';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';

import { type ChartSeries } from '../../chartSeries';
import { LegendIcon } from '../../legend/LegendIcon';

const tooltipItemCss = css`
  display: flex;
  gap: 8px;
  align-items: flex-start;
`;

const tooltipLegendIconContainerCss = css`
  justify-content: center;
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
`;

type TooltipItemProps = {
  readonly series: ChartSeries;
  readonly value?: number | null;
  readonly currency: CurrencySymbolsEnum;
};

export const TooltipItem = memo(function TooltipItem({ series, value, currency }: TooltipItemProps) {
  const valueFormatter = useCallback(
    (value: number) => formatValue(value, series?.format, currency),
    [currency, series?.format],
  );

  if (!series) {
    return null;
  }

  const hasValue = Number.isFinite(value);

  return (
    <div
      key={series.name}
      css={tooltipItemCss}
    >
      <div css={tooltipLegendIconContainerCss}>
        <LegendIcon series={series} />
      </div>

      <Typography
        as="div"
        variant={TypographyVariant.BODY_XSMALL_REGULAR}
        css={css`
          flex: 1 0;
        `}
      >
        {series.label}
      </Typography>

      <Typography
        as="div"
        variant={TypographyVariant.BODY_SMALL_MEDIUM}
        css={
          !hasValue &&
          ((theme) => css`
            color: ${theme.ds.colors.gray[500]};
          `)
        }
      >
        {hasValue ? valueFormatter(value) : <FormattedMessage defaultMessage="No data" />}
      </Typography>
    </div>
  );
});
