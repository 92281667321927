import { type ElementType, memo, useMemo } from 'react';
import { FormattedList } from 'react-intl';

import { TextOverflow } from '../../../../../../general/text-overflow/TextOverflow';
import { Typography } from '../../../../../../general/typography/Typography';
import { type CellSelectMultiValueLabelProps, type CellSelectOption } from '../../CellSelect.types';

import * as styles from './CellSelectMultiValueContainer.styles';

export type CellSelectMultiValueContainerProps<TOption extends CellSelectOption = CellSelectOption> = {
  readonly options: TOption[];
  readonly disabled?: boolean;
  readonly LabelComponent?: ElementType<CellSelectMultiValueLabelProps<TOption>>;
};

const CellSelectMultiValueContainerBase = function CellSelectMultiValueContainer<
  TOption extends CellSelectOption = CellSelectOption,
>({ options, disabled, LabelComponent }: CellSelectMultiValueContainerProps<TOption>) {
  const optionsLabels = useMemo(
    () => options.map((option) => option.multiValueLabel || option.valueLabel || option.label),
    [options],
  );

  const hasOnlyStringLabels = optionsLabels.every((label): label is string => typeof label === 'string');

  return (
    <div css={styles.cellSelectMultiValueContainer}>
      {LabelComponent ? (
        options.map((option) => (
          <LabelComponent
            key={String(option.value)}
            disabled={disabled}
            option={option}
          />
        ))
      ) : hasOnlyStringLabels ? (
        <Typography
          as={TextOverflow}
          variant={Typography.Variant.BODY_BASE_REGULAR}
        >
          <FormattedList
            style="short"
            type="unit"
            value={optionsLabels}
          />
        </Typography>
      ) : (
        options.map((option) => (
          <div key={String(option.value)}>{option.multiValueLabel || option.valueLabel || option.label}</div>
        ))
      )}
    </div>
  );
};

export const CellSelectMultiValueContainer = memo(
  CellSelectMultiValueContainerBase,
) as typeof CellSelectMultiValueContainerBase;
