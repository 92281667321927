import { css } from '@emotion/react';
import { IconArrowLeft } from '@tabler/icons-react';
import { type ForwardedRef, forwardRef, memo, type ReactNode, type ComponentPropsWithoutRef } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { Tooltip } from '../../../overlays/tooltip/Tooltip';
import { useBreadcrumbsContext } from '../Breadcrumbs.context';

import * as styles from './BreadcrumbsBackButton.styles';

export type BreadcrumbsBackButtonProps = MergeAll<
  [
    Omit<ComponentPropsWithoutRef<'button'>, 'children' | 'type'>,
    {
      label: ReactNode;
    },
  ]
>;

const BreadcrumbsBackButtonForwardRef = forwardRef(function BreadcrumbsBackButton(
  props: BreadcrumbsBackButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { withShadow } = useBreadcrumbsContext();

  return (
    <Tooltip content={props.label}>
      <button
        {...props}
        ref={ref}
        type="button"
        css={[
          styles.backButton,
          withShadow &&
            ((theme) => css`
              box-shadow: ${theme.ds.shadows.soft};
            `),
        ]}
      >
        <IconArrowLeft
          color="currentColor"
          size={19.2}
        />
      </button>
    </Tooltip>
  );
});

export const BreadcrumbsBackButton = memo(BreadcrumbsBackButtonForwardRef);
