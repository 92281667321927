import { type ComputedStatement } from '@amalia/core/types';
import { type BaseCustomObjectDefinition } from '@amalia/data-capture/record-models/types';

export const getCustomObjectDefinitionFromDefinitionIdInStatemnet = (
  computedStatement: ComputedStatement,
  definitionId: string,
): BaseCustomObjectDefinition | undefined =>
  Object.values(computedStatement.definitions.customObjects).find((co) => co.id === definitionId);

export const getDatasetNameFromStatementDefinition = (
  computedStatement: ComputedStatement,
  filterMachineName: string,
): string | undefined =>
  'filterMachineName' in computedStatement.definitions.filters
    ? computedStatement.definitions.filters[filterMachineName].name
    : undefined;
