import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { fromNow } from '@amalia/core/types';
import { Group, Typography } from '@amalia/design-system/components';
import { ActionsEnum, SubjectsEnum, canSeeHiddenPlan } from '@amalia/kernel/auth/shared';
import { PlanVisibilityBadge } from '@amalia/payout-definition/plans/components';

import { StatementDetailHeaderActionsCompute } from './StatementDetailHeaderActionsCompute';
import {
  type StatementDetailHeaderActionsProps,
  useStatementDetailHeaderActions,
} from './useStatementDetailHeaderActions';

export const StatementDetailHeaderFirstRow = memo(function StatementDetailHeaderFirstRow(
  props: StatementDetailHeaderActionsProps,
) {
  const { statement, ability } = useStatementDetailHeaderActions(props);

  return (
    <Group
      align="center"
      gap={8}
    >
      {canSeeHiddenPlan(ability) && <PlanVisibilityBadge isPlanHidden={statement.plan.isHidden} />}

      {ability.can(ActionsEnum.calculate, SubjectsEnum.Statement) ? (
        <StatementDetailHeaderActionsCompute {...props} />
      ) : (
        <Typography variant={Typography.Variant.BODY_SMALL_REGULAR}>
          <FormattedMessage
            defaultMessage="Last computed {time}"
            values={{ time: fromNow(statement.lastCalculationDate) }}
          />
        </Typography>
      )}
    </Group>
  );
});
