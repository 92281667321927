import { unparse, type UnparseConfig, type UnparseObject } from 'papaparse';

import { type FileGenerator } from '@amalia/kernel/file-generation/meta';

export const DEFAULT_CSV_BUILD_CONFIG: UnparseConfig = {
  header: true,
  skipEmptyLines: 'greedy',
  newline: '\r\n',
};

export type CsvFileGeneratorInput<T> = T[] | UnparseObject<T>;

// Note: this is the only one used only in frontend.
// Maybe move the other to the backend side and this one to the frontend scope?
// No need to make them compliant on both sides
export class CsvFileGenerator<T> implements FileGenerator<CsvFileGeneratorInput<T>, UnparseConfig, Promise<string>> {
  public generate(data: CsvFileGeneratorInput<T>, config?: UnparseConfig): Promise<string> {
    return Promise.resolve(unparse<T>(data, { ...DEFAULT_CSV_BUILD_CONFIG, ...config }));
  }
}
