import { css } from '@emotion/react';
import { memo, useMemo } from 'react';

import { Divider, Group, Paper } from '@amalia/design-system/components';
import { type BadgeAward, type BadgeConfiguration } from '@amalia/payout-definition/plans/types';
import { UsersAvatarStack } from '@amalia/tenants/users/components';
import { useUsersMap } from '@amalia/tenants/users/state';

import { PlanAwardDetails } from '../award-details/PlanAwardDetails';

export type PlanAwardCardProps = {
  readonly configuration: BadgeConfiguration;
  readonly awards: BadgeAward[];
};

export const PlanAwardCard = memo(function PlanAwardCard({ configuration, awards }: PlanAwardCardProps) {
  const usersMap = useUsersMap(awards.map((a) => a.userId));
  const users = useMemo(() => Object.values(usersMap).map((user) => ({ user })), [usersMap]);

  return (
    <Paper>
      <Group
        align="center"
        gap={24}
        css={css`
          padding: 24px 32px;
        `}
      >
        <PlanAwardDetails configuration={configuration} />

        <Divider.Vertical
          css={(theme) => css`
            background-color: ${theme.ds.colors.gray[100]};
          `}
        />

        <UsersAvatarStack
          maxUsers={4}
          size={UsersAvatarStack.Size.LARGE}
          users={users}
        />
      </Group>
    </Paper>
  );
});
