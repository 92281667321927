import { type ForwardedRef, memo, useCallback, type ComponentPropsWithoutRef, forwardRef } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { Dropdown } from '../../dropdown/Dropdown';
import { selectDropdownTestIds } from '../SelectDropdown.testIds';
import { type SelectDropdownOption } from '../SelectDropdown.types';

export type SelectDropdownItemProps<
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
> = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Option of the item. */
      option: TOption;
      /** Is the option checked. */
      checked?: boolean;
      /** Is it a multi-select. */
      isMultiple?: TIsMultiple;
      /** Checked change handler. */
      onChange: (option: TOption, checked: boolean) => void;
    },
  ]
>;

const SelectDropdownItemBase = forwardRef(function SelectDropdownItem<
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
>(
  { option, isMultiple, checked = false, onChange, ...props }: SelectDropdownItemProps<TOption, TIsMultiple>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const handleChange = useCallback(() => onChange(option, !checked), [onChange, option, checked]);

  return (
    <Dropdown.ItemContainer
      {...props}
      ref={ref}
    >
      {isMultiple ? (
        <Dropdown.ItemCheckbox
          checked={checked}
          data-testid={selectDropdownTestIds.item(option.value)}
          disabled={option.disabled}
          icon={option.icon}
          label={option.label}
          secondaryLabel={option.secondaryLabel}
          tooltip={option.tooltip}
          onClick={handleChange}
        />
      ) : (
        <Dropdown.ItemOption
          checked={checked}
          data-testid={selectDropdownTestIds.item(option.value)}
          disabled={option.disabled}
          icon={option.icon}
          label={option.label}
          secondaryLabel={option.secondaryLabel}
          tooltip={option.tooltip}
          onClick={handleChange}
        />
      )}
    </Dropdown.ItemContainer>
  );
});

export const SelectDropdownItem = memo(SelectDropdownItemBase) as <
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
>(
  props: SelectDropdownItemProps<TOption, TIsMultiple> & { ref?: ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof SelectDropdownItemBase>;
