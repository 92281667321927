import { type MouseEventHandler, memo, useCallback } from 'react';

import { Dropdown } from '../../dropdown/Dropdown';
import { type DropdownItemLinkProps } from '../../dropdown/dropdown-item-link/DropdownItemLink';
import { useMenuDropdownContext } from '../MenuDropdown.context';

export type MenuDropdownItemLinkProps = DropdownItemLinkProps;

export const MenuDropdownItemLink = memo(function MenuDropdownItemLink({
  onClick = undefined,
  ...props
}: MenuDropdownItemLinkProps) {
  const { onClosePopover } = useMenuDropdownContext();

  const onClickProxy: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (event) => {
      onClick?.(event);
      onClosePopover();
    },
    [onClick, onClosePopover],
  );

  return (
    <Dropdown.ItemLink
      {...props}
      onClick={onClickProxy}
    />
  );
});
