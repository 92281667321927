import { useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import { log } from '@amalia/kernel/logger/client';
import { type CustomReport, type CustomReportManifestsMap } from '@amalia/reporting/custom-reports/shared';

import { CustomReportsApiClient } from '../api-client/custom-reports.api-client';

// TODO: rewrite with react query
export const useCustomReportSourcesManifests = (customReport: CustomReport | null, canEdit: boolean) => {
  const { snackError } = useSnackbars();
  const [manifestsMap, setManifestsMap] = useState<CustomReportManifestsMap>({});

  useAsyncEffect(async () => {
    if (customReport) {
      try {
        const sourceManifests = await CustomReportsApiClient.getManifests(
          customReport.id,
          canEdit ? customReport.configuration : null,
        );
        setManifestsMap(sourceManifests);
      } catch (e) {
        log.error(e);
        snackError(toError(e).message);
      }
    }
  }, [customReport?.configuration.joins, canEdit]);

  return { manifestsMap };
};
