import { useEffect } from 'react';

import { useIsFirstMount } from '../use-is-first-mount/useIsFirstMount';

export const useUpdateEffect: typeof useEffect = (effect, deps) => {
  const isFirstMount = useIsFirstMount();

  useEffect(
    () => (!isFirstMount ? effect() : undefined),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ESLint checks useUpdateEffect's dependencies.
    deps,
  );
};
