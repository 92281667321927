import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal } from '@amalia/design-system/components';

export type ConfirmUnselectUsersModalProps = {
  readonly isOpen: boolean;
  readonly onCancel: VoidFunction;
  readonly onConfirm: VoidFunction;
};

export const ConfirmUnselectUsersModal = memo(function ConfirmUnselectUsersModal({
  isOpen,
  onCancel,
  onConfirm,
}: ConfirmUnselectUsersModalProps) {
  return (
    <Modal
      isDismissable
      data-testid="confirm-unselect-users-modal"
      isOpen={isOpen}
      size={Modal.Size.MEDIUM}
      variant={Modal.Variant.DANGER}
      onClose={onCancel}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Apply filter and reset user selection" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage defaultMessage="Applying filters will reset your current user selection.{br}Do you want to continue?" />
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />
        <Modal.MainAction
          type="submit"
          onClick={onConfirm}
        >
          <FormattedMessage defaultMessage="Apply" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
