import { type Theme, css } from '@emotion/react';

export const IS_ACTIVE_CLASSNAME = 'isActive';

export const selectorTrigger = (theme: Theme) => css`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 100%;

  text-align: left;

  cursor: pointer;

  padding: 3.5px 8px 3.5px 12px;

  border-radius: ${theme.ds.borderRadiuses.round};
  border: none;

  transition: ${theme.ds.transitions.default('background-color', 'color', 'box-shadow')};

  color: ${theme.ds.colors.gray[900]};
  background-color: transparent;

  min-width: 96px;

  &:hover,
  &.${IS_ACTIVE_CLASSNAME} {
    background-color: ${theme.ds.colors.primary[50]};
  }

  &.${IS_ACTIVE_CLASSNAME} {
    color: ${theme.ds.colors.primary[500]};
  }

  &:focus-visible {
    outline: 1px solid ${theme.ds.colors.primary[200]};
    outline-offset: -1px;
    box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.primary[50])};
  }
`;

export const label = css`
  flex: 1;

  display: flex;
  align-items: center;
  gap: 6px;

  // Makes text overflow work.
  overflow: hidden;
`;

export const icon = css`
  flex: none;
`;

export const popover = css`
  min-width: max-content;
`;
