import { http } from '@amalia/core/http/client';
import {
  type CreateDatasetOverwriteRequest,
  type AddDatasetRowsRequest,
  type DatasetRow,
  type DeleteDatasetOverwriteRequest,
  type PaginatedQuery,
  type PaginatedResponse,
  type StatementDataset,
  type Overwrite,
} from '@amalia/core/types';

const apiEndpoint = '/statements';

export class StatementDatasetsApiClient {
  /**
   * Fetch all datasets of the statement without pagination.
   *
   * Please don't use it elsewhere than in the tracing or God will kill a kitten.
   * I struggled a lot to make the paginated endpoint performant, use that one instead,
   * and delete this method and the endpoint when the tracing is in v2.
   *
   * @deprecated
   */
  public static async fetchDatasets(statementId: string): Promise<StatementDataset[]> {
    const { data } = await http.get<StatementDataset[]>(`${apiEndpoint}/${statementId}/datasets`);

    return data;
  }

  public static async fetchPaginatedDatasetRows(
    statementId: string,
    datasetId: string,
    paginationOptions: PaginatedQuery,
    forecasted?: boolean,
  ): Promise<PaginatedResponse<DatasetRow>> {
    const { data } = await http.get<PaginatedResponse<DatasetRow>>(
      `${apiEndpoint}/${statementId}/datasets/${datasetId}/rows`,
      {
        params: {
          ...paginationOptions,
          forecasted,
        },
      },
    );

    return data;
  }

  public static async deleteDatasetRow(
    statementId: string,
    datasetId: string,
    rowExternalId: string,
    deleteDatasetOverwriteRequest: DeleteDatasetOverwriteRequest,
  ): Promise<void> {
    await http.delete(`${apiEndpoint}/${statementId}/datasets/${datasetId}/rows/${rowExternalId}`, {
      data: deleteDatasetOverwriteRequest,
    });
  }

  /**
   * Add rows to a dataset.
   * @param statementId
   * @param datasetId
   * @param addDatasetRowsRequest
   */
  public static async addDatasetRows(
    statementId: string,
    datasetId: string,
    addDatasetRowsRequest: AddDatasetRowsRequest,
  ): Promise<DatasetRow[]> {
    const { data } = await http.post<DatasetRow[]>(
      `${apiEndpoint}/${statementId}/datasets/${datasetId}/rows`,
      addDatasetRowsRequest,
    );

    return data;
  }

  public static async createStatementDatasetOverwrite(
    statementId: string,
    datasetId: string,
    createStatementOverwriteRequest: CreateDatasetOverwriteRequest,
  ): Promise<Overwrite> {
    const { data } = await http.post<Overwrite>(
      `${apiEndpoint}/${statementId}/datasets/${datasetId}/overwrites`,
      createStatementOverwriteRequest,
    );
    return data;
  }
}
