import { createContext } from 'react';

import { type DatasetRow, type Overwrite } from '@amalia/core/types';
import { type FormatsEnum } from '@amalia/data-capture/fields/types';
import {
  type FilterOverwriteRemoveCreationRequestDetails,
  type OverwriteCreationRequestDetails,
} from '@amalia/data-correction/overwrites/components';
import { type Dataset } from '@amalia/payout-calculation/types';
import { type StatementThread, type StatementThreadScope } from '@amalia/payout-collaboration/comments/types';
import { type ComputedPlanRuleFieldsToDisplay, type PlanRule } from '@amalia/payout-definition/plans/types';

export type RowsTableContextInterface = {
  // Data
  fields: ComputedPlanRuleFieldsToDisplay[];
  dataset: Dataset;
  cellFormats: Record<string, FormatsEnum | undefined>;
  columnDescriptions: Record<string, string>;

  // Highlight
  rowsToHighlight?: string[];

  // Tracing
  tracingExternalId: string | null;
  setTracingExternalId: (v: string) => void;
  setCurrentTracingData?: any;

  // Comments
  hideComments?: boolean;
  hideEditOverwrite?: boolean;
  cellEditColumnExtensions: { columnName: string; editingEnabled: boolean }[];

  // Rule
  ruleId?: string;
  ruleDefinition?: PlanRule;

  // Serialize row to string
  serializeRow?: (row: DatasetRow) => string | undefined;

  handleOpenOverwriteModal?: (details: OverwriteCreationRequestDetails) => void;
  handleClearOverwrite?: (overwrite: Overwrite) => Promise<void>;
  handleOpenFilterOverwriteRemoveModal?: (details: FilterOverwriteRemoveCreationRequestDetails) => void;

  toggleAddRecordModal?: () => void;
  setAddedRows?: (rows: DatasetRow[]) => void;
  // State
  isForecastedView?: boolean;

  statementThreads: Record<string, StatementThread>;
  openStatementThreadPanel: (statementThread?: StatementThread, scope?: StatementThreadScope) => void;
};

export const RowsTableContext = createContext<RowsTableContextInterface>({} as RowsTableContextInterface);
