import { css } from '@emotion/react';
import { Suspense, lazy, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertBanner, Skeleton } from '@amalia/design-system/components';

const PlaceholderImage = lazy(async () => ({
  default: (await import('./ChartPreviewPlaceholder.svg')).ReactComponent,
}));

export const ChartPreviewPlaceholder = memo(function ChartPreviewPlaceholder() {
  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <Suspense
        fallback={
          <Skeleton
            css={css`
              width: 578px;
              height: 218px;
            `}
          />
        }
      >
        <PlaceholderImage />
      </Suspense>

      <div
        css={css`
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;

          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <AlertBanner
          inline
          withBorder
        >
          <FormattedMessage defaultMessage="Preview your chart here." />
        </AlertBanner>
      </div>
    </div>
  );
});
