import { useTheme } from '@emotion/react';
import { Command } from 'cmdk';
import { type ReactNode, memo, Fragment } from 'react';

import { type TablerIconProps, Typography } from '@amalia/design-system/components';
import { ColorCategory } from '@amalia/design-system/meta';

type CommandGroupProps = {
  readonly title: ReactNode;
  readonly children: ReactNode;
  readonly Icon?: (props: TablerIconProps) => ReactNode;
};

export const CommandGroup = memo(function CommandGroup({ title, children, Icon }: CommandGroupProps) {
  const theme = useTheme();
  return (
    <Command.Group
      heading={
        <Fragment>
          {!!Icon && (
            <Icon
              className="defaultIcon"
              color={theme.ds.colors[ColorCategory.GRAY][800]}
            />
          )}
          <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>{title}</Typography>
        </Fragment>
      }
    >
      {children}
    </Command.Group>
  );
});
