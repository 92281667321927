import { css } from '@emotion/react';
import { IconGhostOff } from '@tabler/icons-react';
import { memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Select, type SelectOption, Typography, Paper, Group, Stack } from '@amalia/design-system/components';
import { useAuthenticatedContext } from '@amalia/kernel/auth/state';
import { useImpersonateUserMutation } from '@amalia/superadmin/state';

import { ImpersonatingBox } from '../utils/ImpersonatingBox';
import { SAMenu } from '../utils/SAMenu';

export type CompanyOption = SelectOption<string>;

export type SuperadminHeaderProps = {
  readonly companiesOptions: CompanyOption[];
  readonly selectedCompanies: string[];
  readonly onChangeSelectedCompanies: (selectedCompanies: string[]) => void;
};

export const SuperadminHeader = memo(function SuperadminHeader({
  companiesOptions,
  selectedCompanies,
  onChangeSelectedCompanies,
}: SuperadminHeaderProps) {
  const { formatMessage } = useIntl();
  const { authenticatedContext } = useAuthenticatedContext();

  const { mutateAsync: impersonateUser } = useImpersonateUserMutation({});
  const onClickResetImpersonate = useCallback(async () => {
    await impersonateUser(authenticatedContext.meta?.amaliaImpersonatorEmail);
  }, [authenticatedContext.meta, impersonateUser]);

  return (
    <Paper
      css={css`
        overflow: hidden;
      `}
    >
      <Stack
        gap={16}
        css={css`
          padding: 16px 24px 24px;
        `}
      >
        <Typography variant={Typography.Variant.HEADING_3_BOLD}>
          <FormattedMessage defaultMessage="Superadmin panel" />
        </Typography>

        <Group
          align="center"
          gap={16}
          justify="space-between"
        >
          <Group
            align="center"
            gap={16}
          >
            <ImpersonatingBox />
            {/* Here when the user have an amaliaImpersonatorEmail, the user is currently impersonating someone*/}
            {!!authenticatedContext.meta?.amaliaImpersonatorEmail && (
              <Button
                icon={<IconGhostOff />}
                size={Button.Size.LARGE}
                variant={Button.Variant.PRIMARY_LIGHT}
                onClick={onClickResetImpersonate}
              >
                <FormattedMessage defaultMessage="Reset impersonate" />
              </Button>
            )}
          </Group>

          <Select<CompanyOption, true>
            isMultiple
            id="selectedCompanies"
            options={companiesOptions}
            placeholder={formatMessage({ defaultMessage: 'Filter companies' })}
            value={selectedCompanies}
            onChange={onChangeSelectedCompanies}
          />
        </Group>
      </Stack>

      <SAMenu />
    </Paper>
  );
});
