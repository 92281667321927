import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { assert } from '@amalia/ext/typescript';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.ENDS_WITH, AmaliaFunctionCategory.STRING);

func.nbParamsRequired = 2;

func.description = 'Return true when the given Source String ends with the given Search String';

func.params = [
  { name: 'sourceString', description: 'String to look into: array, variables, fields, properties, string' },
  { name: 'searchString', description: 'String to look for: array, variables, fields, properties, string' },
];

func.examples = [
  {
    desc: 'Returns true if Opportunity type endsWith Renew.',
    formula: 'ENDS_WITH(opportunity.type, "Renew")' as AmaliaFormula,
  },
  {
    desc: 'Returns true.',
    formula: 'ENDS_WITH("Jean Dupont", "pont")' as AmaliaFormula,
    result: true,
  },
  {
    desc: 'Returns false since the check is case sensitive.',
    formula: 'ENDS_WITH("Opportunity - Renewal XYZ", "xyz")' as AmaliaFormula,
    result: false,
  },
];

/**
 * Check that text contains an other text.
 * @param sourceString
 * @param searchString
 */
func.exec = (sourceString: { toString: () => string } | null | undefined, searchString: string): boolean => {
  assert(
    sourceString !== null && sourceString !== undefined,
    `${AmaliaFunctionKeys.ENDS_WITH}: source string is null or undefined`,
  );

  return sourceString.toString().endsWith(searchString);
};

export default func;
