import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { DonutChart } from '@amalia/lib-ui';
import { CustomReportsPresetsEnum } from '@amalia/reporting/custom-reports/shared';

import { DashboardWidget } from '../DashboardWidget/DashboardWidget';
import { WidgetTitle } from '../WidgetTitle/WidgetTitle';

interface BenchmarkData {
  name: string;
  value: number;
}
type BenchmarkByPlanProps = {
  readonly currency?: CurrencySymbolsEnum;
  readonly data: BenchmarkData[];
  readonly loading?: boolean;
};

export const BenchmarkByPlan = memo(function BenchmarkByPlan({
  currency = CurrencySymbolsEnum.EUR,
  data,
  loading = true,
}: BenchmarkByPlanProps) {
  const cleanedData = useMemo(() => data.filter((row) => row.value >= 0), [data]);

  return (
    <DashboardWidget>
      <WidgetTitle
        customReportId={CustomReportsPresetsEnum.PRESET_BENCHMARK_BY_PLAN}
        title={<FormattedMessage defaultMessage="Benchmark by plans" />}
      />

      <DonutChart
        currency={currency}
        data={cleanedData}
        donutPadding={100}
        hideTooltip={false}
        innerRadius={80}
        loading={loading}
        thickness={15}
      />
    </DashboardWidget>
  );
});
