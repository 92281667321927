import { type TeamAssignment } from './team-assignments';

type ColumnNames = 'assignmentEndDate' | 'assignmentStartDate' | 'teamName' | 'userEmail' | 'userRole';

export type ImportedTeamAssignmentsRows = Record<ColumnNames, string>[];

export type ImportedTeamAssignmentsErrors = {
  rowLineNumber: number;
  errorCode: TeamAssignmentsImportErrors;
}[];

export type AssignmentsToSave = Pick<
  TeamAssignment,
  'effectiveAsOf' | 'effectiveUntil' | 'team' | 'teamRole' | 'user'
>[];

export type TeamAssignmentsToSave = {
  newTeamAssignments: AssignmentsToSave;
  updatedTeamAssignments: AssignmentsToSave;
  keptTeamAssignments: AssignmentsToSave;
};

export enum TeamAssignmentsImportErrors {
  // DATE ERRORS
  INVALID_START_DATE = 'INVALID_START_DATE',
  INVALID_END_DATE = 'INVALID_END_DATE',
  START_DATE_AFTER_END_DATE = 'START_DATE_AFTER_END_DATE',
  // USER ERRORS
  MISSING_USER_EMAIL = 'MISSING_USER_EMAIL',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  MISSING_USER_ROLE = 'MISSING_USER_ROLE',
  INVALID_USER_ROLE = 'INVALID_USER_ROLE',
  // TEAM ERRORS
  MISSING_TEAM_NAME = 'MISSING_TEAM_NAME',
  TEAM_NOT_FOUND = 'TEAM_NOT_FOUND',
  // DUPLICATE ERRORS
  DUPLICATE_ROW = 'DUPLICATE_ROW',
}

export type TeamAssignmentsValidateResponse = {
  errors: ImportedTeamAssignmentsErrors | null;
  assignmentsToSave: TeamAssignmentsToSave | null;
};
