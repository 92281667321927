import { isEqual, pick } from 'lodash';

import { type PlanOption, type TeamOption } from '@amalia/core/types';
import { toError } from '@amalia/ext/typescript';
import {
  type FindUserStatementsParameters,
  StatementsApiClient,
  UserStatementsApiClient,
} from '@amalia/payout-calculation/statements/state';
import {
  type BulkReviewRequest,
  type WorkflowStatementStateAction,
} from '@amalia/payout-collaboration/workflows/types';

import { type ThunkResult } from '../types';

import { USERS_STATEMENTS_ACTIONS } from './constants';
import { selectLastUserStatementsFetchParams, selectUserStatementsList } from './selectors';
import {
  type UserStatementsSetUserStatementsPageSuccessAction,
  type UserStatementsErrorAction,
  type UserStatementsStartAction,
  type UserStatementsClearUsersStatementsListAction,
  type UserStatementsMassReviewStatementsAction,
  type UserStatementsChangeListSelectedOptionsAction,
} from './types';

const userStatementsStart = (): UserStatementsStartAction => ({
  type: USERS_STATEMENTS_ACTIONS.START,
});

const userStatementsError = (error: Error): UserStatementsErrorAction => ({
  type: USERS_STATEMENTS_ACTIONS.ERROR,
  error,
});

const setUserStatementsPageSuccess = (
  userStatementsPage: UserStatementsSetUserStatementsPageSuccessAction['payload']['userStatementsPage'],
  options: UserStatementsSetUserStatementsPageSuccessAction['payload']['options'],
): UserStatementsSetUserStatementsPageSuccessAction => ({
  type: USERS_STATEMENTS_ACTIONS.SET_USER_STATEMENTS_PAGE_SUCCESS,
  payload: { userStatementsPage, options },
});

export const clearUserStatementsList = (): UserStatementsClearUsersStatementsListAction => ({
  type: USERS_STATEMENTS_ACTIONS.CLEAR_USER_STATEMENTS,
});

/// //////////
/// STATEMENTS
/// /////////

/**
 * @deprecated
 * @param searchOptions
 * @param force
 * @param pageNumber
 */
export const fetchUserStatementsList =
  (
    searchOptions: FindUserStatementsParameters,
    pageNumber: number = 0,
    force: boolean = false,
  ): ThunkResult<Promise<UserStatementsErrorAction | UserStatementsSetUserStatementsPageSuccessAction>> =>
  async (dispatch, getState) => {
    dispatch(userStatementsStart());

    const lastOptions = selectLastUserStatementsFetchParams(getState());
    const userStatementsFromState = selectUserStatementsList(getState());

    const options = {
      period: searchOptions.period,
      planId: searchOptions.planId,
      teamId: searchOptions.teamId,
      userIds: searchOptions.userIds,
      isForecast: searchOptions.isForecast,
      searchQuery: searchOptions.searchQuery,
      page: pageNumber,
      nbStatementsPerPage: searchOptions.nbStatementsPerPage,
      sortColumn: searchOptions.sortColumn,
      sortOrder: searchOptions.sortOrder,
    };

    if (!force && isEqual(options, lastOptions)) {
      // @ts-expect-error -- Shit's broken.
      return dispatch(setUserStatementsPageSuccess(userStatementsFromState, options));
    }

    try {
      const userStatementsPage = await UserStatementsApiClient.findUserStatements(searchOptions, pageNumber);

      // If only pagination change, no need to refresh facets nor statistics,
      if (
        isEqual(
          pick(options, ['periodId', 'planId', 'teamId', 'userId', 'searchQuery']),
          pick(lastOptions, ['periodId', 'planId', 'teamId', 'userId', 'searchQuery']),
        )
      ) {
        return dispatch(setUserStatementsPageSuccess(userStatementsPage, options));
      }

      return dispatch(setUserStatementsPageSuccess(userStatementsPage, options));
    } catch (error) {
      return dispatch(userStatementsError(toError(error)));
    }
  };

/// ///////
/// REVIEW
/// //////
const massReviewStatementsAction = (
  calculationRequest: BulkReviewRequest,
): UserStatementsMassReviewStatementsAction => ({
  type: USERS_STATEMENTS_ACTIONS.MASS_REVIEW_STATEMENTS,
  payload: { calculationRequest },
});

export const massReviewStatements =
  (
    calculationRequest: BulkReviewRequest,
    action: WorkflowStatementStateAction,
    isNotify: boolean,
  ): ThunkResult<Promise<UserStatementsErrorAction | UserStatementsMassReviewStatementsAction>> =>
  async (dispatch) => {
    dispatch(userStatementsStart());

    try {
      await StatementsApiClient.reviewStatements(
        {
          ...calculationRequest,
          action,
        },
        isNotify,
      );

      return dispatch(massReviewStatementsAction(calculationRequest));
    } catch (error) {
      return dispatch(userStatementsError(toError(error)));
    }
  };

/// ///////
/// FILTERS
/// //////
export const changeListSelectedOptions = (
  patch: Partial<{ plan?: PlanOption; team?: TeamOption }>,
): UserStatementsChangeListSelectedOptionsAction => ({
  type: USERS_STATEMENTS_ACTIONS.CHANGE_LIST_SELECTED_OPTIONS,
  payload: { patch },
});
