import { createSelector } from 'reselect';

import { type UserContract } from '@amalia/tenants/users/types';

import { isUserActive, sortUsers } from '../../services/users/users.service';
import { type RootState } from '../types';

export const selectUsersMap = (state: RootState) => state.users.map;

export const selectUsersList = createSelector([selectUsersMap], (map) => sortUsers(Object.values(map)));

export const selectActiveUsers = createSelector([selectUsersList], (users): UserContract[] =>
  users.filter((u) => isUserActive(u)),
);

export const selectIsUsersLoading = (state: RootState) => !!state.users.isLoading;
