import { AvatarGroup as MuiAvatarGroup, type AvatarGroupProps as MuiAvatarGroupProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo } from 'react';

import { type AmaliaThemeType } from '@amalia/ext/mui/theme';
import { type UserComputed } from '@amalia/tenants/users/types';

import { Avatar } from './Avatar';

export interface AvatarGroupProps extends MuiAvatarGroupProps {
  readonly users: Pick<UserComputed, 'firstName' | 'id' | 'lastName' | 'pictureURL'>[];
}

const avatarGroupStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    width: 'fit-content',
  },
  avatar: {
    fontSize: '14px',
    color: theme.palette.grey[700],
    border: `20px solid ${theme.palette.common.white}`,
    '&:first-child': {
      backgroundColor: theme.palette.grey[200],
    },

    '&:last-child': {
      color: theme.palette.grey[700],
    },
  },
  avatarRoot: {
    width: '43px',
    height: '43px',

    '&:last-child': {
      marginRight: '-8px',
    },
    '& > $avatar': {
      border: '2px solid white',
    },
  },
}));

export const AvatarGroup = memo(function AvatarGroup({ users, ...props }: AvatarGroupProps) {
  const classes = avatarGroupStyles();
  return (
    <MuiAvatarGroup
      {...props}
      classes={{ avatar: classes.avatar, root: classes.root }}
    >
      {(users || []).map((user) => (
        <Avatar
          key={user.id}
          className={classes.avatar}
          parentClassName={classes.avatarRoot}
          user={user}
        />
      ))}
    </MuiAvatarGroup>
  );
});
