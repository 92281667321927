import { assert, isEnum } from '@amalia/ext/typescript';

import { CurrencySymbolsEnum } from './iso-4217';

export const toCurrencyCode = (value: string): CurrencySymbolsEnum => {
  const currencyCode = value.toUpperCase();

  assert(isEnum(currencyCode, CurrencySymbolsEnum), `Unknown currency ${value}`);

  return currencyCode;
};
