import { TypographyVariant } from './types';

export const TYPOGRAPHY_DEFAULT_ELEMENTS = {
  [TypographyVariant.LARGE_HEADING_REGULAR]: 'h1',
  [TypographyVariant.LARGE_HEADING_MEDIUM]: 'h1',
  [TypographyVariant.LARGE_HEADING_BOLD]: 'h1',
  [TypographyVariant.HEADING_1_REGULAR]: 'h1',
  [TypographyVariant.HEADING_1_MEDIUM]: 'h1',
  [TypographyVariant.HEADING_1_BOLD]: 'h1',
  [TypographyVariant.HEADING_2_REGULAR]: 'h2',
  [TypographyVariant.HEADING_2_MEDIUM]: 'h2',
  [TypographyVariant.HEADING_2_BOLD]: 'h2',
  [TypographyVariant.HEADING_3_REGULAR]: 'h3',
  [TypographyVariant.HEADING_3_MEDIUM]: 'h3',
  [TypographyVariant.HEADING_3_BOLD]: 'h3',
  [TypographyVariant.HEADING_4_REGULAR]: 'h4',
  [TypographyVariant.HEADING_4_MEDIUM]: 'h4',
  [TypographyVariant.HEADING_4_BOLD]: 'h4',
  [TypographyVariant.BODY_LARGE_REGULAR]: 'span',
  [TypographyVariant.BODY_LARGE_MEDIUM]: 'span',
  [TypographyVariant.BODY_LARGE_BOLD]: 'span',
  [TypographyVariant.BODY_BASE_REGULAR]: 'span',
  [TypographyVariant.BODY_BASE_MEDIUM]: 'span',
  [TypographyVariant.BODY_BASE_BOLD]: 'span',
  [TypographyVariant.BODY_SMALL_REGULAR]: 'span',
  [TypographyVariant.BODY_SMALL_MEDIUM]: 'span',
  [TypographyVariant.BODY_SMALL_BOLD]: 'span',
  [TypographyVariant.BODY_XSMALL_REGULAR]: 'span',
  [TypographyVariant.BODY_XSMALL_MEDIUM]: 'span',
  [TypographyVariant.BODY_XSMALL_BOLD]: 'span',
  [TypographyVariant.BODY_XXSMALL_REGULAR]: 'span',
  [TypographyVariant.BODY_XXSMALL_MEDIUM]: 'span',
  [TypographyVariant.BODY_XXSMALL_BOLD]: 'span',
  [TypographyVariant.MONOSPACE_LARGE]: 'span',
  [TypographyVariant.MONOSPACE_BASE]: 'span',
  [TypographyVariant.MONOSPACE_SMALL]: 'span',
} as const satisfies Record<TypographyVariant, string>;
