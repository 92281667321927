import { type ReactNode } from 'react';

import { type TablerIconElement } from '../../general/icons/types';
import { type RadioOptionValue } from '../radio/Radio.types';

export enum RadioButtonGroupSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

export type RadioButtonOptionShape<
  TValue extends RadioOptionValue,
  TShowOnlyIcon extends boolean | undefined = undefined,
> = (TShowOnlyIcon extends false | undefined
  ? {
      showOnlyIcon?: false;
      icon?: TablerIconElement;
    }
  : {
      showOnlyIcon: true;
      icon: TablerIconElement;
    }) & {
  /** Option value. Must be unique. */
  value: TValue;
  /** Option label. */
  label: ReactNode;
  /** Option icon. */
  icon?: TablerIconElement;
  /** Option help displayed in tooltip. */
  help?: ReactNode;
};
