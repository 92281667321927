import { memo, type ReactNode } from 'react';

interface TabPanelProps {
  readonly children?: ReactNode | string;
  readonly index: number;
  readonly value: number;
  readonly reducedHeight?: number;
  readonly fullHeight?: boolean;
}

export const TabPanel = memo(function TabPanel({ index, value, reducedHeight, children, fullHeight }: TabPanelProps) {
  return (
    <div
      hidden={value !== index}
      id={`tabpanel-${index}`}
      role="tabpanel"
      style={{ height: `calc(100% - ${fullHeight ? '0' : reducedHeight || '130'}px)` }}
    >
      {children}
    </div>
  );
});
