import { css } from '@emotion/react';

export const notificationsList = css`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const popoverBody = css`
  padding: 4px 12px;
`;

export const popoverLayout = css`
  max-height: 500px;
  width: 500px;
`;
