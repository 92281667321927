export const PLAN_FORECAST_ACTIONS = {
  START: 'PLAN_FORECASTS/START',
  ERROR: 'PLAN_FORECASTS/ERROR',

  SET_PLAN_FORECAST: 'PLAN_FORECASTS/SET_PLAN_FORECAST',

  EDIT_DATASET: 'PLAN_FORECASTS/EDIT_DATASET',
  EDIT_FIELD: 'PLAN_FORECASTS/EDIT_FIELD',
  EDIT_KPI: 'PLAN_FORECASTS/EDIT_KPI',

  EDIT_OBJECTS_TO_DISPLAY: 'PLAN_FORECASTS/EDIT_OBJECTS_TO_DISPLAY',
  EDIT_KPIS_TO_DISPLAY: 'PLAN_FORECASTS/EDIT_KPIS_TO_DISPLAY',
  EDIT_KPI_TO_DISPLAY_STATUS: 'PLAN_FORECASTS/EDIT_KPI_TO_DISPLAY_STATUS',
  EDIT_FILTERS_TO_DISPLAY: 'PLAN_FORECASTS/EDIT_FILTERS_TO_DISPLAY',
  EDIT_FILTER_TO_DISPLAY_STATUS: 'PLAN_FORECASTS/EDIT_FILTER_TO_DISPLAY_STATUS',
  EDIT_FIELDS_TO_DISPLAY: 'PLAN_FORECASTS/EDIT_FIELDS_TO_DISPLAY',
  EDIT_FIELD_TO_DISPLAY_STATUS: 'PLAN_FORECASTS/EDIT_FIELD_TO_DISPLAY_STATUS',
} as const;
