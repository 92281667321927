import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ValueOrAttributeType } from '@amalia/amalia-lang/formula/types';
import { type RadioButtonOptionShape } from '@amalia/design-system/components';

import { valueOrAttributeMessages } from './useValueOrAttributeOptions.messages';

export const useValueOrAttributeOptions = (
  propertyName?: string,
  customObjectName?: string,
): RadioButtonOptionShape<ValueOrAttributeType>[] =>
  useMemo(
    () => [
      {
        value: ValueOrAttributeType.ATTRIBUTE,
        label: <FormattedMessage {...valueOrAttributeMessages[ValueOrAttributeType.ATTRIBUTE]} />,
        help:
          propertyName && customObjectName ? (
            <FormattedMessage
              defaultMessage="Compare “{propertyName}” with other “{customObjectName}” fields or linked fields."
              values={{
                propertyName,
                customObjectName,
              }}
            />
          ) : undefined,
      },
      {
        value: ValueOrAttributeType.VALUE,
        label: <FormattedMessage {...valueOrAttributeMessages[ValueOrAttributeType.VALUE]} />,
      },
    ],
    [propertyName, customObjectName],
  );
