import { defineMessages } from 'react-intl';

import {
  FormulaBuilderStringOperatorNoArgs,
  FormulaBuilderStringOperatorOneArg,
} from '@amalia/amalia-lang/formula/types';

export const operatorMessages = defineMessages<FormulaBuilderStringOperatorNoArgs | FormulaBuilderStringOperatorOneArg>(
  {
    [FormulaBuilderStringOperatorNoArgs.IS_BLANK]: {
      defaultMessage: 'is blank',
    },

    [FormulaBuilderStringOperatorOneArg.CONTAINS]: {
      defaultMessage: 'contains',
    },

    [FormulaBuilderStringOperatorOneArg.MATCHES]: {
      defaultMessage: 'exactly matches',
    },

    [FormulaBuilderStringOperatorOneArg.STARTS_WITH]: {
      defaultMessage: 'starts with',
    },

    [FormulaBuilderStringOperatorOneArg.ENDS_WITH]: {
      defaultMessage: 'ends with',
    },
  } as const,
);

export const fieldOperatorMessages = defineMessages<FormulaBuilderStringOperatorOneArg>({
  [FormulaBuilderStringOperatorOneArg.CONTAINS]: {
    defaultMessage: '“{propertyName}” contains',
  },

  [FormulaBuilderStringOperatorOneArg.MATCHES]: {
    defaultMessage: '“{propertyName}” exactly matches',
  },

  [FormulaBuilderStringOperatorOneArg.STARTS_WITH]: {
    defaultMessage: '“{propertyName}” starts with',
  },

  [FormulaBuilderStringOperatorOneArg.ENDS_WITH]: {
    defaultMessage: '“{propertyName}” ends with',
  },
} as const);

export const operatorNegativeMessages = defineMessages<
  FormulaBuilderStringOperatorNoArgs | FormulaBuilderStringOperatorOneArg
>({
  [FormulaBuilderStringOperatorNoArgs.IS_BLANK]: {
    defaultMessage: 'is  not blank',
  },

  [FormulaBuilderStringOperatorOneArg.CONTAINS]: {
    defaultMessage: 'does not contain',
  },

  [FormulaBuilderStringOperatorOneArg.MATCHES]: {
    defaultMessage: 'does not exactly match',
  },

  [FormulaBuilderStringOperatorOneArg.STARTS_WITH]: {
    defaultMessage: 'does not start with',
  },

  [FormulaBuilderStringOperatorOneArg.ENDS_WITH]: {
    defaultMessage: 'does not end with',
  },
} as const);

export const fieldOperatorNegativeMessages = defineMessages<FormulaBuilderStringOperatorOneArg>({
  [FormulaBuilderStringOperatorOneArg.CONTAINS]: {
    defaultMessage: '“{propertyName}” does not contain',
  },

  [FormulaBuilderStringOperatorOneArg.MATCHES]: {
    defaultMessage: '“{propertyName}” does not exactly match',
  },

  [FormulaBuilderStringOperatorOneArg.STARTS_WITH]: {
    defaultMessage: '“{propertyName}” does not start with',
  },

  [FormulaBuilderStringOperatorOneArg.ENDS_WITH]: {
    defaultMessage: '“{propertyName}” does not end with',
  },
} as const);
