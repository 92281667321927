import { toError } from '@amalia/ext/typescript';

import * as PlanAssignmentsRepository from '../../services/planAssignments/planAssignment.repository';
import { type PlanAssignmentWithUser } from '../../types/planAssignments';
import { type ThunkResult } from '../types';

import { PLAN_ASSIGNMENTS_ACTIONS } from './constants';
import {
  type PlanAssignmentsSetPlanAssignmentsAction,
  type PlanAssignmentsErrorAction,
  type PlanAssignmentsStartAction,
} from './types';

const planAssignmentsStart = (): PlanAssignmentsStartAction => ({
  type: PLAN_ASSIGNMENTS_ACTIONS.START,
});

const planAssignmentsError = (error: Error): PlanAssignmentsErrorAction => ({
  type: PLAN_ASSIGNMENTS_ACTIONS.ERROR,
  error,
});

const setPlanAssignments = (planAssignments: PlanAssignmentWithUser[]): PlanAssignmentsSetPlanAssignmentsAction => ({
  type: PLAN_ASSIGNMENTS_ACTIONS.SET_PLAN_ASSIGNMENTS,
  payload: { planAssignments },
});

export const fetchPlanAssignments =
  (planIds: string[]): ThunkResult<Promise<PlanAssignmentsErrorAction | PlanAssignmentsSetPlanAssignmentsAction>> =>
  async (dispatch) => {
    dispatch(planAssignmentsStart());

    try {
      const planAssignments = await PlanAssignmentsRepository.listPlanAssignmentsForPlans(planIds);
      return dispatch(setPlanAssignments(planAssignments));
    } catch (error) {
      return dispatch(planAssignmentsError(toError(error)));
    }
  };
