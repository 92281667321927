import { IconCaretDownFilled } from '@tabler/icons-react';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@amalia/design-system/components';
import { assert } from '@amalia/ext/typescript';
import { Avatar, SmallAvatarGroup, UserSelect, type UserSelectProps } from '@amalia/lib-ui';
import { type UserComputed } from '@amalia/tenants/users/types';

type FilterBarUserSelectProps<TUser extends UserComputed> = UserSelectProps<TUser> & {
  readonly render?: never;
  readonly children?: never;
};

const FilterBarUserSelectBase = function FilterBarUserSelect<TUser extends UserComputed>(
  props: FilterBarUserSelectProps<TUser>,
) {
  const { multiple, users } = props;

  const renderAvatars = useCallback(
    (selectedUsers: TUser | TUser[] | null) => {
      if (multiple && Array.isArray(selectedUsers)) {
        return (
          <SmallAvatarGroup
            max={3}
            // If no user is currently selected, show all of them.
            users={(selectedUsers?.length ? selectedUsers : users) || []}
          />
        );
      }
      if (multiple && !selectedUsers) {
        // If no user is currently selected, show all of them.
        return (
          <SmallAvatarGroup
            max={3}
            users={users || []}
          />
        );
      }
      assert(!Array.isArray(selectedUsers), 'UserSelect: multiple is false but value is an array');
      if (!selectedUsers) {
        return <FormattedMessage defaultMessage="No user selected" />;
      }
      return <Avatar user={selectedUsers} />;
    },
    [multiple, users],
  );

  return (
    <UserSelect<TUser> {...props}>
      {({ onClick, value }) => (
        <Button
          data-testid="filterUsers"
          icon={<IconCaretDownFilled />}
          iconPosition={Button.IconPosition.RIGHT}
          variant={Button.Variant.LIGHT}
          onClick={onClick}
        >
          {renderAvatars(value)}
        </Button>
      )}
    </UserSelect>
  );
};

export const FilterBarUserSelect = memo(FilterBarUserSelectBase) as typeof FilterBarUserSelectBase;
