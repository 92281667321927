import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { keyBy } from 'lodash';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal, Typography } from '@amalia/design-system/components';
import { TypographyVariant } from '@amalia/design-system/meta';
import { type UserProfile } from '@amalia/tenants/users/profile/types';

const ConfirmBulkInviteUsersModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export type ConfirmBulkInviteUsersModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onSubmit: () => void;
  readonly users: Pick<UserProfile, 'email' | 'firstName' | 'id' | 'lastName'>[];
  readonly selectedUserIds: string[];
};

export const ConfirmBulkInviteUsersModal = memo(function ConfirmBulkInviteUsersModal({
  isOpen,
  onClose,
  onSubmit,
  users,
  selectedUserIds,
}: ConfirmBulkInviteUsersModalProps) {
  const usersMap = useMemo(() => keyBy(users, 'id'), [users]);

  const selectedUsers = useMemo(() => selectedUserIds.map((id) => usersMap[id]), [selectedUserIds, usersMap]);

  return (
    <Modal
      isDismissable
      isOpen={isOpen}
      size={Modal.Size.MEDIUM}
      variant={Modal.Variant.DEFAULT}
      onClose={onClose}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Send invitation" />
          </Modal.Title>
        </Modal.Header>
        <ConfirmBulkInviteUsersModalBody>
          <span>
            <FormattedMessage
              defaultMessage="Are you sure you want to invite the following {countLabel} to Amalia?"
              values={{
                countLabel: (
                  <Typography variant={TypographyVariant.BODY_BASE_BOLD}>
                    <FormattedMessage
                      defaultMessage="{count, plural, one {# user} other {# users}}"
                      values={{ count: selectedUserIds.length }}
                    />
                  </Typography>
                ),
              }}
            />
          </span>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 6px;
            `}
          >
            {selectedUsers.map((user) => (
              <span key={user.id}>
                <FormattedMessage
                  defaultMessage="{username} ({email})"
                  values={{
                    username: (
                      <Typography variant={TypographyVariant.BODY_BASE_BOLD}>
                        <FormattedMessage
                          defaultMessage="{firstName} {lastName}"
                          values={{ firstName: user.firstName, lastName: user.lastName }}
                        />
                      </Typography>
                    ),
                    email: user.email,
                  }}
                />
              </span>
            ))}
          </div>
        </ConfirmBulkInviteUsersModalBody>
      </Modal.Content>
      <Modal.Actions>
        <Modal.CancelAction />
        <Modal.MainAction onClick={onSubmit}>
          <FormattedMessage defaultMessage="Send invitation" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
