import { type ReactNode, memo, Fragment } from 'react';
import { useParams } from 'react-router-dom';

export type UseParamsProps<TParams extends Record<string, string | undefined> = Record<string, string | undefined>> = {
  readonly children?: (params: ReturnType<typeof useParams<TParams>>) => ReactNode;
};

const UseParamsBase = function UseParams<
  TParams extends Record<string, string | undefined> = Record<string, string | undefined>,
>({ children }: UseParamsProps<TParams>) {
  const params = useParams<TParams>();
  return <Fragment>{children?.(params)}</Fragment>;
};

export const UseParams = memo(UseParamsBase) as typeof UseParamsBase;
