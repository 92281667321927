import { defineMessages } from 'react-intl';

import { ValueOrAttributeType } from '@amalia/amalia-lang/formula/types';

export const valueOrAttributeMessages = defineMessages<ValueOrAttributeType>({
  [ValueOrAttributeType.VALUE]: {
    defaultMessage: 'Manual value',
  },

  [ValueOrAttributeType.ATTRIBUTE]: {
    defaultMessage: 'Attribute',
  },
} as const);
