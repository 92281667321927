import { css } from '@emotion/react';
import {
  IconBuildingSkyscraper,
  IconCpu,
  IconDatabaseImport,
  IconFileDescription,
  IconHome,
  IconPlugConnected,
  IconSettings,
  IconUserCircle,
  IconUsers,
} from '@tabler/icons-react';
import { memo } from 'react';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { Group } from '@amalia/design-system/components';

import { type SaMenuEnum, type SaMenuOption, SAMenuTab } from './SAMenuTab';

const SA_MENU_OPTIONS: Record<SaMenuEnum, SaMenuOption> = {
  home: {
    name: 'Home',
    url: generatePath(routes.SUPERADMIN_HOME),
    icon: <IconHome />,
  },
  companies: {
    name: 'Companies',
    url: generatePath(routes.SUPERADMIN_COMPANIES),
    icon: <IconBuildingSkyscraper />,
  },
  calculations: {
    name: 'Calculations',
    url: generatePath(routes.SUPERADMIN_CALCULATIONS),
    icon: <IconCpu />,
  },
  refreshments: {
    name: 'Refreshments',
    url: generatePath(routes.SUPERADMIN_REFRESHMENTS),
    icon: <IconDatabaseImport />,
  },
  connectors: {
    name: 'Connectors',
    url: generatePath(routes.SUPERADMIN_CONNECTORS),
    icon: <IconPlugConnected />,
  },
  statements: {
    name: 'Statements',
    url: generatePath(routes.SUPERADMIN_STATEMENTS),
    icon: <IconUserCircle />,
  },
  users: {
    name: 'Users',
    url: generatePath(routes.SUPERADMIN_USERS),
    icon: <IconUsers />,
  },
  plans: {
    name: 'Plans',
    url: generatePath(routes.SUPERADMIN_PLANS),
    icon: <IconFileDescription />,
  },
  settings: {
    name: 'Settings',
    url: generatePath(routes.SUPERADMIN_SETTINGS),
    icon: <IconSettings />,
  },
};

export const SAMenu = memo(function SAMenu() {
  return (
    <Group
      css={css`
        height: 48px;
      `}
    >
      {Object.entries(SA_MENU_OPTIONS).map(([key, details]) => (
        <SAMenuTab
          key={key}
          details={details}
          menu={key as SaMenuEnum}
        />
      ))}
    </Group>
  );
});
