import { defineMessages } from 'react-intl';

import {
  FormulaBuilderNumberOperatorNoArgs,
  FormulaBuilderNumberOperatorOneArg,
} from '@amalia/amalia-lang/formula/types';

export const operatorTooltipMessage = defineMessages<
  FormulaBuilderNumberOperatorNoArgs | FormulaBuilderNumberOperatorOneArg
>({
  [FormulaBuilderNumberOperatorNoArgs.IS_BLANK]: {
    defaultMessage: 'Is blank',
  },
  [FormulaBuilderNumberOperatorNoArgs.IS_NOT_BLANK]: {
    defaultMessage: 'Is not blank',
  },
  [FormulaBuilderNumberOperatorOneArg.EQUALS]: {
    defaultMessage: 'Equals to: {value}',
  },

  [FormulaBuilderNumberOperatorOneArg.NOT_EQUALS]: {
    defaultMessage: 'Not equals to: {value}',
  },

  [FormulaBuilderNumberOperatorOneArg.GREATER_THAN]: {
    defaultMessage: 'Greater than: {value}',
  },

  [FormulaBuilderNumberOperatorOneArg.GREATER_THAN_OR_EQUAL]: {
    defaultMessage: 'Greater than or equals to: {value}',
  },

  [FormulaBuilderNumberOperatorOneArg.LESS_THAN]: {
    defaultMessage: 'Less than: {value}',
  },

  [FormulaBuilderNumberOperatorOneArg.LESS_THAN_OR_EQUAL]: {
    defaultMessage: 'Less than or equals to: {value}',
  },
} as const);

export const operatorTagMessage = defineMessages<FormulaBuilderNumberOperatorNoArgs>({
  [FormulaBuilderNumberOperatorNoArgs.IS_BLANK]: {
    defaultMessage: '{propertyName} is blank',
  },
  [FormulaBuilderNumberOperatorNoArgs.IS_NOT_BLANK]: {
    defaultMessage: '{propertyName} is not blank',
  },
} as const);
