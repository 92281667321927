import { css } from '@emotion/react';
import { type ComponentPropsWithoutRef, type ForwardedRef, forwardRef, memo } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

export type LinearProgressProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Height in px. */
      size?: number;
      /** Empty background color. */
      backgroundColor?: string;
      /** Progress background color. */
      progressColor?: string;
      /** Between 0 and 1. */
      progress?: number;
    },
  ]
>;

const LinearProgressForwardRef = forwardRef(function LinearProgress(
  { size = 4, backgroundColor, progressColor, progress = 0, ...props }: LinearProgressProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      {...props}
      ref={ref}
      css={(theme) => css`
        height: ${size}px;
        width: 100%;
        background-color: ${backgroundColor ?? theme.ds.colors.gray[600]};
        transition: ${theme.ds.transitions.default()};
        overflow: hidden;
        border-radius: ${theme.ds.borderRadiuses.round};
      `}
    >
      <div
        css={(theme) => css`
          height: 100%;
          transition: ${theme.ds.transitions.default()};
          width: ${Math.max(0, Math.min(progress, 1)) * 100}%;
          background-color: ${progressColor ?? theme.ds.colors.primary[500]};
          border-radius: ${theme.ds.borderRadiuses.round} 0 0 ${theme.ds.borderRadiuses.round};
        `}
      />
    </div>
  );
});

export const LinearProgress = memo(LinearProgressForwardRef);
