import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { UserHrisIdSource } from '@amalia/tenants/users/types';

import { IntegrationIdSourceFormikSelect } from '../../integration-id/select/IntegrationIdSourceFormikSelect';

import { useHrisIdSourceOptions } from './useHrisIdSourceOptions';

export type UserHrisIdSourceFormikSelectProps = {
  readonly focusInput: VoidFunction;
};

export const UserHrisIdSourceFormikSelect = memo(function UserHrisIdSourceFormikSelect({
  focusInput,
}: UserHrisIdSourceFormikSelectProps) {
  const { formatMessage } = useIntl();

  const options = useHrisIdSourceOptions({
    withoutValues: [UserHrisIdSource.NONE],
  });

  return (
    <IntegrationIdSourceFormikSelect
      confirmModalTitle={<FormattedMessage defaultMessage="Remove HRIS source" />}
      data-testid="user-info-table-select-hrisIdSource"
      focusInput={focusInput}
      integrationIdName="hrisId"
      integrationSourceName="hrisIdSource"
      label={<FormattedMessage defaultMessage="HRIS source" />}
      nullValue={UserHrisIdSource.NONE}
      options={options}
      confirmModalBody={
        <FormattedMessage defaultMessage="Are you sure you want to remove the HRIS source?{br}You will lose the associated HRIS ID." />
      }
      placeholder={formatMessage({
        defaultMessage: 'Select source',
      })}
    />
  );
});
