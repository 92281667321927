import { isArray, lowerCase, orderBy } from 'lodash';

import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.SORT, AmaliaFunctionCategory.ARRAY);

func.nbParamsRequired = 2;

func.description = 'Sort an array on one or more fields';

func.params = [
  { name: 'array', description: 'Array, Filters or Links', validFormats: [FormatsEnum.table] },
  { name: 'sortFields', description: 'Field(s) to sort dataset by' },
  { name: 'orders', description: 'Order(s) to order fields by ("ASC" or "DESC")', defaultValue: 'ASC' },
];

func.examples = [
  {
    desc: 'Returns the filtered dataset sorted by closeDate in descending order.',
    formula: 'SORT(filter.oppsByRepInPeriod, "closeDate", "DESC")' as AmaliaFormula,
  },
  {
    desc: 'Returns the filtered dataset sorted by closeDate in ascending order then by invoiceDate in descending order.',
    formula:
      'SORT(opportunity.oppToOpportunityLineItemLink, ["closeDate","invoiceDate"], ["ASC", "DESC"])' as AmaliaFormula,
  },
];

/**
 * Check that order argument input is valid.
 * @param order
 */
const validateOrderInput = (order: string): 'asc' | 'desc' => {
  const lowercaseOrder = lowerCase(order);
  switch (lowercaseOrder) {
    case 'desc':
    case 'asc':
      return lowercaseOrder;
    default:
      return 'asc';
  }
};

func.exec = (rows: any[], sortFields: string[] | string, orders?: string[]): any => {
  let sort: ('asc' | 'desc')[] | 'asc' | 'desc' = 'asc';
  if (orders) {
    sort = isArray(orders) ? orders?.map((o) => validateOrderInput(o)) : validateOrderInput(orders);
  }

  return orderBy(rows, sortFields, sort);
};

export default func;
