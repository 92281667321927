import { isEqual } from 'lodash';
import { useMemo } from 'react';

import { type ColumnDefinitions, type RowData } from '../../table/Table.types';
import { type ColumnOrderState } from '../DataGrid.types';

export const useReorderedColumns = <TData extends RowData>(
  columns: ColumnDefinitions<TData>,
  columnOrder?: ColumnOrderState,
): ColumnDefinitions<TData> =>
  useMemo(() => {
    if (
      !columnOrder?.length ||
      // If the column order is the same as the column order in the columns array, we don't need to sort.
      isEqual(
        columnOrder,
        columns.map((column) => column.id),
      )
    ) {
      return columns;
    }

    // Sort by given column order.
    // Columns that are not in the columnOrder list are put to the end in the order they were defined in columns.
    return columns.toSorted((colA, colB) => {
      const indexA = columnOrder.indexOf(colA.id);
      const indexB = columnOrder.indexOf(colB.id);

      if (indexA === -1 && indexB === -1) {
        return 0;
      }

      if (indexA === -1) {
        return 1;
      }

      if (indexB === -1) {
        return -1;
      }

      return indexA - indexB;
    });
  }, [columns, columnOrder]);
