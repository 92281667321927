import { http } from '@amalia/core/http/client';
import { type TeamAssignmentWithTeam, type TeamRole } from '@amalia/tenants/assignments/teams/types';

export type GetUserTeamAssignmentsOptions = {
  teamRole?: TeamRole;
  effectiveBetween?: {
    start?: Date | null;
    end?: Date | null;
  };
};
export class UserTeamAssignmentsApiClient {
  public static async getUserTeamAssignments(
    userId: string,
    {
      teamRole,
      effectiveBetween: { start: effectiveBetweenStartDate = null, end: effectiveBetweenEndDate = null } = {},
    }: GetUserTeamAssignmentsOptions = {},
  ) {
    const { data: userTeamAssignments } = await http.get<TeamAssignmentWithTeam[]>(
      `/users/${encodeURIComponent(userId)}/team-assignments`,
      {
        params: {
          teamRole,
          'effectiveBetween.start': effectiveBetweenStartDate,
          'effectiveBetween.end': effectiveBetweenEndDate,
        },
      },
    );

    return userTeamAssignments;
  }
}
