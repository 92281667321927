import { useTheme } from '@emotion/react';
import { IconSquareRoundedCheck, IconSquareRoundedX } from '@tabler/icons-react';
import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { RadioButtonGroup, type RadioButtonGroupProps } from '@amalia/design-system/components';

export type BooleanStringType = '' | 'false' | 'true';

export const BooleanString: Record<'EMPTY' | 'FALSE' | 'TRUE', BooleanStringType> = {
  TRUE: 'true',
  FALSE: 'false',
  EMPTY: '',
} as const;

export type BooleanSelectorProps = Omit<RadioButtonGroupProps<BooleanStringType>, 'options'>;

export const BooleanSelector = memo(function BooleanSelector({ ...props }: BooleanSelectorProps) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const options = useMemo(
    () => [
      {
        icon: <IconSquareRoundedX color={theme.ds.hues.red['500']} />,
        label: formatMessage({ defaultMessage: 'False' }),
        value: BooleanString.FALSE,
      },
      {
        icon: <IconSquareRoundedCheck color={theme.ds.hues.green['500']} />,
        label: formatMessage({ defaultMessage: 'True' }),
        value: BooleanString.TRUE,
      },
    ],
    [formatMessage, theme.ds.hues.green, theme.ds.hues.red],
  );

  return (
    <RadioButtonGroup
      {...props}
      options={options}
    />
  );
});
