import { IconX } from '@tabler/icons-react';
import { type ForwardedRef, forwardRef, memo } from 'react';
import { useIntl } from 'react-intl';

import { InputAction, type InputActionProps } from '../InputAction';

export type InputClearActionProps = Omit<InputActionProps, 'icon' | 'label' | 'variant'> & {
  /** Make label optional. */
  readonly label?: InputActionProps['label'];
};

const InputClearActionForwardRef = forwardRef(function InputClearAction(
  { label, ...props }: InputClearActionProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { formatMessage } = useIntl();

  return (
    <InputAction
      data-testid="input-clear-action"
      {...props}
      ref={ref}
      icon={<IconX />}
      label={label || formatMessage({ defaultMessage: 'Clear value' })}
      variant={InputAction.Variant.DANGER}
    />
  );
});

export const InputClearAction = memo(InputClearActionForwardRef);
