import { capitalize } from 'lodash';
import { memo, useCallback } from 'react';

import { formatUserFullName } from '@amalia/core/types';
import { Typography, UnstyledButton } from '@amalia/design-system/components';
import { useClipboard } from '@amalia/design-system/ext';
import { TypographyVariant } from '@amalia/design-system/meta';
import { useCurrentUser } from '@amalia/kernel/auth/state';

export const ImpersonatingBox = memo(function ImpersonatingBox() {
  const { data: currentUser } = useCurrentUser();
  const { copy } = useClipboard();

  const onClick = useCallback(() => {
    copy(currentUser.email);
  }, [currentUser, copy]);

  return (
    <UnstyledButton onClick={onClick}>
      <Typography
        as="div"
        variant={TypographyVariant.BODY_SMALL_REGULAR}
      >
        {formatUserFullName(currentUser)} - {capitalize(currentUser?.role)}
      </Typography>
      <Typography variant={TypographyVariant.BODY_BASE_MEDIUM}>
        {currentUser?.email} <Typography variant={TypographyVariant.BODY_SMALL_REGULAR}>(click to copy)</Typography>
      </Typography>
    </UnstyledButton>
  );
});
