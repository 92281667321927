import { css, type Theme } from '@emotion/react';

export const tableHeaderCell = (theme: Theme) => css`
  ${theme.ds.typographies.bodySmallMedium};
  color: ${theme.ds.colors.gray[700]};
  padding: 2px 16px;
  min-height: 48px;
`;

export const tableHeaderCellContent = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

export const tooltipIconContainer = css`
  &,
  > * {
    /* Considering that the tooltip child must be a single element (either ColumnTooltip or a Link). */
    display: flex;
  }
`;

export const multiSelectTitleContainer = css`
  display: flex;
  align-items: center;
  gap: 12px;

  // Make nested text-overflow work.
  min-width: 0;
`;

export const iconTitleContainer = css`
  display: flex;
  align-items: center;
  gap: 8px;

  // Make nested text-overflow work.
  min-width: 0;

  &[data-has-subtitle='true'] {
    gap: 12px;
  }

  > svg {
    flex: none;
  }
`;

export const titleContainer = css`
  // Make nested text-overflow work.
  min-width: 0;
`;

export const titleTooltipContainer = css`
  display: flex;
  align-items: center;
  gap: 8px;

  // Make nested text-overflow work.
  min-width: 0;
`;

export const subTitle = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[600]};
`;
