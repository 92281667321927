import { useMemo } from 'react';

import { type PayoutAndPerformanceChartStatisticsRecord } from '@amalia/reporting/custom-reports/shared';

export const useHideKpisForInactiveRecords = ({
  records,
  kpi1DataKey,
  kpi2DataKey,
  isRecordActiveByIndex,
}: {
  records?: PayoutAndPerformanceChartStatisticsRecord[];
  kpi1DataKey?: string;
  kpi2DataKey?: string;
  isRecordActiveByIndex: (index: number) => boolean;
}) =>
  // Don't show kpis for inactive records.
  useMemo(
    () =>
      records?.map((record, index) => {
        const isRecordActive = isRecordActiveByIndex(index);
        return {
          ...record,
          [kpi1DataKey]: isRecordActive ? record[kpi1DataKey] : undefined,
          [kpi2DataKey]: isRecordActive ? record[kpi2DataKey] : undefined,
        };
      }),
    [records, isRecordActiveByIndex, kpi1DataKey, kpi2DataKey],
  );
