import moment from 'moment';

import { type PlanAssignmentWithUser } from '../../types/planAssignments';

export const checkOngoingPlanAssignments = (pa: PlanAssignmentWithUser) => {
  const currentMoment = moment();
  return (
    (!pa.effectiveAsOf || currentMoment.isAfter(moment(pa.effectiveAsOf, 'X'))) &&
    (!pa.effectiveUntil || currentMoment.isBefore(moment(pa.effectiveUntil, 'X')))
  );
};
