import { type ComponentProps, memo, useMemo, type PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';

export type ReactIntlProviderProps = PropsWithChildren<ComponentProps<typeof IntlProvider>>;

export const ReactIntlProvider = memo(function ReactIntlProvider({
  defaultLocale = 'en',
  defaultRichTextElements: propsDefaultRichTextElements = undefined,
  ...props
}: ReactIntlProviderProps) {
  const defaultRichTextElements: Required<ReactIntlProviderProps>['defaultRichTextElements'] = useMemo(
    () => ({
      // @ts-expect-error -- The typings are wrong, and we cannot use self-closing tags with () => <br />.
      br: <br />,
      p: (chunk) => <p>{chunk}</p>,
      ol: (chunk) => <ol>{chunk}</ol>,
      ul: (chunk) => <ul>{chunk}</ul>,
      li: (chunk) => <li>{chunk}</li>,
      ...propsDefaultRichTextElements,
    }),
    [propsDefaultRichTextElements],
  );

  return (
    <IntlProvider
      {...props}
      defaultLocale={defaultLocale}
      defaultRichTextElements={defaultRichTextElements}
    />
  );
});
