import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { Layout } from '@amalia/core/layout/components';
import { routes } from '@amalia/core/routes';
import { AmaliaHead } from '@amalia/design-system/ext';
import { RootRoutes } from '@amalia/ext/react-router-dom';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { AbilityRouteProtector } from '@amalia/kernel/auth/state';

const TeamDetailContainer = lazy(() => import('./detail/TeamDetailContainer'));
const TeamListContainer = lazy(() => import('./list/TeamListContainer'));

export const TeamRoutes = memo(function TeamRoutes() {
  const { formatMessage } = useIntl();

  return (
    <Layout currentPage="teams">
      <RootRoutes>
        <Route
          path={`${routes.TEAM_ITEM}/*`}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Team)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Team details' })} />
              <TeamDetailContainer />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.TEAM_LIST}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Team)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Teams' })} />
              <TeamListContainer />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});
