import { useFormikContext } from 'formik';
import { pick } from 'lodash';
import { useCallback, useState } from 'react';

import { type UserInfo } from '@amalia/tenants/users/profile/types';
import { UserExternalIdSource, UserHrisIdSource } from '@amalia/tenants/users/types';

const popoverFields = ['externalId', 'hrisId', 'externalIdSource', 'hrisIdSource'] as const;

export const useConfirmResetPopoverFieldsIfErrors = ({
  onChangeIsOpen,
}: {
  onChangeIsOpen?: (isOpen: boolean) => void;
}) => {
  const [missingField, setMissingField] = useState<'externalId' | 'hrisId' | null>(null);

  const { values, errors, setFieldValue } = useFormikContext<Pick<UserInfo, (typeof popoverFields)[number]>>();

  const errorKeys = Object.keys(pick(errors, ['externalId', 'hrisId'])).filter(Boolean) as ('externalId' | 'hrisId')[];
  const hasErrors = errorKeys.length > 0;
  const [firstErrorKey] = errorKeys;

  const onChangeIsOpenProxy: typeof onChangeIsOpen = useCallback(
    (isOpen: boolean) => {
      if (!isOpen && hasErrors) {
        /*
         * Wait for the re-render to finish to avoid floating-ui immediately
         * dismissing the ConfirmModal, since we tried to dismiss the popover.
         */
        requestAnimationFrame(() => {
          setMissingField(firstErrorKey);
        });
        return;
      }

      onChangeIsOpen?.(isOpen);
    },
    [firstErrorKey, hasErrors, onChangeIsOpen],
  );

  const onCancelConfirmReset = useCallback(() => {
    setMissingField(null);
  }, []);

  const onConfirmReset = useCallback(async () => {
    if (errorKeys.includes('externalId')) {
      await setFieldValue('externalIdSource', UserExternalIdSource.NONE);
    }

    if (errorKeys.includes('hrisId')) {
      await setFieldValue('hrisIdSource', UserHrisIdSource.NONE);
    }

    setMissingField(null);

    onChangeIsOpen?.(false);
  }, [errorKeys, setFieldValue, onChangeIsOpen]);

  return {
    onChangeIsOpen: onChangeIsOpenProxy,
    onCancelConfirmReset,
    onConfirmReset,
    isConfirmResetOpen: !!missingField,
    missingField,
    formValues: values,
  };
};
