interface CompanyPreferencesSalesforce {
  oAuthFlow?: 'authorization' | 'client_credentials';
  useSandbox?: boolean;
}

export interface CompanyPreferences {
  connectors?: Record<string, CompanyPreferencesSalesforce | undefined>;
}

export interface CompanyCustomization {
  holdAndReleasePaymentDateLabel?: string;
  holdAndReleaseForecastPaymentDateLabel?: string;
  totalCommissionLabel?: string;
  reportsPrecision?: number;
  legacyKpiCardView?: boolean;
}

export const DEFAULT_REPORTS_PRECISION = 2;
