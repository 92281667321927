import { css, type Theme } from '@emotion/react';

export const UP_CLASSNAME = 'up';
export const DOWN_CLASSNAME = 'down';
export const FLAT_CLASSNAME = 'flat';

export const trend = (theme: Theme) => css`
  display: inline-flex;
  align-items: center;
  gap: 2px;

  padding: 2px 6px 2px 4px;
  border-radius: ${theme.ds.borderRadiuses.round};

  > svg {
    flex: none;
  }

  &.${UP_CLASSNAME} {
    background-color: ${theme.ds.colors.success[50]};
    color: ${theme.ds.colors.success[700]};

    > svg {
      color: ${theme.ds.colors.success[500]};
    }
  }

  &.${DOWN_CLASSNAME} {
    background-color: ${theme.ds.colors.danger[50]};
    color: ${theme.ds.colors.danger[700]};

    > svg {
      color: ${theme.ds.colors.danger[500]};
    }
  }

  &.${FLAT_CLASSNAME} {
    background-color: ${theme.ds.colors.warning[50]};
    color: ${theme.ds.colors.warning[700]};

    > svg {
      color: ${theme.ds.colors.warning[500]};
    }
  }
`;
