import { isEmpty, trim } from 'lodash';

import { toCurrencyCode } from '@amalia/ext/iso-4217';
import { assert, isEnum } from '@amalia/ext/typescript';
import { toLanguage } from '@amalia/kernel/intl/types';

import { UserRole } from './userRole';

export const toUserRole = (value: string): UserRole => {
  const role = value.toUpperCase();

  assert(isEnum(role, UserRole), `Unknown role ${value}`);

  return role;
};

/**
 * Validate and Map user property value from csv.
 * @param value
 * @param property
 */
export const toUserProperty = (value: string, property: number | string): string | undefined => {
  const sanitizedValue = trim(value);

  if (isEmpty(sanitizedValue)) {
    return undefined;
  }

  switch (property) {
    case 'language':
      return toLanguage(sanitizedValue);
    case 'role':
      return toUserRole(sanitizedValue);
    case 'currency':
      return toCurrencyCode(sanitizedValue);
    default:
      return sanitizedValue;
  }
};
