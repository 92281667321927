import { convertTimestampToDate, findRelativePeriodDate, formatDate, getDateRangeBoundaries } from '@amalia/core/types';
import { type Period, PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';

export const useFormattedPeriodName = (period?: Period | null) => {
  if (!period) {
    return {
      currentPeriodShortLabel: null,
      currentPeriodLongLabel: null,
      previousPeriodShortLabel: null,
      previousPeriodLongLabel: null,
    };
  }

  const boundaries = getDateRangeBoundaries(period.frequency, convertTimestampToDate(period.startDate));

  // Should never happen but just in case
  if (!boundaries.endDate) {
    return {
      currentPeriodShortLabel: null,
      currentPeriodLongLabel: null,
      previousPeriodShortLabel: null,
      previousPeriodLongLabel: null,
    };
  }

  const currentPeriodDate = convertTimestampToDate(boundaries.endDate);
  const previousPeriodDate = findRelativePeriodDate(period, period.frequency, -1, 'endDate');

  const currentPeriodLongLabel =
    period.frequency === PeriodFrequencyEnum.quarter
      ? formatDate(currentPeriodDate, '[Q]Q YYYY')
      : formatDate(currentPeriodDate, 'MMMM YYYY');

  const previousPeriodLongLabel =
    period.frequency === PeriodFrequencyEnum.quarter
      ? formatDate(previousPeriodDate, '[Q]Q YYYY')
      : formatDate(previousPeriodDate, 'MMMM YYYY');

  return {
    currentPeriodShortLabel: formatDate(currentPeriodDate, 'MM/YYYY'),
    currentPeriodLongLabel,
    previousPeriodShortLabel: formatDate(previousPeriodDate, 'MM/YYYY'),
    previousPeriodLongLabel,
  };
};
