import { type OverwriteCreationRequestDetails } from './overwrite.types';
import { OVERWRITE_CONTEXT, type OverwriteModalInitialValues } from './OverwriteModal';

const createRowChangeForRowTable = (row: OverwriteCreationRequestDetails, values: OverwriteModalInitialValues) => ({
  [row.rowId!]: { content: { [row.field]: values.newValue }, isApplyToOverall: values.isApplyToOverall },
});

const createRowChangeForFilterRemove = (row: OverwriteCreationRequestDetails, values: OverwriteModalInitialValues) => ({
  [row.rowId!]: { change: OVERWRITE_CONTEXT.FILTER_ROW_REMOVE, isApplyToOverall: values.isApplyToOverall },
});

const createRowChangeForCustomObject = (row: OverwriteCreationRequestDetails, values: OverwriteModalInitialValues) => ({
  [row.rowId!]: { [row.field]: values.newValue, isApplyToOverall: values.isApplyToOverall },
});

const createRowChangeForKpi = (row: OverwriteCreationRequestDetails, values: OverwriteModalInitialValues) => ({
  [row.field]: values.newValue,
  isApplyToOverall: values.isApplyToOverall,
});

export const createRowChange = (
  row: OverwriteCreationRequestDetails,
  values: OverwriteModalInitialValues,
  type: OVERWRITE_CONTEXT,
) => {
  if (type === OVERWRITE_CONTEXT.KPI) {
    return createRowChangeForKpi(row, values);
  }

  if (type === OVERWRITE_CONTEXT.ROWTABLE) {
    return createRowChangeForRowTable(row, values);
  }

  if (type === OVERWRITE_CONTEXT.FILTER_ROW_REMOVE) {
    return createRowChangeForFilterRemove(row, values);
  }

  return createRowChangeForCustomObject(row, values);
};
