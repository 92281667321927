import { http } from '@amalia/core/http/client';
import { type RefreshmentAndCalculationStopActions, type DataRefreshment } from '@amalia/core/types';

import { type PaginationOf } from './types';

export async function saGetOngoingRefreshments(): Promise<DataRefreshment[]> {
  const { data } = await http.get<DataRefreshment[]>('/sa_refreshments/ongoing');
  return data;
}

export async function saGetLastRefreshments(
  page: number,
  companiesId: string[],
  filterErrors: boolean,
): Promise<PaginationOf<DataRefreshment>> {
  const { data } = await http.get<PaginationOf<DataRefreshment>>('/sa_refreshments/last', {
    params: {
      page,
      companiesId,
      filterErrors,
    },
  });
  return data;
}

export async function saSetRefreshmentStatus(
  refreshmentId: string,
  action: RefreshmentAndCalculationStopActions,
): Promise<DataRefreshment> {
  const { data } = await http.patch<DataRefreshment>(`/sa_refreshments/operation/${refreshmentId}/${action}`);
  return data;
}
