import { CSS } from '@dnd-kit/utilities';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { IconGripVertical, IconPencil, IconTableShortcut, IconTrash, IconViewportWide } from '@tabler/icons-react';
import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import {
  Divider,
  IconButton,
  IconButtonLink,
  MenuDropdown,
  Stack,
  TextOverflow,
  Typography,
  type SelectOption,
} from '@amalia/design-system/components';
import { Sortable } from '@amalia/ext/dnd-kit';
import { Link } from '@amalia/ext/react-router-dom';
import { DashboardChartDisplay } from '@amalia/reporting/dashboards-v2/components';
import { useGetChartData } from '@amalia/reporting/dashboards-v2/state';
import {
  ChartDisplayContext,
  type ChartType,
  isKpiCardChartConfiguration,
  type ChartLayoutConfig,
  type DashboardChart,
} from '@amalia/reporting/dashboards-v2/types';

import { useChartFiltersPreferences } from '../hooks/useChartFiltersPreferences';

import { DashboardChartFilters } from './DashboardChartFilters';

interface DashboardChartWrapperProps {
  readonly chartLayoutConfig: ChartLayoutConfig;
  readonly dashboardChart: DashboardChart<ChartType>;
  readonly isEditModeActive: boolean;
  readonly onClickEdit: () => void;
  readonly onClickDelete: () => void;
  readonly onChartLayoutSizeChange: (chartLayoutConfigId: ChartLayoutConfig['id'], size: number) => void;
}

export const ChartCard = styled.div<Pick<DashboardChartWrapperProps, 'chartLayoutConfig' | 'isEditModeActive'>>`
  border-radius: ${({ theme }) => theme.ds.borderRadiuses.squared};
  box-shadow: ${({ theme }) => theme.ds.shadows.soft};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.ds.colors.gray[0]};
  grid-column: span ${(p) => p.chartLayoutConfig.size};
  grid-row: span ${(p) => p.chartLayoutConfig.height};
  &:hover {
    .chart-actions {
      visibility: visible;
    }
    .name-container {
      max-width: ${(p) => (p.isEditModeActive ? 'calc(100% - 90px)' : 'calc(100% - 30px)')};
    }
  }
`;

export const ChartCardHeader = styled.div<Pick<DashboardChartWrapperProps, 'isEditModeActive'>>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px 12px ${(p) => (p.isEditModeActive ? '12px' : '32px')};
  width: 100%;
  .name-container {
    display: flex;
    gap: 12px;
    align-items: center;
    max-width: 100%;
  }
`;

export const ChartCardHeaderActions = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  visibility: hidden;
`;

export const DashboardChartWrapper = memo(function DashboardChartWrapper({
  chartLayoutConfig,
  dashboardChart,
  onChartLayoutSizeChange,
  onClickEdit,
  onClickDelete,
  isEditModeActive,
}: DashboardChartWrapperProps) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const { filters, options, setSelectedPeriods, setSelectedTeams, setSelectedUsers } = useChartFiltersPreferences(
    dashboardChart.dashboard.id,
    dashboardChart.id,
    dashboardChart,
  );
  const { data, error } = useGetChartData(dashboardChart.dashboard.id, dashboardChart.id, filters);

  const handleChartLayoutSizeChange = useCallback(
    (size: number | null) => () => {
      onChartLayoutSizeChange(chartLayoutConfig.id, size ?? 12);
    },
    [onChartLayoutSizeChange, chartLayoutConfig.id],
  );

  const chartWidthOptions: SelectOption<number>[] = useMemo(() => {
    const defaultOptions = Array(12)
      .fill(0)
      .map((_v, i) => ({
        value: i + 1,
        label: `${i + 1} / 12${i + 1 === 12 ? ' (full width)' : i + 1 === 6 ? ' (half width)' : ''}`,
      }));

    switch (true) {
      case isKpiCardChartConfiguration(dashboardChart):
        return dashboardChart.displaySettings.isDoughnutEnabled ? defaultOptions.slice(5) : defaultOptions.slice(3);
      default:
        return defaultOptions;
    }
  }, [dashboardChart]);

  return (
    <Sortable
      key={chartLayoutConfig.id}
      id={chartLayoutConfig.id}
    >
      {({ setNodeRef, attributes, listeners, transform, transition, setActivatorNodeRef, isDragging }) => (
        <ChartCard
          key={dashboardChart.id}
          ref={setNodeRef}
          chartLayoutConfig={chartLayoutConfig}
          isEditModeActive={isEditModeActive}
          css={css`
            transform: ${CSS.Translate.toString(transform)};
            transition: ${transition};
            opacity: ${isDragging ? 0.5 : 1};
          `}
        >
          <ChartCardHeader isEditModeActive={isEditModeActive}>
            <div className="name-container">
              {!!isEditModeActive && (
                <div
                  {...attributes}
                  {...listeners}
                  ref={setActivatorNodeRef}
                  css={css`
                    cursor: grab;
                    display: flex;
                    align-items: center;
                    outline: none;
                  `}
                >
                  <IconGripVertical
                    color={theme.ds.colors.gray[600]}
                    size={16}
                  />
                </div>
              )}
              <Typography
                as={TextOverflow}
                variant={Typography.Variant.BODY_BASE_MEDIUM}
              >
                {dashboardChart.name}
              </Typography>
            </div>
            {!!isEditModeActive && (
              <ChartCardHeaderActions className="chart-actions">
                <MenuDropdown
                  content={chartWidthOptions.map((option) => (
                    <MenuDropdown.Item
                      key={option.value}
                      label={option.label}
                      onClick={handleChartLayoutSizeChange(option.value)}
                    />
                  ))}
                >
                  {({ isOpen }) => (
                    <MenuDropdown.IconButton
                      withBackground
                      icon={<IconViewportWide />}
                      label={formatMessage({ defaultMessage: 'Resize chart' })}
                      size={IconButton.Size.SMALL}
                      css={
                        !!isOpen &&
                        css`
                          visibility: visible;
                        `
                      }
                    />
                  )}
                </MenuDropdown>

                <IconButton
                  withBackground
                  icon={<IconPencil />}
                  label={formatMessage({ defaultMessage: 'Edit chart' })}
                  size={IconButton.Size.SMALL}
                  onClick={onClickEdit}
                />

                <IconButton
                  withBackground
                  icon={<IconTrash />}
                  label={formatMessage({ defaultMessage: 'Delete chart' })}
                  size={IconButton.Size.SMALL}
                  variant={IconButton.Variant.DANGER}
                  onClick={onClickDelete}
                />

                <IconButtonLink
                  withBackground
                  icon={<IconTableShortcut />}
                  label={formatMessage({ defaultMessage: 'View report' })}
                  size={IconButtonLink.Size.SMALL}
                  to={
                    <Link
                      openInNewTab
                      to={generatePath(routes.CUSTOM_REPORT_ITEM, { reportId: dashboardChart.customReportId })}
                    />
                  }
                />
              </ChartCardHeaderActions>
            )}
          </ChartCardHeader>
          <Divider.Horizontal
            css={css`
              background-color: ${theme.ds.colors.gray[50]};
            `}
          />

          <Stack>
            <div
              css={css`
                padding: 16px 32px;
                width: 100%;
              `}
            >
              <DashboardChartDisplay
                chartConfiguration={dashboardChart}
                chartData={data}
                chartDisplayContext={ChartDisplayContext.IN_DASHBOARD}
                error={error}
                chartFilters={
                  <DashboardChartFilters
                    chartConfiguration={dashboardChart}
                    filters={filters}
                    options={options}
                    setSelectedPeriods={setSelectedPeriods}
                    setSelectedTeams={setSelectedTeams}
                    setSelectedUsers={setSelectedUsers}
                  />
                }
              />
            </div>
          </Stack>
        </ChartCard>
      )}
    </Sortable>
  );
});
