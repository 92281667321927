import { IconPlus } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { Dropdown } from '@amalia/design-system/components';
import { Link } from '@amalia/ext/react-router-dom';

export const DropDownActionCreateReport = memo(function DropDownActionCreateReport() {
  return (
    <Link
      openInNewTab
      unsetStyle
      to={generatePath(routes.CUSTOM_REPORT_LIST)}
    >
      <Dropdown.Action icon={<IconPlus />}>
        <FormattedMessage defaultMessage="Create a report" />
      </Dropdown.Action>
    </Link>
  );
});
