import { type Permissions } from '@amalia/reporting/custom-reports/shared';
import { type Company } from '@amalia/tenants/companies/types';
import { type UserComputed, type UserStatus, type UserContract } from '@amalia/tenants/users/types';

export const DASHBOARD_LEGACY_ID = 'legacyDashboard';
export interface ChartLayoutConfig {
  chartId: string;
  size: number;
  height: number;
  id: string;
}

export interface Dashboard {
  id: string;

  createdAt: Date;

  createdBy?: UserContract;

  updatedAt: Date;

  updatedBy?: UserContract;

  company: Company;

  name: string;

  chartsLayoutConfig: ChartLayoutConfig[];

  permissions?: Permissions;
}

export interface DashboardShareCandidate extends UserComputed {
  status: UserStatus;
}

export interface UserDashboardRelation {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  companyId: string;
  dashboardId: string;
  userId: string;
}
