import { isNil, last } from 'lodash';
import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { DEFAULT_THEME } from '@amalia/design-system/themes/default';
import { type CommissionTableRow } from '@amalia/frontend/web-data-layers';
import { ChartModifierKeys, ChartModifiers, type CardinalChartRowWithNumberOnly, LineChart } from '@amalia/lib-ui';

import { useGetFormatChartValue } from '../../../utils/charts.utils';

interface TargetAchievementChartProps {
  readonly mode: 'LINEAR' | 'TIER';
  readonly table: CommissionTableRow[];
  readonly value?: number;
  readonly labelsToAdd?: CardinalChartRowWithNumberOnly[];
  readonly xAxisFormat: 'number' | 'percent';
  readonly yAxisFormat: 'number' | 'percent';
}

const INTERPRETATION_RATIO = 0.25;

const computeChartData = (
  mode: 'LINEAR' | 'TIER',
  table: CommissionTableRow[],
  xToHighlight?: number,
): {
  chartData: CardinalChartRowWithNumberOnly[];
  dataPointToHighlight: CardinalChartRowWithNumberOnly;
} => {
  const dataRange =
    // MAX
    (isNil(last(table)[1]) ? last(table)[0] : last(table)[1]) -
    // MIN
    (isNil(table[0][0]) ? table[0][1] : table[0][0]);

  const data = table.map((tableRow) => {
    // If a value is null, it means that it's an "Infinity" or "-Infinity" value.

    // In the case of upperBound, if Infinity is detected, try to multiply the lower bound by the interpretationRatio
    const upperBound = isNil(tableRow[1]) ? (tableRow[0] || 0) + dataRange * INTERPRETATION_RATIO : tableRow[1];

    // In the case of lowerBound, if -Infinity is detected, try to divide the upper bound by the interpreationRatio
    const lowerBound = isNil(tableRow[0]) ? (tableRow[1] || 0) - dataRange * INTERPRETATION_RATIO : tableRow[0];

    return [lowerBound, upperBound, tableRow[2]];
  });

  const chartData: CardinalChartRowWithNumberOnly[] = [];

  let dataPointY = Infinity;
  // make sure X is in graph
  const dataPointX = Math.min(Math.max(xToHighlight || 0, data[0][0]), last(data)[0]);

  switch (mode) {
    case 'LINEAR':
      // Push first item of each row
      chartData.push(...data.map((dataTableRow) => ({ x: dataTableRow[0], value: dataTableRow[2] })));

      // Compute y to highlight
      data.forEach((dataTableRow, idx) => {
        if (
          dataTableRow[0] <= dataPointX &&
          !isNil(data[idx + 1]?.[0]) &&
          data[idx + 1][0] >= dataPointX &&
          dataPointY === Infinity
        ) {
          // (((yb - ya) / (xb - xa)) * (x - xa)) + ya
          dataPointY =
            ((data[idx + 1][2] - dataTableRow[2]) / (data[idx + 1][0] - dataTableRow[0])) *
              (dataPointX - dataTableRow[0]) +
            dataTableRow[2];
        }
      });
      break;
    case 'TIER':
    default:
      // For each row (except the last one), draw the stair
      data.forEach((dataTableRow, idx) => {
        // Horizontal
        chartData.push({ x: dataTableRow[0], value: dataTableRow[2] });
        chartData.push({ x: dataTableRow[1], value: dataTableRow[2] });

        // Vertical
        if (idx < data.length - 1) {
          chartData.push({ x: dataTableRow[1], value: data[idx + 1][2] });
        }
      });

      // Compute y to highlight
      data.forEach((dataTableRow) => {
        if (dataTableRow[0] <= dataPointX && dataTableRow[1] > dataPointX) {
          dataPointY = dataTableRow[2];
        }
      });
  }

  return {
    chartData,
    dataPointToHighlight: { x: dataPointX, value: dataPointY },
  };
};

export const TargetAchievementChart = memo(function TargetAchievementChart({
  mode,
  table,
  value,
  labelsToAdd,
  xAxisFormat,
  yAxisFormat,
}: TargetAchievementChartProps) {
  const { chartData, dataPointToHighlight } = useMemo(() => computeChartData(mode, table, value), [table, mode, value]);

  const { formatMessage } = useIntl();
  const formatChartValue = useGetFormatChartValue();

  const modifiers = useMemo(() => {
    const basicModifiers = [
      ChartModifiers[ChartModifierKeys.TOOLTIP_SCATTER_PLOT]({
        tooltipFormatFn: (d: any) =>
          formatMessage(
            { defaultMessage: '{abscissa}: {value}' },
            { abscissa: formatChartValue(d.x, xAxisFormat), value: formatChartValue(d.value, yAxisFormat) },
          ),
        forceFillColor: DEFAULT_THEME.ds.colors.primary[500],
      }),
      value
        ? ChartModifiers[ChartModifierKeys.HIGHLIGHTED_POINT]({
            point: dataPointToHighlight,
            tooltipText: `Your Achievement: ${formatChartValue(
              dataPointToHighlight.x,
              xAxisFormat,
            )}: ${formatChartValue(dataPointToHighlight.value, yAxisFormat)}`,
            forceFillColor: DEFAULT_THEME.ds.colors.primary[500],
          })
        : null,
    ].filter(Boolean);
    basicModifiers.push(...(labelsToAdd || []).map((l) => ChartModifiers[ChartModifierKeys.LABEL]({ point: l })));

    return basicModifiers;
  }, [dataPointToHighlight, labelsToAdd, xAxisFormat, yAxisFormat, value, formatMessage, formatChartValue]);

  return (
    <LineChart
      data={chartData}
      height={400}
      margin={20}
      modifiers={modifiers}
      width={650}
      options={{
        showAdditionalGridLines: true,
        formatYticks: (tickValue) => formatChartValue(tickValue, yAxisFormat),
        formatXticks: (tickValue) => formatChartValue(tickValue, xAxisFormat),
      }}
      styles={{
        color: 'primary',
        mainAxisColor: 'normal',
        mainAxisLabelColor: 'normal',
        dimensionAxisColor: 'normal',
        dimensionAxisLabelColor: 'normal',
      }}
    />
  );
});
