import { IconWriting } from '@tabler/icons-react';
import { memo, useCallback, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type CustomObject } from '@amalia/core/types';
import { FormatsEnum, type Property } from '@amalia/data-capture/fields/types';
import { Table } from '@amalia/design-system/components';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { type CurrencyValue } from '@amalia/kernel/monetary/types';
import { CustomTableContext } from '@amalia/lib-ui';

type TableCellActionsProps = Readonly<{
  property: Property;
  columnName: string;
  row: CustomObject;
  rowIndex: number;
}>;

export const TableCellActions = memo(function TableCellActions({
  property,
  columnName,
  row,
  rowIndex,
}: TableCellActionsProps) {
  const { formatMessage } = useIntl();
  const { format, ref: valueRef, machineName, name } = property;
  const { content } = row;
  const ability = useAbilityContext();
  const canOverwriteData = ability.can(ActionsEnum.overwrite, SubjectsEnum.Data);
  const columnValue = content[columnName];

  const { objectDefinition, setOverwriteModalInfo } = useContext(CustomTableContext);
  const currencySymbol = useMemo(
    () => (FormatsEnum.currency === format ? (row?.content[columnName] as CurrencyValue)?.symbol : null),
    [row, columnName, format],
  );

  const isRelationOrExternal =
    objectDefinition.externalIds?.includes(machineName) || objectDefinition.nameField === machineName;

  const handleCreateOverwrite = useCallback(() => {
    setOverwriteModalInfo({
      format,
      ref: valueRef,
      currency: currencySymbol,
      oldValue: format === FormatsEnum.currency ? (columnValue as CurrencyValue)?.value : columnValue,
      field: columnName,
      fieldName: name,
      opportunityObject: objectDefinition.name,
      rowNameOrId: row.name || row.id,
      rowId: rowIndex,
      isProperty: true,
    });
  }, [
    setOverwriteModalInfo,
    format,
    valueRef,
    currencySymbol,
    columnValue,
    columnName,
    name,
    objectDefinition.name,
    row.name,
    row.id,
    rowIndex,
  ]);

  if (!canOverwriteData || isRelationOrExternal) {
    return null;
  }
  return (
    <Table.Cell.IconAction
      icon={<IconWriting />}
      label={formatMessage(
        { defaultMessage: 'Overwrite on {rowId}, field {columnName}' },
        { rowId: row.id, columnName },
      )}
      onClick={handleCreateOverwrite}
    />
  );
});
