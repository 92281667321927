import { memo } from 'react';
import { Navigate, generatePath, useParams } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import { routes } from '@amalia/core/routes';
import { DataExportsApiClient } from '@amalia/frontend/web-data-layers';

export const DataExports = memo(function DataExports() {
  const { exportId } = useParams<{ exportId: string }>();

  useAsyncEffect(() => DataExportsApiClient.downloadDataExportFile(exportId), [exportId]);

  return <Navigate to={generatePath(routes.STATEMENTS)} />;
});
