import { memo } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { Layout } from '@amalia/core/layout/components';
import { routes } from '@amalia/core/routes';
import { AmaliaHead } from '@amalia/design-system/ext';
import { RootRoutes } from '@amalia/ext/react-router-dom';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { AbilityRouteProtector } from '@amalia/kernel/auth/state';

import { OverwritesContainer } from './OverwritesContainer';

export const OverwritesRoutes = memo(function OverwritesRoutes() {
  const { formatMessage } = useIntl();

  return (
    <Layout currentPage="overwrites">
      <RootRoutes>
        <Route
          path={routes.OVERWRITES}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Overwrite_Recap)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Overwrites' })} />
              <OverwritesContainer />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});
