import { useMemo } from 'react';

import { roundNumber } from '@amalia/core/types';
import { type PayoutAndPerformanceChartStatisticsRecord } from '@amalia/reporting/custom-reports/shared';

export const useRoundRecordValues = (records?: PayoutAndPerformanceChartStatisticsRecord[]) =>
  useMemo(
    () =>
      records?.map((record) => ({
        ...record,
        ruleMetricPayment__value: record.ruleMetricPayment__value?.value
          ? {
              ...record.ruleMetricPayment__value,
              value: roundNumber(record.ruleMetricPayment__value.value),
            }
          : record.ruleMetricPayment__value,
      })),
    [records],
  );
