import { css, type Theme } from '@emotion/react';

export const tableCell = (theme: Theme) => css`
  text-align: left;
  background-color: inherit;

  &[data-sticky-left='true'],
  &[data-sticky-right='true'] {
    position: sticky;
  }

  // Only set the z-index when the table is not fully scrolled to the left (to make the sticky cell above the other cells), otherwise it cuts the focus outline of the next cell (CellTextField, CellSelect, CellDatePicker).
  div[data-scrolled-to-the-left='false'] &[data-sticky-left='true'],
  // Same thing for the last cell.
  div[data-scrolled-to-the-right='false'] &[data-sticky-right='true'] {
    z-index: 10;
  }

  &[data-sticky-right='true'] {
    right: 0;
  }

  /* Need to set borders on table cells instead of the table itself or it looks weird with column pinning. */
  &:first-of-type,
  *:not([data-sticky-left='true']) + & {
    border-left: 1px solid ${theme.ds.colors.gray[100]};
  }

  &:last-of-type,
  &[data-sticky-left='true'] {
    border-right: 1px solid ${theme.ds.colors.gray[100]};
  }

  tr[data-firstrow='true'] & {
    border-top: 1px solid ${theme.ds.colors.gray[100]};

    &:first-of-type {
      border-top-left-radius: ${theme.ds.borderRadiuses.squared};
    }

    &:last-of-type {
      border-top-right-radius: ${theme.ds.borderRadiuses.squared};
    }
  }

  tr[data-lastrow='true'] & {
    border-bottom: 1px solid ${theme.ds.colors.gray[100]};

    &:first-of-type {
      border-bottom-left-radius: ${theme.ds.borderRadiuses.squared};
    }

    &:last-of-type {
      border-bottom-right-radius: ${theme.ds.borderRadiuses.squared};
    }
  }
`;
