import { memo } from 'react';

import { Checkbox } from '../../../../data-input/checkbox/Checkbox';
import { getRowKey } from '../../helpers/getRowKey';
import { type UseTableMultiSelectionValue } from '../../hooks/useTableMultiSelection';
import { useTableContext } from '../../Table.context';
import { type ColumnDefinitions, type RowData, type RowKey } from '../../Table.types';
import { TableDataCell } from '../table-data-cell/TableDataCell';
import { TableDataCellContent } from '../table-data-cell-content/TableDataCellContent';
import { TableDataRow } from '../table-data-row/TableDataRow';

import { TableBodyRenderCell } from './table-body-render-cell/TableBodyRenderCell';

export type TableBodyProps<TData extends RowData, TKey extends RowKey> = Pick<
  UseTableMultiSelectionValue<TKey>,
  'isSelectionEnabled' | 'selectionColumnWidth'
> & {
  /** Column definitions. */
  readonly columns: ColumnDefinitions<TData>;
  /** Rows. */
  readonly data: TData[];
  /** List of keys of selected rows (determined by rowKey). */
  readonly selectedRows?: TKey[];
  /** Handle selecting a row. */
  readonly onSelectRow?: (isSelected: boolean, row: TKey) => void;
  // FIXME(gautier): is the prop name good for you?
  /** Disable selection on some rows with this predicate */
  readonly isRowSelectableFn?: (row: TData) => boolean;
};

const TableBodyBase = function TableBody<TData extends RowData, TKey extends RowKey>({
  columns,
  data,
  onSelectRow,
  isSelectionEnabled,
  selectedRows,
  selectionColumnWidth,
  isRowSelectableFn,
}: TableBodyProps<TData, TKey>) {
  const { headerRowsCount, isLoading, pinFirstColumn, pinLastColumn, rowKey } = useTableContext<TData, TKey>();

  return (
    <tbody>
      {data.map((row, rowIndex) => {
        const key = getRowKey(row, rowKey);
        return (
          <TableDataRow
            key={key}
            data-row-key={key}
            index={headerRowsCount + rowIndex}
          >
            {/* Show selection column if needed. */}
            {!!isSelectionEnabled && (
              <TableDataCell
                isStickyLeft={!!pinFirstColumn}
                size={0}
                stickyLeftOffset={0}
              >
                <TableDataCellContent>
                  <Checkbox
                    checked={selectedRows?.includes(key)}
                    disabled={isLoading || (isRowSelectableFn ? !isRowSelectableFn(row) : false)}
                    size={Checkbox.Size.SMALL}
                    onChange={(isChecked) => onSelectRow?.(isChecked, key)}
                  />
                </TableDataCellContent>
              </TableDataCell>
            )}

            {columns.map((column, cellIndex) => (
              <TableDataCell
                key={column.id}
                data-cell-key={`cell-${key}-${column.id}`}
                data-column-id={column.id}
                isStickyLeft={cellIndex === 0 && !!pinFirstColumn}
                isStickyRight={cellIndex === columns.length - 1 && pinLastColumn}
                size={column.size}
                stickyLeftOffset={selectionColumnWidth} // Offset the sticky column by the width of the selection column (will be 0 if not displayed).
              >
                <TableBodyRenderCell
                  cellIndex={cellIndex}
                  column={column}
                  row={row}
                  rowIndex={rowIndex}
                />
              </TableDataCell>
            ))}
          </TableDataRow>
        );
      })}
    </tbody>
  );
};

export const TableBody = memo(TableBodyBase) as typeof TableBodyBase;
