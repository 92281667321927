import { memo, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';

import { isConflictError } from '@amalia/core/http/client';
import { routes } from '@amalia/core/routes';
import { Input, Modal, useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import { PlansApiClient } from '@amalia/payout-definition/api-client';

type DuplicatePlanModalProps = {
  readonly planId: string;
  readonly isModalOpen: boolean;
  readonly onCloseModal: () => void;
  readonly defaultPlanName: string;
  readonly refetchPlans: () => Promise<void>;
  readonly redirectOnSuccess?: boolean;
};

export const DuplicatePlanModal = memo(function DuplicatePlanModal({
  isModalOpen,
  onCloseModal,
  defaultPlanName,
  planId,
  refetchPlans,
  redirectOnSuccess = false,
}: DuplicatePlanModalProps) {
  const { snackError, snackSuccess } = useSnackbars();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [planName, setPlanName] = useState<string>(defaultPlanName);

  const [inputError, setInputError] = useState<string | null>(null);

  useEffect(() => {
    setPlanName(defaultPlanName);
  }, [defaultPlanName]);

  const resetInputError = useCallback(() => {
    setInputError(null);
  }, []);

  const handleCloseModal = useCallback(() => {
    onCloseModal();
    resetInputError();
    setPlanName(defaultPlanName);
  }, [defaultPlanName, onCloseModal, resetInputError]);

  const handleDuplicate = useCallback(async () => {
    try {
      const newPlan = await PlansApiClient.duplicatePlan(planId, planName);
      snackSuccess(
        formatMessage({ defaultMessage: 'Plan duplicated! New plan name is {planName}' }, { planName: newPlan.name }),
      );
      handleCloseModal();
      if (redirectOnSuccess) {
        navigate(generatePath(routes.DESIGNER_PLAN, { planId: newPlan.id }));
      } else {
        await refetchPlans();
      }
    } catch (error) {
      snackError(toError(error).message);
      if (isConflictError(error)) {
        setInputError(formatMessage({ defaultMessage: 'Plan with this name already exists' }));
      }
    }
  }, [
    planId,
    planName,
    snackSuccess,
    formatMessage,
    handleCloseModal,
    refetchPlans,
    redirectOnSuccess,
    navigate,
    snackError,
  ]);

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={handleCloseModal}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Duplicate a plan" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Input
            required
            error={inputError}
            label={<FormattedMessage defaultMessage="Name" />}
            value={planName}
            onChange={setPlanName}
            onChangeCapture={resetInputError}
          />
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />
        <Modal.MainAction onClick={handleDuplicate}>
          <FormattedMessage defaultMessage="Confirm" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
