import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import { TeamAssignmentsApiClient } from '@amalia/tenants/assignments/teams/api-client';
import { type ImportedTeamAssignmentsRows, type ComputedTeamAssignment } from '@amalia/tenants/assignments/teams/types';
import { TEAM_QUERY_KEYS } from '@amalia/tenants/teams/state';

import { TEAM_ASSIGNMENTS_KEYS } from './queries.keys';

export const useTeamsAssignments = (teamIds: string[] = []) => {
  const { snackError } = useSnackbars();

  const {
    data: teamsAssignments,
    isError,
    error,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: [TEAM_QUERY_KEYS.TEAMS, TEAM_ASSIGNMENTS_KEYS.TEAMS_ASSIGNMENTS, teamIds],
    queryFn: () => TeamAssignmentsApiClient.fetchMultipleTeamMembersAssignments(teamIds),
    enabled: teamIds.length > 0,
  });

  useEffect(() => {
    if (isError) {
      snackError(toError(error).message);
    }
  }, [isError, error, snackError]);

  return {
    teamsAssignments,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useTeamAssignments = (teamId: string) => {
  const { snackError } = useSnackbars();

  const {
    data: teamAssignments,
    isError,
    error,
    isSuccess,
  } = useQuery({
    queryKey: [TEAM_QUERY_KEYS.TEAMS, TEAM_ASSIGNMENTS_KEYS.TEAMS_ASSIGNMENTS, teamId],
    queryFn: () => TeamAssignmentsApiClient.fetchTeamMembersAssignments(teamId),
    enabled: !!teamId,
  });

  useEffect(() => {
    if (isError) {
      snackError(toError(error).message);
    }
  }, [isError, error, snackError]);

  return {
    teamAssignments,
    isSuccess,
    isError,
  };
};

export const useUpdateTeamMemberAssignment = (
  teamId: string,
  teamAssignments: Record<string, Partial<ComputedTeamAssignment>>,
) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackError, snackSuccess } = useSnackbars();

  return useMutation({
    mutationKey: [TEAM_QUERY_KEYS.TEAMS, TEAM_ASSIGNMENTS_KEYS.TEAMS_ASSIGNMENTS, teamId, 'update'],
    mutationFn: (updatedAssignment: ComputedTeamAssignment) =>
      TeamAssignmentsApiClient.applyAssignmentDiff(
        teamId,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (teamAssignments[updatedAssignment.userId] as ComputedTeamAssignment) || null,
        updatedAssignment,
      ),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Assignment updated.' }));
      await queryClient.invalidateQueries({
        queryKey: [TEAM_QUERY_KEYS.TEAMS, TEAM_ASSIGNMENTS_KEYS.TEAMS_ASSIGNMENTS, teamId],
      });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useCreateTeamMemberAssignment = (teamId: string) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackError, snackSuccess } = useSnackbars();

  return useMutation({
    mutationKey: [TEAM_QUERY_KEYS.TEAMS, TEAM_ASSIGNMENTS_KEYS.TEAMS_ASSIGNMENTS, 'create'],
    mutationFn: (createdAssignment: ComputedTeamAssignment) =>
      TeamAssignmentsApiClient.applyAssignmentDiff(teamId, null, createdAssignment),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Assignment created.' }));
      await queryClient.invalidateQueries({
        queryKey: [TEAM_QUERY_KEYS.TEAMS, TEAM_ASSIGNMENTS_KEYS.TEAMS_ASSIGNMENTS, teamId],
      });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useDeleteTeamMemberAssignment = (teamId: string) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackError, snackSuccess } = useSnackbars();

  return useMutation({
    mutationKey: [TEAM_QUERY_KEYS.TEAMS, TEAM_ASSIGNMENTS_KEYS.TEAMS_ASSIGNMENTS, 'delete'],
    mutationFn: (assignmentId: string) => TeamAssignmentsApiClient.removeTeamAssignment(assignmentId, teamId),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Assignment deleted.' }));
      await queryClient.invalidateQueries({
        queryKey: [TEAM_QUERY_KEYS.TEAMS, TEAM_ASSIGNMENTS_KEYS.TEAMS_ASSIGNMENTS, teamId],
      });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useValidateBulkImportTeamAssignments = () => {
  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: [TEAM_QUERY_KEYS.TEAMS, TEAM_ASSIGNMENTS_KEYS.TEAMS_ASSIGNMENTS, 'validate-bulk-import'],
    mutationFn: TeamAssignmentsApiClient.importBulkTeamAssignments,
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useConfirmBulkImportTeamAssignments = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackError, snackSuccess } = useSnackbars();

  return useMutation({
    mutationKey: [TEAM_QUERY_KEYS.TEAMS, TEAM_ASSIGNMENTS_KEYS.TEAMS_ASSIGNMENTS, 'confirm-bulk-import'],
    mutationFn: (rows: ImportedTeamAssignmentsRows) => TeamAssignmentsApiClient.importBulkTeamAssignments(rows, false),
    onError: (e) => {
      snackError(toError(e).message);
    },
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Teams Assignments updated.' }));
      await queryClient.invalidateQueries({
        queryKey: [TEAM_QUERY_KEYS.TEAMS, TEAM_ASSIGNMENTS_KEYS.TEAMS_ASSIGNMENTS],
      });
    },
  });
};
