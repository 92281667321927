import { css } from '@emotion/react';

export const container = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`;

export const alert = css`
  width: 100%;
`;

export const illustration = css`
  width: 170px;
`;
