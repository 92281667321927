import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { formatUserFullName, type Statement } from '@amalia/core/types';
import { Avatar, FormField, Group, Typography } from '@amalia/design-system/components';

interface StatementDetailsProps {
  readonly statement: Statement;
}

export const AdjustmentStatementDetails = memo(function AdjustmentStatementDetails({
  statement,
}: StatementDetailsProps) {
  return (
    <FormField
      id="statement"
      label={<FormattedMessage defaultMessage="Statement" />}
    >
      <Group
        align="center"
        gap={16}
      >
        <Avatar
          size={40}
          user={statement.user}
        />

        <div>
          <Typography
            as="div"
            variant={Typography.Variant.BODY_BASE_MEDIUM}
          >
            <FormattedMessage
              defaultMessage="{userName} / {periodName}"
              values={{ userName: formatUserFullName(statement.user), periodName: statement.period.name }}
            />
          </Typography>

          <Typography
            as="div"
            variant={Typography.Variant.BODY_BASE_MEDIUM}
          >
            {statement.plan.name}
          </Typography>
        </div>
      </Group>
    </FormField>
  );
});
