import { type JSONContent } from '@tiptap/react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentStatement } from '@amalia/frontend/web-data-layers';
import { PayoutCollaborationCommentsApiClient } from '@amalia/payout-collaboration/comments/api-client';
import { type MessageContent, MessageTypeEnum } from '@amalia/payout-collaboration/comments/types';

import { useStatementUsers } from '../mentions/useStatementUsers';

export const useFormatCommentMessage = () => {
  const currentStatement = useSelector(selectCurrentStatement);

  const users = useStatementUsers(
    PayoutCollaborationCommentsApiClient.getUsersAllowedToViewStatement,
    currentStatement,
  );

  const fromEditorToContent = useCallback(
    (content: JSONContent[] | undefined): MessageContent[] =>
      !content
        ? []
        : content
            .map((node) => {
              switch (node.type) {
                case MessageTypeEnum.mention: {
                  return {
                    type: MessageTypeEnum.mention,
                    content: node.attrs?.label as string,
                    userId: node.attrs?.id as string,
                  };
                }
                case MessageTypeEnum.text: {
                  return {
                    type: MessageTypeEnum.text,
                    content: node.text ?? '',
                  };
                }
                default:
                  return null;
              }
            })
            .filter(Boolean),
    [],
  );

  const fromContentToEditor = useCallback(
    (content: MessageContent[]): JSONContent[] =>
      content
        .map((node) => {
          switch (node.type) {
            case MessageTypeEnum.mention: {
              const mentionnedUser = users.find((usr) => usr.id === node.userId);

              return {
                type: 'mention',
                attrs: {
                  id: node.userId,
                  label: node.content,
                },
                text: `@${mentionnedUser?.firstName} ${mentionnedUser?.lastName}`,
              };
            }
            case MessageTypeEnum.text: {
              return {
                type: MessageTypeEnum.text,
                text: node.content,
              };
            }
            default:
              return null;
          }
        })
        .filter(Boolean),
    [users],
  );

  return {
    fromEditorToContent,
    fromContentToEditor,
  };
};
