import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { UserExternalIdSource } from '@amalia/tenants/users/types';

import { IntegrationIdSourceFormikSelect } from '../../integration-id/select/IntegrationIdSourceFormikSelect';

import { useExternalIdSourceOptions } from './useExternalIdSourceOptions';

export type UserExternalIdSourceFormikSelectProps = {
  readonly focusInput: VoidFunction;
};

export const UserExternalIdSourceFormikSelect = memo(function UserExternalIdSourceFormikSelect({
  focusInput,
}: UserExternalIdSourceFormikSelectProps) {
  const { formatMessage } = useIntl();

  const externalIdSourceOptions = useExternalIdSourceOptions({
    withoutValues: [UserExternalIdSource.NONE],
  });

  return (
    <IntegrationIdSourceFormikSelect
      confirmModalTitle={<FormattedMessage defaultMessage="Remove external source" />}
      data-testid="user-info-table-select-externalIdSource"
      focusInput={focusInput}
      integrationIdName="externalId"
      integrationSourceName="externalIdSource"
      label={<FormattedMessage defaultMessage="External source" />}
      nullValue={UserExternalIdSource.NONE}
      options={externalIdSourceOptions}
      confirmModalBody={
        <FormattedMessage defaultMessage="Are you sure you want to remove the external source?{br}You will lose the associated external ID." />
      }
      placeholder={formatMessage({
        defaultMessage: 'Select source',
      })}
    />
  );
});
