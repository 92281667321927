import styled from '@emotion/styled';

import { FormLayoutSize } from '../FormLayout.types';

export const FormLayoutCheckboxGroup = styled.div`
  // Use display flex to prevent vertical margins of checkboxes/switches from collapsing.
  display: flex;
  flex-direction: column;
  [data-form-layout-size=${FormLayoutSize.SMALL}] & {
    > * {
      margin: 6px 0;
    }
  }
  [data-form-layout-size=${FormLayoutSize.MEDIUM}] & {
    > * {
      margin: 8px 0;
    }
  }
`;
