import { useFormikContext } from 'formik';
import { type ChangeEvent, memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { type CurrencyValue } from '@amalia/kernel/monetary/types';
import { Input } from '@amalia/lib-ui';

import { OverwriteFormField, type OverwriteFormValuesShape } from '../../overwrite.types';
import { getTheValueOfPercentage } from '../../overwrite.utils';

import { messages } from './selectors.messages';
import { type CommonOverwriteSelectorProps } from './selectors.types';

export const OVERWRITE_INPUT_TEST_ID = `${OverwriteFormField.NEW_VALUE}-input`;

export type NumberOverwriteSelectorProps = CommonOverwriteSelectorProps;

export const NumberOverwriteSelector = memo(function NumberOverwriteSelector({
  currentObjectDetails,
}: NumberOverwriteSelectorProps) {
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useFormikContext<OverwriteFormValuesShape>();

  const handleChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      await setFieldValue(OverwriteFormField.NEW_VALUE, e.target.value);
      setFieldValue(
        OverwriteFormField.PERCENTAGE,
        getTheValueOfPercentage(currentObjectDetails.oldValue as CurrencyValue | number, Number(e.target.value)),
      );
    },
    [currentObjectDetails, setFieldValue],
  );

  return (
    <Input
      autoFocus
      data-testid={OVERWRITE_INPUT_TEST_ID}
      id={OverwriteFormField.NEW_VALUE}
      inputProps={{ 'aria-label': OverwriteFormField.NEW_VALUE }}
      name={OverwriteFormField.NEW_VALUE}
      placeholder={formatMessage(messages[FormatsEnum.number])}
      type="number"
      value={values.newValue}
      onChange={handleChange}
    />
  );
});
