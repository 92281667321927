import { useIntl } from 'react-intl';

export const useGetFormatChartValue = () => {
  const { formatNumber } = useIntl();

  return (value: number, format: 'number' | 'percent') =>
    format === 'number'
      ? formatNumber(value, { maximumFractionDigits: 2 })
      : formatNumber(value, { maximumFractionDigits: 2, style: 'percent' });
};
