import { createContext, useContext } from 'react';

import { assert } from '@amalia/ext/typescript';

export type BreadcrumbsContextValue = {
  readonly withShadow?: boolean;
};

export const BreadcrumbsContext = createContext<BreadcrumbsContextValue | null>(null);

export const useBreadcrumbsContext = (): BreadcrumbsContextValue => {
  const context = useContext(BreadcrumbsContext);
  assert(context, 'useBreadcrumbsContext must be used within a <BreadcrumbsContext.Provider>');
  return context;
};
