import { http } from '@amalia/core/http/client';
import { type ConnectorResponseWithLastRefreshment } from '@amalia/data-capture/connectors/types';

export async function saGetConnectors(companiesId: string[]): Promise<ConnectorResponseWithLastRefreshment[]> {
  const { data } = await http.get<ConnectorResponseWithLastRefreshment[]>('/sa_connectors', {
    params: {
      companiesId,
    },
  });
  return data;
}
