import { type MessageDescriptor, type MessageFormatElement } from 'react-intl';

import { type QuotaType, type TokenType } from '@amalia/amalia-lang/tokens/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { type RuleType } from '@amalia/payout-definition/plans/types';

export enum DesignerObjectsActions {
  ASSIGN = 'ASSIGN',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT',
  DUPLICATE = 'DUPLICATE',
  EDIT = 'EDIT',
  EDIT_ACHIEVED = 'EDIT_ACHIEVED',
  EDIT_DESCRIPTION = 'EDIT_DESCRIPTION',
  FORECAST = 'FORECAST',
  OPEN = 'OPEN',
  REMOVE_FROM_RULE = 'REMOVE_FROM_RULE',
}

export interface DesignerCustomObject {
  type: TokenType;
  object: CustomObjectDefinition;
}

export interface DesignerObjectDetails {
  key: string;
  textColor?: string;
  fieldsToSearchIn?: string[];
  group?: {
    field: string | ((object: any) => string);
    format?: (value: QuotaType | RuleType | string) => MessageDescriptor | MessageFormatElement[] | string;
  };
  sortField: string;
  onCopy?: (object: any) => string;
  getDescription?: (object: any) => string;
  possibleActions: DesignerObjectsActions[];
}

export interface DesignerOnSubmitOptions {
  refreshPlanId?: string;
}

export enum LibraryContextValues {
  CURRENT_PLAN = 'current',
  ALL_PLANS = 'all',
}
