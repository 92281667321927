import { IconPlus } from '@tabler/icons-react';
import { Fragment, memo, type ReactNode, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { DesignerTokenIcon } from '@amalia/amalia-lang/tokens/components';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import {
  type CreateDatasetOverwriteRequest,
  type DatasetRow,
  type DeleteDatasetOverwriteRequest,
  type Overwrite,
  OverwriteTypesEnum,
  type PatchCustomObjectRequest,
  type Statement,
} from '@amalia/core/types';
import { CustomObjectDefinitionType } from '@amalia/data-capture/record-models/types';
import { CustomObjectsApiClient } from '@amalia/data-capture/records/api-client';
import { ColumnHelper, DataGrid, getPageCount, Table, useSnackbars } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';
import { toError } from '@amalia/ext/typescript';
import {
  clearStatementOverwriteThunkAction,
  createDatasetOverwriteThunkAction,
  STATEMENTS_ACTIONS,
  useThunkDispatch,
} from '@amalia/frontend/web-data-layers';
import { useStatementDetailContext } from '@amalia/lib-ui';
import { StatementDatasetsApiClient } from '@amalia/payout-calculation/statements/state';
import { type Dataset } from '@amalia/payout-calculation/types';
import { usePeriods } from '@amalia/payout-definition/periods/components';
import { type PlanRule } from '@amalia/payout-definition/plans/types';

import { AddRecordsModal } from '../RowsTable/AddRecordModal/AddRecordsModal';
import { type ComputedPlanRuleFieldsToDisplayWithSubTitle } from '../RowsTable/RowsTable';
import { RowsTableUtils } from '../RowsTable/RowsTable.utils';
import { useRowsTableDataFetch } from '../RowsTable/useRowsTableDataFetch';

import {
  DatasetRecordsDataGridActionsCell,
  type DatasetRecordsDataGridActionsCellProps,
} from './cells/actions/DatasetRecordsDataGridActionsCell';
import { DatasetRecordsDataGridDefaultCell } from './cells/default/DatasetRecordsDataGridDefaultCell';
import { DatasetRecordsDataGridStatementCell } from './cells/statement/DatasetRecordsDataGridStatementCell';
import { DatasetRecordsDataGridUserCell } from './cells/user/DatasetRecordsDataGridUserCell';
import { DatasetRecordsDataGridRowIndicators } from './row-indicators/DatasetRecordsDataGridRowIndicators';

const columnHelper = new ColumnHelper<DatasetRow>();

export type DatasetRecordsDataGridProps = {
  readonly dataset: Dataset;
  readonly fields: ComputedPlanRuleFieldsToDisplayWithSubTitle[];
  readonly rule?: PlanRule;
  readonly statement?: Statement;
  readonly isForecasted?: boolean;
  readonly globalSearchValue?: string;
  readonly datasetMenu?: ReactNode;
  readonly onSetCurrentTracingData: DatasetRecordsDataGridActionsCellProps['onSetCurrentTracingData'];
};

const DEFAULT_DATA = [];

export const DatasetRecordsDataGrid = memo(function DatasetRecordsDataGrid({
  dataset,
  fields,
  rule = undefined,
  statement = undefined,
  isForecasted = false,
  globalSearchValue = undefined,
  datasetMenu = undefined,
  onSetCurrentTracingData,
}: DatasetRecordsDataGridProps) {
  const dispatch = useThunkDispatch();
  const { snackError } = useSnackbars();

  const {
    action,
    ruleid: ruleIdFromUrl,
    externalid: externalIdFromUrl,
  } = useParams<{ ruleid: string; action: string; externalid: string }>();

  const { periodsMap } = usePeriods();

  const { launchCalculation, openStatementThreadPanel, statementThreads } = useStatementDetailContext();

  const { isAddRecordModalOpen, setAddRecordModalOpenTrue, setAddRecordModalOpenFalse } = useBoolState(
    false,
    'addRecordModalOpen',
  );

  const customObjectDefinition =
    statement?.results.definitions.customObjects[dataset.customObjectDefinition.machineName];

  const {
    paginationState,
    isLoading,
    applyOverwrite,
    clearOverwrite,
    setAddedRows,
    datagridState: {
      page,
      setPage,
      pageSize,
      setPageSize,
      columnVisibility,
      setColumnVisibility,
      columnSorting,
      setColumnSorting,
      searchText,
      setSearchText,
      columnPinning,
      setColumnPinning,
      columnOrder,
      setColumnOrder,
    },
  } = useRowsTableDataFetch({
    statement,
    dataset,
    fields,
    globalSearchValue,
    forecasted: isForecasted,
  });

  useEffect(() => {
    // Open deal tracing on this deal if externalId and ruleId matches.
    const datasetRow = paginationState?.rows.find((row) => row.externalId === externalIdFromUrl);
    if (ruleIdFromUrl === rule?.id && datasetRow && action === 'tracing') {
      onSetCurrentTracingData({ datasetRow, dataset, fields });
    }
  }, [rule, ruleIdFromUrl, externalIdFromUrl, action, onSetCurrentTracingData, paginationState?.rows, dataset, fields]);

  const handleClearOverwrite = useCallback(
    async (overwrite: Overwrite) => {
      if (!overwrite?.id) {
        return;
      }

      if (overwrite.overwriteType === OverwriteTypesEnum.PROPERTY) {
        await CustomObjectsApiClient.clearCustomObject({
          definitionMachineName: dataset.customObjectDefinition.machineName,
          objectExternalId: overwrite.appliesToExternalId,
          overwriteId: overwrite.id,
        });
      } else {
        await dispatch(clearStatementOverwriteThunkAction(statement.id, overwrite));
      }

      clearOverwrite(overwrite);

      await launchCalculation?.();
    },
    [statement, dataset.customObjectDefinition.machineName, dispatch, clearOverwrite, launchCalculation],
  );

  // Apply overwrites after editing a cell.
  const handleSubmitOverwrite = useCallback(
    async ({ changed }: { changed: unknown }): Promise<void> => {
      if (paginationState.rows && changed) {
        try {
          const rowTableOverwrite = RowsTableUtils.onCommitChanges(dataset, paginationState.rows, statement, {
            changed,
          });

          let overwrite: Overwrite;
          if (rowTableOverwrite.overwriteRequest) {
            if (rowTableOverwrite.overwriteType === OverwriteTypesEnum.FILTER_ROW_REMOVE) {
              await StatementDatasetsApiClient.deleteDatasetRow(
                rowTableOverwrite.statementId,
                dataset.id,
                rowTableOverwrite.objectExternalId,
                rowTableOverwrite.overwriteRequest as DeleteDatasetOverwriteRequest,
              );
            } else if (rowTableOverwrite.statementId) {
              const resp = await dispatch(
                createDatasetOverwriteThunkAction(
                  rowTableOverwrite.statementId,
                  dataset.id,
                  rowTableOverwrite.overwriteRequest as CreateDatasetOverwriteRequest,
                ),
              );
              if (resp.type === STATEMENTS_ACTIONS.CREATE_OVERWRITE) {
                overwrite = resp.payload?.overwrite;
              }
            } else {
              const resp = await CustomObjectsApiClient.patchCustomObject({
                definitionMachineName: rowTableOverwrite.definitionName,
                objectExternalId: rowTableOverwrite.objectExternalId,
                patch: rowTableOverwrite.overwriteRequest as PatchCustomObjectRequest,
              });
              overwrite = resp.createdOverwrite;
            }

            if (overwrite) {
              applyOverwrite(overwrite);
            }

            // Launch Calculation
            await launchCalculation?.();
          }
        } catch (e) {
          snackError(toError(e).message);
        }
      }
    },
    [dataset, paginationState, statement, launchCalculation, dispatch, snackError, applyOverwrite],
  );

  const columns = useMemo(
    () => [
      ...fields.map((field) => {
        // Need to either find the variable definition (in case of a FIELD) in statement.results.definitions.variables[field.name]
        const variableDefinition = statement?.results.definitions.variables[field.name];
        // Or its custom object definition (in case of a PROPERTY) in statement.results.definitions.customObjects[dataset.customObjectDefinition.machineName].
        const propertyDefinition = customObjectDefinition?.properties[field.name];

        const format = variableDefinition?.format || propertyDefinition?.format;
        const tokenType = variableDefinition ? TokenType.FIELD : propertyDefinition ? TokenType.PROPERTY : undefined;

        return columnHelper.display({
          id: field.name,
          header: field.label,
          isSortable: true,
          tooltip: variableDefinition?.description,
          icon: format && tokenType && (
            <DesignerTokenIcon
              propertyRef={propertyDefinition?.ref}
              tokenFormat={format}
              tokenType={tokenType}
            />
          ),
          size: 240,
          cell: ({ row: datasetRow, rowIndex, cellIndex }) => {
            const rowHighlightMarker =
              cellIndex === 0 ? (
                <DatasetRecordsDataGridRowIndicators
                  dataset={dataset}
                  datasetRow={datasetRow}
                  fields={fields}
                  rule={rule}
                  statement={statement}
                  statementThreads={statementThreads}
                />
              ) : null;

            switch (field.name) {
              case 'id': // Show the id.
                return (
                  <Table.Cell.WithActions
                    actions={rowHighlightMarker}
                    hideActions={false}
                  >
                    {datasetRow.content.id as string}
                  </Table.Cell.WithActions>
                );
              case 'periodId': {
                // Show the period label.
                const periodId = datasetRow.content.periodId as string;
                return (
                  <Table.Cell.WithActions
                    actions={rowHighlightMarker}
                    hideActions={false}
                  >
                    {periodsMap[periodId]?.name || periodId}
                  </Table.Cell.WithActions>
                );
              }
              case 'statementId': // Show a link to the statement.
                return (
                  <DatasetRecordsDataGridStatementCell
                    datasetRow={datasetRow}
                    rowHighlightMarker={rowHighlightMarker}
                  />
                );
              case 'userId': // Show the user name and avatar.
                return (
                  <DatasetRecordsDataGridUserCell
                    datasetRow={datasetRow}
                    rowHighlightMarker={rowHighlightMarker}
                  />
                );
              default:
                return (
                  <DatasetRecordsDataGridDefaultCell
                    customObjectDefinition={customObjectDefinition}
                    dataset={dataset}
                    datasetRow={datasetRow}
                    field={field}
                    isForecasted={isForecasted}
                    propertyDefinition={propertyDefinition}
                    rowHighlightMarker={rowHighlightMarker}
                    rowIndex={rowIndex}
                    rule={rule}
                    statement={statement}
                    statementThreads={statementThreads}
                    variableDefinition={variableDefinition}
                    onClearOverwrite={handleClearOverwrite}
                    onOpenStatementThreadPanel={openStatementThreadPanel}
                    onSubmitOverwrite={handleSubmitOverwrite}
                  />
                );
            }
          },
        });
      }),
      columnHelper.display({
        id: '', // Cannot be sure that there won't be a column named "actions" in the fields, but they must have a label so an empty id will be unique.
        header: '',
        size: 0,
        cell: ({ row: datasetRow, rowIndex }) => (
          <DatasetRecordsDataGridActionsCell
            customObjectDefinition={customObjectDefinition}
            dataset={dataset}
            datasetRow={datasetRow}
            fields={fields}
            isForecasted={isForecasted}
            rowIndex={rowIndex}
            rule={rule}
            statement={statement}
            onClearOverwrite={handleClearOverwrite}
            onSetCurrentTracingData={onSetCurrentTracingData}
            onSubmitOverwrite={handleSubmitOverwrite}
          />
        ),
      }),
    ],
    [
      customObjectDefinition,
      dataset,
      fields,
      handleClearOverwrite,
      isForecasted,
      onSetCurrentTracingData,
      handleSubmitOverwrite,
      periodsMap,
      rule,
      statement,
      statementThreads,
      openStatementThreadPanel,
    ],
  );

  const getDatasetRowKey = useCallback((row: DatasetRow) => row.externalId ?? row.id, []);

  return (
    <Fragment>
      <DataGrid
        pinLastColumn
        columns={columns}
        data={paginationState?.rows || DEFAULT_DATA}
        isLoading={isLoading}
        leftHeaderSlot={datasetMenu}
        rowKey={getDatasetRowKey}
        totalItems={paginationState?.totalItems || 0}
        actions={
          !!(
            isForecasted &&
            statement?.plan.isSimulationAddRecordEnabled &&
            customObjectDefinition?.type !== CustomObjectDefinitionType.VIRTUAL
          ) && (
            <DataGrid.Action
              icon={<IconPlus />}
              onClick={setAddRecordModalOpenTrue}
            >
              <FormattedMessage defaultMessage="Add record" />
            </DataGrid.Action>
          )
        }
        columnOrder={
          <DataGrid.ColumnOrder
            columnOrder={columnOrder}
            onChangeColumnOrder={setColumnOrder}
          />
        }
        columnPinning={
          <DataGrid.ColumnPinning
            isActive={columnPinning}
            onChange={setColumnPinning}
          />
        }
        columnSorting={
          <DataGrid.ColumnSorting.Single
            columnSorting={columnSorting}
            onChangeColumnSorting={setColumnSorting}
          />
        }
        columnVisibility={
          <DataGrid.ColumnVisibility
            columnVisibility={columnVisibility}
            onChangeColumnVisibility={setColumnVisibility}
          />
        }
        pageSize={
          <DataGrid.PageSize
            value={pageSize}
            onChange={setPageSize}
          />
        }
        pagination={
          <DataGrid.Pagination
            page={page}
            pageCount={getPageCount(paginationState?.totalItems || 0, pageSize)}
            onChangePage={setPage}
          />
        }
        search={
          <DataGrid.Search
            value={searchText}
            onChange={setSearchText}
          />
        }
      />

      <AddRecordsModal
        dataset={dataset}
        isOpen={isAddRecordModalOpen}
        statementId={statement?.id}
        onClose={setAddRecordModalOpenFalse}
        onSetAddedRows={setAddedRows}
      />
    </Fragment>
  );
});
