import { useCallback, useMemo, useState } from 'react';

const DEFAULT_PAGE_SIZE = 150;

export type UseShowMoreValue<TItem = unknown> = {
  itemsToShow: TItem[];
  hasMore: boolean;
  onShowMore: () => void;
};

export const useShowMore = <TItem = unknown>(items: TItem[], pageSize = DEFAULT_PAGE_SIZE): UseShowMoreValue<TItem> => {
  const [itemsToShowCount, setItemsToShowCount] = useState(pageSize);

  const itemsToShow = useMemo(() => items.slice(0, itemsToShowCount), [items, itemsToShowCount]);
  const handleShowMore = useCallback(() => setItemsToShowCount((prev) => prev + pageSize), [pageSize]);

  return {
    itemsToShow,
    hasMore: itemsToShowCount < items.length,
    onShowMore: handleShowMore,
  };
};
