import { css } from '@emotion/react';
import { type ReactNode, memo } from 'react';

import { Typography } from '@amalia/design-system/components';

export type StatementSummaryLayoutKpisProps = {
  /** Make it look disabled. */
  readonly disabled?: boolean;
  /** Section title. */
  readonly title: ReactNode;
  /** Section period name. */
  readonly period: ReactNode;
  /** Section content (`<StatementKpis />` component). */
  readonly children: ReactNode;
};

export const StatementSummaryLayoutKpis = memo(function StatementSummaryLayoutKpis({
  title,
  period,
  disabled,
  children,
}: StatementSummaryLayoutKpisProps) {
  return (
    <div
      css={css`
        padding: 12px 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
      `}
    >
      <div>
        <Typography
          as="div"
          variant={Typography.Variant.BODY_XSMALL_BOLD}
          css={(theme) => css`
            color: ${disabled ? theme.ds.colors.gray[300] : theme.ds.colors.gray[800]};
          `}
        >
          {title}
        </Typography>

        <Typography
          as="div"
          variant={Typography.Variant.BODY_XSMALL_REGULAR}
          css={(theme) => css`
            color: ${disabled ? theme.ds.colors.gray[300] : theme.ds.colors.gray[700]};
          `}
        >
          {period}
        </Typography>
      </div>

      <div
        css={css`
          display: flex;
        `}
      >
        {children}
      </div>
    </div>
  );
});
