import { css } from '@emotion/react';
import { type Property } from 'csstype';
import { type ElementType, type HTMLProps, type ForwardedRef, forwardRef } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

export type GroupProps = MergeAll<
  [
    Omit<HTMLProps<HTMLElement>, 'ref'>,
    {
      /** Override the default element for the variant. */
      readonly as?: ElementType;
      readonly align?: Property.AlignItems;
      readonly justify?: Property.JustifyContent;
      readonly gap?: Property.Gap<number | string>;
      readonly grow?: Property.FlexGrow | boolean | number;
      readonly wrap?: Property.FlexWrap | boolean;
      readonly reverse?: boolean;
      readonly inline?: boolean;
    },
  ]
>;

export const Group = forwardRef(function Group(
  {
    as: Component = 'div',
    align = undefined,
    justify = undefined,
    gap = undefined,
    grow = undefined,
    wrap = undefined,
    children = undefined,
    inline = false,
    reverse = false,
    ...props
  }: GroupProps,
  ref: ForwardedRef<HTMLElement>,
) {
  const gapStr = typeof gap === 'number' ? `${gap}px` : gap;
  const wrapStr = typeof wrap === 'boolean' ? (wrap ? 'wrap' : 'nowrap') : wrap;
  const growStr = typeof grow === 'boolean' ? (grow ? '1' : '0') : grow;

  return (
    <Component
      {...props}
      ref={ref}
      css={css`
        display: ${inline ? 'inline-flex' : 'flex'};
        flex-direction: ${reverse ? 'row-reverse' : 'row'};
        align-items: ${align};
        justify-content: ${justify};
        gap: ${gapStr};
        flex-grow: ${growStr};
        flex-wrap: ${wrapStr};
      `}
    >
      {children}
    </Component>
  );
});
