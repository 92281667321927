import clsx from 'clsx';
import { type ElementType, memo } from 'react';

import { TypographyVariant } from '@amalia/design-system/meta';

import { TextOverflow } from '../../../../general/text-overflow/TextOverflow';
import { Typography } from '../../../../general/typography/Typography';
import { type SelectOption, SelectSize, type SingleValueLabelProps } from '../../Select.types';

import * as styles from './SingleValue.styles';

const SIZE_TYPOGRAPHY_MAPPING: Record<SelectSize, TypographyVariant> = {
  [SelectSize.SMALL]: TypographyVariant.BODY_BASE_REGULAR,
  [SelectSize.MEDIUM]: TypographyVariant.BODY_LARGE_REGULAR,
};

export type SingleValueProps<TOption extends SelectOption = SelectOption> = {
  /** Selected option. */
  readonly option: TOption;
  /** Select control size. */
  readonly size: SelectSize;
  /** Is the control disabled. */
  readonly disabled?: boolean;
  /** Component to render the label of the selected option inside the control on a single select component. */
  readonly LabelComponent?: ElementType<SingleValueLabelProps<TOption>>;
};

const SingleValueBase = function SingleValue<TOption extends SelectOption = SelectOption>({
  option,
  size,
  disabled,
  LabelComponent,
}: SingleValueProps<TOption>) {
  return (
    <Typography
      as={TextOverflow}
      className={clsx({ [styles.IS_DISABLED_CLASSNAME]: disabled })}
      css={styles.singleValue}
      variant={SIZE_TYPOGRAPHY_MAPPING[size]}
    >
      {LabelComponent ? (
        <LabelComponent
          disabled={disabled}
          option={option}
          size={size}
        />
      ) : (
        option.singleValueLabel || option.valueLabel || option.label
      )}
    </Typography>
  );
};

export const SingleValue = memo(SingleValueBase) as typeof SingleValueBase;
