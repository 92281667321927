import { type PropertyRef, type FormatsEnum } from '@amalia/data-capture/fields/types';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';

export enum OverwriteFormField {
  NEW_VALUE = 'newValue',
  PERCENTAGE = 'percentage',
  IS_APPLY_OVERALL = 'isApplyToOverall',
}

export type OverwriteFormValuesShape = {
  [OverwriteFormField.NEW_VALUE]: string;
  [OverwriteFormField.PERCENTAGE]: number;
  [OverwriteFormField.IS_APPLY_OVERALL]: boolean;
};

export type OverwriteCreationRequestDetails = {
  format: FormatsEnum;
  ref?: PropertyRef;
  oldValue?: boolean | number | string | null;
  currency?: CurrencySymbolsEnum;
  rule?: string;
  field: string;
  fieldName?: string;
  variableName?: string;
  opportunityObject?: string;
  rowNameOrId?: string;
  rowId?: number | string;
  isProperty: boolean;
  machineName?: string;
};

export type FilterOverwriteRemoveCreationRequestDetails = {
  rule: string;
  filterId: string;
  definitionName: string;
  definitionId: string;
  rowNameOrId: string;
  rowExternalId: string;
  rowId: number | string;
  isAdditionalRow?: boolean;
};
