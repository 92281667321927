import { isEqual } from 'lodash';
import { type DependencyList, useRef, useMemo } from 'react';

export const useDeepMemo = <TData>(factory: () => TData, deps: DependencyList): TData => {
  const dependenciesRef = useRef<DependencyList>(deps);

  if (!isEqual(dependenciesRef.current, deps)) {
    dependenciesRef.current = deps;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps -- factory is not a dependency.
  return useMemo(factory, dependenciesRef.current);
};
