import { type ElementType, memo, useCallback } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { type SelectOption, type MultiValueLabelProps } from '../../../Select.types';
import { MultiValue, type MultiValueProps } from '../multi-value/MultiValue';

export type MultiValueOptionProps<TOption extends SelectOption = SelectOption> = MergeAll<
  [
    Omit<MultiValueProps, 'children'>,
    {
      /** Selected option. */
      option: TOption;
      /** Component to render the label of the selected option inside the control on a single select component. */
      LabelComponent?: ElementType<MultiValueLabelProps<TOption>>;
      /** Callback when the width of the container changes. */
      onChangeWidth: (option: TOption, width: number) => void;
    },
  ]
>;

const MultiValueOptionBase = function MultiValueOption<TOption extends SelectOption = SelectOption>({
  option,
  size,
  disabled,
  onChangeWidth,
  LabelComponent,
  ...props
}: MultiValueOptionProps<TOption>) {
  const handleChangeWidth: Required<MultiValueProps>['onChangeWidth'] = useCallback(
    (width) => onChangeWidth(option, width),
    [onChangeWidth, option],
  );

  return (
    <MultiValue
      {...props}
      disabled={disabled}
      size={size}
      onChangeWidth={handleChangeWidth}
    >
      {LabelComponent ? (
        <LabelComponent
          disabled={disabled}
          option={option}
          size={size}
        />
      ) : (
        option.multiValueLabel || option.valueLabel || option.label
      )}
    </MultiValue>
  );
};

export const MultiValueOption = memo(MultiValueOptionBase) as typeof MultiValueOptionBase;
