import { entries, isEmpty, keys, set, values } from 'lodash';

import { type UserContract } from '@amalia/tenants/users/types';

import { type FilterItem, type SelectItem } from './FilterBarItem';

/**
 * Reset filter items: remove all selected values + remove all aditional filters that had been previously added.
 * @param items
 * @param additionalFilters
 */
export const resetFilterItems = (items: Record<string, FilterItem>, additionalFilters: Record<string, FilterItem>) => {
  const newItems = {};

  entries(items).forEach(([key, item]) => {
    if (!additionalFilters?.[key]) {
      set(newItems, key, {
        ...item,
        checked: [],
      });
    }
  });

  return newItems;
};

/**
 * Check if filterBar is in dirty state (modified):
 * some additional filters have been added or some filter values are set.
 * @param items
 * @param additionalFilters
 */
export const isFilterBarInDirtyState = (
  items: Record<string, FilterItem>,
  additionalFilters: Record<string, FilterItem>,
) => {
  const someOptionalFiltersAreSet = keys(items).some((key) => !!additionalFilters?.[key]);

  const someFiltersHaveCheckedValues = values(items).some((item) => !isEmpty((item as SelectItem)?.checked));

  return someOptionalFiltersAreSet || someFiltersHaveCheckedValues;
};

export const updateFilterItems = (
  items: Record<string, FilterItem>,
  filterKey: string,
  selected: string[] | UserContract[] | boolean,
) => {
  const newItems = {};

  entries(items).forEach(([key, i]) => {
    if (key === filterKey) {
      set(newItems, key, {
        ...i,
        checked: Array.isArray(selected) ? selected : undefined,
        value: !Array.isArray(selected) ? selected : undefined,
      });
    } else {
      set(newItems, key, i);
    }
  });

  return newItems;
};
