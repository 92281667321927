import { Children, memo, useState, type JSXElementConstructor, type ReactElement, isValidElement } from 'react';

import { Paper } from '../../layout/paper/Paper';
import { FatTab } from '../fat-tab/FatTab';

import * as styles from './CardWithTabs.styles';
import { type CardWithTabsValue } from './CardWithTabs.types';
import { CardWithTabsTab, type CardWithTabsTabProps } from './tab/CardWithTabsTab';

export type CardWithTabsProps = {
  readonly children: ReactElement<CardWithTabsTabProps, JSXElementConstructor<CardWithTabsTabProps>>[];
  /** Current tab. Defaults to the first tab. */
  readonly defaultTab?: CardWithTabsValue;
};

const CardWithTabsBase = memo(function CardWithTabsBase({ children, defaultTab }: CardWithTabsProps) {
  const filteredChildren = Children.toArray(children).filter(isValidElement<CardWithTabsTabProps>);

  const [selectedTab, setSelectedTab] = useState<CardWithTabsValue | null>(
    defaultTab ?? filteredChildren.at(0)?.props.value ?? null,
  );

  const selectedChild = filteredChildren.find((child) => child.props.value === selectedTab);

  return (
    <Paper css={styles.container}>
      <div css={styles.tabsContainer}>
        {Children.map(filteredChildren, ({ props: { value, children: _children, ...fatTabProps } }) => (
          <FatTab
            {...fatTabProps}
            key={String(value)}
            css={styles.tab}
            isSelected={selectedTab === value}
            onClick={() => setSelectedTab(value)}
          />
        ))}
      </div>

      <div css={styles.tabContent}>{selectedChild?.props.children}</div>
    </Paper>
  );
});

export const CardWithTabs = Object.assign(CardWithTabsBase, {
  Tab: CardWithTabsTab,
});
