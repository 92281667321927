import { useTheme } from '@emotion/react';
import { IconAward } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Badge, Group, Table } from '@amalia/design-system/components';
import { type ChallengeLeaderboard } from '@amalia/payout-definition/plans/types';

export type ChallengeLeaderboardCellPositionProps = {
  readonly position: ChallengeLeaderboard['position'];
};

export const ChallengeLeaderboardCellPosition = memo(function ChallengeLeaderboardCellPosition({
  position,
}: ChallengeLeaderboardCellPositionProps) {
  const theme = useTheme();
  return (
    <Table.Cell.Main>
      {position === 1 ? (
        <Group
          align="center"
          gap={4}
        >
          <IconAward
            color={theme.ds.colors.primary[400]}
            size={16}
          />

          {position}
        </Group>
      ) : (
        (position ?? (
          <Badge
            size={Badge.Size.SMALL}
            variant={Badge.Status.DANGER}
          >
            <FormattedMessage defaultMessage="Non-eligible" />
          </Badge>
        ))
      )}
    </Table.Cell.Main>
  );
});
