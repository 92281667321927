import { createSelector } from 'reselect';

import { type RootState } from '../types';

export const selectCurrentPlan = (state: RootState) => state.plans.currentPlan;

export const selectPlansMap = (state: RootState) => state.plans.map;
export const selectPlansMapIsFullyLoaded = (state: RootState) => state.plans.isMapFullyLoaded;

export const selectPlans = createSelector([selectPlansMap], (plansMap) => Object.values(plansMap));

export const selectPlansSortedByName = createSelector([selectPlans], (plans) =>
  [...plans].sort((a, b) => a.name.localeCompare(b.name)),
);

export const selectPlansIsLoading = (state: RootState) => !!state.plans.isLoading;
