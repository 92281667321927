import { IconEye } from '@tabler/icons-react';
import { difference } from 'lodash';
import { type Dispatch, memo, type SetStateAction, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import {
  IconButton,
  SelectDropdown,
  type SelectDropdownProps,
  type SelectDropdownOption,
} from '@amalia/design-system/components';

import { type ChartSeries } from '../chartSeries';

type SeriesOption = SelectDropdownOption<ChartSeries['name']>;

type SeriesSelectorProps = Readonly<{
  seriesMetadata: Record<string, ChartSeries>;
  hiddenSeries: ChartSeries['name'][];
  setHiddenSeries: Dispatch<SetStateAction<ChartSeries['name'][]>>;
}>;

/**
 * Component to select the series to display in the chart.
 */
export const SeriesSelector = memo(function SeriesSelector({
  seriesMetadata,
  hiddenSeries,
  setHiddenSeries,
}: SeriesSelectorProps) {
  const { formatMessage } = useIntl();

  const seriesList = useMemo(() => Object.values(seriesMetadata), [seriesMetadata]);

  const options: SeriesOption[] = useMemo(
    () =>
      seriesList.map((series) => ({
        value: series.name,
        label: series.label,
      })),
    [seriesList],
  );

  const value = useMemo(
    () => seriesList.filter((series) => !hiddenSeries.includes(series.name)).map((series) => series.name),
    [seriesList, hiddenSeries],
  );

  const handleChange: Required<SelectDropdownProps<SeriesOption, true>>['onChange'] = useCallback(
    (nextValue) =>
      setHiddenSeries(
        difference(
          options.map((option) => option.value),
          nextValue,
        ),
      ),
    [options, setHiddenSeries],
  );

  return seriesList.length > 1 ? (
    <SelectDropdown<SeriesOption, true>
      isMultiple
      options={options}
      value={value}
      onChange={handleChange}
    >
      {({ isDropdownOpen }) => (
        <IconButton
          withBackground
          icon={<IconEye />}
          isActive={isDropdownOpen}
          label={formatMessage({ defaultMessage: 'Choose series' })}
        />
      )}
    </SelectDropdown>
  ) : null;
});
