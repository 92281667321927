import { css, type Theme } from '@emotion/react';

export const connectorCardBody = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 24px;
  align-items: flex-start;
`;

export const textContainer = css`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const connectorName = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[900]};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const connectorCategory = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[700]};
`;

export const logo = css`
  height: 32px;
  width: auto;
`;
