import { noop } from 'lodash';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { type FormulaBuilderFunctionBlockUserOneArgForm } from '@amalia/amalia-lang/formula/form/types';
import { FormulaKeyword } from '@amalia/amalia-lang/formula/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { Switch, type SwitchProps } from '@amalia/design-system/components';

import {
  type UseAttributesOptionsOptions,
  useAttributesOptions,
} from '../../../../hooks/use-attributes-options/useAttributesOptions';
import { useGetFormulaBuilderAttributeLabel } from '../../../../hooks/use-get-formula-builder-attribute-label/useGetFormulaBuilderAttributeLabel';
import { useValueOrAttributeOptions } from '../../../../hooks/use-value-or-attribute-options/useValueOrAttributeOptions';
import { useFormulaBuilderContext } from '../../../formula-builder/FormulaBuilder.context';
import { type FormulaConditionTagProps } from '../../../formula-condition-tag/FormulaConditionTag';
import { PopoverValueOrAttributeSelector } from '../../../popover-value-or-attribute-selector/PopoverValueOrAttributeSelector';
import { useValueOrAttributeHandlers } from '../../../popover-value-or-attribute-selector/use-value-or-attribute-handlers/useValueOrAttributeHandlers';
import * as styles from '../FunctionUserPopover.styles';

export type FunctionUserOneArgPopoverProps = {
  readonly condition: FormulaBuilderFunctionBlockUserOneArgForm;
  readonly onChange?: FormulaConditionTagProps<FormulaBuilderFunctionBlockUserOneArgForm>['onChange'];
};

const USER_ATTRIBUTES_OPTIONS_FILTERS: UseAttributesOptionsOptions = {
  filterProperty: (property) => property.format === FormatsEnum.text,
  filterVariable: (variable) => variable.format === FormatsEnum.text,
  keywords: Object.values(FormulaKeyword),
  showFilteredAsDisabled: false,
};

export const FunctionUserOneArgPopover = memo(function FunctionUserOneArgPopover({
  condition,
  onChange = noop,
}: FunctionUserOneArgPopoverProps) {
  const { customObjectDefinition } = useFormulaBuilderContext();

  const getFormulaBuilderAttributeLabel = useGetFormulaBuilderAttributeLabel();
  const propertyName = getFormulaBuilderAttributeLabel(condition.args[0]);

  const valueOrAttributeOptions = useValueOrAttributeOptions(propertyName, customObjectDefinition?.name);

  const handleChangeCaseSensitive: Required<SwitchProps>['onChange'] = useCallback(
    (caseSensitive: boolean) =>
      onChange({
        ...condition,
        caseSensitive,
      }),
    [onChange, condition],
  );

  const { handleChangeValueOrAttribute, handleChangeManualValue, handleChangeAttributeValue } =
    useValueOrAttributeHandlers({ condition, onChange });

  const attributesOptions = useAttributesOptions(USER_ATTRIBUTES_OPTIONS_FILTERS);

  return (
    <div css={styles.form}>
      <PopoverValueOrAttributeSelector
        arg={condition.args[1]}
        attributesOptions={attributesOptions}
        conditionCategory={condition.category}
        valueOrAttributeOptions={valueOrAttributeOptions}
        onChangeAttributeValue={handleChangeAttributeValue}
        onChangeManualValue={handleChangeManualValue}
        onChangeValueOrAttribute={handleChangeValueOrAttribute}
      />

      <Switch
        checked={condition.caseSensitive}
        label={<FormattedMessage defaultMessage="Case sensitive" />}
        size={Switch.Size.SMALL}
        tooltip={
          <FormattedMessage defaultMessage="Compare strings taking into account capital and non capital letters.{br}For example, 'Amalia' is not equal to 'amalia'." />
        }
        onChange={handleChangeCaseSensitive}
      />
    </div>
  );
});
