import { type Theme, css } from '@emotion/react';

export const breadcrumbsTextItem = (theme: Theme) => css`
  display: flex;
  gap: 6px;
  align-items: center;

  padding: 3.5px 8px;

  border-radius: ${theme.ds.borderRadiuses.round};

  color: ${theme.ds.colors.gray[900]};
  background-color: transparent;
`;
