import { AxiosError } from 'axios';
import { type SetRequired } from 'type-fest';

import { http } from '@amalia/core/http/client';
import { assert } from '@amalia/ext/typescript';
import {
  type InviteUsersRequest,
  isInviteUsersAggregatedError,
  type UpdateUserProfileRequest,
  type UserProfile,
} from '@amalia/tenants/users/profile/types';

export abstract class UserProfileApiClient {
  public static async getAuthorizedProfiles(): Promise<UserProfile[]> {
    const { data } = await http.get<UserProfile[]>('/users/profile');

    return data;
  }

  public static async getUserProfile(userId: UserProfile['id']): Promise<UserProfile> {
    const encodedUserId = encodeURI(userId);
    const { data: profile } = await http.get<UserProfile>(`/users/profile/${encodedUserId}`);

    return profile;
  }

  public static async updateUserInfo(user: UpdateUserProfileRequest): Promise<UserProfile> {
    const { data } = await http.put<UserProfile>('/users/profile', user);

    return data;
  }

  public static async toggleUserDeactivation(userId: UserProfile['id']): Promise<UserProfile> {
    const encodedUserId = encodeURI(userId);
    const { data } = await http.post<UserProfile>(`/users/profile/${encodedUserId}/toggle-deactivation`);

    return data;
  }

  public static async randomizeUserAvatar() {
    const { data: pictureURL } = await http.patch<unknown>('/users/avatar/randomize');

    assert(pictureURL && typeof pictureURL === 'string', 'No avatar URL is returned from the server');

    return pictureURL;
  }

  public static async uploadAvatar(avatarBase64: string) {
    const { data: pictureURL } = await http.patch<unknown>('/users/avatar', { avatarBase64 });

    assert(pictureURL && typeof pictureURL === 'string', 'No avatar URL is returned from the server');

    return pictureURL;
  }

  public static async inviteUser(userId: string) {
    return UserProfileApiClient.inviteUsers([userId]);
  }

  public static async inviteUsers(userIds: string[]) {
    await http.post('/users/profile/invitations', userIds satisfies InviteUsersRequest);
  }

  public static isBulkInvitationError = (
    error: unknown,
  ): error is SetRequired<AxiosError<{ errors: { id: string; error: Error }[]; total: number }>, 'response'> =>
    error instanceof AxiosError && isInviteUsersAggregatedError(error.response?.data);
}
