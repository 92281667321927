export enum IconActionVariant {
  DEFAULT = 'DEFAULT',
  SUCCESS = 'SUCCESS',
  DANGER = 'DANGER',
}

export enum IconActionSize {
  XSMALL = 'XSMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}
