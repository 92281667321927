import { css } from '@emotion/react';
import { useFormikContext } from 'formik';
import { type FocusEventHandler, Fragment, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormLayout, Popover, type PopoverProps } from '@amalia/design-system/components';
import { CopyToClipboard } from '@amalia/design-system/ext';
import { type UserInfo } from '@amalia/tenants/users/profile/types';

import { UserExternalIdSourceFormikSelect } from './external-id/select/UserExternalIdSourceFormikSelect';
import { UserHrisIdSourceFormikSelect } from './hris-id/select/UserHrisIdSourceFormikSelect';
import { IntegrationIdFormikInput } from './integration-id/input/IntegrationIdFormikInput';
import { IntegrationFormGroup } from './IntegrationFormGroup';

export type IntegrationsEditPopoverUser = Pick<
  UserInfo,
  'externalId' | 'externalIdSource' | 'firstName' | 'hrisId' | 'hrisIdSource' | 'lastName'
>;

export type IntegrationsEditPopoverProps = Omit<PopoverProps, 'content'> & {
  readonly onFocusInput?: FocusEventHandler<HTMLInputElement>;
  readonly onBlurInput?: FocusEventHandler<HTMLInputElement>;
};

export const copyToClipboardButton = css`
  align-self: flex-start;
  position: relative;
  top: 22px;
`;

export const IntegrationsEditPopover = memo(function IntegrationsEditPopover({
  children,
  onFocusInput,
  onBlurInput,
  ...props
}: IntegrationsEditPopoverProps) {
  const { values } = useFormikContext<IntegrationsEditPopoverUser>();

  const { firstName, lastName } = values;

  return (
    <Popover
      {...props}
      content={
        <Popover.Layout>
          <Popover.Header>
            <Popover.Title>
              <FormattedMessage
                defaultMessage="Edit {firstName} {lastName}'s IDs"
                values={{
                  firstName,
                  lastName,
                }}
              />
            </Popover.Title>
          </Popover.Header>
          <Popover.Body>
            <FormLayout size={FormLayout.Size.SMALL}>
              <FormLayout.Group>
                <FormLayout.Row data-testid="user-info-table-row-externalIdSource">
                  <IntegrationFormGroup name="externalId">
                    {({ inputRef, focusInput, isDisabled, isRequired, value }) => (
                      <Fragment>
                        <UserExternalIdSourceFormikSelect focusInput={focusInput} />
                        <IntegrationIdFormikInput
                          ref={inputRef}
                          data-testid="user-info-table-input-externalId"
                          disabled={isDisabled}
                          label={<FormattedMessage defaultMessage="External ID" />}
                          name="externalId"
                          required={isRequired}
                          onBlur={onBlurInput}
                          onFocus={onFocusInput}
                        />
                        <CopyToClipboard
                          css={copyToClipboardButton}
                          label={<FormattedMessage defaultMessage="Click to copy external ID" />}
                          value={value}
                        />
                      </Fragment>
                    )}
                  </IntegrationFormGroup>
                </FormLayout.Row>
                <FormLayout.Row data-testid="user-info-table-row-hrisIdSource">
                  <IntegrationFormGroup name="hrisId">
                    {({ inputRef, focusInput, isDisabled, isRequired, value }) => (
                      <Fragment>
                        <UserHrisIdSourceFormikSelect focusInput={focusInput} />
                        <IntegrationIdFormikInput
                          ref={inputRef}
                          data-testid="user-info-table-input-hrisId"
                          disabled={isDisabled}
                          label={<FormattedMessage defaultMessage="HRIS ID" />}
                          name="hrisId"
                          required={isRequired}
                          onBlur={onBlurInput}
                          onFocus={onFocusInput}
                        />
                        <CopyToClipboard
                          css={copyToClipboardButton}
                          label={<FormattedMessage defaultMessage="Click to copy HRIS ID" />}
                          value={value}
                        />
                      </Fragment>
                    )}
                  </IntegrationFormGroup>
                </FormLayout.Row>
              </FormLayout.Group>
            </FormLayout>
          </Popover.Body>
        </Popover.Layout>
      }
    >
      {children}
    </Popover>
  );
});
