import {
  IconCheck,
  IconPlayerPause,
  IconPlayerPlay,
  IconPlayerRecordFilled,
  IconPlayerStop,
  IconX,
} from '@tabler/icons-react';

import { CalculationStatus } from '@amalia/core/types';
import { type TablerIconElement } from '@amalia/design-system/components';

export const iconForCalculationStatus: Record<CalculationStatus, TablerIconElement> = {
  [CalculationStatus.PENDING]: <IconPlayerPause />,
  [CalculationStatus.REFRESH]: <IconPlayerRecordFilled />,
  [CalculationStatus.STARTED]: <IconPlayerPlay />,
  [CalculationStatus.STOPPING]: <IconPlayerStop />,
  [CalculationStatus.STOPPED]: <IconPlayerStop />,
  [CalculationStatus.SUCCESS]: <IconCheck />,
  [CalculationStatus.ERROR]: <IconX />,
  [CalculationStatus.INCOMPLETE]: <IconX />,
};
