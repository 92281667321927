import { css } from '@emotion/react';
import styled from '@emotion/styled';
import moment from 'moment';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Table, Tooltip } from '@amalia/design-system/components';
import { type Period } from '@amalia/payout-definition/periods/types';

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: ${({ theme }) => theme.ds.borderRadiuses.round};
`;

export type ForecastPaymentDateCellProps = {
  readonly period: Period;
  readonly forecastDate: Date;
};

export const ForecastPaymentDateCell = memo(function ForecastPaymentDateCell({
  period,
  forecastDate,
}: ForecastPaymentDateCellProps) {
  const momentForecast = moment.utc(forecastDate);
  const forecastUnixTimestamp = +momentForecast.format('X');

  return (
    <Table.Cell
      css={css`
        display: flex;
        align-items: center;
        gap: 8px;
      `}
    >
      {momentForecast.format('YYYY-MM-DD')}

      {forecastUnixTimestamp > period.endDate ? (
        <Tooltip content={<FormattedMessage defaultMessage="Forecasted date is after the selected period" />}>
          <Dot
            css={(theme) => css`
              background-color: ${theme.ds.colors.success[500]};
            `}
          />
        </Tooltip>
      ) : forecastUnixTimestamp >= period.startDate && forecastUnixTimestamp < period.endDate ? (
        <Tooltip content={<FormattedMessage defaultMessage="Forecasted date is in the selected period" />}>
          <Dot
            css={(theme) => css`
              background-color: ${theme.ds.colors.warning[500]};
            `}
          />
        </Tooltip>
      ) : (
        <Tooltip content={<FormattedMessage defaultMessage="Forecasted date is before the selected period" />}>
          <Dot
            css={(theme) => css`
              background-color: ${theme.ds.colors.danger[500]};
            `}
          />
        </Tooltip>
      )}
    </Table.Cell>
  );
});
