import { IconReload } from '@tabler/icons-react';
import moment from 'moment/moment';
import { memo, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { fromNow } from '@amalia/core/types';
import { Badge, Button, IconLoading, Tooltip } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';
import { useStatementDetailContext } from '@amalia/lib-ui';
import { CalculationAnalyzeModal } from '@amalia/payout-calculation/statements/components';

import {
  type StatementDetailHeaderActionsProps,
  useStatementDetailHeaderActions,
} from './useStatementDetailHeaderActions';

export const StatementDetailHeaderActionsCompute = memo(function StatementDetailHeaderActionsCompute(
  props: StatementDetailHeaderActionsProps,
) {
  const {
    statement,
    isWorkflowBegun,
    isCalculationRunning,
    isForecastedView,
    launchCalculation,
    calculationProgress,
    stopCalculation,
  } = useStatementDetailHeaderActions(props);

  const { calculationRequest } = useStatementDetailContext();

  const { isCalculationAnalyzeModalOpen, setCalculationAnalyzeModalOpenTrue, setCalculationAnalyzeModalOpenFalse } =
    useBoolState(false, 'CalculationAnalyzeModalOpen');

  // Cannot compute if workflow has started, but can compute in forecast view.
  const isDisabled = isWorkflowBegun && !isForecastedView;

  if (!isCalculationRunning) {
    return (
      <Fragment>
        <Tooltip
          content={
            <FormattedMessage
              defaultMessage="Last on {day} at {hour}"
              values={{
                day: moment(statement.lastCalculationDate).format('YYYY-MM-DD'),
                hour: moment(statement.lastCalculationDate).format('HH:mm A'),
              }}
            />
          }
        >
          <Button
            disabled={isDisabled}
            icon={<IconReload />}
            id="newCalculation"
            variant={Button.Variant.LIGHT}
            badge={
              <Badge variant={Badge.Hue.BLUE}>
                <FormattedMessage
                  defaultMessage="Last computed {time}"
                  values={{ time: fromNow(statement.lastCalculationDate) }}
                />
              </Badge>
            }
            onClick={setCalculationAnalyzeModalOpenTrue}
          >
            <FormattedMessage defaultMessage="Compute" />
          </Button>
        </Tooltip>
        <CalculationAnalyzeModal
          calculationRequest={calculationRequest}
          closeModal={setCalculationAnalyzeModalOpenFalse}
          isOpen={isCalculationAnalyzeModalOpen}
          launchCalculation={launchCalculation}
        />
      </Fragment>
    );
  }

  return (
    <Button
      badge={calculationProgress ? <Badge variant={Badge.Hue.BLUE}>{calculationProgress}</Badge> : undefined}
      disabled={isDisabled}
      icon={<IconLoading icon={IconReload} />}
      id="stopCalculation"
      variant={Button.Variant.LIGHT}
      onClick={stopCalculation}
    >
      <FormattedMessage defaultMessage="Stop calculation" />
    </Button>
  );
});
