import { type UIEventHandler, useCallback, useState } from 'react';

import { type UseResizeObserverOptions, useResizeObserver } from '@amalia/ext/react/hooks';

/**
 * Since there is no way in css to know whether a sticky element is above its siblings, we need to use JS to know if the table is fully scrolled to the left or to the right.
 * This way we can add the sticky cells z-index only when they are above the other cells (aka when the scroll is not fully to the left or right).
 * We need to do this to prevent overlapping the focus outline of an eventual interactive next cell (CellTextField, CellSelect, CellDatePicker).
 */
export const useTableScroll = () => {
  const [isFullyScrolledToTheLeft, setIsFullyScrolledToTheLeft] = useState(false);
  const [isFullyScrolledToTheRight, setIsFullyScrolledToTheRight] = useState(false);

  const handleUpdateFullyScrolled = useCallback((element: HTMLDivElement) => {
    setIsFullyScrolledToTheRight(element.scrollLeft >= element.scrollWidth - element.clientWidth);
    setIsFullyScrolledToTheLeft(element.scrollLeft === 0);
  }, []);

  const handleScroll: UIEventHandler<HTMLDivElement> = useCallback(
    (event) => handleUpdateFullyScrolled(event.currentTarget),
    [handleUpdateFullyScrolled],
  );

  const handleResize: UseResizeObserverOptions<HTMLDivElement>['onResize'] = useCallback(
    (_, element) => handleUpdateFullyScrolled(element),
    [handleUpdateFullyScrolled],
  );

  const containerRef = useResizeObserver({ onResize: handleResize });

  return {
    containerRef,
    onContainerScroll: handleScroll,
    isFullyScrolledToTheLeft,
    isFullyScrolledToTheRight,
  };
};
