import { useCallback, useState } from 'react';

/**
 * By default, recharts' yAxis has a fixed width. This hook gets the width of the yAxis after mount.
 * If the ticks change after mount this will not probably no longer work.
 */
export const useYAxisWidth = () => {
  const [width, setWidth] = useState<number | undefined>(undefined);

  const barChartRef = useCallback((node: { container?: HTMLElement } | null) => {
    const yAxis = node?.container?.querySelector('.recharts-cartesian-axis.recharts-yAxis');

    setWidth(yAxis ? Math.ceil(yAxis.getBoundingClientRect().width) : undefined);
  }, []);

  return { width, chartRef: barChartRef };
};
