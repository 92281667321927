import { type EditorEvents, type Editor } from '@tiptap/react';
import { useEffect, useState, memo } from 'react';

import { type AmaliaFormula } from '@amalia/amalia-lang/formula/types';
import { useDebouncedOnChange, useMountEffect } from '@amalia/ext/react/hooks';
import { type MergeAll } from '@amalia/ext/typescript';

import { getFormulaEditorTextContent } from '../../hooks/use-formula-editor-content/useFormulaEditorContent';
import { FormulaEditorContent, type FormulaEditorContentProps } from '../formula-editor-content/FormulaEditorContent';

export type FormulaEditorFieldProps = MergeAll<
  [
    FormulaEditorContentProps,
    {
      /** Make editor mandatory. */
      editor: Editor;
      /** Current value. */
      value?: AmaliaFormula;
      /** Change handler. */
      onChange?: (formula: AmaliaFormula) => void;
    },
  ]
>;

export const FormulaEditorField = memo(function FormulaEditorField({
  editor,
  onChange,
  value = '',
  ...props
}: FormulaEditorFieldProps) {
  const [formula, setFormula] = useState(value);

  // Debounce the onChange for perf.
  // This is not sanitized because it makes the editor blink, you need to do it yourself (but not on the value itself).
  useDebouncedOnChange(formula, (formula) => onChange?.(formula));

  useMountEffect(() => {
    editor.commands.setContent(value);
  });

  // Add update handlers to the editor.
  useEffect(() => {
    const handleUpdate = ({ transaction }: EditorEvents['update']) => {
      setFormula(getFormulaEditorTextContent(transaction));
    };

    editor.on('update', handleUpdate);

    return () => {
      editor.off('update', handleUpdate);
    };
  }, [editor]);

  return (
    <FormulaEditorContent
      {...props}
      editor={editor}
    />
  );
});
