import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';

import { CalculationParser, type ParserScope } from '../../../CalculationParser';
import { SanitizeFormula } from '../../../sanitizeFormula';
import AmaliaFunction from '../../AmaliaFunction';
import { getValueOrFormula } from '../../utils';

const func = new AmaliaFunction(AmaliaFunctionKeys.SUM, AmaliaFunctionCategory.MISC);

func.nbParamsRequired = 2;

func.description = 'Sum a parameter based on a filtered dataset';

func.params = [
  {
    name: 'dataset',
    description: 'Filters or Links',
    validTokenTypes: [TokenType.FILTER, TokenType.LINK],
    validFormats: [FormatsEnum.table],
  },
  {
    name: 'parameter',
    description: 'Variables, fields or properties to sum.',
    validTokenTypes: [
      TokenType.VARIABLE,
      TokenType.FIELD,
      TokenType.PROPERTY,
      TokenType.FUNCTION,
      TokenType.VIRTUAL_PROPERTY,
    ],
    validTokenValues: {
      [TokenType.FUNCTION]: [AmaliaFunctionKeys.IF, AmaliaFunctionKeys.DEFAULT],
    },
  },
];

func.examples = [
  {
    desc: 'Returns the summed amount of filtered opportunities.',
    formula: 'SUM(filter.closedInQuarter, opportunity.amount)' as AmaliaFormula,
  },
  {
    desc: 'Returns the summed amount of filtered opportunity line items based on its link with the opportunity.',
    formula: 'SUM(opportunity.oppToLineItemsLink, opportunity.oppToLineItemsLink.amount)' as AmaliaFormula,
  },
  {
    desc: 'Returns the summed amount of filtered opportunities when the type is not renewal.',
    formula: 'SUM(filter.closedInQuarter, IF(opportunity.type="Renewal", 0, opportunity.amount)' as AmaliaFormula,
  },
];

func.generateComputedFunctionResult = (args) => ({
  array: getValueOrFormula(args[0]),
  formula: SanitizeFormula.amaliaFormulaToMathJs(getValueOrFormula(args[1])) as AmaliaFormula,
});

func.parametersToEscapeOnParse = [0, 1];

func.execMock = (): number => 1;

func.execRawArgs = (args: any[], _, scope: ParserScope) =>
  CalculationParser.getFunctionResult(args, scope, AmaliaFunctionKeys.SUM);

export default func;
