import { css } from '@emotion/react';
import { type ReactNode, memo, Children } from 'react';

import { Typography } from '../../../general/typography/Typography';

export type MenuGroupProps = {
  readonly title?: ReactNode;
  readonly children?: ReactNode;
};

export const MenuGroup = memo(function MenuGroup({ title, children }: MenuGroupProps) {
  return Children.toArray(children).filter(Boolean).length ? (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 6px;
      `}
    >
      {!!title && (
        <Typography
          variant={Typography.Variant.BODY_SMALL_MEDIUM}
          css={(theme) => css`
            color: ${theme.ds.colors.gray[700]};
            padding: 0 6px;
          `}
        >
          {title}
        </Typography>
      )}

      {!!children && (
        <ul
          css={css`
            display: flex;
            flex-direction: column;
            list-style-type: none;
            gap: 1px;
          `}
        >
          {children}
        </ul>
      )}
    </div>
  ) : null;
});
