import { isNil } from 'lodash';
import { useMemo } from 'react';

import { type SelectDropdownOption } from '../SelectDropdown.types';

import { type UseStateWithOptionsValue } from './useStateWithOptions';

export type UseCheckedOptionsValuesOptions<
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = undefined,
> = {
  /** Wheter the select is multiple. */
  isMultiple?: TIsMultiple;
  /** Value of the select. Depends on isMultiple. */
  value: UseStateWithOptionsValue<TOption, TIsMultiple, TIsClearable>['value'];
};

/**
 * Return the values of checked options.
 *
 * @returns Values of checked options.
 */
export const useCheckedOptionsValues = <
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = undefined,
>({
  isMultiple,
  value,
}: UseCheckedOptionsValuesOptions<TOption, TIsMultiple, TIsClearable>): TOption['value'][] =>
  useMemo(
    () =>
      isMultiple
        ? ((value ?? []) as TOption[]).map((option) => option.value)
        : !isNil(value)
          ? [(value as TOption).value]
          : [],
    [isMultiple, value],
  );
