import { useCallback } from 'react';

/**
 * Adapted from https://stackoverflow.com/questions/45046030/maintaining-href-open-in-new-tab-with-an-onclick-handler-in-react
 */
export const useOpenInNewTab = () =>
  useCallback((url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  }, []);
