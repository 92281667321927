import { css, type Theme } from '@emotion/react';

export const IS_ACTIVE_CLASSNAME = 'active';
export const IS_DISABLED_CLASSNAME = 'disabled';
export const IS_INTERACTIVE_CLASSNAME = 'isInteractive';
export const HAS_HANDLE_CLASSNAME = 'hasHandle';

export const dropdownItemContent = (theme: Theme) => css`
  display: flex;
  align-items: center;

  max-width: 100%;
  gap: 8px;

  padding: 2px 12px;

  min-height: 32px;
  box-sizing: border-box;

  color: ${theme.ds.colors.gray[900]};
  border-radius: ${theme.ds.borderRadiuses.squared};

  transition: ${theme.ds.transitions.default('background-color', 'color')};

  &.${HAS_HANDLE_CLASSNAME} {
    padding-left: 8px;
  }

  &.${IS_DISABLED_CLASSNAME} {
    cursor: not-allowed;
    color: ${theme.ds.colors.gray[300]};
  }

  &:not(.${IS_DISABLED_CLASSNAME}).${IS_INTERACTIVE_CLASSNAME} {
    cursor: pointer;

    &:hover {
      background-color: ${theme.ds.colors.primary[50]};
    }

    &.${IS_ACTIVE_CLASSNAME} {
      color: ${theme.ds.colors.primary[500]};
      background-color: ${theme.ds.colors.primary[50]};
    }
  }
`;
