import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { getSupportedPlanFrequencies } from '@amalia/core/types';
import { useSnackbars } from '@amalia/design-system/components';
import { FormikForm, type FormikFormProps } from '@amalia/ext/formik';
import { toError, type MergeAll } from '@amalia/ext/typescript';
import { log } from '@amalia/kernel/logger/client';
import { PlansApiClient } from '@amalia/payout-definition/api-client';
import { type PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';
import { type Plan } from '@amalia/payout-definition/plans/types';
import { useCurrentCompany } from '@amalia/tenants/companies/state';

type CreatePlanFormValues = {
  name: string;
  frequency: PeriodFrequencyEnum;
};

type CreatePlanFormikProps = FormikFormProps<CreatePlanFormValues, Plan>;

export type CreatePlanFormProps = MergeAll<
  [
    Omit<CreatePlanFormikProps, 'initialValues' | 'onSubmit'>,
    {
      duplicateOf?: {
        id: string;
        name: string;
      };
    },
  ]
>;

export const CreatePlanForm = memo(function CreatePlanForm({ duplicateOf, ...props }: CreatePlanFormProps) {
  const { snackSuccess, snackError } = useSnackbars();
  const { data: company } = useCurrentCompany();

  const defaultFrequency = getSupportedPlanFrequencies(company)[0];

  const initialValues: CreatePlanFormValues = useMemo(
    () => ({
      name: duplicateOf?.name || '',
      frequency: defaultFrequency,
    }),
    [duplicateOf, defaultFrequency],
  );

  const handleSubmit: Required<CreatePlanFormikProps>['onSubmit'] = useCallback(
    async (values: CreatePlanFormValues) => {
      try {
        const newPlan = await PlansApiClient.create(values);

        snackSuccess(<FormattedMessage defaultMessage="Plan created!" />);
        return newPlan;
      } catch (err) {
        log.info(<FormattedMessage defaultMessage="An error occurred during the creation of the plan" />);
        snackError(toError(err).message);
        throw err;
      }
    },
    [snackSuccess, snackError],
  );

  return (
    <FormikForm
      {...props}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    />
  );
});
