import { css } from '@emotion/react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { isEmpty, sortBy } from 'lodash';
import { memo, useCallback, useMemo } from 'react';

import { formatUserFullName, type Option } from '@amalia/core/types';
import { Checkbox } from '@amalia/design-system/components';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';
import { SelectFieldBase } from '@amalia/lib-ui';
import { type PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';
import { type UserContract } from '@amalia/tenants/users/types';

import { PeriodSelector } from '../../statement/period-selector/PeriodSelector';

import { FilterBarSelectExpandIcon } from './FilterBarSelectExpandIcon';
import { FilterBarUserSelect } from './FilterBarUserSelect';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  dropdown: {
    backgroundColor: theme.palette.grey['100'],
    color: `${theme.palette.grey['700']}`,
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.grey['200'],
      color: `${theme.palette.grey['800']}`,
    },
    padding: '4px 12px',
    minWidth: 150,
  },
  dropdownOnlyOneItem: {
    padding: '7px 12px',
  },
  dropdownActive: {
    '&:hover': {
      backgroundColor: theme.palette.grey['300'],
    },
  },
}));

export interface SelectItem {
  label: string;
  isUserFilter?: boolean;
  name?: string;
  elements: Option[] | undefined;
  giveFocusSearchOnOpen?: boolean;
  checked: string[];
  disabled?: boolean;
}

export interface SelectUserItem {
  label: string;
  name?: string;
  users: UserContract[] | undefined;
  giveFocusSearchOnOpen?: boolean;
  checked: UserContract[];
  disabled?: boolean;
}

export interface CheckboxItem {
  label?: string;
  value?: boolean;
  onChange?: (checked: boolean) => void;
  color?: 'default' | 'primary' | 'secondary';
  name: string;
}

export interface PeriodSelectorItem {
  frequency: PeriodFrequencyEnum;
  startDate: number;
  language: string;
  onPeriodChange: (dateString: string) => void;
  label?: string;
}

export type FilterItem = CheckboxItem | PeriodSelectorItem | SelectItem | SelectUserItem;

interface FilterBarItemProps {
  readonly item: FilterItem;
  readonly filterKey: string;
  readonly onChange: (key: string, selected: string[] | UserContract[] | boolean) => void;
}

export const FilterBarItem = memo(function FilterBarItem({ item, filterKey, onChange }: FilterBarItemProps) {
  const classes = useStyles();

  const onChangeHandler = useCallback(
    (selected: string[] | boolean) => {
      onChange(filterKey, selected);
    },
    [filterKey, onChange],
  );

  const onChangeUserHandler = useCallback(
    (selected: UserContract[]) => {
      onChange(filterKey, selected);
    },
    [filterKey, onChange],
  );

  const filterElements = useMemo(() => [...sortBy((item as SelectItem).elements || [], 'label')], [item]);

  switch (true) {
    case !!(item as SelectUserItem).users && (item as SelectUserItem).users.length === 1:
      return (
        <span className={clsx(classes.dropdown, classes.dropdownOnlyOneItem)}>
          {formatUserFullName((item as SelectUserItem).users[0])}
        </span>
      );
    case !!(item as SelectUserItem).users:
      return (
        <FilterBarUserSelect
          multiple
          options={{ hideSelectAll: true }}
          title={(item as SelectUserItem).label}
          users={(item as SelectUserItem).users}
          value={(item as SelectUserItem).checked}
          onChange={onChangeUserHandler}
        />
      );
    case !!(item as SelectItem).elements && (item as SelectItem).elements.length === 1:
      return (
        <span className={clsx(classes.dropdown, classes.dropdownOnlyOneItem)}>
          {(item as SelectItem).elements[0].label}
        </span>
      );
    case !!(item as SelectItem).elements:
      return (
        <SelectFieldBase
          hasResetOption
          isFilter
          isSearchable
          multiple
          renderOnlyLengthValue
          searchWithAutocompletion
          IconComponent={FilterBarSelectExpandIcon}
          id={filterKey}
          labelId={filterKey}
          options={filterElements}
          placeholder={(item as SelectUserItem).label}
          title={(item as SelectUserItem).label}
          value={(item as SelectItem).checked}
          className={clsx(
            classes.dropdown,
            !isEmpty((item as SelectItem).checked) ? classes.dropdownActive : undefined,
          )}
          onChange={(e) => onChangeHandler(e.target.value as string[])}
        />
      );
    case !!(item as PeriodSelectorItem).startDate: {
      const { frequency, startDate, onPeriodChange } = item as PeriodSelectorItem;
      return (
        <div
          css={css`
            min-width: 200px;
          `}
        >
          <PeriodSelector
            frequency={frequency}
            timestamp={startDate}
            onPeriodChange={onPeriodChange}
          />
        </div>
      );
    }
    default:
      return (
        <Checkbox
          checked={(item as CheckboxItem)?.value}
          color={(item as CheckboxItem)?.color}
          label={(item as CheckboxItem)?.label}
          name={(item as CheckboxItem)?.name}
          onChange={(checked) => {
            onChangeHandler(checked);
            (item as CheckboxItem).onChange?.(checked);
          }}
        />
      );
  }
});
