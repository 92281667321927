import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { RadioButtonGroup, type RadioButtonOptionShape } from '@amalia/design-system/components';

type OptionsProps = {
  readonly isApplyToOverall: boolean;
  readonly setIsApplyToOverall: (field: string, value: boolean) => void;
};

type ApplyToValue = 'global' | 'this';

const options: RadioButtonOptionShape<ApplyToValue>[] = [
  {
    label: <FormattedMessage defaultMessage="Apply to this statement only" />,
    value: 'this',
  },
  {
    label: <FormattedMessage defaultMessage="Apply to all statements" />,
    value: 'global',
  },
];

export const OverwriteOptions = memo(function OverwriteOptions({
  isApplyToOverall,
  setIsApplyToOverall,
}: OptionsProps) {
  const handleChange = useCallback(
    (value: ApplyToValue) => setIsApplyToOverall('isApplyToOverall', value === 'global'),
    [setIsApplyToOverall],
  );

  return (
    <RadioButtonGroup<ApplyToValue>
      id="isApplyToOverall"
      name="isApplyToOverall"
      options={options}
      value={isApplyToOverall ? 'global' : 'this'}
      onChange={handleChange}
    />
  );
});
