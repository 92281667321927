import { useQuery } from '@tanstack/react-query';

import { CustomObjectsApiClient } from '@amalia/data-capture/records/api-client';

const SEARCH_RECORDS_QUERY_KEY = 'SEARCH_RECORDS';

export const useSearchRecords = (definitionId: string, searchText: string) =>
  useQuery({
    queryKey: [SEARCH_RECORDS_QUERY_KEY, definitionId, searchText],
    queryFn: () => CustomObjectsApiClient.quickSearch(definitionId, searchText),
    enabled: !!definitionId && !!searchText,
  });
