import { memo } from 'react';
import { type SetOptional } from 'type-fest';

import { Typography, type TypographyProps } from '@amalia/design-system/components';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';

import * as styles from './CurrencySymbol.styles';

export type CurrencySymbolProps = SetOptional<TypographyProps, 'variant'> & {
  readonly currencySymbol: CurrencySymbolsEnum;
};

const CurrencySymbolBase = memo(function CurrencySymbol({
  currencySymbol,
  variant = Typography.Variant.BODY_SMALL_REGULAR,
  ...props
}: CurrencySymbolProps) {
  return (
    <Typography
      {...props}
      css={styles.currencySymbol}
      variant={variant}
    >
      {currencySymbol}
    </Typography>
  );
});

export const CurrencySymbol = Object.assign(CurrencySymbolBase, {
  Variant: Typography.Variant,
});
