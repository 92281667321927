import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';

import { CustomReportsApiClient } from '../api-client/custom-reports.api-client';

import { CUSTOM_REPORTS_KEYS } from './custom-reports.keys';

export const useCustomReportsFolders = () =>
  useQuery({
    queryKey: [CUSTOM_REPORTS_KEYS.CUSTOM_REPORTS_FOLDERS],
    queryFn: () => CustomReportsApiClient.getCustomReportsFolders(),
  });

export const useCreateCustomReportsFolder = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();

  const { snackInfo, snackError } = useSnackbars();

  return useMutation({
    mutationKey: [CUSTOM_REPORTS_KEYS.CUSTOM_REPORTS_FOLDERS, 'create'],
    mutationFn: CustomReportsApiClient.createCustomReportFolder,
    onSuccess: async (newFolder) => {
      await queryClient.invalidateQueries({ queryKey: [CUSTOM_REPORTS_KEYS.CUSTOM_REPORTS_FOLDERS] });
      snackInfo(formatMessage({ defaultMessage: 'Folder “{folderName}” created!' }, { folderName: newFolder.name }));
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const usePatchCustomReportsFolder = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();

  const { snackInfo, snackError } = useSnackbars();

  return useMutation({
    mutationKey: [CUSTOM_REPORTS_KEYS.CUSTOM_REPORTS_FOLDERS, 'patch'],
    mutationFn: CustomReportsApiClient.patchCustomReportFolder,
    onSuccess: async (folder) => {
      await queryClient.invalidateQueries({ queryKey: [CUSTOM_REPORTS_KEYS.CUSTOM_REPORTS_FOLDERS] });
      snackInfo(formatMessage({ defaultMessage: 'Folder “{folderName}” edited!' }, { folderName: folder.name }));
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useDeleteCustomReportsFolder = () => {
  const queryClient = useQueryClient();

  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: [CUSTOM_REPORTS_KEYS.CUSTOM_REPORTS_FOLDERS, 'delete'],
    mutationFn: CustomReportsApiClient.deleteFolder,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        // Invalidating the custom report list is required because the deletion of a folder moves
        // all reports that were in this folder at root.
        queryKey: [CUSTOM_REPORTS_KEYS.CUSTOM_REPORTS],
      });
      await queryClient.invalidateQueries({
        // Invalidating the custom report list is required because the deletion of a folder moves
        // all reports that were in this folder at root.
        queryKey: [CUSTOM_REPORTS_KEYS.CUSTOM_REPORTS_FOLDERS],
      });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};
