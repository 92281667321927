import { type ForwardedRef, type MutableRefObject, useEffect, useRef } from 'react';

/**
 * Use this hook if you need to use the forwarded ref in a component that forwards its ref.
 *
 * @example
 * const MyComponent = forwardRef((props, ref) => {
 *   const actualRef = useForwardedRef(ref);
 *
 *   useEffect(
 *     () => console.log(actualRef.getBoundingClientRect()),
 *   );
 *
 *   return (
 *     <div ref={actualRef} />
 *   );
 * });
 *
 * @param ref - Reference.
 * @returns Reference.
 */
export const useForwardedRef = <TRef = undefined>(ref?: ForwardedRef<TRef | null>): MutableRefObject<TRef | null> => {
  const innerRef = useRef<TRef>(null);

  useEffect(() => {
    if (!ref) return;

    if (typeof ref === 'function') {
      ref(innerRef.current || null);
    } else {
      ref.current = innerRef.current;
    }
  });

  return innerRef;
};
