import { type AmaliaFormula } from '@amalia/amalia-lang/formula/types';
import { type Statement } from '@amalia/core/types';
import { getAllFieldsInContext, getFieldsToDisplay } from '@amalia/frontend/web-data-layers';
import { type FilterDataset, type MetricsDataset, type QuotaDataset } from '@amalia/payout-calculation/types';
import {
  type ComputedPlanRuleFieldsToDisplay,
  type PlanRuleFieldToDisplay,
} from '@amalia/payout-definition/plans/types';

/**
 * Select dataset fields based on statement results and fields to display.
 * @param statement
 * @param fieldsToDisplay
 * @param formula
 * @param datasetToDisplay
 */
export const listDatasetFields = (
  statement: Statement,
  fieldsToDisplay: PlanRuleFieldToDisplay[],
  datasetToDisplay?: FilterDataset | MetricsDataset | QuotaDataset,
  formula?: AmaliaFormula,
) => {
  if (!datasetToDisplay) {
    return [];
  }

  if (fieldsToDisplay) {
    const allFieldsInContext = getAllFieldsInContext(statement.results, datasetToDisplay);

    return (
      fieldsToDisplay
        .map((fieldToDisplay): ComputedPlanRuleFieldsToDisplay => {
          const fieldInContext = allFieldsInContext.find((f) => !!f && f.name === fieldToDisplay.name);
          return fieldInContext
            ? {
                ...fieldInContext,
                displayStatus: fieldToDisplay.displayStatus,
              }
            : null;
        })
        // Removing falsy values (aka fields in configuration not found in current context).
        .filter(Boolean)
    );
  }
  return getFieldsToDisplay(statement.results, datasetToDisplay, formula);
};
