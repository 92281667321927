import styled from '@emotion/styled';

export const DropdownItemLabel = styled.span`
  ${({ theme }) => theme.ds.typographies.bodyBaseRegular};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  flex: 1;
  min-width: 50px;

  &[data-active='true'] {
    ${({ theme }) => theme.ds.typographies.bodyBaseMedium};
  }
`;
