import { SearchPanel, Table, TableGroupRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { type TableProps } from '@mui/material';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import { type ComponentProps, memo, type ReactNode } from 'react';

import { Typography } from '@amalia/design-system/components';

import { useDatagridStyles } from '../datagridStyles';

// SEARCH
export const DatagridSearchInput = function DatagridSearchInput({
  ...restProps
}: ComponentProps<typeof SearchPanel.Input>) {
  return (
    <SearchPanel.Input
      {...restProps}
      className="datagrid-search-input"
    />
  );
};

// CELLS
export const TableHeaderCell = memo(function TableHeaderCell({
  column,
  children,
  ...restProps
}: TableHeaderRow.CellProps) {
  const classes = useDatagridStyles();
  return (
    <TableHeaderRow.Cell
      column={column}
      {...restProps}
      className={clsx((restProps as any).className, classes.header)}
    >
      <Typography variant={Typography.Variant.BODY_SMALL_MEDIUM}>{children}</Typography>
    </TableHeaderRow.Cell>
  );
});

export const TableGroupCell = memo(function TableGroupCell({
  column,
  children,
  ...restProps
}: TableGroupRow.CellProps & { readonly children: ReactNode }) {
  const classes = useDatagridStyles();
  return (
    <TableGroupRow.Cell
      column={column}
      {...restProps}
      className={classes.header}
    >
      {children}
    </TableGroupRow.Cell>
  );
});

// HEADER
export const TableHeaderContent = memo(function TableHeaderContent(props: TableHeaderRow.ContentProps) {
  const classes = useDatagridStyles();

  // @ts-expect-error -- Not in datagrid types but we can add it into columns definitions.
  const subTitle = props.column.subTitle;

  return (
    <TableHeaderRow.Content
      {...props}
      className={classes.headerCellContent}
    >
      {props.children}
      {subTitle ? <div className={classes.subTitle}>{subTitle}</div> : null}
    </TableHeaderRow.Content>
  );
});

// TABLES
export const TableComponentBase = memo(function TableComponentBase({ classes, ...restProps }: TableProps) {
  const ownClasses = useDatagridStyles();
  return (
    <Table.Table
      {...restProps}
      className={clsx(ownClasses.root, classes?.root, 'datagrid')}
    />
  );
});

// DataGrid on fullWidth
export const InitialWidthTableComponent = withStyles({
  root: {
    width: 'initial',
    minWidth: '100% !important',
  },
})(TableComponentBase);
