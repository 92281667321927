import { makeStyles } from '@mui/styles';
import { lightenDarkenColor } from 'react-papaparse';

import { amaliaTheme, type AmaliaThemeType } from '@amalia/ext/mui/theme';

export const DEFAULT_REMOVE_HOVER_COLOR = amaliaTheme.palette.error.main;

export const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40);

export const useBulkCsvModalStyles = makeStyles((theme: AmaliaThemeType) => ({
  blocGoogleSheetTemplate: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(100),
    height: theme.spacing(25),
    border: `1px dashed ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
    borderRadius: '4px',
  },
  boxForm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& $form': {
      width: theme.spacing(100),
      border: `1px dashed ${theme.palette.divider}`,
      marginBottom: theme.spacing(2),
      borderRadius: '4px',
    },
  },
  zone: {
    alignItems: 'center',
    border: `2px dashed ${theme.palette.grey['200']}`,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    height: theme.spacing(25),
    width: theme.spacing(100),
    justifyContent: 'center',
    padding: 20,
    cursor: 'pointer',
  },
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: '4px',
    display: 'flex',
    height: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: `${theme.palette.grey['200']}`,
    borderRadius: '4px',
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  },
  name: {
    backgroundColor: `${theme.palette.grey['200']}`,
    borderRadius: '4px',
    marginBottom: '0.5em',
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: `${theme.palette.grey['200']}`,
  },
  default: {
    borderColor: `${theme.palette.grey['200']}`,
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  },
  UploaderEmpty: {
    width: 200,
    margin: 'auto',
    textAlign: 'center',
    cursor: 'pointer',
  },
}));
