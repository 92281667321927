import { css } from '@emotion/react';

export const overlapContainer = (containerSize: number | string) => css`
  height: ${typeof containerSize === 'string' ? containerSize : `${containerSize}px`};
  width: ${typeof containerSize === 'string' ? containerSize : `${containerSize}px`};
  position: relative;
  & > * {
    position: absolute;
  }
  // Need to add display flex here to prevent the icon from shifting to the right
  display: flex;
`;
