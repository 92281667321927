import { type MouseEventHandler, useCallback } from 'react';

import { useBoolState } from '../use-bool-state/useBoolState';

export type HoverEventHandlers<TElement extends HTMLElement> = {
  onMouseEnter: MouseEventHandler<TElement>;
  onMouseLeave: MouseEventHandler<TElement>;
};

export type UseHoverValue<TElement extends HTMLElement> = [state: boolean, eventHandlers: HoverEventHandlers<TElement>];

export const useHover = <TElement extends HTMLElement>({
  onMouseEnter,
  onMouseLeave,
}: Partial<HoverEventHandlers<TElement>> = {}): UseHoverValue<TElement> => {
  const { isHovered, setHoveredTrue, setHoveredFalse } = useBoolState(false, 'hovered');

  const onMouseEnterProxy: MouseEventHandler<TElement> = useCallback(
    (event) => {
      setHoveredTrue();
      onMouseEnter?.(event);
    },
    [setHoveredTrue, onMouseEnter],
  );

  const onMouseLeaveProxy: MouseEventHandler<TElement> = useCallback(
    (event) => {
      setHoveredFalse();
      onMouseLeave?.(event);
    },
    [setHoveredFalse, onMouseLeave],
  );

  return [
    isHovered,
    {
      onMouseEnter: onMouseEnterProxy,
      onMouseLeave: onMouseLeaveProxy,
    },
  ];
};
