import { memo } from 'react';

import { Dropdown } from '../../dropdown/Dropdown';
import { type DropdownListItem as DropdownListItemShape } from '../DropdownList.types';

export type DropdownListItemProps = {
  readonly item: DropdownListItemShape;
  readonly id?: string;
};

export const DropdownListItem = memo(function DropdownListItem({ item, id }: DropdownListItemProps) {
  return (
    <Dropdown.ItemContainer
      as="li"
      data-testid={id ? `${id}-item-${item.key}` : undefined}
    >
      <Dropdown.ItemContent
        disabled={item.disabled}
        grabHandle={item.grabHandle}
        icon={item.icon}
        label={item.label}
        rightActions={item.rightActions}
        secondaryLabel={item.secondaryLabel}
        tooltip={item.tooltip}
      />
    </Dropdown.ItemContainer>
  );
});
