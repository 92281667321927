import { Avatar as MuiAvatar, type AvatarProps as MuiAvatarProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { type CSSProperties, memo, useMemo } from 'react';

import { type AmaliaThemeType } from '@amalia/ext/mui/theme';
import { type UserComputed } from '@amalia/tenants/users/types';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    position: 'relative',
    top: 0,
  },
  avatar: {
    color: theme.palette.common.white,
    zIndex: 0,
    position: 'relative',
  },
}));

interface AvatarProps extends MuiAvatarProps {
  readonly user: Partial<UserComputed>;
  readonly onClick?: () => void;
  readonly style?: CSSProperties;
  readonly avatarStyles?: CSSProperties;
  readonly parentClassName?: string;
}

export const Avatar = memo(function Avatar({
  user,
  onClick,
  style,
  parentClassName,
  avatarStyles,
  ...props
}: AvatarProps) {
  const classes = useStyles();
  const alt = `${user?.firstName} ${user?.lastName}`;
  const className = clsx(props.className, classes.avatar);

  const avatarStyle = useMemo(
    () => ({
      ...style,
      ...avatarStyles,
      // If we have an onClick function, set cursor pointer.
      cursor: onClick ? 'pointer' : 'initial',
    }),
    [style, avatarStyles, onClick],
  );

  const rootStyle = useMemo(
    () => ({
      ...style,
      top: 0,
    }),
    [style],
  );

  const getInitial = (value: string) => value.charAt(0).toUpperCase() ?? '?';

  const userInitials = `${user?.firstName && getInitial(user.firstName)}${user?.lastName && getInitial(user.lastName)}`;
  const userPicture = user?.pictureURL || '';

  return (
    <div
      className={clsx(classes.root, parentClassName)}
      style={rootStyle}
    >
      <MuiAvatar
        {...props}
        alt={alt}
        className={className}
        src={userPicture}
        style={avatarStyle}
        onClick={onClick}
      >
        {props.children || userInitials}
      </MuiAvatar>
    </div>
  );
});
