import { IconPlug, IconPlugOff } from '@tabler/icons-react';

import { DataConnectorTypes } from '@amalia/data-capture/connectors/types';
import { type Asset } from '@amalia/ext/react/types';
import { UserHrisIdSource } from '@amalia/tenants/users/types';

import { type IntegrationLogos, VENDOR_LOGOS } from '../../../connectors.logos';

export const userHrisIdSourceLogos: Readonly<Record<UserHrisIdSource, IntegrationLogos>> = {
  [UserHrisIdSource.NONE]: {
    Logo: IconPlugOff as Asset,
    CompactLogo: IconPlugOff as Asset,
  },
  [UserHrisIdSource.OTHERSOURCE]: {
    Logo: IconPlug as Asset,
    CompactLogo: IconPlug as Asset,
  },
  [UserHrisIdSource.PERSONIO]: {
    ...VENDOR_LOGOS[DataConnectorTypes.PERSONIO],
  },
  [UserHrisIdSource.BAMBOOHR]: {
    ...VENDOR_LOGOS[DataConnectorTypes.BAMBOOHR],
  },
  [UserHrisIdSource.WORKDAY]: {
    ...VENDOR_LOGOS[DataConnectorTypes.WORKDAY],
  },
};
