import { defineMessages } from 'react-intl';

import { UserHrisIdSource } from '@amalia/tenants/users/types';

export const userHrisIdSourceLabels = defineMessages<UserHrisIdSource>({
  [UserHrisIdSource.NONE]: { defaultMessage: 'No integrations' },
  [UserHrisIdSource.OTHERSOURCE]: { defaultMessage: 'Other HRIS source' },
  [UserHrisIdSource.PERSONIO]: { defaultMessage: 'Personio' },
  [UserHrisIdSource.BAMBOOHR]: { defaultMessage: 'BambooHR' },
  [UserHrisIdSource.WORKDAY]: { defaultMessage: 'Workday' },
} as const);
