import { isEmpty } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';

import { type Statement } from '@amalia/core/types';
import { type FilterDataset, type MetricsDataset, type QuotaDataset } from '@amalia/payout-calculation/types';
import { HidableElementVisibility, type ObjectsToDisplay, type PlanRule } from '@amalia/payout-definition/plans/types';

import { type DatasetWithFields } from './datasetWithField.type';
import { listDatasetFields } from './listDatasetFields';

const getDatasetFilterMachineName = (datasetId: string): string => {
  const datasetIdMetadata = datasetId.split(':');
  return datasetIdMetadata[2];
};
/**
 * Select datasets to display from statement results with their respective fields.
 * @param statement
 * @param ruleDefinition
 */
export const useDatasets = ({
  statement,
  objectsToDisplay,
  isForecastedView,
  ruleDefinition,
}: {
  statement: Statement;
  objectsToDisplay?: Record<string, ObjectsToDisplay>;
  isForecastedView: boolean;
  ruleDefinition: PlanRule;
}) => {
  const previousForecastedViewProps = useRef(isForecastedView);
  const [selectedDataset, setSelectedDataset] = useState<DatasetWithFields>();

  const datasetsWithFields: DatasetWithFields[] = useMemo(() => {
    const filtersToDisplay = isForecastedView
      ? objectsToDisplay[ruleDefinition?.id]?.filtersToDisplay
      : ruleDefinition.filtersToDisplay;

    return (filtersToDisplay || [])
      .map((planRuleFilterToDisplay) => {
        if (planRuleFilterToDisplay.displayStatus !== HidableElementVisibility.ON_DISPLAY) {
          return undefined;
        }

        const filterDefinition = Object.values(statement.results.definitions.filters).find(
          (f) => planRuleFilterToDisplay.id === f.id,
        );
        const dataset = statement.results.datasets.find(
          (d) => getDatasetFilterMachineName(d.id) === filterDefinition?.machineName,
        ) as FilterDataset | MetricsDataset | QuotaDataset;

        const fieldsToDisplay = planRuleFilterToDisplay?.fieldsToDisplay || [];
        const fields = listDatasetFields(statement, fieldsToDisplay, dataset, ruleDefinition.formula);

        // Do not display datasets with empty fields.
        if (isEmpty(fields)) {
          return undefined;
        }

        return {
          ...dataset,
          fields,
        };
      })
      .filter(Boolean);
  }, [statement, ruleDefinition, objectsToDisplay, isForecastedView]);

  /**
   * When the datasets list change, we select the first dataset if none already selected.
   */
  useEffect(() => {
    const hasViewChanged = previousForecastedViewProps.current !== isForecastedView;

    if ((!selectedDataset || hasViewChanged) && !!datasetsWithFields.length) {
      const firstDataset = datasetsWithFields[0];
      setSelectedDataset(firstDataset);
    }

    previousForecastedViewProps.current = isForecastedView;
  }, [datasetsWithFields, selectedDataset, isForecastedView]);

  return {
    selectedDataset,
    setSelectedDataset,
    datasetsWithFields,
  };
};
