export enum SUPER_ADMIN_QUERY_KEYS {
  SA_GET_USER = 'SA_GET_USER',
  SA_LIST_COMPANIES = 'SA_LIST_COMPANIES',
  SA_GET_COMPANY = 'SA_GET_COMPANY',
  SA_LIST_USERS = 'SA_LIST_USERS',
  SA_ON_GOING_CALCULATIONS = 'SA_ON_GOING_CALCULATIONS',
  SA_LAST_CALCULATIONS = 'SA_LAST_CALCULATIONS',
  SA_GET_COMPANY_PERIODS = 'SA_GET_COMPANY_PERIODS',
  SA_ON_GOING_REFRESHMENTS = 'SA_ON_GOING_REFRESHMENTS',
  SA_LAST_REFRESHMENTS = 'SA_LAST_REFRESHMENTS',
  SA_LIST_CONNECTORS = 'SA_LIST_CONNECTORS',
  SA_LIST_STATEMENTS_ON_ERROR = 'SA_LIST_STATEMENTS_ON_ERROR',
  SA_LIST_PLANS = 'SA_LIST_PLANS',
  SA_GET_MASTER_SETTINGS = 'SA_GET_MASTER_SETTINGS',
}
export const getSaCompanyKey = (companyId: string) => `sa_${companyId}`;
