import { useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import { type Statement } from '@amalia/core/types';
import { type UserContract } from '@amalia/tenants/users/types';

export const useStatementUsers = (
  getStatementUsers: (statementId: Required<Statement>['id']) => Promise<Partial<UserContract>[]>,
  currentStatement?: Statement,
) => {
  const [statementUsers, setStatementUsers] = useState<Partial<UserContract>[]>([]);

  useAsyncEffect(async () => {
    if (!currentStatement?.id) {
      return;
    }
    const statementUsers = await getStatementUsers(currentStatement.id);
    setStatementUsers(statementUsers);
  }, [currentStatement?.id, getStatementUsers]);

  return statementUsers;
};
