import { css, keyframes } from '@emotion/react';

const shakeAnimation = keyframes`
  0% { transform: rotate(0deg); }
  2% { transform: rotate(30deg); }
  4% { transform: rotate(0deg); }
  6% { transform: rotate(30deg); }
  8% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
`;

export const notificationsAnchorShake = css`
  transform-origin: 50% 50%;
  transform: rotate(0deg);
  animation: ${shakeAnimation} 5s infinite;
`;
