import { convertTimestampToDate, formatDate, type Statement } from '@amalia/core/types';
import { useUserOvertimeData } from '@amalia/frontend/web-data-layers';
import { RelativePeriodKeyword } from '@amalia/payout-definition/periods/types';

export const useStatementDetailStatistics = (statement: Statement) => {
  const { data: overtime } = useUserOvertimeData({
    periodIds: [RelativePeriodKeyword.CURRENT_YEAR],
    referenceDate: statement.period.endDate
      ? formatDate(convertTimestampToDate(statement.period.endDate), 'YYYY-MM-DD')
      : undefined,
    userId: statement.user.id,
    planId: statement.plan.id,
  });

  return {
    statistics: overtime,
  };
};
