import moment from 'moment-business-days';

import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.businessDaysTo, AmaliaFunctionCategory.DATES);

func.nbParamsRequired = 2;

func.description = 'Count the number of business days between 2 dates (both dates are included)';

func.params = [
  {
    name: 'startDate',
    description:
      'Start of the interval: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
    validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
    validTokenValues: {
      [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
    },
    validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
  },
  {
    name: 'endDate',
    description:
      'End of the interval: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
    validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
    validTokenValues: {
      [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
    },
    validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
  },
];

func.examples = [
  {
    desc: 'Returns 5',
    formula: 'businessDaysTo(toDate("2023-05-01"), toDate("2023-05-05"))' as AmaliaFormula,
    result: 5,
  },
  {
    desc: 'Returns 8',
    formula: 'businessDaysTo(toDate("2023-04-23"), toDate("2023-05-03"))' as AmaliaFormula,
    result: 8,
  },
  {
    desc: 'Returns the number of business days between the statement start date and end date.',
    formula: 'businessDaysTo(statementPeriod.startDate, statementPeriod.endDate)' as AmaliaFormula,
  },
  {
    desc: 'Returns the number of business days between Close Date and the statement end date.',
    formula: 'businessDaysTo(opportunity.closeDate, statementPeriod.endDate)' as AmaliaFormula,
  },
];

func.exec = (startDate: moment.MomentInput | string, endDate: moment.MomentInput | string): number =>
  startDate < endDate
    ? moment(startDate, 'X').businessDiff(moment(endDate, 'X').endOf('day'))
    : moment(endDate, 'X').businessDiff(moment(startDate, 'X').endOf('day'));

export default func;
