import { type Sorting } from '@devexpress/dx-react-grid';
import { css } from '@emotion/react';
import { memo, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  type CreateDatasetOverwriteRequest,
  type DatasetRow,
  type DeleteDatasetOverwriteRequest,
  formatUserFullName,
  type Overwrite,
  OverwriteTypesEnum,
  type PatchCustomObjectRequest,
  type Statement,
} from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { CustomObjectDefinitionType, isCustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { CustomObjectsApiClient } from '@amalia/data-capture/records/api-client';
import {
  type FilterOverwriteRemoveCreationRequestDetails,
  FilterOverwriteRemoveModalContainer,
  OVERWRITE_CONTEXT,
  type OverwriteCreationRequestDetails,
  OverwriteModalContainer,
} from '@amalia/data-correction/overwrites/components';
import { type SortDirection, useSnackbars } from '@amalia/design-system/components';
import { useShallowObjectMemo } from '@amalia/ext/react/hooks';
import { toError } from '@amalia/ext/typescript';
import {
  clearStatementOverwriteThunkAction as clearOverwriteAction,
  createDatasetOverwriteThunkAction,
  selectCurrentStatement,
  selectUsersMap,
  STATEMENTS_ACTIONS,
  useThunkDispatch,
} from '@amalia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext, useCurrentUser } from '@amalia/kernel/auth/state';
import {
  alphabeticalSorter,
  currencySorter,
  DataGrid,
  type DataGridPluginDefinition,
  DataGridPluginPosition,
  type DataGridPropsOptions,
  FullWidthLoader,
  Spinner,
  useStatementDetailContext,
} from '@amalia/lib-ui';
import { StatementDatasetsApiClient } from '@amalia/payout-calculation/statements/state';
import { type Dataset, DatasetType, type FilterDataset } from '@amalia/payout-calculation/types';
import { usePeriods } from '@amalia/payout-definition/periods/components';
import { type ComputedPlanRuleFieldsToDisplay, type PlanRule } from '@amalia/payout-definition/plans/types';

import { AddRecordsModal } from './AddRecordModal/AddRecordsModal';
import { type RowsTableColumn, RowsTableUtils } from './RowsTable.utils';
import { RowsTableAddRecordButton } from './RowsTableAddRecordButton';
import { RowsTableCell } from './RowsTableCell';
import { RowsTableContext, type RowsTableContextInterface } from './RowsTableContext';
import { RowsTableFixedCell } from './RowsTableFixedCell';
import { RowsTableHeaderCell } from './RowsTableHeaderCell';
import { RowsTableRow } from './RowsTableRow';
import { useRowsTableDataFetch } from './useRowsTableDataFetch';

export type ComputedPlanRuleFieldsToDisplayWithSubTitle = ComputedPlanRuleFieldsToDisplay & { subTitle?: string };

interface RowsTableProps {
  readonly dataset: Dataset;
  // Controlled mode.
  readonly datasetRows?: DatasetRow[];
  readonly fields: ComputedPlanRuleFieldsToDisplayWithSubTitle[];
  readonly setCurrentTracingData?: any;
  readonly hideComments?: boolean;
  readonly hideEditOverwrite?: boolean;
  readonly sortProps?: Sorting[];
  readonly rowsToHighlight?: string[];
  readonly ruleId?: string;
  readonly serializeRow?: (row: DatasetRow) => string | undefined;
  readonly globalSearchValue?: string;
  readonly useDefaultGetCellValue?: boolean;
  readonly moreDatagridOptions?: Partial<DataGridPropsOptions>;
  readonly ruleDefinition?: PlanRule;
  readonly forecasted?: boolean;
}

export const RowsTable = memo(function RowsTable({
  dataset,
  datasetRows,
  fields,
  setCurrentTracingData,
  hideComments,
  hideEditOverwrite,
  sortProps,
  rowsToHighlight,
  ruleId,
  serializeRow,
  globalSearchValue,
  useDefaultGetCellValue,
  moreDatagridOptions,
  ruleDefinition,
  forecasted,
}: RowsTableProps) {
  const dispatch = useThunkDispatch();
  const { snackError } = useSnackbars();
  const { data: currentUser } = useCurrentUser();

  // We don't support overwrite and comments on metrics or quota filters.
  const { hideCommentsFinal, hideEditOverwriteFinal } = useMemo(
    () => ({
      hideCommentsFinal: !!hideComments || dataset.type !== DatasetType.filter,
      hideEditOverwriteFinal: !!hideEditOverwrite || dataset.type !== DatasetType.filter,
    }),
    [hideComments, hideEditOverwrite, dataset],
  );

  // If we have rows from the parent, the datagrid is in controlled mode.
  // All sorting, pagination and search is done on the frontend side.
  // TODO: remove this when the filter preview is replaced by the designer.
  const frontendMode = datasetRows !== undefined;

  const usersMap = useSelector(selectUsersMap);
  const { periodsMap } = usePeriods();

  const currentStatement: Statement | undefined = useSelector(selectCurrentStatement);
  const { launchCalculation, openStatementThreadPanel, statementThreads } = useStatementDetailContext();
  const ability = useAbilityContext();

  const { paginationState, applyOverwrite, isLoading, datagridState, clearOverwrite, setAddedRows } =
    useRowsTableDataFetch({
      dataset,
      fields,
      statement: currentStatement,
      datasetRows,
      globalSearchValue,
      forecasted,
    });

  // We currently do not support overwrites on quota, metrics...

  // Remap fields to put them into the dx-grid format.
  const { columns, columnDescriptions }: { columns: RowsTableColumn[]; columnDescriptions: Record<string, string> } =
    useMemo(() => {
      const formattedColumns = fields.map((f) => ({
        title: f.label,
        subTitle: f.subTitle,
        name: f.name,
      }));

      // We add a column for actions (link to crm, delete row, tracing icon).
      formattedColumns.push({
        title: ' ',
        subTitle: undefined,
        name: 'actions',
      });

      const formattedColumnDescriptions = RowsTableUtils.getColumnDescriptions(currentStatement, formattedColumns);

      return {
        columns: formattedColumns,
        columnDescriptions: formattedColumnDescriptions,
      };
    }, [fields, currentStatement]);

  // Give a format for each column.
  const { customObjectDefinition, cellFormats } = useMemo(() => {
    const definitionMachineName = dataset.customObjectDefinition?.machineName;
    const objDef = currentStatement?.results?.definitions?.customObjects?.[definitionMachineName];
    const properties = objDef ? objDef.properties : {};

    const formats = columns.reduce(
      (acc, column) => {
        let cellFormat: FormatsEnum | undefined;

        // Try to find a format from a variableDefinition.
        const variableDefinition = currentStatement?.results.definitions.variables[column.name];
        if (variableDefinition) {
          cellFormat = variableDefinition?.format;
        } else {
          // If we're not in a field, then we're in a custom object property.
          cellFormat = properties[column.name]?.format;
        }

        acc[column.name] = cellFormat;
        return acc;
      },
      {} as Record<string, FormatsEnum | undefined>,
    );

    return { cellFormats: formats, customObjectDefinition: objDef };
  }, [columns, currentStatement, dataset]);

  // Using formats, setup sorters for special column types.
  const integratedSortingColumnExtensions = useMemo(() => {
    const sortColumns: { columnName: string; compare?: (a: any, b: any) => number; sortingEnabled: boolean }[] = [];

    // Loop on each column, eventually register a custom sorter for it.
    Object.entries(cellFormats).forEach(([columnName, format]) => {
      if (format === FormatsEnum.text) {
        sortColumns.push({ columnName, compare: alphabeticalSorter, sortingEnabled: true });
      }

      if (format === FormatsEnum.currency) {
        sortColumns.push({ columnName, compare: currencySorter, sortingEnabled: true });
      }
    });

    return sortColumns;
  }, [cellFormats]);

  const getCellValue = useCallback(
    (row: DatasetRow, columnName: string) => {
      // Manage specific metric cases
      if (dataset.type === DatasetType.metrics) {
        switch (columnName) {
          case 'periodId':
            return periodsMap?.[row?.content?.[columnName]]?.startDate || row?.content?.[columnName];
          case 'userId':
            return formatUserFullName(usersMap?.[row?.content?.[columnName]]) || row?.content?.[columnName];
          default:
            // Let the utils take the lead
            break;
        }
      }
      return RowsTableUtils.getCellValue(row, columnName, currentStatement);
    },
    [currentStatement, dataset, periodsMap, usersMap],
  );

  // Save which line has tracing deployed
  const [tracingExternalId, setTracingExternalId] = useState<string | null>(null);

  // Apply overwrites after editing a cell.
  const onCommitChanges = useCallback(
    async ({ changed }: any): Promise<void> => {
      if (paginationState.rows && changed) {
        try {
          const rowTableOverwrite = RowsTableUtils.onCommitChanges(dataset, paginationState.rows, currentStatement, {
            changed,
          });

          let overwrite: Overwrite;
          if (rowTableOverwrite.overwriteRequest) {
            if (rowTableOverwrite.overwriteType === OverwriteTypesEnum.FILTER_ROW_REMOVE) {
              await StatementDatasetsApiClient.deleteDatasetRow(
                rowTableOverwrite.statementId,
                dataset.id,
                rowTableOverwrite.objectExternalId,
                rowTableOverwrite.overwriteRequest as DeleteDatasetOverwriteRequest,
              );
            } else if (rowTableOverwrite.statementId) {
              const resp = await dispatch(
                createDatasetOverwriteThunkAction(
                  rowTableOverwrite.statementId,
                  dataset.id,
                  rowTableOverwrite.overwriteRequest as CreateDatasetOverwriteRequest,
                ),
              );

              if (resp.type === STATEMENTS_ACTIONS.CREATE_OVERWRITE) {
                overwrite = resp?.payload?.overwrite;
              }
            } else {
              const resp = await CustomObjectsApiClient.patchCustomObject({
                definitionMachineName: rowTableOverwrite.definitionName,
                objectExternalId: rowTableOverwrite.objectExternalId,
                patch: rowTableOverwrite.overwriteRequest as PatchCustomObjectRequest,
              });
              overwrite = resp.createdOverwrite;
            }

            if (overwrite) {
              applyOverwrite(overwrite);
            }

            // Launch Calculation
            launchCalculation?.();
          }
        } catch (e) {
          snackError(toError(e).message);
        }
      }
    },
    [dataset, paginationState, currentStatement, launchCalculation, dispatch, snackError, applyOverwrite],
  );

  const [overwriteDetails, setOverwriteDetails] = useState<OverwriteCreationRequestDetails>(null);
  const handleOpenOverwriteModal = useCallback(
    (details: OverwriteCreationRequestDetails) => {
      setOverwriteDetails(details);
    },
    [setOverwriteDetails],
  );
  const onCloseModal = useCallback(() => setOverwriteDetails(null), [setOverwriteDetails]);

  const [filterOverwriteRemoveDetails, setFilterOverwriteRemoveDetails] =
    useState<FilterOverwriteRemoveCreationRequestDetails>(null);
  const handleOpenFilterOverwriteRemoveModal = useCallback(
    (details: FilterOverwriteRemoveCreationRequestDetails) => {
      setFilterOverwriteRemoveDetails(details);
    },
    [setFilterOverwriteRemoveDetails],
  );
  const onCloseFilterOverwriteRemoveModal = useCallback(
    () => setFilterOverwriteRemoveDetails(null),
    [setFilterOverwriteRemoveDetails],
  );

  const handleClearOverwrite = useCallback(
    async (overwrite: Overwrite) => {
      if (!overwrite?.id) {
        return;
      }

      if (overwrite.overwriteType === OverwriteTypesEnum.PROPERTY) {
        await CustomObjectsApiClient.clearCustomObject({
          definitionMachineName: customObjectDefinition.machineName,
          objectExternalId: overwrite.appliesToExternalId,
          overwriteId: overwrite.id,
        });
      } else {
        await dispatch(clearOverwriteAction(currentStatement.id, overwrite));
      }

      clearOverwrite(overwrite);

      launchCalculation?.();
    },
    [dispatch, clearOverwrite, customObjectDefinition, currentStatement, launchCalculation],
  );

  const cellEditColumnExtensions = [
    { columnName: 'id', editingEnabled: false },
    { columnName: 'userId', editingEnabled: false },
    { columnName: 'statementId', editingEnabled: false },
    ...(customObjectDefinition
      ? [
          ...(customObjectDefinition.externalIds || []).map((externalId) => ({
            columnName: externalId,
            editingEnabled: false,
          })),
          ...(isCustomObjectDefinition(customObjectDefinition) && customObjectDefinition.nameField
            ? [{ columnName: customObjectDefinition.nameField, editingEnabled: false }]
            : []),
        ]
      : []),
  ];

  // ----------------------
  // Add records modal.
  // ----------------------

  const [showAddRecordModal, setShowAddRecordModal] = useState<boolean>(false);

  const handleAddRecordModalClose = useCallback(() => setShowAddRecordModal((prev) => !prev), []);

  // ----------------------
  // Datagrid options
  // ------------------------
  const additionalHeaderComponents: DataGridPluginDefinition[] = useMemo(
    () => [
      {
        key: 'rows-table-header-components',
        children: forecasted &&
          currentStatement?.plan.isSimulationAddRecordEnabled &&
          customObjectDefinition?.type !== CustomObjectDefinitionType.VIRTUAL && <RowsTableAddRecordButton />,
        position: DataGridPluginPosition.rightStart,
      },
      ...(moreDatagridOptions?.additionalHeaderComponents || []),
    ],
    [
      customObjectDefinition?.type,
      forecasted,
      moreDatagridOptions?.additionalHeaderComponents,
      currentStatement?.plan.isSimulationAddRecordEnabled,
    ],
  );

  // This is a temporary mapping from new datagrid state to old datagrid state.
  const [mappedSorting, setMappedSorting] = useMemo(
    () => [
      datagridState.columnSorting.map(({ id, direction }) => ({
        columnName: id,
        direction,
      })),
      (sorting: Sorting[]) =>
        datagridState.setColumnSorting(
          sorting.map(({ columnName, direction }) => ({ id: columnName, direction: direction as SortDirection })),
        ),
    ],
    [datagridState],
  );

  const [mappedHiddenColumns, setMappedHiddenColumns] = useMemo(
    () => [
      Object.entries(datagridState.columnVisibility)
        .filter(([, visibility]) => !visibility)
        .map(([columnName]) => columnName),
      (hiddenColumnNames: string[]) =>
        datagridState.setColumnVisibility(
          hiddenColumnNames.reduce(
            (acc, columnName) => ({
              ...acc,
              [columnName]: false,
            }),
            {},
          ),
        ),
    ],
    [datagridState],
  );

  const DATAGRID_OPTIONS = useMemo<DataGridPropsOptions>(
    () => ({
      ...moreDatagridOptions,
      sort: {
        sortable: true,
        defaultSorting: sortProps,
        overwriteSort: !frontendMode,
        sorting: mappedSorting,
        onSortingChange: setMappedSorting,
        integratedColumnExtensions: frontendMode ? integratedSortingColumnExtensions : undefined,
      },
      search: {
        searchable: true,
        overwriteSearch: !frontendMode,
        value: datagridState.searchText,
        onValueChange: datagridState.setSearchText,
      },
      pages: {
        paginable: true,
        customPagingTotalItems: frontendMode
          ? undefined
          : // PaginationState is null at first render.
            paginationState?.totalItems || 0,
        currentPage: datagridState.page,
        onCurrentPageChange: datagridState.setPage,
        pageSize: datagridState.pageSize,
        onPageSizeChange: datagridState.setPageSize,
      },
      columnVisibility: {
        active: ability.can(ActionsEnum.customize, SubjectsEnum.Statement),
        hiddenColumnNames: mappedHiddenColumns,
        onHiddenColumnNamesChange: setMappedHiddenColumns,
      },
      // Set the accessor to say to DX Grid where the data is. It's also needed for sorting and filtering.
      // This value can be retrieved in the "value" props of the cell but we should not
      // use it because we've lost the meta info about format and currency, and it's not the
      // single source of truth for this data (the dataset is).
      getCellValue: useDefaultGetCellValue ? undefined : getCellValue,

      // First implementation of this RowsTable remapped the rows before putting it into the
      // table to flatten the definition of the object (contentWF). That was a way to do it,
      // but fetching back the full row from the cell was hard and costly (need to make several
      // finds on the dataset to get the original row). This new implementation avoids destructuring
      // the row to simplify the code in the cell component.

      fixed: {
        pinnableFirstColumn: true,
        pinnedFirstColumnComponent: RowsTableFixedCell,
      },

      tableHeaderRowComponent: moreDatagridOptions?.tableHeaderRowComponent || RowsTableHeaderCell,

      additionalHeaderComponents,
    }),
    [
      mappedSorting,
      setMappedSorting,
      additionalHeaderComponents,
      frontendMode,
      integratedSortingColumnExtensions,
      datagridState,
      paginationState,
      ability,
      mappedHiddenColumns,
      setMappedHiddenColumns,
      useDefaultGetCellValue,
      getCellValue,
      moreDatagridOptions,
      sortProps,
    ],
  );

  const contextContent = useShallowObjectMemo<RowsTableContextInterface>({
    // Data
    fields,
    dataset,
    cellFormats,
    columnDescriptions,

    // Highlight
    rowsToHighlight,

    // Tracing
    tracingExternalId,
    setTracingExternalId,
    setCurrentTracingData,

    // Comments
    hideComments: hideCommentsFinal,

    // Overwrite
    hideEditOverwrite: hideEditOverwriteFinal,
    cellEditColumnExtensions,
    // Rule
    ruleId,
    ruleDefinition,

    // Serialize row,
    serializeRow,
    handleOpenOverwriteModal,
    handleClearOverwrite,
    handleOpenFilterOverwriteRemoveModal,

    isForecastedView: forecasted,

    setAddedRows,
    toggleAddRecordModal: handleAddRecordModalClose,
    openStatementThreadPanel,
    statementThreads,
  });

  const datagridId = useMemo(
    // Adapt this when we have new dataset types (we only have filter for now).
    () => (dataset.type === DatasetType.filter ? (dataset as FilterDataset).filterMachineName : 'no-id'),
    [dataset],
  );

  switch (true) {
    // If we don't have a dataset on display, create a placeholder and put the loader in it.
    case isLoading && !dataset:
      return (
        <div
          css={css`
            position: relative;
            height: 180px;
          `}
        >
          <Spinner />
        </div>
      );
    case !!dataset:
      return (
        <RowsTableContext.Provider value={contextContent}>
          <DataGrid
            columns={columns}
            id={datagridId}
            options={DATAGRID_OPTIONS}
            rows={paginationState?.rows || []}
            tableProps={{
              cellComponent: RowsTableCell,
              rowComponent: RowsTableRow,
            }}
          />
          {overwriteDetails ? (
            <OverwriteModalContainer
              currentObjectDetails={overwriteDetails}
              currentUser={currentUser}
              handleClose={onCloseModal}
              handleSubmit={onCommitChanges}
              isOpen={!!overwriteDetails}
              overwriteContext={OVERWRITE_CONTEXT.ROWTABLE}
            />
          ) : null}
          {filterOverwriteRemoveDetails ? (
            <FilterOverwriteRemoveModalContainer
              currentFilterOverwriteRemoveObjectDetails={filterOverwriteRemoveDetails}
              handleClose={onCloseFilterOverwriteRemoveModal}
              handleSubmit={onCommitChanges}
              isOpen={!!filterOverwriteRemoveDetails}
            />
          ) : null}
          <FullWidthLoader show={isLoading} />
          <AddRecordsModal
            dataset={dataset}
            isOpen={showAddRecordModal}
            statementId={currentStatement?.id}
            onClose={handleAddRecordModalClose}
            onSetAddedRows={setAddedRows}
          />
        </RowsTableContext.Provider>
      );

    // Else the rule doesn't have any dataset on display, return null.
    default:
      return null;
  }
});
