import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { type UserProfile } from '@amalia/tenants/users/profile/types';
import {
  UserStatus,
  type UserExternalIdSource,
  type UserHrisIdSource,
  type UserRole,
} from '@amalia/tenants/users/types';

export type UserFilters = {
  readonly status: UserStatus[];
  readonly role: UserRole[];
  readonly currency: CurrencySymbolsEnum[];
  readonly externalIdSource: UserExternalIdSource[];
  readonly hrisIdSource: UserHrisIdSource[];
  readonly id: UserProfile['id'][];
};

export const EMPTY_USER_FILTERS: UserFilters = {
  status: [UserStatus.ACTIVE, UserStatus.INACTIVE, UserStatus.INVITED],
  role: [],
  currency: [],
  externalIdSource: [],
  hrisIdSource: [],
  id: [],
};
