export enum TokenType {
  VARIABLE = 'VARIABLE',
  FILTER = 'FILTER',
  RULE = 'RULE',
  LINK = 'LINK',
  FIELD = 'FIELD',
  QUOTA = 'QUOTA',
  FUNCTION = 'FUNCTION',
  KEYWORD = 'KEYWORD',
  PROPERTY = 'PROPERTY',
  VIRTUAL_PROPERTY = 'VIRTUAL_PROPERTY',
  PLAN = 'PLAN',
  OBJECT = 'OBJECT',
}

export const UNKNOWN_TOKEN_TYPE = 'UNKNOWN';

/**
 * List of all keywords that are usable in the formulas.
 *
 * For instance statementPeriod.startDate, user.name, ...
 */
export enum AmaliaAccessorKeywords {
  statementPeriod = 'statementPeriod',
  statement = 'statement',
  // TODO: pfffff
  planAssignement = 'planAssignement',
  teamAssignment = 'teamAssignment',
  filter = 'filter',
  user = 'user',
  team = 'team',
  plan = 'plan',
  row = '$row',
  statementMetric = 'statementMetric',
  objectMetric = 'objectMetric',
  quota = 'quota',
}
