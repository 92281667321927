import { useMemo } from 'react';

import { fuzzyFilter } from '@amalia/ext/filters';

import { isSelectOptionGroup, type SelectDropdownOption, type SelectOptionGroup } from '../SelectDropdown.types';

/**
 * Default option filter predicate that checks if the option label contains the filter text. The filter label is used if available.
 * The filter is case insensitive.
 *
 * @param option - Option to filter.
 * @param filter - The filter text.
 * @returns True if the option should be kept.
 */
export const defaultFilterOption = <TOption extends SelectDropdownOption = SelectDropdownOption>(
  option: TOption,
  filter: string,
): boolean => {
  const filterLabel = option.filterLabel ?? option.label;
  return typeof filterLabel === 'string' && fuzzyFilter(filterLabel, filter);
};

/**
 * Filter options with the current filter text and return the filtered options by keeping the same options/groups structure.
 * Empty groups are deleted.
 *
 * @param optionsAndGroups - The options or groups to filter.
 * @param filter - The filter text.
 * @param filterOption - The filter predicate.
 * @returns The filtered options or groups.
 */
export const useFilterOptions = <
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
>(
  optionsAndGroups: (TGroup | TOption)[],
  filter: string,
  filterOption: (option: TOption, filter: string) => boolean = defaultFilterOption,
): (TGroup | TOption)[] =>
  useMemo(
    () =>
      (filter
        ? optionsAndGroups.reduce(
            (acc, optionOrGroup) => {
              if (isSelectOptionGroup(optionOrGroup)) {
                acc.push({
                  ...optionOrGroup,
                  options: optionOrGroup.options.filter((option) => filterOption(option, filter)),
                });
              } else if (filterOption(optionOrGroup, filter)) {
                acc.push(optionOrGroup);
              }

              return acc;
            },
            [] as typeof optionsAndGroups,
          )
        : optionsAndGroups
      ).filter((optionOrGroup) => !isSelectOptionGroup(optionOrGroup) || !!optionOrGroup.options.length),
    [filter, filterOption, optionsAndGroups],
  );
