import { type Theme, css } from '@emotion/react';

export const formulaConditionGroup = (theme: Theme) => css`
  background-color: ${theme.ds.colors.gray[50]};
  border: 1px solid ${theme.ds.colors.gray[100]};
  border-radius: ${theme.ds.borderRadiuses.squared};

  padding: 3px 6px;

  display: inline-flex;
  align-items: center;
  gap: 6px;

  > * {
    flex-shrink: 0;
  }
`;

export const conditions = css`
  display: flex;
  align-items: center;
  gap: 6px;

  flex-wrap: wrap;
  flex-shrink: 1;
`;
