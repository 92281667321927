import { css } from '@emotion/react';
import { memo, useMemo } from 'react';

import { useStatementDetailContext } from '@amalia/lib-ui';

import { StatementHeaderPayoutForecasted } from '../../../../common/StatementHeaderPayoutForecasted';

export const StatementDetailSummaryPayoutForecasted = memo(function StatementSummaryPayoutForecasted() {
  const { statement, originalStatement } = useStatementDetailContext();

  const forecastedTotal = useMemo(
    () => ({
      value: statement.total,
      symbol: statement.currency,
    }),
    [statement],
  );

  const statementTotal = useMemo(
    () => ({
      value: originalStatement?.total,
      symbol: originalStatement?.currency,
    }),
    [originalStatement],
  );

  return (
    <StatementHeaderPayoutForecasted
      withBorderTop
      forecastedTotal={forecastedTotal}
      statementTotal={statementTotal}
      css={(theme) => css`
        border-radius: 0 0 ${theme.ds.borderRadiuses.squared} ${theme.ds.borderRadiuses.squared};
      `}
    />
  );
});
