import { isFunction } from 'lodash';
import { type ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { type MergeAll } from '@amalia/ext/typescript';

import { Tag } from '../../../../general/tag/Tag';
import { type SelectDropdownChildRenderProps } from '../../../../overlays/select-dropdown/SelectDropdown.types';
import { useFiltersContext } from '../../Filters.context';
import { type FilterKey, type FilterSelectOption } from '../../Filters.types';

export type FilterSelectTagProps<
  TOption extends FilterSelectOption = FilterSelectOption,
  TIsMultiple extends boolean | undefined = undefined,
> = MergeAll<
  [
    SelectDropdownChildRenderProps<TOption, TIsMultiple>,
    {
      /** Filter key. Must be unique in the Filters children list. */
      id: FilterKey;
      /** Filter label. */
      label: ReactNode | ((count: number) => ReactNode);
      /** Filter label when all options are selected (aka when no options are selected). */
      allSelectedLabel: ReactNode;
      /** Set to true to disable the default behavior of considering that selecting all options is the same as selecting no options. */
      disableSelectAllIsSelectNone?: boolean;
      /** Can this filter be removed. */
      isStatic?: boolean;
      /** Is the dropdown disabled. */
      disabled?: boolean;
    },
  ]
>;

export const FilterSelectTag = function FilterSelectTag<
  TOption extends FilterSelectOption = FilterSelectOption,
  TIsMultiple extends boolean | undefined = undefined,
>({
  id,
  label,
  allSelectedLabel,
  disabled,
  isStatic,
  isDropdownOpen,
  hasEveryOptionSelected,
  disableSelectAllIsSelectNone = false,
  value,
  flatOptions,
  onClear,
}: FilterSelectTagProps<TOption, TIsMultiple>) {
  const { onHideFilter } = useFiltersContext();

  const selectedOptions = value ? ([] as TOption[]).concat(value) : [];

  const handleDelete = useCallback(() => {
    // Clear values before hiding the filter, otherwise the filter is still active but no longer displayed.
    onClear();
    onHideFilter(id);
  }, [id, onClear, onHideFilter]);

  const computedLabel = isFunction(label) ? label(selectedOptions.length) : label;

  return (
    <Tag
      count={selectedOptions.length || flatOptions.length} // If no option selected, show the total number of options.
      disabled={disabled}
      id={id}
      isActive={isDropdownOpen}
      isCountActive={!!selectedOptions.length}
      onDelete={isStatic ? undefined : handleDelete}
    >
      {selectedOptions.length === 1 ? (
        <FormattedMessage
          defaultMessage="{filterName}: {filterValue}"
          values={{
            filterName: computedLabel,
            filterValue: selectedOptions[0].valueLabel || selectedOptions[0].label,
          }}
        />
      ) : (hasEveryOptionSelected && !disableSelectAllIsSelectNone) || selectedOptions.length === 0 ? (
        allSelectedLabel
      ) : (
        computedLabel
      )}
    </Tag>
  );
};
