import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Route, useLocation } from 'react-router-dom';

import { Layout } from '@amalia/core/layout/components';
import { routes } from '@amalia/core/routes';
import { AmaliaHead } from '@amalia/design-system/ext';
import { RootRoutes } from '@amalia/ext/react-router-dom';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { AbilityRouteProtector } from '@amalia/kernel/auth/state';

const StatementDetail = lazy(() => import('./detail/StatementDetail'));
const Statements = lazy(() => import('./list/Statements'));
const StatementNoDetail = lazy(() => import('./noDetail/StatementNoDetail'));

export const StatementsRoutes = memo(function StatementsRoutes() {
  const { formatMessage } = useIntl();
  const isForecast = useLocation().pathname.includes('forecasts');

  return (
    <Layout currentPage={isForecast ? 'forecasts' : 'statements'}>
      <RootRoutes>
        <Route
          path={routes.STATEMENT_COMMENT}
          element={
            <AbilityRouteProtector
              can={(ability) => ability.can(ActionsEnum.view_statement_threads, SubjectsEnum.Statement)}
            >
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statement' })} />
              <StatementDetail />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.STATEMENT_COMMENT_CREATE}
          element={
            <AbilityRouteProtector
              can={(ability) => ability.can(ActionsEnum.add_statement_comments, SubjectsEnum.Statement)}
            >
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statement' })} />
              <StatementDetail />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.STATEMENT_RULE_ACTION_EXTERNAL_ID}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statement' })} />
              <StatementDetail />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.STATEMENT_RULE_ACTION}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statement' })} />
              <StatementDetail />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.STATEMENT_RULE}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statement' })} />
              <StatementDetail />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.STATEMENT}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statement' })} />
              <StatementDetail />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.FORECAST}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Forecast' })} />
              <StatementDetail />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.STATEMENTS_BY_USER_PERIOD}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statements' })} />
              <StatementNoDetail />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.FORECASTS}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Forecasts' })} />
              <Statements />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.FORECASTS_BY_DATE}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Forecasts' })} />
              <Statements />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.STATEMENTS}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statements' })} />
              <Statements />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.STATEMENTS_BY_DATE}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statements' })} />
              <Statements />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});
