import { sortBy, unionBy } from 'lodash';
import { Fragment, memo, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, Link, useNavigate } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { type Statement, formatPeriodMarkerDate, formatUserFullName, type StatementOption } from '@amalia/core/types';
import { Breadcrumbs, type SimpleSelectOption } from '@amalia/design-system/components';
import { useStatementFacets } from '@amalia/frontend/web-data-layers';
import {
  ActionsEnum,
  canSeeHiddenPlan,
  SubjectsEnum,
  SubsetAccessEnum,
  SubsetsAccessHelper,
} from '@amalia/kernel/auth/shared';
import { useAbilityContext, useAuthenticatedContext } from '@amalia/kernel/auth/state';
import { IconPlanVisibility } from '@amalia/payout-definition/plans/components';
import { PlanIsHiddenQueryChoices } from '@amalia/payout-definition/plans/types';
import { type UserComputed } from '@amalia/tenants/users/types';

import { StatementPeriodSelector } from '../../../StatementPeriodSelector';

type StatementDetailHeaderBreadcrumbsProps = {
  readonly statement: Statement;
  readonly isForecastedView?: boolean;
  readonly withShadow?: boolean;
};

const StatementDetailHeaderBreadcrumbsBase = function StatementDetailHeaderBreadcrumbsBase({
  statement,
  isForecastedView,
  withShadow,
}: StatementDetailHeaderBreadcrumbsProps) {
  const ability = useAbilityContext();

  const { statementsListFacets } = useStatementFacets({
    period: statement.period,
    planHiddenStatus: canSeeHiddenPlan(ability) ? PlanIsHiddenQueryChoices.BOTH : PlanIsHiddenQueryChoices.LIVE,
  });

  const navigate = useNavigate();
  const { authenticatedContext } = useAuthenticatedContext();
  const { formatMessage } = useIntl();

  const userViewListRightAccess = useMemo(() => ability.can(ActionsEnum.view_list, SubjectsEnum.Statement), [ability]);

  // PLANS SELECT
  const statementsByPlans = useMemo(
    () =>
      (statementsListFacets.users || []).reduce<Record<string, (StatementOption & { userId: string })[]>>(
        (acc, elm) => {
          (elm.statementsOptions || []).forEach((statementOption) => {
            if (!acc[statementOption.planId]) {
              acc[statementOption.planId] = [];
            }
            acc[statementOption.planId] = [
              ...acc[statementOption.planId],
              ...(elm.statementsOptions || [])
                .filter((s) => s.planId === statementOption.planId)
                .map((s) => ({
                  ...s,
                  userId: elm.id,
                })),
            ];
          });
          return acc;
        },
        {},
      ),
    [statementsListFacets],
  );

  const plans: SimpleSelectOption[] = useMemo(
    () =>
      (statementsListFacets.users || [])
        .map((user) => user.statementsOptions || [])
        .reduce<SimpleSelectOption[]>((acc, statementsOptions) => {
          const planOptions = statementsOptions.map((statement) => ({
            label: statement.planName,
            value: statement.planId,
            icon: <IconPlanVisibility isPlanHidden={statement.isPlanHidden} />,
          }));
          return unionBy(acc, planOptions, 'value');
        }, []),
    [statementsListFacets],
  );

  const proposedPlans: SimpleSelectOption[] = useMemo(() => sortBy(plans, 'label'), [plans]);

  const onChangePlan = useCallback(
    (planId: string) => {
      if (statementsByPlans[planId]?.length) {
        const userPlanStatement = statementsByPlans[planId].find((elm) => elm.userId === statement.user.id);

        navigate(
          generatePath(routes.STATEMENT, {
            id: userPlanStatement?.statementId || statementsByPlans[planId][0].statementId,
          }),
        );
      }
    },
    [navigate, statement.user.id, statementsByPlans],
  );

  // USERS SELECT
  const userSwitchRightAccess = useMemo(
    () =>
      SubsetsAccessHelper.getSubset(authenticatedContext, ActionsEnum.switch_to_another_user, SubjectsEnum.Statement),
    [authenticatedContext],
  );

  const usersCanSeeForThisPlan = useMemo(() => {
    // Get users from the statement Groups that have at least one statement
    const users = (statementsListFacets.users || [])
      .map((elm) => ({
        ...elm,
        statementsOptions: (elm.statementsOptions || []).filter((s) => s.planId === statement.plan.id),
      }))
      .filter((elm) => elm.statementsOptions.length);

    if (userSwitchRightAccess === SubsetAccessEnum.EVERYTHING) {
      return users;
    }

    // Only return users that current user has access to
    return users.filter((user) => authenticatedContext.hierarchy.isManagerOf(user.id, new Date()));
  }, [authenticatedContext, userSwitchRightAccess, statement, statementsListFacets]);

  const hasSeveralUsers = usersCanSeeForThisPlan?.some((user) => user.id !== statement?.user.id);

  const usersOptions: SimpleSelectOption<UserComputed['id']>[] = useMemo(
    () =>
      usersCanSeeForThisPlan.map((user) => ({
        label: formatUserFullName(user),
        value: user.id,
      })),
    [usersCanSeeForThisPlan],
  );

  const onChangeUser = useCallback(
    (userId: UserComputed['id']) => {
      const user = (statementsListFacets.users || []).find((elm) => elm.id === userId);
      if (user?.statementsOptions?.length) {
        const userStatementWithSamePlan = user.statementsOptions.find((elm) => elm?.planId === statement.plan.id);
        navigate(
          generatePath(routes.STATEMENT, {
            id: userStatementWithSamePlan?.statementId || user.statementsOptions[0].statementId,
          }),
        );
      }
    },
    [navigate, statementsListFacets, statement.plan.id],
  );

  return (
    <Breadcrumbs
      withShadow={withShadow}
      back={
        userViewListRightAccess && statement ? (
          <Link
            to={generatePath(isForecastedView ? routes.FORECASTS_BY_DATE : routes.STATEMENTS_BY_DATE, {
              startDate: formatPeriodMarkerDate(statement.period),
            })}
          >
            <Breadcrumbs.BackButton label={<FormattedMessage defaultMessage="Go back" />} />
          </Link>
        ) : null
      }
    >
      <StatementPeriodSelector
        frequency={statement.plan?.frequency}
        isForecast={isForecastedView}
        period={statement.period}
        plan={statement.plan}
        user={statement.user}
      />

      {/* Only display the plan select if there are more than one plan or if no plan selected (shouldn't happen lol) */}
      {proposedPlans.length > 1 || !statement?.plan.id ? (
        <Breadcrumbs.SelectItem
          options={proposedPlans}
          placeholder={formatMessage({ defaultMessage: 'Select a plan' })}
          title={<FormattedMessage defaultMessage="Select plan" />}
          value={statement?.plan.id || null}
          onChange={onChangePlan}
        />
      ) : (
        <Breadcrumbs.TextItem>
          <Fragment>{statement?.plan.name || <FormattedMessage defaultMessage="Plan name" />}</Fragment>
        </Breadcrumbs.TextItem>
      )}

      {hasSeveralUsers ? (
        <Breadcrumbs.SelectItem
          options={usersOptions}
          placeholder={formatMessage({ defaultMessage: 'Select a user' })}
          title={<FormattedMessage defaultMessage="Select user" />}
          value={statement?.user.id || null}
          onChange={onChangeUser}
        />
      ) : (
        <Breadcrumbs.TextItem>
          <Fragment>{formatUserFullName(statement.user)}</Fragment>
        </Breadcrumbs.TextItem>
      )}
    </Breadcrumbs>
  );
};

export const StatementDetailHeaderBreadcrumbs = memo(StatementDetailHeaderBreadcrumbsBase);
