import { IconChecks } from '@tabler/icons-react';
import { times } from 'lodash';
import { memo, useCallback, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage } from 'react-intl';

import { type Notification } from '@amalia/core/types';
import { Button, Popover } from '@amalia/design-system/components';
import { type UsersMap } from '@amalia/tenants/users/types';

import { NotificationListItemSkeleton } from '../notification-list-item/notification-list-item-skeleton/NotificationListItemSkeleton';
import { NotificationListItem } from '../notification-list-item/NotificationListItem';

import * as styles from './NotificationsPopover.styles';

type NotificationsPopoverProps = {
  readonly notifications: Notification[];
  readonly usersMap: UsersMap;
  readonly hasNextPage: boolean;
  readonly isLoading: boolean;
  readonly onFetchNextPage: () => void;
  readonly onClose: () => void;
  readonly onClickMarkAllAsRead: () => void;
};

export const NotificationsPopover = memo(function NotificationsPresentation({
  notifications,
  usersMap,
  hasNextPage,
  isLoading,
  onFetchNextPage,
  onClose,
  onClickMarkAllAsRead,
}: NotificationsPopoverProps) {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const getScrollParent = useCallback(() => scrollContainerRef.current, []);

  // Proxy to remove parameters.
  const handleLoadMore = useCallback(() => onFetchNextPage(), [onFetchNextPage]);

  return (
    <Popover.Layout css={styles.popoverLayout}>
      <Popover.Header>
        <Popover.Title>
          <FormattedMessage defaultMessage="Notifications" />
        </Popover.Title>

        {!!notifications.length && (
          <Popover.HeaderActions>
            <Button
              icon={<IconChecks />}
              size={Button.Size.SMALL}
              variant={Button.Variant.PRIMARY_TEXT}
              onClick={onClickMarkAllAsRead}
            >
              <FormattedMessage defaultMessage="Mark all as read" />
            </Button>
          </Popover.HeaderActions>
        )}
      </Popover.Header>

      <Popover.Body
        ref={scrollContainerRef}
        css={styles.popoverBody}
      >
        <InfiniteScroll
          getScrollParent={getScrollParent}
          hasMore={!!hasNextPage && !isLoading}
          loadMore={handleLoadMore}
          pageStart={1}
          threshold={10}
          useWindow={false}
        >
          {notifications.length || isLoading ? (
            <div css={styles.notificationsList}>
              {notifications.map((notification) => (
                <NotificationListItem
                  key={notification.id}
                  notification={notification}
                  usersMap={usersMap}
                  onClick={onClose}
                />
              ))}

              {!!isLoading && times(10).map((index) => <NotificationListItemSkeleton key={index} />)}
            </div>
          ) : (
            <FormattedMessage defaultMessage="You have no notifications." />
          )}
        </InfiniteScroll>
      </Popover.Body>
    </Popover.Layout>
  );
});
