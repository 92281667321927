import { css } from '@emotion/react';

export const container = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const gridContainer = css`
  padding: 16px 24px 24px;
`;
