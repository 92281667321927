import { parse, stringify } from 'qs';
import { useCallback, useMemo, useRef } from 'react';
import { type Location, useLocation, useNavigate } from 'react-router-dom';

export type UrlState = string | null | undefined;

export type UseUrlStateOptions = {
  navigateMode?: 'push' | 'replace';
};

export type UseUrlStateValue<T extends UrlState> = [
  state: T,
  setState: (newValue: T | ((prev: T) => T), options?: Partial<UseUrlStateOptions>) => void,
];

export const useUrlState = <T extends UrlState>(
  key: string,
  defaultValue?: T | (() => T),
  { navigateMode = 'push' }: UseUrlStateOptions = {},
): UseUrlStateValue<T> => {
  const { search, hash, state: locationState } = useLocation() as Location<unknown>;
  const navigate = useNavigate();

  const initialStateRef = useRef(typeof defaultValue === 'function' ? defaultValue() : defaultValue);

  const parsedQs = useMemo(() => parse(search, { ignoreQueryPrefix: true }), [search]);

  const urlState = useMemo(
    () => ({
      [key]: initialStateRef.current,
      ...parsedQs,
    }),
    [key, parsedQs],
  );

  const setState: UseUrlStateValue<T>[1] = useCallback(
    (newValue, { navigateMode: effectiveNavigateMode = navigateMode } = {}) => {
      const newUrlState = {
        ...urlState,
        [key]: (typeof newValue === 'function' ? newValue(urlState[key] as T) : newValue) ?? undefined,
      };

      navigate(
        {
          hash,
          search: stringify(newUrlState),
        },
        {
          replace: effectiveNavigateMode === 'replace',
          state: locationState,
        },
      );
    },
    [navigate, navigateMode, hash, locationState, key, urlState],
  );

  return [urlState[key] as T, setState];
};
