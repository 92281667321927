import { useRef } from 'react';

import { useDebouncedValue } from '../use-debounced-value/useDebouncedValue';
import { useUpdateEffect } from '../use-update-effect/useUpdateEffect';

export const useDebouncedOnChange = <TValue>(
  value: TValue,
  onChange?: (value: TValue) => void,
  delayMs: number = 300,
) => {
  const debouncedValue = useDebouncedValue<TValue>(value, delayMs);

  // Save callback to ref to avoid infinite loop on the useUpdateEffect.
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  // Update the content when the value changes.
  useUpdateEffect(() => {
    onChangeRef.current?.(debouncedValue);
  }, [debouncedValue]);
};
