import { without } from 'lodash';
import { useMemo } from 'react';

import { UserExternalIdSourceItem, UserExternalIdSourceLabel } from '@amalia/data-capture/connectors/assets';
import { type SelectOption } from '@amalia/design-system/components';
import { UserExternalIdSource } from '@amalia/tenants/users/types';

export type ExternalIdSourceOption = SelectOption<UserExternalIdSource>;

// Alphabetically sort the sources, but put OTHERSOURCE & NONE last
const sourcesOrder = without(
  Object.values(UserExternalIdSource),
  UserExternalIdSource.OTHERSOURCE,
  UserExternalIdSource.NONE,
)
  .toSorted((a, b) => a.localeCompare(b))
  .concat([UserExternalIdSource.OTHERSOURCE, UserExternalIdSource.NONE]);

export const useExternalIdSourceOptions = ({
  withoutValues = [],
  hideIconWhenSelected = false,
}: {
  withoutValues?: UserExternalIdSource[];
  hideIconWhenSelected?: boolean;
} = {}): ExternalIdSourceOption[] =>
  useMemo(
    () =>
      without(Object.values(UserExternalIdSource), ...withoutValues)
        .sort((a, b) => sourcesOrder.indexOf(a) - sourcesOrder.indexOf(b))
        .map((externalIdSource) => ({
          filterLabel: externalIdSource,
          value: externalIdSource,
          label: <UserExternalIdSourceItem externalIdSource={externalIdSource} />,
          ...(hideIconWhenSelected && {
            valueLabel: <UserExternalIdSourceLabel externalIdSource={externalIdSource} />,
          }),
        })),
    [withoutValues, hideIconWhenSelected],
  );
