import { http } from '@amalia/core/http/client';
import { type Period } from '@amalia/payout-definition/periods/types';

export async function saGetPeriods(companyId: string): Promise<Period[]> {
  const { data } = await http.get<Period[]>('/sa_periods', {
    params: {
      companyId,
    },
  });
  return data;
}
