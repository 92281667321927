import { EditorContent, type EditorContentProps } from '@tiptap/react';
import clsx from 'clsx';
import { memo } from 'react';

import { FormField, useFormFieldProps, type UseFormFieldPropsOptions } from '@amalia/design-system/components';
import { type MergeAll } from '@amalia/ext/typescript';

import * as styles from './FormulaEditorContent.styles';

export type FormulaEditorContentProps = MergeAll<[Omit<EditorContentProps, 'ref'>, UseFormFieldPropsOptions]>;

export const FormulaEditorContent = memo(function FormulaEditorContent(props: FormulaEditorContentProps) {
  const {
    formFieldProps,
    otherProps: { spellCheck = false, ...otherProps },
  } = useFormFieldProps(props);

  return (
    <FormField
      {...formFieldProps}
      size={FormField.Size.MEDIUM}
    >
      <EditorContent
        {...otherProps}
        className={clsx(otherProps.className, { [styles.HAS_ERROR_CLASSNAME]: formFieldProps.error })}
        css={styles.formulaEditor}
        spellCheck={spellCheck}
      />
    </FormField>
  );
});
