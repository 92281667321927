import { Fragment, memo } from 'react';
import { useIntl } from 'react-intl';

import { type ValueOrAttributeNumberForm, type ValueOrAttributeForm } from '@amalia/amalia-lang/formula/form/types';
import {
  type AttributeValue,
  ValueOrAttributeType,
  type FormulaBuilderFunctionCategory,
} from '@amalia/amalia-lang/formula/types';
import {
  Input,
  type InputProps,
  RadioButtonGroup,
  type RadioButtonGroupProps,
  type RadioButtonOptionShape,
} from '@amalia/design-system/components';

import { type AttributesOptions } from '../../hooks/use-attributes-options/useAttributesOptions';
import { useFormatFunctionBlockValue } from '../../hooks/use-format-function-block-value/useFormatFunctionBlockValue';
import { AttributeSelector } from '../attribute-selector/AttributeSelector';

export type PopoverValueOrAttributeSelectorProps = {
  readonly arg: ValueOrAttributeForm | ValueOrAttributeNumberForm;
  readonly attributesOptions: AttributesOptions;
  readonly valueOrAttributeOptions: RadioButtonOptionShape<ValueOrAttributeType>[];
  readonly onChangeValueOrAttribute: Required<RadioButtonGroupProps<ValueOrAttributeType>>['onChange'];
  readonly onChangeManualValue: Required<InputProps>['onChange'];
  readonly onChangeAttributeValue: (attribute: AttributeValue) => void;
  readonly inputType?: InputProps['type'];
  readonly leftIcon?: InputProps['leftIcon'];
  readonly conditionCategory: FormulaBuilderFunctionCategory;
};

export const PopoverValueOrAttributeSelector = memo(function PopoverValueOrAttributeSelector({
  arg,
  attributesOptions,
  valueOrAttributeOptions,
  onChangeValueOrAttribute,
  onChangeManualValue,
  onChangeAttributeValue,
  inputType = 'text',
  leftIcon,
  conditionCategory,
}: PopoverValueOrAttributeSelectorProps) {
  const { formatMessage } = useIntl();
  const value = useFormatFunctionBlockValue(
    conditionCategory,
    arg.type === ValueOrAttributeType.VALUE ? arg.value : null,
  );
  return (
    <Fragment>
      <div>
        <RadioButtonGroup
          name="value-or-field"
          options={valueOrAttributeOptions}
          size={RadioButtonGroup.Size.SMALL}
          value={arg.type}
          onChange={onChangeValueOrAttribute}
        />
      </div>

      {arg.type === ValueOrAttributeType.VALUE && (
        <Input
          leftIcon={leftIcon}
          placeholder={formatMessage({ defaultMessage: 'Type manual value' })}
          size={Input.Size.SMALL}
          type={inputType}
          value={value}
          onChange={onChangeManualValue}
        />
      )}

      {arg.type === ValueOrAttributeType.ATTRIBUTE && (
        <AttributeSelector
          options={attributesOptions}
          selectedAttribute={arg}
          onChange={onChangeAttributeValue}
        />
      )}
    </Fragment>
  );
});
