import { keyBy } from 'lodash';
import { createSelector } from 'reselect';

import { type TracingTypes } from '@amalia/core/types';

import { type RootState } from '../types';

export const selectCustomObjectDefinitionsMap = (state: RootState) => state.customObjectDefinitions.map;

export const selectCustomObjectDefinitionsList = createSelector(
  [selectCustomObjectDefinitionsMap],
  (map) => map && Object.values(map).sort((a, b) => a.name.localeCompare(b.name)),
);

export const selectCustomObjectDefinitionsMapById = createSelector([selectCustomObjectDefinitionsMap], (map) =>
  keyBy(Object.values(map || {}), 'id'),
);

export const selectRelationshipsMap = (state: RootState) => state.customObjectDefinitions.relationshipsMap;

export const selectRelationshipsList = createSelector([selectRelationshipsMap], (map) => Object.values(map || {}));

export const selectIsCustomObjectDefinitionsLoading = (state: RootState) => !!state.customObjectDefinitions.isLoading;

export const selectCustomObjectDefinitionsProperties = createSelector(
  [selectCustomObjectDefinitionsList],
  (customObjectDefinitions) =>
    (customObjectDefinitions || []).flatMap((definition) =>
      Object.entries(definition.properties).map(
        ([propertyMachineName, property]): TracingTypes.Property => ({
          ...property!,
          id: propertyMachineName,
          name: property!.name || propertyMachineName,
          definition,
          format: property!.format,
        }),
      ),
    ),
);
