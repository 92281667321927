import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { assert } from '@amalia/ext/typescript';
import { type TeamRole } from '@amalia/tenants/assignments/teams/types';

import { TeamRoleLabels } from './roles.messages';

export type TeamRoleLabelProps = {
  readonly teamRole: TeamRole;
};

export const TeamRoleLabel = memo(function TeamRoleLabel({ teamRole }: TeamRoleLabelProps) {
  assert(teamRole in TeamRoleLabels, `Invalid team role: ${teamRole}`);

  return <FormattedMessage {...TeamRoleLabels[teamRole]} />;
});
