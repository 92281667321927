import moment from 'moment';

import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.dateBetween, AmaliaFunctionCategory.DATES);

func.nbParamsRequired = 3;

func.description = 'Return true if a date is between two dates (both dates are included)';

func.params = [
  {
    name: 'checkDate',
    description:
      'Date to check if in the interval: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD") ',
    validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
    validTokenValues: {
      [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
    },
    validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
  },
  {
    name: 'startDate',
    description:
      'Start of the interval: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
    validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
    validTokenValues: {
      [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
    },
    validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
  },
  {
    name: 'endDate',
    description:
      'End of the interval: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
    validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
    validTokenValues: {
      [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
    },
    validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
  },
];

func.examples = [
  {
    desc: 'Returns true',
    formula: 'dateBetween(toDate("2023-10-16"), toDate("2023-10-01"), toDate("2023-11-04"))' as AmaliaFormula,
    result: true,
  },
  {
    desc: 'Returns true',
    formula: 'dateBetween(toDate("2023-04-01"), toDate("2023-04-01"), toDate("2023-04-30"))' as AmaliaFormula,
    result: true,
  },
  {
    desc: 'Returns true if Close Date is between the start and the end of the statement',
    formula: 'dateBetween(opportunity.closeDate, statementPeriod.startDate, statementPeriod.endDate)' as AmaliaFormula,
  },
];

func.exec = (
  candidateDate: moment.MomentInput | string,
  beginDate: moment.MomentInput | string,
  endDate: moment.MomentInput | string,
): boolean | null =>
  moment(candidateDate, 'X').isBetween(moment(beginDate, 'X'), moment(endDate, 'X'), undefined, '[]');

export default func;
