import * as Yup from 'yup';

import { CustomReportAggregationOperation } from '@amalia/reporting/custom-reports/shared';
import { ChartType, type DashboardChartConfiguration } from '@amalia/reporting/dashboards-v2/types';

const filtersValidations = Yup.object().shape({
  periods: Yup.object().shape({
    isEnabled: Yup.boolean().required(),
    values: Yup.array().of(Yup.string()).required(),
  }),
  teams: Yup.object().shape({
    isEnabled: Yup.boolean().required(),
    values: Yup.array().of(Yup.string()).required(),
  }),
  users: Yup.object().shape({
    isEnabled: Yup.boolean().required(),
    values: Yup.array().of(Yup.string()).required(),
  }),
});

const commonValidations = Yup.object().shape({
  customReportId: Yup.string().required(),
  displaySettings: Yup.object().shape({
    filters: filtersValidations,
  }),
  name: Yup.string().required().min(2).max(100),
  type: Yup.string().oneOf(Object.values(ChartType)).required(),
});

export const kpiCardChartValidationSchema = commonValidations.concat(
  Yup.object().shape({
    displaySettings: Yup.object().shape({
      isDoughnutEnabled: Yup.boolean().required(),
      kpi: Yup.object().shape({
        aggregation: Yup.object().shape({
          operation: Yup.string().required(),
        }),
        identifier: Yup.string().required(),
      }),
    }),
  }),
);

export const simpleBarChartValidationSchema = commonValidations.concat(
  Yup.object().shape({
    displaySettings: Yup.object().shape({
      xAxis: Yup.object().shape({
        identifier: Yup.string().required(),
      }),
      yAxis: Yup.object().shape({
        aggregation: Yup.object().shape({
          operation: Yup.string().required(),
        }),
        identifier: Yup.string().required(),
        joins: Yup.array().of(Yup.string()),
      }),
    }),
  }),
);

export const formInitialValues: DashboardChartConfiguration<ChartType.SIMPLE_BAR_CHART> = {
  name: '',
  customReportId: '',
  type: ChartType.SIMPLE_BAR_CHART,
  displaySettings: {
    filters: {
      periods: {
        isEnabled: true,
        values: [],
      },
      users: {
        isEnabled: true,
        values: [],
      },
      teams: {
        isEnabled: true,
        values: [],
      },
    },
    xAxis: {
      identifier: 'periodStartDate',
    },
    yAxis: {
      aggregation: { operation: CustomReportAggregationOperation.sum },
      identifier: '',
      joins: [],
    },
  },
};
