import { type Dispatch, type SetStateAction, createContext, useContext } from 'react';

import { assert } from '@amalia/ext/typescript';

export type LayoutContextValue = {
  portalElement: HTMLElement | null;
  setIsPortalActive: Dispatch<SetStateAction<boolean>>;
};

export const LayoutContext = createContext<LayoutContextValue | null>(null);

export const useLayoutContext = (): LayoutContextValue => {
  const context = useContext(LayoutContext);
  assert(context, 'useLayoutContext must be used within a <LayoutContext.Provider>');
  return context;
};
