import { type ComponentProps, type JSXElementConstructor, memo, type ReactElement } from 'react';
import { useInView } from 'react-intersection-observer';

import { type AlertBannerProps } from '../../feedback/alert-banner/AlertBanner';

import { PageHeaderRow, type PageHeaderRowProps } from './page-header-row/PageHeaderRow';
import * as styles from './PageHeader.styles';

type PageHeaderRowElement =
  | ReactElement<PageHeaderRowProps, JSXElementConstructor<PageHeaderRowProps>>
  | false
  | null
  | undefined;

export type PageHeaderProps = Omit<ComponentProps<'div'>, 'children'> & {
  /**
   * The rows of the page header.
   */
  readonly children?: PageHeaderRowElement | PageHeaderRowElement[];

  readonly stickyRow?: ReactElement<PageHeaderRowProps, JSXElementConstructor<PageHeaderRowProps>>;
  readonly alert?: ReactElement<AlertBannerProps, JSXElementConstructor<AlertBannerProps>>;
};

const PageHeaderBase = function PageHeader({ children = undefined, stickyRow, alert, ...props }: PageHeaderProps) {
  const { ref, inView } = useInView({ threshold: 0.25 });
  return (
    <div
      {...props}
      ref={ref}
    >
      <div css={styles.pageHeaderContainer}>{children}</div>
      {!!alert && <div css={styles.alertContainer}>{alert}</div>}
      {!inView && stickyRow ? <div css={styles.pageHeaderContainerSticky}>{stickyRow}</div> : null}
    </div>
  );
};

export const PageHeader = Object.assign(memo(PageHeaderBase), {
  Row: PageHeaderRow,
});
