import { defineMessages } from 'react-intl';

import {
  CustomReportAggregationOperation,
  CustomReportSourceIdentifier,
} from '@amalia/reporting/custom-reports/shared';

export const CustomReportAggregationLabel = defineMessages<CustomReportAggregationOperation>({
  [CustomReportAggregationOperation.sum]: { defaultMessage: 'Sum' },
  [CustomReportAggregationOperation.avg]: { defaultMessage: 'Average' },
  [CustomReportAggregationOperation.weighted_average]: { defaultMessage: 'Weighted average' },
  [CustomReportAggregationOperation.min]: { defaultMessage: 'Minimum' },
  [CustomReportAggregationOperation.max]: { defaultMessage: 'Maximum' },
  [CustomReportAggregationOperation.concat]: { defaultMessage: 'Concat' },
  [CustomReportAggregationOperation.concat_distinct]: { defaultMessage: 'Concat distinct' },
  [CustomReportAggregationOperation.first]: { defaultMessage: 'First' },
  [CustomReportAggregationOperation.all]: { defaultMessage: 'All' },
  [CustomReportAggregationOperation.some]: { defaultMessage: 'Some' },
});

export const CustomReportAggregationShortLabel = defineMessages<CustomReportAggregationOperation>({
  [CustomReportAggregationOperation.sum]: { description: 'Short Aggregation Label', defaultMessage: 'Sum' },
  [CustomReportAggregationOperation.avg]: { description: 'Short Aggregation Label', defaultMessage: 'Avg' },
  [CustomReportAggregationOperation.weighted_average]: {
    description: 'Short Aggregation Label',
    defaultMessage: 'W. Avg',
  },
  [CustomReportAggregationOperation.min]: { description: 'Short Aggregation Label', defaultMessage: 'Min' },
  [CustomReportAggregationOperation.max]: { description: 'Short Aggregation Label', defaultMessage: 'Max' },
  [CustomReportAggregationOperation.concat]: { description: 'Short Aggregation Label', defaultMessage: 'Concat' },
  [CustomReportAggregationOperation.concat_distinct]: {
    description: 'Short Aggregation Label',
    defaultMessage: 'Concat Unique',
  },
  [CustomReportAggregationOperation.first]: { description: 'Short Aggregation Label', defaultMessage: '1st' },
  [CustomReportAggregationOperation.all]: { description: 'Short Aggregation Label', defaultMessage: 'All' },
  [CustomReportAggregationOperation.some]: { description: 'Short Aggregation Label', defaultMessage: 'Some' },
});

export const CustomReportAggregationDescription = defineMessages<CustomReportAggregationOperation>({
  [CustomReportAggregationOperation.sum]: { defaultMessage: 'Sums all values' },
  [CustomReportAggregationOperation.avg]: { defaultMessage: 'Take the average of values' },
  [CustomReportAggregationOperation.weighted_average]: { defaultMessage: 'Weighted average on another column' },
  [CustomReportAggregationOperation.min]: { defaultMessage: 'Take the minimum of all values' },
  [CustomReportAggregationOperation.max]: { defaultMessage: 'Take the maximum of all values' },
  [CustomReportAggregationOperation.concat]: { defaultMessage: 'Concatenate all values' },
  [CustomReportAggregationOperation.concat_distinct]: {
    defaultMessage: 'Concatenate all values, not taking duplicates',
  },
  [CustomReportAggregationOperation.first]: { defaultMessage: 'Take the first value encountered' },
  [CustomReportAggregationOperation.all]: { defaultMessage: 'True if each value is true' },
  [CustomReportAggregationOperation.some]: { defaultMessage: 'True if at least one is true' },
});

export const customReportSourceIdentifierLabel = defineMessages<CustomReportSourceIdentifier>({
  [CustomReportSourceIdentifier.RULE_METRIC]: {
    defaultMessage: 'Rule metrics',
    description: 'Custom Report source',
  },
  [CustomReportSourceIdentifier.RECORD_METRIC]: {
    defaultMessage: 'Record metrics',
    description: 'Custom Report source',
  },
  [CustomReportSourceIdentifier.RULE_METRIC_FORECAST]: {
    defaultMessage: 'Rule metrics forecast',
    description: 'Custom Report source',
  },
  [CustomReportSourceIdentifier.RECORD_METRIC_FORECAST]: {
    defaultMessage: 'Record metrics forecast',
    description: 'Custom Report source',
  },
  [CustomReportSourceIdentifier.USER]: {
    defaultMessage: 'Directory',
    description: 'Custom Report source',
  },
  [CustomReportSourceIdentifier.PAYMENT]: {
    defaultMessage: 'Payments',
    description: 'Custom Report source',
  },
  [CustomReportSourceIdentifier.PERIOD]: {
    defaultMessage: 'Periods',
    description: 'Custom Report source',
  },
  [CustomReportSourceIdentifier.CAPTURED_RECORD]: {
    defaultMessage: 'Data',
    description: 'Custom Report source',
  },
  [CustomReportSourceIdentifier.STATEMENT]: {
    defaultMessage: 'Statement',
    description: 'Custom Report source',
  },
});
