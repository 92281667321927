import { type CSSProperties } from 'react';

import { AmaliaFunction } from '@amalia/amalia-lang/formula/evaluate/shared';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { AMALIA_OBJECTS_HIGHLIGHT_COLOR } from '@amalia/ext/mui/theme';
import { type Relationship } from '@amalia/payout-definition/plans/types';

import { IFormulaNode, FormulaNodeEnum } from './IFormulaNode';

const FunctionsEnum = AmaliaFunction.getFunctionsEnum();

export class FunctionFormulaNode extends IFormulaNode<keyof typeof FunctionsEnum> {
  public constructor(
    partialFormula: string,
    startIndex: number,
    nodeObject: keyof typeof FunctionsEnum,
    objectDefinition?: CustomObjectDefinition,
    relationship?: Relationship,
  ) {
    super(partialFormula, startIndex, FormulaNodeEnum.FUNCTION, nodeObject, objectDefinition, relationship);
  }

  public getStyles(): CSSProperties {
    return {
      color: AMALIA_OBJECTS_HIGHLIGHT_COLOR.function,
    };
  }

  public getLabel(): string {
    return this.nodeObject;
  }
}
