import { Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { ChangelogView } from '@amalia/amalia-meta/changelog/views/changelog';
import { PlansRoutes, TeamRoutes } from '@amalia/assignments/views/group';
import { QuotasListView } from '@amalia/assignments/views/quotas/list';
import { QuotaValuesView } from '@amalia/assignments/views/quotas/values';
import { Layout } from '@amalia/core/layout/components';
import { routes } from '@amalia/core/routes';
import { CustomObjectRoutes, OAuthCallback } from '@amalia/data-capture/records/views/group';
import { OverwritesRoutes } from '@amalia/data-correction/overwrites/views/overwrites';
import { AmaliaHead } from '@amalia/design-system/ext';
import { RootRoutes, UseParams } from '@amalia/ext/react-router-dom';
import { checkLocalStorageOnLoad, useFeatureFlag } from '@amalia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum, userProfileSubject } from '@amalia/kernel/auth/shared';
import { AbilityRouteProtector, useCurrentUser } from '@amalia/kernel/auth/state';
import { OmoliotPromptModal, useOmolioPromptModalContext } from '@amalia/omolio/components';
import { CommissionReportView } from '@amalia/payout-calculation/commission-report/views/group';
import { DataExports, StatementsRoutes } from '@amalia/payout-calculation/statements/views/group';
import { TodosView } from '@amalia/payout-collaboration/todos/views/todos';
import { DesignerRoutes } from '@amalia/payout-definition/designer/views/group';
import { PlanAgreementDetailsRoutes } from '@amalia/plan-agreements/views/details';
import { PlanAgreementEditRoutes } from '@amalia/plan-agreements/views/edit';
import { PlanAgreementsListRoutes } from '@amalia/plan-agreements/views/list';
import { PlanAgreementPreviewRoutes } from '@amalia/plan-agreements/views/preview';
import { CustomReportRoutes } from '@amalia/reporting/custom-reports/views';
import { DashboardsV2DetailsRoutes } from '@amalia/reporting/dashboards-v2/views/details';
import { DashboardsV2ListRoutes } from '@amalia/reporting/dashboards-v2/views/list';
import { DashboardView, HomeRedirectView, HomeView } from '@amalia/reporting/homepage/views/group';
import { SuperAdminRoutes } from '@amalia/superadmin/views';
import { CompanySettings } from '@amalia/tenants/companies/settings/layout';
import { IntegrationOauthCallback } from '@amalia/tenants/companies/settings/views/group';
import { CompanyFeatureFlags } from '@amalia/tenants/companies/types';
import { AuditListView } from '@amalia/tenants/monitoring/audit/views/list';
import { tenantUsersRoutes } from '@amalia/tenants/users/profile/shared';
import { DirectoryView } from '@amalia/tenants/users/profile/views/directory';
import { NewInvitationPage } from '@amalia/tenants/users/profile/views/group';
import { MemberProfileView } from '@amalia/tenants/users/profile/views/member';
import { CurrentUserProfileView } from '@amalia/tenants/users/profile/views/profile';

const App = function App() {
  const { data: user } = useCurrentUser();
  const { formatMessage } = useIntl();

  const { isFeatureEnabled: isDashboardFFEnabled } = useFeatureFlag(CompanyFeatureFlags.DASHBOARDS);
  const { isOmolioPromptModalOpen } = useOmolioPromptModalContext();

  useEffect(
    // Clear localstorage if company is different
    () => user && checkLocalStorageOnLoad(user),
    [user],
  );

  return (
    <Fragment>
      <RootRoutes>
        <Route
          path={routes.AUTH_CONNECTOR_CALLBACK}
          element={
            <Fragment>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Connection' })} />
              <OAuthCallback />
            </Fragment>
          }
        />

        <Route
          path={routes.AUTH_INTEGRATION_CALLBACK}
          element={
            <Fragment>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Connection' })} />
              <IntegrationOauthCallback />
            </Fragment>
          }
        />

        <Route
          path={routes.HOME}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.RepHome)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Home' })} />
              <HomeView />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.DATA_EXPORT}
          element={
            <Fragment>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Data export' })} />
              <DataExports />
            </Fragment>
          }
        />

        <Route
          element={<DesignerRoutes />}
          path={`${routes.DESIGNER}/*`}
        />

        <Route
          element={<PlansRoutes />}
          path={`${routes.PLANS}/*`}
        />

        <Route
          element={<TeamRoutes />}
          path={`${routes.TEAM_LIST}/*`}
        />

        <Route
          path={routes.QUOTAS}
          element={
            <Layout currentPage="quotas">
              <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Quota)}>
                <AmaliaHead title={formatMessage({ defaultMessage: 'Quotas' })} />
                <QuotasListView />
              </AbilityRouteProtector>
            </Layout>
          }
        />

        <Route
          path={`${routes.QUOTAS_VALUE}`}
          element={
            <Layout currentPage="quotas">
              <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Quota)}>
                <AmaliaHead title={formatMessage({ defaultMessage: 'Quota values' })} />
                <QuotaValuesView />
              </AbilityRouteProtector>
            </Layout>
          }
        />

        <Route
          path={`${routes.AUDIT}/*`}
          element={
            <Layout currentPage="audit">
              <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Audit)}>
                <AmaliaHead title={formatMessage({ defaultMessage: 'Audit' })} />
                <AuditListView />
              </AbilityRouteProtector>
            </Layout>
          }
        />

        <Route
          element={<PlanAgreementsListRoutes />}
          path={routes.PLAN_AGREEMENTS_LIST}
        />

        <Route
          element={<PlanAgreementEditRoutes />}
          path={routes.PLAN_AGREEMENT_EDIT}
        />

        <Route
          element={<PlanAgreementDetailsRoutes />}
          path={routes.PLAN_AGREEMENT_ASSIGNMENT}
        />

        {/* For backward compatibility with old URL */}
        <Route
          element={<PlanAgreementDetailsRoutes />}
          path={routes.PLAN_AGREEMENT_ASSIGNMENT_OLD}
        />

        <Route
          element={<PlanAgreementPreviewRoutes />}
          path={routes.PLAN_AGREEMENT_PREVIEW}
        />

        <Route
          path={routes.CHANGELOG}
          element={
            <Layout>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Changelog' })} />
              <ChangelogView />
            </Layout>
          }
        />

        <Route
          element={<OverwritesRoutes />}
          path={`${routes.OVERWRITES}/*`}
        />

        <Route
          path={routes.COMMISSION_REPORT}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Payment)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Payments' })} />
              <CommissionReportView />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={tenantUsersRoutes.PROFILE_PAGE}
          element={
            <Layout currentPage="directory">
              <CurrentUserProfileView />
            </Layout>
          }
        />

        <Route
          path={tenantUsersRoutes.MEMBER_PAGE}
          element={
            <UseParams<{ memberId: string }>>
              {({ memberId }) => (
                <Layout currentPage="directory">
                  <AbilityRouteProtector
                    can={(ability) => ability.can(ActionsEnum.view, userProfileSubject({ id: memberId }))}
                  >
                    <MemberProfileView />
                  </AbilityRouteProtector>
                </Layout>
              )}
            </UseParams>
          }
        />

        <Route
          path={tenantUsersRoutes.ADD_MEMBER_PAGE}
          element={
            <Layout currentPage="directory">
              <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.modify, userProfileSubject())}>
                <AmaliaHead title={formatMessage({ defaultMessage: 'New directory' })} />
                <NewInvitationPage />
              </AbilityRouteProtector>
            </Layout>
          }
        />

        <Route
          path={tenantUsersRoutes.DIRECTORY_PAGE}
          element={
            <Layout currentPage="directory">
              <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view_directory, SubjectsEnum.Company)}>
                <AmaliaHead title={formatMessage({ defaultMessage: 'Directory' })} />
                <DirectoryView />
              </AbilityRouteProtector>
            </Layout>
          }
        />

        <Route
          element={<CustomReportRoutes />}
          path={`${routes.CUSTOM_REPORT_LIST}/*`}
        />

        <Route
          path={`${routes.DATA}/*`}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Data)}>
              <CustomObjectRoutes />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={`${routes.COMPANY_SETTINGS}/*`}
          element={
            <AbilityRouteProtector
              can={(ability) =>
                ability.can(ActionsEnum.view_rates, SubjectsEnum.Company) ||
                ability.can(ActionsEnum.view_directory, SubjectsEnum.Company) ||
                ability.can(ActionsEnum.view_settings, SubjectsEnum.Company)
              }
            >
              <CompanySettings />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.TODOS}
          element={
            <Layout>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Todos' })} />
              <TodosView />
            </Layout>
          }
        />

        <Route
          path={`${routes.SUPERADMIN}/*`}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
              <SuperAdminRoutes />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.DASHBOARD}
          element={
            <AbilityRouteProtector can={() => isDashboardFFEnabled}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Dashboard' })} />
              <DashboardView />
            </AbilityRouteProtector>
          }
        />

        <Route
          element={<DashboardsV2ListRoutes />}
          path={`${routes.DASHBOARDS_V2}`}
        />

        <Route
          element={<DashboardsV2DetailsRoutes />}
          path={`${routes.DASHBOARD_V2}`}
        />

        <Route
          element={<StatementsRoutes />}
          path={`${routes.STATEMENTS}/*`}
        />

        <Route
          element={<StatementsRoutes />}
          path={`${routes.FORECASTS}/*`}
        />

        <Route
          element={<HomeRedirectView />}
          path="*"
        />
      </RootRoutes>
      {!!isOmolioPromptModalOpen && <OmoliotPromptModal />}
    </Fragment>
  );
};

export default App;
