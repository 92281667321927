import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import moment from 'moment';
import { memo } from 'react';
import { type ReactDatePickerProps, type ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { FormattedMessage, useIntl } from 'react-intl';

import { type MergeAll } from '@amalia/ext/typescript';

import { IconButton } from '../../../general/icon-button/IconButton';
import { Typography } from '../../../general/typography/Typography';

import * as styles from './DatePickerBaseHeader.styles';

const YEARS_IN_PERIOD = 12;

export type DatePickerBaseHeaderProps<TWithRange extends boolean | undefined = undefined> = MergeAll<
  [
    ReactDatePickerCustomHeaderProps,
    Pick<ReactDatePickerProps<TWithRange, false>, 'showMonthYearPicker' | 'showQuarterYearPicker' | 'showYearPicker'>,
  ]
>;

export const DatePickerBaseHeader = memo(function DatePickerBaseHeader({
  decreaseMonth,
  decreaseYear,
  increaseMonth,
  increaseYear,
  prevMonthButtonDisabled,
  prevYearButtonDisabled,
  nextMonthButtonDisabled,
  nextYearButtonDisabled,
  date,
  showMonthYearPicker,
  showQuarterYearPicker,
  showYearPicker,
}: DatePickerBaseHeaderProps) {
  const isYearPicker = showMonthYearPicker || showQuarterYearPicker || showYearPicker;

  const endPeriod = Math.ceil(date.getFullYear() / YEARS_IN_PERIOD) * YEARS_IN_PERIOD;
  const startPeriod = endPeriod - (YEARS_IN_PERIOD - 1);

  const { formatMessage } = useIntl();

  return (
    <div css={styles.datePickerBaseHeader}>
      <IconButton
        disabled={isYearPicker ? prevYearButtonDisabled : prevMonthButtonDisabled}
        icon={<IconChevronLeft />}
        label={
          isYearPicker
            ? showYearPicker
              ? formatMessage({ defaultMessage: 'Previous year period' })
              : formatMessage({ defaultMessage: 'Previous year' })
            : formatMessage({ defaultMessage: 'Previous month' })
        }
        onClick={isYearPicker ? decreaseYear : decreaseMonth}
      />

      <Typography variant={Typography.Variant.BODY_BASE_BOLD}>
        {isYearPicker ? (
          showYearPicker ? (
            <FormattedMessage
              defaultMessage="{startPeriod} - {endPeriod}"
              values={{ startPeriod, endPeriod }}
            />
          ) : (
            moment(date).format('YYYY')
          )
        ) : (
          moment(date).format('MMMM YYYY')
        )}
      </Typography>

      <IconButton
        disabled={isYearPicker ? nextYearButtonDisabled : nextMonthButtonDisabled}
        icon={<IconChevronRight />}
        label={
          isYearPicker
            ? showYearPicker
              ? formatMessage({ defaultMessage: 'Next year period' })
              : formatMessage({ defaultMessage: 'Next year' })
            : formatMessage({ defaultMessage: 'Next month' })
        }
        onClick={isYearPicker ? increaseYear : increaseMonth}
      />
    </div>
  );
});
