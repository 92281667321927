import { css } from '@emotion/react';
import { IconCheck, IconRefresh, IconSend } from '@tabler/icons-react';
import { Fragment, memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { IconLoading, Table } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';
import { useInviteUser } from '@amalia/tenants/users/profile/state';
import { type UserProfile } from '@amalia/tenants/users/profile/types';

import { ConfirmInviteUserModal } from '../../../../invitation/confirm-modal/ConfirmInviteUserModal';

export type SendInvitationActionProps = {
  readonly user: Pick<UserProfile, 'email' | 'firstName' | 'id' | 'invitationSentAt' | 'lastName'>;
};

export const SendInvitationAction = memo(function SendInvitationAction({ user }: SendInvitationActionProps) {
  const { isConfirmModalOpen, setConfirmModalOpenTrue, setConfirmModalOpenFalse } = useBoolState(
    false,
    'confirmModalOpen',
  );
  const { mutate: sendInvitation, isPending, isSuccess } = useInviteUser(user);

  const onClickInvite = useCallback(() => {
    sendInvitation();
    setConfirmModalOpenFalse();
  }, [sendInvitation, setConfirmModalOpenFalse]);

  if (isPending) {
    return (
      <Table.Cell.IconAction
        disabled
        icon={<IconLoading icon={IconRefresh} />}
        label={<FormattedMessage defaultMessage="Sending invitation" />}
      />
    );
  }

  if (isSuccess) {
    return (
      <Table.Cell.IconAction
        disabled
        label={<FormattedMessage defaultMessage="Invitation sent" />}
        icon={
          <IconCheck
            css={(theme) => css`
              color: ${theme.ds.colors.success[500]};
            `}
          />
        }
      />
    );
  }

  return (
    <Fragment>
      <Table.Cell.IconAction
        icon={<IconSend />}
        label={
          user.invitationSentAt ? (
            <FormattedMessage defaultMessage="Resend invitation" />
          ) : (
            <FormattedMessage defaultMessage="Send invitation" />
          )
        }
        onClick={setConfirmModalOpenTrue}
      />
      {!!isConfirmModalOpen && (
        <ConfirmInviteUserModal
          isOpen={isConfirmModalOpen}
          user={user}
          onClose={setConfirmModalOpenFalse}
          onSubmit={onClickInvite}
        />
      )}
    </Fragment>
  );
});
