import { css } from '@emotion/react';
import { Fragment, memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Checkbox, Divider, FormLayout, Typography } from '@amalia/design-system/components';
import { type ChartDisplaySettings, type ChartType } from '@amalia/reporting/dashboards-v2/types';

import { DashboardChartConfigurationModalLabel } from '../../DashboardChartConfigurationModalLabel';

type SimpleBarChartFiltersProps = Pick<ChartDisplaySettings<ChartType>, 'filters'> & {
  readonly onFilterChange: (
    filterName: keyof ChartDisplaySettings<ChartType>['filters'],
    newChecked: boolean,
  ) => Promise<void>;
};

export const SimpleBarChartFilters = memo(function SimpleBarChartFilters({
  filters,
  onFilterChange,
}: SimpleBarChartFiltersProps) {
  const handleChangeFilterStatus = useCallback(
    (filterName: keyof ChartDisplaySettings<ChartType>['filters']) => async (newChecked: boolean) => {
      await onFilterChange(filterName, newChecked);
    },
    [onFilterChange],
  );

  return (
    <Fragment>
      <FormLayout.Group>
        <DashboardChartConfigurationModalLabel variant={Typography.Variant.BODY_BASE_MEDIUM}>
          <FormattedMessage defaultMessage="X-axis" />
        </DashboardChartConfigurationModalLabel>

        <Checkbox
          checked={!!filters.periods.isEnabled}
          label={<FormattedMessage defaultMessage="Show range filter on period" />}
          onChange={handleChangeFilterStatus('periods')}
        />
      </FormLayout.Group>

      <Divider.Horizontal
        css={(theme) => css`
          margin: 0 -32px;
          width: calc(100% + 64px);
          background-color: ${theme.ds.colors.gray[100]};
        `}
      />

      <FormLayout.Group>
        <DashboardChartConfigurationModalLabel variant={Typography.Variant.BODY_BASE_MEDIUM}>
          <FormattedMessage defaultMessage="Y-axis" />
        </DashboardChartConfigurationModalLabel>

        <Checkbox
          checked={!!filters.teams.isEnabled}
          label={<FormattedMessage defaultMessage="Show filter on teams" />}
          onChange={handleChangeFilterStatus('teams')}
        />

        <Checkbox
          checked={!!filters.users.isEnabled}
          label={<FormattedMessage defaultMessage="Show filter on users" />}
          onChange={handleChangeFilterStatus('users')}
        />
      </FormLayout.Group>
    </Fragment>
  );
});
