import { IconPlus, IconX } from '@tabler/icons-react';
import { noop } from 'lodash';
import { Fragment, memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import {
  addOperand,
  setLogicalOperator,
  removeOperand,
  type FormulaBuilderLogicalOperatorBlockForm,
  setOperand,
  type FormulaBuilderFunctionBlockNumberForm,
  type FormulaBuilderFunctionBlockPercentForm,
  type FormulaBuilderFunctionBlockCurrencyForm,
} from '@amalia/amalia-lang/formula/form/types';
import {
  FormulaBuilderBlockType,
  FormulaBuilderFunctionCategory,
  type FormulaBuilderLogicalOperatorType,
} from '@amalia/amalia-lang/formula/types';
import { IconButton, type RadioButtonGroupProps, RadioButtonSingle } from '@amalia/design-system/components';
import { useBoolState, useHover } from '@amalia/ext/react/hooks';

import { useAndOrOptions } from '../../hooks/use-and-or-options/useAndOrOptions';
import { CreateConditionForm, type CreateConditionFormProps } from '../create-condition-form/CreateConditionForm';
import { useFormulaBuilderContext } from '../formula-builder/FormulaBuilder.context';
import { FormulaConditionTag, type FormulaConditionTagProps } from '../formula-condition-tag/FormulaConditionTag';
import { FormulaConditionTagFormula } from '../formula-condition-tag-formula/FormulaConditionTagFormula';
import { FormulaConditionTagFunctionBoolean } from '../formula-condition-tag-function-boolean/FormulaConditionTagFunctionBoolean';
import { FormulaConditionTagFunctionDate } from '../formula-condition-tag-function-date/FormulaConditionTagFunctionDate';
import { FormulaConditionTagFunctionNumber } from '../formula-condition-tag-function-number/FormulaConditionTagFunctionNumber';
import { FormulaConditionTagFunctionString } from '../formula-condition-tag-function-string/FormulaConditionTagFunctionString';
import { FormulaConditionTagFunctionUser } from '../formula-condition-tag-function-user/FormulaConditionTagFunctionUser';

import { RemoveConditionGroupModal } from './formula-condition-group-modal/RemoveConditionGroupModal';
import * as styles from './FormulaConditionGroup.styles';

export type FormulaConditionGroupProps = {
  /** Operator condition. */
  readonly condition: FormulaBuilderLogicalOperatorBlockForm;
  /** Path to node inside builder. */
  readonly path: string;
  /** Change handler. */
  readonly onChange?: (updatedCondition: FormulaBuilderLogicalOperatorBlockForm) => void;
  /** Delete handler. */
  readonly onDelete?: (conditionId: FormulaBuilderLogicalOperatorBlockForm['id']) => void;
};

export const FormulaConditionGroup = memo(function FormulaConditionGroup({
  condition,
  path,
  onChange = noop,
  onDelete = noop,
}: FormulaConditionGroupProps) {
  const { setActiveConditionId } = useFormulaBuilderContext();
  const { formatMessage } = useIntl();
  const andOrOptions = useAndOrOptions();
  const [isAndOrOptionsActive, andOrOptionsActiveProps] = useHover();

  const { isOperandPopoverOpen, setOperandPopoverOpenFalse, setOperandPopoverOpen } = useBoolState(
    false,
    'operandPopoverOpen',
  );

  const { isGroupDeletionModalOpen, setGroupDeletionModalOpenTrue, setGroupDeletionModalOpenFalse } = useBoolState(
    false,
    'groupDeletionModalOpen',
  );

  const handleDelete = useCallback(() => onDelete(condition.id), [onDelete, condition.id]);

  const handleChangeLogicalOperator: Required<RadioButtonGroupProps<FormulaBuilderLogicalOperatorType>>['onChange'] =
    useCallback((operator) => onChange(setLogicalOperator(condition, operator)), [onChange, condition]);

  const handleRemoveOperand: Required<
    FormulaConditionTagProps<FormulaBuilderLogicalOperatorBlockForm['operands'][number]>
  >['onDelete'] = useCallback(
    (operandId) => {
      // Remove the operand from the condition.
      const updatedCondition = removeOperand(condition, operandId);

      // If no operand left, delete the condition group itself.
      if (updatedCondition.operands.length) {
        onChange(updatedCondition);
      } else {
        handleDelete();
      }
    },
    [onChange, handleDelete, condition],
  );

  const handleCreateOperand: CreateConditionFormProps['onCreateCondition'] = useCallback(
    (operand) => {
      onChange(addOperand(condition, operand));
      setActiveConditionId?.(operand.id);
      setOperandPopoverOpenFalse();
    },
    [onChange, condition, setActiveConditionId, setOperandPopoverOpenFalse],
  );

  const handleChangeOperand: Required<
    FormulaConditionTagProps<FormulaBuilderLogicalOperatorBlockForm['operands'][number]>
  >['onChange'] = useCallback((operand) => onChange(setOperand(condition, operand)), [onChange, condition]);

  return (
    <div css={styles.formulaConditionGroup}>
      <IconButton
        icon={<IconX />}
        label={formatMessage({ defaultMessage: 'Clear group of conditions' })}
        size={IconButton.Size.SMALL}
        variant={IconButton.Variant.DANGER}
        onClick={setGroupDeletionModalOpenTrue}
      />
      <div css={styles.conditions}>
        {condition.operands.map((operand, index) => (
          <Fragment key={operand.id}>
            {index > 0 && (
              <div {...andOrOptionsActiveProps}>
                <RadioButtonSingle
                  isActive={isAndOrOptionsActive}
                  name="operandSwitcher"
                  options={andOrOptions}
                  value={condition.logicalOperator}
                  onChange={handleChangeLogicalOperator}
                />
              </div>
            )}

            {(() => {
              const operandPath = `${path}.operands[${index}]`;
              switch (operand.type) {
                case FormulaBuilderBlockType.FORMULA:
                  return (
                    <FormulaConditionTagFormula
                      condition={operand}
                      path={operandPath}
                      onChange={handleChangeOperand}
                      onDelete={handleRemoveOperand}
                    />
                  );
                case FormulaBuilderBlockType.FUNCTION:
                  // FIXME: extract to separate component to avoid IIFE and nested switch
                  // eslint-disable-next-line sonarjs/no-nested-switch
                  switch (operand.category) {
                    case FormulaBuilderFunctionCategory.STRING:
                      return (
                        <FormulaConditionTagFunctionString
                          condition={operand}
                          path={operandPath}
                          onChange={handleChangeOperand}
                          onDelete={handleRemoveOperand}
                        />
                      );
                    case FormulaBuilderFunctionCategory.DATE:
                      return (
                        <FormulaConditionTagFunctionDate
                          condition={operand}
                          path={operandPath}
                          onChange={handleChangeOperand}
                          onDelete={handleRemoveOperand}
                        />
                      );
                    case FormulaBuilderFunctionCategory.USER:
                      return (
                        <FormulaConditionTagFunctionUser
                          condition={operand}
                          path={operandPath}
                          onChange={handleChangeOperand}
                          onDelete={handleRemoveOperand}
                        />
                      );
                    case FormulaBuilderFunctionCategory.NUMBER:
                      return (
                        <FormulaConditionTagFunctionNumber<FormulaBuilderFunctionBlockNumberForm>
                          condition={operand}
                          path={operandPath}
                          onChange={handleChangeOperand}
                          onDelete={handleRemoveOperand}
                        />
                      );
                    case FormulaBuilderFunctionCategory.PERCENT:
                      return (
                        <FormulaConditionTagFunctionNumber<FormulaBuilderFunctionBlockPercentForm>
                          condition={operand}
                          path={operandPath}
                          onChange={handleChangeOperand}
                          onDelete={handleRemoveOperand}
                        />
                      );
                    case FormulaBuilderFunctionCategory.CURRENCY:
                      return (
                        <FormulaConditionTagFunctionNumber<FormulaBuilderFunctionBlockCurrencyForm>
                          condition={operand}
                          path={operandPath}
                          onChange={handleChangeOperand}
                          onDelete={handleRemoveOperand}
                        />
                      );
                    case FormulaBuilderFunctionCategory.BOOLEAN:
                      return (
                        <FormulaConditionTagFunctionBoolean
                          condition={operand}
                          onChange={handleChangeOperand}
                          onDelete={handleRemoveOperand}
                        />
                      );
                    default:
                      return (
                        <FormulaConditionTag
                          condition={operand}
                          onChange={handleChangeOperand}
                          onDelete={handleRemoveOperand}
                        />
                      );
                  }
                default:
                  return (
                    <FormulaConditionTag
                      condition={operand}
                      onChange={handleChangeOperand}
                      onDelete={handleRemoveOperand}
                    />
                  );
              }
            })()}
          </Fragment>
        ))}
        <CreateConditionForm
          isOpen={isOperandPopoverOpen}
          onChangeIsOpen={setOperandPopoverOpen}
          onCreateCondition={handleCreateOperand}
        >
          <IconButton
            withBackground
            icon={<IconPlus />}
            label={formatMessage({ defaultMessage: 'Add a condition' })}
            size={IconButton.Size.SMALL}
          />
        </CreateConditionForm>
      </div>
      <RemoveConditionGroupModal
        isOpen={isGroupDeletionModalOpen}
        onClose={setGroupDeletionModalOpenFalse}
        onGroupDeletion={handleDelete}
      />
    </div>
  );
});
