import { memo, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal } from '@amalia/design-system/components';

export type ConfirmClearIntegrationIdSourceModalProps = {
  readonly isOpen: boolean;
  readonly title: ReactNode;
  readonly body: ReactNode;
  readonly onCancel: VoidFunction;
  readonly onConfirm: VoidFunction;
};

export const ConfirmClearIntegrationIdSourceModal = memo(function ConfirmClearIntegrationIdSourceModal({
  isOpen,
  title,
  body,
  onCancel,
  onConfirm,
}: ConfirmClearIntegrationIdSourceModalProps) {
  return (
    <Modal
      isDismissable
      data-testid="confirm-clear-integration-id-source-modal"
      isOpen={isOpen}
      size={Modal.Size.SMALL}
      variant={Modal.Variant.DANGER}
      onClose={onCancel}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
      </Modal.Content>
      <Modal.Actions>
        <Modal.CancelAction />
        <Modal.MainAction
          type="submit"
          onClick={onConfirm}
        >
          <FormattedMessage defaultMessage="Remove" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
