import { css, type Theme } from '@emotion/react';

export const modalTitle = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[900]};
  word-break: break-word;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;
