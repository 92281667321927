import { type Theme, css } from '@emotion/react';

import { SelectSize } from '../../../Select.types';

export const IS_DISABLED_CLASSNAME = 'is-disabled';

export const multiValue = (theme: Theme) => css`
  display: flex;
  align-items: center;

  padding: 0 8px;

  border-radius: ${theme.ds.borderRadiuses.squared};

  color: ${theme.ds.colors.gray[900]};
  background-color: ${theme.ds.colors.graySecondary[100]};

  transition: ${theme.ds.transitions.default('color', 'height')};

  > * {
    flex: none;
  }

  &.${SelectSize.SMALL} {
    height: 22px;
  }

  &.${SelectSize.MEDIUM} {
    height: 25px;
  }

  &.${IS_DISABLED_CLASSNAME} {
    color: ${theme.ds.colors.gray[300]};
  }
`;
