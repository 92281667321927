import { css } from '@emotion/react';

export const textOverflow = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  /** Make it work inside a flex container. */
  min-width: 0;
  flex-shrink: 1;
`;

export const tooltipContent = css`
  * {
    color: inherit;
  }
`;
