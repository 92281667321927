import { css } from '@emotion/react';
import { IconHelp } from '@tabler/icons-react';
import { type ReactNode, memo } from 'react';

import { Tooltip } from '../../../tooltip/Tooltip';

export type DropdownItemTooltipProps = {
  readonly tooltip: ReactNode;
};

export const DropdownItemTooltip = memo(function DropdownItemTooltip({ tooltip }: DropdownItemTooltipProps) {
  return (
    <Tooltip content={tooltip}>
      <IconHelp
        color="currentColor"
        size={14}
        css={css`
          flex: none;
        `}
      />
    </Tooltip>
  );
});
