import styled from '@emotion/styled';

export const TableDataCellContent = styled.div`
  ${({ theme }) => theme.ds.typographies.bodyBaseRegular}
  padding: 2px 16px;
  color: ${({ theme }) => theme.ds.colors.gray[900]};

  height: 48px;
  display: flex;
  align-items: center;
`;
