import { createContext, type Dispatch, type SetStateAction } from 'react';

import { type CustomObjectListDetails } from '@amalia/core/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';

export interface CustomTableContextInterface {
  objectDefinition: CustomObjectDefinition;
  customObjectListDetails: CustomObjectListDetails;
  setOverwriteModalInfo: Dispatch<SetStateAction<any>>;
}

export const CustomTableContext = createContext<CustomTableContextInterface>({} as CustomTableContextInterface);
