import { Fragment, memo } from 'react';

import { type ColumnOrderAndVisibilityProps } from '../column-order-and-visibility/ColumnOrderAndVisibility';

export type ColumnVisibilityProps = Required<
  Pick<ColumnOrderAndVisibilityProps, 'columnVisibility' | 'onChangeColumnVisibility'>
>;

export const ColumnVisibility = memo(function ColumnVisibility(_: ColumnVisibilityProps) {
  /**
   * For consistency in the DataGrid interface, column ordering uses the same interface.
   * It is passed to ColumnOrderAndVisibility in DataGrid.
   */
  return <Fragment>{null}</Fragment>;
});
