import { css } from '@emotion/react';
import { memo } from 'react';

import { type ChartSeries, ChartSeriesType } from '../chartSeries';

export type LegendIconProps = {
  readonly series: ChartSeries;
};

export const LegendIcon = memo(function LegendIcon({ series }: LegendIconProps) {
  switch (series.type) {
    case ChartSeriesType.BAR:
      return (
        <div
          css={css`
            background-color: ${series.color};
            border-radius: 4px;
            width: 16px;
            height: 16px;
          `}
        />
      );
    case ChartSeriesType.DOTTED_LINE:
      return (
        <svg
          height={2}
          width={16}
        >
          <line
            stroke={series.color}
            strokeDasharray="2.5 2.5 5 2.5 2.5"
            strokeLinecap="round"
            strokeWidth="1"
            x1="0"
            x2="16"
            y1="0"
            y2="0"
          />
        </svg>
      );
    case ChartSeriesType.LINE:
      return (
        <div
          css={css`
            background-color: ${series.color};
            border-radius: 16px;
            width: 8px;
            height: 8px;
          `}
        />
      );
  }
});
