import { Plugin, Template, TemplatePlaceholder } from '@devexpress/dx-react-core';
import { css } from '@emotion/react';
import { memo, type ReactNode, Fragment } from 'react';

import { Group } from '@amalia/design-system/components';

// PLUGINS
export enum DataGridPluginPosition {
  left = 'left',
  rightEnd = 'right-end',
  rightStart = 'right-start',
  bottomRight = 'bottom-right',
  bottomLeft = 'bottom-left',
}

export interface DataGridPluginDefinition {
  key: string;
  children: ReactNode;
  position: DataGridPluginPosition;
}

export interface DataGridPluginsProps {
  readonly plugins: DataGridPluginDefinition[];
}

export const DataGridPlugins = memo(function DataGridPlugins({ plugins }: DataGridPluginsProps) {
  return (
    <Plugin>
      <Template name="toolbarContent">
        <div
          css={css`
            width: 100%;
          `}
        >
          <Group reverse>
            <Group
              align="center"
              css={css`
                width: 100%;
              `}
            >
              {plugins
                .filter((p) => p.position === DataGridPluginPosition.rightStart)
                .map((p) => (
                  <Fragment key={p.key}>{p.children}</Fragment>
                ))}
              <TemplatePlaceholder />
              {plugins
                .filter((p) => p.position === DataGridPluginPosition.rightEnd)
                .map((p) => (
                  <Fragment key={p.key}>{p.children}</Fragment>
                ))}
            </Group>
          </Group>
          <div
            css={css`
              width: 100%;
            `}
          >
            <Group
              reverse
              css={css`
                width: 100%;
              `}
            >
              {plugins
                .filter((p) => p.position === DataGridPluginPosition.bottomLeft)
                .map((p) => (
                  <div
                    key={p.key}
                    css={css`
                      width: 100%;
                    `}
                  >
                    {p.children}
                  </div>
                ))}
              {plugins
                .filter((p) => p.position === DataGridPluginPosition.bottomRight)
                .map((p) => (
                  <div
                    key={p.key}
                    css={css`
                      width: 100%;
                    `}
                  >
                    {p.children}
                  </div>
                ))}
            </Group>
          </div>
        </div>
      </Template>
    </Plugin>
  );
});
