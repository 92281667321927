import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { type CustomObjectDefinition, CustomObjectDefinitionType } from '@amalia/data-capture/record-models/types';
import { CUSTOM_OBJECTS_KEYS } from '@amalia/data-capture/records/state';

import { CustomObjectDefinitionsApiClient } from '../api-client/custom-object-definitions.api-client';

import { CUSTOM_OBJECT_DEFINITION_KEYS } from './queries.keys';

export const useCustomObjectDefinitions = ({
  select,
}: {
  select?: (data: CustomObjectDefinition[]) => CustomObjectDefinition[];
} = {}) =>
  useQuery({
    queryKey: [CUSTOM_OBJECT_DEFINITION_KEYS.CUSTOM_OBJECT_DEFINITIONS] as const,
    queryFn: async () =>
      (
        await Promise.all([CustomObjectDefinitionsApiClient.list(), CustomObjectDefinitionsApiClient.listVirtual()])
      ).flat(),
    select,
  });

export const useNonVirtualCustomObjectDefinitions = () =>
  useCustomObjectDefinitions({
    select: (data) =>
      data
        .filter((d) => d.type !== CustomObjectDefinitionType.VIRTUAL)
        .toSorted((a, b) => (a.type === b.type ? a.name.localeCompare(b.name) : a.type.localeCompare(b.type))),
  });

export const useDeleteCustomObjectDefinition = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [CUSTOM_OBJECT_DEFINITION_KEYS.CUSTOM_OBJECT_DEFINITIONS, 'delete'],
    mutationFn: (args: Parameters<(typeof CustomObjectDefinitionsApiClient)['delete']>) =>
      CustomObjectDefinitionsApiClient.delete(...args),
    onSuccess: async (_, [objectDefinition]) => {
      await queryClient.invalidateQueries({
        queryKey: [CUSTOM_OBJECT_DEFINITION_KEYS.CUSTOM_OBJECT_DEFINITIONS],
      });

      await queryClient.invalidateQueries({
        queryKey: [CUSTOM_OBJECTS_KEYS.CUSTOM_OBJECTS_PAGE, objectDefinition.machineName],
      });
    },
  });
};
