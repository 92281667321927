import { IconCheckbox } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useLocation } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { IconButton } from '@amalia/design-system/components';
import { Link } from '@amalia/ext/react-router-dom';

interface TodosPresentationProps {
  readonly todosCount: number | null;
}

export const TodosPresentation = memo(function TodosPresentation({ todosCount }: TodosPresentationProps) {
  const location = useLocation();

  return (
    <Link to={generatePath(routes.TODOS)}>
      <IconButton
        data-testtodoscount={todosCount}
        icon={<IconCheckbox />}
        id="nav-todos"
        isActive={location.pathname.includes('todos')}
        label={<FormattedMessage defaultMessage="Todos" />}
        withDot={!!todosCount}
      />
    </Link>
  );
});
