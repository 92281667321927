import { css } from '@emotion/react';

export const selectedOption = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const triggerContainer = css`
  min-width: 0;
`;
