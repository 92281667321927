import { type CSSProperties } from 'react';

import { amaliaTheme, colors } from '@amalia/ext/mui/theme';

import { FormulaNodeEnum, IFormulaNode } from './IFormulaNode';

export class ConstantFormulaNode extends IFormulaNode<string> {
  public constructor(partialFormula: string, startIndex: number, nodeObject: string) {
    super(partialFormula.toString(), startIndex, FormulaNodeEnum.CONSTANT, nodeObject);
  }

  public getStyles(): CSSProperties {
    const baseStyles = { color: colors['grey-600'], textDecoration: undefined as string | undefined };
    const value = this.nodeObject.toString().trim();
    if (value === 'true') {
      baseStyles.textDecoration = `underline ${amaliaTheme.palette.success.main}`;
    }
    if (value === 'false') {
      baseStyles.textDecoration = `underline ${amaliaTheme.palette.error.main}`;
    }
    return baseStyles;
  }

  public getDesignerPath() {
    return null;
  }

  public getLabel(): string {
    return this.nodeObject.toString().trim();
  }
}
