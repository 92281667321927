import { Table } from '@devexpress/dx-react-grid-material-ui';
import { makeStyles } from '@mui/styles';
import { isNil } from 'lodash';
import { memo, useContext, useMemo } from 'react';

import { formatValue } from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';

import { TableBuilderContext } from '../TableBuilderContext';

import { TableBuilderActionCell } from './TableBuilderActionCell';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  cell: {
    padding: `0 ${theme.spacing(2.5)}`,
    backgroundColor: theme.palette.common.white,
    // In readonly mode, action buttons disappear and the cell are really tight.
    height: 50,

    '&:not(:last-child)': {
      borderRight: `2px solid ${theme.palette.divider}`,
    },
  },
}));

export const TableBuilderCell = memo(function TableBuilderCell(cellProps: Table.DataCellProps) {
  const classes = useStyles();
  const { column, tableRow } = cellProps;
  const { shouldFormatCellValue, currency } = useContext(TableBuilderContext);

  const cellContent = useMemo(() => {
    if (column.name === 'actions') {
      return null;
    }

    if (isNil(cellProps.value)) {
      return cellProps.value;
    }

    // @ts-expect-error -- We do have the format in the column.
    const { format } = column;

    return shouldFormatCellValue && [FormatsEnum.currency, FormatsEnum.percent, FormatsEnum.number].includes(format)
      ? formatValue(cellProps.value, format, currency)
      : cellProps.value;
  }, [cellProps, shouldFormatCellValue, column, currency]);

  if (column.name === 'actions') {
    return <TableBuilderActionCell {...cellProps} />;
  }

  return (
    <Table.Cell
      name={`${tableRow.rowId}.values[${column.name}]`}
      {...cellProps}
      className={classes.cell}
    >
      {cellContent}
    </Table.Cell>
  );
});
