import { type Theme, useTheme } from '@emotion/react';
import { Fade, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo } from 'react';

import { colors } from '@amalia/ext/mui/theme';

const useStyles = makeStyles({
  bar: {
    backgroundColor: ({ theme }: { theme: Theme }) => theme.ds.colors.primary[400],
  },
  root: {
    backgroundColor: colors['blue-100'],
    position: 'absolute',
    width: '100%',
    marginTop: 0,
  },
});

interface FullWidthLoaderProps {
  readonly show: boolean;
}

export const FullWidthLoader = memo(function FullWidthLoader({ show }: FullWidthLoaderProps) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div style={{ position: 'relative', height: 0, width: '100%' }}>
      <Fade
        in={!!show}
        timeout={500}
        style={{
          transitionDelay: show ? '200ms' : '0ms',
        }}
      >
        <LinearProgress classes={classes} />
      </Fade>
    </div>
  );
});
