import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertBanner } from '@amalia/design-system/components';
import { DrawingSandglass } from '@amalia/lib-ui';

import * as styles from './StatementSummaryWidgetNotAvailable.styles';

export const StatementSummaryWidgetNotAvailable = memo(function StatementSummaryWidgetNotAvailable() {
  return (
    <div css={styles.container}>
      <div css={styles.alert}>
        <AlertBanner
          data-testid="statement-not-available-banner"
          variant={AlertBanner.Variant.INFO}
        >
          <FormattedMessage defaultMessage="Your statement for this period is not available yet." />
        </AlertBanner>
      </div>

      <DrawingSandglass />
    </div>
  );
});
