import { css, type Theme } from '@emotion/react';
import { IconBulb } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Dropdown, Typography } from '@amalia/design-system/components';
import { TypographyVariant } from '@amalia/design-system/meta';

const footerStyle = (theme: Theme) => css`
  align-items: center;
  border-top: 1px solid ${theme.ds.colors.gray[100]};
  display: flex;
  gap: 6px;
`;

export const FormulaEditorSuggestionDropdownFooter = memo(function TokenListFooter() {
  return (
    <Dropdown.Footer css={footerStyle}>
      <IconBulb />
      <Typography variant={TypographyVariant.BODY_SMALL_REGULAR}>
        <FormattedMessage
          defaultMessage="<em>ENTER</em> to accept. <em>TAB</em> or arrow keys to navigate."
          values={{
            em: (chunks) => <em>{chunks}</em>,
          }}
        />
      </Typography>
    </Dropdown.Footer>
  );
});
