import { type ComputedStatement, type ComputedStatementSummary, type Statement } from '@amalia/core/types';
import { ComputedItemTypes, type ComputedVariable, type ComputeEngineResult } from '@amalia/payout-calculation/types';
import { type ComputedHighlightedKpi, HighlightedKpiIdentifier } from '@amalia/payout-definition/plans/types';

export const getComputedVariableInStatement = <TValue extends ComputeEngineResult = ComputeEngineResult>(
  computedStatement: ComputedStatement | ComputedStatementSummary,
  variableMachineName: string,
): ComputedVariable<TValue> | undefined =>
  computedStatement.computedObjects.find(
    (co): co is ComputedVariable<TValue> =>
      co.type === ComputedItemTypes.VARIABLE && co.variableMachineName === variableMachineName,
  );

const evaluateHighlightedKpiForIdentifier = (
  computedStatement: ComputedStatement | ComputedStatementSummary,
  identifier: HighlightedKpiIdentifier,
): ComputedHighlightedKpi | null => {
  // Grab definition of the KPI in the plan.
  const highlightedKpiDefinition = (computedStatement.definitions.plan?.highlightedKpis || []).find(
    (kpi) => kpi.identifier === identifier,
  );

  if (!highlightedKpiDefinition) {
    return null;
  }

  // Grab variable definition for the variables.
  // Variables are indexed by machineName instead of ids.
  const variableDefinitions = Object.values(computedStatement.definitions.variables || {});
  const variableDefinition = variableDefinitions.find((v) => v.id === highlightedKpiDefinition.variableId);
  const minVariableDefinition = highlightedKpiDefinition.minimumVariableId
    ? variableDefinitions.find((v) => v.id === highlightedKpiDefinition.minimumVariableId)
    : null;
  const maxVariableDefinition = highlightedKpiDefinition.maximumVariableId
    ? variableDefinitions.find((v) => v.id === highlightedKpiDefinition.maximumVariableId)
    : null;

  // Grab the variable in the computed statement.
  const variable =
    variableDefinition && getComputedVariableInStatement<number>(computedStatement, variableDefinition.machineName);
  if (!variable) {
    return null;
  }

  // Get min and max.
  const minVariable = minVariableDefinition
    ? getComputedVariableInStatement<number>(computedStatement, minVariableDefinition.machineName)
    : null;
  const maxVariable = maxVariableDefinition
    ? getComputedVariableInStatement<number>(computedStatement, maxVariableDefinition.machineName)
    : null;

  // If we can't find them, assume it's a percentage and use 0 and 1.
  const currentValue = variable.value ?? null;
  const minimumValue = minVariable?.value ?? 0;
  const maximumValue = maxVariable?.value ?? 1;

  // Evaluate result.
  const progress = (((currentValue || 0) - minimumValue) / (maximumValue - minimumValue)) * 100;

  return {
    format: variableDefinition.format,
    minimumValue,
    maximumValue,
    currentValue,
    maximumVariableLabel: maxVariableDefinition?.name || null,
    minimumVariableLabel: minVariableDefinition?.name || null,
    variableLabel: variableDefinition.name,
    progress,
  };
};

export const evaluateHighlightedKpisProgress = (
  computedStatement: ComputedStatement | ComputedStatementSummary,
): Record<HighlightedKpiIdentifier, ComputedHighlightedKpi | null> =>
  Object.values(HighlightedKpiIdentifier).reduce<Record<HighlightedKpiIdentifier, ComputedHighlightedKpi | null>>(
    (acc, currentIdentifier) => {
      acc[currentIdentifier] = evaluateHighlightedKpiForIdentifier(computedStatement, currentIdentifier);
      return acc;
    },
    {} as Record<HighlightedKpiIdentifier, ComputedHighlightedKpi | null>,
  );

export const getStatementKpis = (statement: Statement, isForecast: boolean = false) => {
  const summary =
    isForecast && statement.forecast?.resultSummary ? statement.forecast.resultSummary : statement.resultSummary;

  return summary ? evaluateHighlightedKpisProgress(summary) : undefined;
};

const aggregateKpi = (
  accKpi: { kpi: ComputedHighlightedKpi | null; count: number },
  kpiToAggregate?: ComputedHighlightedKpi | null,
) => {
  if (kpiToAggregate) {
    if (accKpi.kpi) {
      accKpi.kpi.currentValue ??= 0;
      accKpi.kpi.currentValue += kpiToAggregate.currentValue ?? 0;
      accKpi.kpi.progress += kpiToAggregate.progress;
    } else {
      accKpi.kpi = kpiToAggregate;
    }

    accKpi.count++;
  }
};

const getKpiAverage = (kpi: ComputedHighlightedKpi | null, count: number): ComputedHighlightedKpi | null =>
  kpi
    ? {
        ...kpi,
        currentValue: (kpi.currentValue ?? 0) / count,
        progress: kpi.progress / count,
      }
    : null;

export const getStatementsAverageKpis = (
  /** Statements of the same plan. */
  statements: Statement[],
  /** Is in forecast mode. */
  isForecast: boolean = false,
): Record<HighlightedKpiIdentifier, ComputedHighlightedKpi | null> => {
  const kpisAcc = statements.reduce<
    Record<HighlightedKpiIdentifier, { kpi: ComputedHighlightedKpi | null; count: number }>
  >(
    (acc, statement) => {
      const statementKpis = getStatementKpis(statement, isForecast);

      aggregateKpi(acc[HighlightedKpiIdentifier.PRIMARY], statementKpis?.[HighlightedKpiIdentifier.PRIMARY]);
      aggregateKpi(acc[HighlightedKpiIdentifier.SECONDARY], statementKpis?.[HighlightedKpiIdentifier.SECONDARY]);

      return acc;
    },
    {
      [HighlightedKpiIdentifier.PRIMARY]: { kpi: null, count: 0 },
      [HighlightedKpiIdentifier.SECONDARY]: { kpi: null, count: 0 },
    },
  );

  return {
    [HighlightedKpiIdentifier.PRIMARY]: getKpiAverage(
      kpisAcc[HighlightedKpiIdentifier.PRIMARY].kpi,
      kpisAcc[HighlightedKpiIdentifier.PRIMARY].count,
    ),
    [HighlightedKpiIdentifier.SECONDARY]: getKpiAverage(
      kpisAcc[HighlightedKpiIdentifier.SECONDARY].kpi,
      kpisAcc[HighlightedKpiIdentifier.SECONDARY].count,
    ),
  };
};
