import styled from '@emotion/styled';
import { useFormikContext } from 'formik';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ATTRIBUTE_FORMAT_ICON_MAPPING } from '@amalia/amalia-lang/formula/components';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import {
  Checkbox,
  FormLayout,
  FormikSelect,
  Select,
  Typography,
  type SelectOption,
} from '@amalia/design-system/components';
import {
  CustomReportAggregationsForFormat,
  getCustomReportFieldDefinition,
  parseCustomReportFieldPosition,
  stringifyCustomReportFieldPosition,
  type CustomReport,
  type CustomReportAggregationOperation,
} from '@amalia/reporting/custom-reports/shared';
import { useCustomReportSourcesManifests } from '@amalia/reporting/custom-reports/state';
import { CustomReportAggregationLabel } from '@amalia/reporting/custom-reports/views';
import { type ChartType, type DashboardChartConfiguration } from '@amalia/reporting/dashboards-v2/types';

const CustomInputLabel = styled(Typography)`
  color: ${({ theme }) => theme.ds.colors.gray[800]};
`;

interface KPICardChartContentProps {
  readonly customReports: CustomReport[];
}

export const KPICardChartContent = memo(function KPICardChartContent({ customReports }: KPICardChartContentProps) {
  const { formatMessage } = useIntl();

  const { values, setValues, setFieldValue } =
    useFormikContext<DashboardChartConfiguration<ChartType.KPI_CARD_CHART>>();

  const selectedReport = customReports.find((customReport) => customReport.id === values.customReportId);
  const { manifestsMap } = useCustomReportSourcesManifests(selectedReport ?? null, false);

  const selectedKPI = useMemo(
    () =>
      selectedReport ? getCustomReportFieldDefinition(selectedReport, manifestsMap, values.displaySettings.kpi) : null,
    [selectedReport, manifestsMap, values.displaySettings.kpi],
  );

  const kpisOptions = useMemo(
    () =>
      selectedReport && Object.keys(manifestsMap).length
        ? selectedReport.configuration.fields
            .map((field) => {
              const fieldDefinition = getCustomReportFieldDefinition(selectedReport, manifestsMap, field);

              if (!fieldDefinition) {
                return null;
              }

              return {
                value: stringifyCustomReportFieldPosition(field),
                label: `${field.alias || fieldDefinition.label}`,
                format: fieldDefinition.format,
                icon: ATTRIBUTE_FORMAT_ICON_MAPPING[fieldDefinition.format],
              };
            })
            .filter(Boolean)
        : [],
    [selectedReport, manifestsMap],
  );

  const aggregationsOptions = useMemo(() => {
    const kpiOption = kpisOptions.find(
      ({ value }) => value === stringifyCustomReportFieldPosition(values.displaySettings.kpi),
    );

    if (!kpiOption) {
      return [];
    }

    return CustomReportAggregationsForFormat[kpiOption.format].map((agg) => ({
      value: agg,
      label: formatMessage(CustomReportAggregationLabel[agg]),
    }));
  }, [formatMessage, kpisOptions, values]);

  const handleChangeKPI = useCallback(
    async (newKPI: string | null) => {
      const newValues: DashboardChartConfiguration<ChartType.KPI_CARD_CHART> = {
        ...values,
        displaySettings: {
          ...values.displaySettings,
          kpi: { ...values.displaySettings.kpi, ...parseCustomReportFieldPosition(newKPI!) },
          isDoughnutEnabled: selectedKPI?.format === FormatsEnum.percent && values.displaySettings.isDoughnutEnabled,
        },
      };
      await setValues(newValues);
    },
    [values, setValues, selectedKPI?.format],
  );

  const handleChangeShowDoughnut = useCallback(
    async (newChecked: boolean) => {
      await setFieldValue('displaySettings.isDoughnutEnabled', newChecked);
    },
    [setFieldValue],
  );

  return (
    <FormLayout.Group>
      <CustomInputLabel variant={Typography.Variant.BODY_BASE_MEDIUM}>
        <FormattedMessage defaultMessage="Value" />
      </CustomInputLabel>

      <Select<SelectOption<string>>
        required
        disabled={!selectedReport}
        id="displaySettings.kpi"
        options={kpisOptions}
        value={stringifyCustomReportFieldPosition(values.displaySettings.kpi)}
        placeholder={
          selectedReport
            ? formatMessage({ defaultMessage: 'Select KPI' })
            : formatMessage({ defaultMessage: 'Please select a report first' })
        }
        onChange={handleChangeKPI}
      />

      <FormikSelect<SelectOption<CustomReportAggregationOperation>>
        required
        disabled={!values.displaySettings.kpi.identifier}
        id="displaySettings.kpi.aggregation.operation"
        name="displaySettings.kpi.aggregation.operation"
        options={aggregationsOptions}
        placeholder={
          values.displaySettings.kpi.identifier
            ? formatMessage({ defaultMessage: 'Select aggregate' })
            : formatMessage({ defaultMessage: 'Please select a KPI first' })
        }
      />

      {selectedKPI?.format === FormatsEnum.percent && (
        <Checkbox
          checked={values.displaySettings.isDoughnutEnabled}
          label={<FormattedMessage defaultMessage="Show doughnut" />}
          onChange={handleChangeShowDoughnut}
        />
      )}
    </FormLayout.Group>
  );
});
