import { css } from '@emotion/react';
import { IconViewfinder } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { StatementErrorLevel, StatementErrorType } from '@amalia/core/types';
import { AlertBanner, type AlertBannerVariant, Button, ButtonLink } from '@amalia/design-system/components';
import { Link } from '@amalia/ext/react-router-dom';
import { useStatementDetailContext } from '@amalia/lib-ui';
import { tenantUsersRoutes } from '@amalia/tenants/users/profile/shared';

const ERROR_LEVEL_BANNER_VARIANT_MAPPING: Record<StatementErrorLevel, AlertBannerVariant> = {
  [StatementErrorLevel.ERROR]: AlertBanner.Variant.ERROR,
  [StatementErrorLevel.WARNING]: AlertBanner.Variant.WARNING,
};

export const StatementDetailHeaderPayoutQuotaError = memo(function StatementDetailHeaderPayoutQuotaErrorProps() {
  const {
    statement: { error, user },
  } = useStatementDetailContext();

  // Note: I'm guessing this condition from the backend code. Returned errors should be clearer.
  const isQuotaError = error.level === StatementErrorLevel.WARNING && error.type === StatementErrorType.CONFIGURATION;

  return (
    <AlertBanner
      inline
      withBorder
      alignCenter={isQuotaError}
      variant={ERROR_LEVEL_BANNER_VARIANT_MAPPING[error.level]}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;
        `}
      >
        <div>{error.message}</div>

        {!!isQuotaError && (
          <ButtonLink
            icon={<IconViewfinder />}
            size={Button.Size.SMALL}
            variant={Button.Variant.LIGHT}
            to={
              <Link
                openInNewTab
                to={generatePath(tenantUsersRoutes.MEMBER_PAGE, { memberId: user.id })}
              />
            }
          >
            <FormattedMessage defaultMessage="Go to quotas" />
          </ButtonLink>
        )}
      </div>
    </AlertBanner>
  );
});
