import { FeatureFlagDisabled } from './FeatureFlagDisabled';
import { FeatureFlagEnabled } from './FeatureFlagEnabled';

/**
 * @example
 *
 * Display a component when a feature flag is enabled:
 *
 * ```tsx
 *   <FeatureFlag.Enabled
 *     featureFlag={CompanyFeature.BADGES}
 *   >
 *    <p>Enabled</p>
 *  </FeatureFlag.Enabled>
 * ```
 *
 * This will render `<p>Enabled</p>` when the feature flag BADGES is truthy (true).
 *
 * @example
 *
 * Display a component when a feature flag is disabled:
 *
 * ```tsx
 *  <FeatureFlag.Disabled
 *   featureFlag={CompanyFeature.BADGES}
 * >
 *   <p>Disabled</p>
 * </FeatureFlag.Disabled>
 * ```
 *
 * This will render `<p>Disabled</p>` when the feature flag "BADGES" is falsy (false when cast to boolean).
 */
export const FeatureFlag = {
  Enabled: FeatureFlagEnabled,
  Disabled: FeatureFlagDisabled,
};
