import { useMemo } from 'react';

import { type SelectDropdownOption, type SelectOptionGroup, isSelectOptionGroup } from '../SelectDropdown.types';

/**
 * Flatten the list of options and option groups to a list of options.
 *
 * @param optionsAndGroups - List of options and option groups.
 * @returns List of options.
 */
export const useFlattenOptions = <
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
>(
  optionsAndGroups: (TGroup | TOption)[],
): TOption[] =>
  useMemo(
    () =>
      optionsAndGroups.flatMap((optionOrGroup) =>
        isSelectOptionGroup(optionOrGroup) ? optionOrGroup.options : optionOrGroup,
      ),
    [optionsAndGroups],
  );
