import { IconBuildingSkyscraper, IconRobot } from '@tabler/icons-react';
import { Command } from 'cmdk';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@amalia/design-system/components';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { useOmolioPromptModalContext } from '@amalia/omolio/components';

import { BaseCommandItem } from '../cmdk-styled/BaseCommandItem';
import { useShortcutCommandContext } from '../ShortcutCommands.context';
import { PagesEnum } from '../shortcutCommands.types';

export const SuperAdminCommandsGroup = memo(function SuperAdminCommandsGroup() {
  const ability = useAbilityContext();
  const { setPages, resetSearch, closeDialog } = useShortcutCommandContext();
  const { setOmolioPromptModalOpenTrue } = useOmolioPromptModalContext();
  const canViewSuperAdmin = ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin);

  const handleSelectOmolioPrompt = useCallback(() => {
    closeDialog();
    setOmolioPromptModalOpenTrue();
  }, [closeDialog, setOmolioPromptModalOpenTrue]);

  const handleSelectListCompanies = useCallback(() => {
    setPages((prev) => [...prev, PagesEnum.LIST_COMPANIES]);
    resetSearch();
  }, [resetSearch, setPages]);

  if (!canViewSuperAdmin) {
    return null;
  }

  return (
    <Command.Group
      heading={
        <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>
          <FormattedMessage defaultMessage="Super Admin commands" />
        </Typography>
      }
    >
      <BaseCommandItem
        Icon={IconRobot}
        title={<FormattedMessage defaultMessage="Ask Omolio" />}
        onSelect={handleSelectOmolioPrompt}
      />
      <BaseCommandItem
        Icon={IconBuildingSkyscraper}
        title={<FormattedMessage defaultMessage="List companies" />}
        onSelect={handleSelectListCompanies}
      />
    </Command.Group>
  );
});
