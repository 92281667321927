import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { keyBy } from 'lodash';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import { PlanTeamAssignmentsApiClient } from '@amalia/tenants/assignments/teams/api-client';
import { TEAM_QUERY_KEYS } from '@amalia/tenants/teams/state';
import { type TeamPlanAssignment } from '@amalia/tenants/teams/types';

import { TEAM_ASSIGNMENTS_KEYS } from './queries.keys';

export const usePlanTeamAssignments = (teamId: string) => {
  const { snackError } = useSnackbars();

  const {
    data: planTeamAssignments,
    isError,
    error,
    isSuccess,
  } = useQuery({
    queryKey: [TEAM_QUERY_KEYS.TEAMS, TEAM_ASSIGNMENTS_KEYS.PLAN_TEAMS_ASSIGNMENTS, teamId],
    queryFn: () => PlanTeamAssignmentsApiClient.getTeamPlanAssignments(teamId),
    enabled: !!teamId,
  });

  useEffect(() => {
    if (isError) {
      snackError(toError(error).message);
    }
  }, [isError, error, snackError]);

  return {
    planTeamAssignmentsMap: keyBy(planTeamAssignments, 'planId'),
    isSuccess,
    isError,
  };
};

export const useUpdateTeamPlanAssignment = (teamId: string) => {
  const { snackError, snackSuccess } = useSnackbars();
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [TEAM_QUERY_KEYS.TEAMS, TEAM_ASSIGNMENTS_KEYS.PLAN_TEAMS_ASSIGNMENTS, teamId, 'update'],
    mutationFn: (updatedAssignment: TeamPlanAssignment) =>
      PlanTeamAssignmentsApiClient.updateTeamPlanAssignment(teamId, updatedAssignment),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Team plan assignment updated.' }));
      await queryClient.invalidateQueries({
        queryKey: [TEAM_QUERY_KEYS.TEAMS, TEAM_ASSIGNMENTS_KEYS.PLAN_TEAMS_ASSIGNMENTS, teamId],
      });
    },
    onError: (error) => {
      snackError(toError(error).message);
    },
  });
};

export const useCreateTeamPlanAssignment = (teamId: string) => {
  const { snackError, snackSuccess } = useSnackbars();
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [TEAM_QUERY_KEYS.TEAMS, TEAM_ASSIGNMENTS_KEYS.PLAN_TEAMS_ASSIGNMENTS, teamId, 'create'],
    mutationFn: (planTeamAssignment: TeamPlanAssignment) =>
      PlanTeamAssignmentsApiClient.addTeamPlanAssignment(teamId, planTeamAssignment),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Team plan assignment created.' }));
      await queryClient.invalidateQueries({
        queryKey: [TEAM_QUERY_KEYS.TEAMS, TEAM_ASSIGNMENTS_KEYS.PLAN_TEAMS_ASSIGNMENTS, teamId],
      });
    },
    onError: (error) => {
      snackError(toError(error).message);
    },
  });
};

export const useDeleteTeamPlanAssignment = (teamId: string) => {
  const { snackError, snackSuccess } = useSnackbars();
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [TEAM_QUERY_KEYS.TEAMS, TEAM_ASSIGNMENTS_KEYS.PLAN_TEAMS_ASSIGNMENTS, teamId, 'delete'],
    mutationFn: (planTeamAssignment: TeamPlanAssignment) =>
      PlanTeamAssignmentsApiClient.removeTeamPlanAssignment(teamId, planTeamAssignment),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Team plan assignment deleted.' }));
      await queryClient.invalidateQueries({
        queryKey: [TEAM_QUERY_KEYS.TEAMS, TEAM_ASSIGNMENTS_KEYS.PLAN_TEAMS_ASSIGNMENTS, teamId],
      });
    },
    onError: (error) => {
      snackError(toError(error).message);
    },
  });
};
