import { createContext, memo, type ReactNode, useContext, useMemo } from 'react';

import { useBoolState } from '@amalia/ext/react/hooks';

type OmolioPromptModalContextValues = {
  readonly isOmolioPromptModalOpen: boolean;
  readonly setOmolioPromptModalOpenTrue: () => void;
  readonly setOmolioPromptModalOpenFalse: () => void;
};

const OmolioPromptModalContext = createContext<OmolioPromptModalContextValues | null>(null);

export const OmolioPromptModalContextProvider = memo(function OmolioPromptModalContextProvider({
  children,
}: {
  readonly children: ReactNode;
}) {
  const { isOmolioPromptModalOpen, setOmolioPromptModalOpenTrue, setOmolioPromptModalOpenFalse } = useBoolState(
    false,
    'omolioPromptModalOpen',
  );

  const contextValues = useMemo<OmolioPromptModalContextValues>(
    () => ({
      isOmolioPromptModalOpen,
      setOmolioPromptModalOpenTrue,
      setOmolioPromptModalOpenFalse,
    }),
    [isOmolioPromptModalOpen, setOmolioPromptModalOpenTrue, setOmolioPromptModalOpenFalse],
  );

  return <OmolioPromptModalContext.Provider value={contextValues}>{children}</OmolioPromptModalContext.Provider>;
});

export const useOmolioPromptModalContext = () => {
  const context = useContext(OmolioPromptModalContext);
  if (context === null) {
    throw new Error('useOmolioPromptModalContext must be used within a OmolioPromptModalContextProvider');
  }
  return context;
};
