import { IconChevronRight } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { type StatementError } from '@amalia/core/types';
import { Button, ButtonLink, Paper, Typography } from '@amalia/design-system/components';
import { TypographyVariant } from '@amalia/design-system/meta';

import { StatementSummaryBody } from './statement-summary-body/StatementSummaryBody';
import * as styles from './StatementSummaryWidget.styles';
import { type CategoryWithRulesSummary } from './StatementSummaryWidget.types';

export type StatementSummaryWidgetProps = {
  readonly adjustmentsFormatted?: CategoryWithRulesSummary;
  readonly categoriesWithRulesSummary: CategoryWithRulesSummary[];
  readonly statementError?: StatementError;
  readonly periodName?: string;
  readonly statementId?: string;
  readonly statementTotalAchievedFormatted?: string;
};

/**
 * Displays a summary of the statement for a given period.
 *
 * If there is a statement for the given period, it displays the rules and the corresponding amounts.
 *
 * If there is no statement for the given period, it displays a message and a sandglass.
 */
export const StatementSummaryWidget = memo(function StatementSummaryWidget({
  adjustmentsFormatted,
  statementError,
  categoriesWithRulesSummary,
  periodName,
  statementId,
  statementTotalAchievedFormatted,
}: StatementSummaryWidgetProps) {
  return (
    <Paper css={styles.container}>
      <Typography
        css={styles.title}
        variant={TypographyVariant.HEADING_3_BOLD}
      >
        {periodName}
      </Typography>

      <div css={styles.body}>
        <StatementSummaryBody
          adjustmentsFormatted={adjustmentsFormatted}
          categoriesWithRulesSummary={categoriesWithRulesSummary}
          statementError={statementError}
          statementId={statementId}
          statementTotalAchievedFormatted={statementTotalAchievedFormatted}
        />
      </div>

      <div css={styles.footer}>
        {statementId ? (
          <ButtonLink
            disabled={!!statementError}
            icon={<IconChevronRight />}
            iconPosition={Button.IconPosition.RIGHT}
            variant={Button.Variant.PRIMARY}
            to={generatePath(routes.STATEMENT, {
              id: statementId,
            })}
          >
            <FormattedMessage defaultMessage="View statement" />
          </ButtonLink>
        ) : (
          <Button
            disabled
            icon={<IconChevronRight />}
            iconPosition={Button.IconPosition.RIGHT}
            variant={Button.Variant.PRIMARY}
          >
            <FormattedMessage defaultMessage="View statement" />
          </Button>
        )}
      </div>
    </Paper>
  );
});
