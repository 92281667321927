import { css } from '@emotion/react';
import { memo, useMemo } from 'react';

import { type StatementError } from '@amalia/core/types';
import { Avatar, type AvatarProps } from '@amalia/design-system/components';
import { type GaugeGroupProps } from '@amalia/lib-ui';
import { type ComputedHighlightedKpi, type HighlightedKpiIdentifier } from '@amalia/payout-definition/plans/types';
import { type UserComputed } from '@amalia/tenants/users/types';

import { StatementGaugeGroup } from '../statement-gauge-group/StatementGaugeGroup';

export type StatementAvatarProps = {
  readonly user?: UserComputed;
  readonly error?: StatementError;
  readonly highlightedKpis?: Record<HighlightedKpiIdentifier, ComputedHighlightedKpi | null>;
  readonly fullProgress?: boolean;
  readonly hideAvatar?: boolean;
  readonly isForecast?: boolean;
  readonly avatarSize?: AvatarProps['size'];
  readonly gaugeSize?: GaugeGroupProps['width'];
  readonly gaugeStrokeWidth?: GaugeGroupProps['strokeWidth'];
  readonly gaugeGap?: GaugeGroupProps['gap'];
};

const FULL_PROGRESS = {
  primary: 100,
  secondary: 100,
};

export const StatementAvatar = memo(function StatementAvatar({
  user,
  error,
  highlightedKpis,
  fullProgress,
  isForecast,
  hideAvatar = false,
  avatarSize = Avatar.Size.LARGE,
  gaugeSize = 80,
  gaugeStrokeWidth = 4,
  gaugeGap = 4,
}: StatementAvatarProps) {
  const gaugeProgress = useMemo(
    () => ({
      primary: highlightedKpis?.primary?.progress,
      secondary: highlightedKpis?.secondary?.progress,
    }),
    [highlightedKpis],
  );

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
      `}
    >
      {!hideAvatar && (
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
          `}
        >
          <Avatar
            size={avatarSize}
            user={user}
          />
        </div>
      )}

      {fullProgress ? (
        <StatementGaugeGroup
          errorLevel={error?.level}
          gap={gaugeGap}
          isForecast={isForecast}
          progress={FULL_PROGRESS}
          strokeWidth={gaugeStrokeWidth}
          width={gaugeSize}
        />
      ) : (
        <StatementGaugeGroup
          errorLevel={error?.level}
          gap={gaugeGap}
          isForecast={isForecast}
          progress={gaugeProgress}
          strokeWidth={gaugeStrokeWidth}
          width={gaugeSize}
        />
      )}
    </div>
  );
});
