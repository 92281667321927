import { css, type Theme } from '@emotion/react';
import { type ForwardedRef, forwardRef, memo } from 'react';

import { Link, type LinkProps } from '@amalia/ext/react-router-dom';
import { type MergeAll } from '@amalia/ext/typescript';

import { DropdownItemContent, type DropdownItemContentProps } from '../dropdown-item-content/DropdownItemContent';
import { DropdownItemContainer } from '../Dropdown.styles';

const linkStyle = (theme: Theme) => css`
  width: 100%;
  display: block;
  border-radius: ${theme.ds.borderRadiuses.squared};

  transition: ${theme.ds.transitions.default('box-shadow')};

  text-decoration: none;

  &:focus-visible {
    outline: 1px solid ${theme.ds.colors.primary[200]};
    outline-offset: -1px;
    box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.primary[50])};

    position: relative;
    z-index: 1;
  }
`;

export type DropdownItemLinkProps = MergeAll<
  [Omit<DropdownItemContentProps, 'contentEditable' | 'isInteractive'>, Omit<LinkProps, 'contentEditable'>]
>;

const DropdownItemLinkForwardRef = forwardRef(function DropdownItemLink(
  {
    disabled,
    grabHandle,
    icon,
    isActive,
    label,
    rightActions,
    secondaryLabel,
    tooltip,
    ...props
  }: DropdownItemLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  const content = (
    <DropdownItemContent
      isInteractive
      disabled={disabled}
      grabHandle={grabHandle}
      icon={icon}
      isActive={isActive}
      label={label}
      rightActions={rightActions}
      secondaryLabel={secondaryLabel}
      tooltip={tooltip}
    />
  );

  return (
    <DropdownItemContainer>
      <Link
        {...props}
        ref={ref}
        css={linkStyle}
        disabled={disabled}
      >
        {content}
      </Link>
    </DropdownItemContainer>
  );
});

export const DropdownItemLink = memo(DropdownItemLinkForwardRef);
