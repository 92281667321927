import { SnackbarProvider as NotistackProvider, type SnackbarProviderProps } from 'notistack';
import { memo } from 'react';

import { Snackbar } from '../snackbar/Snackbar';

export const SnackbarProvider = memo(function SnackbarProvider({ children, ...props }: SnackbarProviderProps) {
  return (
    <NotistackProvider
      maxSnack={5}
      Components={{
        default: Snackbar,
        info: Snackbar,
        success: Snackbar,
        warning: Snackbar,
        error: Snackbar,
      }}
      {...props}
    >
      {children}
    </NotistackProvider>
  );
});
