import { useTheme } from '@emotion/react';
import { noop } from 'lodash';
import {
  memo,
  forwardRef,
  type ForwardedRef,
  useCallback,
  type TransitionEventHandler,
  useState,
  type CSSProperties,
  useMemo,
  type ComponentPropsWithoutRef,
} from 'react';

import { useForwardedRef, useUpdateEffect } from '@amalia/ext/react/hooks';
import { type MergeAll } from '@amalia/ext/typescript';

import * as styles from './Collapse.styles';

export type CollapseProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Is the content collapsed. */
      isOpen?: boolean;
      /** Only mount children when opening the Collapse. */
      lazy?: boolean;
    },
  ]
>;

const CollapseForwardRef = forwardRef(function Collapse(
  { isOpen = true, lazy = false, children = null, style = undefined, onTransitionEnd = noop, ...props }: CollapseProps,
  forwardedRef: ForwardedRef<HTMLDivElement>,
) {
  const theme = useTheme();
  const ref = useForwardedRef(forwardedRef);
  const [collapseStyles, setCollapseStyles] = useState<CSSProperties>(isOpen ? {} : styles.collapsedStyles);
  const [collapseContentStyles, setCollapseContentStyles] = useState<CSSProperties>(
    isOpen ? {} : styles.collapsedContentStyles,
  );

  const [isMounted, setIsMounted] = useState(isOpen);

  // Get the container height.
  // NB: Cannot use scrollHeight ?? 'auto' because sometimes the scrollHeight is still 0 but should not be (seems like a bug).
  // If the content is empty there is no transition from auto to 0 anyway so it doesn't matter.
  const getContainerHeight = useCallback(() => ref.current?.scrollHeight || 'auto', [ref]);

  // When the open state changes, we set styles to either open or close the collapse.
  // Ignore on mount since onTransitionEnd is not called so styles will not be cleaned up, and styles are given in initial state.
  useUpdateEffect(() => {
    if (isOpen) {
      setIsMounted(true);
      // Set styles with animation frames because we need the container height but in display none it's 0.
      // Set display block first.
      requestAnimationFrame(() => {
        setCollapseStyles((currentStyles) => ({
          ...currentStyles,
          ...styles.openingStyles,
          ...styles.startTransitionStyles,
        }));

        // Then set the height in the next frame in css to the (now available) height of the div for the transition to work.
        requestAnimationFrame(() => {
          setCollapseStyles((currentStyles) => ({
            ...currentStyles,
            ...styles.transitionStyles(theme, getContainerHeight()),
          }));

          setCollapseContentStyles(styles.openContentStyles);
        });
      });
    } else {
      // Hardcode the height to the current height so the transition to 0 works.
      requestAnimationFrame(() => {
        setCollapseStyles((currentStyles) => ({
          ...currentStyles,
          ...styles.transitionStyles(theme, getContainerHeight()),
          ...styles.startTransitionStyles,
        }));

        setCollapseContentStyles(styles.collapsedContentStyles);

        // Now set height to 0.
        requestAnimationFrame(() => {
          setCollapseStyles((currentStyles) => ({
            ...currentStyles,
            ...styles.collapsingStyles,
          }));
        });
      });
    }
  }, [isOpen, getContainerHeight, theme]);

  // Once the transition ends, keep the collapsed styles or remove the styles if isOpen is true.
  const handleTransitionEnd: TransitionEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (event.target === ref.current && event.propertyName === 'height') {
        if (isOpen) {
          // Since the height was hardcoded during the collapse transition, we need to remove it,
          // otherwise if the children change, the height will be wrong and children will be cut off.
          setCollapseStyles((currentStyles) => {
            const containerHeight = getContainerHeight();
            return currentStyles.height === containerHeight
              ? {}
              : {
                  ...currentStyles,
                  height: containerHeight,
                };
          });
        } else {
          // Set the collapsed styles after the transition ends.
          setCollapseStyles(styles.collapsedStyles);
          setCollapseContentStyles(styles.collapsedContentStyles);
          setIsMounted(false);
        }
      }

      onTransitionEnd(event);
    },
    [ref, isOpen, onTransitionEnd, getContainerHeight],
  );

  const mergedStyles: CSSProperties = useMemo(
    () => ({
      ...style,
      ...collapseStyles,
    }),
    [style, collapseStyles],
  );

  return (
    <div
      {...props}
      ref={ref}
      aria-hidden={props['aria-hidden'] || !isOpen}
      style={mergedStyles}
      onTransitionEnd={handleTransitionEnd}
    >
      <div
        css={styles.collapseContent}
        style={collapseContentStyles}
      >
        {!lazy || isMounted ? children : <div css={styles.lazyPlaceholder} />}
      </div>
    </div>
  );
});

export const Collapse = memo(CollapseForwardRef);
