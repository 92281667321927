import { css } from '@emotion/react';
import { type ForwardedRef, forwardRef, memo, type ReactNode, type MouseEventHandler } from 'react';

import { Typography } from '@amalia/design-system/components';

export type DatePickerInputProps = {
  readonly onClick?: MouseEventHandler<HTMLButtonElement>;
  readonly value?: ReactNode;
  readonly isOpen?: boolean;
};

const DatePickerInputForwardRef = forwardRef(function DatePickerCustomInput(
  { onClick, value, isOpen }: DatePickerInputProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <button
      ref={ref}
      type="button"
      css={(theme) => css`
        all: unset;
        border-radius: ${theme.ds.borderRadiuses.round};

        padding: 3.5px 12px;
        transition: ${theme.ds.transitions.default('background-color', 'box-shadow', 'color')};

        cursor: pointer;

        color: ${isOpen ? theme.ds.colors.primary[500] : theme.ds.colors.gray[900]};
        background-color: ${isOpen ? theme.ds.colors.primary[50] : theme.ds.colors.gray[0]};

        &:hover {
          background-color: ${theme.ds.colors.primary[50]};
        }

        &:focus-visible {
          box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.primary[50])};
          outline: 1px solid ${theme.ds.colors.primary[200]};
          outline-offset: -1px;
        }
      `}
      onClick={onClick}
    >
      <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>{value}</Typography>
    </button>
  );
});

export const DatePickerInput = memo(DatePickerInputForwardRef);
