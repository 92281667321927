import { css, useTheme } from '@emotion/react';
import { isNil } from 'lodash';
import { Fragment, memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertBanner, AlertBannerVariant } from '@amalia/design-system/components';
import { StatementGaugeGroup } from '@amalia/lib-ui-business';
import {
  type CustomReportColumn,
  type PayoutAndPerformanceChartStatisticsRecord,
} from '@amalia/reporting/custom-reports/shared';

import { KpiPill } from '../kpi-pill/KpiPill';

type CardContentProps = Readonly<{
  kpi1: CustomReportColumn;
  kpi2: CustomReportColumn;
  record: PayoutAndPerformanceChartStatisticsRecord;
}>;
export const CardContent = memo(function TabCard({ kpi1, kpi2, record }: CardContentProps) {
  const theme = useTheme();
  const kpi1Value = useMemo(() => record?.[kpi1?.identifier] as number, [kpi1, record]);
  const kpi2Value = useMemo(() => record?.[kpi2?.identifier] as number, [kpi2, record]);

  const gaugeProgress = useMemo(
    () => ({
      primary: !isNil(kpi1Value) ? kpi1Value * 100 : kpi1 ? 0 : null,
      secondary: !isNil(kpi2Value) ? kpi2Value * 100 : kpi2 ? 0 : null,
    }),
    [kpi1, kpi1Value, kpi2, kpi2Value],
  );

  return (
    <Fragment>
      {!kpi1 && !kpi2 ? (
        <div
          css={css`
            margin-bottom: 24px;
          `}
        >
          <AlertBanner
            data-testid="no-kpis-info-banner"
            variant={AlertBannerVariant.INFO}
          >
            <FormattedMessage defaultMessage="No KPI to display. Please contact your admin if you believe this is an error." />
          </AlertBanner>
        </div>
      ) : (
        ((isNil(kpi1Value) && !!kpi1) || (isNil(kpi2Value) && !!kpi2)) && (
          <div
            css={css`
              margin-bottom: 24px;
            `}
          >
            <AlertBanner variant={AlertBannerVariant.WARNING}>
              <FormattedMessage defaultMessage="No data available for the selected view." />
            </AlertBanner>
          </div>
        )
      )}

      <div
        css={css`
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: row;
          gap: 24px;
        `}
      >
        <div>
          <StatementGaugeGroup
            gap={6}
            progress={gaugeProgress}
            width={160}
          />
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 8px;
          `}
        >
          {!!kpi1 && (
            <KpiPill
              color={theme.ds.colors.primary[400]}
              label={kpi1?.label}
              value={kpi1Value}
            />
          )}
          {!!kpi2 && (
            <KpiPill
              color={theme.ds.colors.primary[200]}
              label={kpi2?.label}
              value={kpi2Value}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
});
