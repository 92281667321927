enum EnvironmentEnum {
  production = 'production',
  staging = 'staging',
  development = 'development',
}
export const useHostNameEnvironment = (): EnvironmentEnum => {
  const hostname = window.location.hostname;

  if (hostname.includes(EnvironmentEnum.staging)) {
    return EnvironmentEnum.staging;
  }

  if (hostname.includes('localhost')) {
    return EnvironmentEnum.development;
  }

  return EnvironmentEnum.production;
};
