import { css } from '@emotion/react';
import { FormHelperText } from '@mui/material';
import { keyBy } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { IconButton, type TablerIconElement } from '@amalia/design-system/components';
import { type ValueTargetChangeHandler } from '@amalia/ext/react/types';
import { type UserComputed } from '@amalia/tenants/users/types';

import { type Option } from '../../../../../utils/common.types';
import { FormElementContainer, Input, InputLabel } from '../../../formElements/inputs/Input/Input';
import { type SelectFieldBaseProps, useSelectFieldStyles } from '../../../formElements/SelectField/SelectField';
import { InfoWithTooltip } from '../../../InfoWithTooltip';
import { Text, TextType } from '../../../typography';

import { UserSelect, type UserSelectProps } from './UserSelect';

export type UserSelectFieldBaseProps = Omit<UserSelectProps, 'children' | 'render' | 'users' | 'value'> &
  Pick<SelectFieldBaseProps, 'actions' | 'error' | 'help' | 'id' | 'label' | 'labelClassName' | 'required'> & {
    readonly userOptions: Option<string>[];
    readonly value: Option<string>['value'][];
    readonly disabled?: boolean;
    readonly onChange: ValueTargetChangeHandler<string[]>;
  };

export const UserSelectFieldBase = memo(function UserSelectFieldBase({
  labelClassName,
  actions,
  label,
  help,
  required,
  id,
  error,
  userOptions,
  value,
  onChange,
  disabled,
  multiple,
  ...props
}: UserSelectFieldBaseProps) {
  const classes = useSelectFieldStyles();

  /**
   * The next part is actually kinda ugly.
   *
   * We want to make the User Selector behave exactly like the Select field. Which means
   * it accepts a value/label option, store its data as an array of string, and return the new
   * value in a "synthetic" html event.
   */
  const users: UserComputed[] = useMemo(
    () =>
      (userOptions ?? []).map(({ value, label }) => ({
        id: value,
        email: value,
        firstName: label,
        lastName: '',
        externalId: null,
        pictureURL: null,
      })),
    [userOptions],
  );

  const usersDictionary = useMemo(() => keyBy(users, 'id'), [users]);

  const selectedUsers = useMemo(
    () => (value || []).map((v) => usersDictionary[v]).filter(Boolean),
    [usersDictionary, value],
  );

  const displayedValue = useMemo(() => selectedUsers.map((u) => u.firstName).join(', '), [selectedUsers]);

  const onChangeProxy = useCallback(
    (newValue: UserComputed[]) => onChange({ target: { value: (newValue || []).map((u) => u.id) } }),
    [onChange],
  );

  const computedLabelWithIndicator = useMemo(
    () => (
      <FormattedMessage
        defaultMessage="{label}{hasSeparator, select, true { - } other {}}{hasSelected, select, true {{selectedCount, number} selected} other {}}"
        values={{
          label,
          hasSeparator: !!label && !!value?.length,
          hasSelected: !!value?.length,
          selectedCount: value?.length,
        }}
      />
    ),
    [value, label],
  );

  const commonUserSelectProps = {
    ...props,
    centered: true,
    disabled,
    title: 'Filter User',
    users,
    onChange: onChangeProxy,
  };

  const userSelectProps: UserSelectProps = multiple
    ? {
        ...commonUserSelectProps,
        multiple,
        value: selectedUsers,
      }
    : {
        ...commonUserSelectProps,
        value: selectedUsers[0] ?? null,
      };

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <UserSelect {...userSelectProps}>
        {({ onClick }) => (
          <FormElementContainer relative>
            {label ? (
              <InputLabel
                className={labelClassName}
                htmlFor={id}
              >
                <Text type={TextType.INPUT_NAME}>
                  {computedLabelWithIndicator}
                  {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                  {required ? ' *' : null}
                  {help ? <InfoWithTooltip>{help}</InfoWithTooltip> : null}
                </Text>
              </InputLabel>
            ) : null}
            {actions ? (
              <div className={classes.actions}>
                {(actions || []).map(({ tooltipTitle, content, callback }, index) => (
                  <IconButton
                    key={index}
                    className={classes.actionButton}
                    icon={content as TablerIconElement}
                    label={tooltipTitle}
                    size={IconButton.Size.LARGE}
                    onClick={callback}
                  />
                ))}
              </div>
            ) : null}
            <Input
              autoComplete="off"
              id={id}
              value={displayedValue}
              onClick={onClick}
            />
            {!!error && <FormHelperText error>{error}</FormHelperText>}
          </FormElementContainer>
        )}
      </UserSelect>
    </div>
  );
});
