import { isEmpty } from 'lodash';
import { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { isHttpError } from '@amalia/core/http/client';
import { useSnackbars } from '@amalia/design-system/components';
import { config } from '@amalia/kernel/config/client';
import { BulkCsvImportModal } from '@amalia/lib-ui-business';
import { UserApiClient } from '@amalia/tenants/users/api-client';
import { type SyncUserRequest, toUserProperty } from '@amalia/tenants/users/types';

const { csvMandatoryColumns, csvOptionalColumns, userUploadTemplate } = config.directory;

export type UploadMembersModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
};

export const UploadMembersModal = memo(function UploadMembersModal({ isOpen, onClose }: UploadMembersModalProps) {
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  const onSubmitUploadModal = useCallback(
    (users: SyncUserRequest[]): void => {
      if (isEmpty(users)) {
        snackError(
          formatMessage({
            defaultMessage: 'File is empty or content format is wrong',
          }),
        );
        return;
      }

      UserApiClient.bulkSyncUsers(users)
        .then(() => {
          snackSuccess(
            formatMessage({
              defaultMessage: 'Members added!',
            }),
          );
          // Close the modal.
          onClose();
        })
        .catch((error) => {
          if (isHttpError(error) && error.statusCode === 400 && error.message) {
            snackError(
              formatMessage(
                {
                  defaultMessage: 'An unexpected error occurred while uploading the file: {error}',
                },
                { error: error.message },
              ),
            );
            return;
          }
          snackError(
            formatMessage({
              defaultMessage: 'An error occurred during the upload of the file, please contact your administrator',
            }),
          );
        });
    },
    [formatMessage, onClose, snackSuccess, snackError],
  );

  return (
    <BulkCsvImportModal
      isOpen={isOpen}
      mandatoryColumns={csvMandatoryColumns}
      optionalColumns={csvOptionalColumns}
      submitUploadedRows={onSubmitUploadModal}
      templateLink={userUploadTemplate}
      transform={toUserProperty}
      onClose={onClose}
    />
  );
});
