import { type FocusEventHandler, useCallback, useEffect, useState } from 'react';

import { useFormikFieldAdapter } from '@amalia/ext/formik';

/**
 * To avoid triggering Formik's onChange on every keystroke, this hook stores the value of the input in an internal state.
 * When the input is blurred, the internal value is used to trigger Formik's onChange.
 *
 * This hooks is necessary since we autosave at every change in the form and not each time a field is blurred.
 */
export const useTriggerChangeOnBlur = <TName extends 'externalId' | 'hrisId'>({
  name,
  onBlur,
}: {
  name: TName;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}) => {
  const {
    value: fieldValue,
    onChange: onChangeField,
    onBlur: onBlurField,
    ...formikFieldProps
  } = useFormikFieldAdapter<string | null>({
    name,
    type: 'text',
  });

  // Internal value is used to store the value of the input before triggering onChangeField from Formik.
  const [internalValue, setInternalValue] = useState<string | null>(() => fieldValue || null);

  // Reinitialize internal value when Formik field value changes
  useEffect(() => {
    setInternalValue(fieldValue || null);
  }, [fieldValue]);

  const onChange = useCallback((value: string) => setInternalValue(value || null), []);

  const onBlurProxy: FocusEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      onChangeField(internalValue);
      onBlurField(event);
      onBlur?.(event);
    },
    [onBlur, onChangeField, onBlurField, internalValue],
  );

  return {
    ...formikFieldProps,
    value: internalValue ?? '',
    onChange,
    onBlur: onBlurProxy,
  };
};
