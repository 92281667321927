import { useMemo } from 'react';

import { type ColumnDefinitions, type RowData } from '../../table/Table.types';
import { type ColumnVisibilityState } from '../DataGrid.types';

export const useVisibleColumns = <TData extends RowData>(
  columns: ColumnDefinitions<TData>,
  columnVisibility?: ColumnVisibilityState,
) =>
  useMemo(() => {
    const hasHiddenColumn = Object.values(columnVisibility || {}).includes(false);
    return hasHiddenColumn ? columns.filter((column) => columnVisibility?.[column.id] !== false) : columns;
  }, [columns, columnVisibility]);
