import { capitalize } from 'lodash';

import { AmaliaAccessorKeywords } from '@amalia/amalia-lang/tokens/types';
import { CONNECTORS, StringUtils } from '@amalia/core/types';
import { DataConnectorTypes, type ConnectorResponse } from '@amalia/data-capture/connectors/types';

/**
 * Check if data connector object name is valid (is not an Amalia reserved keyword).
 * @param name
 */
export const isObjectNameValid = (name: string) => {
  const reservedKeywords: string[] = Object.values(AmaliaAccessorKeywords);
  return !reservedKeywords.includes(StringUtils.camelCase(name));
};

export const getDataConnectorName = (dataConnector?: Pick<ConnectorResponse, 'alias' | 'type'>) => {
  if (!dataConnector) return null;
  if (
    [DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT, DataConnectorTypes.SALESFORCE_THIRD_ACCOUNT].includes(
      dataConnector.type,
    ) &&
    dataConnector.alias
  ) {
    return `Salesforce ${dataConnector.alias}`;
  }
  return CONNECTORS[dataConnector.type]?.name || capitalize(dataConnector.type);
};
