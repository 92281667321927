import { defineMessages } from 'react-intl';

import {
  FormulaBuilderNumberOperatorNoArgs,
  FormulaBuilderNumberOperatorOneArg,
} from '@amalia/amalia-lang/formula/types';

export const operatorMessage = defineMessages<FormulaBuilderNumberOperatorNoArgs | FormulaBuilderNumberOperatorOneArg>({
  [FormulaBuilderNumberOperatorNoArgs.IS_BLANK]: {
    defaultMessage: 'Is blank',
  },
  [FormulaBuilderNumberOperatorNoArgs.IS_NOT_BLANK]: {
    defaultMessage: 'Is not blank',
  },
  [FormulaBuilderNumberOperatorOneArg.EQUALS]: {
    defaultMessage: 'Equals to',
  },

  [FormulaBuilderNumberOperatorOneArg.NOT_EQUALS]: {
    defaultMessage: 'Not equals to',
  },

  [FormulaBuilderNumberOperatorOneArg.GREATER_THAN]: {
    defaultMessage: 'Greater than',
  },

  [FormulaBuilderNumberOperatorOneArg.GREATER_THAN_OR_EQUAL]: {
    defaultMessage: 'Greater than or equals to',
  },

  [FormulaBuilderNumberOperatorOneArg.LESS_THAN]: {
    defaultMessage: 'Less than',
  },

  [FormulaBuilderNumberOperatorOneArg.LESS_THAN_OR_EQUAL]: {
    defaultMessage: 'Less than or equals to',
  },
} as const);

export const fieldOperatorMessages = defineMessages<FormulaBuilderNumberOperatorOneArg>({
  [FormulaBuilderNumberOperatorOneArg.EQUALS]: {
    defaultMessage: '“{propertyName}” equals to',
  },
  [FormulaBuilderNumberOperatorOneArg.NOT_EQUALS]: {
    defaultMessage: '“{propertyName}” not equals to',
  },
  [FormulaBuilderNumberOperatorOneArg.GREATER_THAN]: {
    defaultMessage: '“{propertyName}” greater than',
  },
  [FormulaBuilderNumberOperatorOneArg.GREATER_THAN_OR_EQUAL]: {
    defaultMessage: '“{propertyName}” greater or equals to',
  },
  [FormulaBuilderNumberOperatorOneArg.LESS_THAN]: {
    defaultMessage: '“{propertyName}” less than',
  },
  [FormulaBuilderNumberOperatorOneArg.LESS_THAN_OR_EQUAL]: {
    defaultMessage: '“{propertyName}” less or equals to',
  },
});
