import { type ColumnDefinitions, type RowData } from '../../table/Table.types';
import { type ColumnOrderState, type ColumnVisibilityState } from '../DataGrid.types';

import { useReorderedColumns } from './useReorderedColumns';
import { useVisibleColumns } from './useVisibleColumns';

export const useDisplayedColumns = <TData extends RowData>(
  columns: ColumnDefinitions<TData>,
  {
    columnVisibility,
    columnOrder,
  }: {
    columnVisibility?: ColumnVisibilityState;
    columnOrder?: ColumnOrderState;
  },
) => {
  const visibleColumns = useVisibleColumns(columns, columnVisibility);
  return useReorderedColumns(visibleColumns, columnOrder);
};
