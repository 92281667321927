import { ClassNames } from '@emotion/react';
import clsx from 'clsx';
import { type ReactNode, memo, cloneElement, Fragment } from 'react';

import { toLinkElement, type ToOrLinkElement } from '@amalia/ext/react-router-dom';

import { type TablerIconElement } from '../../../general/icons/types';
import { Typography } from '../../../general/typography/Typography';

import * as styles from './MenuItem.styles';

export type MenuItemProps = {
  /** Href. */
  readonly to: ToOrLinkElement<false>;
  /** Active state. */
  readonly isActive?: boolean;
  /** Left icon. */
  readonly icon: TablerIconElement;
  /** Label. */
  readonly children: ReactNode;
};

export const MenuItem = memo(function MenuItem({ to, isActive, icon, children }: MenuItemProps) {
  const linkElement = toLinkElement(to);

  return (
    <li css={styles.menuItem}>
      <ClassNames>
        {({ css, theme }) =>
          cloneElement(
            linkElement,
            {
              className: clsx(
                linkElement.props.className,
                { [styles.IS_ACTIVE_CLASSNAME]: isActive },
                styles.menuItemLink({ css, theme }),
              ),
            },
            <Fragment>
              {cloneElement(icon, {
                size: 16,
                className: css`
                  flex: none;
                `,
              })}

              <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>{children}</Typography>
            </Fragment>,
          )
        }
      </ClassNames>
    </li>
  );
});
