import { type ReactNode, memo, Fragment } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { type FatTabInnerProps } from '../../fat-tab/fat-tab-inner/FatTabInner';
import { type CardWithTabsValue } from '../CardWithTabs.types';

export type CardWithTabsTabProps = MergeAll<
  [
    FatTabInnerProps,
    {
      value: CardWithTabsValue;
      children: ReactNode;
    },
  ]
>;

export const CardWithTabsTab = memo(function CardWithTabsTab(_props: CardWithTabsTabProps) {
  return <Fragment>{null}</Fragment>;
});
