import { css } from '@emotion/react';

export const cellQuickSwitch = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  > * {
    flex: none;
  }
`;
