import { makeStyles } from '@mui/styles';

import { type AmaliaThemeType } from '@amalia/ext/mui/theme';

export const useUserSelectStyles = makeStyles((theme: AmaliaThemeType) => ({
  button: {
    width: 15,
    height: 15,
    color: theme.palette.common.white,
    zIndex: 100,
  },
  dialog: {
    width: 250,
    height: 250,
    position: 'absolute',
    bottom: -10,
    background: theme.palette.common.white,
    transform: 'translate(-20%, 100%)',
    zIndex: 1000,
    borderRadius: 5,
    boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
  },
  dialog__centered: {
    width: '100%',
    transform: 'translate(0, calc(100% - 3rem - 2px))',
  },
  dialogTitle: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(0.75, 0),
    color: theme.palette.secondary.main,
  },
  userList: {
    padding: theme.spacing(0.5, 0),
    height: 180,
    overflowY: 'auto',
    flex: 1,
    '& > li + li': {
      marginTop: '1px',
    },
  },
  avatarList: {
    height: 30,
    width: 30,
  },
}));
