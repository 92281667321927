import { memo } from 'react';

import {
  PayoutAndPerformanceChart,
  type PayoutAndPerformanceChartStyles,
  type PayoutAndPerformanceChartProps,
  useFillCurrentYearPeriodsRecords,
} from '@amalia/lib-ui-business';
import { type Period } from '@amalia/payout-definition/periods/types';

const chartStyles: PayoutAndPerformanceChartStyles = {
  bar: {
    widthSmall: 8,
    widthLarge: 32,
    radiusSmall: [1, 1, 0, 0],
    radiusLarge: [4, 4, 0, 0],
    label: {
      fontSize: 9,
      fontWeight: 400,
    },
  },
  xAxis: {
    offset: 6,
    fontSize: 9,
    fontWeight: 500,
  },
  yAxis: {
    fontSize: 9,
    fontWeight: 400,
  },
};

export type StatementSummaryPayoutAndPerformanceChartProps = Omit<PayoutAndPerformanceChartProps, 'styles'> & {
  readonly period: Period;
};

export const StatementSummaryPayoutAndPerformanceChart = memo(function StatementSummaryPayoutAndPerformanceChart({
  period,
  isRecordActiveFn: propsIsRecordActiveFn,
  ...props
}: StatementSummaryPayoutAndPerformanceChartProps) {
  const { statistics, isRecordActiveFn } = useFillCurrentYearPeriodsRecords({
    statistics: props.statistics,
    frequency: props.frequency,
    period,
    isRecordActiveFn: propsIsRecordActiveFn,
  });

  return (
    <PayoutAndPerformanceChart
      {...props}
      isRecordActiveFn={isRecordActiveFn}
      statistics={statistics}
      styles={chartStyles}
    />
  );
});
