import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ChangelogRole } from '@amalia/amalia-meta/changelog/shared';
import { Badge, BadgeHue, type BadgeProps } from '@amalia/design-system/components';
import { type MergeAll } from '@amalia/ext/typescript';

import { changelogEntryRoleMessages } from '../../../intl/changelog.messages';

const ChangelogRoleToBadgeVariant = {
  [ChangelogRole.ADMIN]: BadgeHue.MAGENTA,
  [ChangelogRole.MANAGER]: BadgeHue.CYAN,
  [ChangelogRole.SALESREP]: BadgeHue.BLUE,
} as const satisfies Record<ChangelogRole, BadgeProps['variant']>;

export type ChangelogEntryRoleBadgeProps = MergeAll<
  [
    Omit<BadgeProps, 'children' | 'variant'>,
    {
      readonly role: ChangelogRole;
    },
  ]
>;

export const ChangelogEntryRoleBadge = memo(function ChangelogEntryRoleBadge({
  role,
  size = Badge.Size.SMALL,
  ...props
}: ChangelogEntryRoleBadgeProps) {
  return (
    <Badge
      {...props}
      size={size}
      variant={ChangelogRoleToBadgeVariant[role]}
    >
      <FormattedMessage {...changelogEntryRoleMessages[role]} />
    </Badge>
  );
});
