import { memo, type ReactNode } from 'react';

import { useFetchAuthenticatedContext } from '@amalia/kernel/auth/state';
import { type LocaleBundle } from '@amalia/kernel/intl/components';
import { AmaliaLocalizationProvider, type AmaliaLocalizationProviderProps } from '@amalia/kernel/intl/state';
import { type LanguagesEnum } from '@amalia/kernel/intl/types';

export type WebLocalizationProviderProps = {
  readonly children: ReactNode;
};

const importLocale: AmaliaLocalizationProviderProps<LanguagesEnum>['importLocale'] = async (locale) =>
  ((await import(`../assets/i18n/${locale}`)) as { bundle: LocaleBundle<LanguagesEnum> }).bundle;

export const WebLocalizationProvider = memo(function WebLocalizationProvider({
  children,
}: WebLocalizationProviderProps) {
  // Cannot use useCurrentUser because this provider is above the AuthContext.
  const { authenticatedContext } = useFetchAuthenticatedContext();

  return (
    <AmaliaLocalizationProvider
      importLocale={importLocale}
      locale={authenticatedContext?.user?.language}
    >
      {children}
    </AmaliaLocalizationProvider>
  );
});
