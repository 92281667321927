import { http } from '@amalia/core/http/client';
import {
  type Calculation,
  CalculationStatus,
  type CalculationRequest,
  CalculationTrigger,
  type CalculationAnalyze,
  CALCULATION_ONGOING_STATUSES,
} from '@amalia/core/types';

const apiEndpoint = '/calculations';

export class CalculationsApiClient {
  public static async calculateStatement(calculationRequest: CalculationRequest): Promise<Calculation> {
    const { data } = await http.post<Calculation>(`${apiEndpoint}/`, {
      periodId: calculationRequest.periodId || undefined,
      userIds:
        !!calculationRequest.userIds && calculationRequest.userIds.length > 0 ? calculationRequest.userIds : undefined,
      teamId: calculationRequest.teamId || undefined,
      planId: calculationRequest.planId || undefined,
      trigger: CalculationTrigger.MANUAL,
      type: calculationRequest.type || undefined,
      dataConnectorObjectsNames: calculationRequest.dataConnectorObjectsNames?.length
        ? calculationRequest.dataConnectorObjectsNames
        : undefined,
    });
    return data;
  }

  public static async analyzeCalculation(calculationRequest: CalculationRequest): Promise<CalculationAnalyze> {
    const { data } = await http.post<CalculationAnalyze>(`${apiEndpoint}/analyze`, {
      periodId: calculationRequest.periodId || undefined,
      userIds: calculationRequest.userIds?.length ? calculationRequest.userIds : undefined,
      teamId: calculationRequest.teamId || undefined,
      planId: calculationRequest.planId || undefined,
      trigger: CalculationTrigger.MANUAL,
      type: calculationRequest.type || undefined,
    });
    return data;
  }

  public static async getRunningCalculation(periodId: string): Promise<Calculation | undefined> {
    const { data } = await http.get<Calculation[]>(`${apiEndpoint}/${periodId}`, {
      params: {
        status: CALCULATION_ONGOING_STATUSES,
      },
    });

    return data[0];
  }

  public static async getLastCalculationForPeriod(periodId: string) {
    const { data } = await http.get<Calculation[]>(`${apiEndpoint}/${periodId}`, {
      params: {
        count: 1,
      },
    });

    return data[0];
  }

  public static async stopCalculation(calculation: Calculation): Promise<void> {
    await http.patch(`${apiEndpoint}/${calculation.id}`, { status: CalculationStatus.STOPPING });
  }
}
