import { type Editor } from '@tiptap/core';
import { type Node } from 'prosemirror-model';
import { createContext, useContext } from 'react';

import { assert } from '@amalia/ext/typescript';

import { type FormulaEditorToken } from '../../../types/formulaEditorToken';

export type FormulaEditorContextValue = {
  /** List of all the available tokens in the formula, scoped to the current plan. */
  planTokens: Record<FormulaEditorToken['formula'], FormulaEditorToken | undefined>;
  openTokenInTabHandler: (token: FormulaEditorToken) => void;
  activeNode?: { node: Node; position: number };
  editor: Editor;
};

export const FormulaEditorContext = createContext<FormulaEditorContextValue | null>(null);

export const useFormulaEditorContext = (): FormulaEditorContextValue => {
  const context = useContext(FormulaEditorContext);
  assert(context, 'useFormulaEditorContext must be used within a <FormulaEditorContext.Provider>');
  return context;
};
