import { http } from '@amalia/core/http/client';

export async function saDatabaseReplicationSync(): Promise<void> {
  return http.post('/sa_settings/database/sync-replication');
}

export async function saDatabaseCreateMasterTable(): Promise<void> {
  return http.post('/sa_settings/database/create-master-tables');
}

export async function saDatabaseRecreateCompanySchemas(): Promise<void> {
  return http.post('/sa_settings/database/recreate-company-schemas');
}
