import { useContext } from 'react';

import { AuthContext } from '../contexts/auth.context';

export const useAuthenticatedContext = () => {
  const authenticatedContext = useContext(AuthContext);

  // FIXME: this is for backward compatibility
  return {
    authenticatedContext,
  };
};
