import { memo, type ReactNode } from 'react';

import { TextOverflow } from '../../../../../general/text-overflow/TextOverflow';

import * as styles from './CellTextFieldValue.styles';

export type CellTextFieldValueProps = {
  readonly isBlurred: boolean;
  readonly formattedValue: ReactNode;
  readonly placeholder?: string;
};

export const CellTextFieldValue = memo(function CellTextFieldValue({
  isBlurred,
  formattedValue,
  placeholder,
}: CellTextFieldValueProps) {
  return (
    <TextOverflow
      aria-hidden={isBlurred}
      css={[styles.cellTextFieldValue, !formattedValue && styles.disabledPlaceholder]}
    >
      {isBlurred ? <div css={styles.blurred} /> : formattedValue || placeholder}
    </TextOverflow>
  );
});
