import styled from '@emotion/styled';

export const DropdownItemContentActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  &:empty {
    display: none;
  }
`;
