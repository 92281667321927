import { type FormatsEnum } from './formats';

export enum PropertyRef {
  USER = 'USER',
}

export interface Property {
  name: string;
  machineName: string;
  format: FormatsEnum;

  isEnum?: boolean;

  isRequired?: boolean;

  // This optional ref is used to indicate that
  // the property is a reference to another object, like a user.
  ref?: PropertyRef;
}
