import { useMemo } from 'react';

import { CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import {
  type CustomReportColumn,
  type PayoutAndPerformanceChartStatisticsRecord,
} from '@amalia/reporting/custom-reports/shared';

const computeCommissionSum = (ytdRecords: PayoutAndPerformanceChartStatisticsRecord[]) =>
  ytdRecords.reduce((acc, record) => acc + (record.ruleMetricPayment__value?.value || 0), 0);

const computeKpiAverage = (ytdRecords: PayoutAndPerformanceChartStatisticsRecord[], kpiIdentifier: string) =>
  ytdRecords.reduce((acc, record) => acc + (record[kpiIdentifier] as number), 0) / ytdRecords.length;

export const useYtdRecord = (
  ytdRecords: PayoutAndPerformanceChartStatisticsRecord[] = [],
  kpi1?: CustomReportColumn,
  kpi2?: CustomReportColumn,
) =>
  useMemo(() => {
    const record: PayoutAndPerformanceChartStatisticsRecord = {
      ruleMetricPeriod__month: '',
      ruleMetricPayment__value: { value: 0, symbol: CurrencySymbolsEnum.EUR },
    };

    if (!ytdRecords.length) {
      return record;
    }

    const amountSum = computeCommissionSum(ytdRecords);
    const symbol = ytdRecords.find((r) => !!r.ruleMetricPayment__value?.symbol)?.ruleMetricPayment__value?.symbol;

    if (ytdRecords.some((r) => r.ruleMetricPayment__value)) {
      record.ruleMetricPayment__value = {
        value: amountSum,
        symbol,
      };
    }

    if (kpi1) {
      record[kpi1.identifier] = computeKpiAverage(ytdRecords, kpi1.identifier);
    }

    if (kpi2) {
      record[kpi2.identifier] = computeKpiAverage(ytdRecords, kpi2.identifier);
    }
    return record;
  }, [kpi1, kpi2, ytdRecords]);
