import { css } from '@emotion/react';
import { IconSearch } from '@tabler/icons-react';
import { isEmpty } from 'lodash';
import { memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type DatasetRow, type CustomObject, type Option } from '@amalia/core/types';
import { AlertBanner, Input, Modal, useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import { useDebouncedInput, useStatementDetailContext } from '@amalia/lib-ui';
import { StatementDatasetsApiClient } from '@amalia/payout-calculation/statements/state';
import { type Dataset } from '@amalia/payout-calculation/types';

import { EmptyModal } from './EmptyModal';
import { SearchRecordResults } from './SearchRecordResults';
import { useSearchRecords } from './useSearchRecords';

interface AddRecordsModalProps {
  readonly statementId: string;
  readonly isOpen: boolean;
  readonly onClose?: () => void;
  readonly onSetAddedRows: (rows: DatasetRow[]) => void;
  readonly dataset: Dataset;
}

export const AddRecordsModal = memo(function AddRecordsModal({
  statementId,
  isOpen,
  onClose,
  dataset,
  onSetAddedRows,
}: AddRecordsModalProps) {
  const { launchCalculation } = useStatementDetailContext();
  const { formatMessage } = useIntl();

  const { snackError } = useSnackbars();

  const [searchValue, setSearchValue] = useState<string | null>();
  const [searchValueDebounced, setSearchValueDebounced] = useState<string | null>();
  const [selectedRecords, setSelectedRecords] = useState<Option[]>([]);
  const { onChange: onDebouncedSearchInputChange } = useDebouncedInput(setSearchValue, setSearchValueDebounced, 1000);

  const { data: searchRecords, isFetching } = useSearchRecords(
    dataset?.customObjectDefinition?.machineName,
    searchValueDebounced,
  );

  const foundRecords = useMemo(
    () =>
      searchRecords?.map((item: CustomObject) => ({
        label: item.name,
        value: item.id,
      })),
    [searchRecords],
  );

  const addRecordsProxy = useCallback(async () => {
    try {
      const newRows = await StatementDatasetsApiClient.addDatasetRows(statementId, dataset.id, {
        customObjectIds: selectedRecords?.map((item) => item.value),
      });

      onSetAddedRows(newRows);

      onClose();

      await launchCalculation?.();
    } catch (e) {
      snackError(toError(e).message);
    }
  }, [statementId, dataset.id, selectedRecords, onSetAddedRows, onClose, launchCalculation, snackError]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Add records" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <AlertBanner>
            <FormattedMessage defaultMessage="Add one or several of your records and simulate your results." />
          </AlertBanner>

          <Input
            id="search-input"
            leftIcon={<IconSearch />}
            placeholder={formatMessage({ defaultMessage: 'Search records' })}
            value={searchValue}
            css={css`
              margin: 16px 0;
            `}
            onChange={onDebouncedSearchInputChange}
          />

          {!isFetching && isEmpty(foundRecords) ? (
            <EmptyModal search={searchValueDebounced} />
          ) : (
            <SearchRecordResults
              foundRecords={foundRecords}
              isSearchRecordsLoading={isFetching}
              selectedRecords={selectedRecords}
              setSelectedRecords={setSelectedRecords}
            />
          )}
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />
        <Modal.MainAction onClick={addRecordsProxy}>
          <FormattedMessage defaultMessage="Apply" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
