import { type ParserScope } from '@amalia/amalia-lang/formula/evaluate/shared';
import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { type ComputeEnginePrimitiveTypes } from '@amalia/payout-calculation/types';

import AmaliaFunction from '../../AmaliaFunction';

import type Math from 'mathjs';

const func = new AmaliaFunction(AmaliaFunctionKeys.DEFAULT, AmaliaFunctionCategory.MISC);

func.nbParamsRequired = 2;

func.description = 'Return a default value if the parameter is null or undefined';

func.params = [
  { name: 'parameter', description: 'Variables, Fields or Properties' },
  { name: 'defaultValue', description: 'Value to return if parameter is null or undefined.' },
];

func.examples = [
  {
    desc: 'Returns the close date if it exists else, it returns the end date of the period.',
    formula: 'DEFAULT(opportunity.closeDate, statementPeriod.endDate)' as AmaliaFormula,
  },
  {
    desc: 'Returns the invoice date if it exists else, it returns an empty string.',
    formula: 'DEFAULT(opportunity.invoice.closeDate, "")' as AmaliaFormula,
  },
  {
    desc: 'Returns the amount cancelled if it exists else, it returns 0.',
    formula: 'DEFAULT(opportunity.amountCancelled, 0)' as AmaliaFormula,
  },
];

func.execRawArgs = (args: Math.MathNode[], _, scope: ParserScope): ComputeEnginePrimitiveTypes => {
  const [parameter, defaultValue] = args;

  try {
    const evaluationResult = parameter.evaluate(scope) as ComputeEnginePrimitiveTypes | undefined;

    if (evaluationResult !== undefined && evaluationResult !== null) {
      return evaluationResult;
    }
  } catch (e) {
    // Silence TypeErrors.
    if (!(e instanceof TypeError)) {
      throw e;
    }
  }

  // If we're here, it means that either the evaluation returned null, or threw a type error.
  // Fallback to the default value.
  return defaultValue.evaluate(scope) as ComputeEnginePrimitiveTypes;
};

export default func;
