import { css } from '@emotion/react';
import { IconArrowRight, IconX } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { ChangelogReleaseType, type ChangelogEntry } from '@amalia/amalia-meta/changelog/shared';
import { routes } from '@amalia/core/routes';
import { Button, ButtonLink, Group, Paper, Stack, Typography } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';
import { Link } from '@amalia/ext/react-router-dom';

export interface ChangelogPopupProps {
  readonly changelogEntries: ChangelogEntry[];
}

export const ChangelogPopup = memo(function ChangelogPopup({ changelogEntries }: ChangelogPopupProps) {
  const { isOpen, setOpenFalse } = useBoolState(true, 'open');

  if (!isOpen) {
    return null;
  }

  const hasNewFeature = changelogEntries.some((entry) => entry.releaseType === ChangelogReleaseType.NEW_RELEASE);
  const hasImprovement = changelogEntries.some((entry) => entry.releaseType === ChangelogReleaseType.IMPROVEMENT);

  return (
    <Paper
      data-testid="changelog-popup"
      css={(theme) => css`
        box-shadow: ${theme.ds.shadows.hard};
        position: fixed;
        padding: 16px;
        bottom: 24px;
        left: 24px;
        z-index: 1000;
        width: 360px;
      `}
    >
      <Stack gap={16}>
        <Group
          align="center"
          justify="space-between"
        >
          <Typography variant={Typography.Variant.HEADING_4_BOLD}>
            <FormattedMessage defaultMessage="Latest updates" />
          </Typography>

          <Button
            icon={<IconX />}
            size={Button.Size.SMALL}
            variant={Button.Variant.LIGHT_TEXT}
            onClick={setOpenFalse}
          >
            <FormattedMessage defaultMessage="Close" />
          </Button>
        </Group>

        <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
          {(() => {
            switch (true) {
              case hasNewFeature && hasImprovement:
                return (
                  <FormattedMessage defaultMessage="Since your last visit, we released new features and improvements." />
                );
              case hasNewFeature:
                return <FormattedMessage defaultMessage="Since your last visit, we released new features." />;
              case hasImprovement:
              default:
                return <FormattedMessage defaultMessage="Since your last visit, we made improvements." />;
            }
          })()}
        </Typography>

        <div
          css={css`
            align-self: flex-end;
          `}
        >
          <ButtonLink
            icon={<IconArrowRight />}
            iconPosition={ButtonLink.IconPosition.RIGHT}
            size={ButtonLink.Size.SMALL}
            variant={ButtonLink.Variant.PRIMARY}
            to={
              <Link
                openInNewTab
                to={generatePath(routes.CHANGELOG)}
              />
            }
            onClick={setOpenFalse}
          >
            <FormattedMessage defaultMessage="Go to changelog" />
          </ButtonLink>
        </div>
      </Stack>
    </Paper>
  );
});
