import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { IconChevronDown } from '@tabler/icons-react';
import { memo, type ReactNode } from 'react';

const StyledAccordion = withStyles((theme) => ({
  root: {
    margin: '0 !important',
    padding: 0,
    paddingTop: theme.spacing(2),
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    margin: 0,
  },
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    display: 'inline-flex',
    padding: 0,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  expanded: {
    minHeight: 'initial !important',

    '& $content': {
      margin: 'initial',
      marginRight: theme.spacing(2),
    },
  },
  content: {
    display: 'block',
    margin: 'initial',
    marginRight: theme.spacing(2),
  },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    borderLeft: '2px solid rgba(0, 0, 0, 0.1)',
    display: 'block',

    '& > div:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
}))(MuiAccordionDetails);

interface AccordionProps {
  readonly title: ReactNode;
  readonly expanded?: boolean;
  readonly children?: ReactNode;
}

export const Accordion = memo(function Accordion({ title, expanded, children }: AccordionProps) {
  return (
    <StyledAccordion
      square
      expanded={expanded}
    >
      <AccordionSummary expandIcon={<IconChevronDown />}>{title}</AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
});
