import { useFormikContext } from 'formik';
import { type ChangeEvent, memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { Input } from '@amalia/lib-ui';

import { OverwriteFormField, type OverwriteFormValuesShape } from '../../overwrite.types';

import { messages } from './selectors.messages';

export const OVERWRITE_INPUT_TEST_ID = `${OverwriteFormField.NEW_VALUE}-input`;

export const TextOverwriteSelector = memo(function TextOverwriteSelector() {
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useFormikContext<OverwriteFormValuesShape>();

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setFieldValue(OverwriteFormField.NEW_VALUE, e.target.value),
    [setFieldValue],
  );

  return (
    <Input
      autoFocus
      data-testid={OVERWRITE_INPUT_TEST_ID}
      id={OverwriteFormField.NEW_VALUE}
      inputProps={{ 'aria-label': OverwriteFormField.NEW_VALUE }}
      name={OverwriteFormField.NEW_VALUE}
      placeholder={formatMessage(messages[FormatsEnum.text])}
      value={values.newValue}
      onChange={handleInputChange}
    />
  );
});
