import { css } from '@emotion/react';
import { memo } from 'react';

import { getCurrencySymbolCharacters } from '@amalia/core/types';
import { Group } from '@amalia/design-system/components';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';

interface EarningsCardCurrencyProps {
  readonly symbol: CurrencySymbolsEnum;
}

export const EarningsCardCurrency = memo(function EarningsCardCurrency({ symbol }: EarningsCardCurrencyProps) {
  return (
    <Group
      align="center"
      justify="center"
      css={(theme) => css`
        height: 30px;
        min-width: 30px;
        width: auto;
        border-radius: ${theme.ds.borderRadiuses.round};
        color: ${theme.ds.colors.gray[0]};
        background-color: ${theme.ds.colors.primary[400]};
      `}
    >
      {getCurrencySymbolCharacters(symbol)}
    </Group>
  );
});
