export const getOrCreatePortalNode = (id: string) => {
  const existingElement = document.getElementById(id);

  if (existingElement) {
    return existingElement;
  }

  const newElement = document.createElement('div');
  newElement.id = id;
  return document.body.appendChild(newElement);
};
