import { type Theme, css } from '@emotion/react';

import { TextAreaSize } from './TextArea.types';

export const HAS_ERROR_CLASSNAME = 'hasError';

export const textarea = (theme: Theme) => css`
  border: 1px solid ${theme.ds.colors.gray[100]};
  border-radius: ${theme.ds.borderRadiuses.squared};
  outline: none;

  width: 100%;
  resize: none;

  box-shadow: ${theme.ds.shadows.soft};

  transition: ${theme.ds.transitions.default('border-color', 'color', 'box-shadow', 'background-color', 'padding')};

  color: ${theme.ds.colors.gray[900]};
  background-color: ${theme.ds.colors.gray[0]};

  &:focus-visible {
    border-color: ${theme.ds.colors.primary[200]};

    &::placeholder {
      color: ${theme.ds.colors.gray[400]};
    }
  }

  &::placeholder {
    color: ${theme.ds.colors.gray[400]};
  }

  &:disabled {
    color: ${theme.ds.colors.gray[300]};
    background-color: ${theme.ds.colors.gray[50]};

    &::placeholder {
      color: ${theme.ds.colors.gray[200]};
    }
  }

  &:not(:disabled) {
    &.${HAS_ERROR_CLASSNAME} {
      border-color: ${theme.ds.colors.danger[500]};
    }
  }

  &.${TextAreaSize.SMALL} {
    ${theme.ds.typographies.bodyBaseRegular};

    padding: 7px 11px;

    &:focus-visible {
      box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.primary[50])};

      &.${HAS_ERROR_CLASSNAME} {
        box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.danger[100])};
      }
    }
  }

  &.${TextAreaSize.MEDIUM} {
    ${theme.ds.typographies.bodyLargeRegular};

    padding: 11px 15px;

    &:focus-visible {
      box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.primary[50])};

      &.${HAS_ERROR_CLASSNAME} {
        box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.danger[100])};
      }
    }
  }
`;
