import { IconTrash } from '@tabler/icons-react';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal, Typography } from '@amalia/design-system/components';
import { useDeleteDashboardChart } from '@amalia/reporting/dashboards-v2/state';
import { type ChartType, type DashboardChart } from '@amalia/reporting/dashboards-v2/types';

interface DashboardChartDeleteModalProps {
  readonly chartId: DashboardChart<ChartType>['id'];
  readonly chartName: DashboardChart<ChartType>['name'];
  readonly dashboardId: string;
  readonly onCloseModal: () => void;
}

export const DashboardChartDeleteModal = memo(function DashboardChartDeleteModal({
  chartId,
  chartName,
  dashboardId,
  onCloseModal,
}: DashboardChartDeleteModalProps) {
  const { mutate: deleteDashboardChart, isPending } = useDeleteDashboardChart(dashboardId, chartId);

  const handleClickDelete = useCallback(() => {
    deleteDashboardChart();
    onCloseModal();
  }, [deleteDashboardChart, onCloseModal]);

  return (
    <Modal
      isOpen
      variant={Modal.Variant.DANGER}
      onClose={onCloseModal}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Delete chart" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormattedMessage
            defaultMessage="Are you sure you want to delete <strong>{chartName}</strong>?"
            values={{
              strong: (chunks) => <Typography variant={Typography.Variant.BODY_BASE_BOLD}>{chunks}</Typography>,
              chartName,
            }}
          />
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />

        <Modal.MainAction
          icon={<IconTrash />}
          isLoading={isPending}
          onClick={handleClickDelete}
        >
          <FormattedMessage defaultMessage="Delete" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
