import styled from '@emotion/styled';
import { IconPlug, IconPlugOff } from '@tabler/icons-react';

import { DataConnectorTypes } from '@amalia/data-capture/connectors/types';
import { HueCategory } from '@amalia/design-system/meta';
import { type Asset } from '@amalia/ext/react/types';
import { UserExternalIdSource } from '@amalia/tenants/users/types';

import { type IntegrationLogos, VENDOR_LOGOS } from '../../../connectors.logos';

const OtherSourceLogo = styled(IconPlug)`
  color: ${({ theme }) => theme.ds.hues[HueCategory.BLUE][700]};
` as Asset;

export const userExternalIdSourceLogos: Readonly<Record<UserExternalIdSource, IntegrationLogos>> = {
  [UserExternalIdSource.NONE]: {
    Logo: IconPlugOff as Asset,
    CompactLogo: IconPlugOff as Asset,
  },
  [UserExternalIdSource.OTHERSOURCE]: {
    Logo: OtherSourceLogo,
    CompactLogo: OtherSourceLogo,
  },
  [UserExternalIdSource.SALESFORCE]: {
    ...VENDOR_LOGOS[DataConnectorTypes.SALESFORCE],
  },
  [UserExternalIdSource.HUBSPOT]: {
    ...VENDOR_LOGOS[DataConnectorTypes.HUBSPOT],
  },
  [UserExternalIdSource.MSDYNAMICS]: {
    ...VENDOR_LOGOS[DataConnectorTypes.MSDYNAMICS],
  },
  [UserExternalIdSource.ZOHO]: {
    ...VENDOR_LOGOS[DataConnectorTypes.ZOHO],
  },
};
