import { css } from '@emotion/react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { ButtonLink, Stack, Typography } from '@amalia/design-system/components';
import { DrawingNotFound } from '@amalia/lib-ui';

export const NotFoundStatementError = memo(function NotFoundStatementError() {
  return (
    <Stack
      align="center"
      gap={16}
      css={css`
        padding: 32px 0;
      `}
    >
      <Stack
        gap={16}
        css={css`
          text-align: center;
        `}
      >
        <DrawingNotFound
          height="auto"
          width={160}
        />

        <Typography variant={Typography.Variant.BODY_LARGE_BOLD}>
          <FormattedMessage defaultMessage="Statement not found" />
        </Typography>
      </Stack>

      <div
        css={css`
          text-align: center;
        `}
      >
        <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
          <FormattedMessage defaultMessage="This statement does not exist." />
          <br />
          <FormattedMessage defaultMessage="This user has probably been unassigned from this plan." />
        </Typography>
      </div>

      <div
        css={css`
          text-align: center;
        `}
      >
        <ButtonLink
          to={generatePath(routes.STATEMENTS)}
          variant={ButtonLink.Variant.PRIMARY}
        >
          <FormattedMessage defaultMessage="Go back to home page" />
        </ButtonLink>
      </div>
    </Stack>
  );
});
