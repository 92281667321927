import { type ReactElement, memo } from 'react';

import { type DatasetRow } from '@amalia/core/types';
import { UserPrettyFormat } from '@amalia/data-capture/fields/components';
import { Table } from '@amalia/design-system/components';
import { useUserInformations } from '@amalia/tenants/users/state';

import { type DatasetRecordsDataGridRowIndicatorsProps } from '../../row-indicators/DatasetRecordsDataGridRowIndicators';

export type DatasetRecordsDataGridUserCellProps = {
  readonly datasetRow: DatasetRow;
  readonly rowHighlightMarker?: ReactElement<DatasetRecordsDataGridRowIndicatorsProps>;
};

export const DatasetRecordsDataGridUserCell = memo(function DatasetRecordsDataGridUserCell({
  datasetRow,
  rowHighlightMarker,
}: DatasetRecordsDataGridUserCellProps) {
  const userId = datasetRow.content.userId as string;
  const { data: user, isLoading } = useUserInformations('id', userId);
  return (
    <Table.Cell.WithActions
      actions={rowHighlightMarker}
      hideActions={false}
    >
      {user || isLoading ? (
        <UserPrettyFormat
          firstName={user?.firstName}
          isLoading={isLoading}
          lastName={user?.lastName}
          pictureURL={user?.pictureURL}
          subLabel={user?.externalId}
        />
      ) : (
        userId
      )}
    </Table.Cell.WithActions>
  );
});
