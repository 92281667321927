import { useAuth0 } from '@auth0/auth0-react';
import { IconReload } from '@tabler/icons-react';
import { type ReactNode, memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertBanner, Button } from '@amalia/design-system/components';
import { config } from '@amalia/kernel/config/client';

import { LayoutUnauthenticatedContent } from '../layout-unauthenticated/content/LayoutUnauthenticatedContent';
import { LayoutUnauthenticated } from '../layout-unauthenticated/LayoutUnauthenticated';

import { ReactComponent as AuthenticationErrorIllustration } from './authenticationErrorIllustration.svg';

export type AuthenticationErrorPageProps = {
  readonly message: ReactNode;
  readonly footer?: ReactNode;
};

export const AuthenticationErrorPage = memo(function AuthenticationErrorPage({
  message = undefined,
  footer = undefined,
}: AuthenticationErrorPageProps) {
  const { logout } = useAuth0();

  const handleLogout = useCallback(
    () =>
      logout({
        logoutParams: { returnTo: config.auth0.redirectUri },
      }),
    [logout],
  );

  return (
    <LayoutUnauthenticated>
      <LayoutUnauthenticatedContent
        footer={footer}
        title={<FormattedMessage defaultMessage="Authentication error" />}
        action={
          <Button
            icon={<IconReload />}
            iconPosition={Button.IconPosition.RIGHT}
            onClick={handleLogout}
          >
            <FormattedMessage defaultMessage="Try again" />
          </Button>
        }
        message={
          <AlertBanner
            inline
            variant={AlertBanner.Variant.ERROR}
          >
            {message}
          </AlertBanner>
        }
        picture={
          <AuthenticationErrorIllustration
            height={235.55}
            width={424}
          />
        }
      />
    </LayoutUnauthenticated>
  );
});
