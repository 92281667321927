import { useContext, useMemo } from 'react';
import { UNSAFE_RouteContext as RouteContext, type RoutesProps, Routes } from 'react-router-dom';

export type RootRoutesProps = RoutesProps;

/**
 * For some reason, react-router@6 supports absolute nested routes for everything except for
 * declaring them. It is bad and they should feel bad.
 *
 * Here is the issue: https://github.com/remix-run/react-router/issues/8035.
 * When it is implemented, you should be able to use <Routes> directly instead of this hack.
 *
 * Hack copied from this comment:
 * https://github.com/remix-run/react-router/issues/8035#issuecomment-997737565.
 */
export const RootRoutes = function RootRoutes({ children, ...props }: RootRoutesProps) {
  const context = useContext(RouteContext);

  const value = useMemo(
    () => ({
      ...context,
      matches: [],
    }),
    [context],
  );

  return (
    <RouteContext.Provider value={value}>
      <Routes {...props}>{children}</Routes>
    </RouteContext.Provider>
  );
};
