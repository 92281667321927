import { css } from '@emotion/react';
import { IconPencil } from '@tabler/icons-react';
import { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { IconButton, Popover } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';
import { BadgeConfigurationIcon } from '@amalia/payout-definition/plans/types';

import { PlanAwardIcon } from '../award-icon/PlanAwardIcon';

import { PlanAwardIconSelectOption } from './award-icon-select-option/PlanAwardIconSelectOption';

export type PlanAwardIconSelectProps = {
  readonly value: BadgeConfigurationIcon;
  readonly onChange: (value: BadgeConfigurationIcon) => void;
  readonly disabled?: boolean;
};

export const PlanAwardIconSelect = memo(function PlanAwardIconSelect({
  value,
  onChange,
  disabled = false,
}: PlanAwardIconSelectProps) {
  const { formatMessage } = useIntl();

  const { isOpen, setOpen, setOpenFalse } = useBoolState(false, 'open');

  const handleChange: typeof onChange = useCallback(
    (...args) => {
      onChange(...args);
      setOpenFalse();
    },
    [onChange, setOpenFalse],
  );

  return (
    <div
      css={css`
        position: relative;
        width: fit-content;
        display: flex;
      `}
    >
      <PlanAwardIcon
        icon={value}
        size={56}
      />

      <Popover
        disabled={disabled}
        isOpen={isOpen}
        content={
          <Popover.Layout>
            <Popover.Header>
              <Popover.Title>{formatMessage({ defaultMessage: 'Select a badge' })}</Popover.Title>
            </Popover.Header>

            <Popover.Body>
              <div
                css={css`
                  display: grid;
                  grid-template-columns: repeat(5, 56px);
                  gap: 6px;
                `}
              >
                {Object.values(BadgeConfigurationIcon).map((icon) => (
                  <PlanAwardIconSelectOption
                    key={icon}
                    icon={icon}
                    isSelected={icon === value}
                    onClick={handleChange}
                  />
                ))}
              </div>
            </Popover.Body>
          </Popover.Layout>
        }
        onChangeIsOpen={setOpen}
      >
        <IconButton
          withBackground
          disabled={disabled}
          icon={<IconPencil />}
          isActive={isOpen}
          label={formatMessage({ defaultMessage: 'Pick another badge' })}
          size={IconButton.Size.SMALL}
          css={css`
            position: absolute;
            right: -8px;
            bottom: -4px;
          `}
        />
      </Popover>
    </div>
  );
});
