import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { assert } from '@amalia/ext/typescript';
import { type UserRole } from '@amalia/tenants/users/types';

import { UserRoleLabels } from './roles.messages';

export type UserRoleLabelProps = {
  readonly role: UserRole;
};

export const UserRoleLabel = memo(function UserRoleLabel({ role }: UserRoleLabelProps) {
  assert(role in UserRoleLabels, `Invalid user role: ${role}`);

  return <FormattedMessage {...UserRoleLabels[role]} />;
});
