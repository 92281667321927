import { useSortable, type UseSortableArguments } from '@dnd-kit/sortable';
import { type ReactNode, Fragment } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

export type SortableProvidedProps = ReturnType<typeof useSortable>;
export type SortableProps = MergeAll<
  [UseSortableArguments, { children: (providedProps: SortableProvidedProps) => ReactNode }]
>;

export const Sortable = function Sortable({ children, ...sortableProps }: SortableProps) {
  const sortableProvidedProps = useSortable(sortableProps);
  return <Fragment>{children(sortableProvidedProps)}</Fragment>;
};
