import { useCallback } from 'react';

import { type FormulaBuilderFunctionBlockDateForm } from '@amalia/amalia-lang/formula/form/types';
import {
  type DateTransform,
  type AttributeValueDate,
  type ManualValueDate,
  ValueOrAttributeType,
  type AttributeValue,
} from '@amalia/amalia-lang/formula/types';
import { convertDateToUtcTimestamp } from '@amalia/core/types';

import { type FormulaConditionTagProps } from '../../formula-condition-tag/FormulaConditionTag';

export type useFunctionDateHandlersProps<TFunctionBlockDateForm extends FormulaBuilderFunctionBlockDateForm> = {
  condition: TFunctionBlockDateForm;
  onChange: Required<FormulaConditionTagProps<TFunctionBlockDateForm>>['onChange'];
};

export const useFunctionDateHandlers = <TFunctionBlockDateForm extends FormulaBuilderFunctionBlockDateForm>({
  condition,
  onChange,
}: useFunctionDateHandlersProps<TFunctionBlockDateForm>) => {
  const handleChangeValueOrAttribute: (type: ValueOrAttributeType, index?: number) => void = useCallback(
    (type, index = 1) => {
      const args: TFunctionBlockDateForm['args'] = [...condition.args];
      args[index] =
        type === ValueOrAttributeType.VALUE
          ? {
              type,
              value: 0,
            }
          : {
              type,
              options: {
                transform: null,
                value: null,
              },
            };
      onChange({
        ...condition,
        args,
      });
    },
    [onChange, condition],
  );

  const handleChangeOptionTransform: (transform: DateTransform | null, index?: number) => void = useCallback(
    (transform, index = 1) => {
      const args: TFunctionBlockDateForm['args'] = [...condition.args];
      args[index] = {
        ...args[index],
        options: {
          ...(args[index] as AttributeValueDate).options,
          transform,
        },
      } as AttributeValueDate;

      onChange({
        ...condition,
        args,
      });
    },
    [onChange, condition],
  );

  const handleChangeOptionTransformValue: (value: string, index?: number) => void = useCallback(
    (value, index = 1) => {
      const args: TFunctionBlockDateForm['args'] = [...condition.args];
      args[index] = {
        ...args[index],
        options: {
          ...(args[index] as AttributeValueDate).options,
          value: value === '' ? null : parseInt(value, 10),
        },
      } as AttributeValueDate;

      onChange({
        ...condition,
        args,
      });
    },
    [onChange, condition],
  );

  const handleChangeManualValue: (value: Date | null, index?: number) => void = useCallback(
    (value, index = 1) => {
      const args: TFunctionBlockDateForm['args'] = [...condition.args];
      args[index] = {
        ...condition.args[index],
        value: !value ? 0 : convertDateToUtcTimestamp(value, 'start'),
      } as ManualValueDate;

      onChange({
        ...condition,
        isDraft: false,
        args,
      });
    },
    [onChange, condition],
  );

  const handleChangeAttributeValue: (attribute: AttributeValue, index?: number) => void = useCallback(
    (attribute: AttributeValue, index = 1) => {
      const args: TFunctionBlockDateForm['args'] = [...condition.args];
      args[index] = {
        ...attribute,
        options: (condition.args[index] as AttributeValueDate).options,
      };

      onChange({
        ...condition,
        isDraft: false,
        args,
      });
    },
    [onChange, condition],
  );

  return {
    handleChangeAttributeValue,
    handleChangeManualValue,
    handleChangeOptionTransform,
    handleChangeOptionTransformValue,
    handleChangeValueOrAttribute,
  };
};
