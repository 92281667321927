import { css } from '@emotion/react';
import { IconCircleFilled } from '@tabler/icons-react';
import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type ForwardedRef, forwardRef, memo, type ReactNode } from 'react';

import { TypographyVariant } from '@amalia/design-system/meta';
import { isEnum, type MergeAll } from '@amalia/ext/typescript';

import { Typography } from '../typography/Typography';

import * as styles from './Badge.styles';
import { BadgeColor, BadgeHue, BadgeSize, BadgeStatus } from './Badge.types';

const SIZE_TYPOGRAPHY_MAPPING: Record<BadgeSize, TypographyVariant> = {
  [BadgeSize.SMALL]: TypographyVariant.BODY_XSMALL_REGULAR,
  [BadgeSize.MEDIUM]: TypographyVariant.BODY_BASE_REGULAR,
  [BadgeSize.LARGE]: TypographyVariant.BODY_LARGE_REGULAR,
} as const;

const SIZE_ICON_SIZE_MAPPING: Record<BadgeSize, number> = {
  [BadgeSize.SMALL]: 4,
  [BadgeSize.MEDIUM]: 6,
  [BadgeSize.LARGE]: 6,
} as const;

export type BadgeProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Badge size. */
      size?: BadgeSize;
      /** Badge content. */
      children: ReactNode;
      /** Variant. */
      variant: BadgeColor | BadgeHue | BadgeStatus;
      /** Add a soft box shadow. */
      withShadow?: boolean;
    },
  ]
>;

const BadgeBase = forwardRef(function Badge(
  { variant, size = BadgeSize.MEDIUM, className = undefined, children, withShadow = false, ...props }: BadgeProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      {...props}
      ref={ref}
      className={clsx(className, size, variant)}
      css={[styles.badge, withShadow && styles.withShadow]}
    >
      {!!isEnum(variant, BadgeStatus) && (
        <IconCircleFilled
          color="currentColor"
          size={SIZE_ICON_SIZE_MAPPING[size]}
          css={css`
            flex: none;
          `}
        />
      )}

      <Typography
        css={styles.text}
        variant={SIZE_TYPOGRAPHY_MAPPING[size]}
      >
        {children}
      </Typography>
    </div>
  );
});

export const Badge = Object.assign(memo(BadgeBase), {
  Size: BadgeSize,
  Color: BadgeColor,
  Hue: BadgeHue,
  Status: BadgeStatus,
});
