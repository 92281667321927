/* eslint-disable no-console */
import { css } from '@emotion/react';
import { IconSearch } from '@tabler/icons-react';
import { keyBy } from 'lodash';
import { memo, type MouseEvent, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { formatCurrencyAmount, roundNumber } from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { IconButton } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';
import { getRulesInCategory } from '@amalia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { log } from '@amalia/kernel/logger/client';
import { useStatementDetailContext } from '@amalia/lib-ui';
import { RuleAccordion } from '@amalia/lib-ui-business';
import { ComputedItemTypes, type ComputedRule, type ComputedVariable } from '@amalia/payout-calculation/types';
import { PlanRulesLayout } from '@amalia/payout-definition/plans/components';
import { RuleCategoryHeader, RuleLayout } from '@amalia/payout-definition/plans/rules/components';
import { type PlanCategory, type PlanRule, RuleType } from '@amalia/payout-definition/plans/types';

import { RuleResult } from './Rule/RuleResult';
import { RuleChallenge } from './Rule/ruleTypes/RuleChallenge';
import { RuleDefault } from './Rule/ruleTypes/RuleDefault';

interface StatementCategoryProps {
  readonly setCurrentTracingData: any;
  readonly globalSearchValue: string;
  readonly category?: PlanCategory;
  readonly activeRuleId: string;
}

export const StatementCategory = memo(function StatementCategory({
  setCurrentTracingData,
  globalSearchValue,
  category,
  activeRuleId,
}: StatementCategoryProps) {
  const { formatMessage } = useIntl();
  const { ruleid: ruleId, action, externalid: externalId } = useParams<Record<string, string>>();
  const ability = useAbilityContext();

  const { statement, originalStatement, isForecastedView } = useStatementDetailContext();

  // Fetch from the definitions the rules that are in the current category.
  const rulesInThisCategory = useMemo(() => {
    const ruleDefs = getRulesInCategory(category?.name, statement.results.definitions.plan.rules || []);

    // Filter the rule that are configured to now show
    return ruleDefs.filter((r: PlanRule) => {
      if (!r.configuration?.showHideRuleVariableId) {
        return true;
      }

      const variableDef = Object.values(statement.results.definitions.variables || {}).find(
        (v) => v.id === r.configuration?.showHideRuleVariableId,
      );
      if (!variableDef) {
        console.warn(`Can't find the variable definition set up for the show rule variable on rule ${r.name}`);
        log.warn(`Can't find the variable definition set up for the show rule variable on rule ${r.name}`);
        return true;
      }

      const computedVariable = statement.results.computedObjects.find(
        (v) => (v as ComputedVariable).variableMachineName === variableDef.machineName,
      );
      if (!computedVariable) {
        console.warn(`Can't find the computed variable set up for the show rule variable on rule ${r.name}`);
        log.warn(`Can't find the computed variable set up for the show rule variable on rule ${r.name}`);
        return true;
      }

      return !!computedVariable.value;
    });
  }, [category?.name, statement.results]);

  const { isRuleExpanded, toggleRuleExpanded } = useBoolState(
    activeRuleId === rulesInThisCategory[0]?.id,
    'ruleExpanded',
  );

  // Build a hashmap with their result.
  const ruleResultsOfThisCategoryMap = useMemo(() => {
    const machineNames = rulesInThisCategory.map((r) => r.ruleMachineName);
    const ruleResults = (statement.results.computedObjects as ComputedRule[])
      .filter((co) => co.type === ComputedItemTypes.RULE && machineNames.includes(co.ruleMachineName))
      .map((r: ComputedRule) => {
        const ruleDef = rulesInThisCategory.find((pr: PlanRule) => pr.ruleMachineName === r.ruleMachineName);
        return {
          ...r,
          definition: ruleDef,
          originalRuleValue:
            isForecastedView &&
            originalStatement?.results?.computedObjects?.find(
              (originalRule): originalRule is ComputedRule => originalRule.id === r.id,
            )?.value,
        };
      });
    return keyBy(ruleResults, 'ruleMachineName');
  }, [
    rulesInThisCategory,
    isForecastedView,
    originalStatement?.results?.computedObjects,
    statement.results?.computedObjects,
  ]);

  const categoryTotal = useMemo(() => {
    const rules = Object.values(ruleResultsOfThisCategoryMap).filter((r: ComputedRule & { definition?: PlanRule }) => {
      // Remove Non Payout Rules variable values from the total.
      if (r.definition?.type === RuleType.NON_PAYOUT) {
        return false;
      }

      // if no main kpi variable overwrite is configured, don't filter it
      if (!r.definition?.configuration?.mainKpiVariableOverwrite) {
        return true;
      }

      const mainKpiVariableOverwrite = Object.values(statement.results.definitions.variables || {}).find(
        (v) => v.id === r.definition.configuration.mainKpiVariableOverwrite,
      );

      // Remove every rule from the category total if it's not a currency
      return mainKpiVariableOverwrite?.format === FormatsEnum.currency;
    });

    return rules.reduce((total, ruleResult) => total + (ruleResult.value || 0), 0);
  }, [ruleResultsOfThisCategoryMap, statement.results]);

  const shouldShowTotalForCurrentCategory = !rulesInThisCategory.every(
    (r) => r.type === RuleType.NON_PAYOUT && r.configuration?.hideTotal,
  );

  // Evaluate the total for this category.
  const formattedTotal = useMemo(() => {
    // Hide total if it's 0 or if it should be hidden.
    if (categoryTotal === 0 || !shouldShowTotalForCurrentCategory) {
      return null;
    }

    return formatCurrencyAmount({
      amount: categoryTotal,
      currencyRate: statement.rate,
      currencySymbol: statement.currency,
    });
  }, [statement, categoryTotal, shouldShowTotalForCurrentCategory]);

  const isCategoryAllHnrAndOnSameDataset = rulesInThisCategory.every(
    (r) =>
      r.type === RuleType.HOLD_AND_RELEASE &&
      r.filtersToDisplay?.[0] &&
      r.filtersToDisplay[0]?.id === rulesInThisCategory?.[0]?.filtersToDisplay?.[0]?.id,
  );

  // Tracing
  const openRuleTracing = useCallback(
    (computedRule: ComputedRule, event?: MouseEvent<HTMLButtonElement>) => {
      event?.preventDefault();
      if (ability.can(ActionsEnum.tracing, SubjectsEnum.Statement)) {
        setCurrentTracingData({ rule: computedRule });
      }
    },
    [setCurrentTracingData, ability],
  );

  useEffect(() => {
    // on load, check if we have everything in the url to open rule tracing.
    // if external id is in the url, don't do anything: the rows table manages the deal tracing opening
    // (as we have to load data from the rows table for the deal tracing)
    if (ruleId && action === 'tracing' && !externalId) {
      // try to fetch the rule definition
      const ruleDefinition = (statement.results?.definitions?.plan?.rules || []).find((r) => r.id === ruleId);
      // try to fetch the computed rule
      if (ruleDefinition) {
        const computedRule: ComputedRule = (statement.results?.computedObjects || []).find(
          (co) => (co as ComputedRule).ruleMachineName === ruleDefinition.ruleMachineName,
        ) as ComputedRule;

        // If we find both matches, we have everything we need to open the rule tracing
        if (computedRule) {
          openRuleTracing(computedRule);
        }
      }
    }
  }, [ruleId, action, openRuleTracing, externalId, statement.results]);

  // If we don't have a rule in this category, don't print anything
  if (!rulesInThisCategory || rulesInThisCategory.length === 0) {
    return null;
  }

  if (isCategoryAllHnrAndOnSameDataset && category?.name) {
    const identifier = `${category?.name}-${rulesInThisCategory[0]?.ruleMachineName || 'none'}`;
    const firstPlanRule = rulesInThisCategory?.[0];
    const firstComputedRule = firstPlanRule ? ruleResultsOfThisCategoryMap[firstPlanRule.ruleMachineName] : null;

    return (
      <RuleAccordion
        header={
          <RuleAccordion.Header
            key={category?.name}
            isExpanded={isRuleExpanded}
            label={category?.name}
            machineName={identifier}
            mainKpi={{
              type: RuleType.HOLD_AND_RELEASE,
              value: { amount: categoryTotal, currencyRate: statement.rate, currencySymbol: statement.currency },
            }}
            onToggleExpanded={toggleRuleExpanded}
          />
        }
      >
        <RuleLayout>
          <RuleLayout.HnrRules>
            {(rulesInThisCategory || []).map((rule: PlanRule) => {
              const computedRule = ruleResultsOfThisCategoryMap[rule.ruleMachineName];
              const { formattedAchievedValue, formattedForecastedValue } = {
                formattedForecastedValue: formatCurrencyAmount({
                  amount: roundNumber(computedRule?.value) || 0,
                  currencyRate: statement.rate,
                  currencySymbol: statement.currency,
                }),
                formattedAchievedValue: formatCurrencyAmount({
                  amount: roundNumber(computedRule?.originalRuleValue) || 0,
                  currencyRate: originalStatement?.rate,
                  currencySymbol: originalStatement?.currency,
                }),
              };

              return (
                <RuleAccordion.Header
                  key={rule.id}
                  hideActions
                  isSubSummary
                  helpLabel={rule.description}
                  isExpanded={isRuleExpanded}
                  label={rule.name}
                  machineName={rule.name}
                  actions={
                    !isForecastedView ? (
                      <IconButton
                        icon={<IconSearch />}
                        label={formatMessage({ defaultMessage: 'Open tracing' })}
                        size={IconButton.Size.SMALL}
                        onClick={(e) => openRuleTracing(computedRule, e)}
                      />
                    ) : undefined
                  }
                  mainKpi={{
                    value: {
                      amount: computedRule?.value || 0,
                      currencyRate: statement.rate,
                      currencySymbol: statement.currency,
                    },
                    isSubMainKpi: true,
                    formattedForecastedValue,
                    formattedOriginalValue: formattedAchievedValue,
                    isForecastedView,
                    isValueForecasted: isForecastedView && formattedAchievedValue !== formattedForecastedValue,
                  }}
                  onToggleExpanded={toggleRuleExpanded}
                />
              );
            })}
          </RuleLayout.HnrRules>

          {firstComputedRule ? (
            <RuleResult
              computedRule={firstComputedRule}
              ruleDefinition={firstPlanRule}
              setCurrentTracingData={setCurrentTracingData}
            />
          ) : null}
        </RuleLayout>
      </RuleAccordion>
    );
  }

  return (
    <PlanRulesLayout.Category
      // Trick to hide the category if there is a categoy to display but without any rules in it (RuleChallenge.tsx can return null if we cannot find the challenge results).
      css={css`
        &:has(> .ruleCategoryHeader:is(:only-child)) {
          display: none;
        }
      `}
    >
      {!!category && (
        <RuleCategoryHeader
          category={category}
          total={formattedTotal}
        />
      )}

      {(rulesInThisCategory || []).map((rule) =>
        rule.type !== RuleType.CHALLENGE ? (
          <RuleDefault
            key={rule.id}
            activeRuleId={activeRuleId}
            category={category}
            computedRule={ruleResultsOfThisCategoryMap[rule.ruleMachineName]}
            globalSearchValue={globalSearchValue}
            openRuleTracing={openRuleTracing}
            rule={rule}
            setCurrentTracingData={setCurrentTracingData}
          />
        ) : (
          <RuleChallenge
            key={rule.id}
            activeRuleId={activeRuleId}
            category={category}
            computedRule={ruleResultsOfThisCategoryMap[rule.ruleMachineName]}
            rule={rule}
          />
        ),
      )}
    </PlanRulesLayout.Category>
  );
});
