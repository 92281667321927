import clsx from 'clsx';
import {
  type ReactNode,
  memo,
  forwardRef,
  type ForwardedRef,
  type ComponentPropsWithoutRef,
  type ReactElement,
} from 'react';

import { useTextOverflows } from '@amalia/ext/react/hooks';
import { type MergeAll } from '@amalia/ext/typescript';

import { CheckboxIcon, type CheckboxIconProps } from '../../../general/checkbox-icon/CheckboxIcon';
import { type GrabHandleProps } from '../../../general/grab-handle/GrabHandle';
import { type TablerIconElement } from '../../../general/icons/types';
import { UnstyledButton } from '../../../general/unstyled-button/UnstyledButton';
import { Tooltip } from '../../tooltip/Tooltip';
import { DropdownItemIcon } from '../dropdown-item-content/dropdown-item-icon/DropdownItemIcon';
import { DropdownItemLabel } from '../dropdown-item-content/dropdown-item-label/DropdownItemLabel';
import { DropdownItemSecondaryLabel } from '../dropdown-item-content/dropdown-item-secondary-label/DropdownItemSecondaryLabel';
import { DropdownItemTooltip } from '../dropdown-item-content/dropdown-item-tooltip/DropdownItemTooltip';
import { DropdownItemContainer } from '../Dropdown.styles';

import * as styles from './DropdownItemCheckbox.styles';

export type DropdownItemCheckboxProps = MergeAll<
  [
    Omit<ComponentPropsWithoutRef<'button'>, 'aria-disabled' | 'aria-selected' | 'role'>,
    {
      /** Item label. */
      label: ReactNode;
      /** Optional secondary label (e.g. property source). */
      secondaryLabel?: ReactNode;
      /** Help tooltip content. */
      tooltip?: ReactNode;
      /** Optional icon. */
      icon?: TablerIconElement;
      /** Is the option checked. */
      checked?: CheckboxIconProps['checked'];
      /** Is the option in indeterminate state. */
      indeterminate?: CheckboxIconProps['indeterminate'];
      /** Optional grab handle in case of drag and drop. */
      grabHandle?: ReactElement<GrabHandleProps>;
    },
  ]
>;

const DropdownItemCheckboxForwardRef = forwardRef(function DropdownItemCheckbox(
  {
    label,
    secondaryLabel = undefined,
    tooltip = undefined,
    checked = undefined,
    icon = undefined,
    indeterminate = undefined,
    disabled = false,
    grabHandle = undefined,
    ...props
  }: DropdownItemCheckboxProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { ref: labelRef, doesTextOverflow } = useTextOverflows([label]);

  return (
    <Tooltip
      content={doesTextOverflow ? label : ''}
      placement="right"
    >
      <DropdownItemContainer>
        <UnstyledButton
          {...props}
          ref={ref}
          aria-checked={checked}
          aria-disabled={disabled}
          css={styles.dropdownItemButton}
          disabled={disabled}
          role="option"
          className={clsx(props.className, {
            [styles.IS_CHECKED_CLASSNAME]: checked,
            [styles.IS_DISABLED_CLASSNAME]: disabled,
          })}
        >
          <div css={styles.dropdownItem}>
            {!disabled && grabHandle}

            <CheckboxIcon
              checked={checked}
              disabled={disabled}
              indeterminate={indeterminate}
              size={CheckboxIcon.Size.SMALL}
            />

            {!!icon && (
              <DropdownItemIcon
                disabled={disabled}
                icon={icon}
              />
            )}

            <DropdownItemLabel ref={labelRef}>{label}</DropdownItemLabel>

            {!!secondaryLabel && (
              <DropdownItemSecondaryLabel data-disabled={disabled}>{secondaryLabel}</DropdownItemSecondaryLabel>
            )}

            {!!tooltip && <DropdownItemTooltip tooltip={tooltip} />}
          </div>
        </UnstyledButton>
      </DropdownItemContainer>
    </Tooltip>
  );
});

export const DropdownItemCheckbox = memo(DropdownItemCheckboxForwardRef);
