/* eslint-disable -- just a monkey patch from jsx that I moved to TSX as possible, eslint complains too much for a monkey patch*/
import LoadImage from 'blueimp-load-image';
import ExifReader from 'exifreader';
import { type ChangeEvent, Component } from 'react';
import ReactAvatarEdit, { type Props as ReactAvatarEditProps } from 'react-avatar-edit';

declare module 'react-avatar-edit' {
  export default class Avatar extends Component<ReactAvatarEditProps> {
    public constructor(props: ReactAvatarEditProps);

    public init(): void;

    public onBeforeFileLoadCallback(e: ChangeEvent<HTMLInputElement>): void;

    public onFileLoadCallback(file: File): void;

    public onFileLoad(e: ChangeEvent<HTMLInputElement>): void;
  }
}

export type ReactAvatarEditMonkeyPatchProps = ReactAvatarEditProps;

/**
 * Monkey patching of "react-avatar-edit" to fix issues with some images making app crash.
 *
 * @deprecated We don't encourage the use of this component, we should implement our own see https://gitlab.com/amal-ia/amalia-web/-/issues/6022
 * @link https://github.com/kirill3333/react-avatar/issues/117
 */
export class ReactAvatarEditMonkeyPatch extends ReactAvatarEdit {
  onFileLoad(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    this.onBeforeFileLoadCallback(e);
    if (!e.target.value) return;

    const file = e.target.files![0];

    this.onFileLoadCallback(file);

    let exifOrientation = 1;
    ExifReader.load(file)
      .then(
        (tags) => (exifOrientation = (tags.Orientation?.value as number) || 1),
        (error) => console.warn('Error reading exif data, using defaults', error),
      )
      .finally(() => {
        LoadImage(
          file,
          (image, _) => {
            this.setState({ image, file, showLoader: false }, () => {
              this.init();
            });
          },
          { orientation: exifOrientation, meta: true },
        );
      });
  }
}
