import { memo } from 'react';

import { CONNECTORS } from '@amalia/core/types';
import { VENDOR_LOGOS } from '@amalia/data-capture/connectors/assets';
import { type DataConnectorTypes } from '@amalia/data-capture/connectors/types';
import { Tooltip } from '@amalia/design-system/components';

interface ConnectorLogoProps {
  readonly connectorType: DataConnectorTypes;
}

export const ConnectorLogo = memo(function ConnectorLogo({ connectorType }: ConnectorLogoProps) {
  const ConnectorLogo = VENDOR_LOGOS[connectorType].CompactLogo;
  const connectorName = CONNECTORS[connectorType].name;

  return (
    <Tooltip content={connectorName}>
      <ConnectorLogo
        height={20}
        width={20}
      />
    </Tooltip>
  );
});
