import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.NOT, AmaliaFunctionCategory.MISC);

func.nbParamsRequired = 1;

func.description =
  'This function takes a boolean value as input and returns the logical negation of that value, effectively flipping true to false and false to true.';

func.params = [{ name: 'parameter', description: 'Boolean value to revert.' }];

func.examples = [
  {
    desc: 'Returns true if opportunity.name does not contains "Closed".',
    formula: 'NOT(CONTAINS(opportunity.name, "Closed")' as AmaliaFormula,
  },
  {
    desc: 'Returns false if the team name is equal to "BDR - FR".',
    formula: 'NOT(team.name = "BDR - FR")' as AmaliaFormula,
  },
  {
    desc: 'Returns false if 1 = 1',
    formula: 'NOT(1 = 1)' as AmaliaFormula,
    result: false,
  },
  {
    desc: 'Returns true when the parameter is null.',
    formula: 'NOT(null)' as AmaliaFormula,
    result: true,
  },
];

func.exec = (evaluate: unknown): boolean => !evaluate;

export default func;
