import { css, type Theme } from '@emotion/react';
import '@amalia/design-system/components';

export const label = css`
  text-wrap: none;
`;

export const defaultLabel = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[900]};
`;
export const accentuatedLabel = (theme: Theme) => css`
  color: ${theme.ds.colors.primary[400]};
`;

export const dimmedLabel = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[500]};
`;
