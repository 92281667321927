import { type Theme, css } from '@emotion/react';

import { FieldSize } from '../types';

export const IS_DISABLED_CLASSNAME = 'isDisabled';

export const formFieldLabel = (theme: Theme) => css`
  display: flex;
  align-items: center;

  color: ${theme.ds.colors.gray[800]};

  > * {
    transition: ${theme.ds.transitions.default()};
  }

  &.${IS_DISABLED_CLASSNAME} {
    color: ${theme.ds.colors.gray[300]};
  }
`;

export const required = (theme: Theme) => css`
  color: ${theme.ds.colors.primary[400]};

  .${IS_DISABLED_CLASSNAME} & {
    color: currentColor;
  }

  &.${FieldSize.SMALL} {
    margin-left: 2px;
  }

  &.${FieldSize.MEDIUM}, &.${FieldSize.LARGE} {
    margin-left: 4px;
  }
`;

export const help = css`
  flex: none;

  &.${FieldSize.SMALL} {
    margin-left: 6px;
  }

  &.${FieldSize.MEDIUM}, &.${FieldSize.LARGE} {
    margin-left: 8px;
  }
`;
