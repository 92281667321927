import { type ForwardedRef, forwardRef } from 'react';

import { type FormikFieldProps, useFormikFieldAdapter } from '@amalia/ext/formik';

import { Checkbox, type CheckboxProps } from '../Checkbox';

export type FormikCheckboxProps = FormikFieldProps<CheckboxProps>;

const FormikCheckboxBase = forwardRef(function FormikCheckbox(
  {
    validate, // Omit validate and any props not passed to Checkbox.
    ...props
  }: FormikCheckboxProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const formikFieldProps = useFormikFieldAdapter<CheckboxProps['value'], 'checkbox'>({
    ...props,
    validate,
    type: 'checkbox',
  });

  return (
    <Checkbox
      {...props}
      {...formikFieldProps}
      ref={ref}
    />
  );
});

export const FormikCheckbox = Object.assign(FormikCheckboxBase, {
  Size: Checkbox.Size,
});
