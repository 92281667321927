import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import { type CustomReport, type CustomReportFacetRequest } from '@amalia/reporting/custom-reports/shared';

import { CustomReportsApiClient } from '../api-client/custom-reports.api-client';

import { CUSTOM_REPORTS_KEYS } from './custom-reports.keys';

export const useCustomReportsList = () =>
  useQuery({
    queryKey: [CUSTOM_REPORTS_KEYS.CUSTOM_REPORTS, 'list'],
    queryFn: () => CustomReportsApiClient.getCustomReports(),
    initialData: [],
  });

export const useCreateCustomReports = () => {
  const queryClient = useQueryClient();

  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: [CUSTOM_REPORTS_KEYS.CUSTOM_REPORTS, 'create'],
    mutationFn: CustomReportsApiClient.createCustomReport,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [CUSTOM_REPORTS_KEYS.CUSTOM_REPORTS] });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const usePatchCustomReport = () => {
  const queryClient = useQueryClient();

  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: [CUSTOM_REPORTS_KEYS.CUSTOM_REPORTS, 'patch'],
    mutationFn: CustomReportsApiClient.patchCustomReport,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [CUSTOM_REPORTS_KEYS.CUSTOM_REPORTS] });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useDuplicateCustomReport = () => {
  const queryClient = useQueryClient();

  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: [CUSTOM_REPORTS_KEYS.CUSTOM_REPORTS, 'duplicate'],
    mutationFn: CustomReportsApiClient.duplicateCustomReport,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [CUSTOM_REPORTS_KEYS.CUSTOM_REPORTS] });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useDeleteCustomReport = () => {
  const queryClient = useQueryClient();

  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: [CUSTOM_REPORTS_KEYS.CUSTOM_REPORTS, 'delete'],
    mutationFn: CustomReportsApiClient.deleteCustomReport,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [CUSTOM_REPORTS_KEYS.CUSTOM_REPORTS] });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useCustomReportFacet = (
  customReportId: CustomReport['id'] | null,
  customReportFacetRequest: CustomReportFacetRequest | null,
) =>
  useQuery({
    queryKey: [CUSTOM_REPORTS_KEYS.CUSTOM_REPORTS, customReportId, 'facets', customReportFacetRequest],
    queryFn: () => CustomReportsApiClient.getFacet(customReportId!, customReportFacetRequest!),
    initialData: [],
    enabled: !!customReportId && !!customReportFacetRequest,
  });
