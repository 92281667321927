import { css } from '@emotion/react';
import { makeStyles } from '@mui/styles';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { IconArrowNarrowRight, IconCalendar } from '@tabler/icons-react';
import clsx from 'clsx';
import moment, { type Moment } from 'moment';
import { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Group, IconButton, Modal, Stack, Typography, useSnackbars } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';

import { type DateRange } from '../../../../../utils/common.types';
import { Text, TextType } from '../../../typography';
import { InputLabel } from '../../inputs/Input/Input';

interface DateRangeMoment {
  startDate: Moment | null;
  endDate: Moment | null;
}

interface DateRangeModalProps {
  readonly label?: string;
  readonly value: DateRange;
  readonly onChange: (value: DateRange) => void;
  readonly placeholder?: string;
  readonly classNames?: { button?: string };
  readonly disabled?: boolean;
}

const useStyles = makeStyles({
  dateRangeButton_withValue: {
    width: '100%',
  },
  valueLabel: {
    textAlign: 'center',
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
});

const dateRangeToMomentRange = (range: DateRange): DateRangeMoment => ({
  startDate: range.startDate ? moment.utc(range.startDate) : null,
  endDate: range.endDate ? moment.utc(range.endDate) : null,
});

const momentRangeToDateRange = (range: DateRangeMoment): DateRange => ({
  startDate: range.startDate ? range.startDate.toDate() : null,
  endDate: range.endDate ? range.endDate.toDate() : null,
});

export const DateRangeModal = memo(function DateRangeModal({
  label,
  value,
  onChange,
  placeholder,
  classNames,
  disabled,
}: DateRangeModalProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { snackError } = useSnackbars();

  const { isModalOpen, setModalOpenTrue, setModalOpenFalse } = useBoolState(false, 'modalOpen');

  // We're proxifing values so you can fiddle in the dialog without modifying the real value.
  const [valueProxy, setValueProxy] = useState<DateRangeMoment>({ startDate: null, endDate: null });

  // If user submits, send proxified values to onChange.
  const onApply = useCallback(() => {
    const dateRange = momentRangeToDateRange(valueProxy);
    // Checking here if startDate < endDate
    if (dateRange.startDate && dateRange.endDate && dateRange.startDate.getTime() > dateRange.endDate.getTime()) {
      snackError(<FormattedMessage defaultMessage="You can't select a start date that is after the end date" />);
      return;
    }

    setModalOpenFalse();
    onChange(dateRange);
  }, [valueProxy, setModalOpenFalse, onChange, snackError]);

  // If user dismisses, close the popup and reset proxy.
  const onDismiss = useCallback(() => {
    setModalOpenFalse();
    // Reset proxy to initial value.
    setValueProxy(dateRangeToMomentRange(value));
  }, [setValueProxy, value, setModalOpenFalse]);

  const onChangeStart = useCallback(
    (newValue: Moment) =>
      setValueProxy((currentValue) => ({
        ...currentValue,
        startDate: newValue,
      })),
    [],
  );

  const onChangeEnd = useCallback(
    (newValue: Moment) =>
      setValueProxy((currentValue) => ({
        ...currentValue,
        endDate: newValue,
      })),
    [],
  );

  // When value changed in parent component, refresh the value proxy too
  useEffect(() => setValueProxy(dateRangeToMomentRange(value)), [value]);

  const hasValue = valueProxy.startDate || valueProxy.endDate;

  const restCommonButtonProps = {
    className: clsx(hasValue && classes.dateRangeButton_withValue, classNames?.button),
    onClick: setModalOpenTrue,
    size: hasValue || placeholder ? Button.Size.SMALL : undefined,
    disabled,
    children: hasValue ? (
      <span className={classes.valueLabel}>
        <span>
          {valueProxy.startDate ? valueProxy.startDate.format('YYYY-MM-DD') : formatMessage({ defaultMessage: 'N/A' })}
        </span>
        <IconArrowNarrowRight />
        <span>
          {valueProxy.endDate ? valueProxy.endDate.format('YYYY-MM-DD') : formatMessage({ defaultMessage: 'N/A' })}
        </span>
      </span>
    ) : (
      placeholder || <IconCalendar />
    ),
  };

  const commonButtonProps = {
    ...restCommonButtonProps,
    startIcon: hasValue || placeholder ? <IconCalendar /> : null,
  };

  return (
    <Fragment>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        {label ? (
          <InputLabel>
            <Text type={TextType.INPUT_NAME}>{label}</Text>
          </InputLabel>
        ) : null}
        {hasValue ? (
          <Button
            variant={Button.Variant.LIGHT}
            {...commonButtonProps}
          />
        ) : placeholder ? (
          <Button
            variant={Button.Variant.LIGHT}
            {...commonButtonProps}
          />
        ) : (
          <IconButton
            label={formatMessage({ defaultMessage: 'Set a date range' })}
            {...restCommonButtonProps}
            icon={<IconCalendar />}
            size={IconButton.Size.MEDIUM}
          />
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        size={Modal.Size.LARGE}
        onClose={onDismiss}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              <FormattedMessage defaultMessage="Select a date range" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Group
              align="flex-start"
              gap={32}
              justify="space-between"
            >
              <Stack
                align="flex-start"
                data-testid="start-date"
              >
                <Typography variant={Typography.Variant.BODY_LARGE_MEDIUM}>
                  <FormattedMessage defaultMessage="Start date" />
                </Typography>

                <StaticDatePicker
                  disabled={disabled}
                  value={valueProxy.startDate ?? moment.utc()}
                  slots={{
                    actionBar: () => null,
                    toolbar: () => null,
                  }}
                  onChange={onChangeStart}
                />

                <Button
                  disabled={disabled}
                  variant={Button.Variant.LIGHT}
                  css={css`
                    align-self: flex-end;
                  `}
                  onClick={() => onChangeStart(null)}
                >
                  <FormattedMessage defaultMessage="Reset start date" />
                </Button>
              </Stack>

              <Stack
                align="flex-start"
                data-testid="end-date"
              >
                <Typography variant={Typography.Variant.BODY_LARGE_MEDIUM}>
                  <FormattedMessage defaultMessage="End date" />
                </Typography>

                <StaticDatePicker
                  disabled={disabled}
                  value={valueProxy.endDate ?? moment.utc()}
                  slots={{
                    actionBar: () => null,
                    toolbar: () => null,
                  }}
                  onChange={onChangeEnd}
                />

                <Button
                  disabled={disabled}
                  variant={Button.Variant.LIGHT}
                  css={css`
                    align-self: flex-end;
                  `}
                  onClick={() => onChangeEnd(null)}
                >
                  <FormattedMessage defaultMessage="Reset end date" />
                </Button>
              </Stack>
            </Group>
          </Modal.Body>
        </Modal.Content>

        <Modal.Actions>
          <Modal.CancelAction />

          <Modal.MainAction
            disabled={disabled}
            onClick={onApply}
          >
            <FormattedMessage defaultMessage="Save" />
          </Modal.MainAction>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
});
