import { type Theme, ThemeProvider, useTheme } from '@emotion/react';
import { type ReactNode, memo, useMemo } from 'react';

import { SnackbarProvider } from '../../feedback/snackbars/snackbar-provider/SnackbarProvider';
import { LayersContextProvider } from '../../overlays/layers-context/Layers.context';

export type DesignSystemProviderProps = {
  /** Current theme. */
  readonly theme: Theme;
  /** App. */
  readonly children?: ReactNode;
};

export const DesignSystemProvider = memo(function DesignSystemProvider({ theme, children }: DesignSystemProviderProps) {
  // MUI uses MUI and our ThemeProvider overrides theirs.
  // As a temporary fix until we can completely phase out MUI,
  // I just merge the 2 themes here so everything still works.
  // The new theme is wrapped in theme.ds namespace, which will be hoisted back to theme when
  // MUI is phased out.
  const muiLegacyTheme = useTheme();

  const mergedThemes = useMemo(
    () => ({
      ...muiLegacyTheme,
      ...theme,
    }),
    [muiLegacyTheme, theme],
  );

  return (
    <ThemeProvider theme={mergedThemes}>
      <LayersContextProvider>
        <SnackbarProvider>
          {theme.ds.globalStyles}

          {children}
        </SnackbarProvider>
      </LayersContextProvider>
    </ThemeProvider>
  );
});
