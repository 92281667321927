import { Fragment, memo } from 'react';

import { MenuDropdown, type DropdownProps } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';

import { AvatarEditorModal } from '../avatar-editor/AvatarEditorModal';

import { RandomizeAvatarDropdownItem } from './randomize-avatar-item/RandomizeAvatarDropdownItem';
import { UploadAvatarDropdownItem } from './upload-avatar-item/UploadAvatarDropdownItem';

export type UserAvatarDropdownProps = DropdownProps;

export const UserAvatarDropdown = memo(function UserAvatarDropdown({ children, ...props }: UserAvatarDropdownProps) {
  const { isModalOpen, setModalOpenFalse, setModalOpenTrue } = useBoolState(false, 'modalOpen');

  return (
    <Fragment>
      <MenuDropdown
        {...props}
        content={
          <div>
            <UploadAvatarDropdownItem onClick={setModalOpenTrue} />
            <RandomizeAvatarDropdownItem />
          </div>
        }
      >
        {children}
      </MenuDropdown>

      {/* Unmount the modal when the dropdown is closed to reset form states. */}
      {!!isModalOpen && (
        <AvatarEditorModal
          isModalOpen={isModalOpen}
          onClose={setModalOpenFalse}
        />
      )}
    </Fragment>
  );
});
