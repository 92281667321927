import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type ConnectorDefinition, CONNECTORS } from '@amalia/core/types';
import { UserExternalIdSource } from '@amalia/tenants/users/types';

export type UserExternalIdSourceLabelProps = {
  readonly externalIdSource: UserExternalIdSource;
};

export const UserExternalIdSourceLabel = memo(function UserExternalIdSourceLabel({
  externalIdSource,
}: UserExternalIdSourceLabelProps) {
  if (externalIdSource === UserExternalIdSource.NONE) {
    return <FormattedMessage defaultMessage="No integrations" />;
  }

  if (externalIdSource === UserExternalIdSource.OTHERSOURCE) {
    return <FormattedMessage defaultMessage="Other external source" />;
  }

  // If it's not one of those two, it's a connector, so let's retrieve its name.
  return (CONNECTORS[externalIdSource.toLowerCase()] as ConnectorDefinition | undefined)?.name || '';
});
