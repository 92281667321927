import { css, type Theme } from '@emotion/react';
import { type CSSProperties } from 'react';

export const openingStyles: CSSProperties = {
  display: 'block',
  overflow: 'hidden',
};

export const collapsedStyles: CSSProperties = {
  display: 'none',
  height: 0,
  overflow: 'hidden',
};

export const collapsingStyles: CSSProperties = {
  height: 0,
  overflow: 'hidden',
};

export const startTransitionStyles: CSSProperties = {
  willChange: 'height',
};

export const transitionStyles = (theme: Theme, height: CSSProperties['height']): CSSProperties => ({
  transition: theme.ds.transitions.default('height'),
  height,
});

export const collapseContent = (theme: Theme) => css`
  transition: ${theme.ds.transitions.default('opacity')};
`;

export const collapsedContentStyles: CSSProperties = {
  opacity: 0,
};

export const openContentStyles: CSSProperties = {
  opacity: 1,
};

export const lazyPlaceholder = css`
  height: 100px;
`;
