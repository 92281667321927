import { IconSwitchHorizontal } from '@tabler/icons-react';
import { type ForwardedRef, forwardRef, memo, type ReactElement, useCallback, type ElementType } from 'react';
import { useIntl } from 'react-intl';

import { assert } from '@amalia/ext/typescript';

import { IconButton } from '../../../../general/icon-button/IconButton';
import { TextOverflow } from '../../../../general/text-overflow/TextOverflow';
import { TableDataCellContent } from '../../layout/table-data-cell-content/TableDataCellContent';
import { type RowData } from '../../Table.types';

import * as styles from './CellQuickSwitch.styles';
import { type CellQuickSwitchOptionComponentProps, type CellQuickSwitchOption } from './CellQuickSwitch.types';

export type CellQuickSwitchProps<
  TOption extends CellQuickSwitchOption = CellQuickSwitchOption,
  TRowData extends RowData = RowData,
> = {
  readonly options: TOption[];
  readonly row: TRowData;
  readonly value?: TOption['value'] | null;
  readonly disabled?: boolean;
  readonly OptionComponent?: ElementType<CellQuickSwitchOptionComponentProps<TOption>>;
  readonly onChange?: (value: TOption['value'], row: TRowData) => void;
};

const CellQuickSwitchForwardRef = forwardRef(function CellQuickSwitch<
  TOption extends CellQuickSwitchOption = CellQuickSwitchOption,
  TRowData extends RowData = RowData,
>(
  { options, value, row, disabled = false, onChange, OptionComponent }: CellQuickSwitchProps<TOption, TRowData>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { formatMessage } = useIntl();
  const currentOptionIndex = options.findIndex((option) => option.value === value);
  const currentOption = options[currentOptionIndex] as TOption | undefined;

  // Find next option (options is circular).
  // If only one option and it is selected, nextOption is undefined.
  const nextOption =
    currentOption && options.length === 1 ? undefined : options[(currentOptionIndex + 1) % options.length];

  const handleClickSwitch = useCallback(() => {
    assert(nextOption, 'handleClickSwitch cannot be called if nextOption does not exist.');
    onChange?.(nextOption.value, row);
  }, [onChange, nextOption, row]);

  return (
    <TableDataCellContent css={styles.cellQuickSwitch}>
      <TextOverflow>
        {!!currentOption && (OptionComponent ? <OptionComponent option={currentOption} /> : currentOption.label)}
      </TextOverflow>

      {!!(nextOption && !disabled) && (
        <IconButton
          ref={ref}
          icon={<IconSwitchHorizontal />}
          label={formatMessage(
            { defaultMessage: 'Switch to {nextOptionLabel}' },
            { nextOptionLabel: nextOption.label },
          )}
          onClick={handleClickSwitch}
        />
      )}
    </TableDataCellContent>
  );
});

export const CellQuickSwitch = memo(CellQuickSwitchForwardRef) as <TOption extends CellQuickSwitchOption>(
  props: CellQuickSwitchProps<TOption> & { ref?: ForwardedRef<HTMLButtonElement> },
) => ReactElement | null;
