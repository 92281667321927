import { css } from '@emotion/react';
import { memo } from 'react';

import { reformatDateString } from '@amalia/core/types';
import { PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';

type AxisTick = {
  readonly x?: number;
  readonly y?: number;
  readonly payload?: {
    value: string;
    index: number;
  };
  readonly frequency?: PeriodFrequencyEnum;
  readonly offset: number;
  readonly fontSize?: number;
  readonly fontWeight?: number;
  readonly doesRecordHaveValueByIndex: (index: number) => boolean;
};

export const MonthAxisTick = memo(function MonthAxisTick({
  x,
  y,
  payload,
  frequency,
  offset,
  fontSize,
  fontWeight,
  doesRecordHaveValueByIndex,
}: AxisTick) {
  const monthLabel = reformatDateString(
    payload.value,
    'MM/YYYY',
    frequency === PeriodFrequencyEnum.quarter ? '[Q]Q' : 'MMM',
  );

  const hasValue = doesRecordHaveValueByIndex(payload.index);

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        dy={offset}
        textAnchor="middle"
        css={(theme) => css`
          font-size: ${fontSize}px;
          font-weight: ${fontWeight};
          fill: ${hasValue ? theme.ds.colors.gray[800] : theme.ds.colors.gray[500]};
        `}
      >
        {monthLabel}
      </text>
    </g>
  );
});
