import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type UserStatus } from '@amalia/tenants/users/types';

import { UserStatusLabels } from './status.messages';

export type UserStatusLabelProps = {
  readonly status: UserStatus;
};

export const UserStatusLabel = memo(function UserStatusLabel({ status }: UserStatusLabelProps) {
  return <FormattedMessage {...UserStatusLabels[status]} />;
});
