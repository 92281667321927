import { type Theme, css } from '@emotion/react';

import { IconActionVariant, IconActionSize } from './IconAction.types';

export const iconAction = (theme: Theme) => css`
  display: flex;

  transition: ${theme.ds.transitions.default()};

  color: ${theme.ds.colors.gray[800]};
  background-color: transparent;

  border-radius: ${theme.ds.borderRadiuses.round};

  > * {
    transition: ${theme.ds.transitions.default()};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${theme.ds.colors.gray[300]};
  }

  &:not(:disabled) {
    &:hover {
      &.${IconActionVariant.DEFAULT} {
        color: ${theme.ds.colors.primary[500]};
      }

      &.${IconActionVariant.SUCCESS} {
        color: ${theme.ds.colors.success[700]};
      }

      &.${IconActionVariant.DANGER} {
        color: ${theme.ds.colors.danger[700]};
      }
    }
  }

  &:focus-visible {
    outline: 1px solid ${theme.ds.colors.primary[200]};

    &.${IconActionVariant.DEFAULT} {
      &.${IconActionSize.XSMALL} {
        box-shadow: ${theme.ds.shadows.focusXsmall(theme.ds.colors.primary[50])};
      }

      &.${IconActionSize.SMALL} {
        box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.primary[50])};
      }

      &.${IconActionSize.MEDIUM} {
        box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.primary[50])};
      }
    }

    &.${IconActionVariant.SUCCESS} {
      outline-color: ${theme.ds.colors.success[500]};

      &.${IconActionSize.XSMALL} {
        box-shadow: ${theme.ds.shadows.focusXsmall(theme.ds.colors.success[100])};
      }

      &.${IconActionSize.SMALL} {
        box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.success[100])};
      }

      &.${IconActionSize.MEDIUM} {
        box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.success[100])};
      }
    }

    &.${IconActionVariant.DANGER} {
      outline-color: ${theme.ds.colors.danger[500]};

      &.${IconActionSize.XSMALL} {
        box-shadow: ${theme.ds.shadows.focusXsmall(theme.ds.colors.danger[100])};
      }

      &.${IconActionSize.SMALL} {
        box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.danger[100])};
      }

      &.${IconActionSize.MEDIUM} {
        box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.danger[100])};
      }
    }
  }

  &.${IconActionSize.XSMALL} {
    width: 14px;
    height: 14px;
  }

  &.${IconActionSize.SMALL} {
    width: 19.2px;
    height: 19.2px;
  }

  &.${IconActionSize.MEDIUM} {
    width: 24px;
    height: 24px;
  }
`;
