import { css } from '@emotion/react';

export const otherStepPopover = css`
  padding: 6px 16px 16px;
`;

export const headerContainer = css`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 12px;
`;

export const switchContainer = css`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const form = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const selectFieldPopover = css`
  max-height: 180px;
  overflow: auto;
`;

export const dateTransformerInput = css`
  width: 130px;
`;

export const buttonSelectField = css`
  width: 200px;
`;

export const dateTransformerContainer = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;
