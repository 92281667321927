import { UserExternalIdSource, UserHrisIdSource } from '@amalia/tenants/users/types';

import { type UserIntegrationsInfo } from '../types';

export enum HasUserIntegrations {
  NONE = 'NONE',
  EXTERNAL_ONLY = 'EXTERNAL_ONLY',
  HRIS_ONLY = 'HRIS_ONLY',
  BOTH = 'BOTH',
}

export const deriveHasUserIntegrations = ({
  externalId,
  externalIdSource = UserExternalIdSource.OTHERSOURCE,
  hrisId,
  hrisIdSource = UserHrisIdSource.OTHERSOURCE,
}: UserIntegrationsInfo): HasUserIntegrations => {
  const isExternalIdSourceNone = !externalId || externalIdSource === UserExternalIdSource.NONE;
  const isHrisIdSourceNone = !hrisId || hrisIdSource === UserHrisIdSource.NONE;

  const areBothSourcesNone = isExternalIdSourceNone && isHrisIdSourceNone;

  if (areBothSourcesNone) {
    return HasUserIntegrations.NONE;
  }

  if (isExternalIdSourceNone && !isHrisIdSourceNone) {
    return HasUserIntegrations.HRIS_ONLY;
  }

  if (!isExternalIdSourceNone && isHrisIdSourceNone) {
    return HasUserIntegrations.EXTERNAL_ONLY;
  }

  return HasUserIntegrations.BOTH;
};
