import { css, type Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { type SuggestionProps } from '@tiptap/suggestion';
import { entries, groupBy } from 'lodash';
import { type ForwardedRef, forwardRef, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { type FormulaEditorToken } from '@amalia/amalia-lang/formula/components';
import { type TokenType } from '@amalia/amalia-lang/tokens/types';
import { Typography } from '@amalia/design-system/components';
import { DrawingNotFound } from '@amalia/lib-ui';

import { FormulaEditorSuggestionDropdownFooter } from './FormulaEditorSuggestionDropdownFooter';
import { SuggestionGroup } from './suggestion-group/SuggestionGroup';
import { useFilteredItems } from './useFilteredItems';
import { useKeyboardNavigationHandler } from './useKeyboardNavigationHandler';
import { useOpenedGroups } from './useOpenedGroups';

export type ForwardedTokenSuggestionListRef = {
  onKeyDown: ({ event }: { event: KeyboardEvent }) => boolean;
};

const containerStyle = (theme: Theme) => css`
  gap: 6px;
  box-shadow: ${theme.ds.shadows.hard};
  background: ${theme.ds.colors.gray[0]};
  border-radius: ${theme.ds.borderRadiuses.squared};
  position: relative;
  width: 320px;
`;

const suggestionListStyle = css`
  padding: 6px;
  // Category + 4 items (32px per item) height + 6px bottom to have space with footer.
  height: 166px;
  scroll-padding: 33px;
  overflow: auto;
`;

const DropdownNoResults = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export type FormulaEditorSuggestionDropdownProps = SuggestionProps<FormulaEditorToken>;

export const FormulaEditorSuggestionDropdown = forwardRef(function TokenSuggestionList(
  { command, items, editor, query }: FormulaEditorSuggestionDropdownProps,
  ref: ForwardedRef<ForwardedTokenSuggestionListRef>,
) {
  const suggestionListContainerRef = useRef<HTMLDivElement | null>(null);

  const filteredItems = useFilteredItems(items, editor.getText(), query);

  const groups = useMemo(() => {
    const tokenGroups = groupBy(filteredItems, 'type');

    return entries(tokenGroups).map(([key, options]) => ({
      label: key as TokenType,
      options,
    }));
  }, [filteredItems]);
  const { openedGroups, toggleGroup, openGroup } = useOpenedGroups(filteredItems);

  const { selectedFormulaToken } = useKeyboardNavigationHandler(
    groups,
    openGroup,
    ref,
    suggestionListContainerRef,
    command,
  );

  return (
    <div css={containerStyle}>
      <div
        ref={suggestionListContainerRef}
        css={suggestionListStyle}
      >
        {groups.length ? (
          groups.map((group) => (
            <SuggestionGroup
              key={group.label}
              command={command}
              isOpen={openedGroups[group.label as unknown as TokenType]}
              options={group.options}
              selectedFormulaToken={selectedFormulaToken}
              toggleGroup={toggleGroup}
              tokenType={group.label}
            />
          ))
        ) : (
          <DropdownNoResults>
            <DrawingNotFound width="75px" />

            <Typography variant={Typography.Variant.BODY_BASE_BOLD}>
              <FormattedMessage defaultMessage="No result found." />
            </Typography>

            <Typography variant={Typography.Variant.BODY_XSMALL_REGULAR}>
              <FormattedMessage defaultMessage="We cannot find any item matching your search." />
            </Typography>
          </DropdownNoResults>
        )}
      </div>
      <FormulaEditorSuggestionDropdownFooter />
    </div>
  );
});
